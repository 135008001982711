import React from 'react'
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";
import "./Home.css";
export default function Home({ scroll }) {
    const history = useHistory();
    const { pathname } = useLocation();
    return (
        <div className={scroll !== 0 ? "skydart__home1" : "skydart__home"}>
            <div className="skydart__home__container">
                <div className="skydart__home__header">
                    <div className="skydart-header-first-left">
                        <a href="https://skydart.com.vn" target="_blank">
                            <img src="https://i.imgur.com/L6PnhXq.png" style={{ width: "50px", height: "50px" }} alt="Skydart Express" />
                        </a>
                        <div className="skydart-header-text" style={{ marginLeft: 10 }}>
                            <h1 style={{ fontSize: 24 }}>SKYDART EXPRESS</h1>
                            <h3 style={{ fontSize: 14 }}>International Air Express Services</h3>
                        </div>
                    </div>
                    <div className="skydart__home__right">
                        <NavLink exact to="/" activeClassName="skydart-active1" className="skd-header-right-option1">
                            <h5 style={{ fontWeight: 600 }}>Home</h5>
                        </NavLink>
                        <NavLink exact to="/tracking" activeClassName="skydart-active1" className="skd-header-right-option1">
                            <h5 style={{ fontWeight: 600 }}>Track My Shipment</h5>
                        </NavLink>
                        <NavLink exact to="/login" activeClassName="skydart-active1" className="skd-header-right-option1">
                            <h5 style={{ fontWeight: 600 }}>Login</h5>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
