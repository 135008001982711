import React, { useState, useEffect } from 'react'
import { db, auth } from "../firebase";
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
export default function UserSetting({ user }) {
    const [userData, setUserData] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [createAt, setCreateAt] = useState("");
    const [traceTime,setTraceTime] = useState("");
    const { userEmail } = useParams();
    const history = useHistory();
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${year}-${month}-${date}`);
    }, [])
    useEffect(() => {
        if (user) {
            db.collection("users").doc(userEmail).get().then((doc) => {
                if (doc.exists) {
                    setUserData(doc.data());
                    setName(doc.data().displayName);
                    setEmail(doc.data().email);
                    setPassword(doc.data().password);
                    setRole(doc.data().role);
                } else {
                    console.log();
                }
            })
        } else {
            setUserData([]);
        }
    }, [user]);
    const onUpdateUser = () => {
        db.collection("users").doc(userEmail).update({
            displayName: name,
            role: role,
            lastUpdate: traceTime,
        }).then(() => {
            alert("Update Succeed");
        })
    }
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setCreateAt(`${date} ${monthName} ${year}`);
    }, [])
    // const reAuth = () => {
    //     db.collection("user").doc(userEmail).delete();
    //     auth.signInWithEmailAndPassword("n.tuan@skydart.com.vn", "nguyentuan");
    //     axios.delete(`https://8fba-113-161-76-170.ngrok.io/api/deleteUser/${userEmail}`).then(() => {
    //         alert("Update Succeed");
    //         history.goBack();
    //     })
    // }
    // const onUpdateUser = () => {
    //     auth.createUserWithEmailAndPassword(email, userData.password).then((auth) => {
    //         if (auth.user) {
    //             auth.user.updateProfile({
    //                 displayName: name,
    //             }).then(() => {
    //                 db.collection("users").doc(email).set({
    //                     displayName: auth.user.displayName,
    //                     email: auth.user.email,
    //                     timestamp: userData.timestamp,
    //                     role: role,
    //                     password: password,
    //                     phone: userData.phone,
    //                     employee: "yes",
    //                 }).then(() => {
    //                     axios.post("https://8fba-113-161-76-170.ngrok.io/api/staff", {
    //                         displayName: name,
    //                         email: email,
    //                         password: password,
    //                         role: role,
    //                         createAt: createAt,
    //                         phone: userData.phone,
    //                         uid: auth.user.uid,

    //                     })
    //                 })
    //                 reAuth();
    //             })
    //         }
    //     });
    // }
    const onUpdateAccount = (e) => {
        e.preventDefault();
        onUpdateUser();
    }
    return (
        <div className="staff__setting">
            <div className="forgot-container">
                <div className="staff__setting__header">
                    <div className="staff__setting__header__input">
                        <h5>Display Name</h5>
                        <input type='text' style={{ background: "white" }} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>Email Address</h5>
                        <input type='text' style={{ background: "white" }} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>Title / Role</h5>
                        <select value={role} required style={{ background: "white" }} onChange={(e) => setRole(e.target.value)}>
                            <option value="">
                                Select Role
                            </option>
                            <option value="CTO">CTO</option>
                            <option value="CFO">CFO</option>
                            <option value="Admin">Admin</option>
                            <option value="Sales">Sales</option>
                            <option value="OPS">OPS</option>
                            <option value="Finance">Finance</option>
                            <option value="Courier">Courier</option>
                            <option value="Pricing">Pricing</option>
                        </select>
                    </div>
                    <form onSubmit={onUpdateAccount}>
                        <div className="staff__setting__header__input">
                            <h5>Password</h5>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} style={{ background: "white" }} type="password" />
                        </div>
                        <div className="staff__setting__header__input">
                            <button type="button" style={{ width: "32%" }} onClick={() => history.goBack()}>Back</button>
                            <button type="submit">Update Account</button>
                        </div>
                        <div className="staff__setting__body__option">
                            <a href="http://skydart.com.vn/" rel="noreferrer" target="_blank">Skydart Express @ 2021</a>
                            <a href="/imported">Manifest</a>
                            <a href="/new-booking">New Booking</a>
                            <a href="/tracking">Upload Tracking</a>
                            <a href="/cusomters">Customers</a>
                            <a href="/shipments">Shipments</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
