import React, {useState,useEffect} from 'react'
import QRCode from 'qrcode.react';
import {db} from "../firebase";
export default function ShippingMark({ hawb,no,exportSelected,date,total,weight,chargeable,length,width,height,importer,address,address2,address3,town,city,state,postal,country,phone,contact }) {

  

    return (
        <div className="skydart-shipping-mark">
            <div className="skydart-shipping-mark-container">
                <div className="skydart-shipping-mark-container-header">
                    <div style={{ border: "1px solid", width: "25%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <h3>WPX</h3>
                    </div>
                    <div style={{ marginLeft: 0, borderTop: "1px solid", width: "66%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <h1>{hawb}</h1>
                    </div>
                    <QRCode value={`https://skydart-booking-app.firebaseapp.com/scan/${hawb}`} size={150} imageSettings={{ src: "https://i.imgur.com/L6PnhXq.png", excavate: true, size: 130 }}
                        level={'H'}
                        includeMargin={true} style={{ border: '1px solid' }} />
                </div>
                <div className="skydart-shipping-mark-container-body">
                    <div className="skydart-shipping-mark-body-option1">
                        <h5 style={{textAlign: 'center'}}>Shipment information</h5>
                        <div className="skydart-shipping-mark-dim">
                            <div className="shipping-mark-dim">
                                <h5 style={{ fontSize: 10 }}>Packages</h5>
                                <h3>{no}/{total}</h3>
                            </div>
                            <div className="shipping-mark-dim">
                                <h5 style={{ fontSize: 10 }}>Total Gross</h5>
                                <h3>{(Math.round(weight * 100) / 100).toFixed(2)}</h3>
                            </div>
                            <div className="shipping-mark-dim">
                                <h5 style={{ fontSize: 10 }}>Chargeable</h5>
                                <h3>{(Math.round(chargeable * 100) / 100).toFixed(2)}</h3>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="skydart-shipping-mark-body-option1" style={{borderLeft: "none"}}>
                        <h5 style={{textAlign: 'center'}}>Dimensions</h5>
                        <div className="skydart-shipping-mark-dim">
                            <div className="shipping-mark-dim">
                                <h5 style={{ fontSize: 10 }}>Length (CM)</h5>
                                <h3>{length}</h3>
                            </div>
                            <div className="shipping-mark-dim">
                                <h5 style={{ fontSize: 10 }}>Width (CM)</h5>
                                <h3>{width}</h3>
                            </div>
                            <div className="shipping-mark-dim">
                                <h5 style={{ fontSize: 10 }}>Height (CM)</h5>
                                <h3>{height}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="skydart-shipping-mark-container-info">
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Importer / Company:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{importer}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Address:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{address}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Address 2:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{address2}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Town:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{town}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>City:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{city}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>State:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{state}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Post Code:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{postal}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Country:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{country}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Telephone:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{phone}</h5>
                    </div>
                    <div className="skydart-shipping-mark-booking-info">
                        <h5 style={{width: "50%"}}>Contact Name:</h5>
                        <h5 style={{color: "black", textDecoration: "none"}}>{contact}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
