
import { useRef, useContext } from "react";
import { useDay } from "@datepicker-react/hooks";
import { jsx } from "@emotion/react";
import DatepickerContext from "./datepickerContext";
import getColor from "./getColor";

function Day({ dayLabel, date }) {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate
  );

  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      className="selecting-date1"
      style={{
        cursor: "pointer",
        padding: "6px",
        border: 0,
        width: 32,
        fontSize: 12,
        borderRadius: getColorFn({
            selectedFirstOrLastColor: "5px",
          }),
        color: getColorFn({
          selectedFirstOrLastColor: "#FFFFFF",
          normalColor: "#001217",
          selectedColor: "black",
          rangeHoverColor: "black",
          disabledColor: "#808285"
        }),
        background: getColorFn({
          selectedFirstOrLastColor: "#0173d1",
          normalColor: "#FFFFFF",
          selectedColor: "rgb(173, 211, 255)",
          rangeHoverColor: "rgb(230,230,230)",
          disabledColor: "#FFFFFF"
        }),
        // hover {
        //   background
        // }
      }}
    >
      {dayLabel}
    </button>
  );
}

export default Day;
