
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress, IconButton } from '@material-ui/core';
import { Add, Apps, Check, ArrowDownward, CallReceived, LocalShipping, ArrowLeft, ArrowRight, ArrowRightAlt, Refresh, Clear, Crop, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, FileCopySharp } from '@material-ui/icons';
import { Package } from 'react-feather';
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { HiDocument } from "react-icons/hi";
import { GrDocument } from "react-icons/gr";
import { IoBuildOutline } from "react-icons/io5";
import { db } from "../firebase";
export default function PhoneAccess({ userData, user }) {
    const [vaiTro, setVaiTro] = useState("");
    const [prep, setPrep] = useState("");
    const [searchHawb, setSearchHawb] = useState("");
    const [searchMawb, setSearchMawb] = useState("");
    const [searchFlight, setSearchFlight] = useState("");
    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageNumberLimit, setPageNumberLimit] = useState(5);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(8);
    const [traceTime, setTraceTime] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [searchType, setSearchType] = useState("MAWB");

    const history = useHistory();
    const getVaiTro = () => {
        db.collection("users").doc(user.email).update({
            subRole: vaiTro,
        })
        alert("Cập nhật thành công");


    }
    if (window.screen.width >= 600) {
        history.push("/");
    }


    useEffect(() => {
        refreshShipment();
    }, []);
    const finalShipment = shipments.filter((s) => {
        return s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) && s.mawb.toLowerCase().includes(searchMawb.toLowerCase()) && s.airline.toLowerCase().includes(searchFlight.toLowerCase());
    })

    const pages = [];
    for (let i = 1; i <= Math.ceil(finalShipment.length / itemPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = finalShipment.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (e) => {
        setCurrentPage(Number(e.target.id));
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageLimit + 1 && number > minPageLimit) {
            return (
                <li key={number} id={number} onClick={handleChangePage} className={currentPage == number ? "active" : null} style={{ borderRadius: "7px", background: "white", border: "1px solid lightgray" }}>
                    {number}
                </li>
            )
        } else {
            return null;
        }

    })
    const allDox = finalShipment.filter(s => {
        return s.type === "DOX";
    })
    const allWpx = finalShipment.filter(s => {
        return s.type === "WPX";
    })
    const selectedAction = shipments.filter((s) => {
        return s.select;
    })
    const refreshShipment = () => {
        axios.get("https://test.skydart.com.vn/api/shipment/clearance/check").then((snapshot) => {
            let data = snapshot.data;
            setShipments(data.filter((s, i) => {
                return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            }).map((s, i) => {
                let value = parseFloat(s.totalInvoiceValue).toFixed(2) || "";
                return {
                    select: false,
                    account: s.account || "",
                    currency: s.currency || "",
                    service: s.service || "SKD",
                    date: s.date || "",
                    hawb: s.hawb || "",
                    mawb: s.mawb || "",
                    makh: s.makh || "",
                    company: s.company || "",
                    contact: s.contact || "",
                    type: s.type || "",
                    status: s.status || "",
                    term: s.term || "",
                    time: s.time || "",
                    address: s.address || "",
                    address2: s.address2 || "",
                    address3: s.address3 || "",
                    city: s.city || "",
                    country: s.country || "",
                    phone: s.phone || "",
                    postal: s.postal || "",
                    state: s.state || "",
                    term: s.term,
                    town: s.town || "",
                    chargeable: s.chargeable || "",
                    amount: s.amount || "",
                    totalItem: s.totalItem || "",
                    transport: s.transport || "",
                    description: s.description || "",
                    controller: s.controller || "",
                    eamFormat: s.eamFormat || "",
                    value: value || "",
                    airline: s.airline || "",
                    reweight: s.reweight || "",
                    agent: s.agent || "",
                    etd: s.etd || "",
                    subStatus: s.subStatus || "",
                    sin: s.sin || "",
                    sender: s.sender || "",
                    note: s.note || "",
                    perform: s.perform || "",
                    rate: s.rate || "",
                    fsc: s.fsc || "",
                    vat: s.vat || "",
                    remote: s.remote || "",
                    comestic: s.comestic || "",
                    addressCorrection: s.addressCorrection || "",
                    totalValue: s.totalValue || "",
                    surcharge: s.surcharge || "",
                    surcharge2: s.surcharge2 || "",
                    surcharge3: s.surcharge3 || "",
                    fscValue: s.fscValue || "",
                    vatValue: s.vatValue || "",
                    sellNote: s.sellNote || "",
                    paymentPickup: s.paymentPickup || "",
                    paymentAcc: s.paymentAcc || "",
                    paymentCompany: s.paymentCompany || "",
                    fromEmail: s.fromEmail || "",
                    fromCity: s.fromCity || "",
                    com: s.com || "",
                    rateUpdate: s.rateUpdate || "",
                    paymentStatus: s.paymentStatus || "",
                    paymentSubStatus: s.paymentSubStatus || "",
                    code: s.code || "",
                    eamService: s.eamService || "",
                    pack: s.pack || "",
                    plan: s.plan || "Pending",
                }
            }))
            setLoading(false);
        }).catch((err) => {
            alert(err);
        })
    }
    const clearShipments = () => {
        if (selectedAction.length !== 0) {
            selectedAction.forEach((s) => {
                db.collection("shipments").doc(s.hawb).update({
                    status: prep,
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        time: traceTime + "T" + dateTime + ":00",
                        location: "VIETNAM - HO CHI MINH",
                        stat: "Shipment cleared for export",
                    })),
                    clearedBy: userData.displayName,
                })
                axios.put("https://test.skydart.com.vn/api/update/transit", { hawb: s.hawb, status: prep }).then(() => {
                    refreshShipment();
                    setPrep("");
                })
            })
            alert("Cập Nhật Thành Công");
        } else if (selectedAction.length === 0) {
            alert("Hãy chọn 1 shipment để cập nhật");
        }

    }
    return (
        <div className="good-morning-status" style={{ color: "black" }}>
            <div className="good-morning-container">
                <div className="good-morning-container-header">
                    <p style={{ color: "black", fontSize: 15, marginLeft: 12 }}>Hãy chọn 1 vị trí:</p>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        {vaiTro !== "Pick Up" && <div className="good-morning-option" onClick={() => setVaiTro("Pick Up")}>
                            <LocalShipping fontSize="small" className="morning-list-icon" />
                            <h3>Pick Up (Giao Nhận)</h3>
                        </div>}
                        {vaiTro === "Pick Up" && <div className="good-morning-option" onClick={() => setVaiTro("")} style={{ background: "rgb(24, 134, 243)", color: "white" }}>
                            <Check fontSize="small" className="morning-list-icon" />
                            <h3>Pick Up (Giao Nhận)</h3>
                        </div>}
                        {vaiTro !== "Stock" && <div className="good-morning-option" onClick={() => setVaiTro("Stock")}>
                            <CallReceived fontSize="small" className="morning-list-icon" />
                            <h3>Stock (Thủ Kho)</h3>
                        </div>}
                        {vaiTro === "Stock" && <div className="good-morning-option" onClick={() => setVaiTro("")} style={{ background: "rgb(24, 134, 243)", color: "white" }}>
                            <Check fontSize="small" className="morning-list-icon" />
                            <h3>Stock (Thủ Kho)</h3>
                        </div>}
                        {vaiTro !== "Process" && <div className="good-morning-option" onClick={() => setVaiTro("Process")}>
                            <IoBuildOutline size={20} className="morning-list-icon" />
                            <h3>Process (Kiểm Hàng)</h3>
                        </div>}
                        {vaiTro === "Process" && <div className="good-morning-option" onClick={() => setVaiTro("")} style={{ background: "rgb(24, 134, 243)", color: "white" }} >
                            <Check fontSize="small" className="morning-list-icon" />
                            <h3>Process (Kiểm Hàng)</h3>
                        </div>}
                        {vaiTro !== "Clearance" && <div className="good-morning-option" onClick={() => setVaiTro("Clearance")}>
                            <Apps fontSize="small" className="morning-list-icon" />
                            <h3>Clearance (Hải Quan)</h3>
                        </div>}
                        {vaiTro === "Clearance" && <div className="good-morning-option" onClick={() => setVaiTro("")} style={{ background: "rgb(24, 134, 243)", color: "white" }} >
                            <Check fontSize="small" className="morning-list-icon" />
                            <h3>Clearance (Hải Quan)</h3>
                        </div>}

                    </div>
                    <div className="good-morning-option-btn">
                        {vaiTro === "" && <button type="button" style={{ background: "lightgray", color: "gray" }}>Chọn vị trí</button>}
                        {vaiTro !== "" && <button type="button" onClick={getVaiTro}>Chọn vị trí</button>}
                    </div>
                </div>
                <div className="good-morning-cleared">

                    <div style={{ display: "flex", marginLeft: 15, marginRight: 15 }}>
                        <h5 style={{ color: "white", fontWeight: 600, marginRight: 5, padding: 7, paddingTop: 10, borderRadius: 5, background: "rgb(24, 134, 243)", textAlign: "center", display: "flex", alignItems: "center" }}><Package size={18} style={{ marginRight: 3 }} />WPX: {allWpx.length}</h5>
                        <h5 style={{ color: "white", fontWeight: 600, marginRight: 5, padding: 7, paddingTop: 10, borderRadius: 5, background: "rgb(24, 134, 243)", textAlign: "center", display: "flex", alignItems: "center" }}><HiDocument size={18} />DOX: {allDox.length}</h5>
                        {userData.subRole === "Clearance" &&
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                                <select value={prep} onChange={(e) => setPrep(e.target.value)} style={{ color: "black", fontWeight: 600, fontSize: 12, marginRight: 5, fontWeight: 600, border: "none", outline: "none", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", height: 35, background: "white", border: "1px solid rgb(230,230,230)" }}>
                                    <option value="">Trạng thái</option>
                                    <option value="Cleared For Export">Cleared</option>
                                </select>
                                {prep !== "" && <button type="button" onClick={clearShipments} style={{ width: 50, color: "white", background: "darkgreen", fontWeight: 600, border: "none", marginLeft: "auto", borderRadius: 5, padding: 5, height: 35 }}>
                                    OK
                                </button>}
                                {prep === "" && <button type="button" onClick={() => alert("Hãy chọn 1 trạng thái")} style={{ color: "gray", background: "lightgray", fontWeight: 600, marginLeft: "auto", border: "none", height: 35, width: 50, borderRadius: 5, padding: 5 }}>
                                    OK
                                </button>}

                            </div>}

                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 5, marginRight: 8, marginLeft: 8 }}>
                        <div className="paginate" style={{ background: "rgb(240,240,240)", width: "100%", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className="pageNumbers">
                                <IconButton type="button" disabled={currentPage == pages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage}><ArrowLeft className="prev-btn-page" /></IconButton>
                                {renderPageNumbers}
                                <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage}><ArrowRight className="prev-btn-page" /></IconButton>
                            </div>
                        </div>
                    </div>
                    {searchMawb !== "" && <p style={{ marginTop: 5, marginLeft: 13, fontSize: 12, wordBreak: "break-word" }}>Tìm MAWB: {searchMawb}</p>}
                    {searchHawb !== "" && <p style={{ marginTop: 5, marginLeft: 13, fontSize: 12, wordBreak: "break-word" }}>Tìm HAWB: {searchHawb}</p>}
                    {searchFlight !== "" && <p style={{ marginTop: 5, marginLeft: 13, fontSize: 12, wordBreak: "break-word" }}>Tìm chuyến bay: {searchFlight}</p>}
                    <div style={{ marginTop: 5, display: "flex", alignItems: "center", background: "white", borderRadius: 5, padding: 3 }}>
                        <div className="skydart-bill-checkbox-header" style={{ border: "none" }}>
                            <input type="checkbox" id="selectAll" onChange={e => {
                                let value = e.target.checked;
                                setShipments(shipments.map(d => {
                                    d.select = value;
                                    return d;
                                }))
                            }} />
                        </div>
                        <Search style={{ marginLeft: 5, fontSize: 23 }} />
                        <select value={searchType} onChange={(e) => setSearchType(e.target.value)} style={{ height: 32, borderRadius: 5, fontSize: 13, color: "black", background: "rgb(230,230,230)", marginLeft: 5 }}>
                            <option value="MAWB">MAWB</option>
                            <option value="HAWB">HAWB</option>
                            <option value="Flight">Chuyến Bay</option>
                        </select>
                        {searchType === "MAWB" && <input type="text" className="phone-input-search" placeholder="Tìm kiếm" value={searchMawb} onChange={(e) => setSearchMawb(e.target.value)} />}
                        {searchType === "HAWB" && <input type="text" className="phone-input-search" placeholder="Tìm kiếm" value={searchHawb} onChange={(e) => setSearchHawb(e.target.value)} />}
                        {searchType === "Flight" && <input type="text" className="phone-input-search" placeholder="Tìm kiếm" value={searchFlight} onChange={(e) => setSearchFlight(e.target.value)} />}
                        <div className="phone-clear-input" onClick={() => {
                            setSearchType("MAWB");
                            setSearchHawb("");
                            setSearchMawb("");
                            setSearchFlight("");
                        }}>
                            <Clear fontSize="small" />
                        </div>
                        {/* <input type="text" value={searchHawb} onChange={(e) => setSearchHawb(e.target.value)} style={{ padding: 7, width: "100%", border: "none", borderRadius: 5, outline: "none" }} placeholder="Tìm Kiếm HAWB" />
                        <input type="text" value={searchMawb} onChange={(e) => setSearchMawb(e.target.value)} style={{ padding: 7, width: "100%", border: "none", borderRadius: 5, outline: "none" }} placeholder="Tìm Kiếm MAWB" />
                        <input type="text" value={searchFlight} onChange={(e) => setSearchFlight(e.target.value)} style={{ padding: 7, width: "100%", border: "none", borderRadius: 5, outline: "none" }} placeholder="Chuyến Bay" /> */}
                    </div>
                    <div className="good-morning-shipment">

                        {currentShipment.map((s, index) => (
                            <div className={s.select ? "good-morning-shipment-content1" : "good-morning-shipment-content"} style={{ marginLeft: 1, padding: 3, borderBottom: "1px solid white" }}>
                                <div className="skydart-bill-checkbox-header" style={{ border: "none" }}>
                                    <input type="checkbox" id="selectAll" checked={s.select} onChange={e => {
                                        let value = e.target.checked;
                                        setShipments(shipments.map(sd => {
                                            if (sd.hawb === s.hawb) {
                                                sd.select = value;
                                            }
                                            return sd;
                                        }))
                                    }} />
                                </div>
                                {s.type === "WPX" && <Package size={23} style={{ marginLeft: 5 }} />}
                                {s.type === "DOX" && <HiDocument size={23} style={{ marginLeft: 5 }} />}
                                <h5 style={{ width: "35%", fontSize: 11, marginLeft: 7 }}>{s.hawb}</h5>
                                <h5 style={{ width: "20%", fontSize: 11 }}>{s.airline}</h5>
                                <h5 style={{ fontSize: 11 }}>{s.mawb}</h5>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}
