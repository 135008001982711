import React, { useState, useEffect, useRef } from 'react'
import './Login.css';
import { useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import { auth } from './firebase';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import validator from 'validator';
import { AiOutlineMail, RiLockPasswordLine, FcSupport, VscSignIn, VscDebugRestart } from "react-icons/all"
import { ArrowDropDown, ChevronRight, Clear, ContactSupportTwoTone, Language, Warning } from '@material-ui/icons';
export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectLang, setSelectLang] = useState(false);
    const history = useHistory("");
    const signIn = () => {
        setLoading(true);
        auth
            .signInWithEmailAndPassword(email, password)
            .then((auth) => {
                setLoading(false);
                history.push("/");
            })
            .catch(error => {
                setError(true);
                setLoading(false);
            });
    }
    const logIn = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            signIn();
            setLoading(false);
        }, 1200)
    }
    function createAccount() {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 500);
        setTimeout(function () { history.push("/registration") }, 500);
    }

    function upgradeAccount() {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 500);
    }
    function forgotPassword() {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 500);
    }
    const customersType = [
        {
            type: "Individual Account",
        },
        {
            type: "Business Account",
        },
    ];
    const renderLang = () => {
        setSelectLang(true);
    }
    return (
        <>
            <div className="login">
                {loading && <div className="loading-screen">
                    <div className="loading-circle">
                        <CircularProgress fontSize="small" />
                    </div>
                </div>}
                {error && <div className="loading-screen">
                    <div className="info-alert">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Warning fontSize="large" style={{ color: "red" }} />
                            <div>
                                <p style={{ fontWeight: 600, marginLeft: "5px" }}>Invalid Sign In Information</p>
                                <p style={{ fontWeight: 600, marginLeft: "5px" }}>Wrong Email Or Password</p>
                            </div>
                        </div>
                        <Button variant="contained" type="button" startIcon={<FcSupport />} className="retry-btn" onClick={() => setError(false)}>Try Again</Button>
                    </div>
                </div>}
                <div className="login__container">
                    <div className="login__header">
                        {/* <img src="https://i.imgur.com/L6PnhXq.png" alt="logo" />
                        <h5>Skydart Express</h5> */}
                        <h3 style={{ fontSize: 18 }}>Sign In With Your Skydart Account</h3>
                    </div>
                    <form className="login__info" onSubmit={logIn}>
                        <TextField
                            className="login-text-field"
                            label="Email Address"
                            variant="outlined"
                            value={email}
                            size="small"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            className="login-text-field"
                            label="Password"
                            size="small"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"
                            type="password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <RiLockPasswordLine fontSize="large" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="sign-in-footer">
                            <button type="submit" className="login-btn1">Sign In</button>
                            <button type="button" className="sign-up-btn" onClick={createAccount}>Create an acount</button>
                        </div>
                    </form>
                </div>

            </div>
            <div className="login-phone">
                <div className="login-phone-container">
                    <div className="login-phone-header">
                        <img src="http://skydart.com.vn/vnt_upload/weblink/logo.png" alt="skd-logo-phone" />
                    </div>
                    <div className="login-phone-body">
                        <div className="login-phone-body-content">
                            <input type="text" value={email} placeholder="Type in your email address / username" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="login-phone-body-content">
                            <input type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="login-phone-body-content">
                            <button type="button" onClick={logIn}>Login</button>
                            {/* <p onClick={forGotPW}>Forgot Account ?</p>
                            <h5 onClick={createAccount}>Create An Account</h5> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}