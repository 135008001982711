import React, { useState, useEffect, useRef } from 'react'
import "./Packing.css";
import ReactToExcel from "react-html-table-to-excel";
import axios from "axios";
export default function Packing({ userData }) {
    const [loading, setLoading] = useState(false);
    const [packs, setPacks] = useState([]);
    const [flights, setFlights] = useState([]);
    const [displayFlight, setDisplayFlight] = useState(false);
    const [input, setInput] = useState("");
    const [mawbInput, setMawbInput] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [displayHawb, setDisplayHawb] = useState(false);
    const [displaySin, setDisplaySin] = useState(false);
    const [displayWeight, setDisplayWeight] = useState(false);
    const [displayExactWeight, setDisplayExactWeight] = useState(false);
    const [displayDescription, setDisplayDescription] = useState(false);
    const [displaySpecial, setDisplaySpecial] = useState(false);
    const [displayPackedBy, setDisplayPackedBy] = useState(false);
    const [fileName, setFileName] = useState("");
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${year}-${month}-${date}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, []);
    useEffect(() => {
        axios.get("https://test.skydart.com.vn/api/flight/getMawb").then((res) => {
            setFlights(res.data);
        });
    }, []);
    let flightRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!flightRef.current.contains(e.target)) {
                setDisplayFlight(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, []);
    const refresh = () => {
        setLoading(true);
        axios.get(`https://test.skydart.com.vn/api/packing/${mawbInput}`).then((snapshot) => {
            let data = snapshot.data;
            setPacks(data.map((s, i) => {
                return {
                    hawb: s.hawb,
                    sin: s.sin,
                    description: s.description,
                    chargeable: s.chargeable,
                    pack: s.pack,
                    mawb: s.mawb,
                    airline: s.airline,
                    special: s.special.toString(),
                    packedBy: s.packedBy,
                    exactWeight: s.exactWeight,
                    pieceID: s.pieceID,
                }
            }));
            setLoading(false);
        }).catch(err => {
            alert(err);
        })
    }
    useEffect(() => {
        if (mawbInput !== "") {
            refresh();
        }
    }, [mawbInput])

    const bagLoad = packs.reduce((res, s) => {
        const key = `${s.pack}`;
        res[key] = res[key] || {
            hawb: s.hawb,
            sin: "",
            weight: 0,
            exactWeight: 0,
            description: "",
            bag: s.pack,
            special: "",
            packedBy: "",

        }
        res[key].sin = (res[key].sin + "|" + s.sin + "|");
        res[key].hawb = (res[key].hawb + "|" + s.hawb + "|");
        res[key].description = (res[key].description + "|" + s.description + "|");
        res[key].weight = (res[key].weight + "|" + s.chargeable + "|");
        res[key].special = (res[key].special + "|" + s.special + "|");
        res[key].exactWeight = (res[key].exactWeight + "|" + s.exactWeight + "|");
        res[key].packedBy = (res[key].packedBy + "|" + s.packedBy + "|");
        return res;
    }, {});
    const bagLoadExport = Object.values(bagLoad).sort((a, b) => {
        return a.bag - b.bag;
    })

    const finalBag = bagLoadExport.map((s) => {
        let hawbArray = s.hawb.split(/[|]+/);
        let sinArray = s.sin.split(/[|]+/);
        let weightArray = s.weight.split(/[|]+/);
        let exactWeightArray = s.exactWeight.split(/[|]+/);
        let packArray = s.packedBy.split(/[|]+/);
        let descArray = s.description.split(/[|]+/);
        let specialArray = s.special.split(/[|]+/);
        let total = weightArray.reduce((a, v) => a = +a + +v, 0);
        return {
            total: total,
            awb: hawbArray.slice(0, -1),
            sin: sinArray.slice(0, -1),
            special: specialArray.slice(0, -1),
            bag: s.bag,
            weight: weightArray.slice(0, -1),
            packedBy: packArray.slice(0, -1),
            exactWeight: exactWeightArray.slice(0, -1),
            desc: descArray.slice(0, -1),
        }
    });
    const shipment = packs.filter((s, i) => {
        return packs.map((val) => val.hawb).indexOf(s.hawb) == i;
    })
    const totalWeight = packs.reduce((a, v) => a = +a + +v.chargeable, 0);

    const sortBag = finalBag.sort((a, b) => {
        a = a.bag || "";
        b = b.bag || "";
        return a.localeCompare(b);
    })


    return (
        <div className="skydart-packing">
            <div className="skydart-packing-container">
                <form className="skydart-packing-text-field" onSubmit={(e) => {
                    e.preventDefault();
                    setMawbInput(input);
                    setDisplayFlight(false);
                }}>
                    <h3>Please select or enter the MAWB number to access the load</h3>
                    <div className="skydart-packing-input-container" ref={flightRef}>
                        <input type="text" required value={input} onClick={() => setDisplayFlight(true)} onChange={(e) => setInput(e.target.value)} placeholder="Enter or select MAWB" />
                        <button type="submit">Get load</button>
                        {displayFlight && <div className="skydart-packing-flight">
                            {flights.filter((s) => {
                                if (s.mawb.toLowerCase().startsWith(input.toLowerCase())) {
                                    return s;
                                }
                            }).map((s, index) => (
                                <div className="skydart-packing-flight-option" onClick={() => {
                                    setInput(s.mawb);
                                    setDisplayFlight(false);
                                    setMawbInput(s.mawb);
                                }}>
                                    <p style={{ fontSize: 11 }}>{s.flight}</p>
                                    <h5 style={{ fontWeight: 600, fontSize: 15, marginTop: 2 }}>{s.mawb}</h5>
                                </div>
                            ))}
                        </div>}
                        {loading && <p style={{ marginLeft: 10, marginTop: 7, fontWeight: 600, fontSize: 13 }}>Loading</p>}
                    </div>
                </form>
                {packs.length !== 0 && <div className="skydart-packing-content">
                    <div className="skydart-packing-sidebar">
                        <div className="skydart-packing-sidebar-info">
                            <h3>MAWB Number</h3>
                            <h3>{mawbInput}</h3>
                        </div>
                        <div className="skydart-packing-sidebar-info">
                            <h3>Pieces amount</h3>
                            <h3>{packs.length} pcs</h3>
                        </div>
                        <div className="skydart-packing-sidebar-info">
                            <h3>Shipment amount</h3>
                            <h3 style={{ fontSize: 19, textDecoration: "underline" }}>{shipment.length} shipments</h3>
                        </div>
                        <div className="skydart-packing-sidebar-info">
                            <h3>Total weight</h3>
                            <h3 style={{ fontSize: 13, textDecoration: "underline", color: "red", fontWeight: 700 }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)} KG</h3>
                        </div>
                        <div className="skydart-packing-sidebar-info">
                            <ReactToExcel className="shipment-bag-export" table="bag-load-export" filename={fileName || `Packing list from ${traceTime}`} sheet="sheet" buttonText="Export packing list" />
                        </div>
                    </div>
                    <div className="skydart-packing-show-bag">
                        <div className="skydart-packing-show-bag-header">
                            <p>Export option</p>
                        </div>
                        <div className="skydart-packing-show-bag-body">
                            <p style={{ fontSize: 11 }}>File name</p>
                            <input type="text" placeholder="Enter file name to export" value={fileName} onChange={(e) => setFileName(e.target.value)} />
                            <p style={{ borderBottom: "1px solid lightgray", marginLeft: 1, fontSize: 11, fontWeight: 600, color: "gray" }}>by default, the file name will be (Packing list from {new Date(traceTime).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })})</p>
                        </div>
                        <div className="skydart-packing-show-bag-header">
                            <p>Column shown</p>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>SKD AWB</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplayHawb(true)}>
                                    <input type="radio" id="yes-skd-awb" name="skd_awb_group" checked={displayHawb ? true : false} />
                                    <label for="yes-skd-awb">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplayHawb(false)}>
                                    <input type="radio" id="no-skd-awb" name="skd_awb_group" checked={!displayHawb ? true : false} />
                                    <label for="no-skd-awb">no</label>
                                </div>
                            </div>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>INTL AWB</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplaySin(true)}>
                                    <input type="radio" id="yes-sin-awb" name="sin_awb_group" checked={displaySin ? true : false} />
                                    <label for="yes-sin-awb">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplaySin(false)}>
                                    <input type="radio" id="no-sin-awb" name="sin_awb_group" checked={!displaySin ? true : false} />
                                    <label for="no-sin-awb">no</label>
                                </div>
                            </div>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>WEIGHT</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplayWeight(true)}>
                                    <input type="radio" id="yes-weight" name="weight_group" checked={displayWeight ? true : false} />
                                    <label for="yes-weight">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplayWeight(false)}>
                                    <input type="radio" id="no-weight" name="weight_group" checked={!displayWeight ? true : false} />
                                    <label for="no-weight">no</label>
                                </div>
                            </div>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>EXACT WEIGHT</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplayExactWeight(true)}>
                                    <input type="radio" id="yes-exactWeight" name="exactWeight_group" checked={displayExactWeight ? true : false} />
                                    <label for="yes-exactWeight">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplayExactWeight(false)}>
                                    <input type="radio" id="no-exactWeight" name="exactWeight_group" checked={!displayExactWeight ? true : false} />
                                    <label for="no-exactWeight">no</label>
                                </div>
                            </div>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>PACK - PERSON</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplayPackedBy(true)}>
                                    <input type="radio" id="yes-packedBy" name="packed_group" checked={displayPackedBy ? true : false} />
                                    <label for="yes-packedBy">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplayPackedBy(false)}>
                                    <input type="radio" id="no-packedBy" name="packed_group" checked={!displayPackedBy ? true : false} />
                                    <label for="no-packedBy">no</label>
                                </div>
                            </div>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>Description</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplayDescription(true)}>
                                    <input type="radio" id="yes-desc" name="desc_group" checked={displayDescription ? true : false} />
                                    <label for="yes-desc">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplayDescription(false)}>
                                    <input type="radio" id="no-desc" name="desc_group" checked={!displayDescription ? true : false} />
                                    <label for="no-desc">no</label>
                                </div>
                            </div>
                        </div>
                        <div className="skydart-packing-show-bag-option">
                            <p>Special</p>
                            <div className="skydart-packing-radio">
                                <div className='skydart-packing-radio-left' onClick={() => setDisplaySpecial(true)}>
                                    <input type="radio" id="yes-special" name="special_group" checked={displaySpecial ? true : false} />
                                    <label for="yes-special">yes</label>
                                </div>
                                <div className="skydart-packing-radio-left" onClick={() => setDisplaySpecial(false)}>
                                    <input type="radio" id="no-special" name="special_group" checked={!displaySpecial ? true : false} />
                                    <label for="no-special">no</label>
                                </div>
                            </div>
                        </div>
                        <table className="skd-user-info-export-excel-table" id="bag-load-export" style={{ background: "white", width: 500 }}>
                            <thead className="skd-user-info-export-excel-table-info">
                                <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <td style={{ fontSize: 30, marginLeft: 50, textAlign: "center", fontWeight: 600, color: "darkblue", width: 280, marginBottom: 5, marginLeft: 10 }}>SKYDART EXPRESS</td>
                                    <td style={{ fontSize: 25, textAlign: "center", fontWeight: 450, color: "rgb(55,55,55)", marginBottom: 5, marginLeft: 10 }}></td>
                                    <td style={{ fontSize: 25, textAlign: "center", fontWeight: 600, color: "rgb(55,55,55)", width: 350, marginBottom: 10 }}>PACKING LIST - BAG LIST - BAG MANIFEST</td>
                                </tr>
                                <tr className="skd-user-info-export-excel-table-info-container" style={{ marginTop: 5, marginBottom: 5, background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <td style={{ fontSize: 18, fontWeight: 600, marginRight: 30, marginLeft: 10 }}>Load Number: {mawbInput}</td>
                                    <td style={{ fontSize: 18, fontWeight: 600, textDecoration: "underline", width: 180 }}>Flight: {mawbInput}</td>
                                    <td style={{ fontSize: 18, fontWeight: 600, marginLeft: 10, textAlign: "center" }}>ETD: {mawbInput} - ETA: {mawbInput}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {sortBag.map((s, index) => (
                                    <tr>
                                        <tr>
                                            <tr style={{ background: "rgb(242,242,242)" }}>
                                                <td></td>
                                                <td></td>
                                                <td style={{ fontSize: 20, fontWeight: 600, marginLeft: 10, textAlign: "center" }}>BAG NO #{s.bag}</td>
                                                <td style={{ fontSize: 16, fontWeight: 600, marginLeft: 10, textAlign: "center" }}>TOTAL</td>
                                            </tr>
                                            {displayHawb && <tr>
                                                {displayHawb && <td style={{ fontSize: 18, border: "1px solid" }}>HAWB no</td>}
                                                {s.awb.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displayHawb && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                            </tr>}
                                            {displaySin && <tr>
                                                {displaySin && <td style={{ fontSize: 18, border: "1px solid" }}>INTL awb</td>}
                                                {s.sin.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displaySin && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                            </tr>}
                                            {displayWeight && <tr>
                                                {displayWeight && <td style={{ fontSize: 18, border: "1px solid" }}>KG</td>}
                                                {s.weight.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displayWeight && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                                {displayWeight && <td style={{ fontSize: 20, fontWeight: 600, color: "red", textAlign: "center", border: "1px solid" }}>{(Math.round(s.total * 100) / 100).toFixed(2)}</td>}
                                            </tr>}
                                            {displayExactWeight && <tr>
                                                {displayExactWeight && <td style={{ fontSize: 18, border: "1px solid" }}>KG</td>}
                                                {s.exactWeight.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displayExactWeight && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                                {displayExactWeight && <td style={{ fontSize: 20, fontWeight: 600, color: "red", textAlign: "center", border: "1px solid" }}>{(Math.round(s.total * 100) / 100).toFixed(2)}</td>}
                                            </tr>}
                                            {displayPackedBy && <tr>
                                                {s.packedBy.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displayPackedBy && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                            </tr>}
                                            {displaySpecial && <tr>
                                                {displaySpecial && <td style={{ fontSize: 18, border: "1px solid" }}>Special</td>}
                                                {s.special.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displaySpecial && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                            </tr>}
                                            {displayDescription && <tr>
                                                {displayDescription && <td style={{ fontSize: 18, border: "1px solid" }}>ITEM</td>}
                                                {s.desc.map((item, index) => (
                                                    <>
                                                        {index !== 0 && displayDescription && <td style={{ fontSize: 18, textAlign: "center", border: "1px solid" }}>{item}</td>}
                                                    </>
                                                ))}
                                            </tr>}
                                        </tr>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
        </div>
    )
}
