import { ClassOutlined, Edit, HomeOutlined, Payment, PaymentSharp, Settings, SettingsOutlined, TrackChangesOutlined } from '@material-ui/icons';
import React from 'react'
import { useEffect } from 'react';
import { Package } from 'react-feather';
import { IoPricetagOutline } from "react-icons/io5";
import { CgLogOut } from 'react-icons/cg';
import { MdPolicy } from "react-icons/md";
import { FiBarChart2 } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { BsUpload } from "react-icons/bs";
import { GrHomeRounded } from 'react-icons/gr';
import { IoHomeOutline } from 'react-icons/io5';
import happynewyear from "./happynewyear.jpg";
import { NavLink, useHistory } from "react-router-dom";
import { auth } from "../firebase";
import "./customer.css";
export default function NavBar() {
    const history = useHistory();
    const getLabel = () => {
        history.push("/label");
    }
    return (
        <div className="skd-navbar">
            <div className="skd-navbar-img">
                <a href="http://skydart.com.vn/" target="_blank"><img src="https://i.imgur.com/L6PnhXq.png" alt="logo" width="37" /></a>
                <h3>Skydart Express</h3>
            </div>
            <div className="skd-navbar-option">
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/">
                    <IoHomeOutline size={20} />
                    <p>Home</p>
                </NavLink>
                {/* <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/overview">
                    <FiBarChart2 size={20}/>
                    <p className="hover-text">Account Overview</p>
                </NavLink> */}
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/booking">
                    <Package size={20} />
                    <p>Book shipment</p>
                </NavLink>
                {/* <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/upload">
                    <BsUpload size={20} />
                    <p>Upload shipment</p>
                </NavLink> */}
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/draft">
                    <Edit style={{ fontSize: 20 }} />
                    <p>Draft booking</p>
                </NavLink>
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/labels">
                    <ClassOutlined style={{ fontSize: 20 }} />
                    <p>Label</p>
                </NavLink>
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/tracking">
                    <TrackChangesOutlined style={{ fontSize: 20 }} />
                    <p>Track shipment</p>
                </NavLink>
                {/* <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/booking">
                    <Payment style={{ fontSize: 20 }} />
                    <p>Payment (Beta)</p>
                </NavLink>
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/booking">
                    <IoPricetagOutline size={20} />
                    <p>Pricing (Beta)</p>
                </NavLink> */}

            </div>
            <div className="skd-navbar-option1">
                <NavLink className="skd-nav-link" activeClassName="skd-nav-active" exact to="/setting">
                    <SettingsOutlined style={{ fontSize: 20 }} />
                    <p>Setting</p>
                </NavLink>
                <div className="skd-nav-link" onClick={() => { auth.signOut(); history.push("/") }}>
                    <CgLogOut size={20} />
                    <p>Sign out</p>
                </div>
            </div>
        </div>
    )
}
