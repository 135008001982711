
import React, { useState, useEffect, useRef } from 'react'
import "./customer.css";
import { useHistory } from "react-router-dom";
import { Snackbar } from '@material-ui/core';
import { IoBuildOutline, GrStakeholder, MdOutlineLayersClear, TiExportOutline, IoMdCheckmarkCircleOutline } from "react-icons/all"
import { Add, ArrowDownward, Check, ArrowLeft, ArrowRight, LocalShippingOutlined, GraphicEqOutlined, PanTool, CallReceived, ArrowRightAlt, Refresh, Clear, Crop, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, FileCopySharp, MoreHoriz, ArrowDropDown, Delete } from '@material-ui/icons';
import { Command, Copy, File, Filter, Package } from 'react-feather';
import { CircularProgress, LinearProgress, IconButton, Button, Checkbox } from '@material-ui/core';
import { db } from '../firebase';
import { useReactToPrint } from 'react-to-print';
import StaffBill2 from "./StaffBill2";
import { CSVLink } from "react-csv";
import MuiAlert from '@material-ui/lab/Alert';
import TracingContainer from './TracingContainer';
import { GoCalendar } from "react-icons/go";
import Inv2 from './Inv2';
import ShippingMark from "./ShippingMark";
import HoldReason from './HoldReason';
import nghitet from "./nghitet.jpg";
import nammoi from "./nammoi.jpg";
import Waybill from './Waybill';
import Month1 from './Month1';
import { AiOutlineMinus } from 'react-icons/ai';
import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import DatepickerContext from './datepickerContext';
// import { IconButton, Checkbox, CircularProgress } from '@material-ui/core';
import { BsSearch } from "react-icons/bs";
import { FiRefreshCw } from "react-icons/fi";
import { AiOutlineMore } from "react-icons/ai";
import { BsBoxSeam, BsTrash, BsBox } from "react-icons/bs";
import { GrHostMaintenance } from "react-icons/gr";
import { AiOutlineStar } from "react-icons/ai";
import { BsFillReplyAllFill } from "react-icons/bs";
import { MdLabelImportantOutline } from "react-icons/md";
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineLabel } from "react-icons/md";
import Draft from './Draft';
export default function DashBoard({ user, userData }) {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? "0" : "");
    let last = firstDayOfCurrentWeek + 6;
    let firstday = firstDayOfCurrentWeek - 6;
    let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
    let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
    let yesterday = date - 1;
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    let lastMonth = month - 1;
    let year = newDate.getFullYear();
    let lastDay = new Date(year, lastMonth, 0);
    let firstDayOfYear = new Date(year, 0, 1);
    let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
    let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
    const monthName = months[newDate.getMonth()];
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? "0" : "") + firstDay.getDate();
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const seven = date - 7;
    const [status, setStatus] = useState("");
    const [track, setTrack] = useState("");
    const [search, setSearch] = useState("");
    const [searchCountry, setSearchCountry] = useState("");
    const fixedStart = `${year}-${month}-${first}`;
    const fixedEnd = new Date().toISOString().substr(0, 10);
    const recent7 = `${year}-${month}-${seven}`;
    const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
    const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
    const [displayExport, setDisplayExport] = useState(false);
    const [displayExportOption, setDisplayExportOption] = useState(false);
    const [displayExportAll, setDisplayExportAll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [shipment, setShipment] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState([]);
    const [renderPrint, setRenderPrint] = useState(false);
    const [excelFileName, setExcelFileName] = useState("");
    const [displayHold, setDisplayHold] = useState(false);
    const [selectTracing, setSelectTracing] = useState([]);
    const [selectPrint, setSelectPrint] = useState([]);
    const [sorted, setSorted] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageNumberLimit, setPageNumberLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertShipment, setAlertShipment] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [displayCountry, setDisplayCountry] = useState("");
    const [searchCode, setSearchCode] = useState("");
    const [displayDate, setDisplayDate] = useState(false);
    const [dateStatus, setDateStatus] = useState("Period");
    const [displaySearch, setDisplaySearch] = useState(false);
    const [newYear, setNewYear] = useState(false);
    const [viewAs, setViewAs] = useState("All");
    const [users, setUsers] = useState([]);
    const [searchDraft, setSearchDraft] = useState("");
   
   
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE
    });
    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths
    } = useDatepicker({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        focusedInput: dateRange.focusedInput,
        onDatesChange: handleDateChange
    });
    function handleDateChange(data) {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE });
        } else {
            setDateRange(data);
        }
    }
    useEffect(() => {
        setTimeout(() => {
            setNewYear(true);
        }, 500)
    }, [])
    const handleCancelDate = () => {
        setDisplayDate(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const d = new Date(dateRange.startDate);
    const selectMonth = ((d.getMonth() + 1) < 10 ? "0" : "") + (d.getMonth() + 1);
    const selectDate = (d.getDate() < 10 ? "0" : "") + d.getDate();
    const selectYear = d.getFullYear();


    const e = new Date(dateRange.endDate);
    const selectEndMonth = ((e.getMonth() + 1) < 10 ? "0" : "") + (e.getMonth() + 1);
    const selectEndDate = (e.getDate() < 10 ? "0" : "") + e.getDate();
    const selectEndYear = e.getFullYear();
    const currentYear = new Date().getFullYear();
    const handleApplyDate = () => {
        setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
        setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
        setDisplayDate(false);
        setDateStatus("Period");
    }
    const handleToday = () => {
        setStartDate(`${year}-${month}-${date}`)
        setEndDate(`${year}-${month}-${date}`)
        setDisplayDate(false);
        setDateStatus("Today");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleYesterday = () => {
        if (yesterday === 0) {
            setStartDate(`${year}-${prevMonth}-${lastDay.getDate() < 10 ? "0" : ""}`);
            setEndDate(`${year}-${prevMonth}-${lastDay.getDate() < 10 ? "0" : ""}`);
            setDisplayDate(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        } else {
            setStartDate(`${year}-${month}-${yesterday}`);
            setEndDate(`${year}-${month}-${yesterday}`);
            setDisplayDate(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        }
    }
    const handleThisWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
        setDisplayDate(false);
        setDateStatus("This week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
        setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setDisplayDate(false);
        setDateStatus("Last week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }

    const handleThisMonth = () => {
        setStartDate(`${year}-${month}-${first}`)
        setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split("T")[0]);
        setDisplayDate(false);
        setDateStatus("This month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastMonth = () => {
        setStartDate(`${year}-${prevMonth}-01`)
        setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
        setDisplayDate(false);
        setDateStatus("Last month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleThisYear = () => {
        setStartDate(`${year}-01-01`);
        setEndDate(`${year}-12-31`);
        setDateStatus("This year");
        setDisplayDate(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const history = useHistory();
    function resetSuccess(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertSuccess(false);
    }
 


    function resetShipment(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertShipment(false);
    }
    useEffect(() => {
        refreshShipment();
        // if (userData.proxyPlanDate === startDate) {
        //     db.collection("users").doc(userData.email).update({
        //         plan: userData.proxyPlan,
        //     })
        // } else {
        //     return;
        // }
        // if (userData.proxyControllerData === startDate) {
        //     db.collection("users").doc(userData.email).update({
        //         controller: userData.proxyController,
        //     })
        // } else {
        //     return;
        // }
    }, [])
    const refreshShipment = () => {
        setLoading(true);
        db.collection("shipments").orderBy("timestamp", "desc").where("fromEmail", "==", user.email).get().then((snapshot) => {
            let data = snapshot.docs.map(doc => doc.data());
            setShipment(data.map((s) => {
                return {
                    select: false,
                    hawb: s.hawb,
                    fromCity: s.fromCity,
                    perform: s.perform,
                    createAt: s.createAt,
                    pickupAt: s.pickupAt,
                    toCountry: s.toCountry,
                    toTown: s.toTown,
                    toCity: s.toCity,
                    shipmentType: s.shipmentType,
                    totalCharge: s.totalCharge,
                    mst: s.mst,
                    reweight: s.reweight,
                    totalPackage: s.totalPackage,
                    status: s.status,
                    totalItemValue: s.totalItemValue,
                    totalInvoiceValue: s.totalInvoiceValue,
                    description: s.description,
                    code: s.code,
                    time: s.time,
                    exporter: s.exporter,
                    eori: s.eori,
                    fromAddress: s.fromAddress,
                    fromAddress2: s.fromAddress2,
                    fromAddress3: s.fromAddress3,
                    fromState: s.fromState,
                    fromTown: s.fromTown,
                    fromCity: s.fromCity,
                    fromCountry: s.fromCountry,
                    fromPostal: s.fromPostal,
                    fromName: s.fromName,
                    fromEmail: s.fromEmail,
                    importer: s.importer,
                    toAddress: s.toAddress,
                    toAddress2: s.toAddress2,
                    toAddress3: s.toAddress3,
                    toCity: s.toCity,
                    toState: s.toState,
                    toTown: s.toTown,
                    toPostal: s.toPostal,
                    sin: s.sin,
                    toCountry: s.toCountry,
                    toPhone: s.toPhone,
                    toName: s.toName,
                    toEmail: s.toEmail,
                    weight: s.weight,
                    pickupBy: s.pickupBy,
                    invoiceValue: s.invoiceValue,
                    transportation: s.transportation,
                    service: s.service,
                    note: s.note,
                    usage: s.usage,
                    packageValue: s.packageValue,
                }
            }))
            setLoading(false);
        })
    }
    let labelRef = useRef();
    let invoiceRef = useRef();
    let shippingMarkRef = useRef();
    let printAllRef = useRef();
    let exportRef = useRef();
    let countryRef = useRef();
    let dateRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!dateRef.current.contains(e.target)) {
                setDisplayDate(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    const printLabel = useReactToPrint({
        content: () => labelRef.current,
    })
    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
    })
    const printShippingMark = useReactToPrint({
        content: () => shippingMarkRef.current,
    })
    const printAll = useReactToPrint({
        content: () => printAllRef.current,
    })

    const finalShipment = shipment.filter((s) => {
        return s.createAt >= startDate && s.createAt <= endDate &&
            s.perform !== "Draft" &&
            s.hawb.toLowerCase().includes(search.toLowerCase()) &&
            s.status.toLowerCase().includes(status.toLowerCase()) &&
            s.toCountry.toLowerCase().startsWith(searchCountry.toLowerCase());
    })
    const sortedCountry1 = [...new Set(finalShipment.map(s => s.toCountry))];
    const sortedCountry = sortedCountry1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const sortedCode = [...new Set(finalShipment.map(s => s.code))];
    const pages = [];
    for (let i = 1; i <= Math.ceil(finalShipment.length / itemPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = finalShipment.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (e) => {
        setCurrentPage(Number(e.target.id));
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    const handleMorePage = () => {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    const handleLessPage = () => {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageLimit + 1 && number > minPageLimit) {
            return (
                <li key={number} id={number} onClick={handleChangePage} className={currentPage == number ? "active" : null}>
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })
    const holdShipment = finalShipment.filter((s) => {
        return s.status === "On Hold";
    })
    const pickupShipment = finalShipment.filter((s) => {
        return s.status === "Picked Up";
    })
    const arrivedShipment = finalShipment.filter((s) => {
        return s.status === "Arrived At Skydart Origin Depot";
    })
    const processShipment = finalShipment.filter((s) => {
        return s.status === "In Process";
    })
    const clearanceShipment = finalShipment.filter((s) => {
        return s.status === "In Clearance";
    })
    const transitShipment = finalShipment.filter((s) => {
        return s.status === "In Transit";
    })
    const deliveredShipment = finalShipment.filter((s) => {
        return s.status === "Delivered";
    })
    const totalWeight = finalShipment.reduce((a, v) => a = +a + +v.totalCharge, 0);
    const totalPieces = finalShipment.reduce((a, v) => a = +a + +v.totalPackage, 0);
    const totalReweight = finalShipment.reduce((a, v) => a = +a + +v.reweight, 0);

    const exportSelected = shipment.filter((s) => {
        return s.select;
    })
    const headers = [
        { label: "SKD HAWB", key: "hawb" },
        { label: "DATE", key: "createAt" },
        { label: "FROM", key: "fromCity" },
        { label: "SHIP TO", key: "toCountry" },
        { label: "TYPE", key: "shipmentType" },
        { label: "KG", key: "totalCharge" },
        { label: "RW", key: "reweight" },
        { label: "PCS", key: "totalPackage" },
        { label: "Value", key: "totalInvoiceValue" },
        { label: "Description", key: "description" },
    ]
    const exportShipment = {
        data: exportSelected,
        headers: headers,
        filename: excelFileName || `${userData.exporter} shipment management`,
    }
    const exportAll = {
        data: shipment,
        headers: headers,
        filename: excelFileName || `${userData.exporter} shipment management`,
    }

    const editPrint = () => {
        setRenderPrint(true);
        setSelectPrint(exportSelected);
    }
    const getShipmentDate = shipment.filter((s) => {

    })
    const requestShipment = () => {

    }
    const trackShipment = (e) => {
        e.preventDefault();
        history.push(`/shipment/tracking/${track}`);
    }
    const getPending = shipment.filter((s) => {
        return s.status === "Pending";
    })
    const getLastMonth = getPending.filter((s) => {
        let thisMonth = s.createAt.slice(-2);
        let lastMonth = s.createAt.slice(-2) - 10;
    })
    const convertThoudsand = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
    }
    const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return (
        <>
            {/* {newYear && <div className="skd-new-year">
                <div className="skd-new-year-container">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <img src={nammoi} alt="" />
                        <img src={nghitet} alt="" />
                    </div>
                    <div className="skd-new-year-clear" style={{ display: "flex", marginLeft: 10 }}>
                        <IconButton onClick={() => setNewYear(false)} type="button" style={{ background: "white", marginBottom: 15, marginLeft: "auto" }}><Clear fontSize="large" /></IconButton>
                    </div>
                </div>
            </div>} */}
            <div ref={viewAs === "All" ? null : dateRef}></div>
            {viewAs === "All" && <div className="skd-dash-board">
                <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
                    <MuiAlert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
                        Update Succeed
                    </MuiAlert>
                </Snackbar>
                <Snackbar open={alertShipment} autoHideDuration={3000} onClose={resetShipment}>
                    <MuiAlert elevation={5} variant="filled" onClose={resetShipment} severity="error">
                        <p>{errMessage}</p>
                    </MuiAlert>
                </Snackbar>
                {displayHold && <div className="shipment-note" style={{ overflowY: 'scroll', overflowX: "hidden" }}>
                    <div className="shipment-note-container" style={{ marginTop: "15%", marginBottom: 70 }}>
                        <div className="shipment-note-header" style={{ background: "rgb(57, 108, 190)", color: "white", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                            <div>
                                <h3>Holded shipment</h3>
                                <p style={{ fontSize: 12, fontWeight: 600 }}>Follow and update shipment for your better experience</p>
                            </div>
                            <IconButton type="button" onClick={() => setDisplayHold(false)}>
                                <Clear fontSize="small" htmlColor="white" />
                            </IconButton>
                        </div>
                        <div className="skydart-tracking-container">
                            <h5 className="tracking-status-header">There are currently {holdShipment.length} on hold</h5>
                            {holdShipment.map((s, index) => {
                                let code = s.code || "";
                                return <HoldReason hawb={s.hawb} code={code} no={index + 1} sin={s.sin} fromCity={s.fromCity} country={s.country} state={s.state} town={s.town} city={s.city} postal={s.postal} status={s.status} note={s.note} />
                            }
                            )}
                        </div>

                    </div>
                </div>}


                {renderPrint && <div className="skydart-create-print">
                    <div className="skydart-create-print-container">
                        <div className="skydart-create-print-header">
                            <h3>Printing Option</h3>
                            <IconButton type="button" style={{ width: 35, height: 35, background: "rgb(236,236,236)" }} onClick={() => { setRenderPrint(false); setSelectPrint([]) }}><Clear fontSize="small" /></IconButton>
                        </div>
                        <div className="skydart-create-print-body">
                            <div className="skydart-create-print-button" onClick={printLabel}>
                                <h5>Print Label</h5>
                            </div>
                            <div className="skydart-create-print-button" onClick={printInvoice}>
                                <h5>Print Invoice</h5>
                            </div>
                            <div className="skydart-create-print-button" onClick={printShippingMark}>
                                <h5>Print Shipping Mark</h5>
                            </div>
                            <div className="skydart-create-print-button" onClick={printAll}>
                                <h5>Print All</h5>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* <div className="skd-dash-board-header">
                <div className="skd-dash-board-header-left">
                    <form className="skd-dash-board-track" onSubmit={trackShipment}>
                        <input type="text" value={track} onChange={(e) => setTrack(e.target.value)} placeholder="Track a shipment here" />
                        <ArrowForward fontSize="small" style={{ marginLeft: 5, cursor: 'pointer' }} onClick={trackShipment} />
                    </form>
                    <div className="skd-dash-board-hold">
                        <Warning style={{ fontSize: 18, color: "rgb(201, 72, 72)" }} />
                        <h5>{holdShipment.length} shipments on hold</h5>
                        {holdShipment.length !== 0 && <div className="skd-hold-reason" onClick={() => setDisplayHold(true)}>
                            <h5 style={{ marginLeft: 0 }}>Review hold reason</h5>
                        </div>}
                    </div>
                </div>
                <div className="skd-dash-board-header-right">
                    <div className="skd-dash-board-header-right-container">
                        <h5 style={{ fontWeight: 600, color: "rgba(0,0,0,0.55)", fontSize: 11, marginRight: 10 }}>{userData.email}</h5>
                        <div className="skd-dash-board-header-right-container-option1" style={{ padding: 5 }} onClick={refreshShipment}>
                            <Refresh fontSize="small" />
                        </div>
                        <div className="skd-dash-board-header-right-container-option" onClick={() => history.push("/booking")}>
                            <Package size={20} />
                            <h5>Book Shipment</h5>
                        </div>
                    </div>
                </div>
            </div> */}
                {/* <div className="skd-dash-board-warn">
                <div className="skd-dash-board-warn-header">
                    <GraphicEqOutlined htmlColor='#0764af' />
                    <h3>Records</h3>
                </div>
                <div className="skd-dash-board-warn-body">
                    <div className="dash-board-option">
                        <h5 style={{ fontWeight: 600 }}>Total weight:</h5>
                        <h5 style={{ color: '#0058be', marginLeft: 5 }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)} KG</h5>
                    </div>
                    <div className="dash-board-option">
                        <h5 style={{ fontWeight: 600 }}>Total reweight:</h5>
                        <h5 style={{ color: '#0058be', marginLeft: 5 }}>{(Math.round(totalReweight * 100) / 100).toFixed(2)} KG</h5>
                    </div>
                    <div className="dash-board-option">
                        <h5 style={{ fontWeight: 600 }}>Total pieces:</h5>
                        <h5 style={{ color: '#0058be', marginLeft: 5 }}>{totalPieces} PCS</h5>
                    </div>
                </div>
            </div> */}
                <div className="skd-dash-board-search">
                    <div className='skd-dash-board-search-left'>
                        <Search style={{ position: "absolute", marginLeft: 5 }} />
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search and filter..." />
                        <h5 style={{ fontWeight: 600, marginLeft: 10, fontSize: 12 }}>Found {finalShipment.length} shipments of {shipment.length} shipments</h5>
                        {exportSelected.length !== 0 && <div className="skydart-notification">
                            <h5>{exportSelected.length} Selected</h5>
                        </div>}
                    </div>
                    <div className="skd-user-shipment-header-right" ref={dateRef} style={{ marginLeft: "auto" }}>
                        <div className="skd-user-shipment-header-right-date" onClick={() => { displayDate ? setDisplayDate(false) : setDisplayDate(true) }}>
                            <GoCalendar size={20} style={{ marginRight: 10 }} />
                            <p style={{ marginRight: 7, fontSize: 13 }}>{dateStatus}:</p>
                            <p style={{ marginRight: 7, fontSize: 13 }}>{startDay}</p>
                            <AiOutlineMinus size={10} style={{ marginRight: 7 }} />
                            <p style={{ marginRight: 7, fontSize: 13 }}>{endDay}</p>
                            <ArrowDropDown style={{ fontSize: 18 }} />
                        </div>
                        {displayDate && <div className="skd-user-date" style={{ right: 10 }}>
                            <DatepickerContext.Provider
                                value={{
                                    focusedDate,
                                    isDateFocused,
                                    isDateSelected,
                                    isDateHovered,
                                    isDateBlocked,
                                    isFirstOrLastSelectedDate,
                                    onDateSelect,
                                    onDateFocus,
                                    onDateHover,
                                }}
                            >
                                <div className="skd-user-date-content">
                                    <div className="skd-user-date-left">
                                        <div className={dateStatus === "Today" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleToday}>
                                            {dateStatus === "Today" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>Today</p>
                                        </div>
                                        <div className={dateStatus === "Yesterday" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleYesterday}>
                                            {dateStatus === "Yesterday" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>Yesterday</p>
                                        </div>
                                        <div className={dateStatus === "This week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisWeek}>
                                            {dateStatus === "This week" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>This week</p>
                                        </div>
                                        <div className={dateStatus === "Last week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastWeek}>
                                            {dateStatus === "Last week" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>Last week</p>
                                        </div>
                                        <div className={dateStatus === "This month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisMonth}>
                                            {dateStatus === "This month" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>This Month</p>
                                        </div>
                                        <div className={dateStatus === "Last month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastMonth}>
                                            {dateStatus === "Last month" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>Last Month</p>
                                        </div>
                                        <div className={dateStatus === "This year" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisYear}>
                                            {dateStatus === "This year" && <Check style={{ fontSize: 15, color: "black" }} />}
                                            <p style={{ color: "black" }}>This Year</p>
                                        </div>
                                    </div>
                                    <div className="skd-user-date-right">
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "15px 0 0",
                                                gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                                                gridGap: "0 24px"
                                            }}
                                        >
                                            <div className="date-month-back" onClick={goToPreviousMonths}>
                                                <ArrowLeft />
                                            </div>
                                            <div className="date-month-back1" onClick={goToNextMonths}>
                                                <ArrowRight />
                                            </div>
                                            {activeMonths.map(month => (
                                                <Month1
                                                    key={`${month.year}-${month.month}`}
                                                    year={month.year}
                                                    month={month.month}
                                                    firstDayOfWeek={firstDayOfWeek}
                                                />
                                            ))}

                                        </div>
                                        <div className="skd-user-date-shown">
                                            <div className="skd-user-date-shown-range">
                                                <h5 style={{ fontWeight: 450 }}>{!dateRange.startDate ? "Start Date" : dateRange.startDate.toDateString()}</h5>
                                                <AiOutlineMinus />
                                                <h5 style={{ fontWeight: 450, marginLeft: 7 }}>{!dateRange.endDate ? "End Date" : dateRange.endDate.toDateString()}</h5>
                                                <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                                            </div>
                                            <div className="skd-user-date-update-button">
                                                <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                                                    <h5 style={{ color: "black", textAlign: "center" }}>Cancel</h5>
                                                </div>
                                                <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                                                    <h5 style={{ color: "white", textAlign: "center" }}>Update</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DatepickerContext.Provider>
                        </div>}
                    </div>
                </div>
                <div className="skd-dash-board-status">
                    {status === "" && <div className="skd-dash-board-status-option">
                        <Package size={23} />
                        <h5>All</h5>
                        <p className="skd-absolute">{finalShipment.length}</p>
                    </div>}
                    {status !== "" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("")}>
                        <Package size={23} />
                        <h5>All</h5>
                        <p className="skd-absolute">{finalShipment.length}</p>
                    </div>}
                    {status === "Picked Up" && <div className="skd-dash-board-status-option">
                        <LocalShippingOutlined style={{ fontSize: 23 }} />
                        <h5>Picked up</h5>
                        <p className="skd-absolute">{pickupShipment.length}</p>
                    </div>}
                    {status !== "Picked Up" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("Picked Up")}>
                        <LocalShippingOutlined style={{ fontSize: 23 }} />
                        <h5>Picked up</h5>
                        <p className="skd-absolute">{pickupShipment.length}</p>
                    </div>}
                    {status === "Arrived" && <div className="skd-dash-board-status-option">
                        <CallReceived style={{ fontSize: 23 }} />
                        <h5>Arrived </h5>
                        <p className="skd-absolute">{arrivedShipment.length}</p>
                    </div>}
                    {status !== "Arrived" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("Arrived")}>
                        <CallReceived style={{ fontSize: 23 }} />
                        <h5>Arrived</h5>
                        <p className="skd-absolute">{arrivedShipment.length}</p>
                    </div>}
                    {status === "In Process" && <div className="skd-dash-board-status-option">
                        <IoBuildOutline size={23} />
                        <h5>Process</h5>
                        <p className="skd-absolute">{processShipment.length}</p>
                    </div>}
                    {status !== "In Process" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("In Process")}>
                        <IoBuildOutline size={23} />
                        <h5>Process</h5>
                        <p className="skd-absolute">{processShipment.length}</p>
                    </div>}
                    {status === "On Hold" && <div className="skd-dash-board-status-option">
                        <PanTool style={{ fontSize: 20 }} />
                        <h5>Hold</h5>
                        <p className="skd-absolute">{holdShipment.length}</p>
                    </div>}
                    {status !== "On Hold" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("On Hold")}>
                        <PanTool style={{ fontSize: 20 }} />
                        <h5>Hold</h5>
                        <p className="skd-absolute">{holdShipment.length}</p>
                    </div>}
                    {status === "In Clearance" && <div className="skd-dash-board-status-option">
                        <MdOutlineLayersClear size={23} />
                        <h5>Clearance</h5>
                        <p className="skd-absolute">{clearanceShipment.length}</p>
                    </div>}
                    {status !== "In Clearance" && <div className='skd-dash-board-status-option1' onClick={() => setStatus("In Clearance")}>
                        <MdOutlineLayersClear size={23} />
                        <h5>Clearance</h5>
                        <p className="skd-absolute">{clearanceShipment.length}</p>
                    </div>}
                    {status === "In Transit" && <div className="skd-dash-board-status-option">
                        <TiExportOutline size={23} />
                        <h5>Transit</h5>
                        <p className="skd-absolute">{transitShipment.length}</p>
                    </div>}
                    {status !== "In Transit" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("In Transit")}>
                        <TiExportOutline size={23} />
                        <h5>Transit</h5>
                        <p className="skd-absolute">{transitShipment.length}</p>
                    </div>}
                    {status === "Delivered" && <div className="skd-dash-board-status-option2">
                        <IoMdCheckmarkCircleOutline size={23} />
                        <h5 style={{ fontWeight: 700, marginLeft: 5, fontSize: 14 }}>Delivered</h5>
                        <p className="skd-absolute">{deliveredShipment.length}</p>
                    </div>}
                    {status !== "Delivered" && <div className="skd-dash-board-status-option1" onClick={() => setStatus("Delivered")} style={{ marginRight: 17 }}>
                        <IoMdCheckmarkCircleOutline size={23} />
                        <h5 style={{ fontWeight: 700, marginLeft: 5, fontSize: 14 }}>Delivered</h5>
                        <p className="skd-absolute">{deliveredShipment.length}</p>
                    </div>}
                </div>
                <div className="skydart-shipment-tool" style={{ marginLeft: 10, marginRight: 10 }}>
                    <div className="skydart-shipment-tool-left" style={{ alignItems: "center" }}>
                        <div className="skydart-shipment-tool-option">
                            <div className="skydart-shipment-tool-create-shipment" style={{ padding: 7, borderRadius: 20, width: 55 }} onClick={() => history.push("/booking")}>
                                <Add fontSize="small" />
                                <h5>Create</h5>
                            </div>
                        </div>
                        <div className="skydart-shipment-tool-option">
                            {exportSelected.length > 0 && <div style={{ padding: 7, borderRadius: 5 }} className="skydart-shipment-tool-edit-shipment" onClick={editPrint}>
                                <Print fontSize="small" />
                                <h5 style={{ marginRight: 5 }}>Print</h5>
                            </div>}
                            {exportSelected.length === 0 && <div style={{ padding: 7, borderRadius: 5, color: "gray", cursor: "not-allowed" }} className="skydart-shipment-tool-edit-shipment">
                                <Print fontSize="small" />
                                <h5 style={{ marginRight: 5 }}>Print</h5>
                            </div>}
                        </div>
                        <div className="skydart-refresh-button1" onClick={refreshShipment} style={{ padding: 5, borderRadius: 5 }}>
                            <Refresh fontSize="small" />
                        </div>
                    </div>
                    <div className="paginate">
                        <div className="pageNumbers">
                            <IconButton type="button" disabled={currentPage == pages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage}><ArrowLeft className="prev-btn-page" /></IconButton>
                            {renderPageNumbers}
                            <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage}><ArrowRight className="prev-btn-page" /></IconButton>
                        </div>
                    </div>


                    {/* <div className="skydart-shipment-tool-right">
                    <div className="skydart-shipment-tool-right-option">
                        <h5>(Filtering 1000 / <strong style={{ fontWeight: 700, textDecoration: "underline", color: "#0068be" }}>1000</strong> Shipments from <strong style={{ fontWeight: 600, textDecoration: "underline", color: "#0068be" }}>All Shipment...</strong>)</h5>

                    </div>
                </div> */}
                    {/* <div className="paginate">
                    <div className="pageNumbers">
                        <IconButton type="button" disabled={currentPage == pages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage}><ArrowBack className="prev-btn-page" /></IconButton>
                        {renderPageNumbers}
                        <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage}><ArrowForward className="prev-btn-page" /></IconButton>
                    </div>
                </div> */}
                    {/* <Pagination shipmentPerPage={shipmentPerPageeeeeeeee}eeeeee allShipment={finalShipment.length} paginate={paginate} /> */}
                </div>
                <div className="skd-dash-fetch">
                    <div className="skd-dash-fetch-container">
                        <div className="skd-dash-fetch-header">
                            <div className="skydart-bill-checkbox-header">
                                <input type="checkbox" id="selectAll" onChange={e => {
                                    let value = e.target.checked;
                                    setSelectedShipment(finalShipment.map(d => {
                                        d.select = value;
                                        return d;
                                    }))
                                }} />
                            </div>
                            <div className="skd-bill-no-header">
                                <h5 style={{ textAlign: "center" }}>No.</h5>
                            </div>
                            <div className="skd-bill-hawb-header">
                                <h5>Waybill Number</h5>
                            </div>



                            <div className="skd-bill-time-header">
                                <h5>Booked Time</h5>
                            </div>
                            <div className="skd-bill-sin-header">
                                <h5 style={{ fontSize: 12 }}>Service</h5>
                            </div>
                            <div className="skd-bill-time-header" style={{ width: "8%" }}>
                                <h5>From</h5>
                            </div>
                            <div className="skd-bill-destination-header" ref={countryRef}>
                                <h5 style={{ marginLeft: 3, color: "black" }}>Ship To</h5>
                                {!sorted && <ArrowDropDown className="skd-bill-destination-icon" style={{ marginLeft: "auto" }} onClick={() => setSorted(true)} />}
                                {sorted && <ArrowDropUp className="skd-bill-destination-icon" style={{ marginLeft: "auto" }} onClick={() => setSorted(false)} />}
                                {displayCountry && <div className="skydart-new-booking-filter-option" style={{ marginTop: 265 }}>
                                    <form className="skydart-new-booking-filter-container" onSubmit={(e) => { e.preventDefault(); setDisplayCountry(false) }} style={{ borderBottom: "1px solid lightgray" }}>
                                        <Search fontSize="small" />
                                        <input type="text" placeholder="Search Country" value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)} />
                                        <select value={searchCode} onChange={(e) => setSearchCode(e.target.value)} style={{ padding: 5, background: "rgb(238,238,238)", cursor: "pointer", border: "none", outline: "none", borderRadius: 5 }}>
                                            <option value="">Search Code</option>
                                            {sortedCode.sort((a, b) => {
                                                return a.localeCompare(b);
                                            }).map((s) => {
                                                return <option value={s}>{s}</option>
                                            })}
                                        </select>
                                        <button className="hidden" type="submit">Submit</button>
                                    </form>
                                    <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                        {sortedCountry.filter((s) => {
                                            if (s.toLowerCase().includes(searchCountry.toLowerCase())) {
                                                return s;
                                            } else {
                                                return s;
                                            }
                                        }).map((s, index) => (
                                            <div className="sorted" onClick={() => setSearchCountry(s)}>
                                                <h5 style={{ fontSize: 10 }}>{s}</h5>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="sorted-btn">
                                        <button type="button" onClick={() => setDisplayCountry(false)}>Search</button>
                                        <h5 onClick={() => { setDisplayCountry(false); setSearchCountry("") }}>Cancel</h5>
                                    </div>
                                </div>}
                            </div>
                            <div className="skd-bill-type-header">
                                <h5>Type</h5>
                            </div>
                            <div className="skd-bill-weight-header">
                                <h5>KG</h5>
                            </div>
                            {/* <div className="skd-bill-weight-header">
                            <h5>RW</h5>
                        </div> */}
                            <div className="skd-bill-weight-header">
                                <h5>PCS</h5>
                            </div>
                            <div className="skd-bill-status-header">
                                <h5>Status</h5>
                            </div>
                            <div className="skd-bill-value-header">
                                <h5>Value</h5>
                            </div>
                        </div>

                        {shipment.length !== 0 && !loading && currentShipment.sort((s, i) => {
                            if (sorted) {
                                s = s.toCountry || "";
                                i = i.toCountry || "";
                                return s.localeCompare(i);
                            }
                        }).map((s, index) => {
                            let code = s.code || "";
                            let description = s.description || "Error";
                            return <div className={s.select ? 'skd-selected' : 'skd-dash-fetch-body'} key={index} onClick={() => {
                                if (!s.select) {
                                    setSelectedShipment(finalShipment.map(sd => {
                                        if (sd.hawb === s.hawb) {
                                            sd.select = true;
                                        }
                                        return sd;
                                    }))
                                } else {
                                    setSelectedShipment(finalShipment.map(sd => {
                                        if (sd.hawb === s.hawb) {
                                            sd.select = false;
                                        }
                                        return sd;
                                    }))
                                }
                            }}>
                                <div className="skydart-bill-checkbox-header">
                                    <input type="checkbox" checked={s.select} />
                                </div>
                                <div className="skd-bill-no-body">
                                    <h5 style={{ textAlign: "center" }}>{index + 1}</h5>
                                </div>
                                <Waybill hawb={s.hawb} booked={s.booked} status={s.status} />
                                <div className="skd-bill-time-body" style={{ position: "relative" }}>
                                    <h5 style={{ marginTop: -5 }}>{new Date(s.createAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - {s.time}</h5>
                                    <div className="skd-bill-hawb-option">
                                        <h5 style={{ fontSize: 10 }}>Pick up: {s.pickupAt || "Pending"}</h5>
                                    </div>
                                </div>
                                <div className="skd-bill-sin-body" style={{ position: "relative" }}>
                                    {s.service === "DHL" && <h5 className="skd-bill-hawb" style={{ marginTop: -5, fontSize: 11 }}>SDH</h5>}
                                    {s.service === "DHL-VN" && <h5 className="skd-bill-hawb" style={{ marginTop: -5, fontSize: 11 }}>VDH</h5>}
                                    {!s.service.includes("DHL") && <h5 className="skd-bill-hawb" style={{ marginTop: -5, fontSize: 11 }}>{s.service}</h5>}
                                    <h5 style={{ fontSize: 10, position: "absolute" }}>awb: {s.sin}</h5>
                                </div>
                                <div className="skd-bill-time-body" style={{ width: "8%" }}>
                                    <h5>{s.fromCity}</h5>
                                </div>
                                <div className="skd-bill-destination-body" style={{ position: "relative" }}>
                                    <img
                                        loading="lazy"
                                        width="18"
                                        src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
                                        alt="" style={{ marginTop: -10 }}
                                    />
                                    <h5 style={{ marginLeft: 5, marginTop: -10 }}>{s.toCountry}</h5>
                                    <div className="skd-bill-destination-option">
                                        <h5>{s.toCity},{s.toTown}</h5>
                                    </div>
                                </div>
                                <div className="skd-bill-type-body">
                                    <h5 style={{ marginTop: -5 }}>{s.shipmentType}</h5>
                                </div>
                                <div className="skd-bill-weight-body">
                                    <h5>{(Math.round(s.totalCharge * 100) / 100).toFixed(2)}</h5>
                                </div>
                                {/* <div className="skd-bill-weight-body">
                                <h5>{(Math.round(s.reweight * 100) / 100).toFixed(2)}</h5>
                            </div> */}
                                <div className="skd-bill-weight-body">
                                    <h5>{s.totalPackage}/{s.totalPackage}</h5>
                                </div>
                                <div className="skd-bill-status-body">
                                    {s.status === "Arrived At Skydart Origin Depot" && <h5 style={{ padding: 3, textAlign: "center", background: "dodgerblue", color: "white", borderRadius: 5, cursor: "pointer" }}>Arrived</h5>}
                                    {s.status === "Pending" && <h5 style={{ padding: 3, textAlign: "center", background: "lightgray", color: "black", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                    {s.status === "Picked Up" && <h5 style={{ padding: 3, textAlign: "center", background: "Orange", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                    {s.status === "In Process" && <h5 style={{ padding: 3, textAlign: "center", background: "#0068be", color: "white", borderRadius: 5, cursor: "pointer" }}>Process</h5>}
                                    {s.status === "On Hold" && <h5 style={{ padding: 3, textAlign: "center", background: "red", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                    {s.status === "Return" && <h5 style={{ padding: 3, textAlign: "center", background: "red", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                    {s.status === "In Clearance" && <h5 style={{ padding: 3, textAlign: "center", background: "orangered", color: "white", borderRadius: 5, cursor: "pointer" }}>Clearance</h5>}
                                    {s.status === "Cleared For Export" && <h5 style={{ padding: 3, textAlign: "center", background: "darkgreen", color: "white", borderRadius: 5, cursor: "pointer" }}>Cleared</h5>}
                                    {s.status === "In Transit" && <h5 style={{ padding: 3, textAlign: "center", background: "#0068be", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                    {s.status === "Delivered" && <h5 style={{ padding: 3, textAlign: "center", background: "green", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                </div>
                                <div className="skd-bill-value-body">
                                    <h5>{(Math.round(s.totalInvoiceValue * 100) / 100).toFixed(2)}</h5>
                                </div>

                            </div>
                        }

                        )}
                        {finalShipment.length === 0 && !loading && <div>
                            <center style={{ padding: 30 }}>
                                <h5>You are currently don't have any shipment yet</h5>
                                <Button variant="contained" className="get-start-btn" startIcon={<Package />} onClick={() => history.push("/booking")}>Create shipment</Button>
                                <p>Change date</p>
                            </center>
                        </div>}
                        {loading && <LinearProgress />}
                    </div>
                    <div className="skd-dash-fetch-header" style={{ bottom: 0, borderTop: "1px solid lightgray", boxShadow: "0px 1px 1px rgba(50, 50, 50, 0.75)" }}>
                        <div className="skydart-bill-checkbox-header">
                            <input type="checkbox" id="selectAll" style={{ opacity: 0 }} />
                        </div>
                        <div className="skd-bill-no-header">
                            <h5 style={{ textAlign: "center", opacity: 0 }}>No.</h5>
                        </div>
                        <div className="skd-bill-hawb-header">
                            <h5 style={{ opacity: 0 }}>Waybill Number</h5>
                        </div>
                        <div className="skd-bill-time-header">
                            <h5 style={{ opacity: 0 }}>Booked Time</h5>
                        </div>
                        <div className="skd-bill-sin-header">
                            <h5 style={{ fontWeight: 600, opacity: 0 }}>Service</h5>
                        </div>
                        <div className="skd-bill-time-header" style={{ width: "8%" }}>
                            <h5 style={{ opacity: 0 }}>From</h5>
                        </div>
                        <div className="skd-bill-destination-header" ref={countryRef}>
                            <h5 style={{ marginLeft: 3, opacity: 0 }}>Ship To</h5>
                            {!sorted && <ArrowDropDown className="skd-bill-destination-icon" style={{ marginLeft: "auto", opacity: 0 }} onClick={() => setSorted(true)} />}
                            {sorted && <ArrowDropUp className="skd-bill-destination-icon" style={{ marginLeft: "auto", opacity: 0 }} onClick={() => setSorted(false)} />}
                            {displayCountry && <div className="skydart-new-booking-filter-option" style={{ marginTop: 265 }}>
                                <form className="skydart-new-booking-filter-container" onSubmit={(e) => { e.preventDefault(); setDisplayCountry(false) }} style={{ borderBottom: "1px solid lightgray" }}>
                                    <Search fontSize="small" />
                                    <input type="text" placeholder="Search Country" value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)} />
                                    <select value={searchCode} onChange={(e) => setSearchCode(e.target.value)} style={{ padding: 5, background: "rgb(238,238,238)", cursor: "pointer", border: "none", outline: "none", borderRadius: 5 }}>
                                        <option value="">Search Code</option>
                                        {sortedCode.sort((a, b) => {
                                            return a.localeCompare(b);
                                        }).map((s) => {
                                            return <option value={s}>{s}</option>
                                        })}
                                    </select>
                                    <button className="hidden" type="submit">Submit</button>
                                </form>
                                <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                    {sortedCountry.filter((s) => {
                                        if (s.toLowerCase().includes(searchCountry.toLowerCase())) {
                                            return s;
                                        } else {
                                            return s;
                                        }
                                    }).map((s, index) => (
                                        <div className="sorted" onClick={() => setSearchCountry(s)}>
                                            <h5 style={{ fontSize: 10 }}>{s}</h5>
                                        </div>
                                    ))}
                                </div>
                                <div className="sorted-btn">
                                    <button type="button" onClick={() => setDisplayCountry(false)}>Search</button>
                                    <h5 onClick={() => { setDisplayCountry(false); setSearchCountry("") }}>Cancel</h5>
                                </div>
                            </div>}
                        </div>
                        <div className="skd-bill-type-header">
                            <h5 style={{ opacity: 0 }}>Type</h5>
                        </div>
                        <div className="skd-bill-weight-header">
                            <h5 style={{ color: "rgb(50, 113, 155)", fontSize: 12.5 }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)}</h5>
                        </div>
                        <div className="skd-bill-weight-header">
                            <h5 style={{ fontSize: 12.5 }}>{convertThoudsand(totalPieces)} pcs</h5>
                        </div>
                        <div className="skd-bill-status-header">
                            <h5 style={{ opacity: 0 }}>Status</h5>
                        </div>
                        <div className="skd-bill-value-header">
                            <h5 style={{ opacity: 0 }}>Value</h5>
                        </div>

                    </div>

                </div>
                <div ref={printAllRef}>
                    <div className="skydart-booking-hawb1" ref={labelRef}>
                        {selectPrint.map((s, index) => (
                            <div className="printing3" key={index}>
                                <StaffBill2
                                    hawb={s.hawb}
                                    exporter={s.exporter}
                                    fromAddress={s.fromAddress}
                                    fromAddress2={s.fromAddress2}
                                    fromAddress3={s.fromAddress3}
                                    fromState={s.fromState}
                                    fromTown={s.fromTown}
                                    fromCity={s.fromCity}
                                    fromPostal={s.fromPostal}
                                    fromCountry={s.fromCountry}
                                    fromPhone={s.fromPhone}
                                    fromName={s.fromName}
                                    fromEmail={s.fromEmail}
                                    importer={s.importer}
                                    toAddress={s.toAddress}
                                    toAddress2={s.toAddress2}
                                    toAddress3={s.toAddress3}
                                    toState={s.toState}
                                    toTown={s.toTown}
                                    toCity={s.toCity}
                                    toPostal={s.toPostal}
                                    toCountry={s.toCountry}
                                    toPhone={s.toPhone}
                                    toName={s.toName}
                                    toEmail={s.toEmail}
                                    amount={s.totalPackage}
                                    weight={s.weight}
                                    chargeable={s.totalCharge}
                                    type={s.shipmentType}
                                    date={s.createAt}
                                    exportSelected={selectPrint}
                                    description={s.description}

                                />
                            </div>
                        ))}
                    </div>
                    <div className="skydart-booking-hawb1" ref={invoiceRef}>
                        {selectPrint.map((s) => {
                            if (s.shipmentType === "WPX") {
                                return <Inv2
                                    invoice={s.invoiceValue}
                                    hawb={s.hawb}
                                    datePicker={s.createAt}
                                    fromCountry={s.fromCountry}
                                    toCountry={s.toCountry}
                                    usage={s.usage}
                                    exporter={s.exporter}
                                    fromAddress={s.fromAddress}
                                    fromAddress2={s.fromAddress2}
                                    fromAddress3={s.fromAddress3}
                                    fromTown={s.fromTown}
                                    fromState={s.fromState}
                                    fromCity={s.fromCity}
                                    fromPostal={s.fromPostal}
                                    fromName={s.fromName}
                                    fromPhone={s.fromPhone}
                                    fromEmail={s.fromEmail}
                                    mst={s.mst}
                                    importer={s.importer}
                                    toAddress={s.toAddress}
                                    toAddress2={s.toAddress2}
                                    toAddress3={s.toAddress3}
                                    toState={s.toState}
                                    toPostal={s.toPostal}
                                    toTown={s.toTown}
                                    toCity={s.toCity}
                                    toName={s.toName}
                                    toPhone={s.toPhone}
                                    toEmail={s.toEmail}
                                    eori={s.eori}
                                    transportation={s.transportation}
                                    totalGross={s.weight}
                                    totalPackage={s.totalPackage}
                                    totalItemValue={s.totalItemValue}
                                    totalInvoiceValue={s.totalInvoiceValue}
                                    exportSelected={selectPrint}
                                />
                            }
                        })}
                    </div>
                    <div className="skydart-booking-hawb1" ref={shippingMarkRef}>
                        {/* {selectPrint.map((s, index) => (
                        <div className="printing4">
                            <PrintMark no={index + 1} exportSelected={selectPrint} packageData={s.packageValue} total={s.totalPackage} hawb={s.hawb} weight={s.weight} chargeable={s.totalCharge} importer={s.importer} address={s.toAddress} address2={s.toAddress2} address3={s.toAddress3} city={s.toCity} state={s.toState} town={s.toTown} postal={s.toPostal} country={s.toCountry} phone={s.toPhone} contact={s.toName} />
                        </div>
                    ))} */}
                        {selectPrint.map((s) => {
                            if (s.shipmentType === "WPX") {
                                return s.packageValue.map((item, index) => (
                                    <div className="printing4">
                                        <ShippingMark no={index + 1} date={s.createAt} length={item.packageLength} width={item.packageWidth} height={item.packageHeight} total={s.packageValue.length} hawb={s.hawb} weight={item.packageWeight} chargeable={item.packageCharge} importer={s.importer} address={s.toAddress} address2={s.toAddress2} address3={s.toAddress3} city={s.toCity} state={s.toState} town={s.toTown} postal={s.toPostal} country={s.toCountry} phone={s.toPhone} contact={s.toName} />
                                    </div>
                                ))
                            }
                        })}
                        {/* {selectPrint.map((s) => {
                        let package = s.packageValue;
                        return <>
                        </>
                    })} */}
                    </div>
                </div>
                {/* {exportSelected.map((s, index) => (
                <div ref={printAllRef} key={index} className="skydart-create-print1">
                    <div className="skydart-booking-hawb1" ref={labelRef}>
                        <StaffBill2
                            hawb={s.hawb}
                            exporter={s.exporter}
                            fromAddress={s.fromAddress}
                            fromAddress2={s.fromAddress2}
                            fromAddress3={s.fromAddress3}
                            fromState={s.fromState}
                            fromTown={s.fromTown}
                            fromCity={s.fromCity}
                            fromPostal={s.fromPostal}
                            fromCountry={s.fromCountry}
                            fromPhone={s.fromPhone}
                            fromName={s.fromName}
                            fromEmail={s.fromEmail}
                            importer={s.importer}
                            toAddress={s.toAddress}
                            toAddress2={s.toAddress2}
                            toAddress3={s.toAddress3}
                            toState={s.toState}
                            toTown={s.toTown}
                            toCity={s.toCity}
                            toPostal={s.toPostal}
                            toCountry={s.toCountry}
                            toPhone={s.toPhone}
                            toName={s.toName}
                            toEmail={s.toEmail}
                            amount={s.totalPackage}
                            weight={s.weight}
                            chargeable={s.totalCharge}
                            type={s.shipmentType}
                            date={s.createAt}
                            exportSelected={exportSelected}
                        />
                    </div>
                    {s.shipmentType === "WPX" && <div className="skydart-booking-hawb" ref={invoiceRef}>
                        <Inv2
                                invoiceValue={invoiceValue}
                                hawb={hawb}
                                datePicker={datePicker}
                                fromCountry={userData.country}
                                toCountry={country}
                                usage={reason}
                                exporter={userData.exporter}
                                fromAddress={userData.address}
                                fromAddress2={userData.address2}
                                fromAddress3={userData.address3}
                                fromTown={userData.town}
                                fromState={userData.state}
                                fromCity={userData.city}
                                fromPostal={userData.postal}
                                fromName={userData.displayName}
                                fromPhone={userData.phone}
                                fromEmail={userData.email}
                                mst={userData.mst}
                                importer={importer}
                                toAddress={address}
                                toAddress2={address2}
                                toAddress3={address3}
                                toState={state}
                                toTown={town}
                                toCity={city}
                                toName={toName}
                                toPhone={phone}
                                toEmail={email}
                                eori={eori}
                                transportation={transport}
                                totalGross={finalGross.toFixed(2)}
                                totalPackage={packageValue.length}
                                totalItemValue={finalInvoiceValue}
                                totalInvoiceValue={totalInvoiceValue}
                            />
                    </div>}
                    {type === "WPX" && <div className="skydart-booking-hawb" ref={shippingMarkRef}>
                            {packageValue.map((s, index) => (
                                <ShippingMark no={index + 1} total={packageValue.length} hawb={hawb} weight={finalGross.toFixed(2)} chargeable={finalCharge.toFixed(2)} length={s.packageLength} width={s.packageWidth} height={+s.packageHeight} importer={importer} address={address} address2={address2} address3={address3} city={city} state={state} town={town} postal={postal} country={country} phone={phone} contact={toName} />
                            ))}
                        </div>}
                </div>
            ))} */}
            </div>}
            {viewAs === "Edit" && <div className="skydart-draft">
                <div className="skydart-draft-header">
                    <div className="skydart-draft-search">
                        <div className="skydart-draft-search-right">
                            <BsSearch size={21} style={{ position: 'absolute', marginLeft: 15 }} />
                            <input type="text" value={searchDraft} onChange={(e) => setSearchDraft(e.target.value)} placeholder="Search for shipment" style={displaySearch ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, border: "1px solid transparent", background: "white", boxShadow: "rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px" } : { borderBottomRightRadius: 7, borderBottomLeftRadius: 7 }} onClick={() => {
                                if (displaySearch) {
                                    setDisplaySearch(false);
                                } else {
                                    setDisplaySearch(true);
                                }
                            }} />
                            {displaySearch && <div className="skydart-draft-search-box">
                                <div className="skydart-draft-search-query">
                                    <h5 style={{ fontSize: 12, fontWeight: 600 }}>Query:</h5>
                                    <div className="skydart-draft-search-title">
                                        <h5>HAWB</h5>
                                    </div>
                                    <div className="skydart-draft-search-equal">
                                        <h5>is</h5>
                                    </div>
                                    <div className="skydart-draft-search-result">
                                        <h5>{searchDraft}</h5>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="skydart-draft-shipment-shown">
                            <h5>Draft Shipments - showing (20/100)</h5>
                        </div>
                    </div>
                </div>
                <div className="skydart-draft-body">
                    <div className="skydart-draft-body-header">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-body-header-refresh">
                            <FiRefreshCw size={21} />
                        </div>
                        <div className="skydart-draft-body-header-refresh">
                            <BsBoxSeam size={21} />
                        </div>
                        <div className="skydart-draft-body-header-refresh">
                            <BsTrash size={21} />
                        </div>
                        <div className="skydart-draft-body-header-refresh">
                            <MdOutlineLabel size={21} />
                        </div>
                        <div className="skydart-draft-body-header-refresh">
                            <AiOutlineMore size={21} />
                        </div>
                        <div className="skydart-draft-paginate">
                            <div className="skydart-draft-paginate-arrow">
                                <IconButton type="button">
                                    <MdArrowBackIosNew fontSize="small" />
                                </IconButton>
                            </div>
                            <div className="skydart-draft-paginate-arrow">
                                <IconButton type="button">
                                    <MdArrowForwardIos fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-phan-loai">
                        <div className="skydart-draft-body-label" onClick={() => setViewAs("All")}>
                            <BsFillReplyAllFill size={21} />
                            <h5>All shipments</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, borderRadius: "15px", background: "rgb(228, 69, 41)", padding: 5, color: "white" }}>100</h5>
                        </div>
                        <div className="skydart-draft-body-label">
                            <GrHostMaintenance size={21} />
                            <h5>Main</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, borderRadius: "15px", background: "#0074D9", padding: 5, color: "white" }}>100</h5>
                        </div>
                        <div className="skydart-draft-body-label">
                            <MdLabelImportantOutline size={21} />
                            <h5>Important</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, background: "rgb(48, 143, 96)", borderRadius: "15px", padding: 5, color: "white" }}>100</h5>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45, }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-body">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" />
                        </div>
                        <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45 }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-content">
                            <div className="skydart-draft-hawb">
                                <BsBox size={19} />
                                <h5>SKD029309101920</h5>
                            </div>
                            <div className="skydart-draft-country">
                                <h5>Vietnam * Ho Chi Minh * Tan Binh * 2 * 70500</h5>
                            </div>
                            <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                            <div className="skydart-draft-address">
                                <p style={{ fontSize: 13.5 }}>32 Lam Son, 108 Hong Ha, 40 Lam Son...</p>
                            </div>
                            <div className="skydart-draft-weight">
                                <p>4.00kg, 2pcs, 5 items</p>
                            </div>
                            <div className="skydart-draft-date">
                                <h5>17/09/2019</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
        </>
    )
}
