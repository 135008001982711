import Search from '@material-ui/icons/Search';
import React, { useState, useEffect, useRef } from 'react'
import "./SkdHeader.css";
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";
import { AccountCircle, Email, ExitToApp, ListAlt, NewReleases, Settings } from '@material-ui/icons';
import { auth, db } from '../firebase';
import { Avatar, CircularProgress } from '@material-ui/core';
import { FcLike } from "react-icons/fc";
import { FcCheckmark } from "react-icons/fc";
import axios from "axios";
import { Package } from 'react-feather';
export default function SkdHeader({ userData, user }) {
    const { pathname } = useLocation();
    const [displayAccount, setDisplayAccount] = useState(false);
    const [displayResult, setDisplayResult] = useState(false);
    const [result, setResult] = useState([]);
    const [search, setSearch] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState("");
    const history = useHistory();
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${year}-${month}-${date}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, []);
    const handleAccount = () => {
        if (displayAccount) {
            setDisplayAccount(false);
        } else if (!displayAccount) {
            setDisplayAccount(true);
        }
    }
    let settingRef = useRef();
    let resultRef = useRef();
    useEffect(() => {
        let handler = (event) => {
            if (!settingRef.current.contains(event.target)) {
                setDisplayAccount(false);
            }
            if (!resultRef.current.contains(event.target)) {
                setDisplayResult(false);
                setResult([]);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    const handleRetrive = (e) => {
        e.preventDefault();
        setDisplayResult(true);
        setLoading(true);
        axios.get(`https://test.skydart.com.vn/getShipment/${search}`).then((res) => {
            setResult(res.data);
            setLoading(false);
            setResponse("Shipment already exist");
        }).catch((err) => {
            db.collection("shipments").doc(search).get().then((res) => {
                if (res.exists) {
                    setResult(res.data());
                    setLoading(false);
                    setResponse("Get shipment");
                } else if (!res.exists) {
                    setLoading(false);
                    alert("Shipment may not be exist or expire, try again");
                    setDisplayResult(false);
                }
            })
        })
    }
    const rebookShipment = () => {
        setLoading(true);
        if (result.shipmentType === "WPX") {
            axios.post("https://test.skydart.com.vn/api/create/shipment", {
                account: "786009",
                currency: "USD",
                service: result.service,
                date: traceTime,
                hawb: result.hawb,
                mawb: "Pending",
                makh: result.makh,
                company: result.importer,
                contact: result.toName,
                otherTerm: result.otherTerm,
                type: result.shipmentType,
                status: result.status,
                term: result.term,
                time: dateTime,
                address: result.toAddress,
                address2: result.toAddress2 || ".",
                address3: result.toAddress3,
                city: result.toCity,
                country: result.toCountry,
                code: result.code,
                phone: result.toPhone,
                phone2: result.toPhone2 || ".",
                postal: result.toPostal,
                state: result.toState,
                plan: result.plan || "Pending",
                value: result.totalInvoiceValue,
                town: result.toTown,
                chargeable: result.totalCharge,
                exactWeight: result.totalCharge,
                exactCharge: result.totalCharge,
                amount: result.totalPackage,
                totalItem: result.totalItem,
                transport: result.transportation,
                description: result.description,
                controller: result.controller || "Pending",
                eamFormat: "NDX",
                dateTime: new Date(),
                airline: "Pending",
                reweight: result.reweight,
                etd: "Pending",
                agent: "Pending",
                subStatus: "Pending",
                sin: "Null",
                sender: result.exporter,
                note: "Null",
                perform: "Null",
                fromEmail: result.fromEmail,
                fromCity: result.fromCity,
                totalValue: "0.00",
                rate: "0.00",
                comestic: "0.00",
                addressCorrection: "0.00",
                remote: "0.00",
                surcharge: "0.00",
                surcharge2: "0.00",
                surcharge3: "0.00",
                fsc: "0",
                fscValue: "0.00",
                vat: "0.00",
                vatValue: "0.00",
                paymentPickup: "CRE",
                paymentAcc: "CRE",
                paymentCompany: "CRE",
                paymentStatus: "Un Paid",
                pickupBy: userData.displayName,
                collectBy: "Pending",
                rateUpdate: result.createAt,
                sellNote: "Pending",
                com: "0.00",
                sellNote: "Pending",
                paymentSubStatus: "Rebook",
                eamService: result.eamService,
                usage: result.usage,
                pack: "Pending",
            })
            setTimeout(() => {
                setLoading(false);
                setSuccess(true);

            }, 1000)
            setTimeout(() => {
                setSuccess(false);
                setDisplayResult(false);
            }, 2000)
        } else if (result.shipmentType === "DOX") {
            axios.post("https://test.skydart.com.vn/api/create/shipment", {
                account: "786009",
                currency: "USD",
                service: result.service,
                date: traceTime,
                hawb: result.hawb,
                mawb: "Pending",
                makh: result.makh,
                company: result.importer,
                contact: result.toName,
                otherTerm: result.otherTerm,
                type: result.shipmentType,
                status: result.status,
                term: result.term,
                time: result.time,
                address: result.toAddress,
                address2: result.toAddress2 || ".",
                address3: result.toAddress3,
                city: result.toCity,
                country: result.toCountry,
                code: result.code,
                phone: result.toPhone,
                phone2: result.toPhone2,
                postal: result.toPostal,
                state: result.toState,
                value: result.totalInvoiceValue,
                town: result.toTown,
                chargeable: result.totalCharge,
                amount: result.totalPackage,
                totalItem: result.totalItem,
                transport: result.transportation,
                description: "Document",
                controller: result.controller || "Pending",
                eamFormat: "DOX",
                dateTime: new Date(),
                airline: "Pending",
                reweight: result.totalCharge,
                etd: "Pending",
                agent: "Pending",
                subStatus: "Pending",
                sin: "Null",
                sender: result.exporter,
                note: "Null",
                perform: "Null",
                fromEmail: result.fromEmail,
                fromCity: result.fromCity,
                totalValue: "0.00",
                rate: result.rate || "0.00",
                comestic: "0.00",
                addressCorrection: "0.00",
                remote: "0.00",
                surcharge: "0.00",
                surcharge2: "0.00",
                surcharge3: "0.00",
                fsc: result.fsc || "0",
                fscValue: result.fscValue || "0.00",
                vat: "0",
                vatValue: "0.00",
                paymentPickup: "CRE",
                paymentAcc: "CRE",
                paymentCompany: "CRE",
                paymentStatus: "Un Paid",
                pickupBy: userData.displayName,
                collectBy: "Pending",
                rateUpdate: result.createAt,
                sellNote: "Pending",
                com: "0.00",
                sellNote: "Pending",
                paymentSubStatus: "Rebook",
                eamService: result.eamService,
                usage: result.usage,
                pack: "Pending",
                plan: result.plan || "Pending",
            })
            setTimeout(() => {
                setLoading(false);
                setSuccess(true);
            }, 1000)
            setTimeout(() => {
                setResult([]);
                setSuccess(false);
                setDisplayResult(false);
            }, 2000)
        }
    }
    return (
        <div className="skd-header">
            <div className="skd-header-container">
                <div className="skydart-header-first-left">
                    <img src="https://i.imgur.com/L6PnhXq.png" style={{ width: "40px", height: "40px" }} alt="Skydart Express" />
                    <div className="skydart-header-text">
                        <h1>Skydart Express</h1>
                        <h3>International Air Express Services</h3>
                    </div>
                </div>
                <div className="skd-header-right">
                    <NavLink exact to="/" activeClassName="skydart-active" className="skd-header-right-option">
                        <h5>New Bookings</h5>
                    </NavLink>
                    <NavLink exact to="/shipments" activeClassName="skydart-active" className="skd-header-right-option">
                        <h5>Shipments</h5>
                    </NavLink>
                    <NavLink exact to="/packing" activeClassName="skydart-active" className="skd-header-right-option">
                        <h5>Packing</h5>
                    </NavLink>
                    {userData.role !== "Courier" && <NavLink exact to="/users" activeClassName="skydart-active" className="skd-header-right-option">
                        <h5>Customers</h5>
                    </NavLink>}
                    {userData.role !== "Courier" && <NavLink exact to="/debt" isActive={() => ['/debt', '/paid'].includes(pathname)} activeClassName="skydart-active" className="skd-header-right-option">
                        <h5>Transactions</h5>
                    </NavLink>}
                    {userData.role !== "Courier" && <NavLink exact to="/rate" activeClassName="skydart-active" className="skd-header-right-option">
                        <h5>Sales</h5>
                    </NavLink>}
                    <div ref={settingRef}>
                        <div className={displayAccount ? "skydart-active" : "skd-header-right-option"} onClick={handleAccount} >
                            <h5>Account</h5>
                        </div>
                        {displayAccount && <div className="skd-header-right-account">
                            <div className="arrow-down"></div>
                            <div className="skd-header-right-account-option">
                                <Avatar src={userData.displayName} alt={userData.displayName} style={{ width: 25, height: 25 }} />
                                <h3 style={{ marginLeft: 7 }}>{userData.displayName}</h3>
                            </div>
                            <div className="skd-header-right-account-option" style={{ borderBottom: "1px solid lightgray", marginBottom: 5 }}>
                                <Email fontSize="small" />
                                <h3 style={{ fontSize: 12, marginLeft: 5 }}>{userData.email}</h3>
                            </div>
                            {/* <div className="skd-header-right-account-option1" onClick={() => { history.push("/staff"); setDisplayAccount(false) }}>
                                <AccountCircle fontSize="small" />
                                <h3>Team</h3>
                            </div> */}
                            <div className="skd-header-right-account-option1" onClick={() => { history.push("/setting"); setDisplayAccount(false) }}>
                                <Settings fontSize="small" />
                                <h3>Setting</h3>
                            </div>
                            <div className="skd-header-right-account-option1" onClick={() => { auth.signOut(); history.push('/') }}>
                                <ExitToApp fontSize="small" />
                                <h3>Sign Out</h3>
                            </div>
                        </div>}
                    </div>
                </div>
                <div ref={resultRef}>
                    <form className="skd-header-center" onSubmit={handleRetrive}>
                        <Search style={{ position: 'absolute', marginLeft: 5 }} />
                        <input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </form>
                    {displayResult && <div className="skd-header-retrive">
                        {loading && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CircularProgress size={30} style={{ padding: 10, color: "#0068be" }} />
                            </div>
                            <p style={{ fontSize: 13 }}>Loading, looking for shipment...</p>
                        </div>}
                        {success && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <FcCheckmark size={40} style={{ padding: 10 }} />
                        </div>}
                        {!loading && !success && <div className="skd-header-retrive-container">
                            <div className="skd-header-retrive-container-header" style={{ padding: 5, display: "flex", alignItems: "center" }}>
                                <Package size={25} />
                                <div style={{ marginLeft: 5, width: "90%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 style={{ fontSize: 15, color: "darkblue", fontWeight: 600, textDecoration: "underline" }}>{result.hawb || result[0].hawb}</h3>
                                    <h5 style={{ fontSize: 15, fontWeight: 600 }}>Service: <strong>{result.service || result[0].service}</strong></h5>
                                </div>
                            </div>
                            <div style={{ padding: 5, borderBottom: "1px solid lightgray" }}>
                                <h3 style={{ fontSize: 14, padding: 3 }}>{result.exporter || result[0].sender}</h3>
                                <h5 style={{ fontSize: 12, padding: 3 }}>{result.makh || result[0].makh}</h5>
                                <h5 style={{ fontSize: 12, padding: 3 }}>MAWB: {result.mawb || result[0].mawb}</h5>
                                <h5 style={{ fontSize: 12, padding: 3 }}>INTL AWB: {result.sin || result[0].sin}</h5>
                            </div>
                            <div style={{ padding: 5 }}>
                                <h3 style={{ fontSize: 14, padding: 3 }}>Booked at: {result.createAt ? new Date(result.createAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : new Date(result[0].date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - {result.time || result[0].time}</h3>
                                <h5 style={{ fontSize: 12, padding: 3 }}>Status: {result.status || result[0].status}</h5>
                                <h5 style={{ fontSize: 12, padding: 3 }}>Controller: {result.controller || result[0].controller}</h5>
                            </div>
                            <div style={{ padding: 5 }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 style={{ fontSize: 14, padding: 3 }}>From:</h3>
                                    <h5 style={{ fontSize: 12, padding: 3 }}>{result.fromCity || result[0].fromCity}</h5>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 style={{ fontSize: 14, padding: 3 }}>To:</h3>
                                    <h5 style={{ fontSize: 12, padding: 3 }}>{result.toCountry || result[0].country}</h5>
                                </div>
                            </div>
                            <div style={{ padding: 5, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h3 style={{ fontSize: 12, padding: 3 }}>Type: {result.shipmentType || result[0].type}</h3>
                                <h5 style={{ fontSize: 12, padding: 3 }}>Chargeable: {result.totalCharge || result[0].chargeable}KG</h5>
                            </div>
                            <div style={{ padding: 5 }}>
                                {response === "Get shipment" && result.status !== "Pending" && <button type="button" className="getshipment-btn" onClick={rebookShipment}>{response}</button>}
                                {response === "Shipment already exist" && <button type="button" className="not-allow-get">{response}</button>}
                                {response === "Get shipment" && result.status !== "Pending" && <button type="button" style={{ marginLeft: 10, cursor: "not-allowed", fontWeight: 600, padding: 8, border: "none", background: "rgb(230,230,230)", borderRadius: 5, color: "gray" }}>Update HAWB</button>}
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}
