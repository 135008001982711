
import React from 'react'
import { Circle } from "better-react-spinkit"
import { CircularProgress, LinearProgress, IconButton } from '@material-ui/core';
export default function Loading() {
    return (
        <center style={{ display: "grid", alignItems: "center" ,width: "100%", filter: "brighter", height: "100vh" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{marginTop: 220, marginLeft: 18}}>
                    <CircularProgress size={30} />
                    <h5 style={{fontWeight: 600, fontSize: 17}}>Loading your booking app...</h5>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src="https://i.imgur.com/L6PnhXq.png" alt="logo"
                    height={50}
                    filter={{ brighter: 1.2 }} />
                <h1 style={{ fontWeight: 600, marginLeft: 5, fontSize: 25, color: "darkblue" }}>Skydart Express</h1>
            </div>
        </center>
    )
}