import React, { useState, useEffect } from 'react'
import { db } from "../firebase";
import { useHistory } from "react-router-dom";
export default function Inv2({
    fromName,
    fromAddress,
    fromAddress2,
    fromAddress3,
    fromCity,
    fromPostal,
    exporter,
    fromState,
    fromTown,
    fromEmail,
    importer,
    fromPhone,
    datePicker,
    hawb,
    fromCountry,
    toName,
    toAddress,
    toAddress2,
    toAddress3,
    city,
    usage,
    toCountry,
    term,
    otherTerm,
    transportation,
    postal,
    toPhone,
    totalPackage,
    totalGross,
    totalItemValue,
    invoiceValue,
    totalInvoiceValue,
    toState,
    toTown,
    toCity,
    toPostal,
    toEmail,
    sin,
    mst,
    eori,
    invoice,
    exportSelected
}) {

    return (
        <div className="invoice2">
            <div className="invoice-term">
                <h1>INVOICE</h1>
                <div className="invoice-term-header">
                    <div className="invoice-term-header-left">
                        <div className="invoice-term-header-left-option">
                            <h5>INVOICE NUMBER</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5>DATE OF INVOICE</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5>INTERNATIONAL AIR WAYBILL NO</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5>COUNTRY OF ORIGIN</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5>DESTINATION</h5>
                        </div>
                        <div className="invoice-term-header-left-option" style={{ borderBottom: "none" }}>
                            <h5>REASON FOR EXPORT</h5>
                        </div>
                    </div>
                    <div className="invoice-term-header-right">
                        <div className="invoice-term-header-right-option">
                            <h5>{hawb}</h5>
                        </div>
                        <div className="invoice-term-header-right-option">
                            <h5>{datePicker}</h5>
                        </div>
                        <div className="invoice-term-header-right-option" style={{ height: "17px" }}>
                            <h5></h5>
                        </div>
                        <div className="invoice-term-header-right-option">
                            <h5>{fromCountry}</h5>
                        </div>
                        <div className="invoice-term-header-right-option">
                            <h5>{toCountry}</h5>
                        </div>
                        <div className="invoice-term-header-right-option" style={{ borderBottom: "none" }}>
                            <h5>{usage}</h5>
                        </div>
                    </div>
                </div>
                <div className="invoice-term-shipping-info">
                    <div className="invoice-term-shipping-info-container">
                        <div className="invoice-term-shipping-info-header">
                            <h5>SHIPPER / EXPORTER</h5>
                        </div>
                        <div className="invoice-term-shipping-info-body">
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Company / Sender Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{exporter}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromAddress}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromAddress2}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">

                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromAddress3}</h5>
                                </div>
                            </div>
                            {fromCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Ward</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromState}</h5>
                                </div>
                            </div>}
                            {fromCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>District</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromTown}</h5>
                                </div>
                            </div>}
                            {fromCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromCity}</h5>
                                </div>
                            </div>}
                            {fromCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Town</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromTown}</h5>
                                </div>
                            </div>}
                            {fromCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromCity}</h5>
                                </div>
                            </div>}
                            {fromCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>State</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromState}</h5>
                                </div>
                            </div>}
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Postal Code</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromPostal}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Country</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromCountry}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Contact Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromName}</h5>
                                </div>
                            </div>

                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Contact Number</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromPhone}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Email Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{fromEmail}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ borderBottom: "none" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>ID Tax Number</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{mst}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="invoice-term-shipping-info-container">
                        <div className="invoice-term-shipping-info-header">
                            <h5>CONSIGNEE / IMPORTER</h5>
                        </div>
                        <div className="invoice-term-shipping-info-body" style={{ borderLeft: "1px solid" }}>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Company / Receiver Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{importer}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toAddress}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toAddress2}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">

                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toAddress3}</h5>
                                </div>
                            </div>
                            {toCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Ward</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toState}</h5>
                                </div>
                            </div>}
                            {toCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>District</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toTown}</h5>
                                </div>
                            </div>}
                            {toCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toCity}</h5>
                                </div>
                            </div>}
                            {toCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toCity}</h5>
                                </div>
                            </div>}
                            {toCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Town</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toTown}</h5>
                                </div>
                            </div>}
                            {toCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>State</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toState}</h5>
                                </div>
                            </div>}
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Postal Code</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toPostal}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Country</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toCountry}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Contact Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toName}</h5>
                                </div>
                            </div>

                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Contact Number</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toPhone}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>Email Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{toEmail}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ borderBottom: "none" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5>IOSS / TIN / EORI / ID Tax No</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none" }}>
                                    <h5>{eori}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invoice-term-full-description-of-good">
                    <div className="invoice-term-full-description-of-good-header">
                        <h5>FULL DESCRIPTION OF GOODS</h5>
                    </div>
                    <div className="invoice-term-full-description-of-good-content">
                        {invoice.map((s, index) => (
                            <>
                                <div className="invoice-full-description-header">
                                    <div className="invoice-full-description-header-option1">
                                        <h5>COMMODITY / ITEM {s.itemNo}</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option">
                                        <h5>PACKING</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option">
                                        <h5>QUANTITY</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option">
                                        <h5>UNIT</h5>
                                    </div>

                                    <div className="invoice-full-description-header-option">
                                        <h5>UNIT PRICE ({toCountry === "Singapore" ? "SGD" : "USD"})</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option" style={{ borderRight: "none" }}>
                                        <h5>S.TOTAL ({toCountry === "Singapore" ? "SGD" : "USD"})</h5>
                                    </div>
                                </div>
                                <div className="invoice-full-description-body">
                                    <div className="invoice-full-description-body-option1">
                                        <h5>{s.itemCommonity}</h5>
                                    </div>
                                    {s.itemPackType !== "Other" && <div className="invoice-full-description-body-option3">
                                        <h5>{s.itemPackType}</h5>
                                    </div>}
                                    {s.itemPackType === "Other" && <div className="invoice-full-description-body-option3">
                                        <h5>{s.itemPackType2}</h5>
                                    </div>}
                                    <div className="invoice-full-description-body-option">
                                        <h5>{s.itemQuantity}</h5>
                                    </div>
                                    {s.itemUnit !== "Other" && <div className="invoice-full-description-body-option">
                                        <h5>{s.itemUnit}</h5>
                                    </div>}
                                    {s.itemUnit === "Other" && <div className="invoice-full-description-body-option">
                                        <h5>{s.itemUnit2}</h5>
                                    </div>}
                                    <div className="invoice-full-description-body-option2">
                                        <h5>{(Math.round(s.itemUnitPrice * 100) / 100).toFixed(2)} {toCountry === "Singapore" ? "SGD" : "USD"}</h5>
                                    </div>
                                    <div className="invoice-full-description-body-option2" style={{ borderRight: "none" }}>
                                        <h5>{(Math.round(s.itemValue * 100) / 100).toFixed(2)} {toCountry === "Singapore" ? "SGD" : "USD"}</h5>
                                    </div>
                                </div>
                                <div className="invoice-description-detail">
                                    <div className="invoice-description-detail-left">
                                        <h5>Origin</h5>
                                        {s.itemHsCode !== "" && <h5>HS Code</h5>}
                                        {s.itemBrand !== "" && <h5>Brand Name</h5>}
                                        {s.itemIngredients !== "" && <h5>Ingredient</h5>}
                                        {s.itemPurpose !== "" && <h5>Purpose</h5>}
                                        {s.itemManufact !== "" && <h5>Manufacturer</h5>}
                                        {s.itemManufactAddress !== "" && <h5>Manufacturer Address</h5>}
                                        {s.itemFda !== "" && <h5>FDA Registration No</h5>}
                                    </div>
                                    <div className="invoice-description-detail-right">
                                        <h5>{s.itemCountryOrigin}</h5>
                                        {s.itemHsCode !== "" && <h5>{s.itemHsCode}</h5>}
                                        {s.itemBrand !== "" && <h5>{s.itemBrand}</h5>}
                                        {s.itemIngredients !== "" && <h5>{s.itemIngredients}</h5>}
                                        {s.itemPurpose !== "" && <h5>{s.itemPurpose}</h5>}
                                        {s.itemManufact !== "" && <h5>{s.itemManufact}</h5>}
                                        {s.itemManufactAddress !== "" && <h5>{s.itemManufactAddress}</h5>}
                                        {s.itemFda !== "" && <h5>{s.itemFda}</h5>}
                                    </div>
                                </div>

                            </>
                        ))}
                    </div>
                    <div className="invoice-value-container">
                        <div className="invoice-value-container-left">
                            <h5>Total Value Of Goods</h5>
                            <h5>Transport Cost Under <strong>{term}</strong> Term</h5>
                            <h5>Total Value Of Invoice</h5>
                            <h5>Total Number Of Package</h5>
                            <h5>Total Weight (KG)</h5>
                        </div>
                        <div className="invoice-value-container-right">
                            <div className="invoice-value-container-right-option">
                                <h5>{(Math.round(totalItemValue * 100) / 100).toFixed(2)} {toCountry === "Singapore" ? "SGD" : "USD"}</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5>{(Math.round(transportation * 100) / 100).toFixed(2) || "0.00"} {toCountry === "Singapore" ? "SGD" : "USD"}</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} {toCountry === "Singapore" ? "SGD" : "USD"}</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5>{totalPackage} PCS</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5>{(Math.round(totalGross * 100) / 100).toFixed(2)} KGS</h5>
                            </div>
                        </div>
                    </div>
                    <div className="invoice-value-text-last-content">
                        {term !== "Other" && <div className="invoice-value-text-primary">
                            <h5>I <strong>{fromName}</strong>.Declare that the articles here in specified are to the best of my knowledgeand and belief
                                Shipment was exported from VietNam under <strong>{term}</strong> term with the true quantity, prices, value and manufacturer <strong>{usage}</strong>.</h5>
                        </div>}
                        {term === "Other" && <div className="invoice-value-text-primary">
                            <h5>I <strong>{fromName}</strong>.Declare that the articles here in specified are to the best of my knowledgeand and belief
                                Shipment was exported from VietNam under <strong>{otherTerm}</strong> term with the true quantity, prices, value and manufacturer <strong>{usage}</strong>.</h5>
                        </div>}
                    </div>

                </div>
            </div>
        </div>
    )
}
