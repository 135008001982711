import { Avatar } from '@material-ui/core';
import { AssessmentOutlined, Person } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import Chart, {
    ArgumentAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    ValueAxis,
    ConstantLine,
    Series,
    Label,
} from 'devextreme-react/chart';
function customizeTooltip(pointInfo) {
    return {
        html: `<div><div class="tooltip-header">${pointInfo.argumentText
            }</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${pointInfo.points[0].seriesName
            }</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.points[0].valueText
            }</span></div><div class="series-name"><span class='bottom-series-name'>${pointInfo.points[1].seriesName
            }</span>: </div><div class="value-text"><span class='bottom-series-value'>${pointInfo.points[1].valueText
            }</span>% </div></div></div>`,
    };
}

function customizePercentageText({ valueText }) {
    return `${valueText}%`;
}
export default function Reporting({ userData, shipments }) {
    let newDate = new Date()
    let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const monthName = months[newDate.getMonth()];
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? "0" : "") + firstDay.getDate();
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const seven = date - 7;
    const history = useHistory();
    const [fixedStart, setFixedStart] = useState(`${year}-${month}-${first}`);
    const [fixedEnd, setFixedEnd] = useState(new Date().toISOString().substr(0, 10));
    const [recent7,setRecent7] = useState(`${year}-${month}-${seven}`)
    const [startDate,setStartDate] = useState(`${year}-${month}-${first}`);
    const [endDate,setEndDate] = useState(new Date().toISOString().substr(0, 10));
    const finalShipment = shipments.filter((s) => {
        return s.createAt >= startDate && s.createAt <= endDate;
    })
    const data = finalShipment.sort((a, b) => +b.totalCharge - +a.totalCharge);
    const totalCount = data.reduce((prevValue, item) => +prevValue + +item.totalCharge, 0);
    let cumulativeCount = 0;
    const dataArray = data.map((item) => {
        cumulativeCount += +item.totalCharge;
        return {
            country: item.toCountry,
            value: +item.totalCharge,
            cumulativePercentage: Math.round((cumulativeCount * 100) / totalCount),
        }
    })
    const last7day = shipments.filter((s) => {
        return s.createAt >= recent7 && s.createAt <= fixedEnd;
    })
    const thisMonthShipment = shipments.filter((s) => {
        return s.createAt >= fixedStart && s.createAt <= fixedEnd;
    })
    const todayShipment = shipments.filter((s) => {
        return s.createAt === fixedEnd;
    })
    return (
        <div className="skd-reporting">
            <div className="skd-reporting-container">
                <div className="skd-reporting-header">
                    <h3>Account Overview</h3>
                    <h5 style={{ fontWeight: 600, color: "gray" }}>{userData.email} ({userData.uid})</h5>
                </div>
                <div className="skd-reporting-content">
                    <div style={{ padding: 5, display: "flex", alignItems: "center" }}>
                        <h5 style={{ fontWeight: 600 }}>Find or track a shipment</h5>
                        <input type="text" placeholder="Find shipment..." />
                        <button type="button" onClick={() => history.push("/booking")}>Create shipment</button>
                    </div>
                </div>
                <div className="skd-reporting-report">
                    <div className="skd-reporting-report-primary">
                        {hour < 12 && hour > 1 && <h3>Good morning, {userData.exporter}</h3>}
                        {hour > 12 && hour < 18 && <h3>Good afternoon, {userData.exporter}</h3>}
                        {hour > 18 && hour <= 24 && <h3>Good evening, {userData.exporter}</h3>}
                        <h5>Looks like you are having new notification from us ?</h5>
                        <div className="skd-reporting-dash">
                            <div className="skd-reporting-dash-header">
                                <Person />
                                <div style={{ marginLeft: 7 }}>
                                    <h3>{userData.exporter} / {userData.country} / {userData.uid}</h3>
                                    <p style={{ fontWeight: 600, fontSize: 12 }}>{userData.email} - {userData.address} - {userData.state} - {userData.town} - {userData.city} - {userData.postal}</p>
                                </div>
                            </div>
                            <div className="skd-reporting-dash-body">
                                <div className="skd-reporting-unpaid">
                                    <h5>Total Shipment:</h5>
                                    <h5>{shipments.length} shipments</h5>
                                </div>
                                <div className="skd-reporting-paid">
                                    <h5>Last 7 days shipping:</h5>
                                    <h5>{last7day.length} shipments</h5>
                                </div>
                                <div className="skd-reporting-paid">
                                    <h5>Today shipping:</h5>
                                    <h5>{todayShipment.length} shipments</h5>
                                </div>
                                <div className="skd-reporting-paid">
                                    <h5>This month shipping:</h5>
                                    <h5>{thisMonthShipment.length} shipments</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="skd-reporting-stat">
                    <div className="skd-reporting-stat-head">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AssessmentOutlined />
                            <h3 style={{ fontWeight: 600, marginLeft: 5 }}>Metric</h3>
                        </div>
                        <div className="skd-reporting-date">
                            <h5 style={{ marginRight: 5, color: "gray" }}>From</h5>
                            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            <h5 style={{ marginLeft: 5, marginRight: 5 }}>---</h5>
                            <h5 style={{ marginRight: 5, color: "gray" }}>To</h5>
                            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>
                    </div>
                    <div className="skd-reporting-stat-body">
                        <Chart
                            title={`${userData.exporter}'s shipping metric`}
                            dataSource={shipments}
                            palette="Harmony Light"
                            id="chart"
                        > <CommonSeriesSettings argumentField="status" />
                            <Series
                                name="Shipment status"
                                valueField="totalCharge"
                                axis="frequency"
                                type="bar"
                                color="#0378d8"
                            />
                            <ArgumentAxis>
                                <Label overlappingBehavior="stagger" />
                            </ArgumentAxis>
                            <ValueAxis
                                name="frequency"
                                position="left"
                                tickInterval={300}
                            />
                            <ValueAxis
                                name="percentage"
                                position="right"
                                tickInterval={20}
                                showZero={true}
                                valueMarginsEnabled={false}
                            >
                                <Label customizeText={"haha"} />
                                <ConstantLine
                                    value={80}
                                    width={2}
                                    color="#fc3535"
                                    dashStyle="dash"
                                >
                                    <Label visible={false} />
                                </ConstantLine>
                            </ValueAxis>

                        </Chart>
                    </div>
                    <div className="skd-reporting-stat-body" style={{ marginTop: 50 }}>
                        <Chart
                            title="Most Shipping Area"
                            dataSource={dataArray}
                            palette="Harmony Light"
                            id="chart"
                        >
                            <CommonSeriesSettings argumentField="country" />
                            <Series
                                name="Weight frequency"
                                valueField="value"
                                axis="frequency"
                                type="bar"
                                color="#fac29a"
                            />
                            <Series
                                name="Cumulative percentage"
                                valueField="cumulativePercentage"
                                axis="percentage"
                                type="spline"
                                color="#6b71c3"
                            />

                            <ArgumentAxis>
                                <Label overlappingBehavior="stagger" />
                            </ArgumentAxis>

                            <ValueAxis
                                name="frequency"
                                position="left"
                                tickInterval={300}
                            />
                            <ValueAxis
                                name="percentage"
                                position="right"
                                tickInterval={20}
                                showZero={true}
                                valueMarginsEnabled={false}
                            >
                                <Label customizeText={customizePercentageText} />
                                <ConstantLine
                                    value={80}
                                    width={2}
                                    color="#fc3535"
                                    dashStyle="dash"
                                >
                                    <Label visible={false} />
                                </ConstantLine>
                            </ValueAxis>

                            <Tooltip
                                enabled={true}
                                shared={true}
                                customizeTooltip={customizeTooltip}
                            />

                            <Legend
                                verticalAlignment="top"
                                horizontalAlignment="center"
                            />
                        </Chart>
                    </div>

                </div>
            </div>
        </div>
    )
}
