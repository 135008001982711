import {createSlice} from "@reduxjs/toolkit";

export const shipmentSlice = createSlice({
    name: "shipment",
    initialState: {
        selectShipment: null,
    },

    reducers: {
        selectShipment: (state, action) => {
            state.selectedShipment = action.payload;
        },
    }
})

export const {selectShipment} = shipmentSlice.actions;
export const selectOpenShipment = (state) => state.shipment.selectedShipment;
export default shipmentSlice.reducer;