import { ArrowDropDown, ArrowForward, LocationOnOutlined } from '@material-ui/icons'
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { db } from "../firebase";
import firebase from "firebase";
export default function TracingContainer({ no, hawb, sin, code, fromCity, country, city, town, state, postal, status }) {
    const [showTracing, setShowTracing] = useState(false);
    const [courierStatus, setCourierStatus] = useState("");
    const [resultTracing, setResultTracing] = useState([]);
    const day = new Date();
    const hour1 = (day.getHours() < 10 ? "0" : "") + day.getHours();
    const min1 = (day.getMinutes() < 10 ? "0" : "") + day.getMinutes();
    const etdHour = +hour1.substring(0, 2) + 2;
    const finalEtd = `${etdHour}:${min1}`;
    const newDate = new Date();
    const day1 = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    const year = newDate.getFullYear();
    const traceTime = `${year}-${month}-${day1}`;
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const dateTime = (`${hour}:${min}`);
    const traceShipment = () => {
        setShowTracing(true);

    }
    useEffect(() => {
        if (sin !== "Null") {
            const options = {
                method: "GET",
                url: "https://api-eu.dhl.com/track/shipments",
                params: { trackingNumber: sin },
                headers: { "DHL-API-Key": "FwexXAXennaJZrqGGTBgP1CwSGjaaxRB" }
            }
            axios.request(options).then((res) => {
                setResultTracing(res.data.shipments[0].events);
                if (res.data.shipments[0].status.description === "Delivered") {
                    db.collection("shipments").doc(hawb).update({
                        status: "Delivered",
                        desc: firebase.firestore.FieldValue.arrayUnion(({
                            time: res.data.shipments[0].events[0].timestamp,
                            location: res.data.shipments[0].events[0].location.address.addressLocality,
                            stat: res.data.shipments[0].events[0].description,
                        }))
                    })
                    axios.put("https://test.skydart.com.vn/api/update/delivered", {
                        hawb: hawb,
                        status: "Delivered",
                    })
                } else {
                    console.log("Shipment này chưa được phát");
                }
            })
        }
    }, [sin])
    return (
        <div className="skydart-tracking">
            <div className="skydart-tracking-header">
                <h5><strong style={{ fontWeight: 600, background: "rgb(235,235,235)", borderRadius: 5, padding: 3, width: "10%", border: "1px solid lightgray" }}>No: {no}</strong> <strong style={{ textDecoration: "underline", color: "rgb(57, 108, 190)" }}>{hawb}</strong>, international awb <a href={`https://mydhl.express.dhl/us/en/tracking.html#/results?id=${sin}`} style={{textDecoration: "none"}} target="_blank"><strong style={{ background: "darkgreen", fontWeight: 600, padding: 1, color: "white" }}>({sin})</strong></a></h5>
            </div>
            <div className="skydart-tracking-content">
                <h5 style={{ fontSize: 11 }}>Shipping area:</h5>
                <div className="skydart-tracking-shipping-area">
                    <h5 style={{ fontSize: 11 }}>{fromCity}</h5>
                    <ArrowForward style={{ fontSize: 15 }} />
                    <img
                        loading="lazy"
                        width="18"
                        src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    <h5 style={{ fontSize: 11 }}>{country}({code}), {city}, {state}, {town}, {postal} </h5>
                </div>
            </div>
            <div className="skydart-tracking-status">
                <h5 style={{ fontSize: 11 }}>Current status: <strong style={{ color: "black", textDecoration: "underline" }}>{status}</strong>, Courier status: <strong style={{ color: "black", textDecoration: "underline" }}></strong> </h5>
                {!showTracing && <div className="skydart-tracking-status-container" onClick={() => setShowTracing(true)}>
                    <h3 style={{ marginLeft: 5, fontSize: 15 }}>Monitor shipment update</h3>
                    <ArrowDropDown />
                </div>}
                {showTracing && <div className="skydart-tracking-status-container1" onClick={() => setShowTracing(false)}>
                    {resultTracing.map((s,index) => (
                        <>
                            {s.description !== "Shipment picked up" && <div style={{borderBottom: "1px solid lightgray", marginTop: 7, marginLeft: 5, marginRight: 5}}>
                                <div className="skydart-track-detail">
                                    <div className="skydart-track-detail-left">
                                        <div style={{border: "1px solid", height: 20, width: 0, color: "lightgray", marginLeft: 10}}></div>
                                        <LocationOnOutlined htmlColor="green"/>    
                                    </div>    
                                    <div className="skydart-track-detail-right">
                                        <h3>{new Date(s.timestamp).toLocaleString()}</h3>
                                        <h5>{s.description.replace("DHL", "Skydart")}</h5>
                                        <p>Area: {s.location.address.addressLocality}</p>
                                    </div>
                                </div>  
                            </div>}
                        </>
                    ))}
                </div>}

            </div>
        </div>
    )
}
