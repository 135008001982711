import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { db, auth } from "./firebase";
import StaffView from './StaffView';
import { useHistory } from "react-router-dom";
import App from './App';
import CustomerView from './CustomerView';
import Err from './Err';
import { Build } from '@material-ui/icons';
import News from './News';
import { Package } from 'react-feather';
import Login from './Login';
export default function Welcome({ user }) {
    const [verify, setVerify] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [displayWelcome, setDisplayWelcome] = useState(false);
    useEffect(() => {
        if (user) {
            db.collection("users").doc(user.email).get().then(doc => {
                if (doc.exists) {
                    setVerify(doc.data().employee);
                } else {
                    setVerify([]);
                    setDisplayWelcome(true);
                }

            }).catch((err) => {
                console.log(err);
            })
        }


    }, [])
    // const getDoc = () => {
    //     setDisplayWelcome(false);
    //     setTimeout(() => {
    //         window.location.reload();
    //     },1500)
    // }

    if (verify === "yes") {
        return <StaffView user={user} />;

    }
    if (verify !== "yes") {
        return <CustomerView user={user} />;
    }
    return (
        <div className="welcome">
            {!displayWelcome && <center className="alert__loading">
                <CircularProgress size={50} />
            </center>}
            {displayWelcome && <center className="alert__loading">
                <CircularProgress size={50} />
                <Button variant="contained" onClick={() => auth.signOut()} style={{background: "green", color: "white", fontWeight: 600, textTransform: "capitalize"}}>Try Again</Button>
            </center>}
        </div>
    )
}