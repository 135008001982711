import { InputAdornment, Snackbar, styled, InputBase } from '@material-ui/core';
import { Add, ArrowDownward, CallReceived, ArrowRightAlt, Refresh, Clear, Crop, Check, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, GetApp, FileCopySharp, ClassOutlined, AllInbox, ViewAgenda, ViewAgendaOutlined, PageviewOutlined, LocalShipping, PanTool, ArrowLeft, ArrowRight } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Command, Copy, File, Filter, Minus, MoreHorizontal, Package } from 'react-feather';
import Avatar from "@material-ui/core/Avatar";
import { saveAs } from "file-saver";
import nammoi from "./nammoi.jpg";
import nghitet from "./nghitet.jpg";
import { BiMailSend } from "react-icons/bi";
import { CircularProgress, LinearProgress, IconButton, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MdClear, MdOutlineLayersClear } from "react-icons/md";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { app, db } from "../firebase";
import "./style.css";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";

import { FcAbout } from "react-icons/fc";
import { useReactToPrint } from 'react-to-print';
import { GrDocumentPdf } from "react-icons/gr";
import { AiOutlineCheckCircle, AiOutlineMinus } from 'react-icons/ai';
import translate from 'translate-google-api';
import { GrApps } from "react-icons/gr";
import { AiFillFilePdf } from "react-icons/ai";
import { IoBuildOutline } from "react-icons/io5";
import firebase from "firebase";
import { FaFileInvoice } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { RiBillLine } from "react-icons/ri";
import { CSVLink } from "react-csv";
import { BsBox, BsReplyAll } from "react-icons/bs";
import { ImNewspaper } from "react-icons/im";
import { FcCheckmark, FcHighPriority, FcOk } from 'react-icons/fc';
import ShippingMark from './ShippingMark';
import Inv2 from './Inv2';
import StaffBill2 from './StaffBill2';
import ReactToExcel from "react-html-table-to-excel";
import Month1 from './Month1';
import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import { GoCalendar } from "react-icons/go";
import DatepickerContext from './datepickerContext';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
const parseString = require('xml2js').parseString
export default function NewBooking({ userData, user }) {
    const [query, setQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [list, setList] = useState([]);
    const history = useHistory();
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? "0" : "");
    let last = firstDayOfCurrentWeek + 6;
    let firstday = firstDayOfCurrentWeek - 6;
    let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
    let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
    let yesterday = date - 1;
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    let lastMonth = month - 1;
    let year = newDate.getFullYear();
    let lastDay = new Date(year, lastMonth, 0);
    let firstDayOfYear = new Date(year, 0, 1);
    let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
    let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;

    const monthName = months[newDate.getMonth()];
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? "0" : "") + firstDay.getDate();
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const [alertShipment, setAlertShipment] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [displaySort, setDisplaySort] = useState(false);
    const [displayDateRange, setDisplayDateRange] = useState(false);
    const [status, setStatus] = useState("");
    const [detected, setDetected] = useState("");
    const [loading, setLoading] = useState(false);
    const [shipments, setShipments] = useState([]);
    const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
    const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
    const [searchHawb, setSearchHawb] = useState("");
    const [findSin, setFindSin] = useState("");
    const [findMawb, setFindMawb] = useState("");
    const [searchType, setSearchType] = useState("");
    const [searchCountry, setSearchCountry] = useState("");
    const [searchCode, setSearchCode] = useState("");
    const [searchCus, setSearchCus] = useState("");
    const [dateMethod, setDateMethod] = useState("range");
    const [searchWeight, setSearchWeight] = useState("");
    const [searchReweight, setSearchReweight] = useState("");
    const [searchPcs, setSearchPcs] = useState("");
    const [searchAirline, setSearchAirline] = useState("");
    const [searchService, setSearchService] = useState("");
    const [searchAgent, setSearchAgent] = useState("");
    const [searchMawb, setSearchMawb] = useState("");
    const [searchSin, setSearchSin] = useState("");
    const [searchPickup, setSearchPickup] = useState("");
    const [searchAcc, setSearchAcc] = useState("");
    const [searchCompany, setSearchCompany] = useState("");
    const [searchPack, setSearchPack] = useState("");
    const [searchSale, setSearchSale] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageNumberLimit, setPageNumberLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [displaySortCustomer, setDisplaySortCustomer] = useState(false);
    const [displaySortCountry, setDisplaySortCountry] = useState(false);
    const [displaySortMawb, setDisplaySortMawb] = useState(false);
    const [displaySortSin, setDisplaySortSin] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(false);
    const [displayType, setDisplayType] = useState(false);
    const [alphabet, setAlphabet] = useState(false);
    const [countryAlphabet, setCountryAlphabet] = useState(false);
    const [searchClass, setSearchClass] = useState("");
    const [displayBreak, setDisplayBreak] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [displayAction, setDisplayAction] = useState(false);
    const [displayFilter, setDisplayFilter] = useState(false);
    const [displayExportOption, setDisplayExportOption] = useState(false);
    const [displayExport, setDisplayExport] = useState(false);
    const [sortDate, setSortDate] = useState(false);
    const [excelFileName, setExcelFileName] = useState("");
    const [labels, setLabels] = useState("");
    const [scrollY, setScrollY] = useState(0);
    const [scrollX, setScrollX] = useState(0);
    const [labelURL, setLabelURL] = useState("");
    const [receiver, setReceiver] = useState("");
    const [displayReceiver, setDisplayReceiver] = useState(false);
    const [traceTime, setTraceTime] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [timeRange, setTimeRange] = useState("");
    const [receiveEmail, setReceiveEmail] = useState("");
    const [displayEditScreen, setDisplayEditScreen] = useState(false);
    const [mst, setMst] = useState("");
    const [alertUpdate, setAlertUpdate] = useState(false);
    const [loadShipment, setLoadShipment] = useState(false);
    const [selection, setSelection] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [hawb, setHawb] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [code, setCode] = useState("");
    const [value, setValue] = useState(0);
    const [country, setCountry] = useState("");
    const [fromCountry, setFromCountry] = useState("");
    const [importer, setImporter] = useState("");
    const [exporter, setExporter] = useState("");
    const [fromAddress, setFromAddress] = useState("");
    const [fromAddress2, setFromAddress2] = useState("");
    const [fromAddress3, setFromAddress3] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [fromTown, setFromTown] = useState("");
    const [town, setTown] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [city, setCity] = useState("");
    const [fromState, setFromState] = useState("");
    const [state, setState] = useState("");
    const [fromPostal, setFromPostal] = useState("");
    const [serviceLabel, setServiceLabel] = useState("");
    const [codeLabel, setCodeLabel] = useState("");
    const [postal, setPostal] = useState("");
    const [fromName, setFromName] = useState("")
    const [toName, setToName] = useState("");
    const [fromPhone, setFromPhone] = useState("");
    const [phone, setPhone] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [email, setEmail] = useState("");
    const [term, setTerm] = useState("");
    const [transport, setTransport] = useState(0);
    const [reason, setReason] = useState("");
    const [eori, setEori] = useState("");
    const [service, setService] = useState("");
    const [envelope, setEnvelope] = useState("");
    const [doxWeight, setDoxWeight] = useState(0.5);
    const [eamService, setEamService] = useState("");
    const [controller, setController] = useState("");
    const [stat, setStat] = useState("");
    const [pickupBy, setPickupBy] = useState("");
    const [createAt, setCreateAt] = useState("");
    const [pickup, setPickup] = useState("");
    const [arrive, setArrive] = useState("");
    const [process, setProcess] = useState("");
    const [clearance, setClearance] = useState("");
    const [clear, setClear] = useState("");
    const [hold, setHold] = useState("");
    const [selectPrint, setSelectPrint] = useState([]);
    const [labelArray, setLabelArray] = useState([]);
    const [mockInfo, setMockInfo] = useState([]);
    const [printOption, setPrintOption] = useState(false);
    const [doxErr, setDoxErr] = useState(false);
    const [displayFromCountry, setDisplayFromCountry] = useState(false);
    const [displayToCountry, setDisplayToCountry] = useState(false);
    const [currentState, setCurrentState] = useState(0);
    const [displayDate, setDisplayDate] = useState(false);
    const [dateStatus, setDateStatus] = useState("Period");
    const [displayHawbList, setDisplayHawbList] = useState(false);
    const [itemRange, setItemRange] = useState(0);
    const [displayInvoiceExport, setDisplayInvoiceExport] = useState(false);
    const [err, setErr] = useState(false);
    const [location, setLocation] = useState("SG");
    const [fileUrl, setFileUrl] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [method, setMethod] = useState("file");
    const [baseService, setBaseService] = useState("");
    const [load, setLoad] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [receivingLabel, setReceivingLabel] = useState(false);
    const [newYear, setNewYear] = useState(false);
    const [bookSuccess, setBookSuccess] = useState("");
    const [packageValue, setPackageValue] = useState([{
        packageLoading: false,
        packageLength: 0,
        packageWidth: 0,
        packageHeight: 0,
        packageWeight: 0,
        packageCharge: 0,
        packNo: 1,
    }]);
    const finalCharge = packageValue.reduce((a, v) => +a + +v.packageCharge, 0);
    const finalGross = packageValue.reduce((a, v) => +a + +v.packageWeight, 0);
    const [invoiceValue, setInvoiceValue] = useState([{
        itemCommonity: "",
        itemCountryOrigin: "Vietnam",
        itemManufactAddress: "",
        itemPackType: "",
        itemPackType2: "",
        itemHsCode: "",
        itemPnc: "",
        itemUnit: "",
        itemPurpose: "",
        itemManufact: "",
        itemUnitPrice: 0,
        itemQuantity: "",
        itemValue: 0,
        itemBrand: "",
        itemIngredients: "",
        itemNo: 1,
        itemFda: "",
        itemUnit2: "",
    }]);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE
    });
    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths
    } = useDatepicker({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        focusedInput: dateRange.focusedInput,
        onDatesChange: handleDateChange
    });
    function handleDateChange(data) {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE });
        } else {
            setDateRange(data);
        }
    }
    useEffect(() => {
        setTimeout(() => {
            setNewYear(true);
        }, 2000)
    }, [])
    const handleCancelDate = () => {
        setDisplayDate(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const d = new Date(dateRange.startDate);
    const selectMonth = ((d.getMonth() + 1) < 10 ? "0" : "") + (d.getMonth() + 1);
    const selectDate = (d.getDate() < 10 ? "0" : "") + d.getDate();
    const selectYear = d.getFullYear();


    const e = new Date(dateRange.endDate);
    const selectEndMonth = ((e.getMonth() + 1) < 10 ? "0" : "") + (e.getMonth() + 1);
    const selectEndDate = (e.getDate() < 10 ? "0" : "") + e.getDate();
    const selectEndYear = e.getFullYear();

    function getLastDayOfYear(year) {
        return new Date(year, 11, 32);
    }
    const currentYear = new Date().getFullYear();
    const handleApplyDate = () => {
        if (!dateRange.endDate) {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setDisplayDate(false);
            setDateStatus("Period");
        } else {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
            setDisplayDate(false);
            setDateStatus("Period");
        }
    }
    const handleToday = () => {
        setStartDate(`${year}-${month}-${date}`)
        setEndDate(`${year}-${month}-${date}`)
        setDisplayDate(false);
        setDateStatus("Today");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleYesterday = () => {
        if (yesterday === 0) {
            setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setDisplayDate(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        } else {
            setStartDate(`${year}-${month}-${yesterday}`);
            setEndDate(`${year}-${month}-${yesterday}`);
            setDisplayDate(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        }
    }
    const handleThisWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
        setDisplayDate(false);
        setDateStatus("This week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
        setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setDisplayDate(false);
        setDateStatus("Last week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }

    const handleThisMonth = () => {
        setStartDate(`${year}-${month}-${first}`)
        setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split("T")[0]);
        setDisplayDate(false);
        setDateStatus("This month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastMonth = () => {
        setStartDate(`${year}-${prevMonth}-01`)
        setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
        setDisplayDate(false);
        setDateStatus("Last month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleThisYear = () => {
        setStartDate(`${year}-01-01`);
        setEndDate(`${year}-12-31`);
        setDateStatus("This year");
        setDisplayDate(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${year}-${month}-${date}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, []);
    let customerRef = useRef();
    let countryRef = useRef();
    let actionRef = useRef();
    let typeRef = useRef();
    let fromCountryRef = useRef();
    let toCountryRef = useRef();
    let printAllRef = useRef();
    let labelRef = useRef();
    let invoiceRef = useRef();
    let shippingMarkRef = useRef();
    let dateRef = useRef();
    let hawbRef = useRef();
    let invoiceRef1 = useRef();
    let exportRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!customerRef.current.contains(e.target)) {
                setDisplaySortCustomer(false);
            }
            if (!exportRef.current.contains(e.target)) {
                setDisplayExportOption(false);
            }
            if (!countryRef.current.contains(e.target)) {
                setDisplaySortCountry(false);
            }
            if (!actionRef.current.contains(e.target)) {
                setDisplayAction(false);
            }
            if (!typeRef.current.contains(e.target)) {
                setDisplayFilter(false);
            }
            if (!printRefContainer.current.contains(e.target)) {
                setPrintOption(false);
            }
            if (!fromCountryRef.current.contains(e.target)) {
                setDisplayFromCountry(false);
            }
            if (!toCountryRef.current.contains(e.target)) {
                setDisplayToCountry(false);
            }
            if (!dateRef.current.contains(e.target)) {
                setDisplayDate(false);
            }
            if (!hawbRef.current.contains(e.target)) {
                setDisplayHawbList(false);
            }
            if (!invoiceRef1.current.contains(e.target)) {
                setDisplayInvoiceExport(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    function resetShipment(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertShipment(false);
    }
    function resetSuccess(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertSuccess(false);
        setAlertUpdate(false);
    }
    function resetUpdate(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertUpdate(false);
    }
    const renderDateRange = () => {
        setDisplayDateRange(true);
    }


    const renderSort = () => {
        setDisplaySort(true);
    }
    if (window.screen.width < 600) {
        history.push("/phone-access");
    }
    useEffect(() => {
        refreshShipment();
    }, [])
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const refreshShipment = () => {
        setLoading(true);
        db.collection("shipments").orderBy("timestamp", "desc").where("status", "==", "Pending").get().then((snapshot) => {
            let data = snapshot.docs.map(doc => doc.data());
            setShipments(data.map((s, i) => {
                let dhlAccount = "";
                let cleName = "";
                let clePhone = "";
                let cleEmail = "";
                let cleState = "";
                let pcsWeight = s.shipmentType === "WPX" ? s.packageValue?.reduce((a, v) => a = +a + +v.packageCharge, 0) : 0;
                let pcsLength = s.shipmentType === "WPX" ? s.packageValue?.reduce((a, v) => a = +a + +v.packageLength, 0) : 0;
                let pcsWidth = s.shipmentType === "WPX" ? s.packageValue?.reduce((a, v) => a = +a + +v.packageWidth, 0) : 0;
                let pcsHeight = s.shipmentType === "WPX" ? s.packageValue?.reduce((a, v) => a = +a + +v.packageHeight, 0) : 0;
                if (s.toCountry === "United States") {
                    dhlAccount = "611051446";
                    cleName = "C/O NVH TRADING SERVICES PTE LTD";
                    clePhone = "65459990";
                    cleEmail = "cs@courierlink.com.sg";
                } else if (s.toCountry !== "United States" && s.shipmentType === "WPX") {
                    dhlAccount = "610799051";
                    cleName = "C/O ANTRON SERVICES";
                    clePhone = "65423395";
                    cleState = s.toState;
                    cleEmail = "angie.antron@gmail.com";
                } else if (s.toCountry !== "United States" && s.shipmentType === "DOX") {
                    dhlAccount = "610654958";
                    cleName = "C/O ANTRON SERVICES";
                    clePhone = "65423395";
                    cleState = s.toState;
                    cleEmail = "angie.antron@gmail.com";
                }

                let value = s.totalInvoiceValue;
                let updated = s.updated || "false";
                let select = s.select || false;
                let multi = s.multi || false;
                let loading = false;
                let eamFormat = s.shipmentType === "DOX" ? "DOX" : "NDX";
                let removeSkd = s.hawb.substring(3);
                let itemValue = s.invoiceValue[0]?.itemValue;
                let itemValue2 = s.invoiceValue[1]?.itemValue;
                let itemValue3 = s.invoiceValue[2]?.itemValue;
                let itemValue4 = s.invoiceValue[3]?.itemValue;
                let itemValue5 = s.invoiceValue[4]?.itemValue;
                let itemValue6 = s.invoiceValue[5]?.itemValue;
                let itemValue7 = s.invoiceValue[6]?.itemValue;
                let itemValue8 = s.invoiceValue[7]?.itemValue;
                let itemValue9 = s.invoiceValue[8]?.itemValue;
                let itemValue10 = s.invoiceValue[9]?.itemValue;
                let itemValue11 = s.invoiceValue[10]?.itemValue;
                let itemValue12 = s.invoiceValue[11]?.itemValue;
                let itemValue13 = s.invoiceValue[12]?.itemValue;
                let itemValue14 = s.invoiceValue[13]?.itemValue;
                let itemValue15 = s.invoiceValue[14]?.itemValue;
                let itemValue16 = s.invoiceValue[15]?.itemValue;
                let itemValue17 = s.invoiceValue[16]?.itemValue;
                let itemValue18 = s.invoiceValue[17]?.itemValue;
                let itemValue19 = s.invoiceValue[18]?.itemValue;
                let itemValue20 = s.invoiceValue[19]?.itemValue;
                let itemNo = s.invoiceValue[0]?.itemNo;
                let itemNo2 = s.invoiceValue[1]?.itemNo || "";
                let itemNo3 = s.invoiceValue[2]?.itemNo || "";
                let itemNo4 = s.invoiceValue[3]?.itemNo || "";
                let itemNo5 = s.invoiceValue[4]?.itemNo || "";
                let itemNo6 = s.invoiceValue[5]?.itemNo || "";
                let itemNo7 = s.invoiceValue[6]?.itemNo || "";
                let itemNo8 = s.invoiceValue[7]?.itemNo || "";
                let itemNo9 = s.invoiceValue[8]?.itemNo || "";
                let itemNo10 = s.invoiceValue[9]?.itemNo || "";
                let itemNo11 = s.invoiceValue[10]?.itemNo || "";
                let itemNo12 = s.invoiceValue[11]?.itemNo || "";
                let itemNo13 = s.invoiceValue[12]?.itemNo || "";
                let itemNo14 = s.invoiceValue[13]?.itemNo || "";
                let itemNo15 = s.invoiceValue[14]?.itemNo || "";
                let itemNo16 = s.invoiceValue[15]?.itemNo || "";
                let itemNo17 = s.invoiceValue[16]?.itemNo || "";
                let itemNo18 = s.invoiceValue[17]?.itemNo || "";
                let itemNo19 = s.invoiceValue[18]?.itemNo || "";
                let itemNo20 = s.invoiceValue[19]?.itemNo || "";
                let itemNo21 = s.invoiceValue[20]?.itemNo || "";
                let itemNo22 = s.invoiceValue[21]?.itemNo || "";
                let itemNo23 = s.invoiceValue[22]?.itemNo || "";
                let itemNo24 = s.invoiceValue[23]?.itemNo || "";
                let itemNo25 = s.invoiceValue[24]?.itemNo || "";
                let itemNo26 = s.invoiceValue[25]?.itemNo || "";
                let itemNo27 = s.invoiceValue[26]?.itemNo || "";
                let itemNo28 = s.invoiceValue[27]?.itemNo || "";
                let itemNo29 = s.invoiceValue[28]?.itemNo || "";
                let itemNo30 = s.invoiceValue[29]?.itemNo || "";
                let itemNo31 = s.invoiceValue[30]?.itemNo || "";
                let itemNo32 = s.invoiceValue[31]?.itemNo || "";
                let itemNo33 = s.invoiceValue[32]?.itemNo || "";
                let itemNo34 = s.invoiceValue[33]?.itemNo || "";
                let itemNo35 = s.invoiceValue[34]?.itemNo || "";
                let itemNo36 = s.invoiceValue[35]?.itemNo || "";
                let itemNo37 = s.invoiceValue[36]?.itemNo || "";
                let itemNo38 = s.invoiceValue[37]?.itemNo || "";
                let itemNo39 = s.invoiceValue[38]?.itemNo || "";
                let itemNo40 = s.invoiceValue[39]?.itemNo || "";
                let itemNo41 = s.invoiceValue[40]?.itemNo || "";
                let itemNo42 = s.invoiceValue[41]?.itemNo || "";
                let itemNo43 = s.invoiceValue[42]?.itemNo || "";
                let itemNo44 = s.invoiceValue[43]?.itemNo || "";
                let itemNo45 = s.invoiceValue[44]?.itemNo || "";
                let itemNo46 = s.invoiceValue[45]?.itemNo || "";
                let itemNo47 = s.invoiceValue[46]?.itemNo || "";
                let itemNo48 = s.invoiceValue[47]?.itemNo || "";
                let itemNo49 = s.invoiceValue[48]?.itemNo || "";
                let itemNo50 = s.invoiceValue[49]?.itemNo || "";
                let itemNo51 = s.invoiceValue[50]?.itemNo || "";
                let itemNo52 = s.invoiceValue[51]?.itemNo || "";
                let itemNo53 = s.invoiceValue[52]?.itemNo || "";
                let itemNo54 = s.invoiceValue[53]?.itemNo || "";
                let itemNo55 = s.invoiceValue[54]?.itemNo || "";
                let itemNo56 = s.invoiceValue[55]?.itemNo || "";
                let itemNo57 = s.invoiceValue[56]?.itemNo || "";
                let itemNo58 = s.invoiceValue[57]?.itemNo || "";
                let itemNo59 = s.invoiceValue[58]?.itemNo || "";
                let itemNo60 = s.invoiceValue[59]?.itemNo || "";
                let itemNo61 = s.invoiceValue[60]?.itemNo || "";
                let itemNo62 = s.invoiceValue[61]?.itemNo || "";
                let itemNo63 = s.invoiceValue[62]?.itemNo || "";
                let itemNo64 = s.invoiceValue[63]?.itemNo || "";
                let itemNo65 = s.invoiceValue[64]?.itemNo || "";
                let itemNo66 = s.invoiceValue[65]?.itemNo || "";
                let itemNo67 = s.invoiceValue[66]?.itemNo || "";
                let itemNo68 = s.invoiceValue[67]?.itemNo || "";
                let itemNo69 = s.invoiceValue[68]?.itemNo || "";
                let itemNo70 = s.invoiceValue[69]?.itemNo || "";
                let itemNo71 = s.invoiceValue[70]?.itemNo || "";
                let itemNo72 = s.invoiceValue[71]?.itemNo || "";
                let itemNo73 = s.invoiceValue[72]?.itemNo || "";
                let itemNo74 = s.invoiceValue[73]?.itemNo || "";
                let itemNo75 = s.invoiceValue[74]?.itemNo || "";
                let itemNo76 = s.invoiceValue[75]?.itemNo || "";
                let itemNo77 = s.invoiceValue[76]?.itemNo || "";
                let itemNo78 = s.invoiceValue[77]?.itemNo || "";
                let itemNo79 = s.invoiceValue[78]?.itemNo || "";
                let itemNo80 = s.invoiceValue[79]?.itemNo || "";
                let itemNo81 = s.invoiceValue[80]?.itemNo || "";
                let itemNo82 = s.invoiceValue[81]?.itemNo || "";
                let itemNo83 = s.invoiceValue[82]?.itemNo || "";
                let itemNo84 = s.invoiceValue[83]?.itemNo || "";
                let itemNo85 = s.invoiceValue[84]?.itemNo || "";
                let itemNo86 = s.invoiceValue[85]?.itemNo || "";
                let itemNo87 = s.invoiceValue[86]?.itemNo || "";
                let itemNo88 = s.invoiceValue[87]?.itemNo || "";
                let itemNo89 = s.invoiceValue[88]?.itemNo || "";
                let itemNo90 = s.invoiceValue[89]?.itemNo || "";
                let itemNo91 = s.invoiceValue[90]?.itemNo || "";
                let itemNo92 = s.invoiceValue[91]?.itemNo || "";
                let itemNo93 = s.invoiceValue[92]?.itemNo || "";
                let itemNo94 = s.invoiceValue[93]?.itemNo || "";
                let itemNo95 = s.invoiceValue[94]?.itemNo || "";
                let itemNo96 = s.invoiceValue[95]?.itemNo || "";
                let itemNo97 = s.invoiceValue[96]?.itemNo || "";
                let itemNo98 = s.invoiceValue[97]?.itemNo || "";
                let itemNo99 = s.invoiceValue[98]?.itemNo || "";
                let itemNo100 = s.invoiceValue[99]?.itemNo || "";
                let quantity = s.invoiceValue[0]?.itemQuantity;
                let quantity2 = s.invoiceValue[1]?.itemQuantity || "";
                let quantity3 = s.invoiceValue[2]?.itemQuantity || "";
                let quantity4 = s.invoiceValue[3]?.itemQuantity || "";
                let quantity5 = s.invoiceValue[4]?.itemQuantity || "";
                let quantity6 = s.invoiceValue[5]?.itemQuantity || "";
                let quantity7 = s.invoiceValue[6]?.itemQuantity || "";
                let quantity8 = s.invoiceValue[7]?.itemQuantity || "";
                let quantity9 = s.invoiceValue[8]?.itemQuantity || "";
                let quantity10 = s.invoiceValue[9]?.itemQuantity || "";
                let quantity11 = s.invoiceValue[10]?.itemQuantity || "";
                let quantity12 = s.invoiceValue[11]?.itemQuantity || "";
                let quantity13 = s.invoiceValue[12]?.itemQuantity || "";
                let quantity14 = s.invoiceValue[13]?.itemQuantity || "";
                let quantity15 = s.invoiceValue[14]?.itemQuantity || "";
                let quantity16 = s.invoiceValue[15]?.itemQuantity || "";
                let quantity17 = s.invoiceValue[16]?.itemQuantity || "";
                let quantity18 = s.invoiceValue[17]?.itemQuantity || "";
                let quantity19 = s.invoiceValue[18]?.itemQuantity || "";
                let quantity20 = s.invoiceValue[19]?.itemQuantity || "";
                let quantity21 = s.invoiceValue[20]?.itemQuantity || "";
                let quantity22 = s.invoiceValue[21]?.itemQuantity || "";
                let quantity23 = s.invoiceValue[22]?.itemQuantity || "";
                let quantity24 = s.invoiceValue[23]?.itemQuantity || "";
                let quantity25 = s.invoiceValue[24]?.itemQuantity || "";
                let quantity26 = s.invoiceValue[25]?.itemQuantity || "";
                let quantity27 = s.invoiceValue[26]?.itemQuantity || "";
                let quantity28 = s.invoiceValue[27]?.itemQuantity || "";
                let quantity29 = s.invoiceValue[28]?.itemQuantity || "";
                let quantity30 = s.invoiceValue[29]?.itemQuantity || "";
                let quantity31 = s.invoiceValue[30]?.itemQuantity || "";
                let quantity32 = s.invoiceValue[31]?.itemQuantity || "";
                let quantity33 = s.invoiceValue[32]?.itemQuantity || "";
                let quantity34 = s.invoiceValue[33]?.itemQuantity || "";
                let quantity35 = s.invoiceValue[34]?.itemQuantity || "";
                let quantity36 = s.invoiceValue[35]?.itemQuantity || "";
                let quantity37 = s.invoiceValue[36]?.itemQuantity || "";
                let quantity38 = s.invoiceValue[37]?.itemQuantity || "";
                let quantity39 = s.invoiceValue[38]?.itemQuantity || "";
                let quantity40 = s.invoiceValue[39]?.itemQuantity || "";
                let quantity41 = s.invoiceValue[40]?.itemQuantity || "";
                let quantity42 = s.invoiceValue[41]?.itemQuantity || "";
                let quantity43 = s.invoiceValue[42]?.itemQuantity || "";
                let quantity44 = s.invoiceValue[43]?.itemQuantity || "";
                let quantity45 = s.invoiceValue[44]?.itemQuantity || "";
                let quantity46 = s.invoiceValue[45]?.itemQuantity || "";
                let quantity47 = s.invoiceValue[46]?.itemQuantity || "";
                let quantity48 = s.invoiceValue[47]?.itemQuantity || "";
                let quantity49 = s.invoiceValue[48]?.itemQuantity || "";
                let quantity50 = s.invoiceValue[49]?.itemQuantity || "";
                let quantity51 = s.invoiceValue[50]?.itemQuantity || "";
                let quantity52 = s.invoiceValue[51]?.itemQuantity || "";
                let quantity53 = s.invoiceValue[52]?.itemQuantity || "";
                let quantity54 = s.invoiceValue[53]?.itemQuantity || "";
                let quantity55 = s.invoiceValue[54]?.itemQuantity || "";
                let quantity56 = s.invoiceValue[55]?.itemQuantity || "";
                let quantity57 = s.invoiceValue[56]?.itemQuantity || "";
                let quantity58 = s.invoiceValue[57]?.itemQuantity || "";
                let quantity59 = s.invoiceValue[58]?.itemQuantity || "";
                let quantity60 = s.invoiceValue[59]?.itemQuantity || "";
                let quantity61 = s.invoiceValue[60]?.itemQuantity || "";
                let quantity62 = s.invoiceValue[61]?.itemQuantity || "";
                let quantity63 = s.invoiceValue[62]?.itemQuantity || "";
                let quantity64 = s.invoiceValue[63]?.itemQuantity || "";
                let quantity65 = s.invoiceValue[64]?.itemQuantity || "";
                let quantity66 = s.invoiceValue[65]?.itemQuantity || "";
                let quantity67 = s.invoiceValue[66]?.itemQuantity || "";
                let quantity68 = s.invoiceValue[67]?.itemQuantity || "";
                let quantity69 = s.invoiceValue[68]?.itemQuantity || "";
                let quantity70 = s.invoiceValue[69]?.itemQuantity || "";
                let quantity71 = s.invoiceValue[70]?.itemQuantity || "";
                let quantity72 = s.invoiceValue[71]?.itemQuantity || "";
                let quantity73 = s.invoiceValue[72]?.itemQuantity || "";
                let quantity74 = s.invoiceValue[73]?.itemQuantity || "";
                let quantity75 = s.invoiceValue[74]?.itemQuantity || "";
                let quantity76 = s.invoiceValue[75]?.itemQuantity || "";
                let quantity77 = s.invoiceValue[76]?.itemQuantity || "";
                let quantity78 = s.invoiceValue[77]?.itemQuantity || "";
                let quantity79 = s.invoiceValue[78]?.itemQuantity || "";
                let quantity80 = s.invoiceValue[79]?.itemQuantity || "";
                let quantity81 = s.invoiceValue[80]?.itemQuantity || "";
                let quantity82 = s.invoiceValue[81]?.itemQuantity || "";
                let quantity83 = s.invoiceValue[82]?.itemQuantity || "";
                let quantity84 = s.invoiceValue[83]?.itemQuantity || "";
                let quantity85 = s.invoiceValue[84]?.itemQuantity || "";
                let quantity86 = s.invoiceValue[85]?.itemQuantity || "";
                let quantity87 = s.invoiceValue[86]?.itemQuantity || "";
                let quantity88 = s.invoiceValue[87]?.itemQuantity || "";
                let quantity89 = s.invoiceValue[88]?.itemQuantity || "";
                let quantity90 = s.invoiceValue[89]?.itemQuantity || "";
                let quantity91 = s.invoiceValue[90]?.itemQuantity || "";
                let quantity92 = s.invoiceValue[91]?.itemQuantity || "";
                let quantity93 = s.invoiceValue[92]?.itemQuantity || "";
                let quantity94 = s.invoiceValue[93]?.itemQuantity || "";
                let quantity95 = s.invoiceValue[94]?.itemQuantity || "";
                let quantity96 = s.invoiceValue[95]?.itemQuantity || "";
                let quantity97 = s.invoiceValue[96]?.itemQuantity || "";
                let quantity98 = s.invoiceValue[97]?.itemQuantity || "";
                let quantity99 = s.invoiceValue[98]?.itemQuantity || "";
                let quantity100 = s.invoiceValue[99]?.itemQuantity || "";
                let unit = s.invoiceValue[0]?.itemUnit;
                let unit2 = s.invoiceValue[1]?.itemUnit || "";
                let unit3 = s.invoiceValue[2]?.itemUnit || "";
                let unit4 = s.invoiceValue[3]?.itemUnit || "";
                let unit5 = s.invoiceValue[4]?.itemUnit || "";
                let unit6 = s.invoiceValue[5]?.itemUnit || "";
                let unit7 = s.invoiceValue[6]?.itemUnit || "";
                let unit8 = s.invoiceValue[7]?.itemUnit || "";
                let unit9 = s.invoiceValue[8]?.itemUnit || "";
                let unit10 = s.invoiceValue[9]?.itemUnit || "";
                let unit11 = s.invoiceValue[10]?.itemUnit || "";
                let unit12 = s.invoiceValue[11]?.itemUnit || "";
                let unit13 = s.invoiceValue[12]?.itemUnit || "";
                let unit14 = s.invoiceValue[13]?.itemUnit || "";
                let unit15 = s.invoiceValue[14]?.itemUnit || "";
                let unit16 = s.invoiceValue[15]?.itemUnit || "";
                let unit17 = s.invoiceValue[16]?.itemUnit || "";
                let unit18 = s.invoiceValue[17]?.itemUnit || "";
                let unit19 = s.invoiceValue[18]?.itemUnit || "";
                let unit20 = s.invoiceValue[19]?.itemUnit || "";
                let unit21 = s.invoiceValue[20]?.itemUnit || "";
                let unit22 = s.invoiceValue[21]?.itemUnit || "";
                let unit23 = s.invoiceValue[22]?.itemUnit || "";
                let unit24 = s.invoiceValue[23]?.itemUnit || "";
                let unit25 = s.invoiceValue[24]?.itemUnit || "";
                let unit26 = s.invoiceValue[25]?.itemUnit || "";
                let unit27 = s.invoiceValue[26]?.itemUnit || "";
                let unit28 = s.invoiceValue[27]?.itemUnit || "";
                let unit29 = s.invoiceValue[28]?.itemUnit || "";
                let unit30 = s.invoiceValue[29]?.itemUnit || "";
                let unit31 = s.invoiceValue[30]?.itemUnit || "";
                let unit32 = s.invoiceValue[31]?.itemUnit || "";
                let unit33 = s.invoiceValue[32]?.itemUnit || "";
                let unit34 = s.invoiceValue[33]?.itemUnit || "";
                let unit35 = s.invoiceValue[34]?.itemUnit || "";
                let unit36 = s.invoiceValue[35]?.itemUnit || "";
                let unit37 = s.invoiceValue[36]?.itemUnit || "";
                let unit38 = s.invoiceValue[37]?.itemUnit || "";
                let unit39 = s.invoiceValue[38]?.itemUnit || "";
                let unit40 = s.invoiceValue[39]?.itemUnit || "";
                let unit41 = s.invoiceValue[40]?.itemUnit || "";
                let unit42 = s.invoiceValue[41]?.itemUnit || "";
                let unit43 = s.invoiceValue[42]?.itemUnit || "";
                let unit44 = s.invoiceValue[43]?.itemUnit || "";
                let unit45 = s.invoiceValue[44]?.itemUnit || "";
                let unit46 = s.invoiceValue[45]?.itemUnit || "";
                let unit47 = s.invoiceValue[46]?.itemUnit || "";
                let unit48 = s.invoiceValue[47]?.itemUnit || "";
                let unit49 = s.invoiceValue[48]?.itemUnit || "";
                let unit50 = s.invoiceValue[49]?.itemUnit || "";
                let unit51 = s.invoiceValue[50]?.itemUnit || "";
                let unit52 = s.invoiceValue[51]?.itemUnit || "";
                let unit53 = s.invoiceValue[52]?.itemUnit || "";
                let unit54 = s.invoiceValue[53]?.itemUnit || "";
                let unit55 = s.invoiceValue[54]?.itemUnit || "";
                let unit56 = s.invoiceValue[55]?.itemUnit || "";
                let unit57 = s.invoiceValue[56]?.itemUnit || "";
                let unit58 = s.invoiceValue[57]?.itemUnit || "";
                let unit59 = s.invoiceValue[58]?.itemUnit || "";
                let unit60 = s.invoiceValue[59]?.itemUnit || "";
                let unit61 = s.invoiceValue[60]?.itemUnit || "";
                let unit62 = s.invoiceValue[61]?.itemUnit || "";
                let unit63 = s.invoiceValue[62]?.itemUnit || "";
                let unit64 = s.invoiceValue[63]?.itemUnit || "";
                let unit65 = s.invoiceValue[64]?.itemUnit || "";
                let unit66 = s.invoiceValue[65]?.itemUnit || "";
                let unit67 = s.invoiceValue[66]?.itemUnit || "";
                let unit68 = s.invoiceValue[67]?.itemUnit || "";
                let unit69 = s.invoiceValue[68]?.itemUnit || "";
                let unit70 = s.invoiceValue[69]?.itemUnit || "";
                let unit71 = s.invoiceValue[70]?.itemUnit || "";
                let unit72 = s.invoiceValue[71]?.itemUnit || "";
                let unit73 = s.invoiceValue[72]?.itemUnit || "";
                let unit74 = s.invoiceValue[73]?.itemUnit || "";
                let unit75 = s.invoiceValue[74]?.itemUnit || "";
                let unit76 = s.invoiceValue[75]?.itemUnit || "";
                let unit77 = s.invoiceValue[76]?.itemUnit || "";
                let unit78 = s.invoiceValue[77]?.itemUnit || "";
                let unit79 = s.invoiceValue[78]?.itemUnit || "";
                let unit80 = s.invoiceValue[79]?.itemUnit || "";
                let unit81 = s.invoiceValue[80]?.itemUnit || "";
                let unit82 = s.invoiceValue[81]?.itemUnit || "";
                let unit83 = s.invoiceValue[82]?.itemUnit || "";
                let unit84 = s.invoiceValue[83]?.itemUnit || "";
                let unit85 = s.invoiceValue[84]?.itemUnit || "";
                let unit86 = s.invoiceValue[85]?.itemUnit || "";
                let unit87 = s.invoiceValue[86]?.itemUnit || "";
                let unit88 = s.invoiceValue[87]?.itemUnit || "";
                let unit89 = s.invoiceValue[88]?.itemUnit || "";
                let unit90 = s.invoiceValue[89]?.itemUnit || "";
                let unit91 = s.invoiceValue[90]?.itemUnit || "";
                let unit92 = s.invoiceValue[91]?.itemUnit || "";
                let unit93 = s.invoiceValue[92]?.itemUnit || "";
                let unit94 = s.invoiceValue[93]?.itemUnit || "";
                let unit95 = s.invoiceValue[94]?.itemUnit || "";
                let unit96 = s.invoiceValue[95]?.itemUnit || "";
                let unit97 = s.invoiceValue[96]?.itemUnit || "";
                let unit98 = s.invoiceValue[97]?.itemUnit || "";
                let unit99 = s.invoiceValue[98]?.itemUnit || "";
                let unit100 = s.invoiceValue[99]?.itemUnit || "";
                let itemName = s.invoiceValue[0]?.itemCommonity;
                let itemName2 = s.invoiceValue[1]?.itemCommonity || "";
                let itemName3 = s.invoiceValue[2]?.itemCommonity || "";
                let itemName4 = s.invoiceValue[3]?.itemCommonity || "";
                let itemName5 = s.invoiceValue[4]?.itemCommonity || "";
                let itemName6 = s.invoiceValue[5]?.itemCommonity || "";
                let itemName7 = s.invoiceValue[6]?.itemCommonity || "";
                let itemName8 = s.invoiceValue[7]?.itemCommonity || "";
                let itemName9 = s.invoiceValue[8]?.itemCommonity || "";
                let itemName10 = s.invoiceValue[9]?.itemCommonity || "";
                let itemName11 = s.invoiceValue[10]?.itemCommonity || "";
                let itemName12 = s.invoiceValue[11]?.itemCommonity || "";
                let itemName13 = s.invoiceValue[12]?.itemCommonity || "";
                let itemName14 = s.invoiceValue[13]?.itemCommonity || "";
                let itemName15 = s.invoiceValue[14]?.itemCommonity || "";
                let itemName16 = s.invoiceValue[15]?.itemCommonity || "";
                let itemName17 = s.invoiceValue[16]?.itemCommonity || "";
                let itemName18 = s.invoiceValue[17]?.itemCommonity || "";
                let itemName19 = s.invoiceValue[18]?.itemCommonity || "";
                let itemName20 = s.invoiceValue[19]?.itemCommonity || "";
                let itemName21 = s.invoiceValue[20]?.itemCommonity || "";
                let itemName22 = s.invoiceValue[21]?.itemCommonity || "";
                let itemName23 = s.invoiceValue[22]?.itemCommonity || "";
                let itemName24 = s.invoiceValue[23]?.itemCommonity || "";
                let itemName25 = s.invoiceValue[24]?.itemCommonity || "";
                let itemName26 = s.invoiceValue[25]?.itemCommonity || "";
                let itemName27 = s.invoiceValue[26]?.itemCommonity || "";
                let itemName28 = s.invoiceValue[27]?.itemCommonity || "";
                let itemName29 = s.invoiceValue[28]?.itemCommonity || "";
                let itemName30 = s.invoiceValue[29]?.itemCommonity || "";
                let itemName31 = s.invoiceValue[30]?.itemCommonity || "";
                let itemName32 = s.invoiceValue[31]?.itemCommonity || "";
                let itemName33 = s.invoiceValue[32]?.itemCommonity || "";
                let itemName34 = s.invoiceValue[33]?.itemCommonity || "";
                let itemName35 = s.invoiceValue[34]?.itemCommonity || "";
                let itemName36 = s.invoiceValue[35]?.itemCommonity || "";
                let itemName37 = s.invoiceValue[36]?.itemCommonity || "";
                let itemName38 = s.invoiceValue[37]?.itemCommonity || "";
                let itemName39 = s.invoiceValue[38]?.itemCommonity || "";
                let itemName40 = s.invoiceValue[39]?.itemCommonity || "";
                let itemName41 = s.invoiceValue[40]?.itemCommonity || "";
                let itemName42 = s.invoiceValue[41]?.itemCommonity || "";
                let itemName43 = s.invoiceValue[42]?.itemCommonity || "";
                let itemName44 = s.invoiceValue[43]?.itemCommonity || "";
                let itemName45 = s.invoiceValue[44]?.itemCommonity || "";
                let itemName46 = s.invoiceValue[45]?.itemCommonity || "";
                let itemName47 = s.invoiceValue[46]?.itemCommonity || "";
                let itemName48 = s.invoiceValue[47]?.itemCommonity || "";
                let itemName49 = s.invoiceValue[48]?.itemCommonity || "";
                let itemName50 = s.invoiceValue[49]?.itemCommonity || "";
                let itemName51 = s.invoiceValue[50]?.itemCommonity || "";
                let itemName52 = s.invoiceValue[51]?.itemCommonity || "";
                let itemName53 = s.invoiceValue[52]?.itemCommonity || "";
                let itemName54 = s.invoiceValue[53]?.itemCommonity || "";
                let itemName55 = s.invoiceValue[54]?.itemCommonity || "";
                let itemName56 = s.invoiceValue[55]?.itemCommonity || "";
                let itemName57 = s.invoiceValue[56]?.itemCommonity || "";
                let itemName58 = s.invoiceValue[57]?.itemCommonity || "";
                let itemName59 = s.invoiceValue[58]?.itemCommonity || "";
                let itemName60 = s.invoiceValue[59]?.itemCommonity || "";
                let itemName61 = s.invoiceValue[60]?.itemCommonity || "";
                let itemName62 = s.invoiceValue[61]?.itemCommonity || "";
                let itemName63 = s.invoiceValue[62]?.itemCommonity || "";
                let itemName64 = s.invoiceValue[63]?.itemCommonity || "";
                let itemName65 = s.invoiceValue[64]?.itemCommonity || "";
                let itemName66 = s.invoiceValue[65]?.itemCommonity || "";
                let itemName67 = s.invoiceValue[66]?.itemCommonity || "";
                let itemName68 = s.invoiceValue[67]?.itemCommonity || "";
                let itemName69 = s.invoiceValue[68]?.itemCommonity || "";
                let itemName70 = s.invoiceValue[69]?.itemCommonity || "";
                let itemName71 = s.invoiceValue[70]?.itemCommonity || "";
                let itemName72 = s.invoiceValue[71]?.itemCommonity || "";
                let itemName73 = s.invoiceValue[72]?.itemCommonity || "";
                let itemName74 = s.invoiceValue[73]?.itemCommonity || "";
                let itemName75 = s.invoiceValue[74]?.itemCommonity || "";
                let itemName76 = s.invoiceValue[75]?.itemCommonity || "";
                let itemName77 = s.invoiceValue[76]?.itemCommonity || "";
                let itemName78 = s.invoiceValue[77]?.itemCommonity || "";
                let itemName79 = s.invoiceValue[78]?.itemCommonity || "";
                let itemName80 = s.invoiceValue[79]?.itemCommonity || "";
                let itemName81 = s.invoiceValue[80]?.itemCommonity || "";
                let itemName82 = s.invoiceValue[81]?.itemCommonity || "";
                let itemName83 = s.invoiceValue[82]?.itemCommonity || "";
                let itemName84 = s.invoiceValue[83]?.itemCommonity || "";
                let itemName85 = s.invoiceValue[84]?.itemCommonity || "";
                let itemName86 = s.invoiceValue[85]?.itemCommonity || "";
                let itemName87 = s.invoiceValue[86]?.itemCommonity || "";
                let itemName88 = s.invoiceValue[87]?.itemCommonity || "";
                let itemName89 = s.invoiceValue[88]?.itemCommonity || "";
                let itemName90 = s.invoiceValue[89]?.itemCommonity || "";
                let itemName91 = s.invoiceValue[90]?.itemCommonity;
                let itemName92 = s.invoiceValue[91]?.itemCommonity || "";
                let itemName93 = s.invoiceValue[92]?.itemCommonity || "";
                let itemName94 = s.invoiceValue[93]?.itemCommonity || "";
                let itemName95 = s.invoiceValue[94]?.itemCommonity || "";
                let itemName96 = s.invoiceValue[95]?.itemCommonity || "";
                let itemName97 = s.invoiceValue[96]?.itemCommonity || "";
                let itemName98 = s.invoiceValue[97]?.itemCommonity || "";
                let itemName99 = s.invoiceValue[98]?.itemCommonity || "";
                let itemName100 = s.invoiceValue[99]?.itemCommonity || "";
                let unitPrice = s.invoiceValue[0]?.itemUnitPrice;
                let unitPrice2 = s.invoiceValue[1]?.itemUnitPrice || "";
                let unitPrice3 = s.invoiceValue[2]?.itemUnitPrice || "";
                let unitPrice4 = s.invoiceValue[3]?.itemUnitPrice || "";
                let unitPrice5 = s.invoiceValue[4]?.itemUnitPrice || "";
                let unitPrice6 = s.invoiceValue[5]?.itemUnitPrice || "";
                let unitPrice7 = s.invoiceValue[6]?.itemUnitPrice || "";
                let unitPrice8 = s.invoiceValue[7]?.itemUnitPrice || "";
                let unitPrice9 = s.invoiceValue[8]?.itemUnitPrice || "";
                let unitPrice10 = s.invoiceValue[9]?.itemUnitPrice || "";
                let unitPrice11 = s.invoiceValue[10]?.itemUnitPrice || "";
                let unitPrice12 = s.invoiceValue[11]?.itemUnitPrice || "";
                let unitPrice13 = s.invoiceValue[12]?.itemUnitPrice || "";
                let unitPrice14 = s.invoiceValue[13]?.itemUnitPrice || "";
                let unitPrice15 = s.invoiceValue[14]?.itemUnitPrice || "";
                let unitPrice16 = s.invoiceValue[15]?.itemUnitPrice || "";
                let unitPrice17 = s.invoiceValue[16]?.itemUnitPrice || "";
                let unitPrice18 = s.invoiceValue[17]?.itemUnitPrice || "";
                let unitPrice19 = s.invoiceValue[18]?.itemUnitPrice || "";
                let unitPrice20 = s.invoiceValue[19]?.itemUnitPrice || "";
                let unitPrice21 = s.invoiceValue[20]?.itemUnitPrice || "";
                let unitPrice22 = s.invoiceValue[21]?.itemUnitPrice || "";
                let unitPrice23 = s.invoiceValue[22]?.itemUnitPrice || "";
                let unitPrice24 = s.invoiceValue[23]?.itemUnitPrice || "";
                let unitPrice25 = s.invoiceValue[24]?.itemUnitPrice || "";
                let unitPrice26 = s.invoiceValue[25]?.itemUnitPrice || "";
                let unitPrice27 = s.invoiceValue[26]?.itemUnitPrice || "";
                let unitPrice28 = s.invoiceValue[27]?.itemUnitPrice || "";
                let unitPrice29 = s.invoiceValue[28]?.itemUnitPrice || "";
                let unitPrice30 = s.invoiceValue[29]?.itemUnitPrice || "";
                let unitPrice31 = s.invoiceValue[30]?.itemUnitPrice || "";
                let unitPrice32 = s.invoiceValue[31]?.itemUnitPrice || "";
                let unitPrice33 = s.invoiceValue[32]?.itemUnitPrice || "";
                let unitPrice34 = s.invoiceValue[33]?.itemUnitPrice || "";
                let unitPrice35 = s.invoiceValue[34]?.itemUnitPrice || "";
                let unitPrice36 = s.invoiceValue[35]?.itemUnitPrice || "";
                let unitPrice37 = s.invoiceValue[36]?.itemUnitPrice || "";
                let unitPrice38 = s.invoiceValue[37]?.itemUnitPrice || "";
                let unitPrice39 = s.invoiceValue[38]?.itemUnitPrice || "";
                let unitPrice40 = s.invoiceValue[39]?.itemUnitPrice || "";
                let unitPrice41 = s.invoiceValue[40]?.itemUnitPrice || "";
                let unitPrice42 = s.invoiceValue[41]?.itemUnitPrice || "";
                let unitPrice43 = s.invoiceValue[42]?.itemUnitPrice || "";
                let unitPrice44 = s.invoiceValue[43]?.itemUnitPrice || "";
                let unitPrice45 = s.invoiceValue[44]?.itemUnitPrice || "";
                let unitPrice46 = s.invoiceValue[45]?.itemUnitPrice || "";
                let unitPrice47 = s.invoiceValue[46]?.itemUnitPrice || "";
                let unitPrice48 = s.invoiceValue[47]?.itemUnitPrice || "";
                let unitPrice49 = s.invoiceValue[48]?.itemUnitPrice || "";
                let unitPrice50 = s.invoiceValue[49]?.itemUnitPrice || "";
                let unitPrice51 = s.invoiceValue[50]?.itemUnitPrice || "";
                let unitPrice52 = s.invoiceValue[51]?.itemUnitPrice || "";
                let unitPrice53 = s.invoiceValue[52]?.itemUnitPrice || "";
                let unitPrice54 = s.invoiceValue[53]?.itemUnitPrice || "";
                let unitPrice55 = s.invoiceValue[54]?.itemUnitPrice || "";
                let unitPrice56 = s.invoiceValue[55]?.itemUnitPrice || "";
                let unitPrice57 = s.invoiceValue[56]?.itemUnitPrice || "";
                let unitPrice58 = s.invoiceValue[57]?.itemUnitPrice || "";
                let unitPrice59 = s.invoiceValue[58]?.itemUnitPrice || "";
                let unitPrice60 = s.invoiceValue[59]?.itemUnitPrice || "";
                let unitPrice61 = s.invoiceValue[60]?.itemUnitPrice || "";
                let unitPrice62 = s.invoiceValue[61]?.itemUnitPrice || "";
                let unitPrice63 = s.invoiceValue[62]?.itemUnitPrice || "";
                let unitPrice64 = s.invoiceValue[63]?.itemUnitPrice || "";
                let unitPrice65 = s.invoiceValue[64]?.itemUnitPrice || "";
                let unitPrice66 = s.invoiceValue[65]?.itemUnitPrice || "";
                let unitPrice67 = s.invoiceValue[66]?.itemUnitPrice || "";
                let unitPrice68 = s.invoiceValue[67]?.itemUnitPrice || "";
                let unitPrice69 = s.invoiceValue[68]?.itemUnitPrice || "";
                let unitPrice70 = s.invoiceValue[69]?.itemUnitPrice || "";
                let unitPrice71 = s.invoiceValue[70]?.itemUnitPrice || "";
                let unitPrice72 = s.invoiceValue[71]?.itemUnitPrice || "";
                let unitPrice73 = s.invoiceValue[72]?.itemUnitPrice || "";
                let unitPrice74 = s.invoiceValue[73]?.itemUnitPrice || "";
                let unitPrice75 = s.invoiceValue[74]?.itemUnitPrice || "";
                let unitPrice76 = s.invoiceValue[75]?.itemUnitPrice || "";
                let unitPrice77 = s.invoiceValue[76]?.itemUnitPrice || "";
                let unitPrice78 = s.invoiceValue[77]?.itemUnitPrice || "";
                let unitPrice79 = s.invoiceValue[78]?.itemUnitPrice || "";
                let unitPrice80 = s.invoiceValue[79]?.itemUnitPrice || "";
                let unitPrice81 = s.invoiceValue[80]?.itemUnitPrice || "";
                let unitPrice82 = s.invoiceValue[81]?.itemUnitPrice || "";
                let unitPrice83 = s.invoiceValue[82]?.itemUnitPrice || "";
                let unitPrice84 = s.invoiceValue[83]?.itemUnitPrice || "";
                let unitPrice85 = s.invoiceValue[84]?.itemUnitPrice || "";
                let unitPrice86 = s.invoiceValue[85]?.itemUnitPrice || "";
                let unitPrice87 = s.invoiceValue[86]?.itemUnitPrice || "";
                let unitPrice88 = s.invoiceValue[87]?.itemUnitPrice || "";
                let unitPrice89 = s.invoiceValue[88]?.itemUnitPrice || "";
                let unitPrice90 = s.invoiceValue[89]?.itemUnitPrice || "";
                let unitPrice91 = s.invoiceValue[90]?.itemUnitPrice || "";
                let unitPrice92 = s.invoiceValue[91]?.itemUnitPrice || "";
                let unitPrice93 = s.invoiceValue[92]?.itemUnitPrice || "";
                let unitPrice94 = s.invoiceValue[93]?.itemUnitPrice || "";
                let unitPrice95 = s.invoiceValue[94]?.itemUnitPrice || "";
                let unitPrice96 = s.invoiceValue[95]?.itemUnitPrice || "";
                let unitPrice97 = s.invoiceValue[96]?.itemUnitPrice || "";
                let unitPrice98 = s.invoiceValue[97]?.itemUnitPrice || "";
                let unitPrice99 = s.invoiceValue[98]?.itemUnitPrice || "";
                let unitPrice100 = s.invoiceValue[99]?.itemUnitPrice || "";
                let itemGrossWeight = +s.totalCharge / s.invoiceValue.length;
                let itemGrossWeight2 = s.invoiceValue?.length >= 2 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight3 = s.invoiceValue?.length >= 3 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight4 = s.invoiceValue?.length >= 4 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight5 = s.invoiceValue?.length >= 5 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight6 = s.invoiceValue?.length >= 6 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight7 = s.invoiceValue?.length >= 7 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight8 = s.invoiceValue?.length >= 8 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight9 = s.invoiceValue?.length >= 9 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight10 = s.invoiceValue?.length >= 10 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight11 = s.invoiceValue?.lnegth >= 11 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight12 = s.invoiceValue?.length >= 12 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight13 = s.invoiceValue?.length >= 13 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight14 = s.invoiceValue?.length >= 14 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight15 = s.invoiceValue?.length >= 15 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight16 = s.invoiceValue?.length >= 16 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight17 = s.invoiceValue?.length >= 17 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight18 = s.invoiceValue?.length >= 18 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight19 = s.invoiceValue?.length >= 19 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemGrossWeight20 = s.invoiceValue?.length === 20 ? +s.totalCharge / s.invoiceValue.length : "";
                let itemNetWeight = itemGrossWeight - 0.5;
                let itemNetWeight2 = s.invoiceValue?.length >= 2 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight3 = s.invoiceValue?.length >= 3 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight4 = s.invoiceValue?.length >= 4 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight5 = s.invoiceValue?.length >= 5 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight6 = s.invoiceValue?.length >= 6 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight7 = s.invoiceValue?.length >= 7 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight8 = s.invoiceValue?.length >= 8 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight9 = s.invoiceValue?.length >= 9 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight10 = s.invoiceValue?.length >= 10 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight11 = s.invoiceValue?.length >= 11 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight12 = s.invoiceValue?.length >= 12 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight13 = s.invoiceValue?.length >= 13 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight14 = s.invoiceValue?.length >= 14 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight15 = s.invoiceValue?.length >= 15 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight16 = s.invoiceValue?.length >= 16 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight17 = s.invoiceValue?.length >= 17 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight18 = s.invoiceValue?.length >= 18 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight19 = s.invoiceValue?.length >= 19 ? itemGrossWeight - 0.5 : "";
                let itemNetWeight20 = s.invoiceValue?.length === 20 ? itemGrossWeight - 0.5 : "";
                let hsCode = s.invoiceValue[0]?.itemHsCode;
                let hsCode2 = s.invoiceValue[1]?.itemHsCode || "";
                let hsCode3 = s.invoiceValue[2]?.itemHsCode || "";
                let hsCode4 = s.invoiceValue[3]?.itemHsCode || "";
                let hsCode5 = s.invoiceValue[4]?.itemHsCode || "";
                let hsCode6 = s.invoiceValue[5]?.itemHsCode || "";
                let hsCode7 = s.invoiceValue[6]?.itemHsCode || "";
                let hsCode8 = s.invoiceValue[7]?.itemHsCode || "";
                let hsCode9 = s.invoiceValue[8]?.itemHsCode || "";
                let hsCode10 = s.invoiceValue[9]?.itemHsCode || "";
                let hsCode11 = s.invoiceValue[10]?.itemHsCode || "";
                let hsCode12 = s.invoiceValue[11]?.itemHsCode || "";
                let hsCode13 = s.invoiceValue[12]?.itemHsCode || "";
                let hsCode14 = s.invoiceValue[13]?.itemHsCode || "";
                let hsCode15 = s.invoiceValue[14]?.itemHsCode || "";
                let hsCode16 = s.invoiceValue[15]?.itemHsCode || "";
                let hsCode17 = s.invoiceValue[16]?.itemHsCode || "";
                let hsCode18 = s.invoiceValue[17]?.itemHsCode || "";
                let hsCode19 = s.invoiceValue[18]?.itemHsCode || "";
                let hsCode20 = s.invoiceValue[19]?.itemHsCode || "";
                let hsCode21 = s.invoiceValue[20]?.itemHsCode || "";
                let hsCode22 = s.invoiceValue[21]?.itemHsCode || "";
                let hsCode23 = s.invoiceValue[22]?.itemHsCode || "";
                let hsCode24 = s.invoiceValue[23]?.itemHsCode || "";
                let hsCode25 = s.invoiceValue[24]?.itemHsCode || "";
                let hsCode26 = s.invoiceValue[25]?.itemHsCode || "";
                let hsCode27 = s.invoiceValue[26]?.itemHsCode || "";
                let hsCode28 = s.invoiceValue[27]?.itemHsCode || "";
                let hsCode29 = s.invoiceValue[28]?.itemHsCode || "";
                let hsCode30 = s.invoiceValue[29]?.itemHsCode || "";
                let hsCode31 = s.invoiceValue[30]?.itemHsCode || "";
                let hsCode32 = s.invoiceValue[31]?.itemHsCode || "";
                let hsCode33 = s.invoiceValue[32]?.itemHsCode || "";
                let hsCode34 = s.invoiceValue[33]?.itemHsCode || "";
                let hsCode35 = s.invoiceValue[34]?.itemHsCode || "";
                let hsCode36 = s.invoiceValue[35]?.itemHsCode || "";
                let hsCode37 = s.invoiceValue[36]?.itemHsCode || "";
                let hsCode38 = s.invoiceValue[37]?.itemHsCode || "";
                let hsCode39 = s.invoiceValue[38]?.itemHsCode || "";
                let hsCode40 = s.invoiceValue[39]?.itemHsCode || "";
                let hsCode41 = s.invoiceValue[40]?.itemHsCode || "";
                let hsCode42 = s.invoiceValue[41]?.itemHsCode || "";
                let hsCode43 = s.invoiceValue[42]?.itemHsCode || "";
                let hsCode44 = s.invoiceValue[43]?.itemHsCode || "";
                let hsCode45 = s.invoiceValue[44]?.itemHsCode || "";
                let hsCode46 = s.invoiceValue[45]?.itemHsCode || "";
                let hsCode47 = s.invoiceValue[46]?.itemHsCode || "";
                let hsCode48 = s.invoiceValue[47]?.itemHsCode || "";
                let hsCode49 = s.invoiceValue[48]?.itemHsCode || "";
                let hsCode50 = s.invoiceValue[49]?.itemHsCode || "";
                let hsCode51 = s.invoiceValue[50]?.itemHsCode || "";
                let hsCode52 = s.invoiceValue[51]?.itemHsCode || "";
                let hsCode53 = s.invoiceValue[52]?.itemHsCode || "";
                let hsCode54 = s.invoiceValue[53]?.itemHsCode || "";
                let hsCode55 = s.invoiceValue[54]?.itemHsCode || "";
                let hsCode56 = s.invoiceValue[55]?.itemHsCode || "";
                let hsCode57 = s.invoiceValue[56]?.itemHsCode || "";
                let hsCode58 = s.invoiceValue[57]?.itemHsCode || "";
                let hsCode59 = s.invoiceValue[58]?.itemHsCode || "";
                let hsCode60 = s.invoiceValue[59]?.itemHsCode || "";
                let hsCode61 = s.invoiceValue[60]?.itemHsCode || "" || "";
                let hsCode62 = s.invoiceValue[61]?.itemHsCode || "";
                let hsCode63 = s.invoiceValue[62]?.itemHsCode || "";
                let hsCode64 = s.invoiceValue[63]?.itemHsCode || "";
                let hsCode65 = s.invoiceValue[64]?.itemHsCode || "";
                let hsCode66 = s.invoiceValue[65]?.itemHsCode || "";
                let hsCode67 = s.invoiceValue[66]?.itemHsCode || "";
                let hsCode68 = s.invoiceValue[67]?.itemHsCode || "";
                let hsCode69 = s.invoiceValue[68]?.itemHsCode || "";
                let hsCode70 = s.invoiceValue[69]?.itemHsCode || "";
                let hsCode71 = s.invoiceValue[70]?.itemHsCode || "";
                let hsCode72 = s.invoiceValue[71]?.itemHsCode || "";
                let hsCode73 = s.invoiceValue[72]?.itemHsCode || "";
                let hsCode74 = s.invoiceValue[73]?.itemHsCode || "";
                let hsCode75 = s.invoiceValue[74]?.itemHsCode || "";
                let hsCode76 = s.invoiceValue[75]?.itemHsCode || "";
                let hsCode77 = s.invoiceValue[76]?.itemHsCode || "";
                let hsCode78 = s.invoiceValue[77]?.itemHsCode || "";
                let hsCode79 = s.invoiceValue[78]?.itemHsCode || "";
                let hsCode80 = s.invoiceValue[79]?.itemHsCode || "";
                let hsCode81 = s.invoiceValue[80]?.itemHsCode || "";
                let hsCode82 = s.invoiceValue[81]?.itemHsCode || "";
                let hsCode83 = s.invoiceValue[82]?.itemHsCode || "";
                let hsCode84 = s.invoiceValue[83]?.itemHsCode || "";
                let hsCode85 = s.invoiceValue[84]?.itemHsCode || "";
                let hsCode86 = s.invoiceValue[85]?.itemHsCode || "";
                let hsCode87 = s.invoiceValue[86]?.itemHsCode || "";
                let hsCode88 = s.invoiceValue[87]?.itemHsCode || "";
                let hsCode89 = s.invoiceValue[88]?.itemHsCode || "";
                let hsCode90 = s.invoiceValue[89]?.itemHsCode || "";
                let hsCode91 = s.invoiceValue[90]?.itemHsCode || "";
                let hsCode92 = s.invoiceValue[91]?.itemHsCode || "";
                let hsCode93 = s.invoiceValue[92]?.itemHsCode || "";
                let hsCode94 = s.invoiceValue[93]?.itemHsCode || "";
                let hsCode95 = s.invoiceValue[94]?.itemHsCode || "";
                let hsCode96 = s.invoiceValue[95]?.itemHsCode || "";
                let hsCode97 = s.invoiceValue[96]?.itemHsCode || "";
                let hsCode98 = s.invoiceValue[97]?.itemHsCode || "";
                let hsCode99 = s.invoiceValue[98]?.itemHsCode || "";
                let hsCode100 = s.invoiceValue[99]?.itemHsCode || "";
                let desc = s.description.split(/[,]+/);
                return {
                    updated: updated,
                    select: select,
                    descArr: desc,
                    cancel: s.cancel || "booking",
                    booked: s.booked,
                    account: s.account || "",
                    currency: s.currency || "",
                    service: s.service || "SKD",
                    date: s.createAt || "",
                    cleDate: s.createAt.replaceAll(/-/g, ''),
                    hawb: s.hawb || "",
                    mawb: s.mawb || "",
                    makh: s.makh,
                    incoterm: "DAP",
                    satDev: "N",
                    numberOfLine: s.shipmentType === "WPX" ? s.invoiceValue?.length : 1,
                    removeSkd: removeSkd,
                    negative: 1,
                    company: s.importer || "",
                    contact: s.toName || "",
                    type: s.shipmentType || "",
                    status: s.status || "",
                    term: s.term || "",
                    time: s.time || "",
                    fromAddress: s.fromAddress || "",
                    fromAddress2: s.fromAddress2 || ".",
                    fromAddress3: s.fromAddress3 || ".",
                    address: s.toAddress || "",
                    address2: s.toAddress2 || ".",
                    address3: s.toAddress3 || ".",
                    city: s.toCity || "",
                    country: s.toCountry || "",
                    fromCountry: s.fromCountry || "",
                    phone: s.toPhone.replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, '') || "",
                    postal: s.toPostal || "",
                    state: s.toState || "",
                    cleState: s.stateLabel,
                    term: s.term,
                    fromPhoneType: "O",
                    town: s.toTown || "",
                    chargeable: (Math.round(s.totalCharge * 100) / 100).toFixed(2).toString() || "",
                    amount: s.totalPackage || "",
                    totalItem: s.totalItem || "",
                    transport: s.transportation || "",
                    description: s.description || "",
                    cleDesc: s.shipmentType === "WPX" ? s.description.slice(0, 168) : "Documents - general business",
                    controller: s.controller || "",
                    eamFormat: eamFormat,
                    toPhoneCode: s.phoneCode || "",
                    toPhoneType: "M",
                    value: s.shipmentType === "DOX" ? "0.00" : value,
                    airline: s.airline || "",
                    reweight: s.reweight || "",
                    cleShipmentType: s.shipmentType === "WPX" ? "P" : "D",
                    agent: s.agent || "",
                    etd: s.etd || "",
                    subStatus: s.subStatus || "",
                    sin: s.sin === "Null" ? "" : s.sin,
                    sender: s.exporter || "",
                    note: s.note || "",
                    perform: s.perform || "",
                    rate: s.rate || "",
                    fsc: s.fsc || "",
                    vat: s.vat || "",
                    remote: s.remote || "",
                    comestic: s.comestic || "",
                    isMerged: s.isMerged,
                    addressCorrection: s.addressCorrection || "",
                    totalValue: s.totalValue || "",
                    surcharge: s.surcharge || "",
                    surcharge2: s.surcharge2 || "",
                    surcharge3: s.surcharge3 || "",
                    vatValue: s.vatValue || "",
                    sellNote: s.sellNote || "",
                    paymentPickup: s.paymentPickup || "",
                    paymentAcc: s.paymentAcc || "",
                    paymentCompany: s.paymentCompany || "",
                    fromEmail: s.fromEmail || "",
                    fromCity: s.fromCity || "",
                    com: s.com || "",
                    rateUpdate: s.rateUpdate || "",
                    paymentStatus: s.paymentStatus || "",
                    paymentSubStatus: s.paymentSubStatus || "",
                    code: s.code || "",
                    eamService: s.eamService,
                    fromPhoneCode: "84",
                    pack: s.pack || "",
                    plan: s.plan || "Pending",
                    invoiceValue: s.shipmentType === "WPX" ? s.invoiceValue : [],
                    packageValue: s.shipmentType === "WPX" ? s.packageValue : [],
                    term: s.term || ".",
                    reason: s.usage?.charAt(0).toUpperCase() + s.usage?.slice(1).toLowerCase() || ".",
                    fromName: s.fromName || "",
                    fromPostal: s.fromPostal || "",
                    fromCode: "VN",
                    fromPhone: s.fromPhone,
                    invoiceValue: s.invoiceValue,
                    packageValue: s.packageValue,
                    pickupBy: s.pickupBy,
                    receiveBy: s.receiveBy,
                    processBy: s.processBy,
                    clearanceBy: s.clearanceBy,
                    clearedBy: s.clearedBy,
                    holdBy: s.holdBy,
                    toEmail: s.toEmail,
                    transportation: s.transportation,
                    fromAddress2: s.fromAddress2 || ".",
                    fromTown: s.fromTown,
                    fromState: s.fromState,
                    stateCode: s.stateCode,
                    loading: false,
                    eori: s.eori,
                    ref: "Pending",
                    firstPcs: s.shipmentType === "WPX" ? pcsWeight / s.packageValue.length : s.weight,
                    firstLength: s.shipmentType === "WPX" ? pcsLength / s.packageValue.length : 1,
                    firstWidth: s.shipmentType === "WPX" ? pcsWidth / s.packageValue.length : 1,
                    firstHeight: s.shipmentType === "WPX" ? pcsHeight / s.packageValue.length : 1,
                    weightCode: "KG",
                    dimCode: "CM",
                    dus: s.dus || [],
                    labelURL: s.labelURL,
                    dhlAccount: dhlAccount,
                    cleNumberOfLine: s.invoiceValue.length,
                    cleName: cleName,
                    cleAddress: "#02-18",
                    cleAddress2: "CARGO AGENT BUILDING C",
                    cleAddress3: "CHANGI AIRFREIGHT CENTRE",
                    clePostal: "819466",
                    cleCode: "SG",
                    uom: "PCS",
                    uom2: s.invoiceValue.length >= 2 ? "PCS" : "",
                    uom3: s.invoiceValue.length >= 3 ? "PCS" : "",
                    uom4: s.invoiceValue.length >= 4 ? "PCS" : "",
                    uom5: s.invoiceValue.length >= 5 ? "PCS" : "",
                    uom6: s.invoiceValue.length >= 6 ? "PCS" : "",
                    uom7: s.invoiceValue.length >= 7 ? "PCS" : "",
                    uom8: s.invoiceValue.length >= 8 ? "PCS" : "",
                    uom9: s.invoiceValue.length >= 9 ? "PCS" : "",
                    uom10: s.invoiceValue.length >= 10 ? "PCS" : "",
                    uom11: s.invoiceValue.length >= 11 ? "PCS" : "",
                    uom12: s.invoiceValue.length >= 12 ? "PCS" : "",
                    uom13: s.invoiceValue.length >= 13 ? "PCS" : "",
                    uom14: s.invoiceValue.length >= 14 ? "PCS" : "",
                    uom15: s.invoiceValue.length >= 15 ? "PCS" : "",
                    uom16: s.invoiceValue.length >= 16 ? "PCS" : "",
                    uom17: s.invoiceValue.length >= 17 ? "PCS" : "",
                    uom18: s.invoiceValue.length >= 18 ? "PCS" : "",
                    uom19: s.invoiceValue.length >= 19 ? "PCS" : "",
                    uom20: s.invoiceValue.length >= 20 ? "PCS" : "",
                    cleCity: "SINGAPORE",
                    cleEmail: cleEmail,
                    clePhoneType: "O",
                    clePhoneCountryCode: "65",
                    clePhone: clePhone,
                    item_value: itemValue / quantity,
                    item_value2: itemValue2 / quantity2,
                    item_value3: itemValue3 / quantity3,
                    item_value4: itemValue4 / quantity4,
                    item_value5: itemValue5 / quantity5,
                    item_value6: itemValue6 / quantity6,
                    item_value7: itemValue7 / quantity7,
                    item_value8: itemValue8 / quantity8,
                    item_value9: itemValue9 / quantity9,
                    item_value10: itemValue10 / quantity10,
                    item_value11: itemValue11 / quantity11,
                    item_value12: itemValue12 / quantity12,
                    item_value13: itemValue13 / quantity13,
                    item_value14: itemValue14 / quantity14,
                    item_value15: itemValue15 / quantity15,
                    item_value16: itemValue16 / quantity16,
                    item_value17: itemValue17 / quantity17,
                    item_value18: itemValue18 / quantity18,
                    item_value19: itemValue19 / quantity19,
                    item_value20: itemValue20 / quantity20,
                    item_number: itemNo,
                    item_number2: itemNo2,
                    item_number3: itemNo3,
                    item_number4: itemNo4,
                    item_number5: itemNo5,
                    item_number6: itemNo6,
                    item_number7: itemNo7,
                    item_number8: itemNo8,
                    item_number9: itemNo9,
                    item_number10: itemNo10,
                    item_number11: itemNo11,
                    item_number12: itemNo12,
                    item_number13: itemNo13,
                    item_number14: itemNo14,
                    item_number15: itemNo15,
                    item_number16: itemNo16,
                    item_number17: itemNo17,
                    item_number18: itemNo18,
                    item_number19: itemNo19,
                    item_number20: itemNo20,
                    item_number21: itemNo21,
                    item_number22: itemNo22,
                    item_number23: itemNo23,
                    item_number24: itemNo24,
                    item_number25: itemNo25,
                    item_number26: itemNo26,
                    item_number27: itemNo27,
                    item_number28: itemNo28,
                    item_number29: itemNo29,
                    item_number30: itemNo30,
                    item_number31: itemNo31,
                    item_number32: itemNo32,
                    item_number33: itemNo33,
                    item_number34: itemNo34,
                    item_number35: itemNo35,
                    item_number36: itemNo36,
                    item_number37: itemNo37,
                    item_number38: itemNo38,
                    item_number39: itemNo39,
                    item_number40: itemNo40,
                    item_number41: itemNo41,
                    item_number42: itemNo42,
                    item_number43: itemNo43,
                    item_number44: itemNo44,
                    item_number45: itemNo45,
                    item_number46: itemNo46,
                    item_number47: itemNo47,
                    item_number48: itemNo48,
                    item_number49: itemNo49,
                    item_number50: itemNo50,
                    item_number51: itemNo51,
                    item_number52: itemNo52,
                    item_number53: itemNo53,
                    item_number54: itemNo54,
                    item_number55: itemNo55,
                    item_number56: itemNo56,
                    item_number57: itemNo57,
                    item_number58: itemNo58,
                    item_number59: itemNo59,
                    item_number60: itemNo60,
                    item_number61: itemNo61,
                    item_number62: itemNo62,
                    item_number63: itemNo63,
                    item_number64: itemNo64,
                    item_number65: itemNo65,
                    item_number66: itemNo66,
                    item_number67: itemNo67,
                    item_number68: itemNo68,
                    item_number69: itemNo69,
                    item_number70: itemNo70,
                    item_number71: itemNo71,
                    item_number72: itemNo72,
                    item_number73: itemNo73,
                    item_number74: itemNo74,
                    item_number75: itemNo75,
                    item_number76: itemNo76,
                    item_number77: itemNo77,
                    item_number78: itemNo78,
                    item_number79: itemNo79,
                    item_number80: itemNo80,
                    item_number81: itemNo81,
                    item_number82: itemNo82,
                    item_number83: itemNo83,
                    item_number84: itemNo84,
                    item_number85: itemNo85,
                    item_number86: itemNo86,
                    item_number87: itemNo87,
                    item_number88: itemNo88,
                    item_number89: itemNo89,
                    item_number90: itemNo90,
                    item_number91: itemNo91,
                    item_number92: itemNo92,
                    item_number93: itemNo93,
                    item_number94: itemNo94,
                    item_number95: itemNo95,
                    item_number96: itemNo96,
                    item_number97: itemNo97,
                    item_number98: itemNo98,
                    item_number99: itemNo99,
                    item_number100: itemNo100,
                    quantity: quantity,
                    quantity2: quantity2,
                    quantity3: quantity3,
                    quantity4: quantity4,
                    quantity5: quantity5,
                    quantity6: quantity6,
                    quantity7: quantity7,
                    quantity8: quantity8,
                    quantity9: quantity9,
                    quantity10: quantity10,
                    quantity11: quantity11,
                    quantity12: quantity12,
                    quantity13: quantity13,
                    quantity14: quantity14,
                    quantity15: quantity15,
                    quantity16: quantity16,
                    quantity17: quantity17,
                    quantity18: quantity18,
                    quantity19: quantity19,
                    quantity20: quantity20,
                    quantity21: quantity21,
                    quantity22: quantity22,
                    quantity23: quantity23,
                    quantity24: quantity24,
                    quantity25: quantity25,
                    quantity26: quantity26,
                    quantity27: quantity27,
                    quantity28: quantity28,
                    quantity29: quantity29,
                    quantity30: quantity30,
                    quantity31: quantity31,
                    quantity32: quantity32,
                    quantity33: quantity33,
                    quantity34: quantity34,
                    quantity35: quantity35,
                    quantity36: quantity36,
                    quantity37: quantity37,
                    quantity38: quantity38,
                    quantity39: quantity39,
                    quantity40: quantity40,
                    quantity41: quantity41,
                    quantity42: quantity42,
                    quantity43: quantity43,
                    quantity44: quantity44,
                    quantity45: quantity45,
                    quantity46: quantity46,
                    quantity47: quantity47,
                    quantity48: quantity48,
                    quantity49: quantity49,
                    quantity50: quantity50,
                    quantity51: quantity51,
                    quantity52: quantity52,
                    quantity53: quantity53,
                    quantity54: quantity54,
                    quantity55: quantity55,
                    quantity56: quantity56,
                    quantity57: quantity57,
                    quantity58: quantity58,
                    quantity59: quantity59,
                    quantity60: quantity60,
                    quantity61: quantity61,
                    quantity62: quantity62,
                    quantity63: quantity63,
                    quantity64: quantity64,
                    quantity65: quantity65,
                    quantity66: quantity66,
                    quantity67: quantity67,
                    quantity68: quantity68,
                    quantity69: quantity69,
                    quantity70: quantity70,
                    quantity71: quantity71,
                    quantity72: quantity72,
                    quantity73: quantity73,
                    quantity74: quantity74,
                    quantity75: quantity75,
                    quantity76: quantity76,
                    quantity77: quantity77,
                    quantity78: quantity78,
                    quantity79: quantity79,
                    quantity80: quantity80,
                    quantity81: quantity81,
                    quantity82: quantity82,
                    quantity83: quantity83,
                    quantity84: quantity84,
                    quantity85: quantity85,
                    quantity86: quantity86,
                    quantity87: quantity87,
                    quantity88: quantity88,
                    quantity89: quantity89,
                    quantity90: quantity90,
                    quantity91: quantity91,
                    quantity92: quantity92,
                    quantity93: quantity93,
                    quantity94: quantity94,
                    quantity95: quantity95,
                    quantity96: quantity96,
                    quantity97: quantity97,
                    quantity98: quantity98,
                    quantity99: quantity99,
                    quantity100: quantity100,
                    unit: unit,
                    unit2: unit2,
                    unit3: unit3,
                    unit4: unit4,
                    unit5: unit5,
                    unit6: unit6,
                    unit7: unit7,
                    unit8: unit8,
                    unit9: unit9,
                    unit10: unit10,
                    unit11: unit11,
                    unit12: unit12,
                    unit13: unit13,
                    unit14: unit14,
                    unit15: unit15,
                    unit16: unit16,
                    unit17: unit17,
                    unit18: unit18,
                    unit19: unit19,
                    unit20: unit20,
                    unit21: unit21,
                    unit22: unit22,
                    unit23: unit23,
                    unit24: unit24,
                    unit25: unit25,
                    unit26: unit26,
                    unit27: unit27,
                    unit28: unit28,
                    unit29: unit29,
                    unit30: unit30,
                    unit31: unit31,
                    unit32: unit32,
                    unit33: unit33,
                    unit34: unit34,
                    unit35: unit35,
                    unit36: unit36,
                    unit37: unit37,
                    unit38: unit38,
                    unit39: unit39,
                    unit40: unit40,
                    unit41: unit41,
                    unit42: unit42,
                    unit43: unit43,
                    unit44: unit44,
                    unit45: unit45,
                    unit46: unit46,
                    unit47: unit47,
                    unit48: unit48,
                    unit49: unit49,
                    unit50: unit50,
                    unit51: unit51,
                    unit52: unit52,
                    unit53: unit53,
                    unit54: unit54,
                    unit55: unit55,
                    unit56: unit56,
                    unit57: unit57,
                    unit58: unit58,
                    unit59: unit59,
                    unit60: unit60,
                    unit61: unit61,
                    unit62: unit62,
                    unit63: unit63,
                    unit64: unit64,
                    unit65: unit65,
                    unit66: unit66,
                    unit67: unit67,
                    unit68: unit68,
                    unit69: unit69,
                    unit70: unit70,
                    unit71: unit71,
                    unit72: unit72,
                    unit73: unit73,
                    unit74: unit74,
                    unit75: unit75,
                    unit76: unit76,
                    unit77: unit77,
                    unit78: unit78,
                    unit79: unit79,
                    unit80: unit80,
                    unit81: unit81,
                    unit82: unit82,
                    unit83: unit83,
                    unit84: unit84,
                    unit85: unit85,
                    unit86: unit86,
                    unit87: unit87,
                    unit88: unit88,
                    unit89: unit89,
                    unit90: unit90,
                    unit91: unit91,
                    unit92: unit92,
                    unit93: unit93,
                    unit94: unit94,
                    unit95: unit95,
                    unit96: unit96,
                    unit97: unit97,
                    unit98: unit98,
                    unit99: unit99,
                    unit100: unit100,
                    itemName: itemName,
                    itemName2: itemName2,
                    itemName3: itemName3,
                    itemName4: itemName4,
                    itemName5: itemName5,
                    itemName6: itemName6,
                    itemName7: itemName7,
                    itemName8: itemName8,
                    itemName9: itemName9,
                    itemName10: itemName10,
                    itemName11: itemName11,
                    itemName12: itemName12,
                    itemName13: itemName13,
                    itemName14: itemName14,
                    itemName15: itemName15,
                    itemName16: itemName16,
                    itemName17: itemName17,
                    itemName18: itemName18,
                    itemName19: itemName19,
                    itemName20: itemName20,
                    itemName21: itemName21,
                    itemName22: itemName22,
                    itemName23: itemName23,
                    itemName24: itemName24,
                    itemName25: itemName25,
                    itemName26: itemName26,
                    itemName27: itemName27,
                    itemName28: itemName28,
                    itemName29: itemName29,
                    itemName30: itemName30,
                    itemName31: itemName31,
                    itemName32: itemName32,
                    itemName33: itemName33,
                    itemName34: itemName34,
                    itemName35: itemName35,
                    itemName36: itemName36,
                    itemName37: itemName37,
                    itemName38: itemName38,
                    itemName39: itemName39,
                    itemName40: itemName40,
                    itemName41: itemName41,
                    itemName42: itemName42,
                    itemName43: itemName43,
                    itemName44: itemName44,
                    itemName45: itemName45,
                    itemName46: itemName46,
                    itemName47: itemName47,
                    itemName48: itemName48,
                    itemName49: itemName49,
                    itemName50: itemName50,
                    itemName51: itemName51,
                    itemName52: itemName52,
                    itemName53: itemName53,
                    itemName54: itemName54,
                    itemName55: itemName55,
                    itemName56: itemName56,
                    itemName57: itemName57,
                    itemName58: itemName58,
                    itemName59: itemName59,
                    itemName60: itemName60,
                    itemName61: itemName61,
                    itemName62: itemName62,
                    itemName63: itemName63,
                    itemName64: itemName64,
                    itemName65: itemName65,
                    itemName66: itemName66,
                    itemName67: itemName67,
                    itemName68: itemName68,
                    itemName69: itemName69,
                    itemName70: itemName70,
                    itemName71: itemName71,
                    itemName72: itemName72,
                    itemName73: itemName73,
                    itemName74: itemName74,
                    itemName75: itemName75,
                    itemName76: itemName76,
                    itemName77: itemName77,
                    itemName78: itemName78,
                    itemName79: itemName79,
                    itemName80: itemName80,
                    itemName81: itemName81,
                    itemName82: itemName82,
                    itemName83: itemName83,
                    itemName84: itemName84,
                    itemName85: itemName85,
                    itemName86: itemName86,
                    itemName87: itemName87,
                    itemName88: itemName88,
                    itemName89: itemName89,
                    itemName90: itemName90,
                    itemName91: itemName91,
                    itemName92: itemName92,
                    itemName93: itemName93,
                    itemName94: itemName94,
                    itemName95: itemName95,
                    itemName96: itemName96,
                    itemName97: itemName97,
                    itemName98: itemName98,
                    itemName99: itemName99,
                    itemName100: itemName100,
                    unitPrice: unitPrice,
                    unitPrice2: unitPrice2,
                    unitPrice3: unitPrice3,
                    unitPrice4: unitPrice4,
                    unitPrice5: unitPrice5,
                    unitPrice6: unitPrice6,
                    unitPrice7: unitPrice7,
                    unitPrice8: unitPrice8,
                    unitPrice9: unitPrice9,
                    unitPrice10: unitPrice10,
                    unitPrice11: unitPrice11,
                    unitPrice12: unitPrice12,
                    unitPrice13: unitPrice13,
                    unitPrice14: unitPrice14,
                    unitPrice15: unitPrice15,
                    unitPrice16: unitPrice16,
                    unitPrice17: unitPrice17,
                    unitPrice18: unitPrice18,
                    unitPrice19: unitPrice19,
                    unitPrice20: unitPrice20,
                    unitPrice21: unitPrice21,
                    unitPrice22: unitPrice22,
                    unitPrice23: unitPrice23,
                    unitPrice24: unitPrice24,
                    unitPrice25: unitPrice25,
                    unitPrice26: unitPrice26,
                    unitPrice27: unitPrice27,
                    unitPrice28: unitPrice28,
                    unitPrice29: unitPrice29,
                    unitPrice30: unitPrice30,
                    unitPrice31: unitPrice31,
                    unitPrice32: unitPrice32,
                    unitPrice33: unitPrice33,
                    unitPrice34: unitPrice34,
                    unitPrice35: unitPrice35,
                    unitPrice36: unitPrice36,
                    unitPrice37: unitPrice37,
                    unitPrice38: unitPrice38,
                    unitPrice39: unitPrice39,
                    unitPrice40: unitPrice40,
                    unitPrice41: unitPrice41,
                    unitPrice42: unitPrice42,
                    unitPrice43: unitPrice43,
                    unitPrice44: unitPrice44,
                    unitPrice45: unitPrice45,
                    unitPrice46: unitPrice46,
                    unitPrice47: unitPrice47,
                    unitPrice48: unitPrice48,
                    unitPrice49: unitPrice49,
                    unitPrice50: unitPrice50,
                    unitPrice51: unitPrice51,
                    unitPrice52: unitPrice52,
                    unitPrice53: unitPrice53,
                    unitPrice54: unitPrice54,
                    unitPrice55: unitPrice55,
                    unitPrice56: unitPrice56,
                    unitPrice57: unitPrice57,
                    unitPrice58: unitPrice58,
                    unitPrice59: unitPrice59,
                    unitPrice60: unitPrice60,
                    unitPrice61: unitPrice61,
                    unitPrice62: unitPrice62,
                    unitPrice63: unitPrice63,
                    unitPrice64: unitPrice64,
                    unitPrice65: unitPrice65,
                    unitPrice66: unitPrice66,
                    unitPrice67: unitPrice67,
                    unitPrice68: unitPrice68,
                    unitPrice69: unitPrice69,
                    unitPrice70: unitPrice70,
                    unitPrice71: unitPrice71,
                    unitPrice72: unitPrice72,
                    unitPrice73: unitPrice73,
                    unitPrice74: unitPrice74,
                    unitPrice75: unitPrice75,
                    unitPrice76: unitPrice76,
                    unitPrice77: unitPrice77,
                    unitPrice78: unitPrice78,
                    unitPrice79: unitPrice79,
                    unitPrice80: unitPrice80,
                    unitPrice81: unitPrice81,
                    unitPrice82: unitPrice82,
                    unitPrice83: unitPrice83,
                    unitPrice84: unitPrice84,
                    unitPrice85: unitPrice85,
                    unitPrice86: unitPrice86,
                    unitPrice87: unitPrice87,
                    unitPrice88: unitPrice88,
                    unitPrice89: unitPrice89,
                    unitPrice90: unitPrice90,
                    unitPrice91: unitPrice91,
                    unitPrice92: unitPrice92,
                    unitPrice93: unitPrice93,
                    unitPrice94: unitPrice94,
                    unitPrice95: unitPrice95,
                    unitPrice96: unitPrice96,
                    unitPrice97: unitPrice97,
                    unitPrice98: unitPrice98,
                    unitPrice99: unitPrice99,
                    unitPrice100: unitPrice100,
                    itemGrossWeight: itemGrossWeight / quantity,
                    itemGrossWeight2: itemGrossWeight2 / quantity2,
                    itemGrossWeight3: itemGrossWeight3 / quantity3,
                    itemGrossWeight4: itemGrossWeight4 / quantity4,
                    itemGrossWeight5: itemGrossWeight5 / quantity5,
                    itemGrossWeight6: itemGrossWeight6 / quantity6,
                    itemGrossWeight7: itemGrossWeight7 / quantity7,
                    itemGrossWeight8: itemGrossWeight8 / quantity8,
                    itemGrossWeight9: itemGrossWeight9 / quantity9,
                    itemGrossWeight10: itemGrossWeight10 / quantity10,
                    itemGrossWeight11: itemGrossWeight11 / quantity11,
                    itemGrossWeight12: itemGrossWeight12 / quantity12,
                    itemGrossWeight13: itemGrossWeight13 / quantity13,
                    itemGrossWeight14: itemGrossWeight14 / quantity14,
                    itemGrossWeight15: itemGrossWeight15 / quantity15,
                    itemGrossWeight16: itemGrossWeight16 / quantity16,
                    itemGrossWeight17: itemGrossWeight17 / quantity17,
                    itemGrossWeight18: itemGrossWeight18 / quantity18,
                    itemGrossWeight19: itemGrossWeight19 / quantity19,
                    itemGrossWeight20: itemGrossWeight20 / quantity20,
                    itemNetWeight: itemNetWeight / quantity,
                    itemNetWeight2: itemNetWeight2 / quantity2,
                    itemNetWeight3: itemNetWeight3 / quantity3,
                    itemNetWeight4: itemNetWeight4 / quantity4,
                    itemNetWeight5: itemNetWeight5 / quantity5,
                    itemNetWeight6: itemNetWeight6 / quantity6,
                    itemNetWeight7: itemNetWeight7 / quantity7,
                    itemNetWeight8: itemNetWeight8 / quantity8,
                    itemNetWeight9: itemNetWeight9 / quantity9,
                    itemNetWeight10: itemNetWeight10 / quantity10,
                    itemNetWeight11: itemNetWeight11 / quantity11,
                    itemNetWeight12: itemNetWeight12 / quantity12,
                    itemNetWeight13: itemNetWeight13 / quantity13,
                    itemNetWeight14: itemNetWeight14 / quantity14,
                    itemNetWeight15: itemNetWeight15 / quantity15,
                    itemNetWeight16: itemNetWeight16 / quantity16,
                    itemNetWeight17: itemNetWeight17 / quantity17,
                    itemNetWeight18: itemNetWeight18 / quantity18,
                    itemNetWeight19: itemNetWeight19 / quantity19,
                    itemNetWeight20: itemNetWeight20 / quantity20,
                    itemCurrency: "USD",
                    itemCurrency2: s.invoiceValue.length >= 2 ? "USD" : "",
                    itemCurrency3: s.invoiceValue.length >= 3 ? "USD" : "",
                    itemCurrency4: s.invoiceValue.length >= 4 ? "USD" : "",
                    itemCurrency5: s.invoiceValue.length >= 5 ? "USD" : "",
                    itemCurrency6: s.invoiceValue.length >= 6 ? "USD" : "",
                    itemCurrency7: s.invoiceValue.length >= 7 ? "USD" : "",
                    itemCurrency8: s.invoiceValue.length >= 8 ? "USD" : "",
                    itemCurrency9: s.invoiceValue.length >= 9 ? "USD" : "",
                    itemCurrency10: s.invoiceValue.length >= 10 ? "USD" : "",
                    itemCurrency11: s.invoiceValue.length >= 11 ? "USD" : "",
                    itemCurrency12: s.invoiceValue.length >= 12 ? "USD" : "",
                    itemCurrency13: s.invoiceValue.length >= 13 ? "USD" : "",
                    itemCurrency14: s.invoiceValue.length >= 14 ? "USD" : "",
                    itemCurrency15: s.invoiceValue.length >= 15 ? "USD" : "",
                    itemCurrency16: s.invoiceValue.length >= 16 ? "USD" : "",
                    itemCurrency17: s.invoiceValue.length >= 17 ? "USD" : "",
                    itemCurrency18: s.invoiceValue.length >= 18 ? "USD" : "",
                    itemCurrency19: s.invoiceValue.length >= 19 ? "USD" : "",
                    itemCurrency20: s.invoiceValue.length >= 20 ? "USD" : "",
                    countryManufactured: "VN",
                    countryManufactured2: s.invoiceValue.length >= 2 ? "VN" : "",
                    countryManufactured3: s.invoiceValue.length >= 3 ? "VN" : "",
                    countryManufactured4: s.invoiceValue.length >= 4 ? "VN" : "",
                    countryManufactured5: s.invoiceValue.length >= 5 ? "VN" : "",
                    countryManufactured6: s.invoiceValue.length >= 6 ? "VN" : "",
                    countryManufactured7: s.invoiceValue.length >= 7 ? "VN" : "",
                    countryManufactured8: s.invoiceValue.length >= 8 ? "VN" : "",
                    countryManufactured9: s.invoiceValue.length >= 9 ? "VN" : "",
                    countryManufactured10: s.invoiceValue.length >= 10 ? "VN" : "",
                    countryManufactured11: s.invoiceValue.length >= 11 ? "VN" : "",
                    countryManufactured12: s.invoiceValue.length >= 12 ? "VN" : "",
                    countryManufactured13: s.invoiceValue.length >= 13 ? "VN" : "",
                    countryManufactured14: s.invoiceValue.length >= 14 ? "VN" : "",
                    countryManufactured15: s.invoiceValue.length >= 15 ? "VN" : "",
                    countryManufactured16: s.invoiceValue.length >= 16 ? "VN" : "",
                    countryManufactured17: s.invoiceValue.length >= 17 ? "VN" : "",
                    countryManufactured18: s.invoiceValue.length >= 18 ? "VN" : "",
                    countryManufactured19: s.invoiceValue.length >= 19 ? "VN" : "",
                    countryManufactured20: s.invoiceValue.length >= 20 ? "VN" : "",
                    hsCode: hsCode,
                    hsCode2: hsCode2,
                    hsCode3: hsCode3,
                    hsCode4: hsCode4,
                    hsCode5: hsCode5,
                    hsCode6: hsCode6,
                    hsCode7: hsCode7,
                    hsCode8: hsCode8,
                    hsCode9: hsCode9,
                    hsCode10: hsCode10,
                    hsCode11: hsCode11,
                    hsCode12: hsCode12,
                    hsCode13: hsCode13,
                    hsCode14: hsCode14,
                    hsCode15: hsCode15,
                    hsCode16: hsCode16,
                    hsCode17: hsCode17,
                    hsCode18: hsCode18,
                    hsCode19: hsCode19,
                    hsCode20: hsCode20,
                    hsCode21: hsCode21,
                    hsCode22: hsCode22,
                    hsCode23: hsCode23,
                    hsCode24: hsCode24,
                    hsCode25: hsCode25,
                    hsCode26: hsCode26,
                    hsCode27: hsCode27,
                    hsCode28: hsCode28,
                    hsCode29: hsCode29,
                    hsCode30: hsCode30,
                    hsCode31: hsCode31,
                    hsCode32: hsCode32,
                    hsCode33: hsCode33,
                    hsCode34: hsCode34,
                    hsCode35: hsCode35,
                    hsCode36: hsCode36,
                    hsCode37: hsCode37,
                    hsCode38: hsCode38,
                    hsCode39: hsCode39,
                    hsCode40: hsCode40,
                    hsCode41: hsCode41,
                    hsCode42: hsCode42,
                    hsCode43: hsCode43,
                    hsCode44: hsCode44,
                    hsCode45: hsCode45,
                    hsCode46: hsCode46,
                    hsCode47: hsCode47,
                    hsCode48: hsCode48,
                    hsCode49: hsCode49,
                    hsCode50: hsCode50,
                    hsCode51: hsCode51,
                    hsCode52: hsCode52,
                    hsCode53: hsCode53,
                    hsCode54: hsCode54,
                    hsCode55: hsCode55,
                    hsCode56: hsCode56,
                    hsCode57: hsCode57,
                    hsCode58: hsCode58,
                    hsCode59: hsCode59,
                    hsCode60: hsCode60,
                    hsCode61: hsCode61,
                    hsCode62: hsCode62,
                    hsCode63: hsCode63,
                    hsCode64: hsCode64,
                    hsCode65: hsCode65,
                    hsCode66: hsCode66,
                    hsCode67: hsCode67,
                    hsCode68: hsCode68,
                    hsCode69: hsCode69,
                    hsCode70: hsCode70,
                    hsCode71: hsCode71,
                    hsCode72: hsCode72,
                    hsCode73: hsCode73,
                    hsCode74: hsCode74,
                    hsCode75: hsCode75,
                    hsCode76: hsCode76,
                    hsCode77: hsCode77,
                    hsCode78: hsCode78,
                    hsCode79: hsCode79,
                    hsCode80: hsCode80,
                    hsCode81: hsCode81,
                    hsCode82: hsCode82,
                    hsCode83: hsCode83,
                    hsCode84: hsCode84,
                    hsCode85: hsCode85,
                    hsCode86: hsCode86,
                    hsCode87: hsCode87,
                    hsCode88: hsCode88,
                    hsCode89: hsCode89,
                    hsCode90: hsCode90,
                    hsCode91: hsCode91,
                    hsCode92: hsCode92,
                    hsCode93: hsCode93,
                    hsCode94: hsCode94,
                    hsCode95: hsCode95,
                    hsCode96: hsCode96,
                    hsCode97: hsCode97,
                    hsCode98: hsCode98,
                    hsCode99: hsCode99,
                    hsCode100: hsCode100,
                    taxIT: "",
                    slash: "#",
                    slash2: itemNo2 === "" ? "" : "#",
                    slash3: itemNo3 === "" ? "" : "#",
                    slash4: itemNo4 === "" ? "" : "#",
                    slash5: itemNo5 === "" ? "" : "#",
                    slash6: itemNo6 === "" ? "" : "#",
                    slash7: itemNo7 === "" ? "" : "#",
                    slash8: itemNo8 === "" ? "" : "#",
                    slash9: itemNo9 === "" ? "" : "#",
                    slash10: itemNo10 === "" ? "" : "#",
                    slash11: itemNo11 === "" ? "" : "#",
                    slash12: itemNo12 === "" ? "" : "#",
                    slash13: itemNo13 === "" ? "" : "#",
                    slash14: itemNo14 === "" ? "" : "#",
                    slash15: itemNo15 === "" ? "" : "#",
                    slash16: itemNo16 === "" ? "" : "#",
                    slash17: itemNo17 === "" ? "" : "#",
                    slash18: itemNo18 === "" ? "" : "#",
                    slash19: itemNo19 === "" ? "" : "#",
                    slash20: itemNo20 === "" ? "" : "#",
                    slash21: itemNo21 === "" ? "" : "#",
                    slash22: itemNo22 === "" ? "" : "#",
                    slash23: itemNo23 === "" ? "" : "#",
                    slash24: itemNo24 === "" ? "" : "#",
                    slash25: itemNo25 === "" ? "" : "#",
                    slash26: itemNo26 === "" ? "" : "#",
                    slash27: itemNo27 === "" ? "" : "#",
                    slash28: itemNo28 === "" ? "" : "#",
                    slash29: itemNo29 === "" ? "" : "#",
                    slash30: itemNo30 === "" ? "" : "#",
                    slash31: itemNo31 === "" ? "" : "#",
                    slash32: itemNo32 === "" ? "" : "#",
                    slash33: itemNo33 === "" ? "" : "#",
                    slash34: itemNo34 === "" ? "" : "#",
                    slash35: itemNo35 === "" ? "" : "#",
                    slash36: itemNo36 === "" ? "" : "#",
                    slash37: itemNo37 === "" ? "" : "#",
                    slash38: itemNo38 === "" ? "" : "#",
                    slash39: itemNo39 === "" ? "" : "#",
                    slash40: itemNo40 === "" ? "" : "#",
                    slash41: itemNo41 === "" ? "" : "#",
                    slash42: itemNo42 === "" ? "" : "#",

                    slash43: itemNo43 === "" ? "" : "#",
                    slash44: itemNo44 === "" ? "" : "#",
                    slash45: itemNo45 === "" ? "" : "#",
                    slash46: itemNo46 === "" ? "" : "#",
                    slash47: itemNo47 === "" ? "" : "#",
                    slash48: itemNo48 === "" ? "" : "#",
                    slash49: itemNo49 === "" ? "" : "#",
                    slash50: itemNo50 === "" ? "" : "#",
                    slash51: itemNo51 === "" ? "" : "#",
                    slash52: itemNo52 === "" ? "" : "#",
                    slash53: itemNo53 === "" ? "" : "#",
                    slash54: itemNo54 === "" ? "" : "#",
                    slash55: itemNo55 === "" ? "" : "#",
                    slash56: itemNo56 === "" ? "" : "#",
                    slash57: itemNo57 === "" ? "" : "#",
                    slash58: itemNo58 === "" ? "" : "#",
                    slash59: itemNo59 === "" ? "" : "#",
                    slash60: itemNo60 === "" ? "" : "#",
                    slash61: itemNo61 === "" ? "" : "#",
                    slash62: itemNo62 === "" ? "" : "#",
                    slash63: itemNo63 === "" ? "" : "#",
                    slash64: itemNo64 === "" ? "" : "#",
                    slash65: itemNo65 === "" ? "" : "#",
                    slash66: itemNo66 === "" ? "" : "#",
                    slash67: itemNo67 === "" ? "" : "#",
                    slash68: itemNo68 === "" ? "" : "#",
                    slash69: itemNo69 === "" ? "" : "#",
                    slash70: itemNo70 === "" ? "" : "#",
                    slash71: itemNo71 === "" ? "" : "#",
                    slash72: itemNo72 === "" ? "" : "#",
                    slash73: itemNo73 === "" ? "" : "#",
                    slash74: itemNo74 === "" ? "" : "#",
                    slash75: itemNo75 === "" ? "" : "#",
                    slash76: itemNo76 === "" ? "" : "#",
                    slash77: itemNo77 === "" ? "" : "#",
                    slash78: itemNo78 === "" ? "" : "#",
                    slash79: itemNo79 === "" ? "" : "#",
                    slash80: itemNo80 === "" ? "" : "#",
                    slash81: itemNo81 === "" ? "" : "#",
                    slash82: itemNo82 === "" ? "" : "#",
                    slash83: itemNo83 === "" ? "" : "#",
                    slash84: itemNo84 === "" ? "" : "#",
                    slash85: itemNo85 === "" ? "" : "#",
                    slash86: itemNo86 === "" ? "" : "#",
                    slash87: itemNo87 === "" ? "" : "#",
                    slash88: itemNo88 === "" ? "" : "#",
                    slash89: itemNo89 === "" ? "" : "#",
                    slash90: itemNo90 === "" ? "" : "#",
                    slash91: itemNo91 === "" ? "" : "#",
                    slash92: itemNo92 === "" ? "" : "#",
                    slash93: itemNo93 === "" ? "" : "#",
                    slash94: itemNo94 === "" ? "" : "#",
                    slash95: itemNo95 === "" ? "" : "#",
                    slash96: itemNo96 === "" ? "" : "#",
                    slash97: itemNo97 === "" ? "" : "#",
                    slash98: itemNo98 === "" ? "" : "#",
                    slash99: itemNo99 === "" ? "" : "#",
                    slash100: itemNo100 === "" ? "" : "#",
                    shortAddress: s.fromAddress.slice(0, 30),
                }
            }))
            setLoading(false);
            setFileName("");
            setFileUrl("");
            setErr(false);
        })
    }







    const roleBase = shipments.filter((s) => {
        if (userData.role === "Sales") {
            return s.controller === userData.displayName;
        } else {
            return s;
        }
    })
    const finalShipment = roleBase.filter((s, i) => {
        let plan = s.plan || "";
        let chargeable = s.chargeable.toString() || "";
        let reweight = s.reweight.toString() || "";
        let amount = s.amount.toString() || "";
        return s.date >= startDate && s.date <= endDate &&
            s.sender.toLowerCase().includes(searchCus.toLowerCase()) &&
            s.pack.toLowerCase().includes(searchPack.toLowerCase()) &&
            s.booked.toLowerCase().includes(status.toLowerCase()) &&
            plan.toLowerCase().includes(searchClass.toLowerCase()) &&
            s.country.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
            s.type.toLowerCase().includes(searchType.toLowerCase()) &&
            s.code.toLowerCase().startsWith(searchCode.toLowerCase()) &&
            chargeable.toLowerCase().includes(searchWeight.toLowerCase()) &&
            reweight.toLowerCase().includes(searchReweight.toLowerCase()) &&
            amount.toLowerCase().includes(searchPcs.toLowerCase()) &&
            s.airline.toLowerCase().includes(searchAirline.toLowerCase()) &&
            s.service.toLowerCase().includes(searchService.toLowerCase()) &&
            s.agent.toLowerCase().includes(searchAgent.toLowerCase()) &&
            s.mawb.toLowerCase().includes(searchMawb.toLowerCase()) &&
            s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
            s.controller.toLowerCase().includes(searchSale.toLowerCase()) &&
            s.paymentPickup.toLowerCase().includes(searchPickup.toLowerCase()) &&
            s.paymentAcc.toLowerCase().includes(searchAcc.toLowerCase()) &&
            s.paymentCompany.toLowerCase().includes(searchCompany.toLowerCase()) &&
            s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) &&
            s.sin.toLowerCase().includes(findSin.toLowerCase()) &&
            s.mawb.toLowerCase().includes(findMawb.toLowerCase());
    })

    const deleteBooking = () => {
        finalShipment.forEach((s) => {
            db.collection("shipments").doc(s.hawb).delete();
        })
    }

    // const deleteShipment = () => {
    //     db.collection("shipments").where("isMerged", "==", "true").get().then((snapshot) => {
    //         snapshot.forEach(doc => {
    //             const data = doc.data();
    //             console.log(doc.id, data);
    //         })
    //     })
    // }
    const pages = [];
    for (let i = 1; i <= Math.ceil(finalShipment.length / itemPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = finalShipment.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (e) => {
        setCurrentPage(Number(e.target.id));
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageLimit + 1 && number > minPageLimit) {
            return (
                <li key={number} id={number} onClick={handleChangePage} className={currentPage == number ? "active" : null}>
                    {number}
                </li>
            )
        } else {
            return null;
        }
    });
    const sortedCus1 = [...new Set(finalShipment.map(s => s.sender))];
    const sortedCountry1 = [...new Set(finalShipment.map(s => s.country))];
    const sortedCode = [...new Set(finalShipment.map(s => s.code))];
    const sortedEmail = [...new Set(shipments.map(s => s.fromEmail))];
    const sortedEmail1 = sortedEmail.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const sortedCustomer = sortedCus1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const sortedCountry = sortedCountry1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const handleSelectAll = (e) => {
        let value = e.target.checked;
        finalShipment.map(s => {
            s.select = value;
            return s;
        })
    }
    const sortCustomer = () => {
        setDisplaySortCustomer(true);
    }
    const sortMawb = () => {
        setDisplaySortMawb(true);
    }
    const exportSelected = finalShipment.filter((s) => {
        return s.select;
    })



    const unbook = shipments.filter((s) => {
        return s.booked === "false" && s.date >= startDate && s.date <= endDate;
    })

    const booked = shipments.filter((s) => {
        return s.booked === "true" && s.date >= startDate && s.date <= endDate;
    })
    const dus = finalShipment.filter((s) => {
        return s.service === "DUS";
    })
    // const dhl = finalShipment.filter((s) => {
    //     return s.service === "DHL";
    // })
    // const fedex = finalShipment.filter((s) => {
    //     return s.service === "FIP";
    // })
    // const ups = finalShipment.filter((s) => {
    //     return s.service === "UPS";
    // })
    // const skd = finalShipment.filter((s) => {
    //     return s.service === "SKD";
    // })
    useEffect(() => {
        let arr = [];
        exportSelected.map((s) => {
            arr.push(s.hawb);
        })
        setLabels(arr.toString());
    }, [exportSelected])
    useEffect(() => {
        if (labels !== "") {
            exportSelected.map((s) => {
                setReceiver(s.sender);
                setReceiveEmail(s.fromEmail);
            })
        } else if (labels.length === 0) {
            setReceiver("");
            setReceiveEmail("");
        }
    }, [labels])
    const getArrayMax = (array) => {
        return Math.max.apply(null, array);
    }
    const highestItem = getArrayMax(exportSelected.map((s) => s.invoiceValue.length));
    // const headers = exportSelected.map((s) => {
    //     return {

    //     }
    // })
    const cleHeader = [
        { label: "SHIPPER DHL ACCOUNT NUMBER (9)", key: "dhlAccount" },
        { label: "Contact Name (35)", key: "cleName" },
        { label: "Company Name (45)", key: "sender" },
        { label: "Address Line 1 (35)", key: "cleAddress" },
        { label: "Street/house number (Not required)", key: "" },
        { label: "Address Line 2 (35)", key: "cleAddress2" },
        { label: "Address Line 3 (35)", key: "cleAddress3" },
        { label: "Post code", key: "clePostal" },
        { label: "City", key: "cleCity" },
        { label: "Suburb", key: "" },
        { label: "State/Province", key: "" },
        { label: "Country Code (2)", key: "cleCode" },
        { label: "Email Address", key: "cleEmail" },
        { label: "Phone Type (H/M/O)", key: "clePhoneType" },
        { label: "Phone Country Code", key: "clePhoneCountryCode" },
        { label: "Phone Number", key: "clePhone" },
        { label: "Phone Office - Extension (5)", key: "" },
        { label: "SMS Capiability (Y/N)", key: "" },
        { label: "Fax Country Code (Refer to GUIDE on other)", key: "" },
        { label: "Fax", key: "" },
        { label: "VAT/Tax ID (35)", key: "" },
        { label: "EORI", key: "" },
        { label: "CNPJ/CPF Number", key: "" },
        { label: "CNPJ/CPF Type", key: "" },
        { label: "IE/RG Number (15)", key: "" },
        { label: "Residential Address (Y/N)", key: "" },
        { label: "Contact Name (35)", key: "contact" },
        { label: "Company Name (45)", key: "company" },
        { label: "Address Line 1 (35)", key: "address" },
        { label: "Street/house number", key: "address" },
        { label: "Address Line 2 (35)", key: "address2" },
        { label: "Address Line 3 (35)", key: "address3" },
        { label: "Post code", key: "postal" },
        { label: "City", key: "city" },
        { label: "Suburb", key: "town" },
        { label: "State/Province", key: "cleState" },
        { label: "Country Code (2)", key: "code" },
        { label: "Email Address", key: "email" },
        { label: "Phone Type (H/M/O)", key: "toPhoneType" },
        { label: "Phone Country Code", key: "toPhoneCode" },
        { label: "Phone Number", key: "phone" },
        { label: "Phone Office - Extension (5)", key: "" },
        { label: "SMS Capiability (Y/N)", key: "" },
        { label: "Fax Country Code (Refer to GUIDE on other)", key: "" },
        { label: "Fax", key: "" },
        { label: "VAT/Tax ID (35)", key: "" },
        { label: "EORI", key: "eori" },
        { label: "CR Number", key: "" },
        { label: "CNPJ/CPF Number", key: "" },
        { label: "CNPJ/CPF Type", key: "" },
        { label: "IE Number (15)", key: "" },
        { label: "Residential Address (Y/N)", key: "" },
        { label: "Notes", key: "" },
        { label: "AWB Number (10)", key: "" },
        { label: "Shipment Date (YYYYMMDD) (Up to 3 Months from today)", key: "cleDate" },
        { label: "Shipment Type (D/P) (D = Documents, P = Non-Documents", key: "cleShipmentType" },
        { label: "3 Letter Product Code (DOX = Documents, WPX = Non-Documents)", key: "type" },
        { label: "Global Product Code (D = Documents, P = Non-Documents)", key: "cleShipmentType" },
        { label: "Local Product Code (D = Documents, P = Non-Documents", key: "cleShipmentType" },
        { label: "Shipment Description (170) (For Documents, input as Documents - general business) only", key: "cleDesc" },
        { label: "Reference (30)", key: "hawb" },
        { label: "Declared value (Required only for Non-Documents)", key: "value" },
        { label: "Declared value currency (3) (Required only for Non-Documents)", key: "currency" },
        { label: "Insurance value (Required only for Non-Documents)", key: "" },
        { label: "Insurance value currency (3) (Required only for Non-Documents)", key: "" },
        { label: "Shipper Account (9)", key: "dhlAccount" },
        { label: "Payer Account (9)", key: "dhlAccount" },
        { label: "Duty & Tax account (9) (Required for Non-Documents DDP Shipments only)", key: "" },
        { label: "Split Duty & Tax Indicator", key: "" },
        { label: "Duty Account", key: "" },
        { label: "Tax Account", key: "" },
        { label: "Incoterm (3) (DAP = Receiver pays for Duties & Taxes, DDP = Shipper pays for Duties & Taxes)", key: "incoterm" },
        { label: "Purpose Of Shipment (Refer to GUIDE on other tab)", key: "reason" },
        { label: "List of emails to be notified", key: "" },
        { label: "Additional message for notification", key: "" },
        { label: "Type of export (Input as P)", key: "" },
        { label: "*Piece Weight", key: "firstPcs" },
        { label: "Weight UOM (KG)", key: "weightCode" },
        { label: "Piece Length", key: "firstLength" },
        { label: "Piece Width", key: "firstWidth" },
        { label: "Piece Height", key: "firstHeight" },
        { label: "Dim UOM (CM)", key: "dimCode" },
        { label: "Piece Reference", key: "" },
        { label: "Pallet Identifier", key: "" },
        { label: "Extended Liability (IB) - Insurance for DOX Only", key: "" },
        { label: "Paperless Trade (Y/N)", key: "" },
        { label: "Hold For Collection (LX)", key: "" },
        { label: "Temporary Import/Export (WM)", key: "" },
        { label: "Neutral Delivery (NN)", key: "" },
        { label: "Saturday Delivery (AA)", key: "satDev" },
        { label: "Hold At Facility (LZ)", key: "" },
        { label: "^Hold At Facility-Facility ID", key: "" },
        { label: "Go Green (EE)", key: "" },
        { label: "Lithium Ion (HB)", key: "" },
        { label: "Dry Ice UN1845 (HC)", key: "" },
        { label: "^Dry Ice UN1845- weight (delimited with ; for multiple values)", key: "" },
        { label: "Lithium Ion (HD)", key: "" },
        { label: "Full Dangerous Goods (HE)", key: "" },
        { label: "Excepted Quantities (HH) ", key: "" },
        { label: "Limited Quantities (HL) ", key: "" },
        { label: "DG UN Number", key: "" },
        { label: "Lithium Metal (HM)", key: "" },
        { label: "Lithium Ion (HV) ", key: "" },
        { label: "Lithium Metal (HW) ", key: "" },
        { label: "Biological UN3373 (HY) ", key: "" },
        { label: "^DG Class Ids with ;delimited", key: "" },
        { label: "Saturday Pickup (AB)", key: "" },
        { label: "Gogreen service for Certified acounts (EA)", key: "" },
        { label: "service QJ", key: "" },
        { label: "service WL", key: "" },
        { label: "service DD", key: "" },
        { label: "service DC", key: "" },
        { label: "service QY", key: "" },
        { label: "*Invoice Type (COM/PRO)", key: "" },
        { label: "*Invoice Number", key: "hawb" },
        { label: "*Invoice Date (YYYYMMDD)", key: "cleDate" },
        { label: "Exporter ID", key: "makh" },
        { label: "Exporter ID", key: "makh" },
        { label: "Shipper VAT (35)", key: "" },
        { label: "Receiver VAT (35)", key: "" },
        { label: "Ultimate destination", key: "" },
        { label: "Named Destination", key: "" },
        { label: "Terms of payment", key: "" },
        { label: "Number of Pallets", key: "" },
        { label: "Invoice reference", key: "" },
        { label: "Package Marks", key: "" },
        { label: "Remarks", key: "" },
        { label: "Name of the person", key: "" },
        { label: "Title", key: "" },
        { label: "Other Charge1 value", key: "" },
        { label: "Other Charge1 Description", key: "" },
        { label: "Other Charge2 value", key: "" },
        { label: "Other Charge2 Description", key: "" },
        { label: "Other Charge3 value", key: "" },
        { label: "Other Charge3 Description", key: "" },
        { label: "Contact Name", key: "" },
        { label: "Company name", key: "" },
        { label: "Address line 1", key: "" },
        { label: "Street/house number", key: "" },
        { label: "Address line 2", key: "" },
        { label: "address line 3", key: "" },
        { label: "Post code", key: "" },
        { label: "City", key: "" },
        { label: "Suburb", key: "" },
        { label: "State/State code/Province", key: '' },
        { label: "Country Code", key: "" },
        { label: "Email Address", key: "" },
        { label: 'Phone type (H/M/O)', key: "" },
        { label: "Phone Country code", key: "" },
        { label: "Phone number", key: "" },
        { label: "Phone Office- Extension", key: "" },
        { label: "SMS capability", key: "" },
        { label: "Fax Country Code", key: "" },
        { label: "Fax", key: "" },
        { label: "Vat/Tax", key: "" },
        { label: "EORI", key: "eori" },
        { label: "CR number", key: "" },
        { label: "CNPJ/CPF number", key: "" },
        { label: "CNPJ/CPF type", key: "" },
        { label: "IE number", key: "" },
        { label: "Residential Address", key: "" },
        { label: "Notes", key: "" },
        { label: "Number Of Line Items (Must match no. of rows under invoice Items", key: "numberOfLine" },
        { label: "*Invoice Line Description (170)", key: "itemName" },
        { label: "Commodity Code", key: "" },
        { label: "Quantity", key: "quantity" },
        { label: "UOM (input as PCS)", key: "uom" },
        { label: "Item Value", key: "item_value" },
        { label: "Item Value Currency (SGD or others)", key: "itemCurrency" },
        { label: "*Net Weight (in KG)", key: "itemNetWeight" },
        { label: "*Gross Weight (in KG)", key: "itemGrossWeight" },
        { label: "*Country Of Origin (Made in which country)", key: "countryManufactured" },

        highestItem >= 2 ? { label: "*Invoice Line Description (170)", key: "itemName2" } : { label: "*Invoice Line Descriptoin (170)", key: "" },
        highestItem >= 2 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 2 ? { label: "Quantity", key: "quantity2" } : { label: "Quantity", key: "" },
        highestItem >= 2 ? { label: "UOM (input as PCS)", key: "uom2" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 2 ? { label: "Item Value", key: "item_value2" } : { label: "Item Value", key: "" },
        highestItem >= 2 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency2" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 2 ? { label: "*Net Weight (in KG)", key: "itemNetWeight2" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 2 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight2" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 2 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured2" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 3 ? { label: "*Invoice Line Description (170)", key: "itemName3" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 3 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 3 ? { label: "Quantity", key: "quantity3" } : { label: "Quantity", key: "" },
        highestItem >= 3 ? { label: "UOM (input as PCS)", key: "uom3" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 3 ? { label: "Item Value", key: "item_value3" } : { label: "Item Value", key: "" },
        highestItem >= 3 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency3" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 3 ? { label: "*Net Weight (in KG)", key: "itemNetWeight3" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 3 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight3" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 3 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured3" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 4 ? { label: "*Invoice Line Description (170)", key: "itemName4" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 4 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 4 ? { label: "Quantity", key: "quantity4" } : { label: "Quantity", key: "" },
        highestItem >= 4 ? { label: "UOM (input as PCS)", key: "uom4" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 4 ? { label: "Item Value", key: "item_value4" } : { label: "Item Value", key: "" },
        highestItem >= 4 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency4" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 4 ? { label: "*Net Weight (in KG)", key: "itemNetWeight4" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 4 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight4" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 4 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured4" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 5 ? { label: "*Invoice Line Description (170)", key: "itemName5" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 5 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 5 ? { label: "Quantity", key: "quantity5" } : { label: "Quantity", key: "" },
        highestItem >= 5 ? { label: "UOM (input as PCS)", key: "uom5" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 5 ? { label: "Item Value", key: "item_value5" } : { label: "Item Value", key: "" },
        highestItem >= 5 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency5" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 5 ? { label: "*Net Weight (in KG)", key: "itemNetWeight5" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 5 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight5" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 5 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured5" } : { label: "*Country Of Origin (Made in which ocuntry)", key: "" },

        highestItem >= 6 ? { label: "*Invoice Line Description (170)", key: "itemName6" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 6 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 6 ? { label: "Quantity", key: "quantity6" } : { label: "Quantity", key: "" },
        highestItem >= 6 ? { label: "UOM (input as PCS)", key: "uom6" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 6 ? { label: "Item Value", key: "item_value6" } : { label: "Item Value", key: "" },
        highestItem >= 6 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency6" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 6 ? { label: "*Net Weight (in KG)", key: "itemNetWeight6" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 6 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight6" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 6 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured6" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 7 ? { label: "*Invoice Line Description (170)", key: "itemName7" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 7 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 7 ? { label: "Quantity", key: "quantity7" } : { label: "Quantity", key: "" },
        highestItem >= 7 ? { label: "UOM (input as PCS)", key: "uom7" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 7 ? { label: "Item Value", key: "item_value7" } : { label: "Item Value", key: "" },
        highestItem >= 7 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency7" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 7 ? { label: "*Net Weight (in KG)", key: "itemNetWeight7" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 7 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight7" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 7 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured7" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 8 ? { label: "*Invoice Line Description (170)", key: "itemName8" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 8 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 8 ? { label: "Quantity", key: "quantity8" } : { label: "Quantity", key: "" },
        highestItem >= 8 ? { label: "UOM (input as PCS)", key: "uom8" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 8 ? { label: "Item Value", key: "item_value8" } : { label: "Item Value", key: "" },
        highestItem >= 8 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency8" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 8 ? { label: "*Net Weight (in KG)", key: "itemNetWeight8" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 8 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight8" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 8 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured8" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 9 ? { label: "*Invoice Line Description (170)", key: "itemName9" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 9 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 9 ? { label: "Quantity", key: "quantity9" } : { label: "Quantity", key: "" },
        highestItem >= 9 ? { label: "UOM (input as PCS)", key: "uom9" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 9 ? { label: "Item Value", key: "item_value9" } : { label: "Item Value", key: "" },
        highestItem >= 9 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency9" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 9 ? { label: "*Net Weight (in KG)", key: "itemNetWeight9" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 9 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight9" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 9 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured9" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 10 ? { label: "*Invoice Line Description (170)", key: "itemName10" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 10 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 10 ? { label: "Quantity", key: "quantity10" } : { label: "Quantity", key: "" },
        highestItem >= 10 ? { label: "UOM (input as PCS)", key: "uom10" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 10 ? { label: "Item Value", key: "item_value10" } : { label: "Item Value", key: "" },
        highestItem >= 10 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency10" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 10 ? { label: "*Net Weight (in KG)", key: "itemNetWeight10" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 10 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight10" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 10 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured10" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 11 ? { label: "*Invoice Line Description (170)", key: "itemName11" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 11 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 11 ? { label: "Quantity", key: "quantity11" } : { label: "Quantity", key: "" },
        highestItem >= 11 ? { label: "UOM (input as PCS)", key: "uom11" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 11 ? { label: "Item Value", key: "item_value11" } : { label: "Item Value", key: "" },
        highestItem >= 11 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency11" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 11 ? { label: "*Net Weight (in KG)", key: "itemNetWeight11" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 11 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight11" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 11 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured11" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 12 ? { label: "*Invoice Line Description (170)", key: "itemName12" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 12 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 12 ? { label: "Quantity", key: "quantity12" } : { label: "Quantity", key: "" },
        highestItem >= 12 ? { label: "UOM (input as PCS)", key: "uom12" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 12 ? { label: "Item Value", key: "item_value12" } : { label: "Item Value", key: "" },
        highestItem >= 12 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency12" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 12 ? { label: "*Net Weight (in KG)", key: "itemNetWeight12" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 12 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight12" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 12 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured12" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 13 ? { label: "*Invoice Line Description (170)", key: "itemName13" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 13 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 13 ? { label: "Quantity", key: "quantity13" } : { label: "Quantity", key: "" },
        highestItem >= 13 ? { label: "UOM (input as PCS)", key: "uom13" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 13 ? { label: "Item Value", key: "item_value13" } : { label: "Item Value", key: "" },
        highestItem >= 13 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency13" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 13 ? { label: "*Net Weight (in KG)", key: "itemNetWeight13" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 13 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight13" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 13 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured13" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 14 ? { label: "*Invoice Line Description (170)", key: "itemName14" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 14 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 14 ? { label: "Quantity", key: "quantity14" } : { label: "Quantity", key: "" },
        highestItem >= 14 ? { label: "UOM (input as PCS)", key: "uom14" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 14 ? { label: "Item Value", key: "item_value14" } : { label: "Item Value", key: "" },
        highestItem >= 14 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency14" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 14 ? { label: "*Net Weight (in KG)", key: "itemNetWeight14" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 14 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight14" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 14 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured14" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 15 ? { label: "*Invoice Line Description (170)", key: "itemName15" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 15 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 15 ? { label: "Quantity", key: "quantity15" } : { label: "Quantity", key: "" },
        highestItem >= 15 ? { label: "UOM (input as PCS)", key: "uom15" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 15 ? { label: "Item Value", key: "item_value15" } : { label: "Item Value", key: "" },
        highestItem >= 15 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency15" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 15 ? { label: "*Net Weight (in KG)", key: "itemNetWeight15" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 15 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight15" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 15 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured15" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 16 ? { label: "*Invoice Line Description (170)", key: "itemName16" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 16 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 16 ? { label: "Quantity", key: "quantity16" } : { label: "Quantity", key: "" },
        highestItem >= 16 ? { label: "UOM (input as PCS)", key: "uom16" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 16 ? { label: "Item Value", key: "item_value16" } : { label: "Item Value", key: "" },
        highestItem >= 16 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency16" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 16 ? { label: "*Net Weight (in KG)", key: "itemNetWeight16" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 16 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight16" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 16 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured16" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 17 ? { label: "*Invoice Line Description (170)", key: "itemName17" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 17 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 17 ? { label: "Quantity", key: "quantity17" } : { label: "Quantity", key: "" },
        highestItem >= 17 ? { label: "UOM (input as PCS)", key: "uom17" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 17 ? { label: "Item Value", key: "item_value17" } : { label: "Item Value", key: "" },
        highestItem >= 17 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency17" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 17 ? { label: "*Net Weight (in KG)", key: "itemNetWeight17" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 17 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight17" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 17 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured17" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 18 ? { label: "*Invoice Line Description (170)", key: "itemName18" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 18 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 18 ? { label: "Quantity", key: "quantity18" } : { label: "Quantity", key: "" },
        highestItem >= 18 ? { label: "UOM (input as PCS)", key: "uom18" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 18 ? { label: "Item Value", key: "item_value18" } : { label: "Item Value", key: "" },
        highestItem >= 18 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency18" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 18 ? { label: "*Net Weight (in KG)", key: "itemNetWeight18" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 18 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight18" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 18 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured18" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 19 ? { label: "*Invoice Line Description (170)", key: "itemName19" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 19 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 19 ? { label: "Quantity", key: "quantity19" } : { label: "Quantity", key: "" },
        highestItem >= 19 ? { label: "UOM (input as PCS)", key: "uom19" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 19 ? { label: "Item Value", key: "item_value19" } : { label: "Item Value", key: "" },
        highestItem >= 19 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency19" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 19 ? { label: "*Net Weight (in KG)", key: "itemNetWeight19" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 19 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight19" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 19 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured19" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        highestItem >= 20 ? { label: "*Invoice Line Description (170)", key: "itemName20" } : { label: "*Invoice Line Description (170)", key: "" },
        highestItem >= 20 ? { label: "Commodity Code", key: "" } : { label: "Commodity Code", key: "" },
        highestItem >= 20 ? { label: "Quantity", key: "quantity20" } : { label: "Quantity", key: "" },
        highestItem >= 20 ? { label: "UOM (input as PCS)", key: "uom20" } : { label: "UOM (input as PCS)", key: "" },
        highestItem >= 20 ? { label: "Item Value", key: "item_value20" } : { label: "Item Value", key: "" },
        highestItem >= 20 ? { label: "Item Value Currency (SGD or others)", key: "itemCurrency20" } : { label: "Item Value Currency (SGD or others)", key: "" },
        highestItem >= 20 ? { label: "*Net Weight (in KG)", key: "itemNetWeight20" } : { label: "*Net Weight (in KG)", key: "" },
        highestItem >= 20 ? { label: "*Gross Weight (in KG)", key: "itemGrossWeight20" } : { label: "*Gross Weight (in KG)", key: "" },
        highestItem >= 20 ? { label: "*Country Of Origin (Made in which country)", key: "countryManufactured20" } : { label: "*Country Of Origin (Made in which country)", key: "" },

        { label: "No. of Pieces (Boxes)", key: "amount" },
        { label: "No. of Shipments (Waybills)", key: "negative" },
    ]
    const eamHeader = [
        { label: "ACCOUNT", key: "account" },
        { label: "HAWB", key: "removeSkd" },
        { label: "SERVICE", key: "eamService" },
        { label: "MAWB", key: "mawb" },
        { label: "DATE", key: "date" },
        { label: "COMPANY", key: "company" },
        { label: "CONTACT", key: "contact" },
        { label: "ADDRESS1", key: "address" },
        { label: "ADDRESS2", key: "address2" },
        { label: "ADDRESS3", key: "address3" },
        { label: "TOWN", key: "town" },
        { label: "City", key: "city" },
        { label: "COUNTRY", key: "code" },
        { label: "POSTCODE", key: "postal" },
        { label: "TELNO", key: "phone" },
        { label: "NO. OF PCS", key: "amount" },
        { label: "WEIGHT", key: "chargeable" },
        { label: "DOX/NDX", key: "eamFormat" },
        { label: "DESCRIP", key: "description" },
        { label: "VALUE", key: "value" },
        { label: "CURRENCY", key: "currency" },
        { label: "NOTES / PLS USE DHL", key: "note" },
        { label: "email", key: "fromEmail" },
        { label: "shipper_company", key: "sender" },
        { label: "shipper_contact_person", key: "fromName" },
        { label: "shipper_address_line1", key: "shortAddress" },
        { label: "shipper_address_line2", key: "fromAddress2" },
        { label: "shipper_city", key: "fromCity" },
        { label: "shipper_postcode", key: "fromPostal" },
        { label: "shipper_country", key: "fromCode" },
        { label: "shipper_telephone", key: "fromPhone" },
        { label: "dhl_awb", key: "sin" },
        { label: "other_charges_1", key: "transport" },
        { label: "export_reason_type", key: "" },
        { label: "export_reason", key: "reason" },
        { label: "tax_vat_id", key: "taxIT" },
        { label: "", key: "slash" },
        { label: "item number", key: "item_number" },
        { label: "qty", key: "quantity" },
        { label: "Qty unit", key: "unit" },
        { label: "Item description", key: "itemName" },
        { label: "Unit Price", key: "unitPrice" },
        { label: "net weight", key: "itemGrossWeight" },
        { label: "gross weight", key: "itemGrossWeight" },
        { label: "country manufactured", key: "countryManufactured" },
        { label: "hs code", key: "hsCode" },
        highestItem >= 2 ? { label: "", key: "slash2" } : { label: "", key: "" },
        { label: "item number", key: "item_number2" },
        { label: "qty", key: "quantity2" },
        { label: "Qty unit", key: "unit2" },
        { label: "Item description", key: "itemName2" },
        { label: "Unit Price", key: "unitPrice2" },
        { label: "net weight", key: "itemGrossWeight2" },
        { label: "gross weight", key: "itemGrossWeight2" },
        { label: "country manufactured", key: "countryManufactured2" },
        { label: "hs code", key: "hsCode2" },
        highestItem >= 3 ? { label: "", key: "slash3" } : { label: "", key: "" },
        { label: "item number", key: "item_number3" },
        { label: "qty", key: "quantity3" },
        { label: "Qty unit", key: "unit3" },
        { label: "Item description", key: "itemName3" },
        { label: "Unit Price", key: "unitPrice3" },
        { label: "net weight", key: "itemGrossWeight3" },
        { label: "gross weight", key: "itemGrossWeight3" },
        { label: "country manufactured", key: "countryManufactured3" },
        { label: "hs code", key: "hsCode3" },
        highestItem >= 4 ? { label: "", key: "slash4" } : { label: "", key: "" },
        { label: "item number", key: "item_number4" },
        { label: "qty", key: "quantity4" },
        { label: "Qty unit", key: "unit4" },
        { label: "Item description", key: "itemName4" },
        { label: "Unit Price", key: "unitPrice4" },
        { label: "net weight", key: "itemGrossWeight4" },
        { label: "gross weight", key: "itemGrossWeight4" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode4" },
        highestItem >= 5 ? { label: "", key: "slash5" } : { label: "", key: "" },
        { label: "item number", key: "item_number5" },
        { label: "qty", key: "quantity5" },
        { label: "Qty unit", key: "unit5" },
        { label: "Item description", key: "itemName5" },
        { label: "Unit Price", key: "unitPrice5" },
        { label: "net weight", key: "itemGrossWeight5" },
        { label: "gross weight", key: "itemGrossWeight5" },
        { label: "country manufactured", key: "countryManufactured5" },
        { label: "hs code", key: "hsCode5" },
        highestItem >= 6 ? { label: "", key: "slash6" } : { label: "", key: "" },
        { label: "item number", key: "item_number6" },
        { label: "qty", key: "quantity6" },
        { label: "Qty unit", key: "unit6" },
        { label: "Item description", key: "itemName6" },
        { label: "Unit Price", key: "unitPrice6" },
        { label: "net weight", key: "itemGrossWeight6" },
        { label: "gross weight", key: "itemGrossWeight6" },
        { label: "country manufactured", key: "countryManufactured6" },
        { label: "hs code", key: "hsCode6" },
        highestItem >= 7 ? { label: "", key: "slash7" } : { label: "", key: "" },
        { label: "item number", key: "item_number7" },
        { label: "qty", key: "quantity7" },
        { label: "Qty unit", key: "unit7" },
        { label: "Item description", key: "itemName7" },
        { label: "Unit Price", key: "unitPrice7" },
        { label: "net weight", key: "itemGrossWeight7" },
        { label: "gross weight", key: "itemGrossWeight7" },
        { label: "country manufactured", key: "countryManufactured7" },
        { label: "hs code", key: "hsCode7" },
        highestItem >= 8 ? { label: "", key: "slash8" } : { label: "", key: "" },
        { label: "item number", key: "item_number8" },
        { label: "qty", key: "quantity8" },
        { label: "Qty unit", key: "unit8" },
        { label: "Item description", key: "itemName8" },
        { label: "Unit Price", key: "unitPrice8" },
        { label: "net weight", key: "itemGrossWeight8" },
        { label: "gross weight", key: "itemGrossWeight8" },
        { label: "country manufactured", key: "countryManufactured8" },
        { label: "hs code", key: "hsCode8" },
        highestItem >= 9 ? { label: "", key: "slash9" } : { label: "", key: "" },
        { label: "item number", key: "item_number9" },
        { label: "qty", key: "quantity9" },
        { label: "Qty unit", key: "unit9" },
        { label: "Item description", key: "itemName9" },
        { label: "Unit Price", key: "unitPrice9" },
        { label: "net weight", key: "itemGrossWeight9" },
        { label: "gross weight", key: "itemGrossWeight9" },
        { label: "country manufactured", key: "countryManufactured9" },
        { label: "hs code", key: "hsCode9" },
        highestItem >= 10 ? { label: "", key: "slash10" } : { label: "", key: "" },
        { label: "item number", key: "item_number10" },
        { label: "qty", key: "quantity10" },
        { label: "Qty unit", key: "unit10" },
        { label: "Item description", key: "itemName10" },
        { label: "Unit Price", key: "unitPrice10" },
        { label: "net weight", key: "itemGrossWeight10" },
        { label: "gross weight", key: "itemGrossWeight10" },
        { label: "country manufactured", key: "countryManufactured10" },
        { label: "hs code", key: "hsCode10" },
        highestItem >= 11 ? { label: "", key: "slash11" } : { label: "", key: "" },
        { label: "item number", key: "item_number11" },
        { label: "qty", key: "quantity11" },
        { label: "Qty unit", key: "unit11" },
        { label: "Item description", key: "itemName11" },
        { label: "Unit Price", key: "unitPrice11" },
        { label: "net weight", key: "itemGrossWeight11" },
        { label: "gross weight", key: "itemGrossWeight11" },
        { label: "country manufactured", key: "countryManufactured11" },
        { label: "hs code", key: "hsCode11" },
        highestItem >= 12 ? { label: "", key: "slash12" } : { label: "", key: "" },
        { label: "item number", key: "item_number12" },
        { label: "qty", key: "quantity12" },
        { label: "Qty unit", key: "unit12" },
        { label: "Item description", key: "itemName12" },
        { label: "Unit Price", key: "unitPrice12" },
        { label: "net weight", key: "itemGrossWeight12" },
        { label: "gross weight", key: "itemGrossWeight12" },
        { label: "country manufactured", key: "countryManufactured12" },
        { label: "hs code", key: "hsCode12" },
        highestItem >= 13 ? { label: "", key: "slash13" } : { label: "", key: "" },
        { label: "item number", key: "item_number13" },
        { label: "qty", key: "quantity13" },
        { label: "Qty unit", key: "unit13" },
        { label: "Item description", key: "itemName13" },
        { label: "Unit Price", key: "unitPrice13" },
        { label: "net weight", key: "itemGrossWeight13" },
        { label: "gross weight", key: "itemGrossWeight13" },
        { label: "country manufactured", key: "countryManufactured13" },
        { label: "hs code", key: "hsCode13" },
        highestItem >= 14 ? { label: "", key: "slash14" } : { label: "", key: "" },
        { label: "item number", key: "item_number14" },
        { label: "qty", key: "quantity14" },
        { label: "Qty unit", key: "unit14" },
        { label: "Item description", key: "itemName14" },
        { label: "Unit Price", key: "unitPrice14" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured14" },
        { label: "hs code", key: "hsCode14" },
        highestItem >= 15 ? { label: "", key: "slash15" } : { label: "", key: "" },
        { label: "item number", key: "item_number15" },
        { label: "qty", key: "quantity15" },
        { label: "Qty unit", key: "unit15" },
        { label: "Item description", key: "itemName15" },
        { label: "Unit Price", key: "unitPrice15" },
        { label: "net weight", key: "itemGrossWeight15" },
        { label: "gross weight", key: "itemGrossWeight15" },
        { label: "country manufactured", key: "countryManufactured15" },
        { label: "hs code", key: "hsCode15" },
        highestItem >= 16 ? { label: "", key: "slash16" } : { label: "", key: "" },
        { label: "item number", key: "item_number16" },
        { label: "qty", key: "quantity16" },
        { label: "Qty unit", key: "unit16" },
        { label: "Item description", key: "itemName16" },
        { label: "Unit Price", key: "unitPrice16" },
        { label: "net weight", key: "itemGrossWeight16" },
        { label: "gross weight", key: "itemGrossWeight16" },
        { label: "country manufactured", key: "countryManufactured16" },
        { label: "hs code", key: "hsCode16" },
        highestItem >= 17 ? { label: "", key: "slash17" } : { label: "", key: "" },
        { label: "item number", key: "item_number17" },
        { label: "qty", key: "quantity17" },
        { label: "Qty unit", key: "unit17" },
        { label: "Item description", key: "itemName17" },
        { label: "Unit Price", key: "unitPrice17" },
        { label: "net weight", key: "itemGrossWeight17" },
        { label: "gross weight", key: "itemGrossWeight17" },
        { label: "country manufactured", key: "countryManufactured17" },
        { label: "hs code", key: "hsCode17" },
        highestItem >= 18 ? { label: "", key: "slash18" } : { label: "", key: "" },
        { label: "item number", key: "item_number18" },
        { label: "qty", key: "quantity18" },
        { label: "Qty unit", key: "unit18" },
        { label: "Item description", key: "itemName18" },
        { label: "Unit Price", key: "unitPrice18" },
        { label: "net weight", key: "itemGrossWeight18" },
        { label: "gross weight", key: "itemGrossWeight18" },
        { label: "country manufactured", key: "countryManufactured18" },
        { label: "hs code", key: "hsCode18" },
        highestItem >= 19 ? { label: "", key: "slash19" } : { label: "", key: "" },
        { label: "item number", key: "item_number19" },
        { label: "qty", key: "quantity19" },
        { label: "Qty unit", key: "unit19" },
        { label: "Item description", key: "itemName19" },
        { label: "Unit Price", key: "unitPrice19" },
        { label: "net weight", key: "itemGrossWeight19" },
        { label: "gross weight", key: "itemGrossWeight19" },
        { label: "country manufactured", key: "countryManufactured19" },
        { label: "hs code", key: "hsCode19" },
        highestItem >= 20 ? { label: "", key: "slash20" } : { label: "", key: "" },
        { label: "item number", key: "item_number20" },
        { label: "qty", key: "quantity20" },
        { label: "Qty unit", key: "unit20" },
        { label: "Item description", key: "itemName20" },
        { label: "Unit Price", key: "unitPrice20" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode20" },
        highestItem >= 21 ? { label: "", key: "slash21" } : { label: "", key: "" },
        { label: "item number", key: "item_number21" },
        { label: "qty", key: "quantity21" },
        { label: "Qty unit", key: "unit21" },
        { label: "Item description", key: "itemName21" },
        { label: "Unit Price", key: "unitPrice21" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode21" },
        highestItem >= 22 ? { label: "", key: "slash22" } : { label: "", key: "" },
        { label: "item number", key: "item_number22" },
        { label: "qty", key: "quantity22" },
        { label: "Qty unit", key: "unit22" },
        { label: "Item description", key: "itemName22" },
        { label: "Unit Price", key: "unitPrice22" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode22" },
        highestItem >= 23 ? { label: "", key: "slash23" } : { label: "", key: "" },
        { label: "item number", key: "item_number23" },
        { label: "qty", key: "quantity23" },
        { label: "Qty unit", key: "unit23" },
        { label: "Item description", key: "itemName23" },
        { label: "Unit Price", key: "unitPrice23" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode23" },
        highestItem >= 24 ? { label: "", key: "slash24" } : { label: "", key: "" },
        { label: "item number", key: "item_number24" },
        { label: "qty", key: "quantity24" },
        { label: "Qty unit", key: "unit24" },
        { label: "Item description", key: "itemName24" },
        { label: "Unit Price", key: "unitPrice24" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode24" },
        highestItem >= 25 ? { label: "", key: "slash25" } : { label: "", key: "" },
        { label: "item number", key: "item_number25" },
        { label: "qty", key: "quantity25" },
        { label: "Qty unit", key: "unit25" },
        { label: "Item description", key: "itemName25" },
        { label: "Unit Price", key: "unitPrice25" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode25" },
        highestItem >= 26 ? { label: "", key: "slash26" } : { label: "", key: "" },
        { label: "item number", key: "item_number26" },
        { label: "qty", key: "quantity26" },
        { label: "Qty unit", key: "unit26" },
        { label: "Item description", key: "itemName26" },
        { label: "Unit Price", key: "unitPrice26" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode26" },
        highestItem >= 27 ? { label: "", key: "slash27" } : { label: "", key: "" },
        { label: "item number", key: "item_number27" },
        { label: "qty", key: "quantity27" },
        { label: "Qty unit", key: "unit27" },
        { label: "Item description", key: "itemName27" },
        { label: "Unit Price", key: "unitPrice27" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode27" },
        highestItem >= 28 ? { label: "", key: "slash28" } : { label: "", key: "" },
        { label: "item number", key: "item_number28" },
        { label: "qty", key: "quantity28" },
        { label: "Qty unit", key: "unit28" },
        { label: "Item description", key: "itemName28" },
        { label: "Unit Price", key: "unitPrice28" },
        { label: "net weight", key: "itemGrossWeight8" },
        { label: "gross weight", key: "itemGrossWeight8" },
        { label: "country manufactured", key: "countryManufactured8" },
        { label: "hs code", key: "hsCode28" },
        highestItem >= 29 ? { label: "", key: "slash29" } : { label: "", key: "" },
        { label: "item number", key: "item_number29" },
        { label: "qty", key: "quantity29" },
        { label: "Qty unit", key: "unit29" },
        { label: "Item description", key: "itemName29" },
        { label: "Unit Price", key: "unitPrice29" },
        { label: "net weight", key: "itemGrossWeight20" },
        { label: "gross weight", key: "itemGrossWeight20" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode29" },
        highestItem >= 30 ? { label: "", key: "slash30" } : { label: "", key: "" },
        { label: "item number", key: "item_number30" },
        { label: "qty", key: "quantity30" },
        { label: "Qty unit", key: "unit30" },
        { label: "Item description", key: "itemName30" },
        { label: "Unit Price", key: "unitPrice30" },
        { label: "net weight", key: "itemGrossWeight8" },
        { label: "gross weight", key: "itemGrossWeight8" },
        { label: "country manufactured", key: "countryManufactured20" },
        { label: "hs code", key: "hsCode30" },
        highestItem >= 31 ? { label: "", key: "slash31" } : { label: "", key: "" },
        { label: "item number", key: "item_number31" },
        { label: "qty", key: "quantity31" },
        { label: "Qty unit", key: "unit31" },
        { label: "Item description", key: "itemName31" },
        { label: "Unit Price", key: "unitPrice31" },
        { label: "net weight", key: "itemGrossWeight3" },
        { label: "gross weight", key: "itemGrossWeight3" },
        { label: "country manufactured", key: "countryManufactured3" },
        { label: "hs code", key: "hsCode31" },
        highestItem >= 32 ? { label: "", key: "slash32" } : { label: "", key: "" },
        { label: "item number", key: "item_number32" },
        { label: "qty", key: "quantity32" },
        { label: "Qty unit", key: "unit32" },
        { label: "Item description", key: "itemName32" },
        { label: "Unit Price", key: "unitPrice32" },
        { label: "net weight", key: "itemGrossWeight2" },
        { label: "gross weight", key: "itemGrossWeight2" },
        { label: "country manufactured", key: "countryManufactured2" },
        { label: "hs code", key: "hsCode32" },
        highestItem >= 33 ? { label: "", key: "slash33" } : { label: "", key: "" },
        { label: "item number", key: "item_number33" },
        { label: "qty", key: "quantity33" },
        { label: "Qty unit", key: "unit33" },
        { label: "Item description", key: "itemName33" },
        { label: "Unit Price", key: "unitPrice33" },
        { label: "net weight", key: "itemGrossWeight8" },
        { label: "gross weight", key: "itemGrossWeight8" },
        { label: "country manufactured", key: "countryManufactured33" },
        { label: "hs code", key: "hsCode33" },
        highestItem >= 34 ? { label: "", key: "slash34" } : { label: "", key: "" },
        { label: "item number", key: "item_number34" },
        { label: "qty", key: "quantity34" },
        { label: "Qty unit", key: "unit34" },
        { label: "Item description", key: "itemName34" },
        { label: "Unit Price", key: "unitPrice34" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode34" },
        highestItem >= 35 ? { label: "", key: "slash35" } : { label: "", key: "" },
        { label: "item number", key: "item_number35" },
        { label: "qty", key: "quantity35" },
        { label: "Qty unit", key: "unit35" },
        { label: "Item description", key: "itemName35" },
        { label: "Unit Price", key: "unitPrice35" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode35" },
        highestItem >= 36 ? { label: "", key: "slash36" } : { label: "", key: "" },
        { label: "item number", key: "item_number36" },
        { label: "qty", key: "quantity36" },
        { label: "Qty unit", key: "unit36" },
        { label: "Item description", key: "itemName36" },
        { label: "Unit Price", key: "unitPrice36" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode36" },
        highestItem >= 37 ? { label: "", key: "slash37" } : { label: "", key: "" },
        { label: "item number", key: "item_number37" },
        { label: "qty", key: "quantity37" },
        { label: "Qty unit", key: "unit37" },
        { label: "Item description", key: "itemName37" },
        { label: "Unit Price", key: "unitPrice37" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode37" },
        highestItem >= 38 ? { label: "", key: "slash38" } : { label: "", key: "" },
        { label: "item number", key: "item_number38" },
        { label: "qty", key: "quantity38" },
        { label: "Qty unit", key: "unit38" },
        { label: "Item description", key: "itemName38" },
        { label: "Unit Price", key: "unitPrice38" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode38" },
        highestItem >= 39 ? { label: "", key: "slash39" } : { label: "", key: "" },
        { label: "item number", key: "item_number39" },
        { label: "qty", key: "quantity39" },
        { label: "Qty unit", key: "unit39" },
        { label: "Item description", key: "itemName39" },
        { label: "Unit Price", key: "unitPrice39" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode39" },
        highestItem >= 40 ? { label: "", key: "slash40" } : { label: "", key: "" },
        { label: "item number", key: "item_number40" },
        { label: "qty", key: "quantity40" },
        { label: "Qty unit", key: "unit40" },
        { label: "Item description", key: "itemName40" },
        { label: "Unit Price", key: "unitPrice40" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode40" },
        highestItem >= 41 ? { label: "", key: "slash41" } : { label: "", key: "" },
        { label: "item number", key: "item_number41" },
        { label: "qty", key: "quantity41" },
        { label: "Qty unit", key: "unit41" },
        { label: "Item description", key: "itemName41" },
        { label: "Unit Price", key: "unitPrice41" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode41" },
        highestItem >= 42 ? { label: "", key: "slash42" } : { label: "", key: "" },
        { label: "item number", key: "item_number42" },
        { label: "qty", key: "quantity42" },
        { label: "Qty unit", key: "unit42" },
        { label: "Item description", key: "itemName42" },
        { label: "Unit Price", key: "unitPrice42" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode42" },
        highestItem >= 43 ? { label: "", key: "slash43" } : { label: "", key: "" },
        { label: "item number", key: "item_number43" },
        { label: "qty", key: "quantity43" },
        { label: "Qty unit", key: "unit43" },
        { label: "Item description", key: "itemName43" },
        { label: "Unit Price", key: "unitPrice43" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode43" },
        highestItem >= 44 ? { label: "", key: "slash44" } : { label: "", key: "" },
        { label: "item number", key: "item_number44" },
        { label: "qty", key: "quantity44" },
        { label: "Qty unit", key: "unit44" },
        { label: "Item description", key: "itemName44" },
        { label: "Unit Price", key: "unitPrice44" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode44" },
        highestItem >= 45 ? { label: "", key: "slash45" } : { label: "", key: "" },
        { label: "item number", key: "item_number45" },
        { label: "qty", key: "quantity45" },
        { label: "Qty unit", key: "unit45" },
        { label: "Item description", key: "itemName45" },
        { label: "Unit Price", key: "unitPrice45" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode45" },
        highestItem >= 46 ? { label: "", key: "slash46" } : { label: "", key: "" },
        { label: "item number", key: "item_number46" },
        { label: "qty", key: "quantity46" },
        { label: "Qty unit", key: "unit46" },
        { label: "Item description", key: "itemName46" },
        { label: "Unit Price", key: "unitPrice46" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode46" },
        highestItem >= 47 ? { label: "", key: "slash47" } : { label: "", key: "" },
        { label: "item number", key: "item_number47" },
        { label: "qty", key: "quantity47" },
        { label: "Qty unit", key: "unit47" },
        { label: "Item description", key: "itemName47" },
        { label: "Unit Price", key: "unitPrice47" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode47" },
        highestItem >= 48 ? { label: "", key: "slash48" } : { label: "", key: "" },
        { label: "item number", key: "item_number48" },
        { label: "qty", key: "quantity48" },
        { label: "Qty unit", key: "unit48" },
        { label: "Item description", key: "itemName48" },
        { label: "Unit Price", key: "unitPrice48" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode48" },
        highestItem >= 49 ? { label: "", key: "slash49" } : { label: "", key: "" },
        { label: "item number", key: "item_number49" },
        { label: "qty", key: "quantity49" },
        { label: "Qty unit", key: "unit49" },
        { label: "Item description", key: "itemName49" },
        { label: "Unit Price", key: "unitPrice49" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode49" },
        highestItem >= 50 ? { label: "", key: "slash50" } : { label: "", key: "" },
        { label: "item number", key: "item_number50" },
        { label: "qty", key: "quantity50" },
        { label: "Qty unit", key: "unit50" },
        { label: "Item description", key: "itemName50" },
        { label: "Unit Price", key: "unitPrice50" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode50" },
        highestItem >= 51 ? { label: "", key: "slash51" } : { label: "", key: "" },
        { label: "item number", key: "item_number51" },
        { label: "qty", key: "quantity51" },
        { label: "Qty unit", key: "unit51" },
        { label: "Item description", key: "itemName51" },
        { label: "Unit Price", key: "unitPrice51" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode51" },
        highestItem >= 52 ? { label: "", key: "slash52" } : { label: "", key: "" },
        { label: "item number", key: "item_number52" },
        { label: "qty", key: "quantity52" },
        { label: "Qty unit", key: "unit52" },
        { label: "Item description", key: "itemName52" },
        { label: "Unit Price", key: "unitPrice52" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode52" },
        highestItem >= 53 ? { label: "", key: "slash53" } : { label: "", key: "" },
        { label: "item number", key: "item_number53" },
        { label: "qty", key: "quantity53" },
        { label: "Qty unit", key: "unit53" },
        { label: "Item description", key: "itemName53" },
        { label: "Unit Price", key: "unitPrice53" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode53" },
        highestItem >= 54 ? { label: "", key: "slash54" } : { label: "", key: "" },
        { label: "item number", key: "item_number54" },
        { label: "qty", key: "quantity54" },
        { label: "Qty unit", key: "unit54" },
        { label: "Item description", key: "itemName54" },
        { label: "Unit Price", key: "unitPrice54" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode54" },
        highestItem >= 55 ? { label: "", key: "slash55" } : { label: "", key: "" },
        { label: "item number", key: "item_number55" },
        { label: "qty", key: "quantity55" },
        { label: "Qty unit", key: "unit55" },
        { label: "Item description", key: "itemName55" },
        { label: "Unit Price", key: "unitPrice55" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode55" },
        highestItem >= 56 ? { label: "", key: "slash56" } : { label: "", key: "" },
        { label: "item number", key: "item_number56" },
        { label: "qty", key: "quantity56" },
        { label: "Qty unit", key: "unit56" },
        { label: "Item description", key: "itemName56" },
        { label: "Unit Price", key: "unitPrice56" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode56" },
        highestItem >= 57 ? { label: "", key: "slash57" } : { label: "", key: "" },
        { label: "item number", key: "item_number57" },
        { label: "qty", key: "quantity57" },
        { label: "Qty unit", key: "unit57" },
        { label: "Item description", key: "itemName57" },
        { label: "Unit Price", key: "unitPrice57" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode57" },
        highestItem >= 58 ? { label: "", key: "slash58" } : { label: "", key: "" },
        { label: "item number", key: "item_number58" },
        { label: "qty", key: "quantity58" },
        { label: "Qty unit", key: "unit58" },
        { label: "Item description", key: "itemName58" },
        { label: "Unit Price", key: "unitPrice58" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode58" },
        highestItem >= 59 ? { label: "", key: "slash59" } : { label: "", key: "" },
        { label: "item number", key: "item_number59" },
        { label: "qty", key: "quantity59" },
        { label: "Qty unit", key: "unit59" },
        { label: "Item description", key: "itemName59" },
        { label: "Unit Price", key: "unitPrice59" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode59" },
        highestItem >= 60 ? { label: "", key: "slash60" } : { label: "", key: "" },
        { label: "item number", key: "item_number60" },
        { label: "qty", key: "quantity60" },
        { label: "Qty unit", key: "unit60" },
        { label: "Item description", key: "itemName60" },
        { label: "Unit Price", key: "unitPrice60" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode60" },
        highestItem >= 61 ? { label: "", key: "slash61" } : { label: "", key: "" },
        { label: "item number", key: "item_number61" },
        { label: "qty", key: "quantity61" },
        { label: "Qty unit", key: "unit61" },
        { label: "Item description", key: "itemName61" },
        { label: "Unit Price", key: "unitPrice61" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode61" },
        highestItem >= 62 ? { label: "", key: "slash62" } : { label: "", key: "" },
        { label: "item number", key: "item_number62" },
        { label: "qty", key: "quantity62" },
        { label: "Qty unit", key: "unit62" },
        { label: "Item description", key: "itemName62" },
        { label: "Unit Price", key: "unitPrice62" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode62" },
        highestItem >= 63 ? { label: "", key: "slash63" } : { label: "", key: "" },
        { label: "item number", key: "item_number63" },
        { label: "qty", key: "quantity63" },
        { label: "Qty unit", key: "unit63" },
        { label: "Item description", key: "itemName63" },
        { label: "Unit Price", key: "unitPrice63" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode63" },
        highestItem >= 64 ? { label: "", key: "slash64" } : { label: "", key: "" },
        { label: "item number", key: "item_number64" },
        { label: "qty", key: "quantity64" },
        { label: "Qty unit", key: "unit64" },
        { label: "Item description", key: "itemName64" },
        { label: "Unit Price", key: "unitPrice64" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode64" },
        highestItem >= 65 ? { label: "", key: "slash65" } : { label: "", key: "" },
        { label: "item number", key: "item_number65" },
        { label: "qty", key: "quantity65" },
        { label: "Qty unit", key: "unit65" },
        { label: "Item description", key: "itemName65" },
        { label: "Unit Price", key: "unitPrice65" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode65" },
        highestItem >= 66 ? { label: "", key: "slash66" } : { label: "", key: "" },
        { label: "item number", key: "item_number66" },
        { label: "qty", key: "quantity66" },
        { label: "Qty unit", key: "unit66" },
        { label: "Item description", key: "itemName66" },
        { label: "Unit Price", key: "unitPrice66" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode66" },
        highestItem >= 67 ? { label: "", key: "slash67" } : { label: "", key: "" },
        { label: "item number", key: "item_number67" },
        { label: "qty", key: "quantity67" },
        { label: "Qty unit", key: "unit67" },
        { label: "Item description", key: "itemName67" },
        { label: "Unit Price", key: "unitPrice67" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode67" },
        highestItem >= 68 ? { label: "", key: "slash68" } : { label: "", key: "" },
        { label: "item number", key: "item_number68" },
        { label: "qty", key: "quantity68" },
        { label: "Qty unit", key: "unit68" },
        { label: "Item description", key: "itemName68" },
        { label: "Unit Price", key: "unitPrice68" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode68" },
        highestItem >= 69 ? { label: "", key: "slash69" } : { label: "", key: "" },
        { label: "item number", key: "item_number69" },
        { label: "qty", key: "quantity69" },
        { label: "Qty unit", key: "unit69" },
        { label: "Item description", key: "itemName69" },
        { label: "Unit Price", key: "unitPrice69" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode69" },
        highestItem >= 70 ? { label: "", key: "slash70" } : { label: "", key: "" },
        { label: "item number", key: "item_number70" },
        { label: "qty", key: "quantity70" },
        { label: "Qty unit", key: "unit70" },
        { label: "Item description", key: "itemName70" },
        { label: "Unit Price", key: "unitPrice70" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode70" },
        highestItem >= 71 ? { label: "", key: "slash71" } : { label: "", key: "" },
        { label: "item number", key: "item_number71" },
        { label: "qty", key: "quantity71" },
        { label: "Qty unit", key: "unit71" },
        { label: "Item description", key: "itemName71" },
        { label: "Unit Price", key: "unitPrice71" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode71" },
        highestItem >= 72 ? { label: "", key: "slash72" } : { label: "", key: "" },
        { label: "item number", key: "item_number72" },
        { label: "qty", key: "quantity72" },
        { label: "Qty unit", key: "unit72" },
        { label: "Item description", key: "itemName72" },
        { label: "Unit Price", key: "unitPrice72" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode72" },
        highestItem >= 73 ? { label: "", key: "slash73" } : { label: "", key: "" },
        { label: "item number", key: "item_number73" },
        { label: "qty", key: "quantity73" },
        { label: "Qty unit", key: "unit73" },
        { label: "Item description", key: "itemName73" },
        { label: "Unit Price", key: "unitPrice73" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode73" },
        highestItem >= 74 ? { label: "", key: "slash74" } : { label: "", key: "" },
        { label: "item number", key: "item_number74" },
        { label: "qty", key: "quantity74" },
        { label: "Qty unit", key: "unit74" },
        { label: "Item description", key: "itemName74" },
        { label: "Unit Price", key: "unitPrice74" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode74" },
        highestItem >= 75 ? { label: "", key: "slash75" } : { label: "", key: "" },
        { label: "item number", key: "item_number75" },
        { label: "qty", key: "quantity75" },
        { label: "Qty unit", key: "unit75" },
        { label: "Item description", key: "itemName75" },
        { label: "Unit Price", key: "unitPrice75" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode75" },
        highestItem >= 76 ? { label: "", key: "slash76" } : { label: "", key: "" },
        { label: "item number", key: "item_number76" },
        { label: "qty", key: "quantity76" },
        { label: "Qty unit", key: "unit76" },
        { label: "Item description", key: "itemName76" },
        { label: "Unit Price", key: "unitPrice76" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode76" },
        highestItem >= 77 ? { label: "", key: "slash77" } : { label: "", key: "" },
        { label: "item number", key: "item_number77" },
        { label: "qty", key: "quantity77" },
        { label: "Qty unit", key: "unit77" },
        { label: "Item description", key: "itemName77" },
        { label: "Unit Price", key: "unitPrice77" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode77" },
        highestItem >= 78 ? { label: "", key: "slash78" } : { label: "", key: "" },
        { label: "item number", key: "item_number78" },
        { label: "qty", key: "quantity78" },
        { label: "Qty unit", key: "unit78" },
        { label: "Item description", key: "itemName78" },
        { label: "Unit Price", key: "unitPrice78" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode78" },
        highestItem >= 79 ? { label: "", key: "slash79" } : { label: "", key: "" },
        { label: "item number", key: "item_number79" },
        { label: "qty", key: "quantity79" },
        { label: "Qty unit", key: "unit79" },
        { label: "Item description", key: "itemName79" },
        { label: "Unit Price", key: "unitPrice79" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode79" },
        highestItem >= 80 ? { label: "", key: "slash80" } : { label: "", key: "" },
        { label: "item number", key: "item_number80" },
        { label: "qty", key: "quantity80" },
        { label: "Qty unit", key: "unit80" },
        { label: "Item description", key: "itemName80" },
        { label: "Unit Price", key: "unitPrice80" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode80" },
        highestItem >= 81 ? { label: "", key: "slash81" } : { label: "", key: "" },
        { label: "item number", key: "item_number81" },
        { label: "qty", key: "quantity81" },
        { label: "Qty unit", key: "unit81" },
        { label: "Item description", key: "itemName81" },
        { label: "Unit Price", key: "unitPrice81" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode81" },
        highestItem >= 82 ? { label: "", key: "slash82" } : { label: "", key: "" },
        { label: "item number", key: "item_number82" },
        { label: "qty", key: "quantity82" },
        { label: "Qty unit", key: "unit82" },
        { label: "Item description", key: "itemName82" },
        { label: "Unit Price", key: "unitPrice82" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode82" },
        highestItem >= 83 ? { label: "", key: "slash83" } : { label: "", key: "" },
        { label: "item number", key: "item_number83" },
        { label: "qty", key: "quantity83" },
        { label: "Qty unit", key: "unit83" },
        { label: "Item description", key: "itemName83" },
        { label: "Unit Price", key: "unitPrice83" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode83" },
        highestItem >= 84 ? { label: "", key: "slash84" } : { label: "", key: "" },
        { label: "item number", key: "item_number84" },
        { label: "qty", key: "quantity84" },
        { label: "Qty unit", key: "unit84" },
        { label: "Item description", key: "itemName84" },
        { label: "Unit Price", key: "unitPrice84" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode84" },
        highestItem >= 85 ? { label: "", key: "slash85" } : { label: "", key: "" },
        { label: "item number", key: "item_number85" },
        { label: "qty", key: "quantity85" },
        { label: "Qty unit", key: "unit85" },
        { label: "Item description", key: "itemName85" },
        { label: "Unit Price", key: "unitPrice85" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode85" },
        highestItem >= 86 ? { label: "", key: "slash86" } : { label: "", key: "" },
        { label: "item number", key: "item_number86" },
        { label: "qty", key: "quantity86" },
        { label: "Qty unit", key: "unit86" },
        { label: "Item description", key: "itemName86" },
        { label: "Unit Price", key: "unitPrice86" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode86" },
        highestItem >= 87 ? { label: "", key: "slash87" } : { label: "", key: "" },
        { label: "item number", key: "item_number87" },
        { label: "qty", key: "quantity87" },
        { label: "Qty unit", key: "unit87" },
        { label: "Item description", key: "itemName87" },
        { label: "Unit Price", key: "unitPrice87" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode87" },
        highestItem >= 88 ? { label: "", key: "slash88" } : { label: "", key: "" },
        { label: "item number", key: "item_number88" },
        { label: "qty", key: "quantity88" },
        { label: "Qty unit", key: "unit88" },
        { label: "Item description", key: "itemName88" },
        { label: "Unit Price", key: "unitPrice88" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode88" },
        highestItem >= 89 ? { label: "", key: "slash89" } : { label: "", key: "" },
        { label: "item number", key: "item_number89" },
        { label: "qty", key: "quantity89" },
        { label: "Qty unit", key: "unit89" },
        { label: "Item description", key: "itemName89" },
        { label: "Unit Price", key: "unitPrice89" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode89" },
        highestItem >= 90 ? { label: "", key: "slash90" } : { label: "", key: "" },
        { label: "item number", key: "item_number90" },
        { label: "qty", key: "quantity90" },
        { label: "Qty unit", key: "unit90" },
        { label: "Item description", key: "itemName90" },
        { label: "Unit Price", key: "unitPrice90" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode90" },
        highestItem >= 91 ? { label: "", key: "slash91" } : { label: "", key: "" },
        { label: "item number", key: "item_number91" },
        { label: "qty", key: "quantity91" },
        { label: "Qty unit", key: "unit91" },
        { label: "Item description", key: "itemName91" },
        { label: "Unit Price", key: "unitPrice91" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode91" },
        highestItem >= 92 ? { label: "", key: "slash92" } : { label: "", key: "" },
        { label: "item number", key: "item_number92" },
        { label: "qty", key: "quantity92" },
        { label: "Qty unit", key: "unit92" },
        { label: "Item description", key: "itemName92" },
        { label: "Unit Price", key: "unitPrice92" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode92" },
        highestItem >= 93 ? { label: "", key: "slash93" } : { label: "", key: "" },
        { label: "item number", key: "item_number93" },
        { label: "qty", key: "quantity93" },
        { label: "Qty unit", key: "unit93" },
        { label: "Item description", key: "itemName93" },
        { label: "Unit Price", key: "unitPrice93" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode93" },
        highestItem >= 94 ? { label: "", key: "slash94" } : { label: "", key: "" },
        { label: "item number", key: "item_number94" },
        { label: "qty", key: "quantity94" },
        { label: "Qty unit", key: "unit94" },
        { label: "Item description", key: "itemName94" },
        { label: "Unit Price", key: "unitPrice94" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode94" },
        highestItem >= 95 ? { label: "", key: "slash95" } : { label: "", key: "" },
        { label: "item number", key: "item_number95" },
        { label: "qty", key: "quantity95" },
        { label: "Qty unit", key: "unit95" },
        { label: "Item description", key: "itemName95" },
        { label: "Unit Price", key: "unitPrice95" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode95" },
        highestItem >= 96 ? { label: "", key: "slash96" } : { label: "", key: "" },
        { label: "item number", key: "item_number96" },
        { label: "qty", key: "quantity96" },
        { label: "Qty unit", key: "unit96" },
        { label: "Item description", key: "itemName96" },
        { label: "Unit Price", key: "unitPrice96" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode96" },
        highestItem >= 97 ? { label: "", key: "slash97" } : { label: "", key: "" },
        { label: "item number", key: "item_number97" },
        { label: "qty", key: "quantity97" },
        { label: "Qty unit", key: "unit97" },
        { label: "Item description", key: "itemName97" },
        { label: "Unit Price", key: "unitPrice97" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode97" },
        highestItem >= 98 ? { label: "", key: "slash98" } : { label: "", key: "" },
        { label: "item number", key: "item_number98" },
        { label: "qty", key: "quantity98" },
        { label: "Qty unit", key: "unit98" },
        { label: "Item description", key: "itemName98" },
        { label: "Unit Price", key: "unitPrice98" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode98" },
        highestItem >= 99 ? { label: "", key: "slash99" } : { label: "", key: "" },
        { label: "item number", key: "item_number99" },
        { label: "qty", key: "quantity99" },
        { label: "Qty unit", key: "unit99" },
        { label: "Item description", key: "itemName99" },
        { label: "Unit Price", key: "unitPrice99" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode99" },
        highestItem >= 100 ? { label: "", key: "slash100" } : { label: "", key: "" },
        { label: "item number", key: "item_number100" },
        { label: "qty", key: "quantity100" },
        { label: "Qty unit", key: "unit100" },
        { label: "Item description", key: "itemName100" },
        { label: "Unit Price", key: "unitPrice100" },
        { label: "net weight", key: "itemGrossWeight14" },
        { label: "gross weight", key: "itemGrossWeight14" },
        { label: "country manufactured", key: "countryManufactured4" },
        { label: "hs code", key: "hsCode100" },
    ]
    const agentReport = {
        data: exportSelected,
        headers: eamHeader,
        filename: "Skydart Shipment Export (EAM)",
    }

    const cleReport = {
        data: exportSelected,
        headers: cleHeader,
        filename: "Skydart Shipment Export (CLE)",
    }

    const renderAction = () => {
        setDisplayAction(true);
    }
    const printLabel = useReactToPrint({
        content: () => labelRef.current,
    })
    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
    })
    const printShippingMark = useReactToPrint({
        content: () => shippingMarkRef.current,
    })
    const printAll = useReactToPrint({
        content: () => printAllRef.current,
    })
    const bookedShipment = () => {
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                booked: "true",
                bookedBy: userData.displayName,
            }).then(() => {
                setSelectedShipment(finalShipment.map(val => {
                    let value = "true";
                    if (val.hawb === s.hawb) {
                        val.booked = value;
                    }
                    return val;
                }))
                setAlertSuccess(true);
            })
        })
    }

    let textInput = useRef();
    let printRefContainer = useRef();
    const unbookedShipment = () => {
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                booked: "false",
            }).then(() => {
                setSelectedShipment(finalShipment.map(val => {
                    let value = "false";
                    if (val.hawb === s.hawb) {
                        val.booked = value;
                    }
                    return val;
                }))
                setAlertSuccess(true);
            })
        })
    }

    let addCarton = () => {
        let x = invoiceValue.length;
        setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: "", itemIngredients: "", itemUnit2: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }])
    }
    const pickUp = () => {
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                status: "Picked Up",
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    status: "Picked Up",
                    time: traceTime + "T" + dateTime + ":00",
                    location: "VIETNAM - VIETNAM - HCM",
                    stat: "Shipment picked up by Skydart",
                })),
                pickupBy: userData.displayName,
            })
            if (s.type === "WPX") {
                axios.post("https://test.skydart.com.vn/api/create/shipment", {
                    account: "786009",
                    currency: "USD",
                    service: s.service,
                    date: traceTime,
                    hawb: s.hawb,
                    mawb: "Pending",
                    makh: s.makh,
                    company: s.company,
                    contact: s.contact,
                    otherTerm: s.otherTerm,
                    type: s.type,
                    status: "Picked Up",
                    term: s.term,
                    direct: s.direct,
                    time: dateTime,
                    address: s.address,
                    address2: s.address2 || ".",
                    address3: s.address3,
                    city: s.city,
                    country: s.country,
                    code: s.code,
                    phone: s.phone,
                    stateCode: s.stateCode,
                    phone2: s.phone2 || ".",
                    postal: s.postal,
                    state: s.state,
                    plan: s.plan || "Pending",
                    value: s.value,
                    town: s.town,
                    chargeable: s.chargeable,
                    amount: s.amount,
                    totalItem: s.totalItem,
                    transport: s.transport,
                    description: s.description,
                    controller: s.controller || "Pending",
                    eamFormat: "NDX",
                    dateTime: s.dateTime,
                    airline: "Pending",
                    reweight: s.reweight,
                    etd: "Pending",
                    agent: "Pending",
                    subStatus: "Pending",
                    sin: "Null",
                    sender: s.sender,
                    note: "Null",
                    perform: "Null",
                    fromEmail: s.fromEmail,
                    fromCity: s.fromCity,
                    totalValue: "0.00",
                    rate: s.rate || "0.00",
                    comestic: "0.00",
                    addressCorrection: "0.00",
                    remote: "0.00",
                    surcharge: "0.00",
                    surcharge2: "0.00",
                    surcharge3: "0.00",
                    fsc: s.fsc || 0,
                    fscValue: s.fscValue,
                    vat: "0.00",
                    vatValue: "0.00",
                    paymentPickup: "CRE",
                    paymentAcc: "CRE",
                    paymentCompany: "CRE",
                    paymentStatus: "Un Paid",
                    pickupBy: userData.displayName,
                    collectBy: "Pending",
                    rateUpdate: s.date,
                    sellNote: "Pending",
                    com: "0.00",
                    sellNote: "Pending",
                    paymentSubStatus: "Rebook",
                    eamService: s.eamService,
                    usage: s.usage,
                    pack: "Pending",
                })
                setAlertSuccess(true);
            }
            if (s.type === "DOX") {
                axios.post(" https://test.skydart.com.vn/api/create/shipment", {
                    account: "786009",
                    currency: "USD",
                    service: s.service,
                    date: traceTime,
                    hawb: s.hawb,
                    mawb: "Pending",
                    makh: s.makh,
                    company: s.company,
                    contact: s.contact,
                    otherTerm: s.otherTerm,
                    type: s.type,
                    status: "Picked Up",
                    term: s.term,
                    time: s.time,
                    direct: s.direct,
                    address: s.address,
                    address2: s.address2 || ".",
                    address3: s.address3,
                    city: s.city,
                    country: s.country,
                    code: s.code,
                    phone: s.phone,
                    phone2: s.phone2 || ".",
                    postal: s.postal,
                    state: s.state,
                    value: s.value,
                    town: s.town,
                    plan: s.plan || "Pending",
                    chargeable: s.chargeable,
                    amount: s.amount,
                    totalItem: s.totalItem,
                    transport: s.trasnport,
                    description: "Document",
                    controller: s.controller || "Pending",
                    eamFormat: "DOX",
                    dateTime: s.dateTime,
                    airline: "Pending",
                    reweight: s.reweight,
                    etd: "Pending",
                    agent: "Pending",
                    subStatus: "Pending",
                    sin: "Null",
                    sender: s.sender,
                    note: "Null",
                    perform: "Null",
                    fromEmail: s.fromEmail,
                    fromCity: s.fromCity,
                    totalValue: "0.00",
                    rate: s.rate || "0.00",
                    comestic: "0.00",
                    addressCorrection: "0.00",
                    remote: "0.00",
                    surcharge: "0.00",
                    surcharge2: "0.00",
                    surcharge3: "0.00",
                    fsc: s.fsc || 0,
                    fscValue: s.fscValue,
                    vat: "0.00",
                    vatValue: "0.00",
                    paymentPickup: "CRE",
                    paymentAcc: "CRE",
                    paymentCompany: "CRE",
                    paymentStatus: "Un Paid",
                    pickupBy: userData.displayName,
                    collectBy: "Pending",
                    rateUpdate: s.date,
                    sellNote: "Pending",
                    com: "0.00",
                    sellNote: "Pending",
                    paymentSubStatus: "Rebook",
                    eamService: s.eamService,
                    usage: s.usage,
                    pack: "Pending",
                })
                setAlertSuccess(true);
            }
        })
    }

    const totalWeight = finalShipment.reduce((a, v) => a = +a + +v.chargeable, 0);
    const totalDus = dus.reduce((a, v) => a = +a + +v.chargeable, 0);
    // const totalDhl = dhl.reduce((a, v) => a = +a + +v.chargeable, 0);
    // const totalFedex = fedex.reduce((a, v) => a = +a + +v.chargeable, 0);
    // const totalUps = ups.reduce((a, v) => a = +a + +v.chargeable, 0);
    // const totalSkd = skd.reduce((a, v) => a = +a + +v.chargeable, 0);
    const totalPieces = finalShipment.reduce((a, v) => a = +a + +v.amount, 0);
    const totalValue = finalShipment.reduce((a, v) => a = +a + +v.value, 0);

    const publishLabel = () => {
        if (fileUrl === "") {
            setErr(true);
        } else {
            setErr(false);
            setLoading(true);
            db.collection("label").add({
                hawb: labels,
                receiver: receiver,
                url: fileUrl,
                amount: exportSelected.length,
                fileName: fileName,
                email: receiveEmail,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                date: traceTime,
            }).then(() => {
                bookedShipment();
                setLabels("");
                setReceiver("");
                setLabelURL("");
                setFileUrl("");
                setFileName("");
                setLoading(false);
                setAlertSuccess(true);
            })
        }
    }

    const handleHawb = (e) => {
        setSearchHawb(e.target.value);
        setCurrentPage(1);
    }
    const handleEditBooking = () => {
        setDisplayEditScreen(true);
        setSelection(exportSelected);
    }
    let removeFormField = (index, e) => {
        // let newFormValues = [...packageValue];
        // newFormValues.splice(i, 1);
        // setPackageValue(newFormValues);
        setPackageValue(packageValue.filter((v, i) => i !== index));
    }
    let addFormFields = () => {
        let x = packageValue.length;
        setPackageValue([...packageValue, { packNo: x + 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0.00, packageCharge: 0 }])
    };
    let removeInvoice = (index, e) => {
        setInvoiceValue(invoiceValue.filter((v, i) => i !== index));
    }
    // const pickShipment = () => {
    //     let commodity = [];
    //     let packing = [];
    //     let qty = [];
    //     let unit = [];
    //     let unitPrice = [];
    //     let subTotal = [];
    //     setLoadShipment(true);
    //     db.collection("shipments").doc(hawb).get().then((doc) => {
    //         if (doc.exists) {
    //             setMockInfo(doc.data());
    //             setSelection(exportSelected);
    //             if (doc.data().shipmentType === "WPX") {
    //                 setType(doc.data().shipmentType);
    //                 setCode(doc.data().code);
    //                 setCountry(doc.data().toCountry);
    //                 setImporter(doc.data().importer);
    //                 setAddress(doc.data().toAddress);
    //                 setAddress2(doc.data().toAddress2);
    //                 setAddress3(doc.data().toAddress3);
    //                 setTown(doc.data().toTown);
    //                 setCity(doc.data().toCity);
    //                 setState(doc.data().toState);
    //                 setPostal(doc.data().toPostal);
    //                 setToName(doc.data().toName);
    //                 setPhone(doc.data().toPhone);
    //                 setEmail(doc.data().toEmail);
    //                 setPackageValue(doc.data().packageValue);
    //                 setInvoiceValue(doc.data().invoiceValue);
    //                 setTerm(doc.data().term);
    //                 setTransport(doc.data().transportation);
    //                 setReason(doc.data().usage);
    //                 setService(doc.data().service);
    //                 setEori(doc.data().eori);
    //                 setExporter(doc.data().exporter);
    //                 setFromCountry(doc.data().fromCountry);
    //                 setFromAddress(doc.data().fromAddress);
    //                 setFromAddress2(doc.data().fromAddress2);
    //                 setFromAddress3(doc.data().fromAddress3);
    //                 setFromTown(doc.data().fromTown);
    //                 setFromCity(doc.data().fromCity);
    //                 setFromState(doc.data().fromState);
    //                 setFromPostal(doc.data().fromPostal);
    //                 setFromName(doc.data().fromName);
    //                 setFromPhone(doc.data().fromPhone);
    //                 setTerm(doc.data().term);
    //                 setFromEmail(doc.data().fromEmail);
    //                 setController(doc.data().controller);
    //                 setDescription(doc.data().description);
    //                 setPickupBy(doc.data().pickupBy);
    //                 setStat(doc.data().status);
    //                 setCreateAt(doc.data().createAt);
    //                 setPickup(doc.data().pickupBy);
    //                 setArrive(doc.data().receiveBy);
    //                 setProcess(doc.data().processBy);
    //                 setClearance(doc.data().clearanceBy);
    //                 setClear(doc.data().clearedBy);
    //                 setHold(doc.data().holdBy);
    //             } else if (doc.data().shipmentType === "DOX") {
    //                 setType(doc.data().shipmentType);
    //                 setCode(doc.data().code);
    //                 setCountry(doc.data().toCountry);
    //                 setImporter(doc.data().importer);
    //                 setAddress(doc.data().toAddress);
    //                 setAddress2(doc.data().toAddress2);
    //                 setAddress3(doc.data().toAddress3);
    //                 setTown(doc.data().toTown);
    //                 setCity(doc.data().toCity);
    //                 setState(doc.data().toState);
    //                 setPostal(doc.data().toPostal);
    //                 setToName(doc.data().toName);
    //                 setPhone(doc.data().toPhone);
    //                 setPickupBy(doc.data().pickupBy);
    //                 setEmail(doc.data().toEmail);
    //                 setTerm(doc.data().term);
    //                 setTransport(doc.data().transportation);
    //                 setEnvelope(doc.data().totalPackage);
    //                 setDoxWeight(doc.data().totalCharge);
    //                 setReason(doc.data().usage);
    //                 setService(doc.data().service);
    //                 setEori(doc.data().eori);
    //                 setExporter(doc.data().exporter);
    //                 setFromCountry(doc.data().fromCountry);
    //                 setFromAddress(doc.data().fromAddress);
    //                 setFromAddress2(doc.data().fromAddress2);
    //                 setFromAddress3(doc.data().fromAddress3);
    //                 setFromTown(doc.data().fromTown);
    //                 setFromCity(doc.data().fromCity);
    //                 setFromState(doc.data().fromState);
    //                 setFromPostal(doc.data().fromPostal);
    //                 setFromName(doc.data().fromName);
    //                 setFromPhone(doc.data().fromPhone);
    //                 setFromEmail(doc.data().fromEmail);
    //                 setController(doc.data().controller);
    //                 setStat(doc.data().status);
    //                 setCreateAt(doc.data().createAt);
    //                 setDescription("Document");
    //                 setPickup(doc.data().pickupBy);
    //                 setArrive(doc.data().receiveBy);
    //                 setProcess(doc.data().processBy);
    //                 setClearance(doc.data().clearanceBy);
    //                 setClear(doc.data().clearedBy);
    //                 setHold(doc.data().holdBy);
    //             }
    //             setLoadShipment(false);
    //         } else {
    //             alert("Current shipment doesn't exist, please try again");
    //             setDisplayEditScreen(false);
    //         }
    //     }).then(() => {
    //         invoiceValue.map((s) => {
    //             commodity.push(s.itemCommonity);
    //         })
    //     })
    // }
    // useEffect(() => {
    //     if (hawb !== "") {
    //         pickShipment();
    //     }
    // }, [hawb])

    const finalInvoiceValue = invoiceValue.reduce((a, v) => a = +a.toFixed(2) + +v.itemValue.toFixed(2), 0);
    const totalInvoiceValue = +finalInvoiceValue + +transport;
    let handleChange = (i, e) => {
        let newFormValues = [...packageValue];
        newFormValues[i][e.target.name] = e.target.value;
        setPackageValue(newFormValues);
    };
    let invoiceChange = (i, e) => {
        let newInvoiceValues = [...invoiceValue];
        newInvoiceValues[i][e.target.name] = e.target.value;
        setInvoiceValue(newInvoiceValues);
    }
    let shipmentChange = (i, e) => {
        let newFormValues = [...finalShipment];
        newFormValues[i][e.target.name] = e.target.value;
        setShipments(newFormValues);
    }
    const closeEdit = () => {
        setSelection([]);
        setHawb("");
        setDisplayEditScreen(false);
        setCurrentState(0);
    }
    const handleChangeService = (e) => {
        if (e.target.value === "DHL") {
            setEamService("INT");
        }
        if (e.target.value === "FIP") {
            setEamService("FIP")
        }
        setService(e.target.value);
    }
    const updateShipment = () => {
        setLoadShipment(true);
        if (status === "Pending") {
            createShipment();
        } else if (status !== "Pending") {
            createShipment();
            editShipment();
        }
    }
    const updateDox = () => {
        setLoadShipment(true);
        if (status === "Pending") {
            createDox();
        } else if (status !== "Pending") {
            createDox();
            editShipment();
        }
    }
    const editShipment = () => {
        axios.put("https://test.skydart.com.vn/api/update/shipment/service", {
            hawb: hawb,
            service: service,
            eamFormat: type === "DOX" ? "DOX" : "NDX",
            code: code,
            value: totalInvoiceValue,
            company: importer,
            type: type,
            country: country,
            fromCity: fromCountry,
            state: state,
            town: town,
            city: city,
            address: address,
            address2: address2,
            address3: address3,
            postal: postal,
            phone: phone,
            contact: toName,
            chargeable: type === "DOX" ? doxWeight : finalCharge,
            eamService: eamService,
        })
    }
    const createDox = () => {
        setLoadShipment(true);
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
            setLoadShipment(false);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
            setLoadShipment(false);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            db.collection("shipments").doc(hawb).update({
                shipmentType: type,
                service: service,
                controller: controller,
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                code: code,
                value: totalInvoiceValue,
                toPhone2: "",
                toCountry: country,
                fromCountry: fromCountry,
                fromCity: fromCity,
                importer: importer,
                toCity: city,
                toState: state,
                toTown: town,
                toAddress: address,
                toAddress2: address2,
                toAddress3: address3,
                toPostal: postal,
                toPhone: phone,
                toName,
                toEmail: email,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge,
                reweight: type === "DOX" ? doxWeight : finalCharge,
                weight: type === "DOX" ? doxWeight : finalGross,
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                description: "Document",
            }).then(() => {
                next();
            })
        }
    }
    const createShipment = () => {
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        setLoadShipment(true);
        setTimeout(() => {
            db.collection("shipments").doc(hawb).update({
                shipmentType: type,
                service: service,
                controller: controller,
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                code: code,
                value: totalInvoiceValue,
                toPhone2: "",
                fromCountry: fromCountry,
                fromCity: fromCity,
                toCountry: country,
                importer: importer,
                toCity: city,
                toState: state,
                toTown: town,
                toAddress: address,
                toAddress2: address2,
                toAddress3: address3,
                toPostal: postal,
                toPhone: phone,
                toName,
                toEmail: email,
                invoiceValue: invoiceValue,
                packageValue: packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge,
                reweight: type === "DOX" ? doxWeight : finalCharge,
                weight: type === "DOX" ? doxWeight : finalGross,
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                eori: eori,
                description: type === "DOX" ? "Document" : arr + ",",
                term: type === "DOX" ? "Document" : term,
                transportation: transport,
                totalItemValue: finalInvoiceValue.toFixed(2),
                totalInvoiceValue: totalInvoiceValue.toFixed(2),
                usage: reason,
            }).then(() => {
                next();
            })
        }, 1000)
    }
    const duplicateService = [...new Set(shipments.map(s => s.service))];
    const duplicatePcs = [...new Set(shipments.map(s => s.amount))];
    const duplicateWeight = [...new Set(shipments.map(s => s.chargeable))];
    const next = () => {
        setLoadShipment(false);
        if (currentState + 1 === selection.length) {
            closeEdit();
            refreshShipment();
        } else {
            setAlertUpdate(true);
            setCurrentState(currentState + 1);
            setSelectedShipment(selection.map(val => {
                let updated = "true";
                if (val.hawb === hawb) {
                    val.updated = updated;
                    setHawb(selection[currentState + 1].hawb);
                }
                return val;
            }))
        }
    }

    const convertThoudsand = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
    }
    const onFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(file.name);
            setFileName(file.name);
            await fileRef.put(file);
            setFileUrl(await fileRef.getDownloadURL());
        } else {
            return;
        }
    }

    // console.log(finalShipment);

    // const requestOptions = () => {
    //     let fromAddress = {
    //         name: "SKYDART",
    //         company: "",
    //         phone: "7546007133",
    //         street1: "4 Taft Road",
    //         street2: "",
    //         street3: "",
    //         city: "Totowa",
    //         state: "NJ",
    //         postal_code: "07512",
    //         country: "US",
    //         residential: false,
    //         tax_id: "",
    //     }
    //     let parcels = packageValue.map((s, index) => {
    //         return {
    //             number: index,
    //             code: null,
    //             unit: "metric",
    //             weight: s.packageWeight,
    //             length: s.packageLength,
    //             width: s.packageWidth,
    //             height: s.packageHeight,
    //             dg_code: "",
    //         }
    //     })
    //     let toAddress = {
    //         name: toName,
    //         company: importer,
    //         phone: phone,
    //         street1: address,
    //         street2: address2,
    //         street3: address3,
    //         city: city,
    //         state: state,
    //         postal_code: postal,
    //         country: "US",
    //         residential: true,
    //         tax_id: "",
    //     }
    //     let ref = {
    //         type: "customer_ref",
    //         value: value,
    //     }
    //     const newLabel = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Basic TkRrPS5ZVHdYYXdsRDJRNWlSYUJIQzlsTVVwRE0zVUhoeVc1bXIxQmVVeTM0ZFpzPTo="
    //         },
    //         body: JSON.stringify({
    //             "date": "2022-08-04",
    //             "service": "FX-HMD",
    //             "from": fromAddress,
    //             "to": toAddress,
    //             "type": "box",
    //             "parcels": parcels,
    //             "insurance": null,
    //             "signature": "none",
    //             "pickup": "dropoff",
    //             "references": [ref],
    //             "remarks": null,
    //             "domestic_options": {
    //                 "value": {
    //                     "currency": "USD",
    //                     "amount": 1,
    //                 },
    //                 "contents": "Books"
    //             },
    //             "international_options": null,
    //             "additional_options": null,
    //             "document_options": {
    //                 "return": true,
    //                 "label_format": "pdf",
    //                 "medium": "url"
    //             },
    //             "notifications": null
    //         })
    //     };
    //     return newLabel;
    // }
    // function fetchAPI(e) {
    //     e.preventDefault();
    //     setLoadShipment(true);
    //     fetch('https://api.ypn.io/v2/shipping/shipments', requestOptions()).then(function (response) {
    //         response.text().then(function (responseText) {
    //             db.collection("shipments").doc(hawb).update({
    //                 dus: JSON.parse(responseText).documents,
    //                 sin: JSON.parse(responseText).documents[0]?.tracking_number,
    //                 labelURL: JSON.parse(responseText).documents[0]?.url,
    //                 booked: "true",
    //                 bookedBy: userData.displayName,
    //             }).then(() => {
    //                 setSelectedShipment(finalShipment.map(val => {
    //                     let value = "true";
    //                     if (val.hawb === hawb) {
    //                         val.booked = value;
    //                         val.sin = JSON.parse(responseText).documents[0]?.tracking_number;
    //                         val.dus = JSON.parse(responseText).documents;
    //                         val.labelURL = JSON.parse(responseText).documents[0]?.url;
    //                     }
    //                     return val;
    //                 }))
    //                 JSON.parse(responseText).documents.forEach((s, index) => {
    //                     db.collection("label").add({
    //                         hawb: hawb,
    //                         receiver: exporter,
    //                         url: s.url || "",
    //                         amount: 1,
    //                         fileName: `DUS Label - ${hawb}`,
    //                         email: fromEmail,
    //                         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    //                         date: traceTime,
    //                     }).then(() => {
    //                         setLoadShipment(false);
    //                         setAlertSuccess(true);
    //                         refreshShipment();
    //                     })
    //                 })
    //             });
    //         }).catch((err) => {
    //             alert(err);
    //             db.collection("shipments").doc(hawb).update({
    //                 getLabel: "false",
    //                 reasonErr: "Error",
    //                 sin: "error",
    //             }).then(() => {
    //                 setSelectedShipment(finalShipment.map(val => {
    //                     if (val.hawb === hawb) {
    //                         val.sin = "error";
    //                         val.dus = [];
    //                     }
    //                     return val;
    //                 }))
    //                 setLoadShipment(false);
    //             })
    //         });
    //     })
    // }
    function toFixedIfNecessary(value, dp) {
        return +parseFloat(value).toFixed(dp);
    }

    function exportText() {
        let text = [];
        exportSelected.map((s, index) => {
            if (s.type === "WPX") {
                text.push(`
${index + 1}|SHIP_FROM|${s.cleName}|${s.sender}|${s.cleAddress}||${s.cleAddress2}|${s.cleAddress3}|${s.clePostal}|${s.cleCity}|||${s.cleCode}|${s.cleEmail}|O|65|${s.clePhone}||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}|${s.address3}|${s.postal.trim()}|${s.city}|${s.town}|${s.state}|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${s.cleDesc}|${s.hawb}|${s.value}|USD|||${s.dhlAccount}|${s.dhlAccount}|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||
${index + 1}|INVOICE_HEADER||${s.hawb}|${s.cleDate}|${s.makh}|${s.makh}||||||||||||||||
${index + 1}|INV_ITEM|${s.itemName}||${s.quantity}|${s.uom}|${toFixedIfNecessary(s.item_value, 4)}|USD|${s.itemNetWeight}|${s.itemGrossWeight}|${s.countryManufactured}
${s.invoiceValue.length >= 2 ? index + 1 + "|INV_ITEM|" + s.itemName2 + "||" + s.quantity2 + "|" + s.uom2 + "|" + toFixedIfNecessary(s?.item_value2, 4) + "|USD|" + s.itemNetWeight2 + "|" + s.itemGrossWeight2 + "|" + s.countryManufactured2 : ""}
${s.invoiceValue.length >= 3 ? index + 1 + "|INV_ITEM|" + s.itemName3 + "||" + s.quantity3 + "|" + s.uom3 + "|" + toFixedIfNecessary(s?.item_value3, 4) + "|USD|" + s.itemNetWeight3 + "|" + s.itemGrossWeight3 + "|" + s.countryManufactured3 : ""}
${s.invoiceValue.length >= 4 ? index + 1 + "|INV_ITEM|" + s.itemName4 + "||" + s.quantity4 + "|" + s.uom4 + "|" + toFixedIfNecessary(s?.item_value4, 4) + "|USD|" + s.itemNetWeight4 + "|" + s.itemGrossWeight4 + "|" + s.countryManufactured4 : ""}
${s.invoiceValue.length >= 5 ? index + 1 + "|INV_ITEM|" + s.itemName5 + "||" + s.quantity5 + "|" + s.uom5 + "|" + toFixedIfNecessary(s?.item_value5, 4) + "|USD|" + s.itemNetWeight5 + "|" + s.itemGrossWeight5 + "|" + s.countryManufactured5 : ""}
${s.invoiceValue.length >= 6 ? index + 1 + "|INV_ITEM|" + s.itemName6 + "||" + s.quantity6 + "|" + s.uom6 + "|" + toFixedIfNecessary(s?.item_value6, 4) + "|USD|" + s.itemNetWeight6 + "|" + s.itemGrossWeight6 + "|" + s.countryManufactured6 : ""}
${s.invoiceValue.length >= 7 ? index + 1 + "|INV_ITEM|" + s.itemName7 + "||" + s.quantity7 + "|" + s.uom7 + "|" + toFixedIfNecessary(s?.item_value7, 4) + "|USD|" + s.itemNetWeight7 + "|" + s.itemGrossWeight7 + "|" + s.countryManufactured7 : ""}
${s.invoiceValue.length >= 8 ? index + 1 + "|INV_ITEM|" + s.itemName8 + "||" + s.quantity8 + "|" + s.uom8 + "|" + toFixedIfNecessary(s?.item_value8, 4) + "|USD|" + s.itemNetWeight8 + "|" + s.itemGrossWeight8 + "|" + s.countryManufactured8 : ""}
${s.invoiceValue.length >= 9 ? index + 1 + "|INV_ITEM|" + s.itemName9 + "||" + s.quantity9 + "|" + s.uom9 + "|" + toFixedIfNecessary(s?.item_value9, 4) + "|USD|" + s.itemNetWeight9 + "|" + s.itemGrossWeight9 + "|" + s.countryManufactured9 : ""}
${s.invoiceValue.length >= 10 ? index + 1 + "|INV_ITEM|" + s.itemName10 + "||" + s.quantity10 + "|" + s.uom10 + "|" + toFixedIfNecessary(s?.item_value10, 4) + "|USD|" + s.itemNetWeight10 + "|" + s.itemGrossWeight10 + "|" + s.countryManufactured10 : ""}
${s.invoiceValue.length >= 11 ? index + 1 + "|INV_ITEM|" + s.itemName11 + "||" + s.quantity11 + "|" + s.uom11 + "|" + toFixedIfNecessary(s?.item_value11, 4) + "|USD|" + s.itemNetWeight11 + "|" + s.itemGrossWeight11 + "|" + s.countryManufactured11 : ""}
${s.invoiceValue.length >= 12 ? index + 1 + "|INV_ITEM|" + s.itemName12 + "||" + s.quantity12 + "|" + s.uom12 + "|" + toFixedIfNecessary(s?.item_value12, 4) + "|USD|" + s.itemNetWeight12 + "|" + s.itemGrossWeight12 + "|" + s.countryManufactured12 : ""}
${s.invoiceValue.length >= 13 ? index + 1 + "|INV_ITEM|" + s.itemName13 + "||" + s.quantity13 + "|" + s.uom13 + "|" + toFixedIfNecessary(s?.item_value13, 4) + "|USD|" + s.itemNetWeight13 + "|" + s.itemGrossWeight13 + "|" + s.countryManufactured13 : ""}
${s.invoiceValue.length >= 14 ? index + 1 + "|INV_ITEM|" + s.itemName14 + "||" + s.quantity14 + "|" + s.uom14 + "|" + toFixedIfNecessary(s?.item_value14, 4) + "|USD|" + s.itemNetWeight14 + "|" + s.itemGrossWeight14 + "|" + s.countryManufactured14 : ""}
${s.invoiceValue.length >= 15 ? index + 1 + "|INV_ITEM|" + s.itemName15 + "||" + s.quantity15 + "|" + s.uom15 + "|" + toFixedIfNecessary(s?.item_value15, 4) + "|USD|" + s.itemNetWeight15 + "|" + s.itemGrossWeight15 + "|" + s.countryManufactured15 : ""}
${s.invoiceValue.length >= 16 ? index + 1 + "|INV_ITEM|" + s.itemName16 + "||" + s.quantity16 + "|" + s.uom16 + "|" + toFixedIfNecessary(s?.item_value16, 4) + "|USD|" + s.itemNetWeight16 + "|" + s.itemGrossWeight16 + "|" + s.countryManufactured16 : ""}
${s.invoiceValue.length >= 17 ? index + 1 + "|INV_ITEM|" + s.itemName17 + "||" + s.quantity17 + "|" + s.uom17 + "|" + toFixedIfNecessary(s?.item_value17, 4) + "|USD|" + s.itemNetWeight17 + "|" + s.itemGrossWeight17 + "|" + s.countryManufactured17 : ""}
${s.invoiceValue.length >= 18 ? index + 1 + "|INV_ITEM|" + s.itemName18 + "||" + s.quantity18 + "|" + s.uom18 + "|" + toFixedIfNecessary(s?.item_value18, 4) + "|USD|" + s.itemNetWeight18 + "|" + s.itemGrossWeight18 + "|" + s.countryManufactured18 : ""}
${s.invoiceValue.length >= 19 ? index + 1 + "|INV_ITEM|" + s.itemName19 + "||" + s.quantity19 + "|" + s.uom19 + "|" + toFixedIfNecessary(s?.item_value19, 4) + "|USD|" + s.itemNetWeight19 + "|" + s.itemGrossWeight19 + "|" + s.countryManufactured19 : ""}
${s.invoiceValue.length == 20 ? index + 1 + "|INV_ITEM|" + s.itemName20 + "||" + s.quantity20 + "|" + s.uom20 + "|" + toFixedIfNecessary(s?.item_value20, 4) + "|USD|" + s.itemNetWeight20 + "|" + s.itemGrossWeight20 + "|" + s.countryManufactured20 : ""}
${s.transport !== 0 ? exportSelected.length + 1 + "|INV_ITEM|" + "FREIGHT COST" + "||" + "1" + "|" + "PCS" + "|" + s.transport + "|USD|" + "0.1" + "|" + "0.1" + "|" + "VN" : ""}
`);
            } else if (s.type === "DOX") {
                text.push(`
${index + 1}|SHIP_FROM|${s.cleName}|${s.sender}|${s.cleAddress}||${s.cleAddress2}|${s.cleAddress3}|${s.clePostal}|${s.cleCity}|||${s.cleCode}|${s.cleEmail}|O|65|${s.clePhone}||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}||${s.postal.trim()}|${s.city}|${s.town}|${s.state}|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${s.cleDesc}|${s.hawb}|${s.value}|USD|||${s.dhlAccount}|${s.dhlAccount}|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||`)
            }
        });
        const finalText = text.toString().replaceAll(",", "").trim();
        var blob = new Blob([finalText], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "skydart_shipment_upload_file");
    }
    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    return (
        <div className="skydart-nav-link">

            {/* {newYear && <div className="skd-new-year">
                <div className="skd-new-year-container">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <img src={nammoi} alt="" />
                        <img src={nghitet} alt="" />
                    </div>
                    <div className="skd-new-year-clear" style={{ display: "flex", marginLeft: 10 }}>
                        <IconButton onClick={() => setNewYear(false)} type="button" style={{ background: "white", marginBottom: 15, marginLeft: "auto" }}><Clear fontSize="large" /></IconButton>
                    </div>
                </div>
            </div>} */}
            <p>{detected}</p>
            {/* <button onClick={onBookShipment}>Book</button> */}
            {/* <button type="button" onClick={deleteBooking}>Delete</button> */}
            <div ref={displayInvoiceExport ? null : invoiceRef1}></div>
            {/* <button onClick={deleteBooking} type="button">Delete</button> */}
            <div ref={printAllRef}>
                <div className="skydart-booking-hawb1" ref={labelRef}>
                    <div className="printing3">
                        <StaffBill2
                            hawb={hawb}
                            exporter={exporter}
                            fromAddress={fromAddress}
                            fromAddress2={fromAddress2}
                            fromAddress3={fromAddress3}
                            fromState={fromState}
                            fromTown={fromTown}
                            fromCity={fromCity}
                            fromPostal={fromPostal}
                            fromCountry={fromCountry}
                            fromPhone={fromPhone}
                            fromName={fromName}
                            fromEmail={fromEmail}
                            importer={importer}
                            toAddress={address}
                            toAddress2={address2}
                            toAddress3={address3}
                            toState={state}
                            toTown={town}
                            toCity={city}
                            toPostal={postal}
                            toCountry={country}
                            toPhone={phone}
                            toName={toName}
                            toEmail={email}
                            amount={packageValue.length}
                            weight={type === "WPX" ? (Math.round(finalGross * 100) / 100).toFixed(2) : (Math.round(doxWeight * 100) / 100).toFixed(2)}
                            chargeable={type === "WPX" ? (Math.round(finalCharge * 100) / 100).toFixed(2) : (Math.round(doxWeight * 100) / 100).toFixed(2)}
                            type={type}
                            date={createAt}
                            exportSelected={selectPrint}
                            description={description}
                        />
                    </div>
                </div>
                <div className="skydart-booking-hawb1" ref={invoiceRef}>
                    {type === "WPX" && <Inv2
                        invoice={invoiceValue}
                        hawb={hawb}
                        datePicker={createAt}
                        fromCountry={fromCountry}
                        toCountry={country}
                        usage={reason}
                        exporter={exporter}
                        fromAddress={fromAddress}
                        fromAddress2={fromAddress2}
                        fromAddress3={fromAddress3}
                        fromTown={fromTown}
                        fromState={fromState}
                        fromCity={fromCity}
                        fromPostal={fromPostal}
                        fromName={fromName}
                        fromPhone={fromPhone}
                        fromEmail={fromEmail}
                        mst={mst}
                        importer={importer}
                        toAddress={address}
                        toAddress2={address2}
                        toAddress3={address3}
                        toState={state}
                        toPostal={postal}
                        toTown={town}
                        toCity={city}
                        toName={toName}
                        toPhone={phone}
                        toEmail={email}
                        eori={eori}
                        transportation={transport}
                        exportSelected={selectPrint}
                        totalGross={(Math.round(finalGross * 100) / 100).toFixed(2)}
                        totalPackage={packageValue.length}
                        totalItemValue={(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)}
                        totalInvoiceValue={(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)}
                    />}
                </div>
                {type === "WPX" && <div className="skydart-booking-hawb1" ref={shippingMarkRef}>
                    {packageValue.map((item, index) => (
                        <div className="printing4">
                            <ShippingMark no={index + 1} date={createAt} length={item.packageLength} width={item.packageWidth} height={item.packageHeight} total={packageValue.length} hawb={hawb} weight={item.packageWeight} chargeable={item.packageCharge} importer={importer} address={address} address2={address2} address3={address3} city={city} state={state} town={town} postal={postal} country={country} phone={phone} contact={toName} />
                        </div>
                    ))}
                </div>}
            </div>
            {/* <div className="update-alert" style={{left: "26%"}}>
                <div className="update-alert-container">
                    <h5 style={{ marginBottom: 12 }}><strong style={{ color: "darkblue" }}>SKYDART EXPRESS</strong> vừa cập nhật phiên bản mới nhằm tăng cường tính bảo mật cho thông tin của quý khách hàng và đảm bảo rằng, quý khách sẽ luôn có một môi trường tạo đơn trực tuyến lành mạnh và an toàn nhất. Chúng tôi rất vinh hạnh khi được góp công vào 1 phần nào đó trong thành công của quý khách hàng thông qua những tiện ích từ dịch vụ mà chúng tôi mang lại. Chúng tôi sẽ luôn luôn cải tiến để mang lại cho quý khách hàng những trải nghiệm thuận tiện và giá trị nhất.<br /> Chúc mừng năm mới 2023! Chúc quý khách sẽ luôn luôn phát triển bền vững và đột phá.</h5>
                    <a href={`https://skydartexpress.com/user/${makeid(15)}/skd-token/${makeid(13)}/accessorMWKSOQLAZ/wiikw9WkQ/skd_api2.0/XQKjoToken/true/YWqz_false/12983Idsoo/${userData.uid}`} target="_self" style={{ color: "blue" }}>Cập nhật ngay!</a>
                    <div className="update-alert-icon-mail"><BiMailSend size={32} /></div>
                </div>
            </div> */}
            {displayEditScreen && <form className="skd-edit-new-booking" onSubmit={(e) => {
                e.preventDefault();
                return;
            }}>
                <Snackbar open={alertUpdate} autoHideDuration={5000} onClose={resetUpdate}>
                    <MuiAlert elevation={5} variant="filled" onClose={resetUpdate} severity="success">
                        Shipment has been updated successfully
                    </MuiAlert>
                </Snackbar>
                <div className="skd-edit-new-booking-container">
                    <div className="skd-edit-new-booking-header" style={{ zIndex: 2 }}>
                        <h5>Edit shipment</h5>
                        <div style={{ position: "relative" }}>
                            {loadShipment && <div className="skd-edit-new-booking-loading">
                                <CircularProgress size={25} style={{ padding: 5, color: "#0178da" }} />
                            </div>}
                        </div>
                        <Clear className="skd-edit-new-booking-header-icon" onClick={closeEdit} />
                    </div>
                    <div className="skd-edit-new-booking-body">
                        <div className="skd-edit-new-booking-left">
                            <h5 style={{ marginTop: 5, fontSize: 11, marginLeft: 0, marginBottom: 5, fontWeight: 600 }}>Selecting:</h5>
                            {selection.map((s, index) => (
                                <>
                                    {s.updated === "false" && <div className={hawb === s.hawb ? "skd-edit-new-booking-left-option" : "skd-edit-new-booking-left-option1"} onClick={hawb === s.hawb ? () => { return } : () => { setHawb(s.hawb); setCurrentState(index) }}>
                                        <h5>{s.hawb}</h5>
                                        {hawb === s.hawb && <FiEdit style={{ marginLeft: "auto" }} />}
                                    </div>}
                                    {s.updated === "true" && <div className="skd-edit-new-booking-left-option3" onClick={hawb === s.hawb ? () => { return } : () => { setHawb(s.hawb); setCurrentState(index) }}>
                                        <h5>{s.hawb}</h5>
                                        {hawb === s.hawb ? <FiEdit style={{ marginLeft: "auto" }} /> : <FcOk style={{ marginLeft: "auto" }} />}
                                    </div>}
                                </>
                            ))}
                            <div className="skd-edit-new-booking-left-option2">
                                <LocalShipping style={{ fontSize: 18 }} />
                                <h5>{pickup || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <CallReceived style={{ fontSize: 18 }} />
                                <h5>{arrive || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <IoBuildOutline style={{ fontSize: 18 }} />
                                <h5>{process || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <PanTool style={{ fontSize: 18 }} />
                                <h5>{hold || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <MdOutlineLayersClear style={{ fontSize: 18 }} />
                                <h5>{clearance || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <Package size={18} />
                                <h5>{clear || "Pending..."}</h5>
                            </div>


                        </div>
                        <div className="skd-edit-new-booking-center">
                            <div className="skd-edit-new-booking-center-container">
                                <div className="skd-edit-new-booking-center-type">
                                    <h5 style={{ fontWeight: 600 }}>Editing: <strong>{hawb}</strong></h5>
                                    <h5 style={{ fontWeigth: 600 }}>{new Date(createAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</h5>
                                    <select value={type} onChange={(e) => setType(e.target.value)}>
                                        <option value="WPX">WPX</option>
                                        <option value="DOX">DOX</option>
                                    </select>
                                </div>
                                <div className="skd-edit-new-booking-center-shipping-info">
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Country (Sender/Recipient)</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div ref={displayEditScreen ? fromCountryRef : null} style={{ position: "relative" }}>
                                            <div className="skd-edit-new-booking-country">
                                                <h5>Ship From</h5>
                                                <input type="text" value={fromCountry} onChange={(e) => setFromCountry(e.target.value)} onClick={() => setDisplayFromCountry(true)} />
                                            </div>
                                            {displayFromCountry && <div className="skd-edit-new-booking-country1">
                                                {countries.filter((s) => {
                                                    if (s.label.toLowerCase().startsWith(fromCountry.toLowerCase())) {
                                                        return s;
                                                    }
                                                }).map((s, index) => (
                                                    <div className="skd-edit-new-booking-country-box" onClick={() => {
                                                        setFromCountry(s.label);
                                                        setDisplayFromCountry(false);
                                                    }}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                        <h5>{s.label}</h5>
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>
                                        <div ref={displayEditScreen ? toCountryRef : null} style={{ position: "relative" }}>
                                            <div className="skd-edit-new-booking-country">
                                                <h5>Ship To</h5>
                                                <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} onClick={() => setDisplayToCountry(true)} />
                                            </div>
                                            {displayToCountry && <div className="skd-edit-new-booking-country1">
                                                {countries.filter((s) => {
                                                    if (s.label.toLowerCase().startsWith(country.toLowerCase())) {
                                                        return s;
                                                    }
                                                }).map((s, index) => (
                                                    <div className="skd-edit-new-booking-country-box" onClick={() => {
                                                        setCountry(s.label);
                                                        setCode(s.code);
                                                        setDisplayToCountry(false);
                                                    }}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                        <h5>{s.label}</h5>
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Recipient Information</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Company Name (Importer)</h5>
                                            <input type="text" required value={importer} onChange={(e) => setImporter(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address</h5>
                                            <input type="text" required value={address} onChange={(e) => setAddress(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 2</h5>
                                            <input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 3</h5>
                                            <input type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Town</h5>
                                            <input type="text" required value={town} onChange={(e) => setTown(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>City</h5>
                                            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>State</h5>
                                            {service !== "DUS" && <input type="text" value={state} onChange={(e) => setState(e.target.value)} maxLength={30} />}
                                            {service === "DUS" && <select required value={state} onChange={(e) => setState(e.target.value)}>
                                                <option value="">Select state</option>
                                                {usState.map((s) => (
                                                    <option value={s.code}>{s.label}, {s.code}</option>
                                                ))}
                                            </select>}
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Postal Code</h5>
                                            <input type="text" required value={postal} onChange={(e) => setPostal(e.target.value)} maxLength={30} />
                                        </div>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Recipient Contact</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Contact Name</h5>
                                            <input type="text" required value={toName} onChange={(e) => setToName(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Phone Number</h5>
                                            <input type="text" required value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Email Address</h5>
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>

                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Sender Information</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Company Name (Exporter)</h5>
                                            <input type="text" disabled value={exporter} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address</h5>
                                            <input type="text" disabled value={fromAddress} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 2</h5>
                                            <input type="text" disabled value={fromAddress2} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 3</h5>
                                            <input type="text" disabled value={fromAddress3} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Town</h5>
                                            <input type="text" disabled value={fromTown} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>City</h5>
                                            <input type="text" value={fromCity} onChange={(e) => setFromCity(e.target.value)} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>State</h5>
                                            <input type="text" disabled value={fromState} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Postal Code</h5>
                                            <input type="text" disabled value={fromPostal} />
                                        </div>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Sender Contact</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Contact Name</h5>
                                            <input type="text" disabled value={fromName} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Phone Number</h5>
                                            <input type="text" disabled value={fromPhone} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Email Address</h5>
                                            <input type="text" disabled value={fromEmail} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="skd-edit-new-booking-right">
                            <div className="skd-edit-new-booking-right-header">
                                <h5>Current Status: {stat}</h5>
                            </div>
                            <div className="skd-edit-new-booking-right-packing">
                                <div className="skd-edit-new-booking-pack-main">
                                    <h5>Packing Information</h5>
                                    <GrApps />
                                </div>
                                {type === "WPX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total packages:</h5>
                                        <h5>{packageValue.length} PCS</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total gross weight:</h5>
                                        <h5>{(Math.round(finalGross * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total chargeable</h5>
                                        <h5>{(Math.round(finalCharge * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                </div>}
                                {type === "DOX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total number of envelope:</h5>
                                        <h5>{envelope}</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total gross weight:</h5>
                                        <h5>{(Math.round(doxWeight * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total chargeable</h5>
                                        <h5>{(Math.round(doxWeight * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                </div>}
                                {type === "WPX" && <div className="skd-edit-new-booking-right-dim">
                                    <div className="skd-edit-new-booking-right-dim1" style={{ justifyContent: "space-between" }}>
                                        <div className="skd-new-booking-left-dim">
                                            <h5>Weight:</h5>
                                            {/* <input type="number" /> */}
                                        </div>
                                        <div className="skd-new-booking-center-dim">
                                            <h5 style={{ marginLeft: 15 }}>Dim:</h5>
                                            {/* <div>
                                                <input type="number" placeholder="L" />

                                                <input type="number" placeholder="W" />

                                                <input type="number" placeholder="H" />
                                            </div> */}
                                        </div>
                                        <div className="skd-new-booking-left-dim">
                                            <h5 style={{ marginLeft: 35 }}>Chargeable:</h5>
                                            {/* <input type="number" /> */}
                                        </div>
                                        <div className="skd-new-booking-empty"></div>
                                    </div>

                                    {packageValue.map((element, index) => {
                                        const dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 5000;
                                        const grossWeight = element.packageWeight;

                                        if (dimCharge < grossWeight) {
                                            element.packageCharge = grossWeight * 1;
                                            element.packageWeight = grossWeight * 1;
                                        } else if (grossWeight < dimCharge) {
                                            element.packageCharge = dimCharge;
                                            element.packageWeight = grossWeight * 1;

                                        }
                                        if (element.packageCharge >= 30) {
                                            element.packageCharge = Math.ceil(element.packageCharge);
                                        } else if (element.packageCharge < 30) {
                                            element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
                                        }
                                        if (element.packageWeight === "") {
                                            element.packageCharge = "0.00";
                                        }
                                        return <div className="skd-edit-new-booking-right-dim1">
                                            <div className="skd-new-booking-left-dim">
                                                <input type="number" value={element.packageWeight || ""} onChange={e => handleChange(index, e)} name="packageWeight" />
                                            </div>
                                            <div className="skd-new-booking-center-dim">
                                                <div>
                                                    <input type="number" placeholder="L" name="packageLength" value={element.packageLength || ""} onChange={e => handleChange(index, e)} />
                                                    <input type="number" placeholder="W" name="packageWidth" value={element.packageWidth || ""} onChange={e => handleChange(index, e)} />
                                                    <input type="number" placeholder="H" name="packageHeight" value={element.packageHeight || ""} onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-new-booking-left-dim">
                                                <input type="number" disabled value={(Math.round(element.packageCharge * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} name="packageCharge" />
                                            </div>
                                            <div className="skydart-pack-option">
                                                <Copy size={13} className="skydart-pack-copy" onClick={() => {
                                                    setPackageValue([...packageValue, { packageLength: element.packageLength, packageWidth: element.packageWidth, packageHeight: element.packageHeight, packageWeight: element.packageWeight, packageCharge: element.packageCharge }])
                                                }} />
                                                {packageValue.length !== 1 && <Minus size={13} className="skydart-pack-copy" onClick={e => removeFormField(index, e)} />}
                                            </div>
                                        </div>
                                    })}

                                    <div className="skydart-pack-add-btn">
                                        <button type="button" onClick={addFormFields}>
                                            <Add style={{ fontSize: 17 }} />
                                        </button>
                                    </div>
                                </div>}
                            </div>
                            <div className="skd-edit-new-booking-right-packing">
                                <div className="skd-edit-new-booking-pack-main">
                                    <h5>Description Of Goods</h5>
                                    <GrApps />
                                </div>
                                {type === "WPX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total items:</h5>
                                        <h5>{invoiceValue.length} items</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total item value:</h5>
                                        <h5>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total invoice value:</h5>
                                        <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                                    </div>
                                </div>}
                                {type === "DOX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5 style={{ color: "black" }}>There is no invoice required for Document (DOX)</h5>
                                    </div>
                                </div>}
                            </div>
                            {type === "WPX" && <div className="skd-edit-new-booking-right-invoice">
                                {invoiceValue.map((s, index) => {
                                    let donGia1 = s.itemUnitPrice;
                                    let soLuong1 = s.itemQuantity;
                                    let tong1 = donGia1 * soLuong1;
                                    s.itemValue = tong1;
                                    return <div style={{ marginBottom: 10 }}>
                                        <div className="skd-edit-new-booking-right-invoice-header">
                                            <div className="skd-edit-new-booking-right-invoice-header-left">
                                                <h5>Item {s.itemNo}</h5>
                                            </div>
                                            <div className="skydart-pack-option">
                                                <Copy size={13} className="skydart-pack-copy" onClick={() => {
                                                    let x = invoiceValue.length;
                                                    setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: s.itemBrand, itemIngredients: s.itemIngredients, itemUnit2: s.itemUnit2, itemPackType2: s.itemPackType2, itemCommonity: s.itemCommonity, itemUnit: s.itemUnit, itemUnitPirce: s.itemUnitPrice, itemQuantity: s.itemQuantity, itemValue: s.itemValue, itemManufact: s.itemManufact, itemPurpose: s.itemPurpose, itemHsCode: s.itemHsCode, itemPackType: s.itemPackType, itemManufactAddress: s.itemManufactAddress, itemCountryOrigin: s.itemCountryOrigin, itemFda: s.itemFda }])
                                                }} />
                                                {invoiceValue.length !== 1 && <Minus size={13} className="skydart-pack-copy" onClick={e => removeInvoice(index, e)} />}
                                            </div>
                                        </div>
                                        <div className="skd-edit-new-booking-right-invoice-body">
                                            <div className="skd-edit-new-booking-right-invoice-itemName">
                                                <input type="text" required placeholder="Declare item name here" name="itemCommonity" value={s.itemCommonity} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Brand Name</h5>
                                                    <input type="text" value={s.itemBrand} name="itemBrand" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Packing Type</h5>
                                                    <select value={s.itemPackType || ""} name="itemPackType" onChange={e => invoiceChange(index, e)}>
                                                        <option value="">Type</option>
                                                        <option value="Bag">Bag</option>
                                                        <option value="Box">Box</option>
                                                        <option value="Carton">Carton</option>
                                                        <option value="Wooden Pack">Wooden Pack</option>
                                                    </select>
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Ingredient</h5>
                                                    <input type="text" value={s.itemIngredients} name="itemIngredients" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Usage</h5>
                                                    <input type="text" value={s.itemPurpose} name="itemPurpose" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Origin Country</h5>
                                                    <input type="text" value={s.itemCountryOrigin} name="itemCountryOrigin" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Manufacturer Name</h5>
                                                    <input type="text" name="itemManufact" value={s.itemManufact} onChange={e => invoiceChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-itemName" style={{ marginTop: 5 }}>
                                                <h5 style={{ fontWeight: 600, fontSize: 12, color: "gray" }}>Manufacturer Address</h5>
                                                <textarea type="text" placeholder="Declare item name here" name="itemManufactAddress" value={s.itemManufactAddress} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Hs Code</h5>
                                                    <input type="text" name="itemHsCode" value={s.itemHsCode} onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>FDA</h5>
                                                    <input type="text" value={s.itemFda || ""} name="itemFda" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Quantity</h5>
                                                    <input type="number" value={s.itemQuantity || ""} name="itemQuantity" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Unit</h5>
                                                    {s.itemUnit !== "Other" && <select name="itemUnit" value={s.itemUnit} onChange={e => invoiceChange(index, e)}>
                                                        <option value="">Unit</option>
                                                        <option value="Pcs">Pcs</option>
                                                        <option value="Pair">Pair</option>
                                                        <option value="Kg">Kg</option>
                                                        <option value="Set">Set</option>
                                                        <option value="Bag">Bag</option>
                                                        <option value="Other">Other</option>
                                                    </select>}
                                                    {s.itemUnit === "Other" && <div style={{ display: "flex" }}>
                                                        <input type="text" value={s.itemUnit2 || ""} name="itemUnit2" onChange={e => invoiceChange(index, e)} />
                                                        <select value={s.itemUnit || ""} name="itemUnit" onChange={e => invoiceChange(index, e)} style={{ width: "18%" }}>
                                                            <option value="">Unit</option>
                                                            <option value="Pcs">Pcs</option>
                                                            <option value="Pair">Pair</option>
                                                            <option value="Kg">Kg</option>
                                                            <option value="Set">Set</option>
                                                            <option value="Bag">Bag</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>}
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Unit Price (USD)</h5>
                                                    <input type="number" required value={s.itemUnitPrice || ""} name="itemUnitPrice" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Total amount (USD)</h5>
                                                    <input type="text" style={{ background: "rgb(240,240,240)" }} disabled value={s.itemValue.toFixed(2)} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                })}
                                <div className="skydart-pack-add-btn">
                                    <button type="button" onClick={addCarton} style={{ marginRight: 10 }}>
                                        <Add style={{ fontSize: 17 }} />
                                    </button>
                                </div>
                            </div>}
                            <div className="skd-edit-new-booking-right-packing" style={{ paddingBottom: 30 }}>
                                <div className="skd-edit-new-booking-pack-main">
                                    <h5>Term Of Transportation</h5>
                                    <GrApps />
                                </div>
                                <div className="skd-edit-new-booking-pack-main1">
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>Transport Term</h5>
                                        <select value={term} onChange={(e) => setTerm(e.target.value)}>
                                            <option value="">Select your transport term</option>
                                            <option value="EXW">EX-WORD (EXW)</option>
                                            <option value="FOB">FREE ON BOARD (FOB)</option>
                                            <option value="CNF">COST & FREIGHT (CNF)</option>
                                            <option value="CIF">COST INSURANCE & FREIGHT (CIF)</option>
                                        </select>
                                    </div>}
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>Transport Value</h5>
                                        {term !== "FOB" && term !== "EXW" && <input type="number" style={{ padding: 7, width: "96%" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} />}
                                        {term === "FOB" && <input type="number" style={{ background: "rgb(230,230,230)", padding: 7, width: "96%" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} disabled />}
                                        {term === "EXW" && <input type="number" style={{ background: "rgb(230,230,230)", padding: 7, width: "96%" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} disabled />}
                                    </div>}
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>Export Reason</h5>
                                        <select value={reason} onChange={(e) => setReason(e.target.value)}>
                                            <option value="">Select your export reason</option>
                                            <option value="THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE">THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE</option>
                                            <option value="THE GOODS ARE E-COMMERCE SELLING">THE GOODS ARE E-COMMERCE SELLING</option>
                                            <option value="THE GOODS ARE RETURNED FOR MAINTENANCE">THE GOODS ARE RETURNED FOR MAINTENANCE</option>
                                            <option value="THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE">THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE</option>
                                            <option value="THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED">THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED</option>
                                        </select>
                                    </div>}
                                    <div className="skd-edit-new-booking-term">
                                        <h5>Service</h5>
                                        <select value={service} onChange={handleChangeService}>
                                            <option value="">Select a service</option>
                                            <option value="SKD">SKD</option>
                                            <option value="DHL">SDH</option>
                                            <option value="UPS">SUPS</option>
                                            <option value="FIP">SIP</option>
                                            <option value="DHL-VN">VDH</option>
                                            <option value="Fedex-VN">VIP</option>
                                            <option value="DAU">DAU</option>
                                            <option value="SEC">SEC</option>
                                            <option value="DUS">DUS</option>
                                            <option value="FUS">FUS</option>
                                            <option value="UUS">UUS</option>
                                        </select>
                                    </div>
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>IOSS / TIN / EORI Number / ID Tax Number</h5>
                                        <input type="text" style={{ width: "96%", padding: 7 }} value={eori} onChange={(e) => setEori(e.target.value)} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skd-edit-new-booking-button">
                        <div className="skd-edit-new-booking-button-option">
                            <div className="skd-new-booking-cancel" onClick={closeEdit}>
                                <h5>Cancel</h5>
                            </div>
                            <div ref={displayInvoiceExport ? invoiceRef1 : null}>
                                {displayInvoiceExport && <div className="skd-new-booking-print-container" style={{ marginTop: -55 }}>
                                    <div className="skd-new-booking-location">
                                        <div className={location === "SG" ? "skd-new-booking-location-option1" : "skd-new-booking-location-option"} onClick={() => setLocation("SG")}>
                                            <h5>SGN</h5>
                                        </div>
                                        <div className={location === "HN" ? "skd-new-booking-location-option1" : "skd-new-booking-location-option"} onClick={() => setLocation("HN")}>
                                            <h5>HAN</h5>
                                        </div>
                                        <ReactToExcel className="skd-new-booking-print2" table="fedex-vn-export" filename={`${hawb} invoice`} sheet="sheet" buttonText="Export Invoice" onClick={() => { setDisplayInvoiceExport(false) }} />
                                    </div>
                                    {/* <div style={{marginLeft: 5,marginTop: 5}}>
                                        <ReactToExcel className="skd-new-booking-print" table="fedex-vn-export" filename="test" sheet="sheet" buttonText="Export Invoice" onClick={closeEdit} />
                                    </div> */}
                                </div>}
                                <div className="skd-new-booking-print" onClick={() => { displayInvoiceExport ? setDisplayInvoiceExport(false) : setDisplayInvoiceExport(true) }}>
                                    <GetApp style={{ fontSize: 15 }} />
                                    <h5>Export</h5>
                                    <ArrowDropDown style={{ fontSize: 15 }} />
                                </div>
                            </div>
                            <table id="fedex-vn-export" style={{ position: "absolute", top: 9999 }}>
                                <thead>
                                    <tr>
                                        <td style={{ borderBottom: "1px solid lightgray", fontSize: 26, textAlign: "center", fontWeight: 600, color: "black", marginBottom: 5 }}>INVOICE</td>
                                    </tr>
                                    <tr>
                                        <td style={{ background: "rgb(240,240,240)" }}></td>
                                        <td style={{ background: "rgb(240,240,240)" }}></td>
                                        <td style={{ background: "rgb(240,240,240)" }}></td>
                                        <td style={{ fontSize: 18, fontWeight: 600, background: "rgb(240,240,240)", border: "1px solid lightgray" }}>SHIPPER</td>
                                        <td style={{ background: "rgb(240,240,240)" }}></td>
                                        <td style={{ fontSize: 25, textAlign: "center", background: "rgb(240,240,240)" }}>{`-->`}</td>
                                        <td style={{ fontSize: 18, fontWeight: 600, background: "rgb(240,240,240)", border: "1px solid lightgray" }}>CONSIGNEE</td>
                                        <td style={{ background: "rgb(240,240,240)" }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 16, fontWeight: 450, borderTop: "1px solid", border: "1px solid lightgray", width: 250 }}>INVOICE NUMBER</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, borderTop: "1px solid", border: "1px solid lightgray", textAlign: "center", width: 220 }}>{hawb}</td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", width: 180 }}>Company / Sender Name</td>
                                        <td style={{ fontSize: 16, fontWeight: 600, border: "1px solid", width: 180, textAlign: "left" }}>Skydart Express</td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left", width: 180 }}>Company / Receiver Name</td>
                                        <td style={{ fontSize: 16, fontWeight: 600, border: "1px solid", textAlign: "left", width: 180 }}>{importer}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", width: 250 }}>DATE OF INVOICE</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, textAlign: "center", border: "1px solid lightgray", width: 220 }}>{new Date(createAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>

                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Address</td>
                                        {location === "SG" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>32 Lam Son</td>}
                                        {location === "HN" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Room 102, No 21, Lot 1B, Trung Yen New Urban Area</td>}
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Address</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{address}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", width: 250 }}>INTERNATIONAL AIR WAYBILL NO</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", textAlign: "center", width: 220 }}></td>

                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{address2}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", width: 250 }}>COUNTRY OF ORIGIN</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", textAlign: "center", width: 220 }}>Vietnam</td>

                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{address3}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", width: 250 }}>DESTINATION</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", textAlign: "center", width: 220 }}>{country}</td>
                                        <td></td>

                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Ward</td>
                                        {location === "SG" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>2</td>}
                                        {location === "HN" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Trung Hoa</td>}
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>City / Province</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{city}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", width: 250 }}>REASON FOR EXPORT</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid lightgray", textAlign: "center", width: 220 }}>{reason}</td>

                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>District</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Tan Binh</td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Town</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{town}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450 }}></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>City / Province</td>
                                        {location === "SG" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Ho Chi Minh</td>}
                                        {location === "HN" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Ha Noi</td>}
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>State</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{state}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Postal Code</td>
                                        {location === "SG" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>70500</td>}
                                        {location === "HN" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>11313</td>}
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Postal Code</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{postal}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Country</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Vietnam</td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Country</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{country}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Contact Name</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Skydart Express</td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Contact Name</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{toName}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Contact Number</td>
                                        {location === "SG" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>02838487976</td>}
                                        {location === "HN" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>02437480692</td>}
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Contact Number</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{phone}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 18, fontWeight: 600 }}></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid" }}>Email Address</td>
                                        {location === "SG" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>info@skydart.com.vn</td>}
                                        {location === "HN" && <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>hanoi@skydart.com.vn</td>}
                                        <td></td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>Email Address</td>
                                        <td style={{ fontSize: 16, fontWeight: 450, border: "1px solid", textAlign: "left" }}>{email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 17, fontWeight: 600 }}>Description Of Goods</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr></tr>
                                </thead>
                                <tbody>
                                    {invoiceValue.map((s) => (
                                        <div>
                                            <tr style={{ background: "rgb(240,240,240)" }}>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>COMMODITY / ITEM {s.itemNo}</td>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>PACKING</td>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>QUANTITY</td>
                                                <td style={{ textAlign: "center", fontSize: 15, fontWeight: 600 }}>UNIT</td>
                                                <td style={{ textAlign: "right", fontSize: 15, fontWeight: 600 }}>UNIT PRICE (USD)</td>
                                                <td></td>
                                                <td style={{ textAlign: "right", fontSize: 15, fontWeight: 600 }}>S.TOTAL (USD)</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: 16 }}>{s.itemCommonity}</td>
                                                <td style={{ fontSize: 16 }}>{s.itemPackType}</td>
                                                <td style={{ fontSize: 16 }}>{s.itemQuantity}</td>
                                                <td style={{ textAlign: "center", fontSize: 16 }}>{s.itemUnit}</td>
                                                <td style={{ textAlign: "right", fontSize: 16 }}>{s.itemUnitPrice}</td>
                                                <td></td>
                                                <td style={{ textAlign: "right", fontSize: 16 }}>{(Math.round(s.itemValue * 100) / 100).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>ORIGIN</td>
                                                <td style={{ fontSize: 16 }}>{s.itemCountryOrigin}</td>
                                            </tr>
                                            {s.itemHsCode !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>HS CODE</td>
                                                <td style={{ fontSize: 16 }}>{s.itemHsCode}</td>
                                            </tr>}
                                            {s.itemBrand !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>BRAND NAME</td>
                                                <td style={{ fontSize: 16, }}>{s.itemBrand}</td>
                                            </tr>}
                                            {s.itemIngredients !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>INGREDIENT</td>
                                                <td style={{ fontSize: 16, }}>{s.itemIngredients}</td>
                                            </tr>}
                                            {s.itemPurpose !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>PURPOSE OF USE</td>
                                                <td style={{ fontSize: 16, }}>{s.itemPurpose}</td>
                                            </tr>}
                                            {s.itemManufact !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>MANUFACTURER</td>
                                                <td style={{ fontSize: 16, }}>{s.itemManufact}</td>
                                            </tr>}
                                            {s.itemManufactAddress !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>MANUFACTURER ADDRESS</td>
                                                <td style={{ fontSize: 16, }}>{s.itemManufactAddress}</td>
                                            </tr>}
                                            {s.itemFda !== "" && <tr>
                                                <td style={{ fontSize: 15, fontWeight: 600 }}>FDA REGISTRATION NO</td>
                                                <td style={{ fontSize: 16, }}>{s.itemFda}</td>
                                            </tr>}
                                        </div>
                                    ))}
                                    <tr style={{ background: "rgb(240,240,240)" }}></tr>
                                    <tr>
                                        <td style={{ fontWeight: 600, fontSize: 16 }}>TOTAL NUMBER OF PACKAGE</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right", fontWeight: 450, fontSize: 16, padding: 7, color: "black" }}>{packageValue.length} PCS</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600, fontSize: 16 }}>TOTAL WEIGHT (KG)</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right", fontWeight: 450, fontSize: 16, padding: 7, color: "black" }}>{(Math.round(finalGross * 100) / 100).toFixed(2)} KG</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600, fontSize: 16 }}>TOTAL VALUE OF GOODS</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right", fontWeight: 450, fontSize: 16, padding: 7, color: "black" }}>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600, fontSize: 16 }}>TRANSPORT COST</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right", fontWeight: 450, fontSize: 16, padding: 7, color: "black" }}>{(Math.round(transport * 100) / 100).toFixed(2) || "0.00"} USD</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600, fontSize: 16 }}>TOTAL VALUE OF INVOICE</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right", fontWeight: 600, fontSize: 16, padding: 7, color: "#0f5592" }}>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div ref={printOption ? printRefContainer : null}>
                                {printOption && <div className="skd-new-booking-print-container">
                                    <div className="skd-new-booking-print-option" onClick={printLabel}>
                                        <RiBillLine />
                                        <h5>Print Label</h5>
                                    </div>
                                    {type === "WPX" && <div className="skd-new-booking-print-option" onClick={printInvoice}>
                                        <FaFileInvoice />
                                        <h5>Print Invoice</h5>
                                    </div>}
                                    {type === "DOX" && <div className="skd-new-booking-print-option1">
                                        <FaFileInvoice />
                                        <h5>Print Invoice</h5>
                                    </div>}
                                    {type === "WPX" && <div className="skd-new-booking-print-option" onClick={printShippingMark}>
                                        <ImNewspaper />
                                        <h5>Print Shipping Mark</h5>
                                    </div>}
                                    {type === "DOX" && <div className="skd-new-booking-print-option1">
                                        <ImNewspaper />
                                        <h5>Print Shipping Mark</h5>
                                    </div>}
                                    <div className="skd-new-booking-print-option" onClick={printAll}>
                                        <BsReplyAll />
                                        <h5>Print All</h5>
                                    </div>
                                </div>}
                                <div className="skd-new-booking-print" onClick={() => { printOption ? setPrintOption(false) : setPrintOption(true) }}>
                                    <Print style={{ fontSize: 15 }} />
                                    <h5>Print</h5>
                                    <ArrowDropDown style={{ fontSize: 15 }} />
                                </div>
                            </div>
                            <button type="button" className="skd-new-booking-update" onClick={type === "WPX" ? updateShipment : updateDox}>Update</button>
                        </div>
                    </div>
                </div>
            </form>}
            {
                displayExport && <div className="shipment-note">
                    <div className='shipment-note-container' style={{ width: "35%", marginTop: "20%" }}>
                        <div className="shipment-export-container" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FileCopySharp fontSize="small" />
                                <h3 style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>File Name</h3>
                            </div>
                            <IconButton type="button" onClick={() => { setDisplayExport(false) }} style={{ width: 30, height: 30 }}>
                                <Clear fontSize="small" />
                            </IconButton>
                        </div>
                        <div className="shipment-export-enter-file-name">
                            <input type="text" value={excelFileName} onChange={(e) => setExcelFileName(e.target.value)} placeholder="Enter file name" />
                        </div>
                        <h5 style={{ marginLeft: 15, fontWeight: 600 }}>Exporting <strong style={{ fontWeight: 600, textDecoration: "underline", color: "darkblue" }}>{exportSelected.length} shipments from New Booking</strong></h5>
                        <div className="shipment-export-enter-file-name" style={{ gap: "0.5em" }}>
                            <CSVLink {...agentReport} onClick={() => setDisplayExport(false)} style={{ fontWeight: 600, marginRight: 15, textDecoration: "none", marginLeft: "auto" }} className="skydart-shipment-tool-create-shipment">Export</CSVLink>
                        </div>
                    </div>
                </div>
            }
            <Snackbar open={alertShipment} autoHideDuration={1000} onClose={resetShipment}>
                <MuiAlert elevation={3} variant="filled" onClose={resetShipment} severity="error">
                    <p>{errMessage}</p>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
                <MuiAlert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
                    Update Succeed
                </MuiAlert>
            </Snackbar>
            <div className="skydart__new__booking" ref={!printOption ? printRefContainer : null}>
                <div className="skydart__new__booking__header" ref={!displayEditScreen ? toCountryRef : null}>
                    <div className="skydart__new__booking__header__left" ref={!displayEditScreen ? fromCountryRef : null}>
                        <div className="skydart__new__booking__body__left">
                            <div className='skydart__new__booking__container'>
                                <div className="skydart__new__booking__container__header">
                                    <div className='skydart__new__booking__option__wrap'>
                                        {status === "" && <div className='skydart__new__booking__container__header__option'>
                                            <BsBox />
                                            <h5>All</h5>
                                        </div>}
                                        {status !== "" && <div className='skydart__new__booking__container__header__option2' onClick={() => setStatus("")}>
                                            <BsBox />
                                            <h5>All</h5>

                                        </div>}
                                        {status === "true" && <div className='skydart__new__booking__container__header__option' style={{ marginRight: 12 }}>
                                            <AiOutlineCheckCircle />
                                            <h5>Booked</h5>
                                            {booked.length !== 0 && <div className="skydart-new-booking-noti"><p>{booked.length > 1000 ? "999+" : booked.length}</p></div>}
                                        </div>}
                                        {status !== "true" && <div className='skydart__new__booking__container__header__option2' style={{ marginRight: 12 }} onClick={() => setStatus("true")}>
                                            <AiOutlineCheckCircle />
                                            <h5>Booked</h5>
                                            {booked.length !== 0 && <div className="skydart-new-booking-noti"><p>{booked.length > 1000 ? "999+" : booked.length}</p></div>}
                                        </div>}
                                        {status === "false" && <div className='skydart__new__booking__container__header__option'>
                                            <MdClear />
                                            <h5>Un booked</h5>
                                            {unbook.length !== 0 && <div className="skydart-new-booking-noti"><p>{unbook.length > 1000 ? "999+" : unbook.length}</p></div>}
                                        </div>}
                                        {status !== "false" && <div className='skydart__new__booking__container__header__option2' onClick={() => setStatus("false")}>
                                            <MdClear />
                                            <h5>Un booked</h5>
                                            {unbook.length !== 0 && <div className="skydart-new-booking-noti"><p>{unbook.length > 1000 ? "999+" : unbook.length}</p></div>}
                                        </div>}
                                        <select className="skydart-new-booking-return-label-left" style={{ outline: "none", padding: 7, marginLeft: 15 }} value={method} onChange={(e) => setMethod(e.target.value)}>
                                            <option value="file">File</option>
                                            <option value="link">Link</option>
                                        </select>
                                        <div className="skydart-new-booking-return-label-left" style={{ marginLeft: 5 }}>
                                            <h5>{receiver.length > 15 ? receiver.slice(0, 15) + "..." : receiver || "Receiving account"}</h5>
                                            <ArrowDropDown fontSize="small" />
                                        </div>
                                        <div ref={hawbRef}>
                                            <div className="skydart-new-booking-return-label-left" style={{ marginLeft: 5 }} onClick={() => {
                                                if (exportSelected.length === 0) {
                                                    setDisplayEditScreen(false);
                                                    setDisplayHawbList(false);
                                                }
                                                else if (exportSelected.length === 1) {
                                                    setHawb(labels);
                                                    setDisplayEditScreen(true);
                                                } else {
                                                    setDisplayHawbList(true);

                                                }
                                            }}>
                                                {exportSelected.length < 1 && <h5>HAWBS</h5>}
                                                {exportSelected.length === 1 && <h5 >{labels}</h5>}
                                                {exportSelected.length > 1 && <h5>{exportSelected.length} shipments</h5>}
                                                <ArrowDropDown fontSize="small" />
                                            </div>
                                            {displayHawbList && <div className="skydart-new-booking-hawb-list">
                                                {exportSelected.length > 1 && exportSelected.map((s) => (
                                                    <div className="skydart-new-booking-hawb-list-option" onClick={() => {
                                                        setDisplayEditScreen(true);
                                                        setHawb(s.hawb);
                                                        setDisplayHawbList(false);
                                                    }}>
                                                        <h5>{s.hawb}</h5>
                                                    </div>
                                                ))}
                                                {exportSelected.length === 0 && <div className="skydart-new-booking-hawb-empty">
                                                    <span style={{ fontWeight: 600, fontSize: 13 }}>No shipment shown...</span>
                                                </div>}
                                            </div>}
                                        </div>
                                        {method === "link" && <input type='text' value={fileUrl} onChange={(e) => setFileUrl(e.target.value)} className="label-input-text" placeholder="Label URL" />}
                                        {method === "file" && <div className="skydart-new-booking-return-label-input">
                                            <input type="file" id="label-file" onChange={onFileChange} accept="application/pdf" />
                                            {fileName === "" && <label for="label-file" className="label-attaching">
                                                <GetApp style={{ fontSize: 18, transform: "rotate(180deg)" }} />
                                                <h5 style={{ fontWeight: 600, marginLeft: 5 }}>Upload file</h5>
                                            </label>}
                                            {fileName !== "" && <div className="skydart-pdf-file-newBooking">
                                                <GrDocumentPdf />
                                                {fileName.length > 20 ? <h5>{fileName.slice(0, 20) + "..."}</h5> : <h5>{fileName}</h5>}
                                            </div>}
                                        </div>}
                                    </div>
                                    <div className="skydart__new__booking__option__wrap1">
                                        <div onClick={refreshShipment} className="skydart-newbooking-refresh-icon">
                                            <Refresh fontSize="small" />
                                            <p className="skydart-newbooking-helper">Refresh</p>
                                        </div>
                                        <div style={{ position: "relative" }} ref={exportRef}>
                                            {exportSelected.length !== 0 && <div style={{ color: "black" }} className="skydart-newbooking-refresh-icon" onClick={() => {
                                                if (displayExportOption) {
                                                    setDisplayExportOption(false);
                                                } else {
                                                    setDisplayExportOption(true);
                                                }
                                            }}>
                                                <GetApp fontSize="small" />
                                                <p className="skydart-newbooking-helper">Export</p>
                                            </div>}
                                            {displayExportOption && exportSelected.length !== 0 && <div className="skydart-newbooking-export-container">
                                                <div style={{ padding: 5, borderBottom: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <h5 style={{ fontWeight: 600 }}>Select agent's format</h5>
                                                    <Clear className="export-clear" onClick={() => {
                                                        setDisplayExportOption(false);
                                                    }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <CSVLink {...agentReport} style={{ padding: 5, width: "fit-content" }}>EAM - FORMAT</CSVLink>
                                                    <p onClick={exportText} style={{ padding: 5, textDecoration: "underline", width: "fit-content", color: "blue", cursor: "pointer" }}>CLE - FORMAT</p>
                                                </div>
                                            </div>}
                                        </div>
                                        {exportSelected.length === 0 && <div className="skydart-newbooking-refresh-icon1">
                                            <GetApp fontSize="small" />
                                            <p className="skydart-newbooking-helper">Export</p>
                                        </div>}
                                        {exportSelected.length !== 0 && <div className="skydart__new__booking__container__header__option1" onClick={renderAction} ref={actionRef}>
                                            <Launch fontSize="small" />
                                            <h5 style={{ marginRight: 5 }}>Action</h5>
                                            {displayAction && <div className="skydart__new__booking__container__action">
                                                {userData.role !== "Courier Manager" && <div className="skydart__new__booking__container__option5" style={{ opacity: 0.8, cursor: "not-allowed" }}>
                                                    <h5>Pick Up</h5>
                                                </div>}
                                                {userData.role === "Courier Manager" && <div className="skydart__new__booking__container__option5" onClick={pickUp}>
                                                    <h5>Pick Up</h5>
                                                </div>}
                                                {userData.role === "CEO" && <div className="skydart__new__booking__container__option5" onClick={pickUp}>
                                                    <h5>Pick Up</h5>
                                                </div>}
                                            </div>}
                                        </div>}
                                        {exportSelected.length === 0 && <div className="skydart__new__booking__container__header__option1" ref={actionRef} style={{ cursor: "not-allowed", opacity: 0.8 }}>
                                            <Launch fontSize="small" htmlColor="gray" />
                                            <h5 style={{ marginRight: 5, color: "gray" }}>Action</h5>
                                        </div>}
                                        <div className="skydart-new-booking-return-label-button">
                                            {userData.role === "CEO" && exportSelected.length === 0 && <button type="button" className="skydart-new-booking-button-publish1">Create Label</button>}
                                            {userData.role === "OPS" && exportSelected.length === 0 && <button type="button" className="skydart-new-booking-button-publish1">Create Label</button>}
                                            {userData.role === "CEO" && exportSelected.length !== 0 && <button type="button" onClick={publishLabel} className="skydart-new-booking-button-publish">Create Label</button>}
                                            {userData.role === "OPS" && exportSelected.length !== 0 && <button type="button" onClick={publishLabel} className="skydart-new-booking-button-publish">Create Label</button>}
                                            {userData.role !== "OPS" && userData.role !== "CEO" && <button type="button" className="skydart-new-booking-button-publish1">Create Label</button>}
                                        </div>
                                        <div className='skydart-new-booking-consumer-book' onClick={() => history.push("/booking")}>
                                            <Add />
                                        </div>
                                    </div>
                                </div>
                                <div className="skydart__new__booking__filter__container">
                                    <div className="skydart__new__booking__search__box">
                                        <Search fontSize="small" style={{ position: 'absolute', marginLeft: 9 }} />
                                        <input type="text" placeholder="Search shipments..." ref={textInput} value={searchHawb} onChange={handleHawb} />
                                        {/* <Filter size={15} className="skydart_new_booking_filter" onClick={() => setDisplayFilter(true)} />
                                        <div ref={typeRef}>
                                            {displayFilter && <div className="skydart_new_booking_filter_container">
                                                <div className="skydart_new_booking_filter_option">
                                                    <h5 style={{ marginBottom: 10, padding: 5 }}>Filter by type</h5>
                                                    <div className="skydart_new_type" onClick={() => { setSearchType(""); setDisplayFilter(false) }}>
                                                        <h5>All</h5>
                                                    </div>
                                                    <div className="skydart_new_type" onClick={() => { setSearchType("WPX"); setDisplayFilter(false) }}>
                                                        <h5>WPX</h5>
                                                    </div>
                                                    <div className="skydart_new_type" onClick={() => { setSearchType("DOX"); setDisplayFilter(false) }}>
                                                        <h5>DOX</h5>
                                                    </div>
                                                    <div className="skydart_new_type" onClick={() => { setSearchType("ECS"); setDisplayFilter(false) }}>
                                                        <h5>ECS</h5>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div> */}
                                    </div>
                                    <h5 style={{ fontSize: 11, fontWeight: 600 }}>Found {finalShipment.length} / {shipments.length} shipments</h5>
                                    {exportSelected.length !== 0 && <div className="skydart_skydart_new_select" style={{ marginLeft: 20 }}>
                                        <h5 style={{ padding: 2 }}>{exportSelected.length} selected</h5>
                                    </div>}
                                    {err && <p style={{ color: "red", fontWeight: 600, marginLeft: 10, fontSize: 12 }}>Please attach a file or enter a link</p>}
                                    <div className="skydart__new__booking__date__box" style={{ marginLeft: "auto" }}>
                                        {/* <select value={skdFontSize} onChange={(e) => setSkdFontSize(e.target.value)}>
                                            <option value={13}>Small</option>
                                            <option value={15}>Large</option>
                                        </select> */}
                                        <div ref={dateMethod !== "range" ? dateRef : null}></div>
                                        {dateMethod === "range" && <div className="skd-user-shipment-header-right" ref={dateMethod === "range" ? dateRef : null} style={{ position: "relative" }}>
                                            <div className="skd-user-shipment-header-right-date" onClick={() => { displayDate ? setDisplayDate(false) : setDisplayDate(true) }}>
                                                <GoCalendar size={20} style={{ marginRight: 10 }} />
                                                <p style={{ marginRight: 7, fontSize: 13 }}>{dateStatus}:</p>
                                                <p style={{ marginRight: 7, fontSize: 13 }}>{startDay}</p>
                                                <AiOutlineMinus size={10} style={{ marginRight: 7 }} />
                                                <p style={{ marginRight: 7, fontSize: 13 }}>{endDay}</p>
                                                <ArrowDropDown style={{ fontSize: 18 }} />
                                            </div>
                                            {displayDate && <div className="skd-user-date" style={{ right: 0 }}>
                                                <DatepickerContext.Provider
                                                    value={{
                                                        focusedDate,
                                                        isDateFocused,
                                                        isDateSelected,
                                                        isDateHovered,
                                                        isDateBlocked,
                                                        isFirstOrLastSelectedDate,
                                                        onDateSelect,
                                                        onDateFocus,
                                                        onDateHover,
                                                    }}
                                                >
                                                    <div className="skd-user-date-content">
                                                        <div className="skd-user-date-left">
                                                            <div className={dateStatus === "Today" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleToday}>
                                                                {dateStatus === "Today" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>Today</p>
                                                            </div>
                                                            <div className={dateStatus === "Yesterday" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleYesterday}>
                                                                {dateStatus === "Yesterday" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>Yesterday</p>
                                                            </div>
                                                            <div className={dateStatus === "This week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisWeek}>
                                                                {dateStatus === "This week" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>This week</p>
                                                            </div>
                                                            <div className={dateStatus === "Last week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastWeek}>
                                                                {dateStatus === "Last week" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>Last week</p>
                                                            </div>
                                                            <div className={dateStatus === "This month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisMonth}>
                                                                {dateStatus === "This month" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>This Month</p>
                                                            </div>
                                                            <div className={dateStatus === "Last month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastMonth}>
                                                                {dateStatus === "Last month" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>Last Month</p>
                                                            </div>
                                                            <div className={dateStatus === "This year" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisYear}>
                                                                {dateStatus === "This year" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                                <p style={{ color: "black" }}>This Year</p>
                                                            </div>
                                                        </div>
                                                        <div className="skd-user-date-right">
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    margin: "15px 0 0",
                                                                    gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                                                                    gridGap: "0 24px"
                                                                }}
                                                            >
                                                                <div className="date-month-back" onClick={goToPreviousMonths}>
                                                                    <ArrowLeft />
                                                                </div>
                                                                <div className="date-month-back1" onClick={goToNextMonths}>
                                                                    <ArrowRight />
                                                                </div>
                                                                {activeMonths.map(month => (
                                                                    <Month1
                                                                        key={`${month.year}-${month.month}`}
                                                                        year={month.year}
                                                                        month={month.month}
                                                                        firstDayOfWeek={firstDayOfWeek}
                                                                    />
                                                                ))}

                                                            </div>
                                                            <div className="skd-user-date-shown">
                                                                <div className="skd-user-date-shown-range">
                                                                    <h5 style={{ fontWeight: 450 }}>{!dateRange.startDate ? "Start Date" : dateRange.startDate.toDateString()}</h5>
                                                                    <AiOutlineMinus />
                                                                    <h5 style={{ fontWeight: 450, marginLeft: 7 }}>{!dateRange.endDate ? "End Date" : dateRange.endDate.toDateString()}</h5>
                                                                    <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                                                                </div>
                                                                <div className="skd-user-date-update-button">
                                                                    <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                                                                        <h5 style={{ color: "black", textAlign: "center" }}>Cancel</h5>
                                                                    </div>
                                                                    <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                                                                        <h5 style={{ color: "white", textAlign: "center" }}>Update</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </DatepickerContext.Provider>
                                            </div>}
                                        </div>}
                                        {dateMethod === "single" && <div className="skd-new-booking-single-date">
                                            <h5>Date range:</h5>
                                            <input type="date" className="default-date-input-skd" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                            <input type="date" className="default-date-input-skd" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                        </div>}
                                    </div>
                                </div>
                                {loading && <div className="skydart-new-booking-loading-progress">
                                    <LinearProgress style={{ height: 5, borderRadius: 5 }} />
                                </div>}
                                <div className="skydart__new__booking__shipment">
                                    <div className="skydart-new-wrap">
                                        <div style={{ width: 1550 }}>
                                            <div className="skydart__new__booking__shipment__header">
                                                <div className="skydart-new-booking-sticky">
                                                    <div className="skydart-bill-checkbox-header" style={{ border: "none", marginTop: 4 }}>
                                                        <input type="checkbox" id="selectAll" onChange={e => {
                                                            let value = e.target.checked;
                                                            setSelectedShipment(finalShipment.map(d => {
                                                                d.select = value;
                                                                return d;
                                                            }))
                                                        }} />
                                                    </div>
                                                    <div className="skydart-bill-edit-header" style={{ width: 30 }}>
                                                        <h5 style={{ textAlign: "center" }}>act</h5>
                                                    </div>
                                                    <div className="skydart_skydart__new__booking__no">
                                                        <h5 style={{ background: "none", fontSize: 13, textAlign: "center", color: "black", fontWeight: 480 }}>No.</h5>
                                                    </div>
                                                    <div className="skydart_skydart__new__booking__booked" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 40 }}>
                                                        <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>Stats</h5>
                                                    </div>
                                                    <div className="skydart_skydart__new__booking__customer" style={{ display: "flex", alignItems: "center" }} ref={customerRef}>
                                                        <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>Exporter</h5>
                                                        <Filter size={13} className="skydart_new_booking_filter" style={{ marginLeft: 5 }} onClick={() => setDisplaySortCustomer(true)} />
                                                        {!alphabet && <ArrowDropDown className="skydart_skydart_drop" onClick={() => setAlphabet(true)} />}
                                                        {alphabet && <ArrowDropUp className="skydart_skydart_drop" onClick={() => setAlphabet(false)} />}
                                                        {displaySortCustomer && <div className="skydart-new-booking-filter-option" style={{ marginTop: 265 }}>
                                                            <form className="skydart-new-booking-filter-container" onSubmit={(e) => { e.preventDefault(); setDisplaySortCustomer(false) }} style={{ borderBottom: "1px solid lightgray" }}>
                                                                <Search fontSize="small" />
                                                                <input type="text" placeholder="Search Customer" value={searchCus} onChange={(e) => setSearchCus(e.target.value)} />
                                                                <button className="hidden" type="submit">Submit</button>
                                                            </form>
                                                            <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                                                {sortedCustomer.filter((s) => {
                                                                    if (s.toLowerCase().includes(searchCus.toLowerCase())) {
                                                                        return s;
                                                                    } else {
                                                                        return s;
                                                                    }
                                                                }).map((s, index) => (
                                                                    <div className="sorted" onClick={() => setSearchCus(s)}>
                                                                        <h5 style={{ fontSize: 11 }}>{s}</h5>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="sorted-btn">
                                                                <button type="button" onClick={() => setDisplaySortCustomer(false)}>Search</button>
                                                                <h5 onClick={() => { setDisplaySortCustomer(false); setSearchCus("") }}>Cancel</h5>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div className="skydart_skydart__new__booking__hawb">
                                                        <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>HAWB</h5>
                                                    </div>
                                                    <div className="skydart_skydart__new__booking__date">
                                                        <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>Created at</h5>
                                                    </div>
                                                    <div className="skydart_skydart__new__booking__destination" style={{ display: "flex", alignItems: "center" }} ref={countryRef}>
                                                        <h5 style={{ marginLeft: 5, fontWeight: 480, fontSize: 13, color: "black" }}>Destination</h5>
                                                        <Filter size={13} className="skydart_new_booking_filter" style={{ marginLeft: 5 }} onClick={() => setDisplaySortCountry(true)} />
                                                        {!countryAlphabet && <ArrowDropDown className="skydart_skydart_drop" onClick={() => setCountryAlphabet(true)} />}
                                                        {countryAlphabet && <ArrowDropUp className="skydart_skydart_drop" onClick={() => setCountryAlphabet(false)} />}
                                                        {displaySortCountry && <div className="skydart-new-booking-filter-option" style={{ marginTop: 265 }}>
                                                            <div className="skydart-new-booking-filter-container" style={{ borderBottom: "1px solid lightgray" }}>
                                                                <Search fontSize="small" />
                                                                <input type="text" placeholder="Search Country" value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)} />
                                                                <select value={searchCode} onChange={(e) => setSearchCode(e.target.value)} style={{ padding: 5, background: "rgb(238,238,238)", cursor: "pointer", border: "none", outline: "none", borderRadius: 5 }}>
                                                                    <option value="">Search Code</option>
                                                                    {sortedCode.sort((a, b) => {
                                                                        return a.localeCompare(b);
                                                                    }).map((s) => {
                                                                        return <option value={s}>{s}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                                                {sortedCountry.filter((s) => {
                                                                    if (s.toLowerCase().includes(searchCus.toLowerCase())) {
                                                                        return s;
                                                                    } else {
                                                                        return s;
                                                                    }
                                                                }).map((s, index) => (
                                                                    <div className="sorted" onClick={() => setSearchCountry(s)}>
                                                                        <h5 style={{ fontSize: 11 }}>{s}</h5>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="sorted-btn">
                                                                <button type="button" onClick={() => setDisplaySortCountry(false)}>Search</button>
                                                                <h5 onClick={() => { setDisplaySortCountry(false); setSearchCountry("") }}>Cancel</h5>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="skydart_skydart__new__booking__type">
                                                    <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>Type</h5>
                                                    {!displayFilter && <ArrowDropDown className="skydart_skydart_drop" onClick={() => setDisplayFilter(true)} />}
                                                    {displayFilter && <ArrowDropUp className="skydart_skydart_drop" onClick={() => setDisplayFilter(false)} />}
                                                    <div ref={typeRef}>
                                                        {displayFilter && <div className="skydart_new_booking_filter_container">
                                                            <div className="skydart_new_booking_filter_option">
                                                                <div className="skydart_new_type" onClick={() => { setSearchType(""); setDisplayFilter(false) }}>
                                                                    <h5>All</h5>
                                                                </div>
                                                                <div className="skydart_new_type" onClick={() => { setSearchType("WPX"); setDisplayFilter(false) }}>
                                                                    <h5>WPX</h5>
                                                                </div>
                                                                <div className="skydart_new_type" onClick={() => { setSearchType("DOX"); setDisplayFilter(false) }}>
                                                                    <h5>DOX</h5>
                                                                </div>
                                                                <div className="skydart_new_type" onClick={() => { setSearchType("ECS"); setDisplayFilter(false) }}>
                                                                    <h5>ECS</h5>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="skydart_skydart__new__booking__info" style={{ display: "flex", alignItems: "center" }}>
                                                    <h5 style={{ textAlign: "center", fontWeight: 480, color: "black", fontSize: 13, }}>KG</h5>
                                                    <select value={searchWeight} onChange={(e) => setSearchWeight(e.target.value)} style={{ width: 20, outline: "none", border: "1px solid lightgray", borderRadius: 5, fontSize: 13, marginLeft: "auto" }}>
                                                        <option value="">All</option>
                                                        {duplicateWeight.sort((s, i) => {
                                                            if (s > 0 && i > 0) {
                                                                return s - i;
                                                            }
                                                            return i - s;
                                                        }).map((s, index) => (
                                                            <option value={s} key={index}>{s}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="skydart_skydart__new__booking__info" style={{ display: "flex", alignItems: "center" }}>
                                                    <h5 style={{ textAlign: 'center', fontWeight: 480, color: "black", fontSize: 13, }}>PCS</h5>
                                                    <select value={searchPcs} onChange={(e) => setSearchPcs(e.target.value)} style={{ width: 20, outline: "none", border: "1px solid lightgray", borderRadius: 5, fontSize: 13, marginLeft: "auto" }}>
                                                        <option value="">All</option>
                                                        {duplicatePcs.map((s, index) => (
                                                            <option value={s} key={index}>{s}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="skydart_skydart__new__booking__info" style={{ display: "flex", alignItems: "center" }}>
                                                    <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>DV</h5>
                                                    <select value={searchService} onChange={(e) => setSearchService(e.target.value)} style={{ width: 20, outline: "none", border: "1px solid lightgray", borderRadius: 5, fontSize: 13, marginLeft: "auto" }}>
                                                        <option value="">All</option>
                                                        {duplicateService.map((s, index) => (
                                                            <option value={s} key={index}>{s}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                                <div className="skydart_skydart__new__booking__info">
                                                    <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>Value</h5>
                                                </div>
                                                <div className="skydart_skydart__new__booking__info">
                                                    <h5 style={{ fontWeight: 480, color: "black", fontSize: 13, }}>Docs</h5>
                                                </div>
                                                <div className="skydart_skydart__new__booking__description" style={{ background: "none", border: "none", marginTop: 0 }}>
                                                    <h5 style={{ fontWeight: 480, color: "black", background: "none", fontSize: 13, }}>Description</h5>
                                                    <FcAbout size={20} />
                                                </div>
                                                {/* <div className="skydart_skydart__new__booking__note" style={{ background: "none" }}>
                                                    <h5 style={{ fontWeight: 480, fontSize: 13, color: "black" }}>Sender's note</h5>
                                                </div> */}
                                            </div>
                                            {currentShipment.sort((s, i) => {
                                                if (alphabet) {
                                                    s = s.sender || "";
                                                    i = i.sender || "";
                                                    return s.localeCompare(i);
                                                }
                                            }).sort((s, i) => {
                                                if (countryAlphabet) {
                                                    s = s.country || "";
                                                    i = i.country || "";
                                                    return s.localeCompare(i);
                                                }
                                            }).map((s, index) => {
                                                return <>
                                                    {!s.multi && <div className={s.select ? "selected-shipment1" : "skydart__new__booking__shipment__body"} key={index} onClick={() => {
                                                        setHawb(s.hawb);
                                                        if (s.type === "WPX") {
                                                            setType(s.type);
                                                            setValue(s.value);
                                                            setCode(s.code);
                                                            setCountry(s.country);
                                                            setAddress(s.address);
                                                            setAddress2(s.address2);
                                                            setAddress3(s.address3);
                                                            setTown(s.town);
                                                            setCity(s.city);
                                                            setState(s.state);
                                                            setImporter(s.company);
                                                            setPostal(s.postal);
                                                            setToName(s.contact);
                                                            setPhone(s.phone);
                                                            setEmail(s.toEmail);
                                                            setPackageValue(s.packageValue);
                                                            setInvoiceValue(s.invoiceValue);
                                                            setTerm(s.term);
                                                            setTransport(s.transportation);
                                                            setReason(s.reason);
                                                            setService(s.service);
                                                            setEori(s.eori);
                                                            setExporter(s.sender);
                                                            setFromCountry(s.fromCountry);
                                                            setFromAddress(s.fromAddress);
                                                            setFromAddress2(s.fromAddress2);
                                                            setFromAddress3(s.fromAddress3);
                                                            setFromTown(s.fromTown);
                                                            setFromCity(s.fromCity);
                                                            setFromState(s.fromState);
                                                            setFromPostal(s.fromPostal);
                                                            setFromName(s.fromName);
                                                            setFromPhone(s.fromPhone);
                                                            setTerm(s.term);
                                                            setFromEmail(s.fromEmail);
                                                            setController(s.controller);
                                                            setDescription(s.description);
                                                            setPickupBy(s.pickupBy);
                                                            setStat(s.status);
                                                            setCreateAt(s.date);
                                                            setPickup(s.pickupBy);
                                                            setArrive(s.receiveBy);
                                                            setProcess(s.processBy);
                                                            setClearance(s.clearanceBy);
                                                            setClear(s.clearedBy);
                                                            setHold(s.holdBy);
                                                            setInvoiceValue(s.invoiceValue);
                                                            setPackageValue(s.packageValue);

                                                        } else if (s.type === "DOX") {
                                                            setType(s.type);
                                                            setCode(s.code);
                                                            setCountry(s.country);
                                                            setImporter(s.company);
                                                            setValue(s.value);
                                                            setAddress(s.address);
                                                            setAddress2(s.address2);
                                                            setAddress3(s.address3);
                                                            setTown(s.town);
                                                            setCity(s.city);
                                                            setState(s.state);
                                                            setPostal(s.postal);
                                                            setToName(s.contact);
                                                            setPhone(s.phone);
                                                            setPickupBy(s.pickupBy);
                                                            setEmail(s.toEmail);
                                                            setTerm(s.term);
                                                            setTransport(s.transportation);
                                                            setEnvelope(s.totalPackage);
                                                            setDoxWeight(s.totalCharge);
                                                            setReason(s.reason);
                                                            setService(s.service);
                                                            setEori(s.eori);
                                                            setExporter(s.sender);
                                                            setFromCountry(s.fromCountry);
                                                            setFromAddress(s.fromAddress);
                                                            setFromAddress2(s.fromAddress2);
                                                            setFromAddress3(s.fromAddress3);
                                                            setFromTown(s.fromTown);
                                                            setFromCity(s.fromCity);
                                                            setFromState(s.fromState);
                                                            setFromPostal(s.fromPostal);
                                                            setFromName(s.fromName);
                                                            setFromPhone(s.fromPhone);
                                                            setFromEmail(s.fromEmail);
                                                            setController(s.controller);
                                                            setStat(s.status);
                                                            setCreateAt(s.date);
                                                            setDescription("Document");
                                                            setPickup(s.pickupBy);
                                                            setArrive(s.receiveBy);
                                                            setProcess(s.processBy);
                                                            setClearance(s.clearanceBy);
                                                            setClear(s.clearedBy);
                                                            setHold(s.holdBy);
                                                        }
                                                        setSelectedShipment(finalShipment.map(sd => {
                                                            if (sd.hawb === s.hawb) {
                                                                sd.multi = true;
                                                            } else {
                                                                sd.multi = false;
                                                            }
                                                            return sd;
                                                        }))
                                                        let arr = [];
                                                        // for (let i = 0; i < s.packageValue.length; i++) {
                                                        //     const refNumber = "SKD";
                                                        //     const newDates = new Date();
                                                        //     const dates = (newDates.getDate() < 10 ? "0" : "") + newDates.getDate();
                                                        //     const months = ((newDates.getMonth() + 1) < 10 ? "0" : "") + (newDates.getMonth() + 1);
                                                        //     const years = newDates.getFullYear().toString().charAt(2);
                                                        //     const yearLength = newDates.getFullYear().toString().charAt(3);
                                                        //     const max = 900000;
                                                        //     const wrapper = (`${years}${yearLength}${months}${dates}`);
                                                        //     const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
                                                        //     return {
                                                        //         hawb: txt,
                                                        //         weight: s.packageValue[i].packageWeight,
                                                        //         height: s.packageValue[i].packageHeight,
                                                        //         length: s.packageValue[i].packageLength,
                                                        //         width: s.packageValue[i].packageWidth,
                                                        //     }
                                                        // }
                                                        if (s.type === "WPX") {
                                                            setLabelArray(s.packageValue.map(s => {
                                                                const refNumber = "SKD";
                                                                const newDates = new Date();
                                                                const dates = (newDates.getDate() < 10 ? "0" : "") + newDates.getDate();
                                                                const months = ((newDates.getMonth() + 1) < 10 ? "0" : "") + (newDates.getMonth() + 1);
                                                                const years = newDates.getFullYear().toString().charAt(2);
                                                                const yearLength = newDates.getFullYear().toString().charAt(3);
                                                                const max = 900000;
                                                                const wrapper = (`77${yearLength}${months}${dates}`);
                                                                const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
                                                                return {
                                                                    hawb: txt,
                                                                    loading: false,
                                                                    weight: s.packageWeight,
                                                                    height: s.packageHeight,
                                                                    length: s.packageLength,
                                                                    width: s.packageWidth,
                                                                    packNo: s.packNo,
                                                                }
                                                            }))
                                                        }

                                                    }} >
                                                        <div className="skydart-new-booking-sticky">
                                                            <div className="skydart-bill-checkbox-header" style={{ border: "none", marginTop: 4 }}>
                                                                <input type="checkbox" checked={s.select} onChange={e => {
                                                                    setSelectedShipment(finalShipment.map(sd => {
                                                                        let value = e.target.checked;
                                                                        if (sd.hawb === s.hawb) {
                                                                            sd.select = value;
                                                                            sd.multi = false;
                                                                        }
                                                                        return sd;
                                                                    }))
                                                                }} />
                                                            </div>
                                                            <div className="skydart-bill-edit-header" onClick={() => {
                                                                // setDisplayEditScreen(true);
                                                                setDisplayEditScreen(true);
                                                                setSelectedShipment(finalShipment.map(sd => {
                                                                    sd.multi = false;
                                                                    return sd;
                                                                }))
                                                            }} style={{ width: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <PageviewOutlined style={{ cursor: "pointer", fontSize: 20 }} />
                                                            </div>

                                                            <div className="skydart_skydart__new__booking__no">
                                                                <h5 style={{ textAlign: "center", }}>{index + 1}</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__booked" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 40 }}>
                                                                {s.booked === "true" && <FcCheckmark className="skydart-skydart-new-booking-check-icon1" />}
                                                                {s.booked === "false" && <FcHighPriority className="skydart-skydart-new-booking-check-icon" />}
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__customer">
                                                                <h5>{s.sender.length > 23 ? s.sender.slice(0, 23) + "..." : s.sender}</h5>
                                                                <p className="skydart-bill-hawb-header-helper">{s.sender}</p>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__hawb">
                                                                <h5 style={{ color: '#0e4d94', textDecoration: "none" }}>{s.hawb}</h5>
                                                                {s.sin === "error" && <p style={{ fontSize: 11, color: "red" }}>{s.sin}</p>}
                                                                {/* <div className="skydart_skydart__new__booking_sin">
                                                                    {s.dus.length < 2 && s.sin !== "error" && <a href={s.labelURL} target="_blank">{s.sin}</a>}
                                                                    {s.dus.length < 2 && s.sin === "error" && <p style={{ color: "red" }}>{s.sin}</p>}
                                                                    <div className="skydart_DUSlabel">
                                                                        <div className="skydart_DUSlabel_container" onClick={() => {
                                                                            if (s.multi) {
                                                                                setSelectedShipment(finalShipment.map(sd => {
                                                                                    if (sd.hawb === s.hawb) {
                                                                                        sd.multi = false;
                                                                                    }
                                                                                    return sd;
                                                                                }))
                                                                            } else if (!s.multi) {
                                                                                setSelectedShipment(finalShipment.map(sd => {
                                                                                    if (sd.hawb === s.hawb) {
                                                                                        sd.multi = true;
                                                                                    }
                                                                                    return sd;
                                                                                }))
                                                                            }
                                                                        }}>
                                                                            <p>Multiple pcs</p>
                                                                        </div>
                                                                        {s.multi && <div className="skydart-dus-multi">
                                                                            <div className="skydart-dus-multi-container">
                                                                                <h5>VU Nguyen</h5>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__date">
                                                                <h5>{new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} - {s.time}</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__destination" style={{ display: "flex", alignItems: "center" }}>
                                                                <h5 style={{ marginLeft: 5 }}>{s.country}</h5>

                                                            </div>
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__type">
                                                            {s.type === "WPX" && <Package size={18} />}
                                                            {s.type === "DOX" && <File size={18} />}
                                                            <h5 style={{ marginLeft: 5 }}>{s.type}</h5>
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__info">
                                                            <h5 style={{ color: 'black', marginLeft: 1 }}>{(Math.round(s.chargeable * 100) / 100).toFixed(2)}</h5>
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__info">
                                                            <h5 style={{ marginLeft: 1 }}>{s.amount} pcs</h5>
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__info" >
                                                            {s.service !== "SKD" && s.service !== "DUS" && s.service !== "DHL" && s.service !== "DHL-VN" && s.service !== "FIP" && s.service !== "UPS" && <h5 style={{ color: 'black', background: "rgb(240,240,240)", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            {s.service === "DHL" && <h5 style={{ color: 'red', background: "yellow", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            {s.service === "FIP" && <h5 style={{ color: 'white', background: "purple", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            {s.service === "UPS" && <h5 style={{ color: 'rgb(247, 202, 136)', background: "brown", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            {s.service === "SKD" && <h5 className="art" style={{ color: 'white', textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            {s.service === "DUS" && <h5 style={{ color: 'white', background: "black", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            {s.service === "DHL-VN" && <h5 style={{ color: 'red', background: "yellow", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__info">
                                                            <h5 style={{ color: 'black' }}>${s.value}</h5>
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__info">
                                                            {s.type === "WPX" && <h5 style={{ color: 'black' }}>{s.invoiceValue.length} items</h5>}
                                                            {s.type === "DOX" && <h5 style={{ color: "black" }}>DOX</h5>}
                                                        </div>
                                                        <div className="skydart_skydart__new__booking__description">
                                                            <h5 style={{ fontWeight: 600, fontSize: 13 }}>{s.description.length > 65 ? s.description.slice(0, 65).replaceAll(",", "  //  ") + "..." : s.description.replaceAll(",", " // ")}</h5>
                                                            <div className="skydart-bill-hawb-header-helper1">
                                                                {s.descArr.filter((item) => {
                                                                    return item !== "";
                                                                }).map((item, index) => {
                                                                    return <div style={{ background: "lightyellow" }}>
                                                                        {index + 1 === 1 && <div style={{ background: "black", color: "white", padding: 5, fontSize: 13 }}>
                                                                            <p>{s.hawb}</p>
                                                                            {/* <button type="button" onClick={() => {
                                                                            translate(s.description, {
                                                                                tld: "en",
                                                                                to: "vi",   
                                                                            })
                                                                        }}>Dịch</button> */}
                                                                        </div>
                                                                        }
                                                                        <div className="skd-bill-helper-option">
                                                                            <p>{index + 1}.</p>
                                                                            <p style={{ marginLeft: 5, color: "black" }}>{item}</p>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* <div className="skydart_skydart__new__booking__note">
                                                        <h5 style={{ fontWeight: 480, fontSize: 13, color: "black" }}>{s.senderNote || "pending"}</h5>
                                                    </div> */}
                                                    </div>}
                                                    {s.multi && <div className={s.select ? "selected-shipment1_multi" : "skydart__new__booking__shipment__body_multi"} key={index}>
                                                        <div style={{ display: "flex", borderBottom: "1px solid rgb(230,230,230)" }}>
                                                            <div className="skydart-new-booking-sticky">
                                                                <div className="skydart-bill-checkbox-header" style={{ border: "none", marginTop: 4 }}>
                                                                    <input type="checkbox" checked={s.select} onChange={e => {
                                                                        setSelectedShipment(finalShipment.map(sd => {
                                                                            let value = e.target.checked;
                                                                            if (sd.hawb === s.hawb) {
                                                                                sd.select = value;
                                                                                sd.multi = true;
                                                                            }
                                                                            return sd;
                                                                        }))
                                                                    }} />
                                                                </div>
                                                                <div className="skydart-bill-edit-header" style={{ width: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <PageviewOutlined onClick={() => {
                                                                        // setDisplayEditScreen(true);
                                                                        setDisplayEditScreen(true);
                                                                        setHawb(s.hawb);
                                                                        setBaseService(s.service);
                                                                        if (s.type === "WPX") {
                                                                            setType(s.type);
                                                                            setValue(s.value);
                                                                            setCode(s.code);
                                                                            setCountry(s.country);
                                                                            setAddress(s.address);
                                                                            setAddress2(s.address2);
                                                                            setAddress3(s.address3);
                                                                            setTown(s.town);
                                                                            setCity(s.city);
                                                                            setState(s.state);
                                                                            setImporter(s.company);
                                                                            setPostal(s.postal);
                                                                            setToName(s.contact);
                                                                            setPhone(s.phone);
                                                                            setEmail(s.toEmail);
                                                                            setPackageValue(s.packageValue);
                                                                            setInvoiceValue(s.invoiceValue);
                                                                            setTerm(s.term);
                                                                            setTransport(s.transportation);
                                                                            setReason(s.reason);
                                                                            setService(s.service);
                                                                            setEori(s.eori);
                                                                            setExporter(s.sender);
                                                                            setFromCountry(s.fromCountry);
                                                                            setFromAddress(s.fromAddress);
                                                                            setFromAddress2(s.fromAddress2);
                                                                            setFromAddress3(s.fromAddress3);
                                                                            setFromTown(s.fromTown);
                                                                            setFromCity(s.fromCity);
                                                                            setFromState(s.fromState);
                                                                            setFromPostal(s.fromPostal);
                                                                            setFromName(s.fromName);
                                                                            setFromPhone(s.fromPhone);
                                                                            setTerm(s.term);
                                                                            setFromEmail(s.fromEmail);
                                                                            setController(s.controller);
                                                                            setDescription(s.description);
                                                                            setPickupBy(s.pickupBy);
                                                                            setStat(s.status);
                                                                            setCreateAt(s.date);
                                                                            setPickup(s.pickupBy);
                                                                            setArrive(s.receiveBy);
                                                                            setProcess(s.processBy);
                                                                            setClearance(s.clearanceBy);
                                                                            setClear(s.clearedBy);
                                                                            setHold(s.holdBy);
                                                                            setInvoiceValue(s.invoiceValue);
                                                                            setPackageValue(s.packageValue);

                                                                        } else if (s.type === "DOX") {
                                                                            setType(s.type);
                                                                            setCode(s.code);
                                                                            setCountry(s.country);
                                                                            setImporter(s.company);
                                                                            setValue(s.value);
                                                                            setAddress(s.address);
                                                                            setAddress2(s.address2);
                                                                            setAddress3(s.address3);
                                                                            setTown(s.town);
                                                                            setCity(s.city);
                                                                            setState(s.state);
                                                                            setPostal(s.postal);
                                                                            setToName(s.contact);
                                                                            setPhone(s.phone);
                                                                            setPickupBy(s.pickupBy);
                                                                            setEmail(s.toEmail);
                                                                            setTerm(s.term);
                                                                            setTransport(s.transportation);
                                                                            setEnvelope(s.totalPackage);
                                                                            setDoxWeight(s.totalCharge);
                                                                            setReason(s.reason);
                                                                            setService(s.service);
                                                                            setEori(s.eori);
                                                                            setExporter(s.sender);
                                                                            setFromCountry(s.fromCountry);
                                                                            setFromAddress(s.fromAddress);
                                                                            setFromAddress2(s.fromAddress2);
                                                                            setFromAddress3(s.fromAddress3);
                                                                            setFromTown(s.fromTown);
                                                                            setFromCity(s.fromCity);
                                                                            setFromState(s.fromState);
                                                                            setFromPostal(s.fromPostal);
                                                                            setFromName(s.fromName);
                                                                            setFromPhone(s.fromPhone);
                                                                            setFromEmail(s.fromEmail);
                                                                            setController(s.controller);
                                                                            setStat(s.status);
                                                                            setCreateAt(s.date);
                                                                            setDescription("Document");
                                                                            setPickup(s.pickupBy);
                                                                            setArrive(s.receiveBy);
                                                                            setProcess(s.processBy);
                                                                            setClearance(s.clearanceBy);
                                                                            setClear(s.clearedBy);
                                                                            setHold(s.holdBy);
                                                                        }

                                                                    }} style={{ cursor: "pointer", fontSize: 20 }} />
                                                                </div>
                                                                <div className="skydart_skydart__new__booking__no">
                                                                    <h5 style={{ textAlign: "center" }}>{index + 1}</h5>
                                                                </div>
                                                                <div className="skydart_skydart__new__booking__booked" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 40 }}>
                                                                    {s.booked === "true" && <FcCheckmark className="skydart-skydart-new-booking-check-icon1" />}
                                                                    {s.booked === "false" && <FcHighPriority className="skydart-skydart-new-booking-check-icon" />}
                                                                </div>
                                                                <div className="skydart_skydart__new__booking__customer">
                                                                    <h5>{s.sender.length > 23 ? s.sender.slice(0, 23) + "..." : s.sender}</h5>
                                                                    <p className="skydart-bill-hawb-header-helper">{s.sender}</p>
                                                                </div>
                                                                <div className="skydart_skydart__new__booking__hawb">
                                                                    <h5 style={{ color: '#0e4d94', textDecoration: "none" }}>{s.hawb}</h5>
                                                                    {/* <div className="skydart_skydart__new__booking_sin">
                                                                    {s.dus.length < 2 && s.sin !== "error" && <a href={s.labelURL} target="_blank">{s.sin}</a>}
                                                                    {s.dus.length < 2 && s.sin === "error" && <p style={{ color: "red" }}>{s.sin}</p>}
                                                                    <div className="skydart_DUSlabel">
                                                                        <div className="skydart_DUSlabel_container" onClick={() => {
                                                                            if (s.multi) {
                                                                                setSelectedShipment(finalShipment.map(sd => {
                                                                                    if (sd.hawb === s.hawb) {
                                                                                        sd.multi = false;
                                                                                    }
                                                                                    return sd;
                                                                                }))
                                                                            } else if (!s.multi) {
                                                                                setSelectedShipment(finalShipment.map(sd => {
                                                                                    if (sd.hawb === s.hawb) {
                                                                                        sd.multi = true;
                                                                                    }
                                                                                    return sd;
                                                                                }))
                                                                            }
                                                                        }}>
                                                                            <p>Multiple pcs</p>
                                                                        </div>
                                                                        {s.multi && <div className="skydart-dus-multi">
                                                                            <div className="skydart-dus-multi-container">
                                                                                <h5>VU Nguyen</h5>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                </div> */}
                                                                </div>
                                                                <div className="skydart_skydart__new__booking__date">
                                                                    <h5>{new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} - {s.time}</h5>
                                                                </div>
                                                                <div className="skydart_skydart__new__booking__destination" style={{ display: "flex", alignItems: "center" }}>
                                                                    <img
                                                                        style={{ marginLeft: 6 }}
                                                                        loading="lazy"
                                                                        width="18"
                                                                        src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                    {s.service !== "DUS" && <h5 style={{ marginLeft: 5 }}>{s.country}</h5>}
                                                                    {s.service === "DUS" && s.stateCode === "" && <h5 style={{ marginLeft: 5 }}>{s.state?.toUpperCase()}</h5>}
                                                                    {s.service === "DUS" && s.state === "" && <h5 style={{ marginLeft: 5 }}>{s.stateCode?.toUpperCase()}</h5>}
                                                                    {s.service === "DUS" && s.state !== "" && s.stateCode !== "" && <h5 style={{ marginLeft: 5 }}>{s.state?.toUpperCase()}</h5>}
                                                                    {s.service === "DUS" && s.state === "" && s.stateCode === "" && <h5 style={{ marginLeft: 5 }}>{s.country}</h5>}
                                                                </div>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__type">
                                                                {s.type === "WPX" && <Package size={18} />}
                                                                {s.type === "DOX" && <File size={18} />}
                                                                <h5 style={{ marginLeft: 5 }}>{s.type}</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__info">
                                                                <h5 style={{ color: 'black', marginLeft: 1 }}>{(Math.round(s.chargeable * 100) / 100).toFixed(2)}</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__info">
                                                                <h5 style={{ marginLeft: 1 }}>{s.amount} pcs</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__info" >
                                                                {s.service !== "SKD" && s.service !== "DUS" && s.service !== "DHL" && s.service !== "DHL-VN" && s.service !== "FIP" && s.service !== "UPS" && <h5 style={{ color: 'black', background: "rgb(240,240,240)", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                                {s.service === "DHL" && <h5 style={{ color: 'red', background: "yellow", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                                {s.service === "FIP" && <h5 style={{ color: 'white', background: "purple", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                                {s.service === "UPS" && <h5 style={{ color: 'rgb(247, 202, 136)', background: "brown", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                                {s.service === "SKD" && <h5 className="art" style={{ color: 'white', textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                                {s.service === "DUS" && <h5 style={{ color: 'white', background: "black", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                                {s.service === "DHL-VN" && <h5 style={{ color: 'red', background: "yellow", textAlign: 'center', borderRadius: 5, width: 40 }}>{s.service}</h5>}
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__info">
                                                                <h5 style={{ color: 'black' }}>${s.value}</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__info">
                                                                <h5 style={{ color: 'black' }}>{s.invoiceValue.length} items</h5>
                                                            </div>
                                                            <div className="skydart_skydart__new__booking__description">
                                                                <h5 style={{ fontWeight: 600, fontSize: 13 }}>{s.description.length > 65 ? s.description.slice(0, 65).replaceAll(",", "  //  ") + "..." : s.description.replaceAll(",", " // ")}</h5>
                                                                <div className="skydart-bill-hawb-header-helper1">
                                                                    {s.descArr.filter((item) => {
                                                                        return item !== "";
                                                                    }).map((item, index) => {
                                                                        return <div style={{ background: "lightyellow" }}>
                                                                            {index + 1 === 1 && <div style={{ background: "black", color: "white", padding: 5, fontSize: 13 }}>
                                                                                <p>{s.hawb}</p>
                                                                                {/* <button type="button" onClick={() => {
                                                                            translate(s.description, {
                                                                                tld: "en",
                                                                                to: "vi",
                                                                            })
                                                                        }}>Dịch</button> */}
                                                                            </div>
                                                                            }
                                                                            <div className="skd-bill-helper-option">
                                                                                <p>{index + 1}.</p>
                                                                                <p style={{ marginLeft: 5, color: "black" }}>{item}</p>
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="skydart-booking-multi-content-bottom">
                                                            <div className="skydart-booking-multi-content-bottom-sticky">
                                                                <form className="skydart-booking-multi-label" onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedShipment(finalShipment.map(sd => {
                                                                        let fromAddress = {
                                                                            name: "SKYDART",
                                                                            company: "",
                                                                            phone: "7546007133",
                                                                            street1: "4 Taft Road",
                                                                            street2: "",
                                                                            street3: "",
                                                                            city: "Totowa",
                                                                            state: "NJ",
                                                                            postal_code: "07512",
                                                                            country: "US",
                                                                            residential: false,
                                                                            tax_id: "",
                                                                        }
                                                                        let parcels = s.packageValue.map((dim, index) => {
                                                                            return {
                                                                                number: index,
                                                                                code: null,
                                                                                unit: "metric",
                                                                                weight: dim.packageWeight,
                                                                                length: dim.packageLength,
                                                                                width: dim.packageWidth,
                                                                                height: dim.packageHeight,
                                                                                dg_code: "",
                                                                            }
                                                                        })
                                                                        let toAddress = {
                                                                            name: s.contact.trim(),
                                                                            company: s.company.trim(),
                                                                            phone: s.phone.trim(),
                                                                            street1: s.address.trim(),
                                                                            street2: s.address2.trim(),
                                                                            street3: s.address3.trim(),
                                                                            city: s.city.trim(),
                                                                            state: s.state.trim(),
                                                                            postal_code: s.postal.trim(),
                                                                            country: "US",
                                                                            residential: false,
                                                                            tax_id: "",
                                                                        }
                                                                        let ref = {
                                                                            type: "customer_ref",
                                                                            value: s.removeSkd,
                                                                        }
                                                                        const newLabel = {
                                                                            method: "POST",
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                                "Authorization": "Basic TkRrPS5ZVHdYYXdsRDJRNWlSYUJIQzlsTVVwRE0zVUhoeVc1bXIxQmVVeTM0ZFpzPTo="
                                                                            },
                                                                            body: JSON.stringify({
                                                                                "date": sd.date,
                                                                                "service": "FX-HMD",
                                                                                "from": fromAddress,
                                                                                "to": toAddress,
                                                                                "type": "box",
                                                                                "parcels": parcels,
                                                                                "insurance": null,
                                                                                "signature": "none",
                                                                                "pickup": "dropoff",
                                                                                "references": [ref],
                                                                                "remarks": null,
                                                                                "domestic_options": {
                                                                                    "value": {
                                                                                        "currency": "USD",
                                                                                        "amount": 1,
                                                                                    },
                                                                                    "contents": "Books"
                                                                                },
                                                                                "international_options": null,
                                                                                "additional_options": null,
                                                                                "document_options": {
                                                                                    "return": true,
                                                                                    "label_format": "pdf",
                                                                                    "medium": "url"
                                                                                },
                                                                                "notifications": null
                                                                            })
                                                                        };


                                                                        if (sd.hawb === s.hawb) {
                                                                            sd.loading = true;
                                                                            fetch('https://api.ypn.io/v2/shipping/shipments', newLabel).then(function (response) {
                                                                                response.text().then(function (responseText) {
                                                                                    db.collection("shipments").doc(sd.hawb).update({
                                                                                        dus: JSON.parse(responseText).documents,
                                                                                        sin: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                        labelURL: JSON.parse(responseText).documents[0]?.url,
                                                                                        booked: "true",
                                                                                        bookedBy: userData.displayName,
                                                                                    }).then(() => {
                                                                                        sd.booked = "true"; // TOD
                                                                                        sd.sin = JSON.parse(responseText).documents[0]?.tracking_number;
                                                                                        sd.dus = JSON.parse(responseText).documents;
                                                                                        sd.labelURL = JSON.parse(responseText).documents[0]?.url;
                                                                                        sd.loading = false;
                                                                                        JSON.parse(responseText).documents.forEach((labels) => {
                                                                                            db.collection("label").add({
                                                                                                hawb: sd.hawb,
                                                                                                receiver: fromEmail,
                                                                                                url: labels.url,
                                                                                                amount: 1,
                                                                                                fileName: `DUS's label - ${sd.hawb} - ${index + 1} / ${JSON.parse(responseText).documents.length}`,
                                                                                                email: fromEmail,
                                                                                                date: traceTime,
                                                                                                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                                                                                            })
                                                                                        })
                                                                                        setAlertSuccess(true);
                                                                                    });
                                                                                }).catch((err) => {
                                                                                    alert(err + sd.hawb);
                                                                                    db.collection("shipments").doc(sd.hawb).update({
                                                                                        sin: "error",
                                                                                        booked: "false",
                                                                                    })
                                                                                    sd.sin = "error";
                                                                                    sd.dus = [];
                                                                                    sd.loading = false;
                                                                                    setLoadShipment(false);
                                                                                });
                                                                            })
                                                                        }
                                                                        return sd;
                                                                    }))
                                                                }}>
                                                                    <div style={{ marginTop: 2, display: "flex", alignItems: "center" }}>
                                                                        {s.dus.length === 0 && <h5 style={{ marginLeft: 7 }}>There are no label that has been placed</h5>}
                                                                        {s.dus.length !== 0 && <h5 style={{ marginLeft: 7 }}>This shipment has {s.dus.length} labels</h5>}
                                                                        {s.sin === "error" && <p style={{ fontSize: 11, color: "red", marginLeft: 5 }}>{s.sin}</p>}
                                                                        {s.loading && <div className="multi-label-loading">
                                                                            <CircularProgress size={18} />
                                                                            <p style={{ fontSize: 13, marginLeft: 5 }}>Loading... please wait</p>
                                                                        </div>}

                                                                        <div className="skydart-multi-booked">
                                                                            <Refresh style={{ fontSize: 17 }} />
                                                                            <h5>Reset form</h5>
                                                                        </div>
                                                                        <div className="skydart-multi-cancel" onClick={() => {
                                                                            setSelectedShipment(finalShipment.map(sd => {
                                                                                if (sd.hawb === s.hawb) {
                                                                                    sd.multi = false;
                                                                                }
                                                                                return sd;
                                                                            }))
                                                                        }}>
                                                                            <Clear style={{ fontSize: 17 }} />
                                                                            <h5>Cancel</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="multi-label-container-newBooking">
                                                                        {s.dus.map((element, index) => (
                                                                            <h5 onClick={() => {
                                                                                window.open(element.url);
                                                                            }}>{index + 1}/{s.dus.length} - {element.tracking_number}</h5>
                                                                        ))}
                                                                    </div>
                                                                    <div className="skydart-booking-multi-input">
                                                                        <TextField className="multi-dus-booking-input" label="Country" value={s.country} variant="outlined" size="small" InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        width="20"
                                                                                        src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                                                        srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                                                        alt=""
                                                                                    />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }} />
                                                                        <TextField required className="multi-dus-booking-input" name="company" value={s.company} label="Company" variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="contact" value={s.contact} label="Contact name" variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="address" value={s.address} label="Address info" variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="address2" value={s.address2} label="Additional address" variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="phone" value={s.phone} label="Phone number" variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="city" label="City" value={s.city} variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="state" label="State" value={s.state} variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                        <TextField required className="multi-dus-booking-input" name="postal" label="Postal code" value={s.postal} variant="outlined" size="small" onChange={e => shipmentChange(index, e)} />
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        {s.service === "DUS" && s.booked !== "true" && <div className="skydart-booking-dus-booked-button">
                                                                            <button type="submit">Booked & return label</button>
                                                                        </div>}
                                                                        <div className="skydart-booking-agent-booked">
                                                                            <div className="skydart-booking-agent">
                                                                                {s.booked === "true" && <div className="skydart-booking-agent-container">
                                                                                    <h5>Label generated</h5>
                                                                                    <Check fontSize='small' style={{ marginLeft: 5 }} />
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="skydart-new-booking-tree">
                                                                <div className="skydart-new-booking-tree-container">
                                                                    <h2 style={{ fontWeight: 600, color: "black", fontSize: 18, padding: 3, background: "rgb(245,245,245)" }}>{s.hawb}</h2>
                                                                    {labelArray.map((pack, index) => {
                                                                        return <div style={{ padding: 5, display: "flex", alignItems: "center", borderBottom: "1px solid rgb(230,230,230)" }} key={index}>
                                                                            <h5 style={{ width: 40, color: "gray" }}>{`${index + 1} / ${labelArray.length}`}</h5>
                                                                            <h5 style={{ marginLeft: 5, width: 75, fontSize: 15 }}>{pack.weight} KG</h5>
                                                                            <h5 style={{ marginLeft: 5, width: 60, fontSize: 12 }}>L {pack.length} cm</h5>
                                                                            <h5 style={{ marginLeft: 5, width: 60, fontSize: 12 }}>W {pack.width} cm</h5>
                                                                            <h5 style={{ marginLeft: 5, width: 75, fontSize: 12 }}>H {pack.height} cm</h5>
                                                                            {!pack.loading && s.sin === "error" && <button type="button" onClick={() => {
                                                                                setSelectedPackage(labelArray.map(sd => {
                                                                                    if (sd.hawb === pack.hawb) {
                                                                                        pack.loading = true;
                                                                                        let fromAddress = {
                                                                                            name: "SKYDART",
                                                                                            company: "",
                                                                                            phone: "7546007133",
                                                                                            street1: "4 Taft Road",
                                                                                            street2: "",
                                                                                            street3: "",
                                                                                            city: "Totowa",
                                                                                            state: "NJ",
                                                                                            postal_code: "07512",
                                                                                            country: "US",
                                                                                            residential: false,
                                                                                            tax_id: "",
                                                                                        }
                                                                                        let parcels = [{
                                                                                            number: index,
                                                                                            code: null,
                                                                                            unit: "metric",
                                                                                            weight: sd.weight,
                                                                                            length: sd.length,
                                                                                            width: sd.width,
                                                                                            height: sd.height,
                                                                                            dg_code: "",
                                                                                        }]
                                                                                        let toAddress = {
                                                                                            name: s.contact.trim(),
                                                                                            company: s.company.trim(),
                                                                                            phone: s.phone.trim(),
                                                                                            street1: s.address.trim(),
                                                                                            street2: s.address2.trim(),
                                                                                            street3: s.address3.trim(),
                                                                                            city: s.city.trim(),
                                                                                            state: s.state.trim(),
                                                                                            postal_code: s.postal.trim(),
                                                                                            country: "US",
                                                                                            residential: true,
                                                                                            tax_id: "",
                                                                                        }
                                                                                        let ref = {
                                                                                            type: "customer_ref",
                                                                                            value: s.value,
                                                                                        }
                                                                                        const newLabel = {
                                                                                            method: "POST",
                                                                                            headers: {
                                                                                                "Content-Type": "application/json",
                                                                                                "Authorization": "Basic TkRrPS5ZVHdYYXdsRDJRNWlSYUJIQzlsTVVwRE0zVUhoeVc1bXIxQmVVeTM0ZFpzPTo="
                                                                                            },
                                                                                            body: JSON.stringify({
                                                                                                "date": "2022-08-04",
                                                                                                "service": "FX-HMD",
                                                                                                "from": fromAddress,
                                                                                                "to": toAddress,
                                                                                                "type": "box",
                                                                                                "parcels": parcels,
                                                                                                "insurance": null,
                                                                                                "signature": "none",
                                                                                                "pickup": "dropoff",
                                                                                                "references": [ref],
                                                                                                "remarks": null,
                                                                                                "domestic_options": {
                                                                                                    "value": {
                                                                                                        "currency": "USD",
                                                                                                        "amount": 1,
                                                                                                    },
                                                                                                    "contents": "Books"
                                                                                                },
                                                                                                "international_options": null,
                                                                                                "additional_options": null,
                                                                                                "document_options": {
                                                                                                    "return": true,
                                                                                                    "label_format": "pdf",
                                                                                                    "medium": "url"
                                                                                                },
                                                                                                "notifications": null
                                                                                            })
                                                                                        };
                                                                                        fetch('https://api.ypn.io/v2/shipping/shipments', newLabel).then(function (response) {
                                                                                            response.text().then(function (responseText) {
                                                                                                db.collection("label").add({
                                                                                                    hawb: s.hawb,
                                                                                                    receiver: fromEmail,
                                                                                                    url: JSON.parse(responseText).documents[0]?.url,
                                                                                                    amount: 1,
                                                                                                    fileName: `DUS - label ${index + 1} / ${labelArray.length}`,
                                                                                                    email: fromEmail,
                                                                                                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                                                                                                    date: traceTime,
                                                                                                })
                                                                                                if (pack.packNo === 1) {
                                                                                                    db.collection("shipments").doc(s.hawb).update({
                                                                                                        dus: firebase.firestore.FieldValue.arrayUnion(({
                                                                                                            tracking_number: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                            url: JSON.parse(responseText).documents[0]?.url
                                                                                                        })),
                                                                                                        sin: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                    }).then(() => {
                                                                                                        setSelectedShipment(finalShipment.map(shipment => {
                                                                                                            let dusValue = [{
                                                                                                                tracking_number: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                                url: JSON.parse(responseText).documents[0]?.url
                                                                                                            }];
                                                                                                            if (s.hawb === shipment.hawb) {
                                                                                                                shipment.dus = [...shipment.dus, ...dusValue];
                                                                                                            }
                                                                                                            return shipment
                                                                                                        }))
                                                                                                        pack.loading = false;
                                                                                                        setAlertSuccess(true);
                                                                                                    })
                                                                                                } else if (pack.packNo !== 1) {
                                                                                                    db.collection("shipments").doc(s.hawb).update({
                                                                                                        dus: firebase.firestore.FieldValue.arrayUnion(({
                                                                                                            tracking_number: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                            url: JSON.parse(responseText).documents[0]?.url
                                                                                                        })),
                                                                                                    }).then(() => {
                                                                                                        setSelectedShipment(finalShipment.map(shipment => {
                                                                                                            let dusValue = [{
                                                                                                                tracking_number: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                                url: JSON.parse(responseText).documents[0]?.url
                                                                                                            }];
                                                                                                            if (s.hawb === shipment.hawb) {
                                                                                                                shipment.dus = [...shipment.dus, ...dusValue];
                                                                                                            }
                                                                                                            return shipment
                                                                                                        }))
                                                                                                        pack.loading = false;
                                                                                                        setAlertSuccess(true);
                                                                                                    })
                                                                                                } else if (index === labelArray.length - 1) {
                                                                                                    db.collection("shipments").doc(s.hawb).update({
                                                                                                        dus: firebase.firestore.FieldValue.arrayUnion(({
                                                                                                            tracking_number: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                            url: JSON.parse(responseText).documents[0]?.url
                                                                                                        })),
                                                                                                        booked: "true",
                                                                                                        bookedBy: userData.displayName,
                                                                                                    }).then(() => {
                                                                                                        setSelectedShipment(finalShipment.map(shipment => {
                                                                                                            let dusValue = [{
                                                                                                                tracking_number: JSON.parse(responseText).documents[0]?.tracking_number,
                                                                                                                url: JSON.parse(responseText).documents[0]?.url
                                                                                                            }];
                                                                                                            if (s.hawb === shipment.hawb) {
                                                                                                                shipment.dus = [...shipment.dus, ...dusValue];
                                                                                                                shipment.booked = "true";
                                                                                                            }
                                                                                                            return shipment
                                                                                                        }))
                                                                                                        pack.loading = false;
                                                                                                        setAlertSuccess(true);
                                                                                                    })
                                                                                                }
                                                                                            })
                                                                                        }).catch((err) => {
                                                                                            alert(err);
                                                                                            pack.loading = false;
                                                                                        })
                                                                                    }
                                                                                }))
                                                                            }
                                                                            }>GET LABEL</button>}
                                                                            {pack.loading && <div>
                                                                                <CircularProgress size={15} />
                                                                            </div>}
                                                                        </div>
                                                                    })}
                                                                    <h5 style={{ marginTop: 3, color: "red", fontSize: 10 }}>Weight counted as agent's format</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="skydart_skydart__new__booking__note">
                                                        <h5 style={{ fontWeight: 480, fontSize: 13, color: "black" }}>{s.senderNote || "pending"}</h5>
                                                    </div> */}
                                                    </div>}
                                                </>
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ display: "flex", alignItems: "center", padding: 5, marginLeft: 3.5 }}>
                                            <Avatar src={user.displayName} alt="" style={{ width: 32, height: 32 }} />
                                            <h5 style={{ marginLeft: 7, fontSize: 13, fontWeight: 600 }}>{user.displayName}</h5>
                                        </div>
                                        <h5 style={{ padding: 5, borderRadius: 5, marginLeft: 5, marginRight: 5, fontSize: 12, fontWeight: 600, background: "#effff0", color: "green" }}>TOTAL: <strong style={{ fontSize: 13, marginLeft: 3, fontWeight: 600, borderRadius: 5, padding: 3, background: "darkgreen", color: "white" }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)} KG</strong> </h5>
                                        <h5 style={{ padding: 5, borderRadius: 5, marginRight: 5, fontSize: 12, fontWeight: 600, background: "rgb(240,240,240)", color: "black" }}>DUS: <strong style={{ fontSize: 13, marginLeft: 3, padding: 3, fontWeight: 600, borderRadius: 5, background: "black", color: "white" }}>{(Math.round(totalDus * 100) / 100).toFixed(2)} KG</strong> </h5>
                                        {/* <h5 style={{ padding: 5, borderRadius: 5, marginRight: 5, fontSize: 12, fontWeight: 600, background: "rgb(247, 246, 193)", color: "red" }}>DHL: <strong style={{ fontSize: 13, marginLeft: 3, padding: 3, fontWeight: 600, borderRadius: 5, background: "yellow", color: "red" }}>{(Math.round(totalDhl * 100) / 100).toFixed(2)} KG</strong></h5>
                                        <h5 style={{ padding: 5, borderRadius: 5, marginRight: 5, fontSize: 12, fontWeight: 600, background: "rgb(214, 183, 243)", color: "black" }}>FIP: <strong style={{ fontSize: 13, marginLeft: 3, padding: 3, fontWeight: 600, borderRadius: 5, background: "purple", color: "white" }}>{(Math.round(totalFedex * 100) / 100).toFixed(2)} KG</strong></h5> */}
                                        <div className="paginate" style={{ marginLeft: "auto", padding: 5 }}>
                                            <div className="pageNumbers">
                                                <IconButton type="button" disabled={currentPage == pages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage}><ArrowLeft className="prev-btn-page" /></IconButton>
                                                {renderPageNumbers}
                                                <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage}><ArrowRight className="prev-btn-page" /></IconButton>
                                            </div>
                                        </div>
                                        {/* <h5 style={{ fontWeight: 600, fontSize: 12, marginLeft: 13, display: "flex", alignItems: "center" }}>Total Weight: <strong style={{ textDecoration: "underline", color: '#0068be', fontWeight: 700 }}>{totalWeight}KG</strong>, Total Packages: <strong style={{ fontWeight: 700, textDecoration: "underline", color: '#0068be' }}>{totalPieces}PCS</strong>, Total Value: <strong style={{ fontWeight: 700, textDecoration: "underline", color: '#0068be' }}>${totalValue}</strong>, <Package size={18} />WPX:<strong style={{ fontWeight: 700, textDecoration: "underline", color: '#0068be' }}>{totalWpx.length}</strong>, <File size={16} />DOX:<strong style={{ fontWeight: 700, textDecoration: "underline", color: '#0068be' }}>{totalDox.length}</strong>  </h5> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div >
    )
}
const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];
const usState = [
    { code: "AL", label: "Alabama" },
    { code: "AK", label: "Alaska" },
    { code: "AZ", label: "Arizona" },
    { code: "AR", label: "Arkansas" },
    { code: "CA", label: "California	" },
    { code: "CZ", label: "Canal Zone" },
    { code: "CO", label: "Colorado" },
    { code: "CT", label: "Connecticut" },
    { code: "DE", label: "Delaware" },
    { code: "DC", label: "District of Columbia" },
    { code: "FL", label: "Florida" },
    { code: "GA", label: "Georgia" },
    { code: "GU", label: "Guam" },
    { code: "HI", label: "Hawaii" },
    { code: "ID", label: "Idaho" },
    { code: "IL", label: "Illinois" },
    { code: "IA", label: "Iowa" },
    { code: "KS", label: "Kansas" },
    { code: "KY", label: "Kentucky" },
    { code: "LA", label: "Louisiana" },
    { code: "ME", label: "Maine" },
    { code: "MD", label: "Maryland" },
    { code: "MA", label: "Massachusetts" },
    { code: "MI", label: "Michigan" },
    { code: "MN", label: "Minnesota" },
    { code: "MS", label: "Mississippi" },
    { code: "MO", label: "Missouri" },
    { code: "MT", label: "Montana" },
    { code: "NE", label: "Nebraska" },
    { code: "NV", label: "Nevada" },
    { code: "NH", label: "New Hampshire" },
    { code: "NJ", label: "New Jersey" },
    { code: "NM", label: "New Mexico" },
    { code: "NY", label: "New York" },
    { code: "NC", label: "North Carolina" },
    { code: "ND", label: "North Dakota" },
    { code: "OH", label: "Ohio" },
    { code: "OK", label: "Oklahoma" },
    { code: "OR", label: "Oregon" },
    { code: "PA", label: "Pennsylvania" },
    { code: "PR", label: "Puerto Rico" },
    { code: "RI", label: "Rhode Island" },
    { code: "SC", label: "South Carolina" },
    { code: "SD", label: "South Dakota" },
    { code: "TN", label: "Tennessee" },
    { code: "TX", label: "Texas" },
    { code: "UT", label: "Utah" },
    { code: "VT", label: "Vermont" },
    { code: "VI", label: "Virgin Islands" },
    { code: "VA", label: "Virginia" },
    { code: "WA", label: "Washington" },
    { code: "WX", label: "West Virginia" },
    { code: "WI", label: "Wisconsin" },
    { code: "WY", label: "Wyoming" },
]