import React, { useState, useEffect } from 'react'
import { db } from "../firebase";
import { useHistory } from "react-router-dom";
export default function CustomerInvoice({
    fromName,
    fromAddress,
    fromAddress2,
    fromAddress3,
    fromCity,
    fromPostal,
    exporter,
    fromState,
    fromTown,
    fromEmail,
    importer,
    fromPhone,
    datePicker,
    hawb,
    fromCountry,
    toName,
    toAddress,
    toAddress2,
    toAddress3,
    city,
    usage,
    toCountry,
    term,
    otherTerm,
    transportation,
    postal,
    toPhone,
    totalPackage,
    totalGross,
    totalItemValue,
    invoiceValue,
    totalInvoiceValue,
    toState,
    toTown,
    toCity,
    toPostal,
    toEmail,
    sin,
    mst,
    eori,
    invoice,
    exportSelected
}) {
    return (
        <div className="new-invoice">
            <div className="invoice-term" style={{ margin: 15, borderRadius: 3 }}>
                <h1 style={{ fontSize: 20 }}>INVOICE</h1>
                <div className="invoice-term-header">
                    <div className="invoice-term-header-left">
                        <div className="invoice-term-header-left-option">
                            <h5 style={{ fontSize: 12 }}>INVOICE NUMBER</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5 style={{ fontSize: 12 }}>DATE OF INVOICE</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5 style={{ fontSize: 12 }}>INTERNATIONAL AIR WAYBILL NO</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5 style={{ fontSize: 12 }}>COUNTRY OF ORIGIN</h5>
                        </div>
                        <div className="invoice-term-header-left-option">
                            <h5 style={{ fontSize: 12 }}>DESTINATION</h5>
                        </div>
                        <div className="invoice-term-header-left-option" style={{ borderBottom: "none" }}>
                            <h5 style={{ fontSize: 12 }}>REASON FOR EXPORT</h5>
                        </div>
                    </div>
                    <div className="invoice-term-header-right">
                        <div className="invoice-term-header-right-option">
                            <h5 style={{ fontSize: 12 }}>{hawb}</h5>
                        </div>
                        <div className="invoice-term-header-right-option">
                            <h5 style={{ fontSize: 12 }}>{datePicker}</h5>
                        </div>
                        <div className="invoice-term-header-right-option" style={{ height: "15px" }}>
                            <h5 style={{ fontSize: 12 }}></h5>
                        </div>
                        <div className="invoice-term-header-right-option">
                            <h5 style={{ fontSize: 12 }}>{fromCountry}</h5>
                        </div>
                        <div className="invoice-term-header-right-option">
                            <h5 style={{ fontSize: 12 }}>{toCountry}</h5>
                        </div>
                        <div className="invoice-term-header-right-option" style={{ borderBottom: "none" }}>
                            <h5 style={{ fontSize: 12 }}>{usage}</h5>
                        </div>
                    </div>
                </div>
                <div className="invoice-term-shipping-info">
                    <div className="invoice-term-shipping-info-container">
                        <div className="invoice-term-shipping-info-header">
                            <h5>SHIPPER / EXPORTER</h5>
                        </div>
                        <div className="invoice-term-shipping-info-body">
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Company / Sender Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{exporter}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromAddress}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromAddress2}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">

                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromAddress3}</h5>
                                </div>
                            </div>
                            {fromCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Ward</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromState}</h5>
                                </div>
                            </div>}
                            {fromCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>District</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromTown}</h5>
                                </div>
                            </div>}
                            {fromCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromCity}</h5>
                                </div>
                            </div>}
                            {fromCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Town</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromTown}</h5>
                                </div>
                            </div>}
                            {fromCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromCity}</h5>
                                </div>
                            </div>}
                            {fromCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>State</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromState}</h5>
                                </div>
                            </div>}
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Postal Code</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromPostal}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Country</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromCountry}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Contact Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromName}</h5>
                                </div>
                            </div>

                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Contact Number</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromPhone}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Email Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{fromEmail}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ borderBottom: "none" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>ID Tax Number</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{mst}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="invoice-term-shipping-info-container">
                        <div className="invoice-term-shipping-info-header">
                            <h5>CONSIGNEE / IMPORTER</h5>
                        </div>
                        <div className="invoice-term-shipping-info-body" style={{ borderLeft: "1px solid" }}>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Company / Receiver Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{importer}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toAddress}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toAddress2}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ height: "19px" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">

                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toAddress3}</h5>
                                </div>
                            </div>
                            {toCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Ward</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toState}</h5>
                                </div>
                            </div>}
                            {toCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>District</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toTown}</h5>
                                </div>
                            </div>}
                            {toCountry === "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toCity}</h5>
                                </div>
                            </div>}
                            {toCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>City / Province</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toCity}</h5>
                                </div>
                            </div>}
                            {toCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Town</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toTown}</h5>
                                </div>
                            </div>}
                            {toCountry !== "Vietnam" && <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>State</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toState}</h5>
                                </div>
                            </div>}
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Postal Code</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toPostal}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Country</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toCountry}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Contact Name</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toName}</h5>
                                </div>
                            </div>

                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Contact Number</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toPhone}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option">
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>Email Address</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{toEmail}</h5>
                                </div>
                            </div>
                            <div className="invoice-term-shipping-info-body-option" style={{ borderBottom: "none" }}>
                                <div className="invoice-term-shipping-info-body-option-divider">
                                    <h5 style={{ fontSize: 10 }}>IOSS / TIN / EORI / ID Tax No</h5>
                                </div>
                                <div className="invoice-term-shipping-info-body-option-divider" style={{ borderRight: "none", wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                    <h5 style={{ fontSize: 10 }}>{eori}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invoice-term-full-description-of-good">
                    <div className="invoice-term-full-description-of-good-header">
                        <h5>FULL DESCRIPTION OF GOODS</h5>
                    </div>
                    <div className="invoice-term-full-description-of-good-content">
                        {invoice.map((s, index) => (
                            <>
                                <div className="invoice-full-description-header">
                                    <div className="invoice-full-description-header-option1">
                                        <h5 style={{ fontSize: 10 }}>COMMODITY / ITEM {s.itemNo}</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option">
                                        <h5 style={{ fontSize: 10 }}>PACKING</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option">
                                        <h5 style={{ fontSize: 10 }}>QUANTITY</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option">
                                        <h5 style={{ fontSize: 10 }}>UNIT</h5>
                                    </div>

                                    <div className="invoice-full-description-header-option">
                                        <h5 style={{ fontSize: 10 }}>UNIT PRICE (USD)</h5>
                                    </div>
                                    <div className="invoice-full-description-header-option" style={{ borderRight: "none" }}>
                                        <h5 style={{ fontSize: 10 }}>S.TOTAL (USD)</h5>
                                    </div>
                                </div>
                                <div className="invoice-full-description-body">
                                    <div className="invoice-full-description-body-option1">
                                        <h5 style={{ fontSize: 10 }}>{s.itemCommonity}</h5>
                                    </div>
                                    {s.itemPackType !== "Other" && <div className="invoice-full-description-body-option3">
                                        <h5 style={{ fontSize: 10 }}>{s.itemPackType}</h5>
                                    </div>}
                                    {s.itemPackType === "Other" && <div className="invoice-full-description-body-option3">
                                        <h5 style={{ fontSize: 10 }}>{s.itemPackType2}</h5>
                                    </div>}
                                    <div className="invoice-full-description-body-option">
                                        <h5 style={{ fontSize: 10 }}>{s.itemQuantity}</h5>
                                    </div>
                                    {s.itemUnit !== "Other" && <div className="invoice-full-description-body-option">
                                        <h5 style={{ fontSize: 10 }}>{s.itemUnit}</h5>
                                    </div>}
                                    {s.itemUnit === "Other" && <div className="invoice-full-description-body-option">
                                        <h5 style={{ fontSize: 10 }}>{s.itemUnit2}</h5>
                                    </div>}
                                    <div className="invoice-full-description-body-option2">
                                        <h5 style={{ fontSize: 10 }}>{(Math.round(s.itemUnitPrice * 100) / 100).toFixed(2)} USD</h5>
                                    </div>
                                    <div className="invoice-full-description-body-option2" style={{ borderRight: "none" }}>
                                        <h5 style={{ fontSize: 10 }}>{(Math.round(s.itemValue * 100) / 100).toFixed(2)} USD</h5>
                                    </div>
                                </div>
                                <div className="invoice-description-detail">
                                    <div className="invoice-description-detail-left">
                                        <h5 style={{ fontSize: 10 }}>Origin</h5>
                                        {s.itemHsCode !== "" && <h5 style={{ fontSize: 10 }}>HS Code</h5>}
                                        {s.itemBrand !== "" && <h5 style={{ fontSize: 10 }}>Brand Name</h5>}
                                        {s.itemIngredients !== "" && <h5 style={{ fontSize: 10 }}>Ingredient</h5>}
                                        {s.itemPurpose !== "" && <h5 style={{ fontSize: 10 }}>Purpose</h5>}
                                        {s.itemManufact !== "" && <h5 style={{ fontSize: 10 }}>Manufacturer</h5>}
                                        {s.itemManufactAddress !== "" && <h5 style={{ fontSize: 10 }}>Manufacturer Address</h5>}
                                        {s.itemFda !== "" && <h5 style={{ fontSize: 10 }}>FDA Registration No</h5>}
                                    </div>
                                    <div className="invoice-description-detail-right">
                                        <h5 style={{ fontSize: 10 }}>{s.itemCountryOrigin}</h5>
                                        {s.itemHsCode !== "" && <h5 style={{ fontSize: 10 }}>{s.itemHsCode}</h5>}
                                        {s.itemBrand !== "" && <h5 style={{ fontSize: 10 }}>{s.itemBrand}</h5>}
                                        {s.itemIngredients !== "" && <h5 style={{ fontSize: 10 }}>{s.itemIngredients}</h5>}
                                        {s.itemPurpose !== "" && <h5 style={{ fontSize: 10 }}>{s.itemPurpose}</h5>}
                                        {s.itemManufact !== "" && <h5 style={{ fontSize: 10 }}>{s.itemManufact}</h5>}
                                        {s.itemManufactAddress !== "" && <h5 style={{ fontSize: 10 }}>{s.itemManufactAddress}</h5>}
                                        {s.itemFda !== "" && <h5 style={{ fontSize: 10 }}>{s.itemFda}</h5>}
                                    </div>
                                </div>

                            </>
                        ))}
                    </div>
                    <div className="invoice-value-container">
                        <div className="invoice-value-container-left">
                            <h5 style={{ fontSize: 12 }}>Total Value Of Goods</h5>
                            <h5 style={{ fontSize: 12 }}>Transport Cost Under <strong>{term}</strong> Term</h5>
                            <h5 style={{ fontSize: 12 }}>Total Value Of Invoice</h5>
                            <h5 style={{ fontSize: 12 }}>Total Number Of Package</h5>
                            <h5 style={{ fontSize: 12 }} > Total Weight (KG)</h5>
                        </div>
                        <div className="invoice-value-container-right">
                            <div className="invoice-value-container-right-option">
                                <h5 style={{ fontSize: 12 }}>{(Math.round(totalItemValue * 100) / 100).toFixed(2)} USD</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5 style={{ fontSize: 12 }}>{(Math.round(transportation * 100) / 100).toFixed(2) || "0.00"} USD</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5 style={{ fontSize: 12 }}>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5 style={{ fontSize: 12 }}>{totalPackage} PCS</h5>
                            </div>
                            <div className="invoice-value-container-right-option">
                                <h5 style={{ fontSize: 12 }}>{(Math.round(totalGross * 100) / 100).toFixed(2)} KGS</h5>
                            </div>
                        </div>
                    </div>
                    <div className="invoice-value-text-last-content">
                        {term !== "Other" && <div className="invoice-value-text-primary">
                            <h5 style={{fontSize: 11}}>I <strong>{fromName}</strong>.Declare that the articles here in specified are to the best of my knowledgeand and belief
                                Shipment was exported from VietNam under <strong>{term}</strong> term with the true quantity, prices, value and manufacturer <strong>{usage}</strong>.</h5>
                        </div>}
                        {term === "Other" && <div className="invoice-value-text-primary">
                            <h5 style={{fontSize: 11}}>I <strong>{fromName}</strong>.Declare that the articles here in specified are to the best of my knowledgeand and belief
                                Shipment was exported from VietNam under <strong>{otherTerm}</strong> term with the true quantity, prices, value and manufacturer <strong>{usage}</strong>.</h5>
                        </div>}
                    </div>

                </div>
            </div>
        </div >
    )
}
