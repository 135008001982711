import React, { useState, useEffect } from 'react'
import "./Tracing.css"
import { useParams, useHistory } from 'react-router-dom'
import { db } from "../firebase";
import { Add, ArrowBack, ArrowDropDown, ArrowDropUp, Build, Info, Print, Publish } from '@material-ui/icons';
import { TextField, Paper, StepContent, Typography, Button, Box, Step, StepLabel, Stepper, CircularProgress } from '@material-ui/core';
import firebase from "firebase";
import axios from "axios";
export default function Tracing({ user }) {
    const [loading, setLoading] = useState(false);
    const { hawb } = useParams();
    const [stats, setStats] = useState([]);
    const [lastUpdate, setLastUpdate] = useState([]);
    const [resultTracing, setResultTracing] = useState([]);
    const [location, setLocation] = useState([]);
    const [emptyResult, setEmptyResult] = useState(false);
    const [displayNote, setDisplayNote] = useState(false);
    const [currentStatus, setCurrentStatus] = useState("");
    const [statusTime, setStatusTime] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [filter, setFilter] = useState("");
    const [sin, setSin] = useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [hawbResult, setHawbResult] = useState([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const steps = [
        {
            label: 'Select campaign settings',
            description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
            label: 'Create an ad group',
            description:
                'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'Create an ad',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Create an ad group1',
            description:
                'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'Create an ad1',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
    ];
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const history = useHistory();
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${year}-${month}-${date}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, [])
    const trackArray = hawb.split(/[,]+/);
    useEffect(() => {
        setLoading(true);
        db.collection("shipments").where("hawb", "in", trackArray).get().then((snapshot) => {
            setHawbResult(snapshot.docs.map(doc => doc.data()));
            const sinArray = snapshot.docs.map(doc => doc.data().sin);
            const statArray = snapshot.docs.map(doc => doc.data().desc);
            setStats(statArray[0]);
            setSin(sinArray[0]);
            setLoading(false);
        }).then(() => {
            const options = {
                method: 'GET',
                url: 'https://api-eu.dhl.com/track/shipments',
                params: { trackingNumber: sin },
                headers: { 'DHL-API-Key': 'FwexXAXennaJZrqGGTBgP1CwSGjaaxRB' }
            };
            axios.request(options).then(function (response) {
                console.log(response.data);
                setResultTracing(response.data.shipments[0].events);
                setCurrentStatus(response.data.shipments[0].status.description);
                setStatusTime(response.data.shipments[0].status.timestamp);
            }).catch(function (error) {
                alert(error);
            });
        })
    }, [])
    function convertDate(d){
        var parts = d.split(" ");
        var months = {Jan: "01",Feb: "02",Mar: "03",Apr: "04",May: "05",Jun: "06",Jul: "07",Aug: "08",Sep: "09",Oct: "10",Nov: "11",Dec: "12"};
        return parts[3]+"-"+months[parts[1]]+"-"+parts[2];
       }

    const mappingData = stats.concat(resultTracing);




    return (
        <>
            <div className="tracing">
                {loading && <>
                    <div className="tracking-loading">
                        <div className="tracing-loading-container">
                            <CircularProgress size={30} color="white" />
                            <p>Loading...</p>
                        </div>
                    </div>
                </>
                }
                {emptyResult && <>
                    <div className="tracking-loading">
                        <div className="empty-container">
                            <div className="empty-content">
                                <Build htmlColor="darkblue" fontSize="large" />
                                <h5>Your tracking number is not valid or shipment is already exspired.</h5>
                                <p>Tracking number is available only for 13 digits number</p>
                                <Button variant="contained" className="try-again-btn" onClick={() => history.push("/track")}>Try Again</Button>
                                <></>
                                <Button variant="contained" className="empty-create" onClick={() => history.push("/booking")}>Create A Shipment</Button>
                            </div>
                        </div>
                    </div>
                </>}
                <div className="tracing__container">
                    <div className="tracing__header">
                        <ArrowBack style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => history.goBack()} />
                        <h3>Available Tracking</h3>
                        <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Filter shipment" />
                    </div>
                    <div className="tracing__body">
                        <div className="tracing__body__left">
                            {hawbResult.filter((s) => {
                                if (s.hawb.toLowerCase().includes(filter.toLowerCase())) {
                                    return s;
                                }
                            }).map((s) => (
                                <div className={sin === s.sin ? "tracing__body__option1" : "tracing__body__option"}>
                                    <div className="tracing__body__option__left">
                                        <h5>#{s.hawb}</h5>
                                        <div className="tracing__body__option__status__box">
                                            <p style={{ fontSize: 12 }}>{s.status}</p>
                                        </div>
                                        <p className="tracing_view_detail" onClick={() => {
                                            setSin(s.sin);
                                            setStats(s.desc);
                                            setLoading(true);
                                            setTimeout(() => {
                                                const options = {
                                                    method: 'GET',
                                                    url: 'https://api-eu.dhl.com/track/shipments',
                                                    params: { trackingNumber: s.sin },
                                                    headers: { 'DHL-API-Key': 'FwexXAXennaJZrqGGTBgP1CwSGjaaxRB' }
                                                };
                                                axios.request(options).then(function (response) {
                                                    setResultTracing(response.data.shipments[0].events);
                                                    setCurrentStatus(response.data.shipments[0].status.description);
                                                    setLoading(false);
                                                }).catch(function (error) {
                                                    console.log(error);
                                                    setLoading(false);
                                                });
                                            }, 1000)
                                        }}>View shipment detail</p>
                                        <p style={{ marginTop: 5 }}>{s.totalCharge} KG</p>
                                        <div className="tracing__recipient__name">
                                            <h3>Recipient name</h3>
                                            <p>{s.toName}</p>
                                        </div>
                                    </div>
                                    <div className="tracing__body__option__right">
                                        <p style={{ marginLeft: 30 }}>Created: {s.createAt}</p>
                                        <Box sx={{ maxWidth: 500 }}>
                                            <Stepper orientation="vertical" activeStep={1}>
                                                <Step>
                                                    <StepLabel>
                                                        Pick Up
                                                        <Typography style={{ color: "darkblue" }}>{s.fromCity} - {s.fromCountry}</Typography>
                                                    </StepLabel>
                                                </Step>
                                                <Step>
                                                    <StepLabel>
                                                        Delievery
                                                        <Typography style={{ color: "darkblue" }}>{s.toCity} - {s.toCountry}</Typography>
                                                    </StepLabel>
                                                </Step>
                                            </Stepper>

                                        </Box>
                                    </div>
                                </div>

                            ))}
                        </div>

                        <div className="tracing__body__right">
                            <div className="tracing__body__right__primary">
                                <div style={{ display: "flex", padding: 15, alignItems: "center", borderBottom: '1px solid rgba(88, 129, 218, 0.212)', justifyContent: "space-between" }}>
                                    <h3>Shipment detail</h3>
                                    <div className="tracing__print">
                                        <Print fontSize="small" />
                                        <p>Print</p>
                                    </div>
                                </div>
                                <div className="tracing__skydart__detail">
                                    <Box sx={{ maxWidth: 400, }}>
                                        <Stepper activeStep={activeStep} orientation="vertical">
                                            {mappingData.map((s, index) => (
                                                <Step key={s.description} onClick={() => setActiveStep(index)}>
                                                    <StepLabel
                                                        optional={
                                                            <Typography variant="caption">{s.timestamp || s.time}</Typography>
                                                        }
                                                    >
                                                        {s.location.address?.addressLocality || s.location}
                                                    </StepLabel>
                                                    <StepContent>
                                                        <Typography style={{ fontSize: 14 }}>{s.description?.replaceAll("DHL", "SKYDART") || s.stat}</Typography>
                                                    </StepContent>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {activeStep === steps.length && (
                                            <Paper square elevation={0} sx={{ p: 3 }} style={{ marginLeft: 15 }}>
                                                <Typography>All steps completed - you&apos;re finished</Typography>
                                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                                    Reset
                                                </Button>
                                            </Paper>
                                        )}
                                    </Box>
                                </div>
                            </div>
                            {/* <div className="tracing__body__right__secondary">
                                <h3>Shipment detail</h3>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
