import React, { useState, useEffect, useRef } from 'react'
import './Login.css';
import { useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import { auth } from './firebase';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { AiOutlineMail, RiLockPasswordLine, FcSupport, VscSignIn, VscDebugRestart } from "react-icons/all"
import { ArrowDropDown, ChevronRight, Clear, ContactSupportTwoTone, Language, Warning } from '@material-ui/icons';
export default function LoginVn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectLang, setSelectLang] = useState(false);
    const history = useHistory("");
    const signIn = () => {
        auth
            .signInWithEmailAndPassword(email, password)
            .then(auth => {
                history.push("/");
            })
            .catch(error => {
                setError(true);
            });

    }
    const logIn = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            signIn();
            setLoading(false);
        }, 1200)
    }
    let langRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!langRef.current.contains(e.target)) {
                setSelectLang(false);

            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    function createAccount() {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 500);
        setTimeout(function () { history.push("/registration/vn") }, 500);
    }
    function upgradeAccount() {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 500);
    }
    function forgotPassword() {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 500);
    }

    const renderLang = () => {
        setSelectLang(true);
    }

    const makh = "MAKH";




    return (
        <div className="login">
            {loading && <div className="loading-screen">
                <div className="loading-circle">
                    <CircularProgress fontSize="small" />
                </div>
            </div>}
            {error && <div className="loading-screen">
                <div className="info-alert">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Warning fontSize="large" style={{ color: "red" }} />
                        <div>
                            <p style={{ fontWeight: 600, marginLeft: "5px" }}>Invalid Sign In Information</p>
                            <p style={{ fontWeight: 600, marginLeft: "5px" }}>Wrong Email Or Password</p>
                        </div>
                    </div>
                    <Button variant="contained" type="button" startIcon={<FcSupport />} className="retry-btn" onClick={() => setError(false)}>Try Again</Button>
                </div>
            </div>}
            <div className="login__container">
                <div className="login__header">
                    <img src="https://i.imgur.com/L6PnhXq.png" alt="logo" />
                    <h5>Skydart Express</h5>
                    <h3>Đăng Nhập Với Tài Khoản Skydart Của Bạn</h3>
                </div>
                <form className="login__info">
                    <TextField
                        className="login-text-field"
                        label="Email Address"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        className="login-text-field"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        type="password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <RiLockPasswordLine fontSize="large" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div style={{ marginLeft: "20px", width: "90%" }}>
                        <p style={{ fontSize: "12px", fontWeight: 600, marginTop: "10px", color: "gray" }}>Chào mừng tới với Skydart Express! Nếu bạn là khách hàng mới, hãy tạo tài khoản để bắt đầu</p>
                        <p style={{ fontSize: "12px", fontWeight: 600, color: "gray" }}>Nếu bạn gặp bất cứ vấn đề gì về xác thực thông tin và tạo tài khoản, những lựa chọn bên dưới có thể hữu ích dành cho bạn </p>
                    </div>
                    <div className="sign-in-footer">
                        <button type="button" className="sign-up-btn" onClick={createAccount}>Tạo Tài Khoản</button>
                        <button type="button" onClick={logIn} className="login-btn">Đăng Nhập</button>
                    </div>
                </form>
            </div>
            <div className="login__footer" ref={langRef}>
                <div className="login__footer__left" onClick={renderLang}>
                    <Language />
                    <h5>English</h5>
                    <ArrowDropDown />
                </div>
                {selectLang && <div className="login-lang">
                    <div className="login-lang-option" onClick={() => setSelectLang(false)}>
                        <h5>Tiếng Việt</h5>
                    </div>
                    <div className="login-lang-option" onClick={() => history.push("/")}>
                        <h5>English</h5>
                    </div>
                </div>}

                <div className="login__footer__right">
                    <ContactSupportTwoTone />
                    <h5>Contact Us</h5>
                </div>
            </div>

        </div>
    )
}