import React, { useState, useEffect, useRef } from 'react'
import "./StaffBooking.css";
import { Add, ArrowDownward, CallReceived, ArrowRightAlt, LocationOnOutlined, Refresh, Clear, Crop, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, VisibilityTwoTone, Phone, Info, Public, PrintDisabled, PrintOutlined, Publish, HelpOutlineOutlined } from '@material-ui/icons';
import { db } from '../firebase';
import { MdOutlineClear } from "react-icons/md";
import { RiArrowDownSFill } from "react-icons/ri";
import axios from "axios";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import { GrDocument } from "react-icons/gr";
import { HiOutlineDocument } from "react-icons/hi";
import { IoMdAddCircle } from "react-icons/io";
import { GiCommercialAirplane } from "react-icons/gi";
import { BsBoxSeam } from "react-icons/bs";
import { SiReasonstudios } from "react-icons/si";
import firebase from "firebase";
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { CircularProgress } from "@material-ui/core";
import { FaFileInvoice } from "react-icons/fa";
import { FcCheckmark, FcHighPriority, FcOk } from 'react-icons/fc';
import { VscDebugRestart, VscNotebookTemplate } from "react-icons/vsc";
import { MdSimCardDownload } from "react-icons/md";
import { CSVLink } from "react-csv";
export default function StaffBooking({ user }) {
    const [displayType, setDisplayType] = useState(false);
    const [displayService, setDisplayService] = useState(false);
    const [displayReason, setDisplayReason] = useState(false);
    const [displayLabel, setDisplayLabel] = useState(true);
    const [displayInvoice, setDisplayInvoice] = useState(false);
    const [displayShippingMark, setDisplayShippingMark] = useState(false);
    const [useTemplate, setUseTemplate] = useState(true);
    const [fileAttached, setFileAttached] = useState(false);
    const [attachLoading, setAttachLoading] = useState(false);
    const [validBook, setValidBook] = useState(false);
    const [rapidBooking, setRapidBooking] = useState(true);
    const [displayCountry, setDisplayCountry] = useState(false);
    const [displayDraft, setDisplayDraft] = useState(false);
    const [displayState, setDisplayState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [shipmentList, setShipmentList] = useState([]);
    const [bookConfirm, setBookConfirm] = useState(false);
    const [displayAttach, setDisplayAttach] = useState(false);
    const [doxErr, setDoxErr] = useState(false);
    const [date, setDate] = useState("");
    const history = useHistory();
    const [packageValue, setPackageValue] = useState([{
        packageLength: 0,
        packageWidth: 0,
        packageHeight: 0,
        packageWeight: 0,
        packageCharge: 0,
        packNo: 1,
    }]);
    const [invoiceValue, setInvoiceValue] = useState([{
        itemCommonity: "",
        itemCountryOrigin: "Vietnam",
        itemManufactAddress: "",
        itemPackType: "",
        itemPackType2: "",
        itemHsCode: "",
        itemPnc: "",
        itemUnit: "",
        itemPurpose: "",
        itemManufact: "",
        itemUnitPrice: 0,
        itemQuantity: 0,
        itemValue: 0,
        itemBrand: "",
        itemIngredients: "",
        itemNo: 1,
        itemFda: "",
        itemUnit2: "",
    }]);
    const [type, setType] = useState("WPX");
    const [service, setService] = useState("");
    const [hawb, setHawb] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [datePicker, setDatePicker] = useState("");
    const [proxyService, setProxyService] = useState("");
    const [reason, setReason] = useState("");
    const [eamService, setEamService] = useState("");
    const [input, setInput] = useState("");
    const [term, setTerm] = useState("");
    const [eori, setEori] = useState("");
    const [code, setCode] = useState("");
    const [transport, setTransport] = useState(0);
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [countryData, setCountryData] = useState([]);
    const [companyInput, setCompanyInput] = useState("");
    const [countryInput, setCountryInput] = useState("");
    const [addressInput, setAddressInput] = useState("");
    const [addressInput2, setAddressInput2] = useState("");
    const [addressInput3, setAddressInput3] = useState("");
    const [townInput, setTownInput] = useState("");
    const [cityInput, setCityInput] = useState("");
    const [stateInput, setStateInput] = useState("");
    const [postalInput, setPostalInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [contactInput, setContactInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [description, setDescription] = useState("");
    const [doxWeight, setDoxWeight] = useState(0.5);
    const [envelope, setEnvelope] = useState(1);
    const [rated, setRated] = useState([]);
    const [direct, setDirect] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [rate, setRate] = useState(0);
    const [doxRate, setDoxRate] = useState(0);
    const [displayFormat, setDisplayFormat] = useState(false);
    const [customerArray, setCustomerArray] = useState([]);
    const [userData, setUserData] = useState([]);
    const [exporter, setExporter] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [fromState, setFromState] = useState("");
    const [fromTown, setFromTown] = useState("");
    const [fromAddress, setFromAddress] = useState("");
    const [fromAddress2, setFromAddress2] = useState("");
    const [fromAddress3, setFromAddress3] = useState("");
    const [fromPostal, setFromPostal] = useState("");
    const [fromPhone, setFromPhone] = useState("");
    const [fromName, setFromName] = useState("");
    const [controller, setController] = useState("");
    const [uid, setUid] = useState("");
    const [makh, setMakh] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [plan, setPlan] = useState("");
    const [displayCustomer, setDisplayCustomer] = useState(false);
    const [accountInput, setAccountInput] = useState("");
    const [attachType, setAttachType] = useState("");
    const [manifest, setManifest] = useState([]);
    const [fsc, setFsc] = useState(0);
    const [dhlFsc, setDhlFsc] = useState(0);
    const [fedexFsc, setFedexFsc] = useState(0);
    const [upsFsc, setUpsFsc] = useState(0);
    const [board, setBoard] = useState("");
    const [skdFsc, setSkdFsc] = useState(0);
    useEffect(() => {
        const refNumber = "SKD";
        const newDates = new Date();
        const dates = (newDates.getDate() < 10 ? "0" : "") + newDates.getDate();
        const months = ((newDates.getMonth() + 1) < 10 ? "0" : "") + (newDates.getMonth() + 1);
        const years = newDates.getFullYear().toString().charAt(2);
        const yearLength = newDates.getFullYear().toString().charAt(3);
        const max = 900000;
        const wrapper = (`${years}${yearLength}${months}${dates}`);
        const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
        setHawb(txt);
    }, [])
    useEffect(() => {
        // axios.get("https://countriesnow.space/api/v0.1/countries/").then((res) => {
        //   setCountryData(res.data.data);
        // })
    }, [])
    useEffect(() => {
        db.collection("users").where("controller", "==", user.displayName).get().then((snapshot) => {
            setCustomerArray(snapshot.docs.map(doc => doc.data()));
        })
    }, [])

    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        setDatePicker(`${year}-${month}-${date}`);
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${date} ${monthName} ${year}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, []);

    // console.log(input.split(/[/]+/));
    const inputArray = input.split(/\r?\n/);
    const handleType = () => {
        if (!displayType) {
            setDisplayType(true);
        } else {
            setDisplayType(false);
        }
    }
    const handleService = () => {
        if (!displayService) {
            setDisplayService(true);
        } else {
            setDisplayService(false);
        }
    }
    const handleReason = () => {
        if (!displayReason) {
            setDisplayReason(true);
        } else {
            setDisplayReason(false);
        }
    }
    // const inputArray = input.split(/[#]+/);

    const handleWPX = () => {
        if (type === "WPX") {
            return;
        } else {
            setType("WPX");
            setDisplayType(false);
        }
    }
    const handleDOX = () => {
        if (type === "DOX") {
            return;
        } else {
            setType("DOX");
            setDisplayType(false);
        }
    }
    useEffect(() => {
        db.collection("surcharge").doc("skd-surcharge").onSnapshot((doc) => {
            if (doc.exists) {
                setDhlFsc(doc.data().dhl);
                setFedexFsc(doc.data().fedex);
                setUpsFsc(doc.data().ups);
            }
        })
    }, [])
    const importer = inputArray[0]?.trim() || companyInput;
    const addressContainer = inputArray[1]?.trim() || "";
    const address = addressContainer.slice(0, 30) || addressInput;
    const address2 = addressContainer.slice(31, 61) || addressInput2;
    const address3 = addressContainer.slice(62, 92) || addressInput3;
    const town = inputArray[2]?.trim() || townInput;
    const city = inputArray[3]?.trim() || cityInput;
    const state = inputArray[4]?.trim() || stateInput;
    const postal = inputArray[5]?.trim() || postalInput;
    const country = inputArray[6]?.trim() || countryInput;
    const phone = inputArray[7]?.trim() || phoneInput;
    const contact = inputArray[8]?.trim() || contactInput;
    const email = inputArray[9]?.trim() || emailInput;
    useEffect(() => {
        if (direct === "us") {
            if (state !== "" && code !== "" && service !== "" && country !== "" && importer !== "" && address !== "" && address2 !== "" && town !== "" && city !== "" && postal !== "" && contact !== "" && phone !== "") {
                setValidBook(true);
            } else {
                setValidBook(false);
            }
        } else if (direct !== "us") {
            if (code !== "" && service !== "" && country !== "" && importer !== "" && address !== "" && address2 !== "" && town !== "" && city !== "" && postal !== "" && contact !== "" && phone !== "") {
                setValidBook(true);
            } else {
                setValidBook(false);
            }
        }

    })

    let addFormFields = () => {
        let x = packageValue.length;
        setPackageValue([...packageValue, { packNo: x + 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0.00, packageCharge: 0 }])
    };
    let removeFormField = (index, e) => {
        if (packageValue.length === 1) {
            return;
        } else {
            setPackageValue(packageValue.filter((v, i) => i !== index));
        }
    }
    let removeInvoice = (index, e) => {
        if (invoiceValue.length === 1) {
            return;
        } else {
            setInvoiceValue(invoiceValue.filter((v, i) => i !== index));
        }
    }
    let addCarton = () => {
        let x = invoiceValue.length;
        setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: "", itemIngredients: "", itemUnit2: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }])
    }
    const resetPacking = () => {
        setPackageValue([{ packNo: 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0, packageCharge: 0 }]);
    }
    const resetInvoice = () => {
        setInvoiceValue([{ itemNo: 1, itemUnit2: "", itemBrand: "", itemIngredients: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }]);
    }

    const finalCharge = packageValue.reduce((a, v) => +a + +v.packageCharge, 0);
    const finalGross = packageValue.reduce((a, v) => +a + +v.packageWeight, 0);
    const finalAttachInvoiceValue = invoiceValue.map((s) => {
        return {
            itemValue: s.itemQuantity * s.itemUnitPrice,
        }
    });
    const finalInvoiceValue = finalAttachInvoiceValue.reduce((a, v) => a = +a?.toFixed(2) + +v.itemValue?.toFixed(2), 0);
    const totalInvoiceValue = +finalInvoiceValue + +transport;
    const headers = [
        { label: "itemNo", key: "itemNo" },
        { label: "itemCommonity", key: "itemCommonity" },
        { label: "itemPackType", key: "itemPackType" },
        { label: "itemIngredients", key: "itemIngredients" },
        { label: "itemUsage", key: "itemUsage" },
        { label: "itemCountryOrigin", key: "itemCountryOrigin" },
        { label: "itemManufact", key: "itemManufact" },
        { label: "itemManufactAddress", key: "itemManufactAddress" },
        { label: "itemHsCode", key: "itemHsCode" },
        { label: "itemFda", key: "itemFda" },
        { label: "itemQuantity", key: "itemQuantity" },
        { label: "itemUnit", key: "itemUnit" },
        { label: "itemUnitPrice", key: "itemUnitPrice" },
    ]
    // console.log(finalInvoiceValue);
    // console.log(finalAttachInvoiceValue);
    const exportInvoice = {
        data: invoiceValue,
        headers: headers,
        filename: `${hawb} invoice`,
    }
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    function niceBytes(x) {
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }
    let typeRef = useRef();
    let serviceRef = useRef();
    let reasonRef = useRef();
    let countryRef = useRef();
    let accountRef = useRef();
    let stateRef = useRef();
    let bookingRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!bookingRef.current.contains(e.target)) {
                setBookConfirm(false);
            }
            if (!typeRef.current.contains(e.target)) {
                setDisplayType(false);
            }
            if (!serviceRef.current.contains(e.target)) {
                setDisplayService(false);
            }
            if (!reasonRef.current.contains(e.target)) {
                setDisplayReason(false);
            }
            if (!countryRef.current.contains(e.target)) {
                setDisplayCountry(false);
            }
            if (!accountRef.current.contains(e.target)) {
                setDisplayCustomer(false);
            }
            if (!stateRef.current.contains(e.target)) {
                setDisplayState(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    function removeAccents(str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }

    // const getService = db.collection(service);

    const onBookShipment = (e) => {
        e.preventDefault();
        setLoading(true);
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            db.collection("shipments").doc(hawb).set({
                account: "786009",
                currency: "USD",
                service: service || "SKD",
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                perform: "New",
                status: "Pending",
                id: (Math.random() + 1).toString(36).substring(7),
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    stat: "Shipment booked, waiting for confirmation by Skydart ",
                    time: datePicker + "T" + dateTime + ":00",
                    location: "VIETNAM - VIETNAM - HCM",
                })),
                controller: userData.controller || "Pending",
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                important: "",
                airline: "Pending",
                mawb: "Pending",
                etd: "Pending",
                agent: "Pending",
                subStatus: "Pending",
                sin: "Null",
                note: "Null",
                perform: "Null",
                code: code,
                hawb,
                uid: user.uid,
                makh: makh,
                paymentStatus: "",
                shipmentType: type,
                fromCountry: "Vietnam",
                exporter: removeAccents(exporter),
                fromCity: removeAccents(fromCity),
                fromState: removeAccents(fromState),
                fromTown: removeAccents(fromTown),
                fromAddress: removeAccents(fromAddress),
                fromAddress2: removeAccents(fromAddress2),
                fromAddress3: removeAccents(fromAddress3),
                value: totalInvoiceValue,
                fromPostal: fromPostal,
                fromPhone: fromPhone,
                fromName: removeAccents(fromName),
                toPhone2: "",
                fromEmail: fromEmail,
                dateTime: new Date(),
                toCountry: country,
                importer,
                toCity: removeAccents(city),
                toState: removeAccents(state),
                toTown: removeAccents(town),
                toAddress: removeAccents(address),
                toAddress2: removeAccents(address2),
                toAddress3: removeAccents(address3),
                stateCode: stateCode,
                toPostal: postal,
                toPhone: phone,
                toName: removeAccents(contact),
                toEmail: email,
                createAt: datePicker,
                time: dateTime,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                reweight: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                weight: type === "DOX" ? doxWeight : finalGross.toFixed(2),
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                eori,
                otherTerm: "",
                description: type === "DOX" ? "Document" : arr + ",",
                term,
                transportation: transport,
                totalItemValue: type === "WPX" ? finalInvoiceValue.toFixed(2).toString() : "0.00",
                totalInvoiceValue: type === "WPX" ? totalInvoiceValue.toFixed(2).toString() : "0.00",
                usage: reason,
                rate: type === "WPX" ? rate?.toFixed(2) : doxRate?.toFixed(2),
                fsc: "0",
                fscValue: "0.00",
                addressCorrection: "0.00",
                direct: direct,
                surcharge: "0.00",
                surcharge2: "0.00",
                surcharge3: "0.00",
                remote: "0.00",
                vat: "No",
                vatValue: "0.00",
                eamService: eamService,
                plan: plan || "Pending",
                booked: "false",
            }).then(() => {
                setLoading(false);
                history.push("/");
                setBookConfirm(true);
            })
        }
    }
    const onSaveShipment = () => {
        setLoading(true);
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            setTimeout(() => {
                db.collection("shipments").doc(hawb).set({
                    account: "786009",
                    currency: "USD",
                    service: service || "SKD",
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    perform: "Draft",
                    status: "Editing",
                    id: (Math.random() + 1).toString(36).substring(7),
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        stat: "Shipment booked, waiting for confirmation by Skydart ",
                        time: datePicker + "T" + dateTime + ":00",
                        location: "VIETNAM - VIETNAM - HCM",
                    })),
                    controller: userData.controller || "Pending",
                    eamFormat: type === "DOX" ? "DOX" : "NDX",
                    important: "",
                    airline: "Pending",
                    mawb: "Pending",
                    etd: "Pending",
                    direct: direct,
                    agent: "Pending",
                    subStatus: "Pending",
                    sin: "Null",
                    note: "Null",
                    code: code,
                    hawb,
                    uid: user.uid,
                    makh: userData.makh,
                    paymentStatus: "",
                    shipmentType: type,
                    fromCountry: userData.country,
                    exporter: userData.exporter,
                    fromCity: userData.city,
                    fromState: userData.state,
                    fromTown: userData.town,
                    fromAddress: userData.address,
                    fromAddress2: userData.address2,
                    fromAddress3: userData.address3,
                    value: totalInvoiceValue,
                    fromPostal: userData.postal,
                    fromPhone: userData.phone,
                    fromName: userData.displayName,
                    toPhone2: "",
                    fromEmail: userData.email,
                    dateTime: new Date(),
                    toCountry: country,
                    importer,
                    toCity: city,
                    toState: state,
                    toTown: town,
                    toAddress: address,
                    toAddress2: address2,
                    toAddress3: address3,
                    toPostal: postal,
                    toPhone: phone,
                    toName: contact,
                    toEmail: email,
                    createAt: datePicker,
                    time: dateTime,
                    invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                    packageValue: type === "DOX" ? "Pending" : packageValue,
                    totalCharge: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                    reweight: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                    weight: type === "DOX" ? doxWeight : finalGross.toFixed(2),
                    totalPackage: type === "DOX" ? envelope : packageValue.length,
                    totalItem: type === "DOX" ? envelope : invoiceValue.length,
                    eori,
                    otherTerm: "",
                    description: type === "DOX" ? "Document" : arr + ",",
                    term,
                    transportation: transport,
                    totalItemValue: type === "WPX" ? finalInvoiceValue.toFixed(2).toString() : "0.00",
                    totalInvoiceValue: type === "WPX" ? totalInvoiceValue.toFixed(2).toString() : "0.00",
                    usage: reason,
                    rate: "0.00",
                    fsc: fsc,
                    fscValue: "0.00",
                    addressCorrection: "0.00",
                    surcharge: "0.00",
                    surcharge2: "0.00",
                    surcharge3: "0.00",
                    remote: "0.00",
                    vat: "No",
                    vatValue: "0.00",
                    eamService: eamService,
                    plan: userData.plan || "Pending",
                    booked: "false",
                }).then(() => {
                    setLoading(false);
                    setSaveConfirm(true);
                })
            }, 200)
        }
    }

    let handleChange = (i, e) => {
        let newFormValues = [...packageValue];
        newFormValues[i][e.target.name] = e.target.value;
        setPackageValue(newFormValues);
    };
    let invoiceChange = (i, e) => {
        let newInvoiceValues = [...invoiceValue];
        newInvoiceValues[i][e.target.name] = removeAccents(e.target.value);
        setInvoiceValue(newInvoiceValues);

    }
    const processTracking = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setShipmentList(list)
        console.log(list);
    }
    // const handleUpload = (e) => {
    //   const file = e.target.files[0];
    //   if (file) {
    //     ExcelRenderer(file, (err, resp) => {
    //       if (err) {
    //         console.log(err);
    //       }
    //       else {
    //         setShipmentList(resp.rows);
    //       }
    //     });
    //   } else {
    //     return;
    //   }
    // }
    const handleUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                processTracking(data);
            };
            reader.readAsBinaryString(file);
        } else {
            return;
        }

    }
    const bookingHeaders = [
        { label: "service", key: "" },
        { label: "importer", key: "" },
        { label: "toName", key: "" },
        { label: "toAddress", key: "" },
        { label: "toAddress2", key: "" },
        { label: "toAddress3", key: "" },
        { label: "toTown", key: "" },
        { label: "toCity", key: "" },
        { label: "toCountry", key: "" },
        { label: "toPostal", key: "" },
        { label: "toPhone", key: "" },
        { label: "amount", key: "" },
        { label: "chargeable", key: "" },
        { label: "shipmentType", key: "" },
        { label: "toEmail", key: "" },
        { label: "reason", key: "" },
        { label: "tax_vat_id", key: "" },
        { label: "", key: "slash" },
        { label: "item number", key: "" },
        { label: "qty", key: "" },
        { label: "Qty unit", key: "" },
        { label: "Item description", key: "" },
        { label: "Unit Price", key: "" },
        { label: "net weight", key: "" },
        { label: "gross weight", key: "" },
        { label: "country manufactured", key: "" },
        { label: "hs code", key: "" },
    ]
    const exportFormat = {
        data: [],
        headers: bookingHeaders,
        filename: `BookingTemplate_skd`,
    }
    const getRate = (e) => {
        e.preventDefault();
        setLoading(true);
        if (plan === "FWD") {
            db.collection(board).doc(country).get().then((doc) => {
                if (doc.exists) {
                    if (type === "DOX") {
                        if (doxWeight === 0) {
                            setDoxRate(0);
                        } else if (doxWeight === 0.5) {
                            setDoxRate(doc.data().doxHalf);
                        } else if (doxWeight === 1) {
                            setDoxRate(doc.data().doxOne);
                        } else if (doxWeight === 1.5) {
                            setDoxRate(doc.data().doxOnehalf);
                        } else if (doxWeight === 2) {
                            setDoxRate(doc.data().doxTwo);
                        }
                    }
                    if (service === "DHL") {
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                                setRate(0);
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge === 21) {
                                setRate(doc.data().twentyone);
                            } else if (finalCharge === 21.5) {
                                setRate(doc.data().twentyoneHalf);
                            } else if (finalCharge === 22) {
                                setRate(doc.data().twentytwo);
                            } else if (finalCharge === 22.5) {
                                setRate(doc.data().twentytwoHalf);
                            } else if (finalCharge === 23) {
                                setRate(doc.data().twentythree);
                            } else if (finalCharge === 23.5) {
                                setRate(doc.data().twentythreeHalf);
                            } else if (finalCharge === 24) {
                                setRate(doc.data().twentyfour);
                            } else if (finalCharge === 24.5) {
                                setRate(doc.data().twentyfourHalf);
                            } else if (finalCharge === 25) {
                                setRate(doc.data().twentyfive);
                            } else if (finalCharge === 25.5) {
                                setRate(doc.data().twentyfiveHalf);
                            } else if (finalCharge === 26) {
                                setRate(doc.data().twentysix);
                            } else if (finalCharge === 26.5) {
                                setRate(doc.data().twentysixHalf);
                            } else if (finalCharge === 27) {
                                setRate(doc.data().twentyseven);
                            } else if (finalCharge === 27.5) {
                                setRate(doc.data().twentysevenHalf);
                            } else if (finalCharge === 28) {
                                setRate(doc.data().twentyeight);
                            } else if (finalCharge === 28.5) {
                                setRate(doc.data().twentyeightHalf);
                            } else if (finalCharge === 29) {
                                setRate(doc.data().twentynine);
                            } else if (finalCharge === 29.5) {
                                setRate(doc.data().twentynineHalf);
                            } else if (finalCharge === 30) {
                                setRate(doc.data().thirty);
                            } else if (finalCharge > 30 && finalCharge < 71) {
                                setRate(doc.data().small * finalCharge);
                            } else if (finalCharge > 70 && finalCharge < 301) {
                                setRate(doc.data().medium * finalCharge);
                            } else if (finalCharge > 300 && finalCharge < 1000) {
                                setRate(doc.data().large * finalCharge);
                            }
                        }
                    } else if (service === "FIP") {
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge > 20 && finalCharge < 45) {
                                setRate(doc.data().small * finalCharge);
                            } else if (finalCharge > 44 && finalCharge < 71) {
                                setRate(doc.data().medium * finalCharge);
                            } else if (finalCharge > 70 && finalCharge < 100) {
                                setRate(doc.data().large * finalCharge);
                            } else if (finalCharge > 99 && finalCharge < 300) {
                                setRate(doc.data().extraLarge * finalCharge);
                            } else if (finalCharge > 299 && finalCharge < 499) {
                                setRate(doc.data().extraLarge2 * finalCharge);
                            } else if (finalCharge > 499 && finalCharge < 1000) {
                                setRate(doc.data().extraLarge3 * finalCharge);
                            }
                        }

                    } else if (service === "UPS") {
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                                setRate(0);
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge === 21) {
                                setRate(doc.data().twentyone);
                            } else if (finalCharge === 21.5) {
                                setRate(doc.data().twentyoneHalf);
                            } else if (finalCharge === 22) {
                                setRate(doc.data().twentytwo);
                            } else if (finalCharge === 22.5) {
                                setRate(doc.data().twentytwoHalf);
                            } else if (finalCharge === 23) {
                                setRate(doc.data().twentythree);
                            } else if (finalCharge === 23.5) {
                                setRate(doc.data().twentythreeHalf);
                            } else if (finalCharge === 24) {
                                setRate(doc.data().twentyfour);
                            } else if (finalCharge === 24.5) {
                                setRate(doc.data().twentyfourHalf);
                            } else if (finalCharge === 25) {
                                setRate(doc.data().twentyfive);
                            } else if (finalCharge === 25.5) {
                                setRate(doc.data().twentyfiveHalf);
                            } else if (finalCharge === 26) {
                                setRate(doc.data().twentysix);
                            } else if (finalCharge === 26.5) {
                                setRate(doc.data().twentysixHalf);
                            } else if (finalCharge === 27) {
                                setRate(doc.data().twentyseven);
                            } else if (finalCharge === 27.5) {
                                setRate(doc.data().twentysevenHalf);
                            } else if (finalCharge === 28) {
                                setRate(doc.data().twentyeight);
                            } else if (finalCharge === 28.5) {
                                setRate(doc.data().twentyeightHalf);
                            } else if (finalCharge === 29) {
                                setRate(doc.data().twentynine);
                            } else if (finalCharge === 29.5) {
                                setRate(doc.data().twentynineHalf);
                            } else if (finalCharge === 30) {
                                setRate(doc.data().thirty);
                            } else if (finalCharge > 30 && finalCharge < 45) {
                                setRate(doc.data().small * finalCharge);
                            } else if (finalCharge > 44 && finalCharge < 71) {
                                setRate(doc.data().medium * finalCharge);
                            } else if (finalCharge > 70 && finalCharge < 100) {
                                setRate(doc.data().large * finalCharge);
                            } else if (finalCharge > 99 && finalCharge < 300) {
                                setRate(doc.data().extraLarge * finalCharge);
                            } else if (finalCharge >= 300) {
                                setRate(doc.data().extraLarge2 * finalCharge);
                            }
                        }
                    } else if (service === "DUS") {
                        setFsc(0);
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                                setRate(0);
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge === 21) {
                                setRate(doc.data().twentyone);
                            } else if (finalCharge === 21.5) {
                                setRate(doc.data().twentyoneHalf);
                            } else if (finalCharge === 22) {
                                setRate(doc.data().twentytwo);
                            } else if (finalCharge === 22.5) {
                                setRate(doc.data().twentytwoHalf);
                            } else if (finalCharge === 23) {
                                setRate(doc.data().twentythree);
                            } else if (finalCharge === 23.5) {
                                setRate(doc.data().twentythreeHalf);
                            } else if (finalCharge === 24) {
                                setRate(doc.data().twentyfour);
                            } else if (finalCharge === 24.5) {
                                setRate(doc.data().twentyfourHalf);
                            } else if (finalCharge === 25) {
                                setRate(doc.data().twentyfive);
                            } else if (finalCharge === 25.5) {
                                setRate(doc.data().twentyfiveHalf);
                            } else if (finalCharge === 26) {
                                setRate(doc.data().twentysix);
                            } else if (finalCharge === 26.5) {
                                setRate(doc.data().twentysixHalf);
                            } else if (finalCharge === 27) {
                                setRate(doc.data().twentyseven);
                            } else if (finalCharge === 27.5) {
                                setRate(doc.data().twentysevenHalf);
                            } else if (finalCharge === 28) {
                                setRate(doc.data().twentyeight);
                            } else if (finalCharge === 28.5) {
                                setRate(doc.data().twentyeightHalf);
                            } else if (finalCharge === 29) {
                                setRate(doc.data().twentynine);
                            } else if (finalCharge === 29.5) {
                                setRate(doc.data().twentynineHalf);
                            } else if (finalCharge === 30) {
                                setRate(doc.data().thirty);
                            } else if (finalCharge > 30 && finalCharge < 45) {
                                setRate(doc.data().small * finalCharge);
                            } else if (finalCharge > 44 && finalCharge < 69) {
                                setRate(doc.data().medium * finalCharge);
                            } else if (finalCharge > 68 && finalCharge < 301) {
                                setRate(doc.data().large * finalCharge);
                            }
                        }
                    }
                } else {
                    setRate(0);
                    setDoxRate(0);
                    setFsc(0);
                    setLoading(false);
                    setBookConfirm(true);
                }
            }).then(() => {
                setBookConfirm(true);
                setLoading(false);
            });
        } else {
            setRate(0);
            setDoxRate(0);
            setFsc(0);
            setLoading(false);
            setBookConfirm(true);
        }

    }

    const handleFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            readExcel(file);
        } 
    }
    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
        promise.then((item) => {
            setManifest(item);
            console.log(item);
        })
        setDisplayAttach(true);
    }
    const onAttachment = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            uploadFile();
        }, 1000)
    }
    const uploadFile = () => {
        manifest.forEach((s) => {
            const refNumber = "SKD";
            const newDates = new Date();
            const dates = (newDates.getDate() < 10 ? "0" : "") + newDates.getDate();
            const months = ((newDates.getMonth() + 1) < 10 ? "0" : "") + (newDates.getMonth() + 1);
            const years = newDates.getFullYear().toString().charAt(2);
            const yearLength = newDates.getFullYear().toString().charAt(3);
            const max = 900000;
            const wrapper = (`${years}${yearLength}${months}${dates}`);
            const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
            const hawb = txt;
            axios.post("https://test.skydart.com.vn/api/create/shipment", {
                account: "786009",
                currency: "USD",
                service: s.SERVICE,
                date: date,
                hawb: attachType === "DIS" || attachType === "BILL" ? s.HAWB : hawb,
                mawb: s.MAWB,
                makh: s.ACCOUNT,
                company: s.COMPANY,
                contact: s.CONTACT,
                otherTerm: "Null",
                type: s.TYPE,
                status: s.STATUS,
                term: "Null",
                time: ":00",
                address: s.ADDRESS1,
                address2: s.ADDRESS2,
                address3: s.ADDRESS3,
                city: s.TOWN,
                country: s.COUNTRY,
                code: s.COUNTRY,
                phone: s.TELNO,
                phone2: "Null",
                postal: s.POSTCODE,
                state: "Pending",
                value: s.VALUE,
                town: s.TOWN,
                chargeable: s.WEIGHT.toString(),
                amount: s.PCS,
                totalItem: s.PCS,
                transport: "Null",
                description: s.DESCRIP,
                controller: s.CONTROLLER,
                eamFormat: s.TYPE,
                dateTime: new Date(),
                airline: "Pending",
                reweight: s.WEIGHT.toString(),
                etd: "Pending",
                agent: attachType === "DIS" ? "DIS" : "Pending",
                subStatus: "Pending",
                sin: "Null",
                sender: s.CUSTOMER,
                note: "Null",
                perform: "Null",
                fromCity: s.FROM,
                fromEmail: "Attach",
                totalValue: "0.00",
                rate: s.Rate || "0.00",
                comestic: "0.00",
                addressCorrection: s.ADDCORRECT || "0.00",
                remote: s.REMOTE || "0.00",
                surcharge: "0.00",
                surcharge2: "0.00",
                surcharge3: "0.00",
                fsc: s.FSC || 0,
                fscValue: "0.00",
                vat: s.VAT || 0,
                vatValue: "0.00",
                paymentPickup: "CRE",
                paymentAcc: "CRE",
                paymentCompany: "CRE",
                paymentStatus: "Un Paid",
                sellNote: "Pending",
                com: "0.00",
                sellNote: "Pending",
                paymentSubStatus: "Rebook",
                pack: "Pending",
                plan: s.PLAN,
            })
            setTimeout(() => {
                setLoading(false);
                history.push("/shipments");
            }, 2000)
        })
    }
    const onBookShipment2 = () => {
        setLoading(true);
        setBookConfirm(false);
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            db.collection("shipments").doc(hawb).set({
                account: "786009",
                currency: "USD",
                service: service || "SKD",
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                perform: "New",
                status: "Pending",
                id: (Math.random() + 1).toString(36).substring(7),
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    stat: "Shipment booked, waiting for confirmation by Skydart ",
                    time: datePicker + "T" + dateTime + ":00",
                    location: "VIETNAM - VIETNAM - HCM",
                })),
                controller: userData.controller || "Pending",
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                important: "",
                airline: "Pending",
                mawb: "Pending",
                etd: "Pending",
                agent: "Pending",
                subStatus: "Pending",
                sin: "Null",
                note: "Null",
                perform: "Null",
                code: code,
                hawb,
                uid: user.uid,
                makh: userData.makh,
                paymentStatus: "",
                shipmentType: type,
                fromCountry: userData.country,
                exporter: removeAccents(exporter),
                fromCity: removeAccents(fromCity),
                fromState: removeAccents(fromState),
                fromTown: removeAccents(fromTown),
                fromAddress: removeAccents(fromAddress),
                fromAddress2: removeAccents(fromAddress2),
                fromAddress3: removeAccents(fromAddress3),
                value: totalInvoiceValue,
                fromPostal: fromPostal,
                fromPhone: fromPhone,
                fromName: removeAccents(fromName),
                toPhone2: "",
                fromEmail: fromEmail,
                dateTime: new Date(),
                toCountry: country,
                importer,
                toCity: removeAccents(city),
                toState: removeAccents(state),
                toTown: removeAccents(town),
                toAddress: removeAccents(address),
                toAddress2: removeAccents(address2),
                toAddress3: removeAccents(address3),
                stateCode: stateCode,
                toPostal: postal,
                toPhone: phone,
                toName: removeAccents(contact),
                toEmail: email,
                createAt: datePicker,
                time: dateTime,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                reweight: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                weight: type === "DOX" ? doxWeight : finalGross.toFixed(2),
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                eori,
                otherTerm: "",
                description: type === "DOX" ? "Document" : arr + ",",
                term,
                transportation: transport,
                totalItemValue: type === "WPX" ? finalInvoiceValue.toFixed(2).toString() : "0.00",
                totalInvoiceValue: type === "WPX" ? totalInvoiceValue.toFixed(2).toString() : "0.00",
                usage: reason,
                rate: type === "WPX" ? rate?.toFixed(2) : doxRate?.toFixed(2),
                // rate: "0.00",
                fsc: fsc,
                // fsc: 0,
                fscValue: "0.00",
                addressCorrection: "0.00",
                direct: direct,
                surcharge: "0.00",
                surcharge2: "0.00",
                surcharge3: "0.00",
                remote: "0.00",
                vat: "No",
                vatValue: "0.00",
                eamService: eamService,
                plan: userData.plan || "Pending",
                booked: "false",
            }).then(() => {
                setLoading(false);
                window.location.reload();
            })
        }
    }

    const fscValue = rate * fsc / 100;
    const fscIncluded = rate + fscValue;
    const doxFscValue = doxRate * fsc / 100;
    const doxFscIncluded = doxRate + doxFscValue;
    return (
        <div className="staff-booking">
            <div ref={bookConfirm ? null : bookingRef}></div>
            {bookConfirm && <div className="skydart-bookShipment-loading">
                <div className="skydart-bookShipment-loading-container1" ref={bookConfirm ? bookingRef : null}>
                    <div style={{ display: "flex", padding: 10, alignItems: "center", flexDirection: "column" }}>
                        <FcCheckmark size={35} />
                        <p style={{ padding: 15, fontSize: 15, fontWeight: 600, textAlign: "center" }}>Your shipment has been booked successfully.</p>
                    </div>
                    <div className="skydart-bookShipment-save-confirm-option">
                        <button type="button" className="skydart-bookShipment-save-confirm-cancel" onClick={onBookShipment}>Manage shipment</button>
                        <button type="button" className="skydart-bookShipment-save-confirm-book-another" onClick={onBookShipment2}>Book another shipment</button>
                    </div>
                </div>
            </div>}
            {loading && <div className="staff-booking-loading">
                <CircularProgress style={{ color: "white" }} />
            </div>}
            <div className="staff-booking-left">
                <div className="staff-booking-left-header">
                    <h3>CREATE & UPLOAD SHIPMENT</h3>
                </div>
                <form className="staff-booking-left-body" onSubmit={onAttachment}>
                    <div className="staff-booking-left-option" style={{ flexDirection: "row" }}>
                        <input type="file" id="staff-booking-hidden" onChange={handleFile} />
                        <label for="staff-booking-hidden" className="staff-booking-attach" style={{ textAlign: "center", width: 150, borderRadius: "10px" }}>Upload file</label>
                        <select required style={{ marginTop: 5, padding: 7, marginLeft: 10, background: "rgb(240,240,240)", borderRadius: "10px", border: "none" }} value={attachType} onChange={(e) => setAttachType(e.target.value)}>
                            <option value="">Attachment type</option>
                            <option value="DIS">DIS</option>
                            <option value="BILL">BILL</option>
                            <option value="RTR">RTR</option>
                        </select>
                    </div>
                    <div className="staff-booking-left-option">
                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                        <button type="submit">Attach</button>
                    </div>
                </form>
            </div>
            <div className="staff-booking-right">
                <div className="staff-booking-right-primary">
                    <h3 style={{ color: "darkblue" }}>Package Information</h3>
                    <div className="staff-booking-right-primary-container">
                        <div className="staff-booking-right-primary-form" ref={accountRef}>
                            <h5 style={{ fontWeight: 600 }}>Account</h5>
                            <input type="text" placeholder="Please choose account" onClick={() => setDisplayCustomer(true)} value={accountInput} onChange={(e) => { setAccountInput(e.target.value) }} />
                            {displayCustomer && accountInput !== "" && <div className="staff-account-select">
                                {customerArray.filter((s) => {
                                    if (s.exporter.toLowerCase().includes(accountInput.toLowerCase())) {
                                        return s;
                                    }
                                }).map((s) => (
                                    <div className='staff-account-select-container' onClick={() => {
                                        setAccountInput(s.exporter);
                                        setDisplayCustomer(false);
                                        setController(s.controller);
                                        setUid(s.uid);
                                        setMakh(s.makh);
                                        setExporter(s.exporter);
                                        setFromCity(s.city);
                                        setFromState(s.state);
                                        setFromTown(s.town);
                                        setFromAddress(s.address);
                                        setFromAddress2(s.address2);
                                        setFromAddress3(s.address3);
                                        setFromPostal(s.postal);
                                        setFromPhone(s.phone);
                                        setFromName(s.displayName);
                                        setFromEmail(s.email);
                                        setPlan(s.plan);
                                    }}>
                                        <h5>{s.exporter}</h5>
                                        <p>{s.email}</p>
                                    </div>
                                ))}
                            </div>}
                        </div>
                        <div style={{ position: "relative" }} ref={serviceRef}>
                            <div className="skydart-bookShipment-select-type" onClick={handleService}>
                                <div style={{ display: "flex", alignItems: "center", marginLeft: 2 }}>
                                    <GiCommercialAirplane size={21} />
                                    <h3>{proxyService || "Select services"}</h3>
                                </div>
                                <RiArrowDownSFill size={21} />
                            </div>
                            {displayService && <div className="skydart-bookShipment-select-type-container" style={{ height: "25vh", overflowY: "scroll" }}>
                                <p style={{ marginLeft: 8, fontSize: 12, fontWeight: 600, color: "gray" }}>Services:</p>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("SKD");
                                    setProxyService("SKD");
                                    setDisplayService(false);
                                    setDirect("");
                                    setRate(0);
                                    setFsc(skdFsc);
                                    setDoxRate(0);
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "SKD" ? true : false} />
                                    </div>
                                    <h3>SKD</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    if (country === "") {
                                        setService("DHL");
                                        setProxyService("SDH");
                                        setDisplayService(false);
                                        setDirect("");
                                        setEamService("INT");
                                        setFsc(dhlFsc);
                                        setRate(0);
                                        setDoxRate(0);
                                    } else {
                                        setService("DHL");
                                        setProxyService("SDH");
                                        setDisplayService(false);
                                        setDirect("");
                                        setEamService("INT");
                                        setFsc(dhlFsc);
                                        setRate(0);
                                        setDoxRate(0);
                                        setLoading(true);
                                        db.collection("dhl").doc(country).get().then((doc) => {
                                            if (doc.exists) {
                                                setRated(doc.data());
                                                if (type === "WPX") {
                                                    if (finalCharge === 0.5) {
                                                        setRate(doc.data().half);
                                                    } else if (finalCharge === 1) {
                                                        setRate(doc.data().one);
                                                    } else if (finalCharge === 1.5) {
                                                        setRate(doc.data().oneHalf);
                                                    } else if (finalCharge === 2) {
                                                        setRate(doc.data().two);
                                                    } else if (finalCharge === 2.5) {
                                                        setRate(doc.data().twoHalf);
                                                    } else if (finalCharge === 3) {
                                                        setRate(doc.data().three);
                                                    } else if (finalCharge === 3.5) {
                                                        setRate(doc.data().threeHalf);
                                                    } else if (finalCharge === 4) {
                                                        setRate(doc.data().four);
                                                    } else if (finalCharge === 4.5) {
                                                        setRate(doc.data().fourHalf);
                                                    } else if (finalCharge === 5) {
                                                        setRate(doc.data().five);
                                                    } else if (finalCharge === 5.5) {
                                                        setRate(doc.data().fiveHalf);
                                                    } else if (finalCharge === 6) {
                                                        setRate(doc.data().six);
                                                    } else if (finalCharge === 6.5) {
                                                        setRate(doc.data().sixHalf);
                                                    } else if (finalCharge === 7) {
                                                        setRate(doc.data().seven);
                                                    } else if (finalCharge === 7.5) {
                                                        setRate(doc.data().sevenHalf);
                                                    } else if (finalCharge === 8) {
                                                        setRate(doc.data().eight);
                                                    } else if (finalCharge === 8.5) {
                                                        setRate(doc.data().eightHalf);
                                                    } else if (finalCharge === 9) {
                                                        setRate(doc.data().nine);
                                                    } else if (finalCharge === 9.5) {
                                                        setRate(doc.data().nineHalf);
                                                    } else if (finalCharge === 10) {
                                                        setRate(doc.data().ten);
                                                    } else if (finalCharge === 10.5) {
                                                        setRate(doc.data().tenHalf);
                                                    } else if (finalCharge === 11) {
                                                        setRate(doc.data().eleven);
                                                    } else if (finalCharge === 11.5) {
                                                        setRate(doc.data().elevenHalf);
                                                    } else if (finalCharge === 12) {
                                                        setRate(doc.data().twelve);
                                                    } else if (finalCharge === 12.5) {
                                                        setRate(doc.data().twelveHalf);
                                                    } else if (finalCharge === 13) {
                                                        setRate(doc.data().thirteen);
                                                    } else if (finalCharge === 13.5) {
                                                        setRate(doc.data().thirteenHalf);
                                                    } else if (finalCharge === 14) {
                                                        setRate(doc.data().fourteen);
                                                    } else if (finalCharge === 14.5) {
                                                        setRate(doc.data().fourteenHalf);
                                                    } else if (finalCharge === 15) {
                                                        setRate(doc.data().fifteen);
                                                    } else if (finalCharge === 15.5) {
                                                        setRate(doc.data().fifteenHalf);
                                                    } else if (finalCharge === 16) {
                                                        setRate(doc.data().sixteen);
                                                    } else if (finalCharge === 16.5) {
                                                        setRate(doc.data().sixteenHalf);
                                                    } else if (finalCharge === 17) {
                                                        setRate(doc.data().seventeen);
                                                    } else if (finalCharge === 17.5) {
                                                        setRate(doc.data().seventeenHalf);
                                                    } else if (finalCharge === 18) {
                                                        setRate(doc.data().eightteen);
                                                    } else if (finalCharge === 18.5) {
                                                        setRate(doc.data().eightteenHalf);
                                                    } else if (finalCharge === 19) {
                                                        setRate(doc.data().nineteen);
                                                    } else if (finalCharge === 19.5) {
                                                        setRate(doc.data().nineteenHalf);
                                                    } else if (finalCharge === 20) {
                                                        setRate(doc.data().twenty);
                                                    } else if (finalCharge === 20.5) {
                                                        setRate(doc.data().twentyHalf);
                                                    } else if (finalCharge === 21) {
                                                        setRate(doc.data().twentyone);
                                                    } else if (finalCharge === 21.5) {
                                                        setRate(doc.data().twentyoneHalf);
                                                    } else if (finalCharge === 22) {
                                                        setRate(doc.data().twentytwo);
                                                    } else if (finalCharge === 22.5) {
                                                        setRate(doc.data().twentytwoHalf);
                                                    } else if (finalCharge === 23) {
                                                        setRate(doc.data().twentythree);
                                                    } else if (finalCharge === 23.5) {
                                                        setRate(doc.data().twentythreeHalf);
                                                    } else if (finalCharge === 24) {
                                                        setRate(doc.data().twentyfour);
                                                    } else if (finalCharge === 24.5) {
                                                        setRate(doc.data().twentyfourHalf);
                                                    } else if (finalCharge === 25) {
                                                        setRate(doc.data().twentyfive);
                                                    } else if (finalCharge === 25.5) {
                                                        setRate(doc.data().twentyfiveHalf);
                                                    } else if (finalCharge === 26) {
                                                        setRate(doc.data().twentysix);
                                                    } else if (finalCharge === 26.5) {
                                                        setRate(doc.data().twentysixHalf);
                                                    } else if (finalCharge === 27) {
                                                        setRate(doc.data().twentyseven);
                                                    } else if (finalCharge === 27.5) {
                                                        setRate(doc.data().twentysevenHalf);
                                                    } else if (finalCharge === 28) {
                                                        setRate(doc.data().twentyeight);
                                                    } else if (finalCharge === 28.5) {
                                                        setRate(doc.data().twentyeightHalf);
                                                    } else if (finalCharge === 29) {
                                                        setRate(doc.data().twentynine);
                                                    } else if (finalCharge === 29.5) {
                                                        setRate(doc.data().twentynineHalf);
                                                    } else if (finalCharge === 30) {
                                                        setRate(doc.data().thirty);
                                                    } else if (finalCharge > 30 && finalCharge < 71) {
                                                        setRate(doc.data().small * finalCharge);
                                                    } else if (finalCharge > 70 && finalCharge < 301) {
                                                        setRate(doc.data().medium * finalCharge);
                                                    } else if (finalCharge > 300 && finalCharge < 1000) {
                                                        setRate(doc.data().large * finalCharge);
                                                    }
                                                } else if (type === "DOX") {
                                                    if (doxWeight == 0.5) {
                                                        setDoxRate(doc.data().doxHalf);
                                                    } else if (doxWeight == 1) {
                                                        setDoxRate(doc.data().doxOne);
                                                    } else if (doxWeight == 1.5) {
                                                        setDoxRate(doc.data().doxOnehalf);
                                                    } else if (doxWeight == 2) {
                                                        setDoxRate(doc.data().doxTwo);
                                                    }
                                                }
                                            }
                                        }).then(() => {
                                            setLoading(false);
                                        })
                                    }
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "DHL" ? true : false} />
                                    </div>
                                    <h3>SDH</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    if (country === "") {
                                        setService("FIP");
                                        setFsc(fedexFsc);
                                        setDisplayService(false);
                                        setProxyService("SIP");
                                        setDirect("");
                                        setEamService("FIP");
                                        setRate(0);
                                        setDoxRate(0);
                                    } else {
                                        setService("FIP");
                                        setFsc(fedexFsc);
                                        setDisplayService(false);
                                        setProxyService("SIP");
                                        setRate(0);
                                        setDoxRate(0);
                                        setDirect("");
                                        setEamService("FIP");
                                        setLoading(true);
                                        db.collection("fedex").doc(country).get().then((doc) => {
                                            if (doc.exists) {
                                                setRated(doc.data());
                                                if (type === "WPX") {
                                                    if (finalCharge === 0.5) {
                                                        setRate(doc.data().half);
                                                    } else if (finalCharge === 1) {
                                                        setRate(doc.data().one);
                                                    } else if (finalCharge === 1.5) {
                                                        setRate(doc.data().oneHalf);
                                                    } else if (finalCharge === 2) {
                                                        setRate(doc.data().two);
                                                    } else if (finalCharge === 2.5) {
                                                        setRate(doc.data().twoHalf);
                                                    } else if (finalCharge === 3) {
                                                        setRate(doc.data().three);
                                                    } else if (finalCharge === 3.5) {
                                                        setRate(doc.data().threeHalf);
                                                    } else if (finalCharge === 4) {
                                                        setRate(doc.data().four);
                                                    } else if (finalCharge === 4.5) {
                                                        setRate(doc.data().fourHalf);
                                                    } else if (finalCharge === 5) {
                                                        setRate(doc.data().five);
                                                    } else if (finalCharge === 5.5) {
                                                        setRate(doc.data().fiveHalf);
                                                    } else if (finalCharge === 6) {
                                                        setRate(doc.data().six);
                                                    } else if (finalCharge === 6.5) {
                                                        setRate(doc.data().sixHalf);
                                                    } else if (finalCharge === 7) {
                                                        setRate(doc.data().seven);
                                                    } else if (finalCharge === 7.5) {
                                                        setRate(doc.data().sevenHalf);
                                                    } else if (finalCharge === 8) {
                                                        setRate(doc.data().eight);
                                                    } else if (finalCharge === 8.5) {
                                                        setRate(doc.data().eightHalf);
                                                    } else if (finalCharge === 9) {
                                                        setRate(doc.data().nine);
                                                    } else if (finalCharge === 9.5) {
                                                        setRate(doc.data().nineHalf);
                                                    } else if (finalCharge === 10) {
                                                        setRate(doc.data().ten);
                                                    } else if (finalCharge === 10.5) {
                                                        setRate(doc.data().tenHalf);
                                                    } else if (finalCharge === 11) {
                                                        setRate(doc.data().eleven);
                                                    } else if (finalCharge === 11.5) {
                                                        setRate(doc.data().elevenHalf);
                                                    } else if (finalCharge === 12) {
                                                        setRate(doc.data().twelve);
                                                    } else if (finalCharge === 12.5) {
                                                        setRate(doc.data().twelveHalf);
                                                    } else if (finalCharge === 13) {
                                                        setRate(doc.data().thirteen);
                                                    } else if (finalCharge === 13.5) {
                                                        setRate(doc.data().thirteenHalf);
                                                    } else if (finalCharge === 14) {
                                                        setRate(doc.data().fourteen);
                                                    } else if (finalCharge === 14.5) {
                                                        setRate(doc.data().fourteenHalf);
                                                    } else if (finalCharge === 15) {
                                                        setRate(doc.data().fifteen);
                                                    } else if (finalCharge === 15.5) {
                                                        setRate(doc.data().fifteenHalf);
                                                    } else if (finalCharge === 16) {
                                                        setRate(doc.data().sixteen);
                                                    } else if (finalCharge === 16.5) {
                                                        setRate(doc.data().sixteenHalf);
                                                    } else if (finalCharge === 17) {
                                                        setRate(doc.data().seventeen);
                                                    } else if (finalCharge === 17.5) {
                                                        setRate(doc.data().seventeenHalf);
                                                    } else if (finalCharge === 18) {
                                                        setRate(doc.data().eightteen);
                                                    } else if (finalCharge === 18.5) {
                                                        setRate(doc.data().eightteenHalf);
                                                    } else if (finalCharge === 19) {
                                                        setRate(doc.data().nineteen);
                                                    } else if (finalCharge === 19.5) {
                                                        setRate(doc.data().nineteenHalf);
                                                    } else if (finalCharge === 20) {
                                                        setRate(doc.data().twenty);
                                                    } else if (finalCharge === 20.5) {
                                                        setRate(doc.data().twentyHalf);
                                                    } else if (finalCharge > 20 && finalCharge < 45) {
                                                        setRate(doc.data().small * finalCharge);
                                                    } else if (finalCharge > 44 && finalCharge < 71) {
                                                        setRate(doc.data().medium * finalCharge);
                                                    } else if (finalCharge > 70 && finalCharge < 100) {
                                                        setRate(doc.data().large * finalCharge);
                                                    } else if (finalCharge > 99 && finalCharge < 300) {
                                                        setRate(doc.data().extraLarge * finalCharge);
                                                    } else if (finalCharge > 299 && finalCharge < 499) {
                                                        setRate(doc.data().extraLarge2 * finalCharge);
                                                    } else if (finalCharge > 499 && finalCharge < 1000) {
                                                        setRate(doc.data().extraLarge3 * finalCharge);
                                                    }
                                                } else if (type === "DOX") {
                                                    if (doxWeight == 0.5) {
                                                        setDoxRate(doc.data().doxHalf);
                                                    } else if (doxWeight == 1) {
                                                        setDoxRate(doc.data().doxOne);
                                                    } else if (doxWeight == 1.5) {
                                                        setDoxRate(doc.data().doxOnehalf);
                                                    } else if (doxWeight == 2) {
                                                        setDoxRate(doc.data().doxTwo);
                                                    }
                                                }
                                            }
                                        }).then(() => {
                                            setLoading(false);
                                        })
                                    }
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "FIP" ? true : false} />
                                    </div>
                                    <h3>SIP</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("UPS");
                                    setProxyService("SUPS");
                                    setDirect("");
                                    setRate(0);
                                    setDoxRate(0);
                                    setFsc(upsFsc);
                                    setDisplayService(false);
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "UPS" ? true : false} />
                                    </div>
                                    <h3>SUP</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("DHL-VN");
                                    setFsc(0);
                                    setProxyService("VDH");
                                    setDisplayService(false);
                                    setDirect("");
                                    setRate(0);
                                    setDoxRate(0);
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "DHL-VN" ? true : false} />
                                    </div>
                                    <h3>VDH</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("Fedex-VN");
                                    setProxyService("VIP");
                                    setDirect("");
                                    setDisplayService(false);
                                    setFsc(0);
                                    setRate(0);
                                    setDoxRate(0);
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "Fedex-VN" ? true : false} />
                                    </div>
                                    <h3>VIP</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("UPS-VN");
                                    setProxyService("VUPS");
                                    setDisplayService(false);
                                    setRate(0);
                                    setDoxRate(0);
                                    setFsc(0);
                                    setDirect("");
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "UPS-VN" ? true : false} />
                                    </div>
                                    <h3>VUPS</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("DAU");
                                    setDisplayService(false);
                                    setProxyService("DAU");
                                    setDirect("");
                                    setRate(0);
                                    setFsc(0);
                                    setDoxRate(0);
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "DAU" ? true : false} />
                                    </div>
                                    <h3>DAU</h3>
                                </div>

                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("SEC");
                                    setProxyService("SEC");
                                    setDisplayService(false);
                                    setDirect("");
                                    setRate(0);
                                    setFsc(0);
                                    setDoxRate(0);
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "SEC" ? true : false} />
                                    </div>
                                    <h3>SEC</h3>
                                </div>
                                <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                    setService("DUS");
                                    setProxyService("DUS");
                                    setDisplayService(false);
                                    setCountryInput("United States");
                                    setDirect("us");
                                    setFsc(0);
                                    setCode("US");
                                }}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={service === "DUS" ? true : false} />
                                    </div>
                                    <h3>DUS</h3>
                                </div>
                            </div>}
                        </div>
                        <div style={{ position: "relative" }} ref={typeRef}>
                            <div className="skydart-bookShipment-select-type" onClick={handleType}>
                                {type === "WPX" && <div style={{ display: "flex", alignItems: "center", marginLeft: 2 }}>
                                    <BsBoxSeam size={21} />
                                    <h3>Worldwide Package Express (WPX)</h3>
                                </div>}
                                {type === "DOX" && <div style={{ display: "flex", alignItems: "center", marginLeft: 2 }}>
                                    <GrDocument size={21} />
                                    <h3>Document (DOX)</h3>
                                </div>}
                                <RiArrowDownSFill size={21} />
                            </div>
                            {displayType && <div className="skydart-bookShipment-select-type-container">
                                <p style={{ marginLeft: 8, fontSize: 12, fontWeight: 600, color: "gray" }}>Select a suitable shipping type for your shipment.</p>
                                <div onClick={handleWPX} className={type === "WPX" ? "skydart-bookShipment-select-type-option" : "skydart-bookShipment-select-type-option1"}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={type === "WPX" ? true : false} />
                                    </div>
                                    <BsBoxSeam size={18} />
                                    <h3>Worldwide Package Express (WPX)</h3>
                                </div>
                                <div onClick={handleDOX} className={type === "DOX" ? "skydart-bookShipment-select-type-option" : "skydart-bookShipment-select-type-option1"}>
                                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                        <input type="checkbox" checked={type === "DOX" ? true : false} />
                                    </div>
                                    <GrDocument size={18} />
                                    <h3>Document (DOX)</h3>
                                </div>
                            </div>}
                        </div>
                        {type === "DOX" && <div ref={reasonRef}></div>}
                        {type === "WPX" &&
                            <div style={{ position: 'relative' }} ref={reasonRef}>
                                <div className="skydart-bookShipment-select-type" onClick={handleReason}>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: 2 }}>
                                        <SiReasonstudios size={21} />
                                        <h3>{reason || "Please select your export reason"}</h3>
                                    </div>
                                    <RiArrowDownSFill size={21} />
                                </div>
                                {displayReason && <div className="skydart-bookShipment-select-type-container">
                                    <p style={{ marginLeft: 8, fontSize: 12, fontWeight: 600, color: "gray" }}>Reasons:</p>
                                    <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                        setReason("GIFT");
                                        setDisplayReason(false);
                                    }}>
                                        <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                            <input type="checkbox" checked={reason === "GIFT" ? true : false} />
                                        </div>
                                        <h3>THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE</h3>
                                    </div>
                                    <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                        setReason("E-COMMERCE SELLING");
                                        setDisplayReason(false);
                                    }}>
                                        <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                            <input type="checkbox" checked={reason === "E-COMMERCE SELLING" ? true : false} />
                                        </div>
                                        <h3>THE GOODS ARE E-COMMERCE SELLING</h3>
                                    </div>
                                    <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                        setReason("RETURNED FOR MAINTENANCE");
                                        setDisplayReason(false);
                                    }}>
                                        <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                            <input type="checkbox" checked={reason === "RETURNED FOR MAINTENANCE" ? true : false} />
                                        </div>
                                        <h3>THE GOODS ARE RETURNED FOR MAINTENANCE</h3>
                                    </div>
                                    <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                        setReason("SAMPLE");
                                        setDisplayReason(false);
                                    }}>
                                        <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                            <input type="checkbox" checked={reason === "SAMPLE" ? true : false} />
                                        </div>
                                        <h3>THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE</h3>
                                    </div>
                                    <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                                        setReason("COMMERCIAL");
                                        setDisplayReason(false);
                                    }}>
                                        <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                                            <input type="checkbox" checked={reason === "COMMERCIAL" ? true : false} />
                                        </div>
                                        <h3>THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED</h3>
                                    </div>
                                </div>}
                            </div>}
                        <div ref={stateRef}></div>
                        <div className="skydart-regular-booking">
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Country</p>
                                <div className="skydart-regular-booking-name" style={{ position: "relative" }} ref={countryRef}>
                                    {service !== "DUS" && <input type="text" value={countryInput} disabled={service === "" ? true : false} onChange={(e) => setCountryInput(e.target.value)} onClick={() => setDisplayCountry(true)} />}
                                    {service === "DUS" && <input type="text" value={countryInput} onChange={(e) => setCountryInput(e.target.value)} disabled />}
                                    {countryInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {countryInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                    {displayCountry && countryInput !== "" && <div className="skydart-regular-country-render">
                                        {countries.filter((s) => {
                                            if (s.label.toLowerCase().startsWith(countryInput.toLowerCase())) {
                                                return s;
                                            }
                                        }).map((s) => (
                                            <div className="skydart-regular-country-render-option" onClick={() => {
                                                setCountryInput(s.label);
                                                setCode(s.code);
                                                setDisplayCountry(false);
                                            }}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                <h5>{s.label}</h5>
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Company</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={companyInput} onChange={(e) => setCompanyInput(e.target.value)} />
                                    {companyInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {companyInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Address</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" maxLength={30} value={addressInput} onChange={(e) => setAddressInput(e.target.value)} />
                                    {addressInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {addressInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Address 2</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" maxLength={30} value={addressInput2} onChange={(e) => setAddressInput2(e.target.value)} />
                                    {addressInput2 !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {addressInput2 === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Address 3</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" maxLength={30} value={addressInput3} onChange={(e) => setAddressInput3(e.target.value)} />
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Town</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={townInput} onChange={(e) => setTownInput(e.target.value)} />
                                    {townInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {townInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>City</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={cityInput} onChange={(e) => setCityInput(e.target.value)} />
                                    {cityInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {cityInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            {service !== "UUS" && <div ref={stateRef}></div>}
                            {service !== "FUS" && <div ref={stateRef}></div>}
                            {service !== "DUS" && <div ref={stateRef}></div>}
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>State</p>
                                {service !== "DUS" && service !== "UUS" && service !== "FUS" && <div className="skydart-regular-booking-name">
                                    <input type="text" value={stateInput} onChange={(e) => setStateInput(e.target.value)} />
                                </div>}
                                {service === "DUS" && <div className="skydart-regular-booking-name" ref={service === "DUS" ? stateRef : null} style={{ position: "relative" }}>
                                    <input type="text" value={stateInput} onChange={(e) => setStateInput(e.target.value)} onClick={() => {
                                        if (displayState) {
                                            setDisplayState(false);
                                        } else {
                                            setDisplayState(true);
                                        }
                                    }} />
                                    {displayState && <div className="skydart-regular-country-render">
                                        {usState.filter((s) => {
                                            if (s.label.toLowerCase().startsWith(stateInput.toLowerCase())) {
                                                return s;
                                            }
                                        }).map((s) => (
                                            <div className="skydart-regular-country-render-option" onClick={() => { setStateInput(s.label); setStateCode(s.code); setDisplayState(false) }}>
                                                <h5>{`${s.label}, ${s.code}`}</h5>
                                            </div>
                                        ))}
                                    </div>}
                                </div>}
                                {service === "FUS" && <div className="skydart-regular-booking-name" ref={service === "FUS" ? stateRef : null} style={{ position: "relative" }}>
                                    <input type="text" value={stateInput} onChange={(e) => setStateInput(e.target.value)} onClick={() => {
                                        if (displayState) {
                                            setDisplayState(false);
                                        } else {
                                            setDisplayState(true);
                                        }
                                    }} />
                                    {displayState && <div className="skydart-regular-country-render">
                                        {usState.filter((s) => {
                                            if (s.label.toLowerCase().startsWith(stateInput.toLowerCase())) {
                                                return s;
                                            }
                                        }).map((s) => (
                                            <div className="skydart-regular-country-render-option" onClick={() => { setStateInput(s.label); setStateCode(s.code); setDisplayState(false) }}>
                                                <h5>{`${s.label}, ${s.code}`}</h5>
                                            </div>
                                        ))}
                                    </div>}
                                </div>}
                                {service === "UUS" && <div className="skydart-regular-booking-name" ref={service === "UUS" ? stateRef : null} style={{ position: "relative" }}>
                                    <input type="text" value={stateInput} onChange={(e) => setStateInput(e.target.value)} onClick={() => {
                                        if (displayState) {
                                            setDisplayState(false);
                                        } else {
                                            setDisplayState(true);
                                        }
                                    }} />
                                    {displayState && <div className="skydart-regular-country-render">
                                        {usState.filter((s) => {
                                            if (s.label.toLowerCase().startsWith(stateInput.toLowerCase())) {
                                                return s;
                                            }
                                        }).map((s) => (
                                            <div className="skydart-regular-country-render-option" onClick={() => { setStateInput(s.label); setStateCode(s.code); setDisplayState(false) }}>
                                                <h5>{`${s.label}, ${s.code}`}</h5>
                                            </div>
                                        ))}
                                    </div>}
                                </div>}
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Postal Code</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={postalInput} onChange={(e) => setPostalInput(e.target.value)} />
                                    {postalInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {postalInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Telephone</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={phoneInput} onChange={(e) => setPhoneInput(e.target.value)} />
                                    {phoneInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {phoneInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Contact Name</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={contactInput} onChange={(e) => setContactInput(e.target.value)} />
                                    {contactInput !== "" && <FcOk size={18} style={{ marginLeft: 10 }} />}
                                    {contactInput === "" && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                                </div>
                            </div>
                            <div className="skydart-regular-booking-option">
                                <p style={{ fontSize: 12, marginLeft: 1 }}>Email Address</p>
                                <div className="skydart-regular-booking-name">
                                    <input type="text" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        {type === "WPX" && <div className="skydart-bookShipment-info-packing-header">
                            <div className="skydart-bookShipment-packing-info-header-option">
                                <div className="skydart-bookShipment-packing-info-kg">
                                    <p>Gross (KG)</p>
                                </div>
                            </div>
                            <div className="skydart-bookShipment-packing-info-header-option">
                                <div className="skydart-bookShipment-packing-info-dim">
                                    <p style={{ marginLeft: -11 }}>Length (CM)</p>
                                </div>

                                <div className="skydart-bookShipment-packing-info-dim">
                                    <p style={{ marginLeft: -11 }}>Width (CM)</p>
                                </div>

                                <div className="skydart-bookShipment-packing-info-dim">
                                    <p style={{ marginLeft: -11 }}>Height (CM)</p>
                                </div>
                            </div>
                            <div className="skydart-bookShipment-packing-info-header-option">
                                <div className="skydart-bookShipment-packing-info-kg">
                                    <p style={{ marginLeft: -22 }}>Chargeable (KG)</p>
                                </div>
                            </div>
                            <div className="skydart-bookShipment-packing-info-header-option2" style={{ border: "none", cursor: "default" }}>
                            </div>
                            <div className="skydart-bookShipment-packing-info-header-option4">
                            </div>
                        </div>}
                        {type === "DOX" && <div className="skydart-bookShipment-packing">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <h5>Packing information</h5>

                            </div>
                            <div style={{ padding: 5, display: "flex", alignItems: "center" }}>
                                <p style={{ fontSize: 13, fontWeight: 600 }}>Number of envelope:</p>
                                <input type="number" className="dox-input-declare" value={envelope} onChange={(e) => setEnvelope(e.target.value)} />
                                <p style={{ fontSize: 13, fontWeight: 600, width: "5%", marginLeft: 5 }}>PCS</p>
                            </div>
                            <div style={{ padding: 5, display: "flex", alignItems: "center" }}>
                                <p style={{ fontSize: 13, fontWeight: 600 }}>Total weight:</p>
                                <input type="number" className="dox-input-declare" style={{ background: "rgb(240,240,240)" }} value={doxWeight} onChange={(e) => setDoxWeight(e.target.value)} />
                                <p style={{ fontSize: 13, fontWeight: 600, width: "5%", marginLeft: 5 }}>KG</p>
                            </div>
                        </div>}
                        {type === "WPX" && <div style={{ marginTop: 5 }}>
                            {packageValue.map(((element, index) => {
                                const dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 5000;
                                const grossWeight = element.packageWeight;

                                if (dimCharge < grossWeight) {
                                    element.packageCharge = grossWeight * 1;
                                    element.packageWeight = grossWeight * 1;
                                } else if (grossWeight < dimCharge) {
                                    element.packageCharge = dimCharge;
                                    element.packageWeight = grossWeight * 1;

                                }
                                if (element.packageCharge >= 30) {
                                    element.packageCharge = Math.ceil(element.packageCharge);
                                } else if (element.packageCharge < 30) {
                                    element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
                                }
                                if (element.packageWeight === "") {
                                    element.packageCharge = "0.00";
                                }
                                return <div className="skydart-bookShipment-info-packing-header" key={index}>
                                    <div className="skydart-bookShipment-packing-info-header-option">
                                        <div className="skydart-bookShipment-packing-info-kg">
                                            <input type="number" required value={element.packageWeight || ""} onChange={e => handleChange(index, e)} name="packageWeight" />
                                        </div>
                                    </div>
                                    <div className="skydart-bookShipment-packing-info-header-option">
                                        <div className="skydart-bookShipment-packing-info-dim">
                                            <input type="number" style={{ marginLeft: 10 }} name="packageLength" value={element.packageLength || ""} onChange={e => handleChange(index, e)} />
                                            <p style={{ marginLeft: 9, fontSize: 15 }}>X</p>
                                        </div>
                                        <div className="skydart-bookShipment-packing-info-dim">
                                            <input type="number" style={{ marginLeft: 10 }} name="packageWidth" value={element.packageWidth || ""} onChange={e => handleChange(index, e)} />
                                            <p style={{ marginLeft: 9, fontSize: 15 }}>X</p>
                                        </div>
                                        <div className="skydart-bookShipment-packing-info-dim">
                                            <input type="number" style={{ marginLeft: 10 }} name="packageHeight" value={element.packageHeight || ""} onChange={e => handleChange(index, e)} />
                                        </div>
                                    </div>
                                    <div className="skydart-bookShipment-packing-info-header-option">
                                        <div className="skydart-bookShipment-packing-info-kg">
                                            <input type="number" disabled value={(Math.round(element.packageCharge * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} name="packageCharge" />
                                        </div>
                                    </div>
                                    <div className="skydart-bookShipment-packing-info-header-option1" onClick={() => {
                                        setPackageValue([...packageValue, { packageLength: element.packageLength, packageWidth: element.packageWidth, packageHeight: element.packageHeight, packageWeight: element.packageWeight, packageCharge: element.packageCharge }])
                                    }}>
                                        <p>Copy</p>
                                    </div>
                                    <div className="skydart-bookShipment-packing-info-header-option3" onClick={e => removeFormField(index, e)}>
                                        <MdOutlineClear size={15} />
                                    </div>
                                </div>
                            }))}

                        </div>}
                        {type === "WPX" && <div style={{ marginTop: 5 }}>
                            <div className="skydart-bookShipment-info-packing-header" style={{ alignItems: "center", borderTop: "1px solid lightgray", paddingTop: 5 }}>
                                <div className="skydart-bookShipment-packing-info-header-option">
                                    <div className="skydart-bookShipment-packing-info-kg">
                                        <p style={{ fontWeight: 600 }}>Total</p>
                                    </div>
                                </div>
                                <div className="skydart-bookShipment-packing-info-header-option">
                                    <div className="skydart-bookShipment-packing-info-dim">
                                        <p style={{ marginLeft: 16, fontWeight: 600 }}>Weight</p>
                                    </div>
                                    <div className="skydart-bookShipment-packing-info-dim">
                                        <p style={{ marginLeft: 10, background: "rgb(240, 248, 255)", borderRadius: 3, fontSize: 13, width: "50%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 7, paddingBottom: 7, padding: 5, fontWeight: 600, color: "rgb(56, 97, 133)" }} >{(Math.round(finalGross * 100) / 100).toFixed(2)}</p>
                                        <p style={{ marginLeft: 9, fontSize: 15, opacity: 0 }}>X</p>
                                    </div>
                                    <div className="skydart-bookShipment-packing-info-dim">
                                        <p style={{ marginLeft: 11, fontWeight: 600 }}>Chargeable</p>
                                    </div>
                                </div>
                                <div className="skydart-bookShipment-packing-info-header-option">
                                    <div className="skydart-bookShipment-packing-info-kg">
                                        <p style={{ background: "rgb(243, 255, 251)", color: "darkgreen", fontWeight: 600, fontSize: 13, width: "80%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 7, paddingBottom: 7, padding: 5, fontWeight: 600 }}>{(Math.round(finalCharge * 100) / 100).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="skydart-bookShipment-packing-info-header-option1" onClick={addFormFields}>
                                    <p>Adds</p>
                                </div>
                                <div className="skydart-bookShipment-packing-info-header-option3" onClick={resetPacking}>
                                    <VscDebugRestart size={15} />
                                </div>
                            </div>
                        </div>}
                        {type === "WPX" && <div className="skydart-bookShipment-invoice">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h5>Description of goods</h5>
                            </div>
                            <p style={{ marginLeft: 5, marginRight: 5, lineHeight: 1.5, fontSize: 13.5 }}>Declare element of your goods in detail for a faster process duration.</p>
                            <div style={{ display: "flex", marginTop: 12, marginLeft: 5 }}>
                                <input type="file" id="book-upload" onChange={handleFile} />
                                <label className={fileAttached ? "label-upload1" : "label-upload"} style={{ position: "relative" }}>
                                    <IoDocumentAttachSharp size={20} />
                                    <p>Add invoice</p>
                                    {fileAttached && <p className="file-attached-text">{invoiceValue.length}</p>}
                                </label>
                                <input type="file" id="book-upload" />
                                <div {...exportInvoice} style={{ color: "black", textDecoration: "none" }} for="book-upload" className="label-upload">
                                    <MdSimCardDownload size={20} />
                                    <p>Download invoice</p>
                                </div>
                                <label className={useTemplate ? "label-upload1" : "label-upload"} onClick={() => {
                                    if (!useTemplate) {
                                        setUseTemplate(true);
                                        setFileAttached(false);
                                        resetInvoice();
                                    } else {
                                        setUseTemplate(false);
                                        setFileAttached(false);
                                    }
                                }}>
                                    <VscNotebookTemplate size={20} />
                                    <p>Use template</p>
                                </label>
                                {attachLoading && <CircularProgress size={23} style={{ color: "rgb(31, 82, 139)", marginTop: 4 }} />}
                            </div>
                            {!attachLoading && fileAttached && <div className="skydart-bookShipment-invoice-wrap">
                                <div className="skydart-bookShipment-invoice-attached">
                                    <div className="skydart-bookShipment-invoice-attached-header">
                                        <div className="skydart-bookShipment-invoice-attached-option">
                                            <p>Name</p>
                                        </div>
                                        <div className="skydart-bookShipment-invoice-attached-option">
                                            <p>Type</p>
                                        </div>
                                        <div className="skydart-bookShipment-invoice-attached-option">
                                            <p>Size</p>
                                        </div>
                                    </div>
                                    <div className="skydart-bookShipment-invoice-attached-body">
                                        <div className="skydart-bookShipment-invoice-attached-option1">
                                            <p>{fileName}</p>
                                        </div>
                                        <div className="skydart-bookShipment-invoice-attached-option1">
                                            <p>{fileType}</p>
                                        </div>
                                        <div className="skydart-bookShipment-invoice-attached-option1">
                                            <p>{niceBytes(fileSize)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="skydart-bookShipment-subTotal">
                                    <div className="skydart-bookShipment-subTotal-option">
                                        <p>Total item:</p>
                                        <p>{invoiceValue.length} {invoiceValue.length === 1 ? "item" : "items"}</p>
                                    </div>
                                    <div className="skydart-bookShipment-subTotal-option">
                                        <p>Total item value:</p>
                                        <p>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</p>
                                    </div>
                                    <div className="skydart-bookShipment-subTotal-option">
                                        <p>Total invoice value:</p>
                                        <p style={{ textDecoration: "underline" }}>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</p>
                                    </div>
                                    <div className="skydart-bookShipment-subTotal-btn">
                                        <div className="skydart-bookShipment-subTotal-cancel" onClick={() => { setDisplayInvoice(true); setDisplayLabel(false); setDisplayShippingMark(false) }}>
                                            <FaFileInvoice size={20} />
                                            <p>View invoice</p>
                                        </div>
                                        <div className="skydart-bookShipment-subTotal-cancel" onClick={() => {
                                            resetInvoice();
                                            setFileAttached(false);
                                        }}>
                                            <VscDebugRestart size={20} />
                                            <p>Reset</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {useTemplate && <div className="skydart-bookShipment-invoice-wrap">
                                {invoiceValue.map((s, index) => {
                                    let donGia1 = s.itemUnitPrice;
                                    let soLuong1 = s.itemQuantity;
                                    let tong1 = donGia1 * soLuong1;
                                    s.itemValue = tong1;
                                    return <div className="skydart-bookShipment-invoice-container">
                                        <div className="skydart-bookShipment-invoice-header">
                                            <p style={{ marginLeft: 3, fontSize: 15, fontWeight: 600 }}>Item {index + 1}</p>
                                            <div className="skydart-bookShipment-packing-info-header-option1" style={{ width: 30, marginLeft: "auto" }} onClick={() => {
                                                setInvoiceValue([...invoiceValue, { itemNo: index + 1, itemBrand: s.itemBrand, itemIngredients: s.itemIngredients, itemUnit2: s.itemUnit2, itemPackType2: s.itemPackType2, itemCommonity: s.itemCommonity, itemUnit: s.itemUnit, itemUnitPirce: s.itemUnitPrice, itemQuantity: s.itemQuantity, itemValue: s.itemValue, itemManufact: s.itemManufact, itemPurpose: s.itemPurpose, itemHsCode: s.itemHsCode, itemPackType: s.itemPackType, itemManufactAddress: s.itemManufactAddress, itemCountryOrigin: s.itemCountryOrigin, itemFda: s.itemFda }])
                                            }}>
                                                <p>Copy</p>
                                            </div>
                                            <div className="skydart-bookShipment-packing-info-header-option3" style={{ width: 15 }} onClick={e => removeInvoice(index, e)} >
                                                <MdOutlineClear size={15} />
                                            </div>
                                        </div>
                                        <div className="skydart-bookShipment-invoice-box">
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Item name: *</p>
                                                <input type="text" required name="itemCommonity" value={s.itemCommonity} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Brand name:</p>
                                                <input type="text" value={s.itemBrand} name="itemBrand" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Packing type:</p>
                                                <select value={s.itemPackType || ""} name="itemPackType" onChange={e => invoiceChange(index, e)}>
                                                    <option value="">Select packing type</option>
                                                    <option value="Bag">Bag</option>
                                                    <option value="Box">Box</option>
                                                    <option value="Carton">Carton</option>
                                                    <option value="Wooden Pack">Wooden Pack</option>
                                                </select>
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Ingredient:</p>
                                                <input type="text" value={s.itemIngredients} name="itemIngredients" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Usage:</p>
                                                <input type="text" value={s.itemPurpose} name="itemPurpose" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Country of origin:</p>
                                                <input type="text" value={s.itemCountryOrigin} placeholder="Nước xuất khẩu" name="itemCountryOrigin" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Manufacturer name:</p>
                                                <input type="text" name="itemManufact" value={s.itemManufact} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Manufacturer address:</p>
                                                <input type="text" name="itemManufactAddress" value={s.itemManufactAddress} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>HS code:</p>
                                                <input type="text" name="itemHsCode" value={s.itemHsCode} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>FDA:</p>
                                                <input type="text" value={s.itemFda || ""} name="itemFda" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Quantity: *</p>
                                                <input type="number" required value={s.itemQuantity || ""} name="itemQuantity" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Unit: *</p>
                                                <select required value={s.itemUnit || ""} name="itemUnit" onChange={e => invoiceChange(index, e)}>
                                                    <option value="">Select item unit</option>
                                                    <option value="PCS">PCS</option>
                                                    <option value="BOX">BOX</option>
                                                    <option value="KG">KG</option>
                                                    <option value="PRS">PRS</option>
                                                </select>
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Unit price: *</p>
                                                <input type="number" required value={s.itemUnitPrice || ""} name="itemUnitPrice" onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skydart-bookShipment-invoice-box-option">
                                                <HiOutlineDocument size={20} />
                                                <p>Total amount (USD):</p>
                                                <input type="text" disabled value={s.itemValue.toFixed(2) + " " + "USD" || "0.00" + "USD"} />
                                            </div>
                                        </div>
                                    </div>
                                })}
                                <div className="skydart-bookShipment-subTotal">
                                    <div className="skydart-bookShipment-subTotal-option">
                                        <p>Total item:</p>
                                        <p>{invoiceValue.length} {invoiceValue.length === 1 ? "item" : "items"}</p>
                                    </div>
                                    <div className="skydart-bookShipment-subTotal-option">
                                        <p>Total item value:</p>
                                        <p>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</p>
                                    </div>
                                    <div className="skydart-bookShipment-subTotal-option">
                                        <p>Total invoice value:</p>
                                        <p style={{ textDecoration: "underline" }}>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</p>
                                    </div>
                                    <div className="skydart-bookShipment-subTotal-btn">
                                        <div className="skydart-bookShipment-subTotal-cancel" onClick={() => { setDisplayInvoice(true); setDisplayLabel(false); setDisplayShippingMark(false) }}>
                                            <FaFileInvoice size={20} />
                                            <p>View invoice</p>
                                        </div>
                                        <div className="skydart-bookShipment-subTotal-cancel" onClick={resetInvoice}>
                                            <VscDebugRestart size={20} />
                                            <p>Reset</p>
                                        </div>
                                        <div className="skydart-bookShipment-subTotal-cancel" onClick={addCarton}>
                                            <IoMdAddCircle size={20} />
                                            <p>Add item</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>}
                        {type === "WPX" && <div className="skydart-bookShipment-term">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <h5>Transport Term</h5>
                                <div className="skydart-bookShipment-term-container">
                                    <div className={term === "EXW" ? "skydart-bookShipment-term-option1" : "skydart-bookShipment-term-option"} onClick={() => {
                                        setTerm("EXW");
                                    }}>
                                        <p>EXW</p>
                                    </div>
                                    <div className={term === "FOB" ? "skydart-bookShipment-term-option1" : "skydart-bookShipment-term-option"} style={{ borderLeft: "none" }} onClick={() => {
                                        setTerm("FOB");
                                    }}>
                                        <p>FOB</p>
                                    </div>
                                    <div className={term === "CNF" ? "skydart-bookShipment-term-option1" : "skydart-bookShipment-term-option"} style={{ borderLeft: "none" }} onClick={() => {
                                        setTerm("CNF")
                                    }}>
                                        <p>CNF</p>
                                    </div>
                                    <div className={term === "CIF" ? "skydart-bookShipment-term-option1" : "skydart-bookShipment-term-option"} style={{ borderLeft: "none" }} onClick={() => {
                                        setTerm("CIF");
                                    }}>
                                        <p>CIF</p>
                                    </div>
                                </div>
                            </div>
                            <div className="skydart-bookShipment-transportValue">
                                <p >Transport value</p>
                                <input type="number" value={transport} onChange={(e) => setTransport(e.target.value)} />
                            </div>
                        </div>}

                        {type === "WPX" && <div className="skydart-bookShipment-service">
                            <h5>IOSS / TIN / EORI Number / ID Tax Number</h5>
                            <input type="text" value={eori} onChange={(e) => setEori(e.target.value)} />
                        </div>}
                    </div>
                </div>
                <div className="skydart-staff-booking-secondary">
                    <h3 style={{ color: "darkblue", opacity: 0 }}>Booking Option</h3>
                    <div className="skydart-staff-booking-secondary-box">
                        <div className="skydart-staff-booking-secondary-bottom" onClick={() => {
                            if (type === "WPX") {
                                alert(rate.toFixed(2))
                            } else if (type === "DOX") {
                                alert(doxRate.toFixed(2));
                            }
                        }}>
                            <h5>Calculate shipping cost</h5>
                        </div>
                        {validBook && <div className="skydart-staff-booking-secondary-bottom" onClick={getRate}>
                            <h5>Book shipment</h5>
                        </div>}
                        {!validBook && <div className="skydart-staff-booking-secondary-bottom" style={{ opacity: 0.5, cursor: "not-allowed" }}>
                            <h5>Book shipment</h5>
                        </div>}
                        <div className="skydart-staff-booking-secondary-bottom" onClick={() => history.goBack()}>
                            <h5>Cancel</h5>
                        </div>
                    </div>
                    {accountInput !== "" && <div className="skydart-staff-booking-info">
                        Sender info:
                        <div style={{ wordBreak: "break-word" }}>
                            <p style={{ fontWeight: 600, fontSize: 11 }}>ID:{uid}</p>
                            <p>- {exporter}</p>
                            <p>- {makh}</p>
                            <p>- {fromCity}</p>
                            <p>- Vietnam</p>
                            <p>- {fromAddress}</p>
                            <p>- {fromTown}</p>
                            <p>- {fromState}</p>
                            <p>- {fromPostal}</p>
                            <p>- {fromPhone}</p>
                            {type === "WPX" && <p>Estimate shipping cost (NET): ${rate?.toFixed(2) || "UNDEFINED"}</p>}
                            {type === "WPX" && <p>Estimate shipping cost (FSC included): ${fscIncluded?.toFixed(2)}</p>}
                            {type === "DOX" && <p>Estimate shipping cost (NET): ${doxRate?.toFixed(2) || "UNDEFINED"}</p>}
                            {type === "DOX" && <p>Estimate shipping cost (FSC included): ${doxFscIncluded?.toFixed(2)}</p>}
                        </div>
                    </div>}
                </div>
                {/* <div className="skydart-staff-booking-secondary">
                    <h3 style={{ color: "black", textAlign: "center" }}>Create account</h3>
                    <div className="skydart-staff-booking-secondary-box">
                        <div className="staff-create-account-input">
                            <p>Customer name</p>
                            <input type="text" placeholder="Enter customer full name" />
                        </div>
                        <div className="staff-create-account-input">
                            <p>Company name</p>
                            <input type="text" placeholder="Enter company / exporter name" />
                        </div>
                        <div className="staff-create-account-input">
                            <p>Email address</p>
                            <input type="text" placeholder="Enter company / exporter name" />
                        </div>
                        <div className="staff-create-account-input">
                            <p>Password</p>
                            <input type="password" placeholder="Enter company / exporter name" />
                        </div>
                        <div className="staff-create-account-input">
                            <p>Phone number</p>
                            <input type="text" placeholder="Enter company / exporter name" />
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="staff-create-account-input">
                                <p>Country</p>
                                <select style={{ padding: 5, marginTop: 3, width: 180, border: "1px solid lightgray", borderRadius: 5 }}>
                                    <option value="">Select country</option>
                                </select>
                            </div>
                            <div className="staff-create-account-input">
                                <p>City</p>
                                <select style={{ padding: 5, marginTop: 3, width: 130, border: "1px solid lightgray", borderRadius: 5 }}>
                                    <option value="">Select city</option>
                                </select>
                            </div>
                        </div>
                        <div className="staff-create-account-input">
                            <p>District && Town</p>
                            <select style={{ padding: 5, marginTop: 3, width: 180, border: "1px solid lightgray", borderRadius: 5 }}>
                                <option value="">Select district</option>
                            </select>
                        </div>
                        <div className="staff-create-account-input">
                            <p>Ward && State</p>
                            <select style={{ padding: 5, marginTop: 3, width: 180, border: "1px solid lightgray", borderRadius: 5 }}>
                                <option value="">Select ward</option>
                            </select>
                        </div>
                        <div className="staff-create-account-input">
                            <p>Address</p>
                            <input type="text" placeholder="Enter company / exporter name" />
                        </div>
                        <div className="staff-create-account-input">
                            <p>Postal Code</p>
                            <input type="text" placeholder="Enter company / exporter name" />
                        </div>
                        <div className="staff-create-account-button">
                            <button type="button">Create</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div >
    )
}
const usState = [
    { code: "AL", label: "Alabama" },
    { code: "AK", label: "Alaska" },
    { code: "AZ", label: "Arizona" },
    { code: "AR", label: "Arkansas" },
    { code: "CA", label: "California	" },
    { code: "CZ", label: "Canal Zone" },
    { code: "CO", label: "Colorado" },
    { code: "CT", label: "Connecticut" },
    { code: "DE", label: "Delaware" },
    { code: "DC", label: "District of Columbia" },
    { code: "FL", label: "Florida" },
    { code: "GA", label: "Georgia" },
    { code: "GU", label: "Guam" },
    { code: "HI", label: "Hawaii" },
    { code: "ID", label: "Idaho" },
    { code: "IL", label: "Illinois" },
    { code: "IA", label: "Iowa" },
    { code: "KS", label: "Kansas" },
    { code: "KY", label: "Kentucky" },
    { code: "LA", label: "Louisiana" },
    { code: "ME", label: "Maine" },
    { code: "MD", label: "Maryland" },
    { code: "MA", label: "Massachusetts" },
    { code: "MI", label: "Michigan" },
    { code: "MN", label: "Minnesota" },
    { code: "MS", label: "Mississippi" },
    { code: "MO", label: "Missouri" },
    { code: "MT", label: "Montana" },
    { code: "NE", label: "Nebraska" },
    { code: "NV", label: "Nevada" },
    { code: "NH", label: "New Hampshire" },
    { code: "NJ", label: "New Jersey" },
    { code: "NM", label: "New Mexico" },
    { code: "NY", label: "New York" },
    { code: "NC", label: "North Carolina" },
    { code: "ND", label: "North Dakota" },
    { code: "OH", label: "Ohio" },
    { code: "OK", label: "Oklahoma" },
    { code: "OR", label: "Oregon" },
    { code: "PA", label: "Pennsylvania" },
    { code: "PR", label: "Puerto Rico" },
    { code: "RI", label: "Rhode Island" },
    { code: "SC", label: "South Carolina" },
    { code: "SD", label: "South Dakota" },
    { code: "TN", label: "Tennessee" },
    { code: "TX", label: "Texas" },
    { code: "UT", label: "Utah" },
    { code: "VT", label: "Vermont" },
    { code: "VI", label: "Virgin Islands" },
    { code: "VA", label: "Virginia" },
    { code: "WA", label: "Washington" },
    { code: "WX", label: "West Virginia" },
    { code: "WI", label: "Wisconsin" },
    { code: "WY", label: "Wyoming" },
]
const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];