import React, { useState, useEffect, useRef } from 'react'
import { Add, ArrowDownward, CallReceived, ArrowRightAlt, Refresh, Clear, Crop, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, VisibilityTwoTone, Phone, Info, Public, PrintDisabled, PrintOutlined } from '@material-ui/icons';
import { useHistory, NavLink, useParams } from "react-router-dom";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Command, Copy, File, Filter, Minus, Package } from 'react-feather';
import axios from "axios";
import "./style.css";
import { FcCheckmark, FcHighPriority } from 'react-icons/fc';
import StaffBill2 from './StaffBill2';
import Inv2 from './Inv2';
import ShippingMark from './ShippingMark';
import { useReactToPrint } from 'react-to-print';
import { db } from "../firebase";
import { CircularProgress, IconButton } from '@material-ui/core';
import firebase from "firebase";
export default function Hawb2({ user, userData }) {
    const [packageValue, setPackageValue] = useState([{
        packageLength: 0,
        packageWidth: 0,
        packageHeight: 0,
        packageWeight: 0,
        packageCharge: 0,
        packNo: 1,
    }]);
    const [invoiceValue, setInvoiceValue] = useState([{
        itemCommonity: "",
        itemCountryOrigin: "Vietnam",
        itemManufactAddress: "",
        itemPackType: "",
        itemPackType2: "",
        itemHsCode: "",
        itemPnc: "",
        itemUnit: "",
        itemPurpose: "",
        itemManufact: "",
        itemUnitPrice: 0,
        itemQuantity: "",
        itemValue: 0,
        itemBrand: "",
        itemIngredients: "",
        itemNo: 1,
        itemFda: "",
        itemUnit2: "",
    }]);
    const history = useHistory();
    const [type, setType] = useState("WPX");
    const [displayAccount, setDisplayAccount] = useState(false);
    const [searchEmail, setSearchEmail] = useState("");
    const [shipments, setShipments] = useState([]);
    const [code, setCode] = useState("");
    const [displayCountry, setDisplayCountry] = useState(false);
    const [country, setCountry] = useState("");
    const [fromCountry, setFromCountry] = useState("");
    const [importer, setImporter] = useState("");
    const [exporter, setExporter] = useState("");
    const [fromAddress, setFromAddress] = useState("");
    const [fromAddress2, setFromAddress2] = useState("");
    const [fromAddress3, setFromAddress3] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [fromTown, setFromTown] = useState("");
    const [town, setTown] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [city, setCity] = useState("");
    const [fromState, setFromState] = useState("");
    const [state, setState] = useState("");
    const [fromPostal, setFromPostal] = useState("");
    const [postal, setPostal] = useState("");
    const [fromName, setFromName] = useState("")
    const [toName, setToName] = useState("");
    const [fromPhone, setFromPhone] = useState("");
    const [phone, setPhone] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [email, setEmail] = useState("");
    const [term, setTerm] = useState("");
    const [transport, setTransport] = useState(0);
    const [reason, setReason] = useState("");
    const [eori, setEori] = useState("");
    const [service, setService] = useState("");
    const [validBook, setValidBook] = useState(false);
    const [review, setReview] = useState(false);
    const [dateTime, setDateTime] = useState("");
    const [loading, setLoading] = useState(true);
    const [traceTime, setTraceTime] = useState("");
    const [datePicker, setDatePicker] = useState("");
    const [envelope, setEnvelope] = useState("");
    const [doxWeight, setDoxWeight] = useState(0.5);
    const [eamService, setEamService] = useState("");
    const [bookedDate, setBookedDate] = useState("");
    const [doxErr, setDoxErr] = useState(false);
    const [controller, setController] = useState("");
    const [renderPrint, setRenderPrint] = useState(false);
    const [status, setStatus] = useState("");
    const [hawbInput, setHawbInput] = useState("");
    const [pickupBy, setPickupBy] = useState("");
    const [timestamp, setTimestamp] = useState("");
    const [sin, setSin] = useState("");
    const [uid, setUid] = useState("");
    const [makh, setMakh] = useState("");
    const [desc, setDesc] = useState([]);
    const [createAt, setCreateAt] = useState("");
    const [time, setTime] = useState("");
    const [rate, setRate] = useState("");
    const [plan, setPlan] = useState("");
    const [description, setDescription] = useState("");
    const [pickup, setPickup] = useState("");
    const [arrive, setArrive] = useState("");
    const [process, setProcess] = useState("");
    const [clearance, setClearance] = useState("");
    const [clear, setClear] = useState("");
    const [hold, setHold] = useState("");
    const { hawb } = useParams();
    useEffect(() => {
        db.collection("shipments").doc(hawb).get().then((doc) => {
            if (doc.exists) {
                if (doc.data().shipmentType === "WPX") {
                    setType(doc.data().shipmentType);
                    setCode(doc.data().code);
                    setCountry(doc.data().toCountry);
                    setImporter(doc.data().importer);
                    setAddress(doc.data().toAddress);
                    setAddress2(doc.data().toAddress2);
                    setAddress3(doc.data().toAddress3);
                    setTown(doc.data().toTown);
                    setCity(doc.data().toCity);
                    setState(doc.data().toState);
                    setPostal(doc.data().toPostal);
                    setToName(doc.data().toName);
                    setPhone(doc.data().toPhone);
                    setEmail(doc.data().toEmail);
                    setPackageValue(doc.data().packageValue);
                    setInvoiceValue(doc.data().invoiceValue);
                    setTerm(doc.data().term);
                    setTransport(doc.data().transportation);
                    setReason(doc.data().usage);
                    setService(doc.data().service);
                    setEori(doc.data().eori);
                    setExporter(doc.data().exporter);
                    setFromCountry(doc.data().fromCountry);
                    setFromAddress(doc.data().fromAddress);
                    setFromAddress2(doc.data().fromAddress2);
                    setFromAddress3(doc.data().fromAddress3);
                    setFromTown(doc.data().fromTown);
                    setFromCity(doc.data().fromCity);
                    setFromState(doc.data().fromState);
                    setFromPostal(doc.data().fromPostal);
                    setFromName(doc.data().fromName);
                    setFromPhone(doc.data().fromPhone);
                    setTerm(doc.data().term);
                    setFromEmail(doc.data().fromEmail);
                    setBookedDate(doc.data().dateTime);
                    setController(doc.data().controller);
                    setHawbInput(hawb);
                    setPickupBy(doc.data().pickupBy);
                    setStatus(doc.data().status);
                    setSin(doc.data().sin);
                    setTimestamp(doc.data().timestamp);
                    setUid(doc.data().uid);
                    setMakh(doc.data().makh);
                    setDesc(doc.data().desc);
                    setCreateAt(doc.data().createAt);
                    setTime(doc.data().time);
                    setRate(doc.data().rate);
                    setPlan(doc.data().plan);
                    setPickup(doc.data().pickupBy);
                    setArrive(doc.data().receiveBy);
                    setProcess(doc.data().processBy);
                    setClearance(doc.data().clearanceBy);
                    setClear(doc.data().clearedBy);
                    setHold(doc.data().holdBy);
                    setDescription(doc.data().description);
                } else if (doc.data().shipmentType === "DOX") {
                    setType(doc.data().shipmentType);
                    setCode(doc.data().code);
                    setHawbInput(hawb);
                    setUid(doc.data().uid);
                    setCountry(doc.data().toCountry);
                    setImporter(doc.data().importer);
                    setAddress(doc.data().toAddress);
                    setAddress2(doc.data().toAddress2);
                    setAddress3(doc.data().toAddress3);
                    setTown(doc.data().toTown);
                    setCity(doc.data().toCity);
                    setState(doc.data().toState);
                    setPostal(doc.data().toPostal);
                    setToName(doc.data().toName);
                    setMakh(doc.data().makh);
                    setPhone(doc.data().toPhone);
                    setPickupBy(doc.data().pickupBy);
                    setEmail(doc.data().toEmail);
                    setTerm(doc.data().term);
                    setTransport(doc.data().transportation);
                    setEnvelope(doc.data().totalPackage);
                    setDoxWeight(doc.data().totalCharge);
                    setReason(doc.data().usage);
                    setService(doc.data().service);
                    setEori(doc.data().eori);
                    setTimestamp(doc.data().timestamp);
                    setExporter(doc.data().exporter);
                    setFromCountry(doc.data().fromCountry);
                    setFromAddress(doc.data().fromAddress);
                    setFromAddress2(doc.data().fromAddress2);
                    setFromAddress3(doc.data().fromAddress3);
                    setFromTown(doc.data().fromTown);
                    setFromCity(doc.data().fromCity);
                    setFromState(doc.data().fromState);
                    setFromPostal(doc.data().fromPostal);
                    setFromName(doc.data().fromName);
                    setFromPhone(doc.data().fromPhone);
                    setFromEmail(doc.data().fromEmail);
                    setBookedDate(doc.data().dateTime);
                    setController(doc.data().controller);
                    setStatus(doc.data().status);
                    setSin(doc.data().sin);
                    setCreateAt(doc.data().createAt);
                    setRate(doc.data().rate);
                    setTime(doc.data().time);
                    setDescription("Document");
                    setPlan(doc.data().plan);
                    setPickup(doc.data().pickupBy);
                    setArrive(doc.data().receiveBy);
                    setProcess(doc.data().processBy);
                    setClearance(doc.data().clearanceBy);
                    setClear(doc.data().clearedBy);
                    setHold(doc.data().holdBy);
                }
                setLoading(false);

            } else {
                alert("This shipment may not exist in the system");
                history.goBack();
                setLoading(false);
            }
        })
    }, [])

    let handleChange = (i, e) => {
        let newFormValues = [...packageValue];
        newFormValues[i][e.target.name] = e.target.value;
        setPackageValue(newFormValues);
    };
    let invoiceChange = (i, e) => {
        let newInvoiceValues = [...invoiceValue];
        newInvoiceValues[i][e.target.name] = e.target.value;
        setInvoiceValue(newInvoiceValues);
    }
    let addFormFields = () => {
        let x = packageValue.length;
        setPackageValue([...packageValue, { packNo: x + 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0.00, packageCharge: 0 }])
    };
    let addCarton = () => {
        let x = invoiceValue.length;
        setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: "", itemIngredients: "", itemUnit2: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }])
    }
    let removeFormField = (i) => {
        let newFormValues = [...packageValue];
        newFormValues.splice(i, 1);
        setPackageValue(newFormValues);
    }
    let removeInvoice = (i) => {
        let newInvoiceValues = [...invoiceValue];
        newInvoiceValues.splice(i, 1);
        setInvoiceValue(newInvoiceValues);
    }
    let emailRef = useRef();
    let countryRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!emailRef.current.contains(e.target)) {
                setDisplayAccount(false);
            }
            if (!countryRef.current.contains(e.target)) {
                setDisplayCountry(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        setDatePicker(`${year}-${month}-${date}`);
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${date} ${monthName} ${year}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);

    }, [])
    const handleChangeService = (e) => {
        if (e.target.value === "DHL") {
            setEamService("INT");
        }
        if (e.target.value === "FIP") {
            setEamService("FIP")
        }
        setService(e.target.value);
    }
    const sortedEmail = [...new Set(shipments.map(s => s.fromEmail))];
    const sortedEmail1 = sortedEmail.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const renderPrintOption = () => {
        setRenderPrint(true);
    }
    let labelRef = useRef();
    let invoiceRef = useRef();
    let shippingMarkRef = useRef();
    let printAllRef = useRef();
    const printLabel = useReactToPrint({
        content: () => labelRef.current,
    })
    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
    })
    const printShippingMark = useReactToPrint({
        content: () => shippingMarkRef.current,
    })
    const printAll = useReactToPrint({
        content: () => printAllRef.current,
    })
    const bookShipment = (e) => {
        e.preventDefault();
        setLoading(true);
        if (status === "Pending") {
            createShipment();
        } else if (status !== "Pending") {
            createShipment();
            editShipment();
        }
    }
    const bookDox = () => {
        setLoading(true);
        if (status === "Pending") {
            createDox();
        } else if (status !== "Pending") {
            createDox();
            editShipment();
        }
    }
    const editShipment = () => {
        axios.put("https://test.skydart.com.vn/api/update/shipment/service", {
            hawb: hawb,
            service: service,
            eamFormat: type === "DOX" ? "DOX" : "NDX",
            code: code,
            value: totalInvoiceValue,
            company: importer,
            country: country,
            state: state,
            town: town,
            city: city,
            address: address,
            address2: address2,
            address3: address3,
            postal: postal,
            phone: phone,
            contact: toName,
            chargeable: type === "DOX" ? doxWeight : finalCharge,
            eamService: eamService,
        })
    }
    const createDox = () => {
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
            setLoading(false);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
            setLoading(false);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            db.collection("shipments").doc(hawb).update({
                service: service,
                controller: controller,
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                code: code,
                value: totalInvoiceValue,
                toPhone2: "",
                toCountry: country,
                importer: importer,
                toCity: city,
                toState: state,
                toTown: town,
                toAddress: address,
                toAddress2: address2,
                toAddress3: address3,
                toPostal: postal,
                toPhone: phone,
                toName,
                toEmail: email,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge,
                reweight: type === "DOX" ? doxWeight : finalCharge,
                weight: type === "DOX" ? doxWeight : finalGross,
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                description: "Document",
            }).then(() => {
                setLoading(false);
                history.goBack();
            })
        }
    }
    const createShipment = () => {
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        setLoading(true);
        setTimeout(() => {
            db.collection("shipments").doc(hawb).update({
                service: service,
                controller: controller,
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                code: code,
                value: totalInvoiceValue,
                toPhone2: "",
                toCountry: country,
                importer: importer,
                toCity: city,
                toState: state,
                toTown: town,
                toAddress: address,
                toAddress2: address2,
                toAddress3: address3,
                toPostal: postal,
                toPhone: phone,
                toName,
                toEmail: email,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge,
                reweight: type === "DOX" ? doxWeight : finalCharge,
                weight: type === "DOX" ? doxWeight : finalGross,
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                eori: eori,
                description: type === "DOX" ? "Document" : arr + ",",
                term: type === "DOX" ? "Document" : term,
                transportation: transport,
                totalItemValue: finalInvoiceValue.toFixed(2).toString(),
                totalInvoiceValue: totalInvoiceValue.toFixed(2).toString(),
                usage: reason,
            }).then(() => {
                setLoading(false);
                history.goBack();
            })
        }, 1000)
    }
    const finalCharge = packageValue.reduce((a, v) => +a + +v.packageCharge, 0);
    const finalGross = packageValue.reduce((a, v) => +a + +v.packageWeight, 0);
    const finalInvoiceValue = invoiceValue.reduce((a, v) => a = +a.toFixed(2) + +v.itemValue.toFixed(2), 0);
    const totalInvoiceValue = +finalInvoiceValue + +transport;
    return (
        <div className='skydart-create'>
            {renderPrint && <div className="skydart-create-print">
                <div className="skydart-create-print-container">
                    <div className="skydart-create-print-header">
                        <h3>Printing Option</h3>
                        <IconButton type="button" style={{ width: 35, height: 35, background: "rgb(236,236,236)" }} onClick={() => setRenderPrint(false)}><Clear fontSize="small" /></IconButton>
                    </div>
                    <div className="skydart-create-print-button1" onClick={() => setRenderPrint(false)}>
                        <h5>Review Shipment</h5>
                    </div>
                    <div className="skydart-create-print-body">
                        <div className="skydart-create-print-button" onClick={printLabel}>
                            <h5>Print Label</h5>
                        </div>
                        <div className="skydart-create-print-button" onClick={printInvoice}>
                            <h5>Print Invoice</h5>
                        </div>
                        <div className="skydart-create-print-button" onClick={printShippingMark}>
                            <h5>Print Shipping Mark</h5>
                        </div>
                        <div className="skydart-create-print-button" onClick={printAll}>
                            <h5>Print All</h5>
                        </div>
                    </div>
                </div>
            </div>}
            {loading && <div className="skydart-create-print">
                <div className="skydart-create-print-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress size={35} style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }} />
                    <h5 style={{ padding: 10, marginLeft: 7 }}>Loading...</h5>
                </div>
            </div>}
            <div className='skydart-create-container'>
                <div className='skydart-create-render'>
                    <div className='skydart-create-side'>
                        <div className="skydart-create-side-option" onClick={() => history.goBack()}>
                            <Clear className="skydart-create-clear" />
                        </div>
                        <NavLink className="skydart-create-side-option" activeClassName="skydart-create-side-option1" exact to="/booking">
                            <InsertDriveFile className="skydart-create-clear1" />
                        </NavLink>
                    </div>
                    <div className='skydart-create-title'>
                        <div className="skydart-create-title-header">
                            <img style={{ cursor: "pointer" }} src="https://i.imgur.com/L6PnhXq.png" alt="logo" width="35" />
                            <div>
                                <h5 style={{ fontWeight: 600, marginLeft: 5, fontSize: 16 }}>Edit Shipment</h5>
                                <h5 style={{ fontWeight: 500, background: "whitesmoke", fontSize: 10, width: "fit-content", marginLeft: 5, color: "gray" }}>Today: February, 14 2022</h5>
                            </div>
                        </div>
                        <div className='skydart-create-title-type'>
                            <BorderLeft fontSize="small" />
                            <h3>Shipping Type</h3>
                            <ArrowForward fontSize="small" style={{ marginLeft: "auto", background: "rgb(231, 241, 255)", color: "#0068be", borderRadius: 20 }} />
                        </div>
                        {type === "WPX" && <div className="skydart-create-title-type-list1">
                            <Package size={20} />
                            <h5>Parcel (WPX)</h5>
                        </div>}
                        {type === "DOX" && <div className="skydart-create-title-type-list1">
                            <File size={20} />
                            <h5>Document (DOX)</h5>
                        </div>}
                        {userData.role === "Admin" && <>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Picked up by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{pickup}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Received by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{arrive}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Process by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{process}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Clearance by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{clearance}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Cleared by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{clear}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Holded by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{hold}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Current status: <strong style={{ marginLeft: "auto", fontWeight: 600, background: "darkblue", color: "white", textDecoration: "underline" }}>{status}</strong></h5>
                            </div>
                        </>}
                        {userData.role === "CEO" && <>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Picked up by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{pickup}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Received by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{arrive}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Process by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{process}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Clearance by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{clearance}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Cleared by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{clear}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Holded by: <strong style={{ marginLeft: "auto", textDecoration: "underline" }}>{hold}</strong></h5>
                            </div>
                            <div className="skydart-create-title-type-list">
                                <h5 style={{ display: "flex", width: "100%" }}>Current status: <strong style={{ marginLeft: "auto", fontWeight: 600, background: "darkblue", color: "white", textDecoration: "underline" }}>{status}</strong></h5>
                            </div>
                        </>}
                        {/* {type !== "ECS" && <div className="skydart-create-title-type-list" onClick={() => setType("ECS")}>
                            <Storefront fontSize="small" />
                            <h5>E-commerce (ECS)</h5>
                        </div>}
                        {type === "ECS" && <div className="skydart-create-title-type-list1">
                            <Storefront fontSize="small" />
                            <h5>E-commerce (ECS)</h5>
                        </div>} */}
                    </div>
                    <form className="skydart-booking-form" onSubmit={bookShipment}>
                        <div className="skydart-booking-form-header">
                            <div className="skydart-booking-form-header-left">
                                <Package size={20} />
                                <h5>Shipping type: <strong style={{ fontWeight: 900 }}>({type})</strong></h5>
                            </div>
                            <div className="skydart-booking-form-header-center">
                                <ArrowForwardIos style={{ fontSize: 12, color: "black" }} />
                            </div>
                            <div className="skydart-booking-account">
                                <h5>Booking for account</h5>
                            </div>
                            <div className="skydart-booking-form-header-center">
                                <ArrowForwardIos style={{ fontSize: 12, color: "black" }} />
                            </div>
                            <div className="skydart-booking-account">
                                <h5 style={{ color: "gray" }}>Please select an account</h5>
                            </div>
                            <div className="skydart-booking-form-header-center">
                                <ArrowForwardIos style={{ fontSize: 12, color: "black" }} />
                            </div>
                            <div className="skydart-booking-account-input" ref={emailRef}>
                                <Email style={{ position: "absolute", marginLeft: 5, fontSize: 15 }} />
                                <input type="text" value={userData.email} placeholder="Search and filter account" />
                                {!displayAccount && <ArrowDropDown style={{ fontSize: 15, color: "black", marginLeft: 5 }} />}
                                {/* {displayAccount && <ArrowDropUp style={{ fontSize: 15, color: "black", marginLeft: 5 }} onClick={() => setDisplayAccount(false)} />}
                                {displayAccount && <div className="skydart-booking-account-render">
                                    {sortedEmail1.filter((s) => {
                                        if (s.toLowerCase().includes(searchEmail.toLowerCase())) {
                                            return s;
                                        }
                                    }).map((s, index) => (
                                        <div className="skydart-booking-account-option" onClick={() => { setSearchEmail(s); setDisplayAccount(false) }} key={index}>
                                            <Email style={{ fontSize: 15 }} />
                                            <h5 style={{ fontSize: 10, fontWeight: 600 }}>{s}</h5>
                                        </div>
                                    ))}
                                </div>} */}
                            </div>
                        </div>
                        <div className="skydart-booking-nav-header">
                            {review && <div className="skydart-booking-edit" onClick={() => setReview(false)}>
                                <Edit fontSize="small" />
                                <h5>Booking</h5>
                            </div>}
                            {!review && <div className="skydart-booking-edit1">
                                <Edit fontSize="small" />
                                <h5>Booking</h5>
                            </div>}
                            {!review && <div className="skydart-booking-edit" onClick={() => setReview(true)}>
                                <VisibilityTwoTone fontSize="small" />
                                <h5>Review</h5>
                            </div>}
                            {review && <div className="skydart-booking-edit1">
                                <VisibilityTwoTone fontSize="small" />
                                <h5>Review</h5>
                            </div>}
                        </div>
                        {!review && <div className="skydart-booking-box">
                            <div className='skydart-booking-box-left'>
                                <div className='skydart-booking-box-first'>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <h5 style={{ marginRight: 5 }}>Destination Country/Region</h5>
                                        {country === "" && <FcHighPriority className="skd-book-icon" />}
                                        {country !== "" && <FcCheckmark className="skd-book-icon" />}
                                    </div>
                                    <div className="skydart-booking-text-input" style={{ position: "relative" }}>
                                        <div style={{ display: "flex", width: "100%", alignItems: "center" }} ref={countryRef}>
                                            {code === "" && <Public className="skydart-country-icon" />}
                                            {code !== "" && <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
                                                alt=""
                                                style={{ position: "absolute", marginLeft: 5 }}
                                            />}
                                            <input type="text" placeholder="Enter your destination country / region here" style={{ paddingLeft: 30 }} value={country} onChange={(e) => {
                                                setCountry(e.target.value);
                                                if (e.target.value === "") {
                                                    setCode("");
                                                }
                                            }} onClick={() => setDisplayCountry(true)} />
                                            <div>
                                                {displayCountry && <div className="skydart-booking-country-container">
                                                    {countries.filter((s) => {
                                                        if (s.label.toLowerCase().startsWith(country.toLowerCase())) {
                                                            return s;
                                                        }
                                                    }).map((s, index) => (
                                                        <div className="skydart-booking-country" onClick={() => { setCountry(s.label); setCode(s.code); setDisplayCountry(false) }} key={index}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            <h5>{s.label} ({s.code})</h5>
                                                        </div>
                                                    ))}
                                                </div>}
                                            </div>
                                        </div>

                                        <select value={code} onChange={(e) => {
                                            setCode(e.target.value.slice(0, 2));
                                            setCountry(e.target.value.substring(2))
                                        }}>
                                            <option value="">By Code</option>
                                            {countries.map((s) => (
                                                <option value={s.code + s.label} onClick={() => setCountry(s.label)}>{s.code}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="skydart-edit-waybill">
                                        <h5 style={{ fontWeight: 600, fontSize: 12 }}>HAWB (Not recommended)</h5>
                                        <input type="text" value={hawbInput} onChange={(e) => setHawbInput(e.target.value)} style={{ padding: 7, border: "rgb(236,236,236)", background: "rgb(236,236,236)", outline: "none", marginTop: 3 }} />
                                        <button type="button" style={{ padding: 7, borderRadius: 5, fontWeight: 600, marginLeft: 7, border: "none", background: "rgb(230,230,230)", cursor: "pointer" }}>Update Awb</button>
                                    </div>
                                </div>
                                <div className='skydart-booking-box-first'>
                                    <div>
                                        <h5 style={{ marginRight: 5 }}>Recipient Information</h5>
                                        <h5 style={{ fontSize: 10, marginTop: 3, fontWeight: 500 }}>All the field that include the warning icon are requirement information that need to be declared before ship your package / document.</h5>
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Company name</h5>
                                            {importer === "" && <FcHighPriority />}
                                            {importer !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" value={importer} onChange={(e) => setImporter(e.target.value)} maxLength={30} placeholder="Enter importer's company or full name here" />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Address</h5>
                                            {address === "" && <FcHighPriority />}
                                            {address !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} maxLength={30} />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Address 2</h5>
                                        </div>
                                        <input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} maxLength={30} />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Address 3</h5>
                                        </div>
                                        <input type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} maxLength={30} />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Town</h5>
                                            {town === "" && <FcHighPriority />}
                                            {town !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" value={town} onChange={(e) => setTown(e.target.value)} maxLength={30} placeholder="Enter destination's town" />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>City</h5>
                                            {city === "" && <FcHighPriority />}
                                            {city !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" placeholder="Enter your destination's city here" value={city} onChange={(e) => setCity(e.target.value)} maxLength={30} />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>State</h5>
                                        </div>
                                        <input type="text" value={state} onChange={(e) => setState(e.target.value)} maxLength={30} />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Postal Code</h5>
                                            {postal === "" && <FcHighPriority />}
                                            {postal !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" value={postal} onChange={(e) => setPostal(e.target.value)} maxLength={30} placeholder="Destination's zip/postal code" />
                                    </div>
                                </div>
                                <div className='skydart-booking-box-first'>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <h5 style={{ marginRight: 5 }}>Contact Detail</h5>
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h5>Contact Name</h5>
                                            {toName === "" && <FcHighPriority />}
                                            {toName !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" value={toName} onChange={(e) => setToName(e.target.value)} maxLength={30} placeholder="Enter your recipient's contact name" />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Phone style={{ fontSize: 15 }} />
                                            <h5 style={{ marginLeft: 5 }}>Phone Number</h5>
                                            {phone === "" && <FcHighPriority />}
                                            {phone !== "" && <FcCheckmark />}
                                        </div>
                                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={30} />
                                    </div>
                                    <div className="skydart-booking-text-input1">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Email style={{ fontSize: 15 }} />
                                            <h5 style={{ marginLeft: 5 }}>Email Address</h5>
                                        </div>
                                        <input type="text" placeholder="Recipient's email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ margin: 5, width: 100, height: 50 }}>
                                </div>
                            </div>
                            <div className="skydart-booking-box-right">
                                <div className="skydart-booking-box-right-header">
                                    <div className="skydart-booking-box-right-top">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Info htmlColor="#0074D9" />
                                            <h5 style={{ marginLeft: 5, fontWeight: 600, fontSize: 12 }}>Where is sender/origin info ?</h5>
                                        </div>
                                        <div className="skydart-booking-box-right-render-more">
                                            <ArrowDropDown />
                                        </div>
                                    </div>
                                    <div className="skydart-booking-box-right-top-text">
                                        <h5>Sender/Origin information will be saved as registration info by customers.</h5>
                                    </div>
                                    <div className="skydart-booking-box-right-top-last">
                                        <div className="skydart-box-right" onClick={() => setReview(true)}>
                                            <h5>View Info</h5>
                                        </div>
                                    </div>
                                </div>
                                {type === "WPX" && <div className="skydart-booking-box-right-second">
                                    <div className="skydart-booking-box-right-second-header">
                                        <Package size={20} />
                                        <h5>Packing Information</h5>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total packages:</h5>
                                            <input type="number" value={packageValue.length} disabled />
                                        </div>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total gross weight:</h5>
                                            <input type="text" value={(Math.round(finalGross * 100) / 100).toFixed(2)} disabled style={{ background: "rgb(238,238,238)" }} />
                                        </div>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total charge:</h5>
                                            <input type="text" value={(Math.round(finalCharge * 100) / 100).toFixed(2)} disabled style={{ background: "rgb(238,238,238)" }} />
                                        </div>
                                    </div>
                                    <div className="skydart-pack-num-header">
                                        <div className="skydart-pack-num-header-option">
                                            <h5 style={{ fontSize: 9, marginLeft: 7, textDecoration: "underline" }}>Weight</h5>

                                        </div>
                                        <div className="skydart-pack-num-header-dim">
                                            <h5 style={{ fontSize: 9, textDecoration: "underline", textAlign: "center" }}>Dimensions (Cm)</h5>
                                        </div>
                                        <div className="skydart-pack-total-header-charge">
                                            <h5 style={{ fontSize: 9, marginLeft: 4, textDecoration: "underline" }}>Chargeable</h5>

                                        </div>
                                    </div>
                                    {packageValue.map(((element, index) => {
                                        const dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 5000;
                                        const grossWeight = element.packageWeight;

                                        if (dimCharge < grossWeight) {
                                            element.packageCharge = grossWeight * 1;
                                            element.packageWeight = grossWeight * 1;
                                        } else if (grossWeight < dimCharge) {
                                            element.packageCharge = dimCharge;
                                            element.packageWeight = grossWeight * 1;

                                        }
                                        if (element.packageCharge >= 30) {
                                            element.packageCharge = Math.ceil(element.packageCharge);
                                        } else if (element.packageCharge < 30) {
                                            element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
                                        }
                                        if (element.packageWeight === "") {
                                            element.packageCharge = "0.00";
                                        }

                                        // setTotalGross(finalGross);
                                        return <div key={index} className="skydart-pack-value">
                                            {/* <h5 style={{ marginLeft: 4, color: "black" }}>Pack {element.packNo}</h5> */}

                                            <div className="skydart-pack-num-body">
                                                <div className="skydart-pack-num-body-option">
                                                    <input type="number" placeholder="KG" required value={element.packageWeight || ""} onChange={e => handleChange(index, e)} name="packageWeight" />
                                                </div>
                                                <div className="skydart-pack-num-body-dim">
                                                    <input type="number" placeholder="Length" name="packageLength" value={element.packageLength || ""} onChange={e => handleChange(index, e)} />
                                                    <input type="number" placeholder="Width" name="packageWidth" value={element.packageWidth || ""} onChange={e => handleChange(index, e)} />
                                                    <input type="number" placeholder="Height" name="packageHeight" value={element.packageHeight || ""} onChange={e => handleChange(index, e)} />
                                                </div>
                                                <div className="skydart-pack-total-body-charge">
                                                    <input type="number" placeholder="Chargeable" disabled value={(Math.round(element.packageCharge * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} name="packageCharge" />
                                                    <div className="skydart-pack-option">
                                                        <Copy size={10} className="skydart-pack-copy" onClick={() => {
                                                            setPackageValue([...packageValue, { packageLength: element.packageLength, packageWidth: element.packageWidth, packageHeight: element.packageHeight, packageWeight: element.packageWeight, packageCharge: element.packageCharge }])
                                                        }} />
                                                        {packageValue.length !== 1 && <Minus size={10} className="skydart-pack-copy" onClick={removeFormField} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    ))}
                                    <div className="skydart-pack-add-btn">
                                        <button type="button" onClick={addFormFields}>+</button>
                                    </div>
                                </div>}
                                {type === "WPX" && <div className="skydart-booking-box-right-second">
                                    <div className="skydart-booking-box-right-second-header">
                                        <h5 style={{ marginLeft: 0 }}>Description Of Goods</h5>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total items:</h5>
                                            <input type="number" value={invoiceValue.length} disabled />
                                        </div>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total item value:</h5>
                                            <input type="text" value={+finalInvoiceValue.toFixed(2)} disabled style={{ background: "rgb(238,238,238)" }} />
                                        </div>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total invoice value:</h5>
                                            <input type="text" value={+totalInvoiceValue.toFixed(2)} disabled style={{ background: "rgb(238,238,238)" }} />
                                        </div>
                                    </div>

                                    <div className="skydart-invoice-declare">
                                        {invoiceValue.map((s, index) => {
                                            let donGia1 = s.itemUnitPrice;
                                            let soLuong1 = s.itemQuantity;
                                            let tong1 = donGia1 * soLuong1;
                                            s.itemValue = tong1;
                                            return <div className="skydart-invoice-declare-container">
                                                <div className="skydart-invoice-declare-long">
                                                    <div className="skydart-invoice-long">
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <h5>Item {s.itemNo}</h5>
                                                            <div className="skydart-pack-option">
                                                                <Copy size={10} className="skydart-pack-copy" onClick={() => {
                                                                    let x = invoiceValue.length;
                                                                    setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: s.itemBrand, itemIngredients: s.itemIngredients, itemUnit2: s.itemUnit2, itemPackType2: s.itemPackType2, itemCommonity: s.itemCommonity, itemUnit: s.itemUnit, itemUnitPirce: s.itemUnitPrice, itemQuantity: s.itemQuantity, itemValue: s.itemValue, itemManufact: s.itemManufact, itemPurpose: s.itemPurpose, itemHsCode: s.itemHsCode, itemPackType: s.itemPackType, itemManufactAddress: s.itemManufactAddress, itemCountryOrigin: s.itemCountryOrigin, itemFda: s.itemFda }])
                                                                }} />
                                                                {invoiceValue.length !== 1 && <Minus size={10} className="skydart-pack-copy" onClick={removeInvoice} />}
                                                            </div>
                                                        </div>
                                                        <input type="text" placeholder="Declare your item's name here" required name="itemCommonity" value={s.itemCommonity} onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                </div>
                                                <div className="skydart-invoice-declare-invoice-header">
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Brand name</h5>
                                                        <input type="text" value={s.itemBrand} name="itemBrand" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Packing type</h5>
                                                        <select value={s.itemPackType || ""} name="itemPackType" onChange={e => invoiceChange(index, e)}>
                                                            <option value="">Type</option>
                                                            <option value="Bag">Bag</option>
                                                            <option value="Box">Box</option>
                                                            <option value="Carton">Carton</option>
                                                            <option value="Wooden Pack">Wooden Pack</option>
                                                        </select>
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Ingredient</h5>
                                                        <input type="text" value={s.itemIngredients} name="itemIngredients" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                </div>
                                                <div className="skydart-invoice-declare-invoice-header">
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Usage</h5>
                                                        <input type="text" value={s.itemPurpose} name="itemPurpose" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Country of origin</h5>
                                                        <input type="text" value={s.itemCountryOrigin} name="itemCountryOrigin" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Manufacturer name</h5>
                                                        <input type="text" name="itemManufact" value={s.itemManufact} onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                </div>
                                                <div className="skydart-invoice-declare-long">
                                                    <div className="skydart-invoice-long">
                                                        <h5>Manufacturer Address</h5>
                                                        <input type="text" placeholder="Declare address of item's origin" name="itemManufactAddress" value={s.itemManufactAddress} onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                </div>
                                                <div className="skydart-invoice-declare-invoice-header">
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Hs code</h5>
                                                        <input type="text" name="itemHsCode" value={s.itemHsCode} onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>FDA</h5>
                                                        <input type="text" value={s.itemFda || ""} name="itemFda" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Quantity</h5>
                                                        <input type="number" required value={s.itemQuantity || ""} name="itemQuantity" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                </div>
                                                <div className="skydart-invoice-declare-invoice-header">
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Unit</h5>
                                                        {s.itemUnit !== "Other" && <select name="itemUnit" value={s.itemUnit} onChange={e => invoiceChange(index, e)}>
                                                            <option value="">Unit</option>
                                                            <option value="Pcs">Pcs</option>
                                                            <option value="Pair">Pair</option>
                                                            <option value="Kg">Kg</option>
                                                            <option value="Set">Set</option>
                                                            <option value="Bag">Bag</option>
                                                            <option value="Other">Other</option>
                                                        </select>}
                                                        {s.itemUnit === "Other" && <div style={{ display: "flex" }}>
                                                            <input type="text" value={s.itemUnit2 || ""} name="itemUnit2" onChange={e => invoiceChange(index, e)} />
                                                            <select value={s.itemUnit || ""} name="itemUnit" onChange={e => invoiceChange(index, e)} style={{ width: "18%" }}>
                                                                <option value="">Unit</option>
                                                                <option value="Pcs">Pcs</option>
                                                                <option value="Pair">Pair</option>
                                                                <option value="Kg">Kg</option>
                                                                <option value="Set">Set</option>
                                                                <option value="Bag">Bag</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>}
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Unit price (USD)</h5>
                                                        <input type="number" required value={s.itemUnitPrice || ""} name="itemUnitPrice" onChange={e => invoiceChange(index, e)} />
                                                    </div>
                                                    <div className="skydart-invoice-declare-brand-header">
                                                        <h5>Total amount (USD)</h5>
                                                        <input type="text" style={{ background: "rgb(240,240,240)" }} disabled value={s.itemValue.toFixed(2) + " " + "USD" || "0.00" + "USD"} />
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        <div className="skydart-pack-add-btn">
                                            <button type="button" onClick={addCarton}>+</button>
                                        </div>
                                    </div>
                                </div>}
                                {type === "WPX" && <div className="skydart-booking-box-right-second">
                                    <div className="skydart-booking-box-right-second-header">
                                        <h5 style={{ marginLeft: 0 }}>Term Of Transportation</h5>
                                    </div>
                                    <div className="skydart-term-selecting">
                                        <select value={term} onChange={(e) => setTerm(e.target.value)}>
                                            <option value="">Select your transport term</option>
                                            <option value="EXW">EX-WORD (EXW)</option>
                                            <option value="FOB">FREE ON BOARD (FOB)</option>
                                            <option value="CNF">COST & FREIGHT (CNF)</option>
                                            <option value="CIF">COST INSURANCE & FREIGHT (CIF)</option>
                                        </select>
                                        <h5 style={{ fontWeight: 600, fontSize: 11 }}>Transportation value:</h5>
                                        {term !== "FOB" && term !== "EXW" && <input type="number" placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} />}
                                        {term === "FOB" && <input type="number" style={{ background: "lightgray" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} disabled />}
                                        {term === "EXW" && <input type="number" style={{ background: "lightgray" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} disabled />}
                                        <select value={reason} onChange={(e) => setReason(e.target.value)} required>
                                            <option value="">Select your export reason</option>
                                            <option value="THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE">THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE</option>
                                            <option value="THE GOODS ARE E-COMMERCE SELLING">THE GOODS ARE E-COMMERCE SELLING</option>
                                            <option value="THE GOODS ARE RETURNED FOR MAINTENANCE">THE GOODS ARE RETURNED FOR MAINTENANCE</option>
                                            <option value="THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE">THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE</option>
                                            <option value="THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED">THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED</option>
                                        </select>
                                        <h5 style={{ fontWeight: 600, fontSize: 11 }}>Select a service:</h5>
                                        <select value={service} onChange={handleChangeService} required>
                                            <option value="">Select a service</option>
                                            <option value="SKD">SKD</option>
                                            <option value="DHL">SDH</option>
                                            <option value="UPS">SUPS</option>
                                            <option value="FIP">SIP</option>
                                            <option value="DHL-VN">VDH</option>
                                            <option value="Fedex-VN">VIP</option>
                                        </select>
                                        <input type="text" placeholder="Consignee IOSS / TIN / EORI NUMBER / ID Tax Number" value={eori} onChange={(e) => setEori(e.target.value)} />

                                    </div>
                                </div>}
                                {type === "DOX" && <div className="skydart-booking-box-right-second">
                                    <div className="skydart-booking-box-right-second-header">
                                        <File size={20} />
                                        <h5>Document Information</h5>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Number of envelope:</h5>
                                            <input type="number" value={envelope} onChange={(e) => setEnvelope(e.target.value)} />
                                        </div>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total gross weight:</h5>
                                            <input type="text" value={doxWeight} onChange={(e) => setDoxWeight(e.target.value)} />
                                        </div>
                                        <div className="skydart-booking-box-right-total-pack" style={{ marginLeft: 8 }}>
                                            <h5>Total charge:</h5>
                                            <input type="text" value={doxWeight} disabled style={{ background: "rgb(238,238,238)" }} />
                                        </div>
                                    </div>
                                    <select value={service} required onChange={handleChangeService} className="dox-service">
                                        <option value="">Select a service</option>
                                        <option value="SKD">SKD</option>
                                        <option value="DHL">SDH</option>
                                        <option value="FIP">SIP</option>
                                        <option value="UPS">SUPS</option>
                                        <option value="DHL-VN">VDH</option>
                                        <option value="Fedex-VN">VIP</option>
                                        <option value="UPS-VN">VUPS</option>
                                    </select>
                                </div>}
                            </div>
                        </div>}
                        {review && <div className="skydart-booking-box1" ref={countryRef} style={{ flexDirection: 'column' }}>
                            <div ref={printAllRef}>
                                <div className="skydart-booking-hawb" ref={labelRef}>
                                    <StaffBill2
                                        hawb={hawb}
                                        exporter={exporter}
                                        fromAddress={fromAddress}
                                        fromAddress2={fromAddress2}
                                        fromAddress3={fromAddress3}
                                        fromState={fromState}
                                        fromTown={fromTown}
                                        fromCity={fromCity}
                                        fromPostal={fromPostal}
                                        fromCountry={fromCountry}
                                        fromPhone={fromPhone}
                                        fromName={fromName}
                                        fromEmail={fromEmail}
                                        importer={importer}
                                        toAddress={address}
                                        toAddress2={address2}
                                        toAddress3={address3}
                                        toState={state}
                                        toTown={town}
                                        toCity={city}
                                        toPostal={postal}
                                        toCountry={country}
                                        toPhone={phone}
                                        toName={toName}
                                        toEmail={email}
                                        amount={packageValue.length}
                                        weight={type === "WPX" ? finalGross.toFixed(2) : doxWeight}
                                        chargeable={type === "WPX" ? finalCharge.toFixed(2) : doxWeight}
                                        type={type}
                                        invoiceValue={invoiceValue}
                                        date={createAt}
                                        pickupBy={pickupBy}
                                    />
                                </div>
                                {type === "WPX" && <div className="skydart-booking-hawb" ref={invoiceRef}>
                                    <Inv2
                                        invoice={invoiceValue}
                                        hawb={hawb}
                                        datePicker={createAt}
                                        fromCountry={fromCountry}
                                        toCountry={country}
                                        usage={reason}
                                        exporter={exporter}
                                        fromAddress={fromAddress}
                                        fromAddress2={fromAddress2}
                                        fromAddress3={fromAddress3}
                                        fromTown={fromTown}
                                        fromState={fromState}
                                        fromCity={fromCity}
                                        fromPostal={fromPostal}
                                        fromName={fromName}
                                        fromPhone={fromPhone}
                                        fromEmail={fromEmail}
                                        mst=""
                                        importer={importer}
                                        toAddress={address}
                                        toAddress2={address2}
                                        toAddress3={address3}
                                        toPostal={postal}
                                        toState={state}
                                        toTown={town}
                                        toCity={city}
                                        toName={toName}
                                        toPhone={phone}
                                        toEmail={email}
                                        eori={eori}
                                        transportation={transport}
                                        totalGross={finalGross.toFixed(2)}
                                        totalPackage={packageValue.length}
                                        totalItemValue={finalInvoiceValue}
                                        totalInvoiceValue={totalInvoiceValue}
                                    />
                                </div>}
                                {type === "WPX" && <div className="skydart-booking-hawb" ref={shippingMarkRef}>
                                    {packageValue.map((s, index) => (
                                        <ShippingMark
                                            no={index + 1}
                                            total={packageValue.length}
                                            hawb={hawb}
                                            weight={s.packageWeight}
                                            chargeable={s.packageCharge}
                                            length={s.packageLength}
                                            width={s.packageWidth}
                                            height={s.packageHeight}
                                            importer={importer}
                                            address={address}
                                            address2={address2}
                                            address3={address3}
                                            city={city}
                                            state={state}
                                            town={town}
                                            postal={postal}
                                            date={createAt}
                                            country={country}
                                            phone={phone}
                                            contact={toName} />
                                    ))}
                                </div>}
                            </div>
                        </div>}
                        <div className="skydart-booking-button">
                            <div className="skydart-booking-button-header">
                                {/* <h5>By click "Book Shipment", all of your shipping information will be saved and <strong style={{ fontWeight: 500, color: "#0068be", textDecoration: "underline" }}>Skydart's courier</strong> will pick your shipment up if requested as soon as Skydart receive your booking information.</h5> */}
                            </div>
                            <div className="skydart-booking-button-body">
                                <div className="skydart-booking-container">
                                    <button type="button" className="skydart-booking-print" onClick={renderPrintOption}><PrintOutlined style={{ fontSize: 16, marginRight: 5 }} /> Print</button>
                                    <button type="button" className="refresh-booking-shipment" onClick={() => history.goBack()}>Cancel</button>
                                    {type === "WPX" && <button type="submit" className="skydart-booking-shipment">Publish</button>}
                                    {type === "DOX" && <button type="button" onClick={bookDox} className="skydart-booking-shipment">Publish</button>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];