import React, { useState, useEffect, useRef } from 'react'
import { RiArrowDownSFill } from "react-icons/ri";
import { IconButton, Checkbox, CircularProgress, LinearProgress, Snackbar } from '@material-ui/core';
import { BsSearch } from "react-icons/bs";
import { FiRefreshCw } from "react-icons/fi";
import { AiOutlineMore } from "react-icons/ai";
import { BsBoxSeam, BsTrash, BsBox } from "react-icons/bs";
import { GrDocument, GrHostMaintenance } from "react-icons/gr";
import { AiOutlineStar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { BsFillReplyAllFill } from "react-icons/bs";
import { BsHourglassSplit } from "react-icons/bs";
import firebase from "firebase";
import { FcCheckmark } from "react-icons/fc";
import { Clear, FileCopy } from "@material-ui/icons";
import { IoMdDocument } from "react-icons/io";
import { FaFileInvoice } from "react-icons/fa";
import MuiAlert from '@material-ui/lab/Alert';
import { IoMdArrowDropdown } from "react-icons/io";
import { db } from '../firebase';
import { MdLabelImportantOutline, MdOutlineClear } from "react-icons/md";
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineLabel } from "react-icons/md";
import { Check } from 'react-feather';
export default function Draft({ user, userData }) {
    const [loading, setLoading] = useState(true);
    const [loadingShipment, setLoadingShipment] = useState(false);
    const [hawb, setHawb] = useState("");
    const [type, setType] = useState("");
    const [service, setService] = useState("");
    const [eamService, setEamService] = useState("");
    const [reason, setReason] = useState("");
    const [country, setCountry] = useState("");
    const [direct, setDirect] = useState("");
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [town, setTown] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postal, setPostal] = useState("");
    const [phone, setPhone] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [term, setTerm] = useState("");
    const [eori, setEori] = useState("");
    const [code, setCode] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [datePicker, setDatePicker] = useState("");
    const [transport, setTransport] = useState(0);
    const [doxWeight, setDoxWeight] = useState(0.5);
    const [doxErr, setDoxErr] = useState(false);
    const [validBook, setValidBook] = useState(false);
    const [envelope, setEnvelope] = useState(1);
    const [rate, setRate] = useState(0);
    const [doxRate, setDoxRate] = useState(0);
    const [fsc, setFsc] = useState(0);
    const [dhlFsc, setDhlFsc] = useState(0);
    const [fedexFsc, setFedexFsc] = useState(0);
    const [upsFsc, setUpsFsc] = useState(0);
    const [skdFsc, setSkdFsc] = useState(0);
    const [board, setBoard] = useState("");
    const [bookConfirm, setBookConfirm] = useState(false);
    const [packageValue, setPackageValue] = useState([{
        packageLength: 0,
        packageWidth: 0,
        packageHeight: 0,
        packageWeight: 0,
        packageCharge: 0,
        packNo: 1,
    }]);
    const [invoiceValue, setInvoiceValue] = useState([{
        itemCommonity: "",
        itemCountryOrigin: "Vietnam",
        itemManufactAddress: "",
        itemPackType: "",
        itemPackType2: "",
        itemHsCode: "",
        itemPnc: "",
        itemUnit: "",
        itemPurpose: "",
        itemManufact: "",
        itemUnitPrice: 0,
        itemQuantity: 0,
        itemValue: 0,
        itemBrand: "",
        itemIngredients: "",
        itemNo: 1,
        itemFda: "",
        itemUnit2: "",
    }]);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [status, setStatus] = useState("");
    const [search, setSearch] = useState("");
    const [shipment, setShipment] = useState([]);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageNumberLimit, setPageNumberLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [selectedShipment, setSelectedShipment] = useState([]);
    const [displayMoreSelect, setDisplayMoreSelect] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [displayState, setDisplayState] = useState(false);
    const [stateCode, setStateCode] = useState("");
    const [displayEdit, setDisplayEdit] = useState(false);
    const [displayCountry, setDisplayCountry] = useState(false);
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        setDatePicker(`${year}-${month}-${date}`);
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${date} ${monthName} ${year}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, [])
    const refreshShipment = () => {
        setLoading(true);
        db.collection("shipments").orderBy("timestamp", "desc").where("uid", "==", user.uid).get().then((snapshot) => {
            let data = snapshot.docs.map(doc => doc.data());
            setShipment(data.map((s) => {
                return {
                    select: false,
                    important: s.important || false,
                    hawb: s.hawb,
                    importer: s.importer,
                    perform: s.perform,
                    fromCity: s.fromCity,
                    createAt: s.createAt,
                    pickupAt: s.pickupAt,
                    toCountry: s.toCountry,
                    toTown: s.toTown,
                    toCity: s.toCity,
                    shipmentType: s.shipmentType,
                    totalCharge: s.totalCharge,
                    reweight: s.reweight,
                    totalPackage: s.totalPackage,
                    status: s.status,
                    totalItemValue: s.totalItemValue,
                    totalInvoiceValue: s.totalInvoiceValue,
                    description: s.description,
                    code: s.code,
                    time: s.time,
                    exporter: s.exporter,
                    subStatus: s.subStatus,
                    fromAddress: s.fromAddress,
                    fromAddress2: s.fromAddress2,
                    fromAddress3: s.fromAddress3,
                    fromState: s.fromState,
                    fromTown: s.fromTown,
                    fromCity: s.fromCity,
                    fromCountry: s.fromCountry,
                    fromPostal: s.fromPostal,
                    fromName: s.fromName,
                    fromEmail: s.fromEmail,
                    importer: s.importer,
                    toAddress: s.toAddress,
                    toAddress2: s.toAddress2,
                    toAddress3: s.toAddress3,
                    toCity: s.toCity,
                    toState: s.toState,
                    toTown: s.toTown,
                    toPostal: s.toPostal,
                    sin: s.sin,
                    toCountry: s.toCountry,
                    toPhone: s.toPhone,
                    toName: s.toName,
                    toEmail: s.toEmail,
                    weight: s.weight,
                    pickupBy: s.pickupBy,
                    invoiceValue: s.invoiceValue,
                    transportation: s.transportation,
                    service: s.service,
                    note: s.note,
                    usage: s.usage,
                    packageValue: s.packageValue,
                    term: s.term,
                    eori: s.eori,
                    reason: s.usage,
                    totalInvoiceValue: s.totalInvoiceValue,
                    totalCharge: s.totalCharge,
                }
            }))
            setLoading(false);
        })
    }
    useEffect(() => {
        refreshShipment();
    }, [])
    const finalShipment = shipment.filter((s) => {
        return s.perform === "Draft";
    })
    const importantDraft = finalShipment.filter((s) => {
        return s.status === "Important";
    })
    const pages = [];
    for (let i = 1; i <= Math.ceil(finalShipment.length / itemPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = finalShipment.slice(indexOfFirstItem, indexOfLastItem);
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }
    const exportSelected = shipment.filter((s) => {
        return s.select;
    })
    let selectOption = useRef();
    let countryRef = useRef();
    let stateRef = useRef();
    let bookingRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!selectOption.current.contains(e.target)) {
                setDisplayMoreSelect(false);
            }
            if (!countryRef.current.contains(e.target)) {
                setDisplayCountry(false);
            }
            if (!stateRef.current.contains(e.target)) {
                setDisplayState(false);
            }
            if (!bookingRef.current.contains(e.target)) {
                setBookConfirm(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, []);
    const handleShipment = () => {
        setUpdateLoading(true);
        setTimeout(() => {
            exportSelected.forEach((s) => {
                if (s.shipmentType === "WPX") {
                    if (s.totalInvoiceValue !== "0.00" && s.totalCharge !== "0.00" && s.description !== "") {
                        db.collection("shipments").doc(s.hawb).update({
                            perform: "New",
                            status: "Pending",
                            createAt: datePicker,
                            time: dateTime,
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                    } else {
                        return;
                    }
                } else if (s.shipmentType === "DOX") {
                    db.collection("shipments").doc(s.hawb).update({
                        perform: "New",
                        status: "Pending",
                        createAt: datePicker,
                        time: dateTime,
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        totalInvoiceValue: "0.00",
                        totalCharge: doxWeight,
                        description: "Document",
                    })
                }
            })
            setUpdateLoading(false);
            setAlertSuccess(true);
            refreshShipment();
        }, 1000)

    }
    const handleDelete = () => {
        setUpdateLoading(true);
        setTimeout(() => {
            exportSelected.forEach((s) => {
                db.collection("shipments").doc(s.hawb).delete();
            })
            setUpdateLoading(false);
            setAlertSuccess(true);
            refreshShipment();
        }, 1000)
    }
    function resetSuccess(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertSuccess(false);
    }
    function removeAccents(str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }
    const finalGross = packageValue.reduce((a, v) => +a + +v.packageWeight, 0);
    const finalCharge = packageValue.reduce((a, v) => +a + +v.packageCharge, 0);
    let handleChange = (i, e) => {
        let newFormValues = [...packageValue];
        newFormValues[i][e.target.name] = e.target.value;
        setPackageValue(newFormValues);
    };
    let invoiceChange = (i, e) => {
        let newInvoiceValues = [...invoiceValue];
        newInvoiceValues[i][e.target.name] = removeAccents(e.target.value);
        setInvoiceValue(newInvoiceValues);

    }
    let addFormFields = () => {
        let x = packageValue.length;
        setPackageValue([...packageValue, { packNo: x + 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0.00, packageCharge: 0 }])
    };
    let addCarton = () => {
        let x = invoiceValue.length;
        setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: "", itemIngredients: "", itemUnit2: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }])
    }
    const resetPacking = () => {
        setPackageValue([{ packNo: 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0, packageCharge: 0 }]);
    }
    const resetInvoice = () => {
        setInvoiceValue([{ itemNo: 1, itemUnit2: "", itemBrand: "", itemIngredients: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }]);
    }
    const finalAttachInvoiceValue = invoiceValue.map((s) => {
        return {
            itemValue: s.itemQuantity * s.itemUnitPrice,
        }
    });
    const finalInvoiceValue = finalAttachInvoiceValue.reduce((a, v) => a = +a?.toFixed(2) + +v.itemValue?.toFixed(2), 0);
    const totalInvoiceValue = +finalInvoiceValue + +transport;
    const onSaveShipment = () => {
        setLoadingShipment(true);
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            setTimeout(() => {
                db.collection("shipments").doc(hawb).update({
                    code: code,
                    shipmentType: type,
                    toPhone2: "",
                    dateTime: new Date(),
                    toCountry: country,
                    importer: company,
                    toCity: city,
                    toState: state,
                    toTown: town,
                    toAddress: address,
                    toAddress2: address2,
                    toAddress3: address3,
                    toPostal: postal,
                    toPhone: phone,
                    toName: contact,
                    toEmail: email,
                    createAt: datePicker,
                    time: dateTime,
                    invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                    packageValue: type === "DOX" ? "Pending" : packageValue,
                    totalCharge: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                    reweight: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                    weight: type === "DOX" ? doxWeight : finalGross.toFixed(2),
                    totalPackage: type === "DOX" ? envelope : packageValue.length,
                    totalItem: type === "DOX" ? envelope : invoiceValue.length,
                    eori,
                    otherTerm: "",
                    description: type === "DOX" ? "Document" : arr + ",",
                    term,
                    transportation: transport,
                    totalItemValue: type === "WPX" ? finalInvoiceValue.toFixed(2).toString() : "0.00",
                    totalInvoiceValue: type === "WPX" ? totalInvoiceValue.toFixed(2).toString() : "0.00",
                    usage: reason,
                }).then(() => {
                    setLoadingShipment(false);
                    setDisplayEdit(false);
                    setAlertSuccess(true);
                    refreshShipment();
                })
            }, 1000)
        }
    }
    const getRate = (e) => {
        e.preventDefault();
        if (userData.plan === "FWD") {
            db.collection(board).doc(country).get().then((doc) => {
                if (doc.exists) {
                    if (type === "DOX") {
                        if (doxWeight === 0) {
                            setDoxRate(0);
                        } else if (doxWeight === 0.5) {
                            setDoxRate(doc.data().doxHalf);
                        } else if (doxWeight === 1) {
                            setDoxRate(doc.data().doxOne);
                        } else if (doxWeight === 1.5) {
                            setDoxRate(doc.data().doxOnehalf);
                        } else if (doxWeight === 2) {
                            setDoxRate(doc.data().doxTwo);
                        }
                    }
                    if (service === "DHL") {
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                                setRate(0);
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge === 21) {
                                setRate(doc.data().twentyone);
                            } else if (finalCharge === 21.5) {
                                setRate(doc.data().twentyoneHalf);
                            } else if (finalCharge === 22) {
                                setRate(doc.data().twentytwo);
                            } else if (finalCharge === 22.5) {
                                setRate(doc.data().twentytwoHalf);
                            } else if (finalCharge === 23) {
                                setRate(doc.data().twentythree);
                            } else if (finalCharge === 23.5) {
                                setRate(doc.data().twentythreeHalf);
                            } else if (finalCharge === 24) {
                                setRate(doc.data().twentyfour);
                            } else if (finalCharge === 24.5) {
                                setRate(doc.data().twentyfourHalf);
                            } else if (finalCharge === 25) {
                                setRate(doc.data().twentyfive);
                            } else if (finalCharge === 25.5) {
                                setRate(doc.data().twentyfiveHalf);
                            } else if (finalCharge === 26) {
                                setRate(doc.data().twentysix);
                            } else if (finalCharge === 26.5) {
                                setRate(doc.data().twentysixHalf);
                            } else if (finalCharge === 27) {
                                setRate(doc.data().twentyseven);
                            } else if (finalCharge === 27.5) {
                                setRate(doc.data().twentysevenHalf);
                            } else if (finalCharge === 28) {
                                setRate(doc.data().twentyeight);
                            } else if (finalCharge === 28.5) {
                                setRate(doc.data().twentyeightHalf);
                            } else if (finalCharge === 29) {
                                setRate(doc.data().twentynine);
                            } else if (finalCharge === 29.5) {
                                setRate(doc.data().twentynineHalf);
                            } else if (finalCharge === 30) {
                                setRate(doc.data().thirty);
                            } else if (finalCharge > 30 && finalCharge < 71) {
                                setRate(doc.data().small * finalCharge);
                            } else if (finalCharge > 70 && finalCharge < 301) {
                                setRate(doc.data().medium * finalCharge);
                            } else if (finalCharge > 300 && finalCharge < 1000) {
                                setRate(doc.data().large * finalCharge);
                            }
                        }
                    } else if (service === "FIP") {
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge > 20.5 && finalCharge < 45) {
                                setRate(0);
                            } else if (finalCharge > 44 && finalCharge < 71) {
                                setRate(0);
                            } else if (finalCharge > 70 && finalCharge < 100) {
                                setRate(0);
                            } else if (finalCharge > 99 && finalCharge < 300) {
                                setRate(0);
                            } else if (finalCharge > 299 && finalCharge < 499) {
                                setRate(0);
                            } else if (finalCharge > 499 && finalCharge < 1000) {
                                setRate(0);
                            }
                        }

                    } else if (service === "UPS") {
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                                setRate(0);
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge > 20.5 && finalCharge < 45) {
                                setRate(0);
                            } else if (finalCharge > 44 && finalCharge < 71) {
                                setRate(0);
                            } else if (finalCharge > 70 && finalCharge < 100) {
                                setRate(0);
                            } else if (finalCharge > 99 && finalCharge < 300) {
                                setRate(0);
                            } else if (finalCharge >= 300) {
                                setRate(0);
                            }
                        }
                    } else if (service === "DUS") {
                        setFsc(0);
                        if (type === "WPX") {
                            if (finalCharge === 0) {
                                setRate(0);
                            } else if (finalCharge === 0.5) {
                                setRate(doc.data().half);
                            } else if (finalCharge === 1) {
                                setRate(doc.data().one);
                            } else if (finalCharge === 1.5) {
                                setRate(doc.data().oneHalf);
                            } else if (finalCharge === 2) {
                                setRate(doc.data().two);
                            } else if (finalCharge === 2.5) {
                                setRate(doc.data().twoHalf);
                            } else if (finalCharge === 3) {
                                setRate(doc.data().three);
                            } else if (finalCharge === 3.5) {
                                setRate(doc.data().threeHalf);
                            } else if (finalCharge === 4) {
                                setRate(doc.data().four);
                            } else if (finalCharge === 4.5) {
                                setRate(doc.data().fourHalf);
                            } else if (finalCharge === 5) {
                                setRate(doc.data().five);
                            } else if (finalCharge === 5.5) {
                                setRate(doc.data().fiveHalf);
                            } else if (finalCharge === 6) {
                                setRate(doc.data().six);
                            } else if (finalCharge === 6.5) {
                                setRate(doc.data().sixHalf);
                            } else if (finalCharge === 7) {
                                setRate(doc.data().seven);
                            } else if (finalCharge === 7.5) {
                                setRate(doc.data().sevenHalf);
                            } else if (finalCharge === 8) {
                                setRate(doc.data().eight);
                            } else if (finalCharge === 8.5) {
                                setRate(doc.data().eightHalf);
                            } else if (finalCharge === 9) {
                                setRate(doc.data().nine);
                            } else if (finalCharge === 9.5) {
                                setRate(doc.data().nineHalf);
                            } else if (finalCharge === 10) {
                                setRate(doc.data().ten);
                            } else if (finalCharge === 10.5) {
                                setRate(doc.data().tenHalf);
                            } else if (finalCharge === 11) {
                                setRate(doc.data().eleven);
                            } else if (finalCharge === 11.5) {
                                setRate(doc.data().elevenHalf);
                            } else if (finalCharge === 12) {
                                setRate(doc.data().twelve);
                            } else if (finalCharge === 12.5) {
                                setRate(doc.data().twelveHalf);
                            } else if (finalCharge === 13) {
                                setRate(doc.data().thirteen);
                            } else if (finalCharge === 13.5) {
                                setRate(doc.data().thirteenHalf);
                            } else if (finalCharge === 14) {
                                setRate(doc.data().fourteen);
                            } else if (finalCharge === 14.5) {
                                setRate(doc.data().fourteenHalf);
                            } else if (finalCharge === 15) {
                                setRate(doc.data().fifteen);
                            } else if (finalCharge === 15.5) {
                                setRate(doc.data().fifteenHalf);
                            } else if (finalCharge === 16) {
                                setRate(doc.data().sixteen);
                            } else if (finalCharge === 16.5) {
                                setRate(doc.data().sixteenHalf);
                            } else if (finalCharge === 17) {
                                setRate(doc.data().seventeen);
                            } else if (finalCharge === 17.5) {
                                setRate(doc.data().seventeenHalf);
                            } else if (finalCharge === 18) {
                                setRate(doc.data().eightteen);
                            } else if (finalCharge === 18.5) {
                                setRate(doc.data().eightteenHalf);
                            } else if (finalCharge === 19) {
                                setRate(doc.data().nineteen);
                            } else if (finalCharge === 19.5) {
                                setRate(doc.data().nineteenHalf);
                            } else if (finalCharge === 20) {
                                setRate(doc.data().twenty);
                            } else if (finalCharge === 20.5) {
                                setRate(doc.data().twentyHalf);
                            } else if (finalCharge === 21) {
                                setRate(doc.data().twentyone);
                            } else if (finalCharge === 21.5) {
                                setRate(doc.data().twentyoneHalf);
                            } else if (finalCharge === 22) {
                                setRate(doc.data().twentytwo);
                            } else if (finalCharge === 22.5) {
                                setRate(doc.data().twentytwoHalf);
                            } else if (finalCharge === 23) {
                                setRate(doc.data().twentythree);
                            } else if (finalCharge === 23.5) {
                                setRate(doc.data().twentythreeHalf);
                            } else if (finalCharge === 24) {
                                setRate(doc.data().twentyfour);
                            } else if (finalCharge === 24.5) {
                                setRate(doc.data().twentyfourHalf);
                            } else if (finalCharge === 25) {
                                setRate(doc.data().twentyfive);
                            } else if (finalCharge === 25.5) {
                                setRate(doc.data().twentyfiveHalf);
                            } else if (finalCharge === 26) {
                                setRate(doc.data().twentysix);
                            } else if (finalCharge === 26.5) {
                                setRate(doc.data().twentysixHalf);
                            } else if (finalCharge === 27) {
                                setRate(doc.data().twentyseven);
                            } else if (finalCharge === 27.5) {
                                setRate(doc.data().twentysevenHalf);
                            } else if (finalCharge === 28) {
                                setRate(doc.data().twentyeight);
                            } else if (finalCharge === 28.5) {
                                setRate(doc.data().twentyeightHalf);
                            } else if (finalCharge === 29) {
                                setRate(doc.data().twentynine);
                            } else if (finalCharge === 29.5) {
                                setRate(doc.data().twentynineHalf);
                            } else if (finalCharge === 30) {
                                setRate(doc.data().thirty);
                            } else if (finalCharge > 30 && finalCharge < 45) {
                                setRate(doc.data().small * finalCharge);
                            } else if (finalCharge > 44 && finalCharge < 69) {
                                setRate(doc.data().medium * finalCharge);
                            } else if (finalCharge > 68 && finalCharge < 301) {
                                setRate(doc.data().large * finalCharge);
                            }
                        }
                    }
                } else {
                    setRate(0);
                    setDoxRate(0);
                    setFsc(0);
                    setBookConfirm(true);
                }
            }).then(() => {
                setBookConfirm(true);
                setLoading(false);
            });
        } else {
            setRate(0);
            setDoxRate(0);
            setFsc(0);
            setLoading(false);
            setBookConfirm(true);
        }

    }
    const onBookShipment = (e) => {
        e.preventDefault();
        setLoadingShipment(true);
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            setTimeout(() => {
                db.collection("shipments").doc(hawb).set({
                    account: "786009",
                    currency: "USD",
                    service: service || "SKD",
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    perform: "New",
                    status: "Pending",
                    id: (Math.random() + 1).toString(36).substring(7),
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        stat: "Shipment booked, waiting for confirmation by Skydart ",
                        time: datePicker + "T" + dateTime + ":00",
                        location: "VIETNAM - VIETNAM - HCM",
                    })),
                    controller: userData.controller || "Pending",
                    eamFormat: type === "DOX" ? "DOX" : "NDX",
                    important: "",
                    airline: "Pending",
                    mawb: "Pending",
                    etd: "Pending",
                    agent: "Pending",
                    subStatus: "Pending",
                    sin: "Null",
                    note: "Null",
                    perform: "Null",
                    code: code,
                    hawb,
                    uid: user.uid,
                    makh: userData.makh,
                    direct: service === "DUS" ? "us" : direct,
                    stateCode: stateCode,
                    paymentStatus: "",
                    shipmentType: type,
                    fromCountry: userData.country,
                    exporter: removeAccents(userData.exporter),
                    fromCity: removeAccents(userData.city),
                    fromState: removeAccents(userData.state),
                    fromTown: removeAccents(userData.town),
                    fromAddress: removeAccents(userData.address),
                    fromAddress2: removeAccents(userData.address2),
                    fromAddress3: removeAccents(userData.address3),
                    value: totalInvoiceValue,
                    fromPostal: userData.postal,
                    fromPhone: userData.phone,
                    fromName: removeAccents(userData.displayName),
                    toPhone2: "",
                    fromEmail: userData.email,
                    dateTime: new Date(),
                    toCountry: country,
                    importer: company,
                    toCity: removeAccents(city),
                    toState: removeAccents(state),
                    toTown: removeAccents(town),
                    toAddress: removeAccents(address),
                    toAddress2: removeAccents(address2),
                    toAddress3: removeAccents(address3),
                    toPostal: postal,
                    toPhone: phone,
                    toName: removeAccents(contact),
                    toEmail: email,
                    createAt: datePicker,
                    time: dateTime,
                    invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                    packageValue: type === "DOX" ? "Pending" : packageValue,
                    totalCharge: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                    reweight: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                    weight: type === "DOX" ? doxWeight : finalGross.toFixed(2),
                    totalPackage: type === "DOX" ? envelope : packageValue.length,
                    totalItem: type === "DOX" ? envelope : invoiceValue.length,
                    eori,
                    otherTerm: "",
                    description: type === "DOX" ? "Document" : arr + ",",
                    term,
                    transportation: transport,
                    totalItemValue: type === "WPX" ? finalInvoiceValue.toFixed(2).toString() : "0.00",
                    totalInvoiceValue: type === "WPX" ? totalInvoiceValue.toFixed(2).toString() : "0.00",
                    usage: reason,
                    rate: type === "WPX" ? rate?.toFixed(2) : doxRate?.toFixed(2),
                    fsc: fsc,
                    fscValue: "0.00",
                    addressCorrection: "0.00",
                    surcharge: "0.00",
                    surcharge2: "0.00",
                    surcharge3: "0.00",
                    remote: "0.00",
                    vat: "No",
                    vatValue: "0.00",
                    eamService: eamService,
                    plan: userData.plan || "Pending",
                    booked: "false",
                }).then(() => {
                    setLoadingShipment(false);
                    setDisplayEdit(false);
                    setAlertSuccess(true);
                    refreshShipment();
                })
            }, 1000)
        }
    }
    const onBookShipment2 = () => {
        setLoading(true);
        setBookConfirm(false);
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            db.collection("shipments").doc(hawb).set({
                account: "786009",
                currency: "USD",
                service: service || "SKD",
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                perform: "New",
                status: "Pending",
                id: (Math.random() + 1).toString(36).substring(7),
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    stat: "Shipment booked, waiting for confirmation by Skydart ",
                    time: datePicker + "T" + dateTime + ":00",
                    location: "VIETNAM - VIETNAM - HCM",
                })),
                controller: userData.controller || "Pending",
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                important: "",
                airline: "Pending",
                mawb: "Pending",
                etd: "Pending",
                agent: "Pending",
                subStatus: "Pending",
                sin: "Null",
                note: "Null",
                perform: "Null",
                code: code,
                hawb,
                uid: user.uid,
                makh: userData.makh,
                paymentStatus: "",
                shipmentType: type,
                fromCountry: userData.country,
                exporter: removeAccents(userData.exporter),
                fromCity: removeAccents(userData.city),
                fromState: removeAccents(userData.state),
                fromTown: removeAccents(userData.town),
                fromAddress: removeAccents(userData.address),
                fromAddress2: removeAccents(userData.address2),
                fromAddress3: removeAccents(userData.address3),
                value: totalInvoiceValue,
                fromPostal: userData.postal,
                fromPhone: userData.phone,
                fromName: removeAccents(userData.displayName),
                toPhone2: "",
                fromEmail: userData.email,
                dateTime: new Date(),
                toCountry: country,
                importer: company,
                toCity: removeAccents(city),
                toState: removeAccents(state),
                toTown: removeAccents(town),
                toAddress: removeAccents(address),
                toAddress2: removeAccents(address2),
                toAddress3: removeAccents(address3),
                stateCode: stateCode,
                toPostal: postal,
                toPhone: phone,
                toName: removeAccents(contact),
                toEmail: email,
                createAt: datePicker,
                time: dateTime,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                reweight: type === "DOX" ? doxWeight : finalCharge.toFixed(2),
                weight: type === "DOX" ? doxWeight : finalGross.toFixed(2),
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                eori,
                otherTerm: "",
                description: type === "DOX" ? "Document" : arr + ",",
                term,
                transportation: transport,
                totalItemValue: type === "WPX" ? finalInvoiceValue.toFixed(2).toString() : "0.00",
                totalInvoiceValue: type === "WPX" ? totalInvoiceValue.toFixed(2).toString() : "0.00",
                usage: reason,
                // rate: type === "WPX" ? rate?.toFixed(2) : doxRate?.toFixed(2),
                rate: type === "WPX" ? rate?.toFixed(2) : doxRate?.toFixed(2),
                fsc: fsc,
                fscValue: "0.00",
                addressCorrection: "0.00",
                direct: service === "DUS" ? "us" : direct,
                surcharge: "0.00",
                surcharge2: "0.00",
                surcharge3: "0.00",
                remote: "0.00",
                vat: "No",
                vatValue: "0.00",
                eamService: eamService,
                plan: userData.plan || "Pending",
                booked: "false",
            }).then(() => {
                setLoading(false);
                history.push("/booking");
            })
        }
    }
    let removeFormField = (index, e) => {
        if (packageValue.length === 1) {
            return;
        } else {
            setPackageValue(packageValue.filter((v, i) => i !== index));
        }
    }
    let removeInvoice = (index, e) => {
        if (invoiceValue.length === 1) {
            return;
        } else {
            setInvoiceValue(invoiceValue.filter((v, i) => i !== index));
        }
    }
    useEffect(() => {
        if (code !== "" && service !== "" && country !== "" && company !== "" && address !== "" && address2 !== "" && town !== "" && city !== "" && postal !== "" && contact !== "" && phone !== "") {
            setValidBook(true);
        } else {
            setValidBook(false);
        }
    })
    const invoiceIncluded = finalShipment.filter((s) => {
        return s.totalInvoiceValue !== "0.00" && s.totalCharge !== "0.00" && s.description !== "";
    })

    return (
        <div className="skydart-draft">
            <div ref={bookConfirm ? null : bookingRef}></div>
            {bookConfirm && <div className="skydart-bookShipment-loading">
                <div className="skydart-bookShipment-loading-container1" ref={bookConfirm ? bookingRef : null} style={{ zIndex: 9999 }}>
                    <div style={{ display: "flex", padding: 10, alignItems: "center", flexDirection: "column" }}>
                        <FcCheckmark size={35} />
                        <p style={{ padding: 15, fontSize: 15, fontWeight: 600, textAlign: "center" }}>Your shipment has been booked successfully.</p>
                    </div>
                    <div className="skydart-bookShipment-save-confirm-option">
                        <button type="button" className="skydart-bookShipment-save-confirm-cancel" onClick={onBookShipment}>Manage shipment</button>
                        <button type="button" className="skydart-bookShipment-save-confirm-book-another" onClick={onBookShipment2}>Book another shipment</button>
                    </div>
                </div>
            </div>}
            {updateLoading && <div className="skydart-user-update-loading">
                <div className="skydart-user-update-loading-left">
                    <BsHourglassSplit size={22} />
                </div>
                <div className="skydart-user-update-loading-right">
                    <LinearProgress style={{ height: 6.5, borderRadius: 5 }} />
                    <p style={{ marginTop: 7, fontSize: 13, marginLeft: 1 }}>Loading...</p>
                </div>
            </div>}
            <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
                <MuiAlert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
                    Update Success!
                </MuiAlert>
            </Snackbar>
            <div className="skydart-draft-header">
                <div className="skydart-draft-search">
                    <div className="skydart-draft-search-right">
                        <BsSearch size={21} style={{ position: 'absolute', marginLeft: 15 }} />
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search for shipment" style={displaySearch ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, border: "1px solid transparent", background: "white", boxShadow: "rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px" } : { borderBottomRightRadius: 7, borderBottomLeftRadius: 7 }} />
                    </div>
                    {loading && <div className="skydart-draft-loading">
                        <p>Loading...</p>
                    </div>}
                    <div className="skydart-draft-shipment-shown">
                        <h5>Showing ({finalShipment.length}/{shipment.length}) shipments</h5>
                    </div>
                </div>
            </div>
            <div className="skydart-draft-wrap">
                <div className="skydart-draft-body">
                    <div className="skydart-draft-body-header">
                        <div className="skydart-draft-body-header-checkbox">
                            <Checkbox color="primary" checked={exportSelected.length !== 0} indeterminate={exportSelected.length !== 0 && exportSelected.length !== finalShipment.length} onChange={e => {
                                let value = e.target.checked;
                                setSelectedShipment(finalShipment.map(d => {
                                    d.select = value;
                                    return d;
                                }))
                            }} />
                        </div>
                        <div ref={selectOption}>
                            <div className="skydart-draft-body-header-refresh" onClick={() => {
                                if (displayMoreSelect) {
                                    setDisplayMoreSelect(false);
                                } else {
                                    setDisplayMoreSelect(true);
                                }
                            }}>
                                <IoMdArrowDropdown size={21} />
                                {!displayMoreSelect && <p className="skydart-draft-helper">Select option</p>}
                            </div>
                            {displayMoreSelect && <div className="skydart-draft-body-select">
                                <div className="skydart-draft-body-select-option" onClick={() => {
                                    setSelectedShipment(finalShipment.map(d => {
                                        d.select = true;
                                        return d;
                                    }))
                                    setDisplayMoreSelect(false);
                                }}>
                                    <h5>Select all shipment</h5>
                                </div>
                                <div className="skydart-draft-body-select-option">
                                    <h5>Select shipment without invoice</h5>
                                </div>
                                <div className="skydart-draft-body-select-option">
                                    <h5>Select shipment with invoice included</h5>
                                </div>
                            </div>}
                        </div>
                        <div className="skydart-draft-body-header-refresh" onClick={refreshShipment}>
                            <FiRefreshCw size={21} />
                            <p className="skydart-draft-helper">Refresh</p>
                        </div>
                        {exportSelected.length !== 0 && <div className="skydart-draft-body-header-refresh" onClick={handleShipment}>
                            <BsBoxSeam size={21} />
                            <p className="skydart-draft-helper">Book</p>
                        </div>}
                        {exportSelected.length !== 0 && <div className="skydart-draft-body-header-refresh" onClick={handleDelete}>
                            <BsTrash size={21} />
                            <p className="skydart-draft-helper">Delete</p>
                        </div>}
                        <div className="skydart-draft-body-header-refresh">
                            <AiOutlineMore size={21} />
                            <p className="skydart-draft-helper">More</p>
                        </div>
                        {exportSelected.length !== 0 && <p style={{ marginLeft: "auto", borderRadius: "5px", padding: 5, fontSize: 13, background: "rgb(58, 111, 180)", color: "white", fontWeight: 600 }}>{exportSelected.length} selected</p>}
                        <div className="skydart-draft-paginate">
                            <div className="skydart-draft-paginate-arrow">
                                <IconButton type="button" disabled={currentPage == pages[0] ? true : false} onClick={handlePrevPage}>
                                    <MdArrowBackIosNew fontSize="small" />
                                </IconButton>
                            </div>
                            <div className="skydart-draft-paginate-arrow">
                                <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} onClick={handleNextPage}>
                                    <MdArrowForwardIos fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-body-phan-loai">
                        <div className="skydart-draft-body-label" onClick={() => history.push("/")}>
                            <BsFillReplyAllFill size={21} />
                            <h5>All shipments</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, borderRadius: "15px", background: "rgb(228, 69, 41)", padding: 5, color: "white" }}>{shipment.length}</h5>
                        </div>
                        <div className="skydart-draft-body-label">
                            <GrHostMaintenance size={21} />
                            <h5>Draft</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, borderRadius: "15px", background: "#0074D9", padding: 5, color: "white" }}>{finalShipment.length}</h5>
                        </div>
                        {/* <div className="skydart-draft-body-label">
                            <MdLabelImportantOutline size={21} />
                            <h5>Important</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, background: "rgb(202, 236, 120)", borderRadius: "15px", padding: 5, color: "black" }}>{importantDraft.length}</h5>
                        </div> */}
                        <div className="skydart-draft-body-label">
                            <FaFileInvoice size={21} />
                            <h5>Ready to ship</h5>
                            <h5 style={{ marginLeft: "auto", fontSize: 13, background: "rgb(48, 143, 96)", borderRadius: "15px", padding: 5, color: "white" }}>{invoiceIncluded.length}</h5>
                        </div>
                    </div>
                    {!loading && currentShipment.filter((s) => {
                        if (s.hawb.toLowerCase().includes(search.toLowerCase())) {
                            return s;
                        }
                    }).map((s) => (
                        <div className={s.select ? "skydart-draft-body-body1" : "skydart-draft-body-body"}>
                            <div className="skydart-draft-body-header-checkbox">
                                <Checkbox color="primary" checked={s.select} onChange={e => {
                                    setSelectedShipment(finalShipment.map(sd => {
                                        let value = e.target.checked;
                                        if (sd.hawb === s.hawb) {
                                            sd.select = value;
                                        }
                                        return sd;
                                    }))
                                }} />
                            </div>
                            {/* <div className="skydart-draft-important">
                            <IconButton type="button" style={{ width: 45, height: 45, }} onClick={() => {
                                if (s.important) {

                                }
                            }}>
                                <AiOutlineStar />
                            </IconButton>
                        </div> */}
                            <div className="skydart-draft-content" onClick={() => {
                                setDisplayEdit(true);
                                setHawb(s.hawb);
                                setType(s.shipmentType);
                                setService(s.service);
                                setReason(s.reason);
                                setCountry(s.toCountry);
                                setCode(s.code);
                                setCompany(s.importer);
                                setAddress(s.toAddress);
                                setAddress2(s.toAddress2);
                                setAddress3(s.toAddress3);
                                setTown(s.toTown);
                                setCity(s.toCity);
                                setState(s.toState);
                                setPostal(s.toPostal);
                                setPhone(s.toPhone);
                                setContact(s.toName);
                                setEmail(s.toEmail);
                                setTerm(s.term);
                                setEori(s.eori);
                                setTransport(s.transportation);
                                setPackageValue(s.shipmentType === "WPX" ? s.packageValue : []);
                                setInvoiceValue(s.shipmentType === "WPX" ? s.invoiceValue : []);
                                if (s.service === "DHL") {
                                    setBoard("dhl");
                                } else if (s.service === "FIP") {
                                    setBoard("fedex");
                                } else if (s.service === "UPS") {
                                    setBoard("ups");
                                } else if (s.service === "DUS") {
                                    setBoard("dus");
                                }
                            }}>
                                <div className="skydart-draft-hawb">
                                    <h5>{s.hawb}</h5>
                                </div>
                                <div className="skydart-draft-country">
                                    <h5><strong>{s.toCountry}</strong> * {s.toCity} * {s.toTown} * {s.toState} * {s.toPostal}</h5>
                                </div>
                                <p style={{ marginLeft: 5, fontSize: 18, marginRight: 5 }}>-</p>
                                <div className="skydart-draft-address">
                                    <p style={{ fontSize: 13.5 }}>{s.toAddress}, {s.toAddress2}</p>
                                </div>
                                <div className="skydart-draft-date">
                                    <h5>{formatDate(new Date(s.createAt))}</h5>
                                </div>
                                {s.totalInvoiceValue !== "0.00" && s.totalCharge !== "0.00" && s.description !== "" && s.shipmentType === "WPX" && <div className="skydart-draft-invoice">
                                    <Check size={15} />
                                    <h5>Ready to ship</h5>
                                </div>}
                                {s.shipmentType === "DOX" && <div className="skydart-draft-invoice">
                                    <Check size={15} />
                                    <h5>Ready to ship</h5>
                                </div>}
                            </div>
                        </div>
                    ))}
                </div>
                <div ref={displayEdit ? null : countryRef}></div>
                {!displayEdit && service !== "DUS" && <div ref={stateRef}></div>}
                {!displayEdit && service !== "FUS" && <div ref={stateRef}></div>}
                {!displayEdit && service !== "UUS" && <div ref={stateRef}></div>}
                {displayEdit && <form className="skydart-draft-edit-form" onSubmit={getRate}>
                    <div className="skydart-draft-sticky" style={{ zIndex: 2 }}>
                        <div className="skydart-draft-edit-form-header">
                            <p>Edit and book shipment</p>
                            <IconButton type="button" onClick={() => { setDisplayEdit(false); setService(""); setHawb("") }}>
                                <Clear fontSize="small" />
                            </IconButton>
                        </div>
                        <div className="skydart-draft-edit-form-hawb">
                            <h3>{hawb}</h3>
                        </div>
                    </div>
                    <div className="skydart-draft-edit-form-input">
                        <p style={{ fontSize: 13 }}>Select your shipment type</p>
                        <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }}>
                            <div onClick={() => setType("WPX")} className={type === "WPX" ? "skydart-draft-edit-form-type-option1" : "skydart-draft-edit-form-type-option"}>
                                <BsBox size={25} />
                                <h5>WPX</h5>
                            </div>
                            <div onClick={() => setType("DOX")} className={type === "DOX" ? "skydart-draft-edit-form-type-option1" : "skydart-draft-edit-form-type-option"}>
                                <IoMdDocument size={25} />
                                <h5>DOX</h5>
                            </div>
                        </div>
                    </div>
                    <div className="skydart-draft-edit-form-input">
                        <p style={{ fontSize: 13, marginBottom: 5 }}>Service</p>
                        <div className="skydart-draft-edit-service-option" onClick={() => setService("SKD")}>
                            <input type="radio" id="skd" name="service-group" checked={service === "SKD" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="skd">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>SKD</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => { setService("DHL"); setEamService("INT") }}>
                            <input type="radio" id="sdh" name="service-group" checked={service === "DHL" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="sdh">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>SDH</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => { setService("FIP"); setEamService("FIP") }}>
                            <input type="radio" id="sip" name="service-group" checked={service === "FIP" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="sip">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>SIP</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => { setService("UPS") }}>
                            <input type="radio" id="sup" name="service-group" checked={service === "UPS" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="sup">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>SUP</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setService("DHL-VN")}>
                            <input type="radio" id="vdh" name="service-group" checked={service === "DHL-VN" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="vdh">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>VDH</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setService("Fedex-VN")}>
                            <input type="radio" id="vip" name="service-group" checked={service === "Fedex-VN" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="vip">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>VIP</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setService("UPS-VN")}>
                            <input type="radio" id="vup" name="service-group" checked={service === "UPS-VN" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="vup">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>VUP</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setService("DAU")}>
                            <input type="radio" id="dau" name="service-group" checked={service === "DAU" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="dau">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>DAU</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setService("SEC")}>
                            <input type="radio" id="sec" name="service-group" checked={service === "SEC" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="sec">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>SEC</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => { setService("DUS"); setCountry("United States"); setCode("US"); setDirect("us") }}>
                            <input type="radio" id="dus" name="service-group" checked={service === "DUS" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="dus">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>DUS</p>
                            </label>
                        </div>
                    </div>
                    {type === "WPX" && <div className="skydart-draft-edit-form-input">
                        <p style={{ fontSize: 13, marginBottom: 5 }}>Reason for export</p>
                        <div className="skydart-draft-edit-service-option" onClick={() => setReason("GIFT")}>
                            <input type="radio" id="gift" name="reason-group" checked={reason === "GIFT" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="gift">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>GIFT</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setReason("E-COMMERCE SELLING")}>
                            <input type="radio" id="e-commerce" name="reason-group" checked={reason === "E-COMMERCE SELLING" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="e-commerce">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>E-COMMERCE SELLING</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setReason("RETURNED FOR MAINTENANCE")}>
                            <input type="radio" id="return" name="reason-group" checked={reason === "RETURNED FOR MAINTENANCE" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="return">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>RETURNED FOR MAINTENANCE</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setReason("SAMPLE")}>
                            <input type="radio" id="sample" name="reason-group" checked={reason === "SAMPLE" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="sample">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>SAMPLE</p>
                            </label>
                        </div>
                        <div className="skydart-draft-edit-service-option" onClick={() => setReason("COMMERCIAL")}>
                            <input type="radio" id="commercial" name="reason-group" checked={reason === "COMMERCIAL" ? true : false} />
                            <label style={{ display: "flex", alignItems: "center" }} for="commercial">
                                <p style={{ fontSize: 14, marginLeft: 10 }}>COMMERCIAL</p>
                            </label>
                        </div>
                    </div>}
                    <div className="skydart-draft-edit-form-input">
                        <p style={{ fontSize: 13 }}>Address information</p>
                        <div className="skydart-draft-edit-address" style={{ position: "relative" }} ref={displayEdit ? countryRef : null}>
                            <p style={{ fontSize: 10 }}>Country:</p>
                            {service !== "DUS" && service !== "FUS" && service !== "UUS" && <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} onClick={() => setDisplayCountry(true)} />}
                            {service === "DUS" && <input type="text" value={country} disabled onChange={(e) => setCountry(e.target.value)} />}
                            {service === "FUS" && <input type="text" value={country} disabled onChange={(e) => setCountry(e.target.value)} />}
                            {service === "UUS" && <input type="text" value={country} disabled onChange={(e) => setCountry(e.target.value)} />}
                            {displayCountry && <div className="skydart-draft-edit-country-container">
                                {countries.filter((s) => {
                                    if (s.label.toLowerCase().startsWith(country.toLowerCase())) {
                                        return s;
                                    }
                                }).map((s) => (
                                    <div className="skydart-draft-edit-country-option" onClick={() => {
                                        setCountry(s.label);
                                        setCode(s.code);
                                        setDisplayCountry(false);
                                    }}>
                                        <h5>{s.label}</h5>
                                    </div>
                                ))}
                            </div>}
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Company:</p>
                            <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Address:</p>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Address 2:</p>
                            <input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Address 3:</p>
                            <input type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Town:</p>
                            <input type="text" value={town} onChange={(e) => setTown(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>City:</p>
                            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                        {service !== "DUS" && <div ref={stateRef}></div>}
                        {service !== "FUS" && <div ref={stateRef}></div>}
                        {service !== "UUS" && <div ref={stateRef}></div>}
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>State:</p>
                            {service !== "DUS" && service !== "FUS" && service !== "UUS" && <input type="text" value={state} onChange={(e) => setState(e.target.value)} />}
                            {service === "DUS" && <div style={{ position: "relative" }} ref={service === "DUS" ? stateRef : null}>
                                <input type="text" value={state} onChange={(e) => setState(e.target.value)} onClick={() => setDisplayState(true)} />
                                {displayState && <div className="skydart-draft-edit-country-container">
                                    {usState.filter((s) => {
                                        if (s.label.toLowerCase().startsWith(state.toLowerCase())) {
                                            return s;
                                        }
                                    }).map((s) => (
                                        <div className="skydart-draft-edit-country-option" onClick={() => {
                                            setState(s.label);
                                            setStateCode(s.code);
                                            setDisplayState(false);
                                        }}>
                                            <h5>{s.label}, {s.code}</h5>
                                        </div>
                                    ))}
                                </div>}
                            </div>}
                            {service === "FUS" && <div style={{ position: "relative" }} ref={service === "FUS" ? stateRef : null}>
                                <input type="text" value={state} onChange={(e) => setState(e.target.value)} onClick={() => {
                                    if (displayState) {
                                        setDisplayState(false);
                                    } else {
                                        setDisplayState(true);
                                    }
                                }} />
                                {displayState && <div className="skydart-draft-edit-country-container">
                                    {usState.filter((s) => {
                                        if (s.label.toLowerCase().startsWith(state.toLowerCase())) {
                                            return s;
                                        }
                                    }).map((s) => (
                                        <div className="skydart-draft-edit-country-option" onClick={() => {
                                            setState(s.label);
                                            setStateCode(s.code);
                                            setDisplayState(false);
                                        }}>
                                            <h5>{s.label}, {s.code}</h5>
                                        </div>
                                    ))}
                                </div>}
                            </div>}
                            {service === "UUS" && <div style={{ position: "relative" }} ref={service === "UUS" ? stateRef : null}>
                                <input type="text" value={state} onChange={(e) => setState(e.target.value)} onClick={() => {
                                    if (displayState) {
                                        setDisplayState(false);
                                    } else {
                                        setDisplayState(true);
                                    }
                                }} />
                                {displayState && <div className="skydart-draft-edit-country-container">
                                    {usState.filter((s) => {
                                        if (s.label.toLowerCase().startsWith(state.toLowerCase())) {
                                            return s;
                                        }
                                    }).map((s) => (
                                        <div className="skydart-draft-edit-country-option" onClick={() => {
                                            setState(s.label);
                                            setStateCode(s.code);
                                            setDisplayState(false);
                                        }}>
                                            <h5>{s.label}, {s.code}</h5>
                                        </div>
                                    ))}
                                </div>}
                            </div>}
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Postal Code:</p>
                            <input type="text" value={postal} onChange={(e) => setPostal(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Telephone:</p>
                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Contact Name:</p>
                            <input type="text" value={contact} onChange={(e) => setContact(e.target.value)} />
                        </div>
                        <div className="skydart-draft-edit-address">
                            <p style={{ fontSize: 10 }}>Email Address:</p>
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    {type === "WPX" && <div className="skydart-draft-edit-form-input">
                        <p style={{ fontSize: 13 }}>Transport term</p>
                        <select style={{ marginTop: 7, padding: 7, width: "100%", border: "1px solid rgb(230,230,230)" }} value={term} onChange={(e) => setTerm(e.target.value)}>
                            <option value="">Select transport term</option>
                            <option value="EXW">EWX</option>
                            <option value="FOB">FOB</option>
                            <option value="CNF">CNF</option>
                            <option value="CIF">CIF</option>
                        </select>
                        <input value={eori} onChange={(e) => setEori(e.target.value)} type="text" placeholder="IOSS / TIN / EORI Number / ID Tax Number" style={{ padding: 5, border: "1px solid rgb(230,230,230)", marginTop: 7, width: "96%" }} />
                        <div style={{ marginTop: 5 }}>
                            <p style={{ fontSize: 11 }}>Transport value:</p>
                            <input style={{ padding: 5, width: "95%", marginTop: 3 }} type="number" value={transport} onChange={(e) => setTransport(e.target.value)} />
                        </div>
                    </div>}
                    <div className="skydart-draft-edit-form-input">
                        <p style={{ fontSize: 13 }}>Packing information</p>
                        <div className="skydart-draft-edit-packing-flex">
                            {type === "WPX" && <div className="skydart-draft-edit-packing">
                                <h5 style={{ fontSize: 11 }}># of packages</h5>
                                <div className="skydart-draft-edit-packing-form">
                                    <p>{packageValue.length} PCS</p>
                                </div>
                            </div>}
                            {type === "WPX" && <div className="skydart-draft-edit-packing">
                                <h5 style={{ fontSize: 11 }}>Chargeable weight</h5>
                                <div className="skydart-draft-edit-packing-form">
                                    <p>{finalCharge.toFixed(2)} kg</p>
                                </div>
                            </div>}
                            {type === "DOX" && <div className="skydart-draft-edit-packing">
                                <h5 style={{ fontSize: 11 }}># of envelope</h5>
                                <div className="skydart-draft-edit-packing-form">
                                    <input type="number" value={envelope} onChange={(e) => setEnvelope(e.target.value)} />
                                </div>
                            </div>}
                            {type === "DOX" && <div className="skydart-draft-edit-packing">
                                <h5 style={{ fontSize: 11 }}>Chargeable weight</h5>
                                <div className="skydart-draft-edit-packing-form">
                                    <input type="number" value={doxWeight} onChange={(e) => setDoxWeight(e.target.value)} />
                                </div>
                            </div>}
                        </div>
                        {type === "WPX" && <div className="skydart-draft-edit-packing-packageValue">
                            {packageValue.map((element, index) => {
                                const dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 5000;
                                const grossWeight = element.packageWeight;

                                if (dimCharge < grossWeight) {
                                    element.packageCharge = grossWeight * 1;
                                    element.packageWeight = grossWeight * 1;
                                } else if (grossWeight < dimCharge) {
                                    element.packageCharge = dimCharge;
                                    element.packageWeight = grossWeight * 1;

                                }
                                if (element.packageCharge >= 30) {
                                    element.packageCharge = Math.ceil(element.packageCharge);
                                } else if (element.packageCharge < 30) {
                                    element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
                                }
                                if (element.packageWeight === "") {
                                    element.packageCharge = "0.00";
                                }
                                return <div className="skydart-draft-edit-packing-packageValue-container">
                                    <div className="skydart-draft-edit-packing-packageValue-option">
                                        <input type="number" required value={element.packageWeight || ""} onChange={e => handleChange(index, e)} name="packageWeight" />
                                        <p>kg</p>
                                    </div>

                                    <div className="skydart-draft-edit-packing-packageValue-option">
                                        <input type="number" value={element.packageLength || ""} onChange={e => handleChange(index, e)} name="packageLength" />
                                        <p>cm</p>
                                    </div>
                                    <div className="skydart-draft-edit-packing-packageValue-option">
                                        <input type="number" name="packageWidth" value={element.packageWidth || ""} onChange={e => handleChange(index, e)} />
                                        <p>cm</p>
                                    </div>
                                    <div className="skydart-draft-edit-packing-packageValue-option">
                                        <input type="number" name="packageHeight" value={element.packageHeight || ""} onChange={e => handleChange(index, e)} />
                                        <p>cm</p>
                                    </div>
                                </div>
                            })}
                        </div>}
                        {type === "WPX" && <div className="skydart-draft-edit-packing-packageValue-button">
                            <div className="skydart-draft-edit-packing-packageValue-button-option" onClick={addFormFields}>
                                <h5>Add package</h5>
                            </div>
                            <div className="skydart-draft-edit-packing-packageValue-button-option" onClick={resetPacking}>
                                <h5>Reset packing</h5>
                            </div>
                        </div>}
                    </div>
                    {type === "WPX" && <div className="skydart-draft-edit-form-input" style={{ border: "none" }}>
                        <p style={{ fontSize: 13 }}>Description of goods</p>
                        <p style={{ fontSize: 10, fontWeight: 600, marginTop: 2 }}>Unit can only be (BOX,PRS,PCS,KG)</p>
                        {invoiceValue.map((s, index) => {
                            let donGia1 = s.itemUnitPrice;
                            let soLuong1 = s.itemQuantity;
                            let tong1 = donGia1 * soLuong1;
                            s.itemValue = tong1;
                            return <div className="skydart-draft-edit-invoice-draft">
                                <div className="skydart-draft-edit-invoice-head">
                                    <p style={{ fontSize: 13, fontWeight: 600 }}>Item {index + 1}</p>
                                    <div>
                                        <FileCopy fontSize="small" style={{ marginRight: 5, cursor: "pointer" }} onClick={() => {
                                            setInvoiceValue([...invoiceValue, { itemNo: index + 1, itemBrand: s.itemBrand, itemIngredients: s.itemIngredients, itemUnit2: s.itemUnit2, itemPackType2: s.itemPackType2, itemCommonity: s.itemCommonity, itemUnit: s.itemUnit, itemUnitPirce: s.itemUnitPrice, itemQuantity: s.itemQuantity, itemValue: s.itemValue, itemManufact: s.itemManufact, itemPurpose: s.itemPurpose, itemHsCode: s.itemHsCode, itemPackType: s.itemPackType, itemManufactAddress: s.itemManufactAddress, itemCountryOrigin: s.itemCountryOrigin, itemFda: s.itemFda }])
                                        }} />
                                        <Clear fontSize="small" style={{ marginRight: 5, cursor: "pointer" }} onClick={e => removeInvoice(index, e)} />
                                    </div>
                                </div>
                                <div className="skydart-draft-edit-invoice-body">
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Item name: *</p>
                                        <input type="text" required name="itemCommonity" value={s.itemCommonity} onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Brand name:</p>
                                        <input type="text" value={s.itemBrand} name="itemBrand" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Packing type:</p>
                                        <select value={s.itemPackType || ""} name="itemPackType" onChange={e => invoiceChange(index, e)}>
                                            <option value="">Select packing type</option>
                                            <option value="Bag">Bag</option>
                                            <option value="Box">Box</option>
                                            <option value="Carton">Carton</option>
                                            <option value="Wooden Pack">Wooden Pack</option>
                                        </select>
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Ingredient:</p>
                                        <input type="text" value={s.itemIngredients} name="itemIngredients" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Usage:</p>
                                        <input type="text" value={s.itemPurpose} name="itemPurpose" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Country of origin:</p>
                                        <input type="text" value={s.itemCountryOrigin} placeholder="Nước xuất khẩu" name="itemCountryOrigin" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Manufacturer name:</p>
                                        <input type="text" name="itemManufact" value={s.itemManufact} onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>Manufacturer address:</p>
                                        <input type="text" name="itemManufactAddress" value={s.itemManufactAddress} onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>HS code:</p>
                                        <input type="text" name="itemHsCode" value={s.itemHsCode} onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>FDA:</p>
                                        <input type="text" value={s.itemFda || ""} name="itemFda" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>QUANTITY:</p>
                                        <input type="number" required value={s.itemQuantity || ""} name="itemQuantity" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>UNIT:</p>
                                        <select required value={s.itemUnit || ""} name="itemUnit" onChange={e => invoiceChange(index, e)}>
                                            <option value="">Select item unit</option>
                                            <option value="PCS">PCS</option>
                                            <option value="BOX">BOX</option>
                                            <option value="KG">KG</option>
                                            <option value="PRS">PRS</option>
                                        </select>
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>UNIT PRICE:</p>
                                        <input type="number" required value={s.itemUnitPrice || ""} name="itemUnitPrice" onChange={e => invoiceChange(index, e)} />
                                    </div>
                                    <div className="skydart-draft-edit-invoice-input">
                                        <p>TOTAL AMOUNT (USD):</p>
                                        <input type="text" disabled value={s.itemValue.toFixed(2) + " " + "USD" || "0.00" + "USD"} />
                                    </div>
                                </div>
                            </div>
                        })}
                        <div className="skydart-draft-edit-packing-packageValue-button">
                            <div className="skydart-draft-edit-packing-packageValue-button-option" onClick={addCarton}>
                                <h5>Add item</h5>
                            </div>
                            <div className="skydart-draft-edit-packing-packageValue-button-option" onClick={resetInvoice}>
                                <h5>Reset invoice</h5>
                            </div>
                        </div>
                        <div className="skydart-draft-edit-invoice-summary">
                            <div className="skydart-draft-edit-invoice-summary-option">
                                <h5>Total item</h5>
                                <h5>{invoiceValue.length} item</h5>
                            </div>
                            <div className="skydart-draft-edit-invoice-summary-option">
                                <h5>Total item value</h5>
                                <h5>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                            </div>
                            <div className="skydart-draft-edit-invoice-summary-option">
                                <h5>Total invoice value</h5>
                                <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                            </div>
                        </div>
                    </div>}
                    <div className="skydart-draft-edit-book" style={{ zIndex: 2 }}>
                        <button type="button" className="skydart-draft-save-shipment" onClick={onSaveShipment}>Save Draft</button>
                        {validBook && <button type="submit" className="skydart-draft-book-shipment">Book Shipment</button>}
                        {!validBook && <button type="button" className="skydart-draft-book-shipment" style={{ opacity: 0.5, cursor: "not-allowed" }}>Book Shipment</button>}
                        {loadingShipment && <div className="skydart-draft-book-loading" style={{ marginTop: 12, marginBottom: 10 }}>
                            <CircularProgress size={30} />
                        </div>}
                    </div>
                </form>}
            </div>
        </div >
    )
}

const usState = [
    { code: "AL", label: "Alabama" },
    { code: "AK", label: "Alaska" },
    { code: "AZ", label: "Arizona" },
    { code: "AR", label: "Arkansas" },
    { code: "CA", label: "California	" },
    { code: "CZ", label: "Canal Zone" },
    { code: "CO", label: "Colorado" },
    { code: "CT", label: "Connecticut" },
    { code: "DE", label: "Delaware" },
    { code: "DC", label: "District of Columbia" },
    { code: "FL", label: "Florida" },
    { code: "GA", label: "Georgia" },
    { code: "GU", label: "Guam" },
    { code: "HI", label: "Hawaii" },
    { code: "ID", label: "Idaho" },
    { code: "IL", label: "Illinois" },
    { code: "IA", label: "Iowa" },
    { code: "KS", label: "Kansas" },
    { code: "KY", label: "Kentucky" },
    { code: "LA", label: "Louisiana" },
    { code: "ME", label: "Maine" },
    { code: "MD", label: "Maryland" },
    { code: "MA", label: "Massachusetts" },
    { code: "MI", label: "Michigan" },
    { code: "MN", label: "Minnesota" },
    { code: "MS", label: "Mississippi" },
    { code: "MO", label: "Missouri" },
    { code: "MT", label: "Montana" },
    { code: "NE", label: "Nebraska" },
    { code: "NV", label: "Nevada" },
    { code: "NH", label: "New Hampshire" },
    { code: "NJ", label: "New Jersey" },
    { code: "NM", label: "New Mexico" },
    { code: "NY", label: "New York" },
    { code: "NC", label: "North Carolina" },
    { code: "ND", label: "North Dakota" },
    { code: "OH", label: "Ohio" },
    { code: "OK", label: "Oklahoma" },
    { code: "OR", label: "Oregon" },
    { code: "PA", label: "Pennsylvania" },
    { code: "PR", label: "Puerto Rico" },
    { code: "RI", label: "Rhode Island" },
    { code: "SC", label: "South Carolina" },
    { code: "SD", label: "South Dakota" },
    { code: "TN", label: "Tennessee" },
    { code: "TX", label: "Texas" },
    { code: "UT", label: "Utah" },
    { code: "VT", label: "Vermont" },
    { code: "VI", label: "Virgin Islands" },
    { code: "VA", label: "Virginia" },
    { code: "WA", label: "Washington" },
    { code: "WX", label: "West Virginia" },
    { code: "WI", label: "Wisconsin" },
    { code: "WY", label: "Wyoming" },
]

const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];