import React, { useState, useEffect, useRef } from "react";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "./Loading";
import { auth, db } from "./firebase";
import Login from "./Login";
import Registration from "./Registration";
import LoginVn from "./LoginVn";
import Welcome from "./Welcome";
import { MdFlightTakeoff } from "react-icons/md";
import Forgot from "./Forgot";
import { Search } from "@material-ui/icons";
import TextareaAutosize from "react-textarea-autosize";
import Scanning from "./Scanning";
import skydartMock from "./images/skydartMock.jpg";
import Home from "./Home";
import { BsArrowUp } from "react-icons/bs";
import Main from "./Main";
import Tracing from "./skydart2.0/Tracing";
import Track from "./skydart2.0/Track";
function App() {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadTracking, setLoadTracking] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [tracking, setTracking] = useState("");
  const [tracked, setTracked] = useState([]);
  const ref = useRef();
  const onScroll = () => {
    const scrollY = window.scrollY;
    const scrollTop = ref.current.scrollTop;
    setScroll(scrollTop);
  }
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {

      if (authUser) {
        setUser(authUser);
        setLoading(false);
      } else {
        setUser(false);
        setLoading(false);
      }
    })
  }, []);
  const hawbArray = tracking.split(/[/]+/);
  const onTrack = () => {
    db.collection("shipments").where("hawb", "in", hawbArray).orderBy("timestamp", "desc").get().then((snapshot) => {
      setTracked(snapshot.docs.map(doc => doc.data()));
      console.log(snapshot.docs.map(doc => doc.data()))
    }).catch((err) => {
      alert(err);
    })
  }
  const handleScrollTop = () => {
    ref.current.scrollTo(0, 0);
  }
  if (!user) {
    return (
      <div>
        <div className="customer-app1" style={{ display: "block" }} ref={ref} onScroll={onScroll}>
          <Router>
            <Home scroll={scroll} />
            {scroll !== 0 && <div className="skydart-scrollTop" onClick={handleScrollTop}>
              <BsArrowUp size={28} />
            </div>}
            <Switch>
              <Route exact path="/">
                <Main />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/tracking/:hawb">
                <Tracing />
              </Route>
              <Route exact path="/tracking/">
                <Track />
              </Route>
              <Route exact path="/scan/:hawb">
                <Login />
              </Route>
              <Route exact path="/login/vn">
                <LoginVn />
              </Route>
              <Route exact path="/registration">
                <Registration />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    )
  }

  if (loading) return <Loading />;
  return (
    <div className="app1">
      {/* Header Component  */}
      <div className="app__page">
        <Welcome user={user} />
      </div>
    </div>
  );
}

export default App;
