import { IconButton } from '@material-ui/core';
import { Clear, Delete, Edit } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import { db } from "../firebase";
import {CopyToClipboard} from 'react-copy-to-clipboard';
export default function Waybill({ hawb, date,booked, status, endDate }) {
    const [alertShipment, setAlertShipment] = useState(false);
    const cancelShipment = () => {
        setAlertShipment(true);
    }
    // const deleteShipment = () => {
    //     db.collection("shipments").doc(hawb).get().then((res) => {
    //         if (res.data().status === "Pending") {
    //             db.collection("shipments").doc(hawb).delete().then(() => {
    //                 alert(`${hawb} has been delete, try book another shipment`);
    //                 setAlertShipment(false);
    //             });
    //         } else if (res.data().status !== "Pending") {
    //             alert("This shipment has been picked up by Skydart's couriers, you can not delete it anymore");
    //             setAlertShipment(false);
    //         }
    //     })
    // }

    return (
        <>
            
            <div className="skd-bill-hawb-body" style={{ position: "relative" }}>
                <h5 className="skd-bill-hawb" style={{ marginTop: -5 }}>{hawb}</h5>
                <div className="skd-bill-hawb-option">
                    <div className="skd-hawb-option">
                        <Edit style={{ fontSize: 13 }} />
                        <h5 style={{ fontSize: 9 }}>Copy</h5>
                    </div>
                </div>
            </div>
        </>
    )
}
