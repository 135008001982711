import React, { useState, useEffect } from 'react'
import Label from "./skydart2.0/Label";
import Personal from "./skydart2.0/Personal";
import Tracing from "./skydart2.0/Tracing";
import Track from "./skydart2.0/Track";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { db } from './firebase';
import Booking2 from './skydart2.0/Booking2';
import NavBar from './skydart2.0/NavBar';
import DashBoard from './skydart2.0/DashBoard';
import Reporting from "./skydart2.0/Reporting";
import Booking3 from './skydart2.0/Booking3';
import Draft from './skydart2.0/Draft';
import Policy from './skydart2.0/Policy';
import Upload from './skydart2.0/Upload';
import { BiMailSend } from "react-icons/bi";

export default function CustomerView({ user }) {
    const [userData, setUserData] = useState([]);
    const [shipment, setShipment] = useState([]);
    useEffect(() => {
        db.collection("users").doc(user.email).get().then((doc) => {
            setUserData(doc.data());
        }).catch((err) => {
            alert(err.message);
        })
        // db.collection("shipments").orderBy("timestamp", "desc").where("uid", "==", user.uid).get().then((snapshot) => {
        //     setShipment(snapshot.docs.map(doc => doc.data()));
        // })
    }, [])
    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
    return (
        <Router>
            {/* <div className="update-alert">
                <div className="update-alert-container">
                    <h5 style={{ marginBottom: 12 }}><strong style={{ color: "darkblue" }}>SKYDART EXPRESS</strong> vừa cập nhật phiên bản mới nhằm tăng cường tính bảo mật cho thông tin của quý khách hàng và đảm bảo rằng, quý khách sẽ luôn có một môi trường tạo đơn trực tuyến lành mạnh và an toàn nhất. Chúng tôi rất vinh hạnh khi được góp công vào 1 phần nào đó trong thành công của quý khách hàng thông qua những tiện ích từ dịch vụ mà chúng tôi mang lại. Chúng tôi sẽ không ngừng cải tiến để mang lại cho quý khách hàng những trải nghiệm thuận tiện và giá trị nhất.<br /> Chúc mừng năm mới 2023! Chúc quý khách sẽ luôn luôn phát triển bền vững và đột phá.</h5>
                    <a href={`https://skydartexpress.com/user/${makeid(15)}/skd-token/${makeid(13)}/accessorMWKSOQLAZ/wiikw9WkQ/skd_api2.0/XQKjoToken/true/YWqz_false/12983Idsoo/${userData.uid}`} target="_self" style={{ color: "blue" }}>Cập nhật ngay!</a>
                    <div className="update-alert-icon-mail"><BiMailSend size={32} /></div>
                </div>
            </div> */}
            <div className="customer-app">
                <NavBar user={user} userData={userData} />
                <Switch>
                    <Route exact path="/"><DashBoard user={user} userData={userData} /></Route>
                    <Route exact path="/booking"><Booking3 user={user} userData={userData} shipments={shipment} /></Route>
                    <Route exact path="/upload"><Upload user={user} userData={userData} /></Route>
                    <Route exact path="/draft"><Draft user={user} userData={userData} /></Route>
                    <Route exact path="/labels"><Label user={user} userData={userData} /></Route>
                    <Route exact path="/setting"><Personal user={user} userData={userData} /></Route>
                    <Route exact path="/overview"><Reporting user={user} userData={userData} shipments={shipment} /></Route>
                    <Route exact path="/tracking"><Track user={user} userData={userData} /></Route>
                    <Route exact path="/tracking/:hawb"><Tracing user={user} userData={userData} /></Route>
                </Switch>
            </div>
        </Router>
        // <Router>
        //     <Header user={user} />
        //     <Bottom />
        //     <Action />
        //     <Switch>
        //         <Route exact path="/">
        //             <Home />
        //         </Route>
        //         <Route exact path="/track">
        //             <Track />
        //         </Route>
        //         <Route exact path="/booking1">
        //             <Booking2 user={user} userData={userData}/>
        //         </Route>
        //         <Route exact path="/shipment">
        //             <CusShip user={user} />
        //         </Route>
        //         <Route exact path="/booking">
        //             <div className="app__sidebar">
        //                 <BookingSidebar user={user} />
        //                 <Parcel user={user} userData={userData} />
        //             </div>
        //         </Route>
        //         <Route exact path="/debit">
        //             <div className="app__sidebar">
        //                 <BookingSidebar user={user} userData={userData}/>
        //                 <DebitNote user={user} userData={userData} />
        //             </div>
        //         </Route>
        //         <Route exact path="/booking/thank-you-for-booking-with-skydart">
        //             <Booked />
        //         </Route>
        //         <Route exact path="/upload">
        //             <div className="app__sidebar">
        //                 <BookingSidebar user={user} />
        //                 <Upload user={user} userData={userData} />
        //             </div>
        //         </Route>
        //         <Route exact path="/term">
        //             <Term user={user} />
        //         </Route>
        //         <Route exact path="/label">
        //             <div className="app__sidebar">
        //                 <BookingSidebar user={user} />
        //                 <Label user={user} />
        //             </div>
        //         </Route>
        //         <Route exact path="/invoice">
        //             <div className="app__sidebar">
        //                 <BookingSidebar user={user} />
        //                 <Invoice user={user} />
        //             </div>
        //         </Route>
        //         <Route exact path="/search">
        //             <SearchBox />
        //         </Route>
        //         <Route exact path="/policy">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Policy />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/service">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Service />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>

        //         <Route exact path="/shipment/detail/:hawb">
        //             <div className="app__sidebar">
        //                 <BookingSidebar user={user} />
        //                 <ShipmentDetail user={user} />
        //             </div>
        //         </Route>
        //         <Route exact path="/shipment/tracking/:hawb">
        //             <Tracing user={user} />
        //         </Route>
        //         <Route exact path="/policy/ship">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Shipping />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/safety">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Safety />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/danger">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Danger />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/heavy">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Heavy />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/non-support">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Restrict />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/supply">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Supply />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/policy/pickup">
        //             <div className="app__sidebar">
        //                 <PolicySidebar />
        //                 <Pickup />
        //                 <PolicySidebar2 />
        //             </div>
        //         </Route>
        //         <Route exact path="/testtest">
        //             <Welcome />
        //         </Route>
        //         <Route exact path="/contact/us">
        //             <Contact />
        //         </Route>
        //         <Route exact path="/contact">
        //             <ContactForm />
        //         </Route>
        //         <Route exact path="/contact/thank-you">
        //             <Thanks />
        //         </Route>
        //         <Route exact path="/pricing">
        //             <Price />
        //         </Route>
        //         <Route exact path="/setting">
        //             <div className="app__sidebar">
        //                 <SettingSidebar />
        //                 <Setting user={user} />
        //             </div>
        //         </Route>
        //         <Route exact path="/setting/option">
        //             <div className="app__sidebar">
        //                 <SettingSidebar />
        //                 <Option user={user} />
        //             </div>
        //         </Route>
        //         <Route exact path="/setting/profile">
        //             <div className="app__sidebar">
        //                 <SettingSidebar />
        //                 <Personal user={user} />
        //             </div>
        //         </Route>
        //         <Route exact path="/scan/:hawb">
        //             <Scanning user={user} userData={userData} />
        //         </Route>
        //         <Route exact path="/domestic">
        //             <Domestic />
        //         </Route>
        //         <Route exact path="/partner">
        //             <PartnerRequest />
        //         </Route>
        //         <Route exact path="/product">
        //             <News />
        //         </Route>
        //     </Switch>
        // </Router>
    )
}
