import React, { useState, useEffect } from 'react'
import "./Main.css";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Clear, Email, EmailOutlined } from '@material-ui/icons';
import { RiLockPasswordLine } from 'react-icons/ri';
import { IoIosPricetags } from "react-icons/io";
import { FaArrowAltCircleDown } from "react-icons/fa";
import shippingimg from "./images/shippingimg.png";
import { Button, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import dashboard from "./images/dashboard.jpg";
import booking from "./images/booking.jpg";
import waitingClearance from "./images/waitingClearance.png";
import pullingShipment from "./images/pullingShipment.png";
import skd from "./images/skd.png";
import { auth } from './firebase';
import packaging from "./images/package.png";
import dg from "./images/dg.png";
import { MdSupportAgent } from "react-icons/md";
import { BiLogIn } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import box from "./images/box.png";
import document from "./images/document.png";
import bag from "./images/bag.png";
import label from "./images/label.jpg";
import skydartware from "./images/skydartware.jpg";
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { db } from './firebase';
import { Package } from 'react-feather';
import { TextareaAutosize } from '@material-ui/core';
import axios from 'axios';
export default function Main() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadTrack, setLoadTrack] = useState(false);
    const [errMessage, setErrMessage] = useState(false);
    const [tracking, setTracking] = useState("");
    const [onTrack, setOnTrack] = useState(false);
    const [displaySignUp, setDisplaySignUp] = useState(false);
    const history = useHistory();
    const [error, setError] = useState(false);
    const [trackResult, setTrackResult] = useState("");
    const [users, setUsers] = useState([]);
    // let addFormFields = () => {
    //     setTrackForm([...trackForm, { tracking: "" }])
    // };
    // let handleChange = (i, e) => {
    //     let newFormValues = [...trackForm];
    //     newFormValues[i][e.target.name] = e.target.value.slice(0, 15);
    //     setTrackForm(newFormValues);
    //     if (newFormValues[i][e.target.name].length > 14) {
    //         setTrackForm([...trackForm, { tracking: newFormValues[i][e.target.name].slice(15, 30) }])
    //     }
    // }

    const signIn = (e) => {
        e.preventDefault();
        setLoading(true);
        auth
            .signInWithEmailAndPassword(email, password)
            .then((auth) => {
                setLoading(false);
                history.push("/");
            })
            .catch(error => {
                alert(error);
                setLoading(false);
            });
    }
    const trackingArray = tracking.split(/\r?\n/);
    useEffect(() => {
        let arr = [];
        trackingArray.map((s) => {
            arr.push(s);
        })
        setTrackResult(arr.toString());
    }, [trackingArray])
    // useEffect(() => {
    //     db.collection("users").get().then((snapshot) => {
    //         setUsers(snapshot.docs.map(doc => doc.data()));
    //     })
    // }, [])
    const staffs = users.filter((s) => {
        return s.employee === "yes";
    })
    const customers = users.filter((s) => {
        return s.employee === "no"
    })
    const customer1 = customers.slice(701, 800);
    const addCustomer = () => {
        customer1.forEach((s) => {
            axios.post("https://test.skydart.com.vn/api/customers", {
                address: s.address,
                address2: s.address2,
                address3: s.address3,
                city: s.city,
                controller: s.controller,
                country: s.country,
                createAt: s.createAt,
                timestamp: s.timestamp,
                displayName: s.displayName,
                email: s.email,
                exporter: s.exporter,
                makh: s.makh,
                password: s.password,
                plan: s.plan,
                postal: s.postal,
                state: s.state,
                town: s.town,
                uid: s.uid,
            })

        })
    }
    const addStaff = () => {
        staffs.map((s) => {
            axios.post("https://test.skydart.com.vn/api/staff", {
                displayName: s.displayName,
                email: s.email,
                employee: s.employee,
                role: s.role,
                subRole: s.subRole,
                title: s.role,
                password: s.password,
                createAt: s.createAt,
                phone: s.phone,
                uid: s.uid,
            })
        })
    }
    return (
        <div className="skydart-main">

            {displaySignUp && <div className="skydart-main-signup">
                <div className="skydart-main-signup-container">
                    <div className="skydart-main-signup-header">
                        <h3>Create account to get shipped anytime</h3>
                        <Clear className="skydart-main-clear" onClick={() => { setDisplaySignUp(false) }} />
                    </div>
                </div>
            </div>}
            <div className="skydart__home__container">
                <div className="skydart-main-primary">
                    <div className="skydart-main-primary-left">
                        <div className="skydart-main-primary-left-header">
                            <h1>
                                Suitable express <br />
                                shipping solution <br />
                                for everyone
                            </h1>
                        </div>
                        <div style={{ display: "flex" }}>
                            <p className="skydart-main-regist">New customer ?</p>
                            <p className="skydart-main-register" onClick={() => history.push("/registration")}>Create an account</p>
                        </div>
                        <form className="skydart-main-primary-left-body" onSubmit={signIn}>
                            <input className="skydart-main-text"
                                value={email}
                                type='text'
                                placeholder="Email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input className="skydart-main-text"
                                value={password}
                                type='password'
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="skydart-main-button">
                                {!loading && <button type="submit">Sign In</button>}
                                {loading && <button type="button" style={{ opacity: 0.5, cursor: "not-allowed" }}><CircularProgress size={15} color="white" /></button>}
                            </div>

                        </form>
                        <p className="chick">Skydart Express make your shipping journey become easy, fast,<br />safety, and no worry.</p>
                    </div>
                    {/* <div className="skydart-main-bag-img">
                        <img src={bag} alt="" />
                    </div> */}
                    {/* <div className="skydart-main-img-dash">
                        <img src={document} alt="" />
                    </div> */}
                    {/* <div className="video-responsive">
                        <iframe
                            width="650"
                            height="380"
                            src='https://www.youtube.com/embed/rokGy0huYEA'
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div> */}
                    {/* <div className="skydart-main-img-booking">
                        <div className="skydart-main-img-booking-fast">
                            <button type="button">
                                <Package size={20} style={{ marginRight: 5 }} />
                                Ship now
                            </button>
                        </div>
                    </div> */}
                    <div className="skydart-main-tracking">
                        <h1 style={{ fontWeight: 600, marginTop: 15 }}>Track your shipment</h1>
                        <div className="skydart-tracking-sticky-center">
                            <TextareaAutosize maxLength={159} style={tracking !== "" ? { boxShadow: "green 0px 3px 0px" } : { boxShadow: "none" }} value={tracking} onChange={(e) => setTracking(e.target.value)} className="skydart-textarea" placeholder="Up to 10 shipments separated by an enter or return" />
                            <a href={`/tracking/${trackResult}`}><button type="button">Track</button></a>
                        </div>
                        <p style={{ marginTop: 10, lineHeight: 1.7, marginLeft: 2 }}>Following your package online, <br />have your shipment's status in real time.
                            Ship your package ? <br /> Join Skydart today!</p>

                    </div>
                </div>
                {/* <div className="skydart-main-service">
                    <img src="https://skydart.com.vn/vnt_upload/weblink/banner-6.jpg" alt="" />
                    <div className="skydart-main-service-container">
                        <h1 style={{ padding: 10, fontWeight: 600, textAlign: 'center', color: "#4d4e53" }}>Ideal Logistic Solution </h1>
                        <div className="skydart-main-service-section">
                            <div className="skydart-main-service-option">
                                <BsBoxSeam size={30} />
                                <h3>Ship Now</h3>
                                <p>Get package into shipped <br /> today for best price</p>
                            </div>
                            <div className="skydart-main-service-option">
                                <IoIosPricetags size={30} />
                                <h3>Get quote</h3>
                                <p>Refer our price to decide <br /> where you should <br /> choose to ship your package </p>
                            </div>
                            <div className="skydart-main-service-option">
                                <MdSupportAgent size={30} />
                                <h3>Logistic support</h3>
                                <p>Share to us every of your <br /> logistic question</p>
                            </div>
                            <div className="skydart-main-service-option" style={{ borderRight: "none" }}>
                                <BiLogIn size={30} />
                                <h3>Login</h3>
                                <p>Login to start ship your package <br /> easier and faster</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="login-phone">
                <div className="login-phone-container">
                    <div className="login-phone-header">
                        <img src="http://skydart.com.vn/vnt_upload/weblink/logo.png" alt="skd-logo-phone" />
                    </div>
                    <div className="login-phone-body">
                        <div className="login-phone-body-content">
                            <input type="text" value={email} placeholder="Type in your email address / username" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="login-phone-body-content">
                            <input type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="login-phone-body-content">
                            <button type="button" onClick={signIn}>Login</button>
                            {/* <p onClick={forGotPW}>Forgot Account ?</p>
                            <h5 onClick={createAccount}>Create An Account</h5> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="skydart-main-choose-service">
                <div className="skydart-main-choose-service-container">
                    <div className="skydart-main-choose-service-title">
                        <FaArrowAltCircleDown />
                        <p>Visit our services</p>
                    </div>
                    <div className="skydart-main-choose-service-body">
                        <div className="skydart-main-choose-service-option">
                            <div className="skydart-main-choose-primary">
                                <img src="https://skydart.com.vn/vnt_upload/news/02_2017/thumbs/480_crop_news7.jpg" alt="" />
                            </div>
                            <p>Skydart Specialist Portal </p>
                        </div>
                        <div className="skydart-main-choose-service-option">
                            <div className="skydart-main-choose-primary">
                                <img src="https://skydart.com.vn/vnt_upload/news/02_2017/thumbs/480_crop_Take_off_2.jpg" alt="" />
                            </div>
                            <p>Skydart Air Freight</p>
                        </div>
                        <div className="skydart-main-choose-service-option">
                            <div className="skydart-main-choose-primary">
                                <img src={pullingShipment} alt="" />
                            </div>
                            <p>Skydart Import, Export</p>
                        </div>
                        <div className="skydart-main-choose-service-option">
                            <div className="skydart-main-choose-primary">
                                <img src={dg} alt="" style={{ width: 150, height: 150 }} />
                            </div>
                            <p>Skydart Dangerous Goods</p>
                        </div>

                    </div>
                    <div className="skydart-main-choose-service-mission">
                        <div className="skydart-main-choose-service-mission-header">
                            <img src="https://i.imgur.com/L6PnhXq.png" style={{ width: "70px", height: "70px" }} alt="Skydart Express" />
                            <h1>SKYDART EXPRESS</h1>
                        </div>
                        <h3 className="chick2" style={{ marginTop: 5, textAlign: "center", fontSize: 24, color: "black" }}>Revolution of customer experience, helping people and businesses <br />to delievery their value and goods to others.</h3>
                        <p style={{ marginTop: 15, textAlign: "center", fontSize: 14, color: "rgb(35, 59, 37)" }}>Skydart has shipped milion of packages from Vietnam to all countries around the world, that's mean we are also <br /> one of the biggestagent of most of the largest courier corporate in the world.  </p>
                        <button type="button">Ship Now</button>
                    </div>
                </div>
            </div> */}
        </div >
    )
}
