import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StaffSetting from './skydart2.0/StaffSetting';
import { db } from "./firebase";
import Scanning from './Scanning';
import UserSetting from './skydart2.0/UserSetting';
// import Rate from './skydart-employee/Rate';
import SkydartHeader from './skydart2.0/SkydartHeader';
import NewBooking from './skydart2.0/NewBooking';
import Shipmentss from "./skydart2.0/Shipmentss";
import Booking2 from './skydart2.0/Booking2';
import PhoneAccess from "./skydart2.0/PhoneAccess";
import Hawb2 from './skydart2.0/Hawb2';
import Users from './skydart2.0/Users';
import Debt from './skydart2.0/Debt';
import StaffCreate from './skydart2.0/StaffCreate';
import Rate from './skydart2.0/Rate';
import SkdHeader from './skydart2.0/SkdHeader';
import TransHome from './skydart2.0/TransHome';
import Booking3 from './skydart2.0/Booking3';
import Pricing from './skydart2.0/Pricing';
import StaffBooking from './skydart2.0/StaffBooking';
import Packing from './skydart2.0/Packing';
import Passcode from './Passcode';
export default function Staff({ user }) {
    const [userData, setUserData] = useState([]);
    const [shipment, setShipment] = useState([]);
    useEffect(() => {
        db.collection("users").doc(user.email).get().then((doc) => {
            setUserData(doc.data());
        })
    }, [])
    return (
        <div className="staff-app">
            <Router>
                <SkdHeader user={user} userData={userData} />
                {/* {!userData.changedPaswword && <Passcode user={user} userData={userData}/>} */}
                <Switch>
                    <Route exact path="/"><NewBooking user={user} userData={userData} /></Route>
                    <Route exact path="/phone-access"><PhoneAccess user={user} userData={userData} /></Route>
                    <Route exact path="/shipments"><Shipmentss user={user} userData={userData} /></Route>
                    <Route exact path="/booking"><StaffBooking user={user} userData={userData} /></Route>
                    <Route exact path="/shipment/:hawb"><Hawb2 user={user} userData={userData} /></Route>
                    <Route exact path="/scan/:hawb"><Scanning user={user} userData={userData} /></Route>
                    <Route exact path="/setting"><StaffSetting user={user} userData={userData} /></Route>
                    <Route exact path="/users"><Users user={user} userData={userData} /></Route>
                    <Route exact path="/rate"><Pricing user={user} userData={userData} /></Route>
                    <Route exact path="/packing"><Packing userData={userData} /></Route>
                    {/* <Route exact path="/staff"><TransHome user={user} userData={userData} /></Route> */}
                    <Route exact path="/setting/:userEmail"><UserSetting user={user} userData={userData} /></Route>
                    <Route exact path="/debt"><Debt user={user} userData={userData} /></Route>
                </Switch>
            </Router>
            {/* {userData.role === "CEO" && <Router>
                <Switch>
                    <Route exact path="/"><NewBooking user={user} userData={userData} /></Route>
                </Switch>
            </Router>} */}
        </div>
        // <Router>
        //     <StaffHeader user={user} userData={userData} />
        //     <div className="app">
        //         <Switch>
        //             <Route exact path="/home">
        //                 <TransHome user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/fsc">
        //                 <Fsc user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/rate">
        //                 <Rate user={user} userData={userData}/>
        //             </Route>
        //             <Route exact path="/">
        //                 <Shipmentss user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/customers">
        //                 <Customer user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/new-shipment">
        //                 <NewShipment user={user} userData={userData}/>
        //             </Route>
        //             <Route exact path="/tracking">
        //                 <MultipleTrack user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/new-booking/:hawb">
        //                 <Hawb />
        //             </Route>
        //             <Route exact path="/setting">
        //                 <StaffSetting user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/revenue">
        //                 <Revenue user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/trans/customer">
        //                 <CusDebit user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/trans/customer/paid">
        //                 <CusPaid user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/customers/:email/:company">
        //                 <CustomerInfo user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/scan/:hawb">
        //                 <Scanning user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/setting/:userEmail">
        //                 <UserSetting user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/customer/debit/:sender">
        //                 <DebitDetail user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/customer/paid/:sender">
        //                 <PaidDetail user={user} userData={userData} />
        //             </Route>

        //             <Route exact path="/trans/pickup">
        //                 <PickUpPaid user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/trans/acc">
        //                 <AccountantPaid user={user} userData={userData} />
        //             </Route>
        //             <Route exact path="/trans/company">
        //                 <CompanyPaid user={user} userData={userData} />
        //             </Route>
        //         </Switch>
        //     </div>
        // </Router>
    )
}
