import { CircularProgress } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { db, auth } from "../firebase";
import "./Track.css";
export default function Track() {
    const history = useHistory();
    const [hawb, setHawb] = useState("");
    const [validTrack, setValidTrack] = useState(false);
    const [loading, setLoading] = useState(false);
    const [trackResult, setTrackResult] = useState("");
    // useEffect(() => {
    //     setTrackShipment(hawb.slice(0,3));
    //     if (trackShipment !== "SKD") {
    //         setValidTrack(false);
    //     } else if (trackShipment === "SKD"){
    //         setValidTrack(true);
    //     }
    // })
    const track = () => {
        if (track === "") {
            alert("Please type in at least 1 tracking number");
        } else {
            setLoading(true);
            setTimeout(() => {
                history.push(`/tracking/${trackResult}`);
                setLoading(false);
            }, 1200)
        }

    }
    const trackingArray = hawb.split(/\r?\n/);
    useEffect(() => {
        let arr = [];
        trackingArray.map((s) => {
            arr.push(s);
        })
        setTrackResult(arr.toString());
    }, [trackingArray])
    return (
        <div className="track">
            <div className="tracking__container">
                <div className="tracking__container__content">
                    <div className="tracking__content">
                        <div className="tracking__content__header">
                            <h1>Track and trace your shipment</h1>
                            <div className="tracking__content__header__right">
                                <div className="tracking__header__option" onClick={() => history.goBack()}>
                                    <h3>Back</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tracking__content__body">
                            <div className="tracking__box">
                                <div className="track__box">
                                    <Search fontSize="large" />
                                    <textarea type="text" value={hawb} onChange={(e) => setHawb(e.target.value)} placeholder="Enter up to 10 tracking number, separated by enter or return" />
                                </div>
                                <div className="tracking__button" onClick={track}>
                                    <button type="button">
                                        Track Shipment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <>
                <div className="tracking-loading">
                    <div className="tracing-loading-container">
                        <CircularProgress size={30} color="white" />
                        <p>Loading...</p>
                    </div>
                </div>
            </>
            }
        </div>
    )
}
