import React, { useState, useEffect, useRef } from 'react'
import "./Pricing.css";
import { Add, ArrowDownward, ArrowDropDown, LocalShipping, FileCopyOutlined, RefreshOutlined, Block, CallReceived, Assessment, ArrowRightAlt, Refresh, Clear, Crop, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, FileCopySharp, MoreHoriz, ShowChart, Event, ArrowLeft, ArrowRight, LocalShippingOutlined, PanTool, Check } from '@material-ui/icons';
import { Checkbox, CircularProgress, IconButton, Snackbar } from "@material-ui/core";
import DatepickerContext from "./datepickerContext";
import axios from "axios";
import MuiAlert from '@material-ui/lab/Alert';
import { AiOutlineMinus } from 'react-icons/ai';
import { File, Filter } from 'react-feather';
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { db } from "../firebase";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import Rate from "./Rate";
import Month from './Month';
import { GoCalendar } from "react-icons/go";
export default function Pricing({ userData }) {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? "0" : "");
    let last = firstDayOfCurrentWeek + 6;
    let firstday = firstDayOfCurrentWeek - 6;
    let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
    let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
    let yesterday = date - 1;
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    let lastMonth = month - 1;
    let year = newDate.getFullYear();
    let lastDay = new Date(year, lastMonth, 0);
    let firstDayOfYear = new Date(year, 0, 1);
    let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
    let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
    const monthName = months[newDate.getMonth()];
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? "0" : "") + firstDay.getDate();
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const [loading, setLoading] = useState(true);
    const [shipments, setShipments] = useState([]);
    const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
    const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
    const [dateStatus, setDateStatus] = useState("Period");
    const [displayDateRange, setDisplayDateRange] = useState(false);
    const [onGoingPage, setOnGoingPage] = useState("");
    const [displayAutoRate, setDisplayAutoRate] = useState(false);
    const [displayRating, setDisplayRating] = useState(false);
    const [loadingRate, setLoadingRate] = useState(false);
    const [displaySurcharge, setDisplaySurcharge] = useState(false);
    const [rate, setRate] = useState([]);
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [rateInput, setRateInput] = useState("");
    const [fscInput, setFscInput] = useState("");
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [fscValueInput, setFscValueInput] = useState("");
    const [comesticInput, setComesticInput] = useState("");
    const [remoteInput, setRemoteInput] = useState("");
    const [addressCorrectionInput, setAddressCorrectionInput] = useState("");
    const [vatValueInput, setVatValueInput] = useState("");
    const [surchargeInput, setSurchargeInput] = useState("");
    const [surchargeInput2, setSurchargeInput2] = useState("");
    const [surchargeInput3, setSurchargeInput3] = useState("");
    const [comInput, setComInput] = useState("");
    const [sellNoteInput, setSellNoteInput] = useState("");
    const [vatInput, setVatInput] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [fuel, setFuel] = useState("");
    const [type, setType] = useState("");
    const [currentPlan, setCurrentPlan] = useState('');
    const [recent, setRecent] = useState([]);
    const [addressCorrect, setAddressCorrect] = useState("");
    const [dhlFsc, setDhlFsc] = useState("");
    const [fedexFsc, setFedexFsc] = useState("");
    const [upsFsc, setUpsFsc] = useState("");
    const [dusFsc, setDusFsc] = useState("");
    const [otherFsc, setOtherFsc] = useState("");
    const [remote, setRemote] = useState("");
    const [fetchRemote, setFetchRemote] = useState("");
    const [fetchAddressCorrect, setFetchAddressCorrect] = useState("");
    const [fetchDhl, setFetchDhl] = useState("");
    const [fetchFedex, setFetchFedex] = useState("");
    const [fetchUps, setFetchUps] = useState("");
    const [fetchDus, setFetchDus] = useState("");
    const [fetchOther, setFetchOther] = useState("");
    const [displayCountry, setDisplayCountry] = useState(false);
    const [displaySender, setDisplaySender] = useState(false);
    const [displayOverview, setDisplayOverview] = useState(false);
    const [sortDate, setSortDate] = useState(false);
    const [breakCountry, setBreakCountry] = useState(false);
    const [breakWeight, setBreakWeight] = useState(false);
    const [breakService, setBreakService] = useState(false);
    const [breakRate, setBreakRate] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE
    });
    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths
    } = useDatepicker({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        focusedInput: dateRange.focusedInput,
        onDatesChange: handleDateChange
    });
    const [searchRate, setSearchRate] = useState("");
    const [searchAddress, setSearchAddress] = useState("");
    const [searchRemote, setSearchRemote] = useState("");
    const [searchSurcharge, setSearchSurcharge] = useState("");
    const [searchSurcharge2, setSearchSurcharge2] = useState("");
    const [searchSurcharge3, setSearchSurcharge3] = useState("");
    const [searchFsc, setSearchFsc] = useState("");
    const [searchFscValue, setSearchFscValue] = useState("");
    const [searchVat, setSearchVat] = useState("");
    const [searchVatValue, setSearchVatValue] = useState("");
    const [searchCus, setSearchCus] = useState("");
    const [searchWeight, setSearchWeight] = useState("");
    const [searchReweight, setSearchReweight] = useState("");
    const [searchService, setSearchService] = useState("");
    const [searchClass, setSearchClass] = useState("");
    const [searchCountry, setSearchCountry] = useState("");
    const [searchType, setSearchType] = useState("");
    const [searchComestic, setSearchComestic] = useState("");
    const [searchSale, setSearchSale] = useState("");
    const [searchSellNote, setSearchSellNote] = useState("");
    const [searchHawb, setSearchHawb] = useState("");
    const [searchNote, setSearchNote] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageNumberLimit, setPageNumberLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [selectedShipment, setSelectedShipment] = useState([]);
    function removeAccents(str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }

    const roleBase = shipments.filter((s) => {
        if (userData.role === "Sales") {
            return s.controller === userData.displayName;
        } else {
            return s;
        }
    })
    let dateRef = useRef();
    function handleDateChange(data) {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE });
        } else {
            setDateRange(data);
        }
    }
    const handleCancelDate = () => {
        setDisplayDateRange(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const d = new Date(dateRange.startDate);
    const selectMonth = ((d.getMonth() + 1) < 10 ? "0" : "") + (d.getMonth() + 1);
    const selectDate = (d.getDate() < 10 ? "0" : "") + d.getDate();
    const selectYear = d.getFullYear();


    const e = new Date(dateRange.endDate);
    const selectEndMonth = ((e.getMonth() + 1) < 10 ? "0" : "") + (e.getMonth() + 1);
    const selectEndDate = (e.getDate() < 10 ? "0" : "") + e.getDate();
    const selectEndYear = e.getFullYear();

    const handleApplyDate = () => {
        if (!dateRange.endDate) {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setDisplayDateRange(false);
            setDateStatus("Period");
        } else {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
            setDisplayDateRange(false);
            setDateStatus("Period");
        }
    }
    const handleToday = () => {
        setStartDate(`${year}-${month}-${date}`)
        setEndDate(`${year}-${month}-${date}`)
        setDisplayDateRange(false);
        setDateStatus("Today");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleYesterday = () => {
        if (yesterday === 0) {
            setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setDisplayDateRange(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        } else {
            setStartDate(`${year}-${month}-${yesterday}`);
            setEndDate(`${year}-${month}-${yesterday}`);
            setDisplayDateRange(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        }
    }
    const handleThisWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
        setDisplayDateRange(false);
        setDateStatus("This week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
        setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setDisplayDateRange(false);
        setDateStatus("Last week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const currentYear = new Date().getFullYear();
    const handleThisMonth = () => {
        setStartDate(`${year}-${month}-${first}`)
        setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split("T")[0]);
        setDisplayDateRange(false);
        setDateStatus("This month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastMonth = () => {
        setStartDate(`${year}-${prevMonth}-01`)
        setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
        setDisplayDateRange(false);
        setDateStatus("Last month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleThisYear = () => {
        setStartDate(`${year}-01-01`);
        setEndDate(`${year}-12-31`);
        setDateStatus("This year");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const convertThoudsand = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
    }
    useEffect(() => {
        let handler = (e) => {
            if (!dateRef.current.contains(e.target)) {
                setDisplayDateRange(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const finalShipment = roleBase.filter((s, i) => {
        let plan = s.plan || "";
        let rate = s.rate;
        let addressCorrect = s.addressCorrection;
        let remote = s.remote;
        let surcharge = s.surcharge;
        let fsc = s.fsc;
        let fscValue = s.fscValue;
        let surcharge2 = s.surcharge2;
        let vat = s.vat;
        let vatValue = s.vatValue;
        let accountNote = s.accountNote || "";
        let surcharge3 = s.surcharge3;
        return s.sender.toLowerCase().includes(searchCus.toLowerCase()) &&
            plan.toLowerCase().includes(searchClass.toLowerCase()) &&
            s.country.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
            s.type.toLowerCase().includes(searchType.toLowerCase()) &&
            s.service.toLowerCase().includes(searchService.toLowerCase()) &&
            rate.toLowerCase().includes(searchRate.toLowerCase()) &&
            addressCorrect.toLowerCase().includes(searchAddress.toLowerCase()) &&
            s.vat.toLowerCase().startsWith(searchVat.toLowerCase()) &&
            remote.toLowerCase().startsWith(searchRemote.toLowerCase()) &&
            surcharge.toLowerCase().startsWith(searchSurcharge.toLowerCase()) &&
            fsc.toLowerCase().startsWith(searchFsc.toLowerCase()) &&
            s.comestic.toLowerCase().startsWith(searchComestic.toLowerCase()) &&
            fscValue.toLowerCase().startsWith(searchFscValue.toLowerCase()) &&
            surcharge2.toLowerCase().startsWith(searchSurcharge2.toLowerCase()) &&
            vatValue.toLowerCase().startsWith(searchVatValue.toLowerCase()) &&
            surcharge3.toLowerCase().startsWith(searchSurcharge3.toLowerCase()) &&
            s.controller.toLowerCase().includes(searchSale.toLowerCase()) &&
            s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) &&
            s.note.toLowerCase().includes(searchNote.toLowerCase()) &&
            s.sellNote.toLowerCase().includes(searchSellNote.toLowerCase()) &&
            s.reweight.toLowerCase().startsWith(searchReweight.toLowerCase());
    })
    const getClass = finalShipment.filter((s) => {
        if (searchClass !== "") {
            return s.plan === searchClass;
        } else {
            return s;
        }
    })
    const getWeight = getClass.filter((s) => {
        if (searchWeight !== "") {
            return s.chargeable === searchWeight;
        } else {
            return s;
        }
    })
    const pages = [];
    for (let i = 1; i <= Math.ceil(getWeight.length / itemPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = getWeight.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (e) => {
        setCurrentPage(Number(e.target.id));
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageLimit + 1 && number > minPageLimit) {
            return (
                <li key={number} id={number} onClick={handleChangePage} style={{ border: "1px solid rgb(230, 232, 241)" }} className={currentPage == number ? "active32" : null}>
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })

    const exportSelected = roleBase.filter((s) => {
        return s.select;
    })
    const handleFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                readExcel(file);
                setFileName(file.name);
                setFileType(file.type);
                setFileSize(file.size);
            }, 1200)
        } else {
            setLoading(false);
        }
    }
    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
        promise.then((item) => {
            setRate(item);
            console.log(item);
        })
    }
    const uploadRate = () => {

    }
    const updateCloudRate = () => {

    }
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    function niceBytes(x) {

        let l = 0, n = parseInt(x, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }


    const updateDhlCloud = () => {
        addFile();
        rate.forEach((s) => {
            db.collection(currentPlan === 'FWD' ? "dhl" : "dhl-fwd2").doc(s.Country).set({
                country: s.Country,
                zone: s.Zone,
                doxHalf: s.DoxHalf?.toFixed(2),
                doxOne: s.DoxOne?.toFixed(2),
                doxOneHalf: s.DoxOnehalf?.toFixed(2),
                doxTwo: s.Doxtwo?.toFixed(2),
                half: s.Half?.toFixed(2),
                one: s.One?.toFixed(2),
                oneHalf: s.OneHalf?.toFixed(2),
                two: s.Two?.toFixed(2),
                twoHalf: s.TwoHalf?.toFixed(2),
                three: s.Three?.toFixed(2),
                threeHalf: s.ThreeHalf?.toFixed(2),
                four: s.Four?.toFixed(2),
                fourHalf: s.FourHalf?.toFixed(2),
                five: s.Five?.toFixed(2),
                fiveHalf: s.FiveHalf?.toFixed(2),
                six: s.Six?.toFixed(2),
                sixHalf: s.SixHalf?.toFixed(2),
                seven: s.Seven?.toFixed(2),
                sevenHalf: s.SevenHalf?.toFixed(2),
                eight: s.Eight?.toFixed(2),
                eightHalf: s.EightHalf?.toFixed(2),
                nine: s.Nine?.toFixed(2),
                nineHalf: s.NineHalf?.toFixed(2),
                ten: s.Ten?.toFixed(2),
                tenHalf: s.TenHalf?.toFixed(2),
                eleven: s.Eleven?.toFixed(2),
                elevenHalf: s.ElevenHalf?.toFixed(2),
                twelve: s.Twelve?.toFixed(2),
                twelveHalf: s.TwelveHalf?.toFixed(2),
                thirteen: s.Thirteen?.toFixed(2),
                thirteenHalf: s.ThirteenHalf?.toFixed(2),
                fourteen: s.Fourteen?.toFixed(2),
                fourteenHalf: s.FourteenHalf?.toFixed(2),
                fifteen: s.Fifteen?.toFixed(2),
                fifteenHalf: s.FifteenHalf?.toFixed(2),
                sixteen: s.Sixteen?.toFixed(2),
                sixteenHalf: s.SixteenHalf?.toFixed(2),
                seventeen: s.Seventeen?.toFixed(2),
                seventeenHalf: s.SeventeenHalf?.toFixed(2),
                eightteen: s.Eightteen?.toFixed(2),
                eightteenHalf: s.EightteenHalf?.toFixed(2),
                nineteen: s.Nineteen?.toFixed(2),
                nineteenHalf: s.NineteenHalf?.toFixed(2),
                twenty: s.Twenty?.toFixed(2),
                twentyHalf: s.TwentyHalf?.toFixed(2),
                twentyone: s.Twentyone?.toFixed(2),
                twentyoneHalf: s.TwentyoneHalf?.toFixed(2),
                twentytwo: s.Twentytwo?.toFixed(2),
                twentytwoHalf: s.TwentytwoHalf?.toFixed(2),
                twentythree: s.Twentythree?.toFixed(2),
                twentythreeHalf: s.TwentythreeHalf?.toFixed(2),
                twentyfour: s.Twentyfour?.toFixed(2),
                twentyfourHalf: s.TwentyfourHalf?.toFixed(2),
                twentyfive: s.Twentyfive?.toFixed(2),
                twentyfiveHalf: s.TwentyfiveHalf?.toFixed(2),
                twentysix: s.Twentysix?.toFixed(2),
                twentysixHalf: s.TwentysixHalf?.toFixed(2),
                twentyseven: s.Twentyseven?.toFixed(2),
                twentysevenHalf: s.TwentysevenHalf?.toFixed(2),
                twentyeight: s.Twentyeight?.toFixed(2),
                twentyeightHalf: s.TwentyeightHalf?.toFixed(2),
                twentynine: s.Twentynine?.toFixed(2),
                twentynineHalf: s.TwentynineHalf?.toFixed(2),
                thirty: s.Thirty?.toFixed(2),
                small: s.Small?.toFixed(2),
                medium: s.Medium?.toFixed(2),
                large: s.Large?.toFixed(2),
            }).then(() => {
                setLoading(false);
            })
        })
    }

    const updateFIECloud = () => {
        addFile();
        rate.forEach((s) => {
            db.collection(currentPlan === 'FWD' ? "fie" : 'fie-fwd2').doc(s.Country).set({
                // code: s.Code,
                country: s.Country,
                zone: s.Zone,
                doxHalf: s.DoxHalf?.toFixed(2),
                doxOne: s.DoxOne?.toFixed(2),
                doxOneHalf: s.DoxOnehalf?.toFixed(2),
                doxTwo: s.DoxTwo?.toFixed(2),
                half: s.Half?.toFixed(2),
                one: s.One?.toFixed(2),
                oneHalf: s.OneHalf?.toFixed(2),
                two: s.Two?.toFixed(2),
                twoHalf: s.TwoHalf?.toFixed(2),
                three: s.Three?.toFixed(2),
                threeHalf: s.ThreeHalf?.toFixed(2),
                four: s.Four?.toFixed(2),
                fourHalf: s.FourHalf?.toFixed(2),
                five: s.Five?.toFixed(2),
                fiveHalf: s.FiveHalf?.toFixed(2),
                six: s.Six?.toFixed(2),
                sixHalf: s.SixHalf?.toFixed(2),
                seven: s.Seven?.toFixed(2),
                sevenHalf: s.SevenHalf?.toFixed(2),
                eight: s.Eight?.toFixed(2),
                eightHalf: s.EightHalf?.toFixed(2),
                nine: s.Nine?.toFixed(2),
                nineHalf: s.NineHalf?.toFixed(2),
                ten: s.Ten?.toFixed(2),
                tenHalf: s.TenHalf?.toFixed(2),
                eleven: s.Eleven?.toFixed(2),
                elevenHalf: s.ElevenHalf?.toFixed(2),
                twelve: s.Twelve?.toFixed(2),
                twelveHalf: s.TwelveHalf?.toFixed(2),
                thirteen: s.Thirteen?.toFixed(2),
                thirteenHalf: s.ThirteenHalf?.toFixed(2),
                fourteen: s.Fourteen?.toFixed(2),
                fourteenHalf: s.FourteenHalf?.toFixed(2),
                fifteen: s.Fifteen?.toFixed(2),
                fifteenHalf: s.FifteenHalf?.toFixed(2),
                sixteen: s.Sixteen?.toFixed(2),
                sixteenHalf: s.SixteenHalf?.toFixed(2),
                seventeen: s.Seventeen?.toFixed(2),
                seventeenHalf: s.SeventeenHalf?.toFixed(2),
                eightteen: s.Eightteen?.toFixed(2),
                eightteenHalf: s.EightteenHalf?.toFixed(2),
                nineteen: s.Nineteen?.toFixed(2),
                nineteenHalf: s.NineteenHalf?.toFixed(2),
                twenty: s.Twenty?.toFixed(2),
                twentyHalf: s.TwentyHalf?.toFixed(2),
                small: s.Small?.toFixed(2),
                medium: s.Medium?.toFixed(2),
                large: s.Large?.toFixed(2),
                extraLarge: s.ExtraLarge?.toFixed(2),
                extraLarge2: s.ExtraLarge2?.toFixed(2),
                extraLarge3: s.ExtraLarge3?.toFixed(2),
            }).then(() => {
                setLoading(false);
            })
        })
    }
    const updateF3PCloud = () => {
        addFile();
        rate.forEach((s) => {
            db.collection(currentPlan === 'FWD' ? "f3p" : 'f3p-fwd2').doc(s.Country).set({
                // code: s.Code,
                country: s.Country,
                zone: s.Zone,
                doxHalf: s.DoxHalf?.toFixed(2),
                doxOne: s.DoxOne?.toFixed(2),
                doxOneHalf: s.DoxOnehalf?.toFixed(2),
                doxTwo: s.DoxTwo?.toFixed(2),
                half: s.Half?.toFixed(2),
                one: s.One?.toFixed(2),
                oneHalf: s.OneHalf?.toFixed(2),
                two: s.Two?.toFixed(2),
                twoHalf: s.TwoHalf?.toFixed(2),
                three: s.Three?.toFixed(2),
                threeHalf: s.ThreeHalf?.toFixed(2),
                four: s.Four?.toFixed(2),
                fourHalf: s.FourHalf?.toFixed(2),
                five: s.Five?.toFixed(2),
                fiveHalf: s.FiveHalf?.toFixed(2),
                six: s.Six?.toFixed(2),
                sixHalf: s.SixHalf?.toFixed(2),
                seven: s.Seven?.toFixed(2),
                sevenHalf: s.SevenHalf?.toFixed(2),
                eight: s.Eight?.toFixed(2),
                eightHalf: s.EightHalf?.toFixed(2),
                nine: s.Nine?.toFixed(2),
                nineHalf: s.NineHalf?.toFixed(2),
                ten: s.Ten?.toFixed(2),
                tenHalf: s.TenHalf?.toFixed(2),
                eleven: s.Eleven?.toFixed(2),
                elevenHalf: s.ElevenHalf?.toFixed(2),
                twelve: s.Twelve?.toFixed(2),
                twelveHalf: s.TwelveHalf?.toFixed(2),
                thirteen: s.Thirteen?.toFixed(2),
                thirteenHalf: s.ThirteenHalf?.toFixed(2),
                fourteen: s.Fourteen?.toFixed(2),
                fourteenHalf: s.FourteenHalf?.toFixed(2),
                fifteen: s.Fifteen?.toFixed(2),
                fifteenHalf: s.FifteenHalf?.toFixed(2),
                sixteen: s.Sixteen?.toFixed(2),
                sixteenHalf: s.SixteenHalf?.toFixed(2),
                seventeen: s.Seventeen?.toFixed(2),
                seventeenHalf: s.SeventeenHalf?.toFixed(2),
                eightteen: s.Eightteen?.toFixed(2),
                eightteenHalf: s.EightteenHalf?.toFixed(2),
                nineteen: s.Nineteen?.toFixed(2),
                nineteenHalf: s.NineteenHalf?.toFixed(2),
                twenty: s.Twenty?.toFixed(2),
                twentyHalf: s.TwentyHalf?.toFixed(2),
                small: s.Small?.toFixed(2),
                medium: s.Medium?.toFixed(2),
                large: s.Large?.toFixed(2),
                extraLarge: s.ExtraLarge?.toFixed(2),
                extraLarge2: s.ExtraLarge2?.toFixed(2),
                extraLarge3: s.ExtraLarge3?.toFixed(2),
            }).then(() => {
                setLoading(false);
            })
        })
    }
    const updateFedexCloud = () => {
        addFile();
        rate.forEach((s) => {
            db.collection(currentPlan === 'FWD' ? "fedex" : 'fedex-fwd2').doc(s.Country).set({
                // code: s.Code,
                country: s.Country,
                zone: s.Zone,
                doxHalf: s.DoxHalf?.toFixed(2),
                doxOne: s.DoxOne?.toFixed(2),
                doxOneHalf: s.DoxOnehalf?.toFixed(2),
                doxTwo: s.DoxTwo?.toFixed(2),
                half: s.Half?.toFixed(2),
                one: s.One?.toFixed(2),
                oneHalf: s.OneHalf?.toFixed(2),
                two: s.Two?.toFixed(2),
                twoHalf: s.TwoHalf?.toFixed(2),
                three: s.Three?.toFixed(2),
                threeHalf: s.ThreeHalf?.toFixed(2),
                four: s.Four?.toFixed(2),
                fourHalf: s.FourHalf?.toFixed(2),
                five: s.Five?.toFixed(2),
                fiveHalf: s.FiveHalf?.toFixed(2),
                six: s.Six?.toFixed(2),
                sixHalf: s.SixHalf?.toFixed(2),
                seven: s.Seven?.toFixed(2),
                sevenHalf: s.SevenHalf?.toFixed(2),
                eight: s.Eight?.toFixed(2),
                eightHalf: s.EightHalf?.toFixed(2),
                nine: s.Nine?.toFixed(2),
                nineHalf: s.NineHalf?.toFixed(2),
                ten: s.Ten?.toFixed(2),
                tenHalf: s.TenHalf?.toFixed(2),
                eleven: s.Eleven?.toFixed(2),
                elevenHalf: s.ElevenHalf?.toFixed(2),
                twelve: s.Twelve?.toFixed(2),
                twelveHalf: s.TwelveHalf?.toFixed(2),
                thirteen: s.Thirteen?.toFixed(2),
                thirteenHalf: s.ThirteenHalf?.toFixed(2),
                fourteen: s.Fourteen?.toFixed(2),
                fourteenHalf: s.FourteenHalf?.toFixed(2),
                fifteen: s.Fifteen?.toFixed(2),
                fifteenHalf: s.FifteenHalf?.toFixed(2),
                sixteen: s.Sixteen?.toFixed(2),
                sixteenHalf: s.SixteenHalf?.toFixed(2),
                seventeen: s.Seventeen?.toFixed(2),
                seventeenHalf: s.SeventeenHalf?.toFixed(2),
                eightteen: s.Eightteen?.toFixed(2),
                eightteenHalf: s.EightteenHalf?.toFixed(2),
                nineteen: s.Nineteen?.toFixed(2),
                nineteenHalf: s.NineteenHalf?.toFixed(2),
                twenty: s.Twenty?.toFixed(2),
                twentyHalf: s.TwentyHalf?.toFixed(2),
                small: s.Small?.toFixed(2),
                medium: s.Medium?.toFixed(2),
                large: s.Large?.toFixed(2),
                extraLarge: s.ExtraLarge?.toFixed(2),
                extraLarge2: s.ExtraLarge2?.toFixed(2),
                extraLarge3: s.ExtraLarge3?.toFixed(2),
            }).then(() => {
                setLoading(false);
            })
        })
    }

    const updateDusCloud = () => {
        addFile();
        rate.forEach((s) => {
            db.collection(currentPlan === 'FWD' ? "dus" : "dus-fwd2").doc(s.Country).set({
                country: s.Country,
                doxHalf: s.Half?.toFixed(2),
                doxOne: s.One?.toFixed(2),
                doxOneHalf: s.OneHalf?.toFixed(2),
                doxTwo: s.Two?.toFixed(2),
                half: s.Half?.toFixed(2),
                one: s.One?.toFixed(2),
                oneHalf: s.OneHalf?.toFixed(2),
                two: s.Two?.toFixed(2),
                twoHalf: s.TwoHalf?.toFixed(2),
                three: s.Three?.toFixed(2),
                threeHalf: s.ThreeHalf?.toFixed(2),
                four: s.Four?.toFixed(2),
                fourHalf: s.FourHalf?.toFixed(2),
                five: s.Five?.toFixed(2),
                fiveHalf: s.FiveHalf?.toFixed(2),
                six: s.Six?.toFixed(2),
                sixHalf: s.SixHalf?.toFixed(2),
                seven: s.Seven?.toFixed(2),
                sevenHalf: s.SevenHalf?.toFixed(2),
                eight: s.Eight?.toFixed(2),
                eightHalf: s.EightHalf?.toFixed(2),
                nine: s.Nine?.toFixed(2),
                nineHalf: s.NineHalf?.toFixed(2),
                ten: s.Ten?.toFixed(2),
                tenHalf: s.TenHalf?.toFixed(2),
                eleven: s.Eleven?.toFixed(2),
                elevenHalf: s.ElevenHalf?.toFixed(2),
                twelve: s.Twelve?.toFixed(2),
                twelveHalf: s.TwelveHalf?.toFixed(2),
                thirteen: s.Thirteen?.toFixed(2),
                thirteenHalf: s.ThirteenHalf?.toFixed(2),
                fourteen: s.Fourteen?.toFixed(2),
                fourteenHalf: s.FourteenHalf?.toFixed(2),
                fifteen: s.Fifteen?.toFixed(2),
                fifteenHalf: s.FifteenHalf?.toFixed(2),
                sixteen: s.Sixteen?.toFixed(2),
                sixteenHalf: s.SixteenHalf?.toFixed(2),
                seventeen: s.Seventeen?.toFixed(2),
                seventeenHalf: s.SeventeenHalf?.toFixed(2),
                eightteen: s.Eightteen?.toFixed(2),
                eightteenHalf: s.EightteenHalf?.toFixed(2),
                nineteen: s.Nineteen?.toFixed(2),
                nineteenHalf: s.NineteenHalf?.toFixed(2),
                twenty: s.Twenty?.toFixed(2),
                small: s.Small?.toFixed(2),
                medium: s.Medium?.toFixed(2),
                large: s.Large?.toFixed(2),
            }).then(() => {
                setLoading(false);
            })
        })
    }
    const addFile = () => {
        setLoading(true);
        axios.post("https://test.skydart.com.vn/api/file", {
            fileName: fileName,
            fileType: fileType,
            fileSize: niceBytes(fileSize).toString(),
        })
    }

    const merge = (e) => {
        e.preventDefault();
        if (type === "dhl") {
            updateDhlCloud();
        } else if (type === "fip") {
            updateFedexCloud();
        } else if (type === "dus") {
            updateDusCloud();
        } else if (type === 'fie') {
            updateFIECloud();
        } else if (type === 'f3p') {
            updateF3PCloud();
        }
    }
    const fuelValue = (+rateInput + +addressCorrectionInput + +remoteInput + +surchargeInput) * +fuel / 100;
    const vatValue = (+rateInput + +addressCorrectionInput + +remoteInput + +comesticInput + +surchargeInput + +fuel + +surchargeInput2) * +vatInput / 100;
    const submitRate = (e) => {
        e.preventDefault();
        setLoadingRate(true);
        exportSelected.forEach(s => {
            axios.put("https://test.skydart.com.vn/api/update/rate", {
                rate: rateInput || s.rate,
                fsc: fuel || s.fsc,
                fscValue: fuelValue,
                comestic: comesticInput || s.comestic,
                remote: remoteInput || s.remote,
                addressCorrection: addressCorrectionInput || s.addressCorrection,
                vat: vatInput || s.vat,
                vatValue: vatValue,
                surcharge: surchargeInput || s.surcharge,
                surcharge2: surchargeInput2 || s.surcharge2,
                surcharge3: surchargeInput3 || s.surcharge3,
                sellNote: sellNoteInput || s.sellNote,
                totalValue: "",
                rateUpdate: traceTime,
                com: comInput || s.com,
                hawb: s.hawb,
            }).then(() => {
                setLoadingRate(false);
                setRateInput("");
                setComesticInput("");
                setRemoteInput("");
                setAddressCorrectionInput("");
                setVatValueInput("");
                setSurchargeInput("");
                setSurchargeInput2("");
                setSurchargeInput3("");
                setSellNoteInput("");
                setVatInput("");
                setDisplayRating(false);
            }).then(() => {
                setAlertSuccess(true);
            })
        })
    }
    function resetSuccess(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertSuccess(false);
    }
    const refreshSurcharge = () => {
        db.collection("surcharge").doc("skd-surcharge").get().then((doc) => {
            if (doc.exists) {
                setFetchDhl(doc.data().dhl);
                setFetchFedex(doc.data().fedex);
                setFetchUps(doc.data().ups);
                setFetchDus(doc.data().dus);
                setLoading(false);
            }
        })
    }
    useEffect(() => {
        refreshSurcharge();
    }, [])
    const handleSurcharge = () => {
        setDisplaySurcharge(false);
        setLoading(true);
        db.collection("surcharge").doc("skd-surcharge").set({
            dhl: dhlFsc || fetchDhl,
            fedex: fedexFsc || fetchFedex,
            ups: upsFsc || fetchUps,
            dus: dusFsc || fetchDus,
            lastUpdate: userData.displayName,
        }).then(() => {
            setDhlFsc("");
            setFedexFsc("");
            setUpsFsc("");
            setDusFsc("");
            setAlertSuccess(true);
            setLoading(false);
            setRemote("");
            setAddressCorrect("");
            refreshSurcharge();
        })
    }
    const sortedCus1 = [...new Set(getWeight.map(s => s.sender))];
    const sortedCountry1 = [...new Set(getWeight.map(s => s.country))];
    const duplicateWeight = [...new Set(getWeight.map(s => s.chargeable))];
    const duplicateComestic = [...new Set(getWeight.map(s => s.comestic))];
    const duplicateReweight = [...new Set(getWeight.map(s => s.reweight))];
    const duplicateService = [...new Set(getWeight.map(s => s.service))];
    const duplicateRate = [...new Set(getWeight.map(s => s.rate))];
    const duplicateAddress = [...new Set(getWeight.map(s => s.addressCorrection))];
    const duplicateRemote = [...new Set(getWeight.map(s => s.remote))];
    const duplicateExtra1 = [...new Set(getWeight.map(s => s.surcharge))];
    const duplicateExtra2 = [...new Set(getWeight.map(s => s.surcharge2))];
    const duplicateExtra3 = [...new Set(getWeight.map(s => s.surcharge3))];
    const duplicateFsc = [...new Set(getWeight.map(s => s.fsc))];
    const duplicateFscValue = [...new Set(getWeight.map(s => s.fscValue))];
    const duplicateVat = [...new Set(getWeight.map(s => s.vat))];
    const duplicateVatValue = [...new Set(getWeight.map(s => s.vatValue))];
    const duplicateSale = [...new Set(getWeight.map(s => s.controller))];
    const duplicateClass = [...new Set(getWeight.map(s => s.plan))];
    const duplicateNote = [...new Set(getWeight.map(s => s.note))];
    const duplicateSellNote = [...new Set(getWeight.map(s => s.sellNote))];
    const sortedCustomer = sortedCus1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const sortedCountry = sortedCountry1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    let customerRef = useRef();
    let countryRef = useRef();
    const dhlHeader = [
        { label: "Country", key: "label" },
        { label: "Zone", key: "zone" },
        { label: "DoxHalf", key: "" },
        { label: "DoxOne", key: "" },
        { label: "DoxOnehalf", key: "" },
        { label: "Doxtwo", key: "" },
        { label: "Half", key: "" },
        { label: "One", key: "" },
        { label: "Two", key: "" },
        { label: "TwoHalf", key: "" },
        { label: "Three", key: "" },
        { label: "ThreeHalf", key: "" },
        { label: "Four", key: "" },
        { label: "FourHalf", key: "" },
        { label: "Five", key: "" },
        { label: "FiveHalf", key: "" },
        { label: "Six", key: "" },
        { label: "SixHalf", key: "" },
        { label: "Seven", key: "" },
        { label: "SevenHalf", key: "" },
        { label: "Eight", key: "" },
        { label: "EightHalf", key: "" },
        { label: "Nine", key: "" },
        { label: "NineHalf", key: "" },
        { label: "Ten", key: "" },
        { label: "TenHalf", key: "" },
        { label: "Eleven", key: "" },
        { label: "ElevenHalf", key: "" },
        { label: "Twelve", key: "" },
        { label: "TwelveHalf", key: "" },
        { label: "Thirteen", key: "" },
        { label: "ThirteenHalf", key: "" },
        { label: "Fourteen", key: "" },
        { label: "FourteenHalf", key: "" },
        { label: "Fifteen", key: "" },
        { label: "FifteenHalf", key: "" },
        { label: "Sixteen", key: "" },
        { label: "SixteenHalf", key: "" },
        { label: "Seventeen", key: "" },
        { label: "SeventeenHalf", key: "" },
        { label: "Eightteen", key: "" },
        { label: "EightteenHalf", key: "" },
        { label: "Nineteen", key: "" },
        { label: "NineteenHalf", key: "" },
        { label: "Twenty", key: "" },
        { label: "TwentyHalf", key: "" },
        { label: "Twentyone", key: "" },
        { label: "TwentyoneHalf", key: "" },
        { label: "Twentytwo", key: "" },
        { label: "TwentytwoHalf", key: "" },
        { label: "Twentythree", key: "" },
        { label: "TwentythreeHalf", key: "" },
        { label: "Twentyfour", key: "" },
        { label: "TwentyfourHalf", key: "" },
        { label: "Twentyfive", key: "" },
        { label: "TwentyfiveHalf", key: "" },
        { label: 'Twentysix', key: "" },
        { label: "TwentysixHalf", key: "" },
        { label: "Twentyseven", key: "" },
        { label: "TwentysevenHalf", key: "" },
        { label: "Twentyeight", key: "" },
        { label: "TwentyeightHalf", key: "" },
        { label: "Twentynine", key: "" },
        { label: "TwentynineHalf", key: "" },
        { label: "Thirty", key: "" },
        { label: "Small", key: "" },
        { label: "Medium", key: "" },
        { label: "Large", key: "" },
    ]
    const exportData = {
        data: countries,
        headers: dhlHeader,
        filename: "Test",
    }

    return (
        <div className="skydart-pricing">
            {/* <CSVLink {...exportData}>Test</CSVLink> */}
            <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
                <MuiAlert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
                    Update Succeed
                </MuiAlert>
            </Snackbar>
            {displayOverview && <div className="skydart-pricing-rating">
                <div className="skydart-pricing-overview-container">
                    <h3>VU Nguyen</h3>
                </div>
            </div>}
            {displaySurcharge && <div className="skydart-create-print">
                <div className="shipment-note-container" style={{ marginTop: "15%", width: "30%" }}>
                    <div className="skydart-create-surcharge">
                        <h3 style={{ fontWeight: 600, fontSize: 15 }}>Update Surcharge</h3>
                        <IconButton onClick={() => setDisplaySurcharge(false)} type="button" style={{ width: 35, height: 35, background: "rgb(230,230,230)" }}><Clear style={{ fontSize: 18 }} /></IconButton>
                    </div>
                    <div className="skydart-create-surcharge-container">
                        <div className="skydart-create-surcharge-container-header">
                            <div className="skydart-create-surcharge-option">
                                <h5>DHL FSC</h5>
                                <input type="number" value={dhlFsc} onChange={(e) => setDhlFsc(e.target.value)} placeholder="DHL fuel surcharge" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>Fedex FSC</h5>
                                <input type="number" value={fedexFsc} onChange={(e) => setFedexFsc(e.target.value)} placeholder="FEDEX fuel surcharge" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>UPS FSC</h5>
                                <input type="number" value={upsFsc} onChange={(e) => setUpsFsc(e.target.value)} placeholder="OTHER fuel surcharge" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>DUS FSC</h5>
                                <input type="number" value={dusFsc} onChange={(e) => setDusFsc(e.target.value)} placeholder="Address Correction" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <button type="button" onClick={handleSurcharge}>Update surcharge</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {displayRating && <div className="skydart-pricing-rating" style={{ display: "flex" }}>
                <div className="skydart-pricing-rating-container">
                    <div className="skydart-pricing-rating-header">
                        <h3 style={{ fontWeight: 600, color: "rgb(54, 59, 78)" }}>RATE SHIPMENT</h3>
                        <Clear style={{ cursor: "pointer" }} onClick={() => setDisplayRating(false)} />
                    </div>
                    <div className="skydart-pricing-rating-body">
                        <div className="skydart-pricing-rating-alert">
                            <p style={{ fontWeight: 600, fontSize: 12 }}>Amount shipment</p>
                            <p>{exportSelected.length} shipments</p>
                        </div>
                    </div>
                    {exportSelected.length === 0 && <p style={{ padding: 10 }}>Please select at least 1 shipment to start rating</p>}
                    {exportSelected.length !== 0 && <form onSubmit={submitRate}>
                        <div className="skydart-pricing-rating-input">
                            <p>RATE</p>
                            <input type="number" value={rateInput} onChange={(e) => setRateInput(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>ADD CORRECT</p>
                            <input type="number" value={addressCorrectionInput} onChange={(e) => setAddressCorrectionInput(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>REMOTE</p>
                            <input type="number" value={remoteInput} onChange={(e) => setRemoteInput(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>EXTRA 1</p>
                            <input type="number" value={surchargeInput} onChange={(e) => setSurchargeInput(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>FSC (%)</p>
                            <input type="number" value={fuel} onChange={(e) => setFuel(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>FSC ($)</p>
                            <input type="number" value={(Math.round(fuelValue * 100) / 100).toFixed(2)} disabled />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>EXTRA 2</p>
                            <input type="number" value={surchargeInput2} onChange={(e) => setSurchargeInput2(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>VAT (%)</p>
                            <select value={vatInput} onChange={(e) => setVatInput(e.target.value)} required>
                                <option value="">VAT</option>
                                <option value="0">0%</option>
                                <option value="8">8%</option>
                            </select>
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>VAT ($)</p>
                            <input type="text" value={(Math.round(vatValue * 100) / 100).toFixed(2)} disabled />
                        </div>
                        <div className="skydart-pricing-rating-input">
                            <p>EXTRA 3</p>
                            <input type="number" value={surchargeInput3} onChange={(e) => setSurchargeInput3(e.target.value)} />
                        </div>
                        <div className="skydart-pricing-rating-note">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Edit style={{ marginLeft: 10 }} />
                                <p style={{ marginLeft: 10 }}>Selling note:</p>
                            </div>
                            <textarea type="text" placeholder="Enter selling note here..." value={sellNoteInput} onChange={(e) => setSellNoteInput(e.target.value)} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <button type="submit" className="skydart-pricing-submit">
                                Submit
                            </button>
                        </div>
                    </form>}
                </div>
                <div className="skydart-pricing-inform">
                    {exportSelected.map((s) => (
                        <div style={{ padding: 10, borderLeft: "5px solid lightgray" }}>
                            <h5>{s.hawb}</h5>
                            <div style={{}}>
                                <small>Selling note:</small>
                                <p style={{ color: "black", fontWeight: 450 }}>{s.sellNote}</p>
                            </div>
                            <div>
                                <small>OPS note:</small>
                                <p style={{ color: "black", fontWeight: 450 }}>{s.note}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {displayAutoRate && <div className="skydart-pricing-autoRate">
                <div className="skydart-rate">
                    <div className="skydart-rate-container">
                        <div className="skd-rate-header">
                            <h3>Automaticly Shipping Rate</h3>
                            <form style={{ display: "flex", alignItems: "center" }} onSubmit={merge}>
                                {rate.length !== 0 && <select required value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value="">Select type</option>
                                    <option value="dhl">DHL</option>
                                    <option value="fip">FIP</option>
                                    <option value="dus">DUS</option>
                                    <option value="fie">FIE</option>
                                    <option value="f3p">F3P</option>
                                </select>}
                                {rate.length !== 0 && <select required value={currentPlan} onChange={(e) => setCurrentPlan(e.target.value)}>
                                    <option value="">Select plan</option>
                                    <option value="FWD">FWD</option>
                                    <option value="FWD2">FWD2</option>
                                </select>}
                                {rate.length !== 0 ? <button type="submit">Update</button> : <button type="button" style={{ background: "whitesmoke", color: "gray", boxShadow: "none", border: "1px solid lightgray" }} onClick={() => alert("Please upload a file first before you can update")}>Update</button>}
                                <input type="file" onChange={handleFile} id="file-input" />
                                <label for="file-input" className="upload-icon">
                                    <FileCopyOutlined className="upload-rate-icon" />
                                    <h5 className="rate-helper">Upload a file</h5>
                                </label>
                                <div className="upload-icon" style={{ marginLeft: 5 }} onClick={() => {
                                    setDisplayAutoRate(false);
                                }}>
                                    <Clear className="upload-rate-icon" />
                                    <h5 className="rate-helper">Close</h5>
                                </div>
                            </form>
                        </div>
                        <div className="skd-rate-body" style={{ borderRight: "15px solid white", marginTop: 5 }}>
                            <div className="skd-rate-body-header">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    {loading && <p style={{ fontWeight: 600, fontSize: 12, padding: 7, margin: 5 }}>Loading, Please wait...</p>}
                                </div>
                                {rate.length !== 0 && <div className="skd-rate-body-rate-just">
                                    <div className="skd-rate-option">
                                        <h5>File Name</h5>
                                    </div>
                                    <div className="skd-rate-option">
                                        <h5>File Type</h5>
                                    </div>
                                    <div className="skd-rate-option">
                                        <h5>File Size</h5>
                                    </div>
                                </div>}
                                {rate.length !== 0 && <div className="skd-rate-body-rate-just1">
                                    <div className="skd-rate-option" style={{ display: "flex", alignItems: "center" }}>
                                        <File size={18} />
                                        <h5 style={{ fontSize: 11, marginLeft: 5 }}>{fileName}</h5>
                                    </div>
                                    <div className="skd-rate-option">
                                        <h5 style={{ fontSize: 11 }}>{fileType}</h5>
                                    </div>
                                    <div className="skd-rate-option">
                                        <h5 style={{ fontSize: 11 }}>{niceBytes(fileSize)}</h5>
                                    </div>
                                </div>}

                            </div>
                        </div>


                    </div>
                </div>
            </div>}
            {loading && <div className="skydart-pricing-loading">
                <div className="skydart-pricing-loader" style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress style={{ color: "white" }} />
                    <p style={{ fontWeight: 600, fontSize: 20, color: "white", marginLeft: 10 }}>LOADING...</p>
                </div>
            </div>}
            <div className="skydart-pricing-header">
                <div className="skydart-pricing-primary">
                    <div className='skydart-pricing-header-left'>
                        <Search style={{ position: 'absolute', marginLeft: 5 }} />
                        <input type="text" placeholder="Search HAWB" value={searchHawb} onChange={(e) => setSearchHawb(e.target.value)} />
                    </div>
                    {/* <div className="skydart-pricing-header-currentUse" style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        <button type="button" style={{ padding: 1.1 }} onClick={refreshShipment}><Refresh /></button>
                    </div> */}
                    <div className="skydart-pricing-header-currentUse">
                        <button type="button">Overview</button>
                    </div>
                    <div className="skydart-pricing-header-currentUse">
                        <button type="button" onClick={() => setDisplayAutoRate(true)}>Auto Rated</button>
                    </div>
                    <div className="skydart-pricing-header-currentEdit">
                        <button type="button" onClick={() => setDisplayRating(true)}>Selling</button>
                        <Assessment className="skd-local-offer" onClick={() => { setDisplaySurcharge(true) }} />
                    </div>
                    <div className="skydart-pricing-header-rateApplied">
                        {exportSelected.length !== 0 && <p><strong style={{ background: "#006fca", color: "white", border: "1px solid transparent", borderRadius: 3, fontWeight: 600, padding: 6 }}>{exportSelected.length} selected</strong></p>}
                    </div>
                    <div style={{ display: "flex", marginLeft: 9 }}>
                        <p style={{ width: "100px" }}>DHL: <strong>{fetchDhl}%</strong></p>
                        <p style={{ width: "100px" }}>FIP: <strong>{fetchFedex}%</strong></p>
                        <p style={{ width: "100px" }}>SUP: <strong>{fetchUps}%</strong></p>
                        <p style={{ width: "100px" }}>DUS: <strong>{fetchDus}%</strong></p>
                    </div>
                    <div style={{ marginLeft: "auto" }} ref={dateRef}>
                        <div className="skd-user-shipment-header-right-date" style={{ padding: 10 }} onClick={() => { displayDateRange ? setDisplayDateRange(false) : setDisplayDateRange(true) }}>
                            <GoCalendar size={20} style={{ marginRight: 10 }} />
                            <p style={{ marginRight: 7, }}>{dateStatus}:</p>
                            <p style={{ marginRight: 7, }}>{startDay}</p>
                            <AiOutlineMinus size={10} style={{ marginRight: 7 }} />
                            <p style={{ marginRight: 7, }}>{endDay}</p>
                            <ArrowDropDown style={{ fontSize: 18 }} />
                        </div>
                        {displayDateRange && <div className="skydart-date-range-container" style={{ marginRight: 10 }}>
                            <div className="skydart-date-range-left" style={{ position: "relative" }}>
                                <DatepickerContext.Provider
                                    value={{
                                        focusedDate,
                                        isDateFocused,
                                        isDateSelected,
                                        isDateHovered,
                                        isDateBlocked,
                                        isFirstOrLastSelectedDate,
                                        onDateSelect,
                                        onDateFocus,
                                        onDateHover
                                    }}>
                                    <div className="skydart-date-range-header">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div className="skydart-date-range-input">
                                                <h5>Start Date</h5>
                                                <input type="text" disabled style={{ background: "white" }} value={!dateRange.startDate ? dateRange.startDate : dateRange.startDate.toDateString()} />
                                            </div>
                                            <AiOutlineMinus />
                                            <div className="skydart-date-range-input">
                                                <h5>End Date</h5>
                                                <input type="text" disabled style={{ background: "white" }} value={!dateRange.endDate ? dateRange.endDate : dateRange.endDate.toDateString()} />
                                            </div>
                                            {/* <div className="skydart-date-shortcut">
                                            <button type="button" onClick={handleToday}>Today</button>
                                            <button type="button" onClick={handleThisMonth}>This Month</button>
                                        </div> */}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "15px 0 0",
                                                gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                                                gridGap: "0 25px"
                                            }}
                                        >
                                            <div className="date-month-back" onClick={goToPreviousMonths}>
                                                <ArrowLeft />
                                            </div>
                                            <div className="date-month-back1" onClick={goToNextMonths}>
                                                <ArrowRight />
                                            </div>
                                            {activeMonths.map(month => (
                                                <Month
                                                    key={`${month.year}-${month.month}`}
                                                    year={month.year}
                                                    month={month.month}
                                                    firstDayOfWeek={firstDayOfWeek}
                                                />
                                            ))}

                                        </div>
                                        <div className="skydart-date-range-button">
                                            {dateRange.startDate && <div>
                                                <h5 style={{ fontWeight: 600, fontSize: 12 }}>{!dateRange.startDate ? dateRange.startDate : dateRange.startDate.toDateString()} - {!dateRange.endDate ? dateRange.endDate : dateRange.endDate.toDateString()}</h5>
                                                <h5 style={{ fontWeight: 480, fontSize: 11, color: "gray" }}>Ho Chi Minh time</h5>
                                            </div>}
                                            <button type="button" className="skydart-date-range-cancel" onClick={handleCancelDate}>Cancel</button>
                                            <button type="button" className="skydart-date-range-apply" onClick={handleApplyDate}>Apply</button>
                                        </div>
                                    </div>
                                </DatepickerContext.Provider>
                            </div>
                            <div className="skydart-date-range-right">
                                <h5 style={{ fontWeight: 700, padding: 10, fontSize: 12 }}>Date preset</h5>
                                <div className={dateStatus === "Today" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleToday}>
                                    {dateStatus === "Today" && <Check style={{ fontSize: 15 }} />}
                                    <p>Today</p>
                                </div>
                                <div className={dateStatus === "Yesterday" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleYesterday}>
                                    {dateStatus === "Yesterday" && <Check style={{ fontSize: 15 }} />}
                                    <p>Yesterday</p>
                                </div>
                                <div className={dateStatus === "This week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisWeek}>
                                    {dateStatus === "This week" && <Check style={{ fontSize: 15 }} />}
                                    <p>This week</p>
                                </div>
                                <div className={dateStatus === "Last week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastWeek}>
                                    {dateStatus === "Last week" && <Check style={{ fontSize: 15 }} />}
                                    <p>Last week</p>
                                </div>
                                <div className={dateStatus === "This month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisMonth}>
                                    {dateStatus === "This month" && <Check style={{ fontSize: 15 }} />}
                                    <p>This Month</p>
                                </div>
                                <div className={dateStatus === "Last month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastMonth}>
                                    {dateStatus === "Last month" && <Check style={{ fontSize: 15 }} />}
                                    <p>Last Month</p>
                                </div>
                                <div className={dateStatus === "This year" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisYear}>
                                    {dateStatus === "This year" && <Check style={{ fontSize: 15 }} />}
                                    <p>This Year</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: 5, paddingBottom: 7, borderBottom: "1px solid rgb(230,230,230)" }}>
                    {breakCountry && <div className="skydart-searched-result">
                        <h5>Breaking: Country</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setBreakCountry(false)} />
                    </div>}
                    {breakWeight && <div className="skydart-searched-result">
                        <h5>Breaking: Weight</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setBreakWeight(false)} />
                    </div>}
                    {breakService && <div className="skydart-searched-result">
                        <h5>Breaking: Service</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setBreakService(false)} />
                    </div>}
                    {breakRate && <div className="skydart-searched-result">
                        <h5>Breaking: Rate</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setBreakRate(false)} />
                    </div>}
                    {searchClass !== "" && <div className="skydart-searched-result">
                        <h5>Class is: {searchClass}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchClass("")} />
                    </div>}
                    {searchCus !== "" && <div className="skydart-searched-result">
                        <h5>Customer: {searchCus}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchCus("")} />
                    </div>}
                    {searchCountry !== "" && <div className="skydart-searched-result">
                        <h5>Ship To: {searchCountry}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchCountry("")} />
                    </div>}
                    {searchType !== "" && <div className="skydart-searched-result">
                        <h5>Type: {searchType}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchType("")} />
                    </div>}
                    {searchWeight !== "" && <div className="skydart-searched-result">
                        <h5>Weight is: {searchWeight}kg</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchWeight("")} />
                    </div>}
                    {searchReweight !== "" && <div className="skydart-searched-result">
                        <h5>Reweight is: {searchWeight}kg</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchReweight("")} />
                    </div>}
                    {/* {searchPcs !== "" && <div className="skydart-searched-result">
                    <h5>Pcs: {searchPcs}pcs</h5>
                    <Clear className="skydart-searched-clear" onClick={() => setSearchPcs("")} />
                </div>} */}
                    {/* {searchAirline !== "" && <div className="skydart-searched-result">
                    <h5>Airline is: {searchAirline}</h5>
                    <Clear className="skydart-searched-clear" onClick={() => setSearchAirline("")} />
                </div>} */}
                    {searchService !== "" && <div className="skydart-searched-result">
                        <h5>Service: {searchService}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchService("")} />
                    </div>}
                    {/* {searchAgent !== "" && <div className="skydart-searched-result">
                    <h5>Agent: {searchAgent}</h5>
                    <Clear className="skydart-searched-clear" onClick={() => setSearchAgent("")} />
                </div>} */}
                    {/* {searchMawb !== "" && <div className="skydart-searched-result">
                    <h5>MAWB: {searchMawb}</h5>
                    <Clear className="skydart-searched-clear" onClick={() => setSearchMawb("")} />
                </div>} */}
                    {/* {searchSin !== "" && <div className="skydart-searched-result">
                    <h5>SIN HAWB: {searchSin}</h5>
                    <Clear className="skydart-searched-clear" onClick={() => setSearchSin("")} />
                </div>} */}
                    {searchRate !== "" && <div className="skydart-searched-result">
                        <h5>Rate is: {searchRate}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchRate("")} />
                    </div>}
                    {searchComestic !== "" && <div className="skydart-searched-result">
                        <h5>Extra: {searchComestic}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchComestic("")} />
                    </div>}
                    {searchAddress !== "" && <div className="skydart-searched-result">
                        <h5>{searchAddress}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchAddress("")} />
                    </div>}
                    {searchRemote !== "" && <div className="skydart-searched-result">
                        <h5>{searchRemote}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchRemote("")} />
                    </div>}
                    {searchSurcharge !== "" && <div className="skydart-searched-result">
                        <h5>Extra 1: {searchSurcharge}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchSurcharge("")} />
                    </div>}
                    {searchFsc !== "" && <div className="skydart-searched-result">
                        <h5>{searchFsc}%</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchFsc("")} />
                    </div>}
                    {searchFscValue !== "" && <div className="skydart-searched-result">
                        <h5>{searchFscValue}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchFscValue("")} />
                    </div>}
                    {searchSurcharge2 !== "" && <div className="skydart-searched-result">
                        <h5>Extra 2: {searchSurcharge2}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchSurcharge2("")} />
                    </div>}
                    {searchVat !== "" && <div className="skydart-searched-result">
                        <h5>{searchVat}%</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchVat("")} />
                    </div>}
                    {searchVatValue !== "" && <div className="skydart-searched-result">
                        <h5>{searchVatValue}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchVatValue("")} />
                    </div>}
                    {searchSurcharge3 !== "" && <div className="skydart-searched-result">
                        <h5>Extra 3: {searchSurcharge3}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchSurcharge3("")} />
                    </div>}
                    {searchSale !== "" && <div className="skydart-searched-result">
                        <h5>Controller: {searchSale}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchSale("")} />
                    </div>}
                    {searchNote !== "" && <div className="skydart-searched-result">
                        <h5>OPS Note: {searchNote}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchNote("")} />
                    </div>}
                    {searchSellNote !== "" && <div className="skydart-searched-result">
                        <h5>Selling Note: {searchSellNote}</h5>
                        <Clear className="skydart-searched-clear" onClick={() => setSearchSellNote("")} />
                    </div>}
                    {/* {searchAccNote !== "" && <div className="skydart-searched-result">
                    <h5>Accountant Note: {searchAccNote}</h5>
                    <Clear className="skydart-searched-clear" onClick={() => setSearchAccNote("")} />
                </div>} */}
                </div>

            </div>
        </div>
    )
}
const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
        zone: "7",
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
        zone: "War",
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264', zone: "8" },
    { code: 'AL', label: 'Albania', phone: '355', zone: "7" },
    { code: 'AM', label: 'Armenia', phone: '374', zone: "8" },
    { code: 'AO', label: 'Angola', phone: '244', zone: "8" },
    { code: 'AQ', label: 'Antarctica', phone: '672', zone: "?" },
    { code: 'AR', label: 'Argentina', phone: '54', zone: "8" },
    { code: 'AS', label: 'American Samoa', phone: '1-684', zone: "8" },
    { code: 'AT', label: 'Austria', phone: '43', zone: "6" },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
        zone: "9",
    },
    { code: 'AW', label: 'Aruba', phone: '297', zone: "8" },
    { code: 'AX', label: 'Alland Islands', phone: '358', zone: "?" },
    { code: 'AZ', label: 'Azerbaijan', phone: '994', zone: "8" },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
        zone: "7",
    },
    { code: 'BB', label: 'Barbados', phone: '1-246', zone: "8" },
    { code: 'BD', label: 'Bangladesh', phone: '880', zone: "4" },
    { code: 'BE', label: 'Belgium', phone: '32', zone: "6" },
    { code: 'BF', label: 'Burkina Faso', phone: '226', zone: "8" },
    { code: 'BG', label: 'Bulgaria', phone: '359', zone: "6" },
    { code: 'BH', label: 'Bahrain', phone: '973', zone: '7' },
    { code: 'BI', label: 'Burundi', phone: '257', zone: "8" },
    { code: 'BJ', label: 'Benin', phone: '229', zone: "8" },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590', zone: "?" },
    { code: 'BM', label: 'Bermuda', phone: '1-441', zone: "?" },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];