import React, { useState, useEffect } from 'react'
import "./StaffSetting.css";
import { db, auth } from "../firebase";
export default function StaffSetting({ user }) {
    const [userData, setUserData] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    useEffect(() => {
        if (user) {
            db.collection("users").doc(user.email).get().then((doc) => {
                if (doc.exists) {
                    setUserData(doc.data());
                } else {
                    console.log();
                }
            })
        } else {
            setUserData([]);
        }
    }, [user])
    const onUpdateAccount = () => {
        if (password.length >= 6 && confirmPass === password) {
            auth.currentUser.updatePassword(password).then(() => {
                db.collection("users").doc(user.email).update({
                    password: password,
                })
            }).then(() => {
                alert("Your password has been updated");
            }).catch((err) => {
                alert(err.message);
            })
        } else {
            alert("Your password doesn't match with your old password or your password is too short");
        }
    }
    return (
        <div className="staff__setting">
            <div className="forgot-container" style={{marginTop: "0%", paddingBottom: 20, width: "30%"}}>
                <div className="staff__setting__header">
                    <div className="staff__setting__header__input">
                        <h5>Display Name</h5>
                        <input type='text' value={userData.displayName} disabled />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>Email Address</h5>
                        <input type='text' value={userData.email} disabled />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>Title / Role</h5>
                        <input type='text' value={userData.role} disabled />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>Password</h5>
                        <input value={userData.password} type="password" disabled />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>New Password</h5>
                        <input type='password' placeholder="Type in your password, at least 6 characters" style={{ background: "white" }} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="staff__setting__header__input">
                        <h5>Confirm</h5>
                        <input type='password' placeholder="Confirming your password" style={{ background: "white" }} value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                    </div>
                    <div className="staff__setting__header__input">
                        <button type="button" onClick={onUpdateAccount}>Update Account</button>
                    </div>
                    <div className="staff__setting__body__option">
                        <a href="http://skydart.com.vn/" rel="noreferrer" target="_blank">Skydart Express @ 2021</a>
                        <a href="/imported">Manifest</a>
                        <a href="/new-booking">New Booking</a>
                        <a href="/tracking">Upload Tracking</a>
                        <a href="/cusomters">Customers</a>
                        <a href="/shipments">Shipments</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
