import { ArrowDropDown, ArrowDropUp, ArrowForward, ArrowLeft, ArrowRight, CallReceived, Check, Clear, Description, DragIndicator, Email, Event, GetApp, List, MoreHoriz, MoreVertOutlined, People, Person, PersonOutlined, Phone, PlaylistAddCheck, Refresh, Search, Warning } from '@material-ui/icons';
import React, { useState, useEffect, useRef } from 'react'
import "./style.css";
import { db } from "../firebase";
import { useHistory } from "react-router-dom";
import { CircularProgress, IconButton, Snackbar, LinearProgress } from '@material-ui/core';

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { RiErrorWarningFill } from "react-icons/ri";
import Debit2 from './Debit2';
import { BsHourglassSplit } from "react-icons/bs";
import "@progress/kendo-theme-material/dist/all.css";
import MuiAlert from '@material-ui/lab/Alert';
import { MdCreditCard, MdCreditCardOff } from 'react-icons/md';
import { CSVLink } from "react-csv";
import { GoHome } from "react-icons/go";
import axios from "axios";
import { RiFolderInfoLine, RiBarChartLine, RiMoneyDollarCircleLine, RiCurrencyLine } from "react-icons/ri";
import { MdWrongLocation } from "react-icons/md";
import XLSX from "xlsx";
import { BiSliderAlt } from "react-icons/bi";
import { GoCalendar } from "react-icons/go";
import { AiOutlineMinus } from 'react-icons/ai';
import { MdOutlineAutoAwesomeMosaic, MdPayment, MdSummarize } from 'react-icons/md';
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import { Filter, Package } from 'react-feather';
import { FiRefreshCw } from 'react-icons/fi';
import DatepickerContext from './datepickerContext';
import Month1 from './Month1';
import { FcLeave, FcShipped, FcApproval } from "react-icons/fc";
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import ReactToExcel from "react-html-table-to-excel";
export default function Users({ userData, user }) {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? "0" : "");
    let last = firstDayOfCurrentWeek + 6;
    let firstday = firstDayOfCurrentWeek - 6;
    let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
    let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
    let yesterday = date - 1;
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    let lastMonth = month - 1;
    let year = newDate.getFullYear();
    let lastDay = new Date(year, lastMonth, 0);
    let firstDayOfYear = new Date(year, 0, 1);
    let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
    let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
    const monthName = months[newDate.getMonth()];
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? "0" : "") + firstDay.getDate();
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const [displayCustomer, setDisplayCustomer] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState("");
    const [showCustomer, setShowCustomer] = useState(false);
    const [customerName, setCustomerName] = useState("");
    const [exporterName, setExporterName] = useState("");
    const [controllerName, setControllerName] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [cityName, setCityName] = useState("");
    const [countryName, setCountryName] = useState("");
    const [phone, setPhone] = useState("");
    const [plan, setPlan] = useState("");
    const [postal, setPostal] = useState("");
    const [state, setState] = useState("");
    const [town, setTown] = useState("");
    const [email, setEmail] = useState("");
    const [makh, setMakh] = useState("");
    const [mst, setMst] = useState("");
    const [createAt, setCreateAt] = useState("");
    const [shipments, setShipments] = useState([]);
    const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
    const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
    const [paymentStatus, setPaymentStatus] = useState("Un Paid");
    const [selectedUser, setSelectedUser] = useState([]);
    const [staffInput, setStaffInput] = useState("");
    const [planInput, setPlanInput] = useState("");
    const [updateLoading, setUpdateLoading] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [filterController, setFilterController] = useState("");
    const [filter, setFilter] = useState("");
    const [displayController, setDisplayController] = useState(false);
    const [displayPlan, setDisplayPlan] = useState(false);
    const [displayDate, setDisplayDate] = useState(false);
    const [displayPaymentStatus, setDisplayPaymentStatus] = useState(false);
    const [err, setErr] = useState(false);
    const [currencyExchange, setCurrencyExchange] = useState(0);
    const [proxy, setProxy] = useState("");
    const [showList, setShowList] = useState(true);
    const [showInfo, setShowInfo] = useState(true);
    const [filteringCustomer, setFilteringCustomer] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [pageNumberLimit, setPageNumberLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(10);

    const [currentCustomerPage, setCurrentCustomerPage] = useState(1);
    const [customerPerPage, setCustomerPerPage] = useState(20);
    const [pageNumberLimitCustomer, setPageNumberLimitCustomer] = useState(5);
    const [minPageLimitCustomer, setMinPageLimitCustomer] = useState(0);
    const [maxPageLimitCustomer, setMaxPageLimitCustomer] = useState(5);
    const [sortCountry, setSortCountry] = useState(false);
    const [displayFilterCountry, setDisplayFilterCountry] = useState(false);
    const [displayFilterSin, setDisplayFilterSin] = useState(false);
    const [searchCountry, setSearchCountry] = useState("");
    const [searchSin, setSearchSin] = useState("");
    const [searchType, setSearchType] = useState("");
    const [displayFilterType, setDisplayFilterType] = useState(false);
    const [sortWeight, setSortWeight] = useState(false);
    const [sortReweight, setSortReweight] = useState(false);
    const [sortRate, setSortRate] = useState(false);
    const [displayExport, setDisplayExport] = useState(false);
    const [scrollTopLeft, setScrollTopLeft] = useState(0);
    const [scrollTopCenter, setScrollTopCenter] = useState(0);
    const [scrollTopRight, setScrollTopRight] = useState(0);
    const [scrollLeftCenter, setScrollLeftCenter] = useState(0);
    const [dateStatus, setDateStatus] = useState("Period");
    const [fileName, setFileName] = useState("");
    const [exportStatus, setExportStatus] = useState("Raw");
    const [includeInfo, setIncludeInfo] = useState(false);
    const [displayMoreRight, setDisplayMoreRight] = useState(false);
    const [sortDate, setSortDate] = useState(false);
    const [getExcel, setGetExcel] = useState([]);
    const [scheduling, setScheduling] = useState(false);
    const [scheduleDate, setScheduleDate] = useState("");
    const [getPdf, setGetPdf] = useState([]);
    const [showLocation, setShowLocation] = useState(true);
    const [showSummary, setShowSummary] = useState(true);
    const [searchController, setSearchController] = useState("");
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE
    });
    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths
    } = useDatepicker({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        focusedInput: dateRange.focusedInput,
        onDatesChange: handleDateChange
    });
    function handleDateChange(data) {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE });
        } else {
            setDateRange(data);
        }
    }
    const history = useHistory();


    useEffect(() => {
        db.collection("users").where("employee", "==", "no").orderBy("timestamp", "desc").get().then((snapshot) => {
            let data = snapshot.docs.map(doc => doc.data());
            setCustomers(data.map((s) => {
                return {
                    select: false,
                    exporter: s.exporter,
                    createAt: s.createAt,
                    controller: s.controller,
                    address: s.address,
                    address2: s.address2,
                    address3: s.address3,
                    city: s.city,
                    country: s.country,
                    displayName: s.displayName,
                    phone: s.phone,
                    plan: s.plan,
                    postal: s.postal,
                    state: s.state,
                    town: s.town,
                    email: s.email,
                    makh: s.makh,
                    mst: s.mst,
                }
            }))
            setLoading(false);
        })
        db.collection("users").where("employee", "==", "yes").orderBy("timestamp", "desc").get().then((snapshot) => {
            setStaffs(snapshot.docs.map(doc => doc.data()));
        })
    }, [])

    function removeAccents(str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }
    const refresh = () => {
        setFetchLoading(true);
        axios.get(`https://test.skydart.com.vn/api/shipmentttt/${startDate}/${endDate}/**/skd/shipment`).then((snapshot) => {
            let data = snapshot.data;
            setShipments(data.filter((s, i) => {
                return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            }).map((s, i) => {
                let fuelValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge) * +s.fsc / 100)
                let vatValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +fuelValue + +s.surcharge2 + +s.comestic) * +s.vat / 100);
                let total = +s.rate + +s.addressCorrection + +s.comestic + +s.remote + +s.surcharge + +fuelValue + +s.surcharge2 + +vatValue + +s.surcharge3;
                let removeSkd = s.hawb.substring(3);
                let otherCharge = +s.surcharge + +s.surcharge2 + +s.surcharge3;
                let fsc = (Math.round(fuelValue * 100) / 100).toFixed(2);
                let totalExtra = total;
                return {
                    select: false,
                    account: s.account || "",
                    currency: s.currency || "",
                    service: s.service || "SKD",
                    date: s.date || "",
                    hawb: s.hawb || "",
                    removeSkd: removeSkd,
                    mawb: s.mawb || "",
                    makh: s.makh || "",
                    fscExtra: fsc,
                    totalExtra: totalExtra,
                    company: s.company || "",
                    contact: s.contact || "",
                    type: s.type || "",
                    status: s.status || "",
                    term: s.term || "",
                    time: s.time || "",
                    address: s.address || "",
                    address2: s.address2 || "",
                    address3: s.address3 || "",
                    city: s.city || "",
                    otherCharge: otherCharge,
                    country: s.country || "",
                    phone: s.phone || "",
                    postal: s.postal || "",
                    state: s.state || "",
                    term: s.term,
                    town: s.town || "",
                    chargeable: s.chargeable || "",
                    amount: s.amount || "",
                    totalItem: s.totalItem || "",
                    transport: s.transport || "",
                    description: s.description || "",
                    controller: s.controller || "",
                    eamFormat: s.eamFormat || "",
                    value: s.value || "",
                    airline: s.airline || "",
                    reweight: s.reweight || "",
                    agent: s.agent || "",
                    etd: s.etd || "",
                    subStatus: s.subStatus || "",
                    sin: s.sin || "",
                    sender: removeAccents(s.sender) || "",
                    note: s.note || "",
                    perform: s.perform || "",
                    rate: s.rate || "",
                    fsc: s.fsc || "",
                    vat: s.vat || "",
                    remote: s.remote || "",
                    comestic: s.comestic || "",
                    addressCorrection: s.addressCorrection || "",
                    totalValue: total,
                    surcharge: s.surcharge || "",
                    surcharge2: s.surcharge2 || "",
                    surcharge3: s.surcharge3 || "",
                    fscValue: fuelValue || "",
                    vatValue: vatValue || "",
                    sellNote: s.sellNote || "",
                    paymentPickup: s.paymentPickup || "",
                    paymentAcc: s.paymentAcc || "",
                    paymentCompany: s.paymentCompany || "",
                    fromEmail: s.fromEmail || "",
                    fromCity: s.fromCity || "",
                    com: s.com || "",
                    rateUpdate: s.rateUpdate || "",
                    paymentStatus: s.paymentStatus || "",
                    paymentSubStatus: s.paymentSubStatus || "",
                    code: s.code || "",
                    eamService: s.eamService || "",
                    pack: s.pack || "",
                    accNote: s.accountNote,
                    plan: s.plan || "Pending",
                }
            }))
            setFetchLoading(false);
        })
    }
    useEffect(() => {
        refresh();
    }, [startDate, endDate])

    const getShipment = shipments.filter((s) => {
        return s.makh === makh &&
            s.paymentStatus === paymentStatus &&
            s.hawb.toLowerCase().includes(filter.toLowerCase()) &&
            s.country.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
            s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
            s.type.toLowerCase().includes(searchType.toLowerCase());
    })
    const debt = shipments.filter((s) => {
        return s.makh === makh && s.paymentStatus === "Un Paid";
    })
    const paid = shipments.filter((s) => {
        return s.makh === makh && s.paymentStatus === "Pay";
    })

    // const exportedShipment = shipments.sort((a, b) => {
    //     if (includeInfo) {
    //         a = a.date;
    //         b = b.date;
    //         return a.localeCompare(b);
    //     } else {
    //         a = a.date;
    //         b = b.date;
    //         return b.localeCompare(a);
    //     }
    // }).filter((s) => {
    //     return s.makh === makh && s.paymentStatus === paymentStatus;
    // })

    const sortCustomer = customers.sort((a, b) => {
        a = a.exporter || "";
        b = b.exporter || "";
        return a.localeCompare(b);
    })
    const findCustomer = sortCustomer.filter((s) => {
        return s.exporter.toLowerCase().includes(searchCustomer.toLowerCase()) &&
            s.controller.toLowerCase().includes(filterController.toLowerCase());
    })
    const customerPages = [];
    for (let i = 1; i <= Math.ceil(findCustomer.length / customerPerPage); i++) {
        customerPages.push(i);
    }
    const handleChangePage1 = (e) => {
        setCurrentCustomerPage(Number(e.target.id));
    }
    const handleNextPage1 = () => {
        setCurrentCustomerPage(currentCustomerPage + 1);
        if (currentCustomerPage + 1 > maxPageLimitCustomer) {
            setMaxPageLimitCustomer(maxPageLimitCustomer + pageNumberLimitCustomer);
            setMinPageLimitCustomer(minPageLimitCustomer + pageNumberLimitCustomer);
        }
    }
    const handlePrevPage1 = () => {
        setCurrentCustomerPage(currentCustomerPage - 1);
        if ((currentCustomerPage - 1) % pageNumberLimitCustomer == 0) {
            setMaxPageLimitCustomer(maxPageLimitCustomer - pageNumberLimitCustomer);
            setMinPageLimitCustomer(minPageLimitCustomer - pageNumberLimitCustomer);
        }
    }
    const handleMorePage1 = () => {
        setMaxPageLimitCustomer(maxPageLimitCustomer + pageNumberLimitCustomer);
        setMinPageLimitCustomer(minPageLimitCustomer + pageNumberLimitCustomer);
    }
    const handleLessPage1 = () => {
        setMaxPageLimitCustomer(maxPageLimitCustomer - pageNumberLimitCustomer);
        setMinPageLimitCustomer(minPageLimitCustomer - pageNumberLimitCustomer);
    }
    const renderPageNumbers1 = customerPages.map((number) => {
        if (number < maxPageLimitCustomer + 1 && number > minPageLimitCustomer) {
            return (
                <li key={number} style={{ borderRadius: 5, border: "1px solid rgb(230,230,230)", borderRadius: 5 }} id={number} onClick={handleChangePage1} className={currentCustomerPage == number ? "active" : null}>
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })
    const pages = [];
    for (let i = 1; i <= Math.ceil(getShipment.length / itemPerPage); i++) {
        pages.push(i);
    }
    const handleChangePage = (e) => {
        setCurrentPage(Number(e.target.id));
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    const handleMorePage = () => {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    const handleLessPage = () => {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageLimit + 1 && number > minPageLimit) {
            return (
                <li key={number} id={number} onClick={handleChangePage} className={currentPage == number ? "active" : null}>
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })
    const indexOfLastItem1 = currentCustomerPage * customerPerPage;
    const indexOfFirstItem1 = indexOfLastItem1 - customerPerPage;
    const currentCustomer = findCustomer.slice(indexOfFirstItem1, indexOfLastItem1);


    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = getShipment.slice(indexOfFirstItem, indexOfLastItem);
    /// un paid amount ///
    const totalAmount = getShipment.reduce((a, v) => a = +a + +v.totalValue, 0);
    const totalVND = totalAmount.toFixed(2) * currencyExchange;
    const totalRate = getShipment.reduce((a, v) => a = +a + +v.rate, 0);
    const totalWeight = getShipment.reduce((a, v) => a = +a + +v.chargeable, 0);
    const totalPieces = getShipment.reduce((a, v) => a = +a + +v.amount, 0);
    const totalReweight = getShipment.reduce((a, v) => a = +a + +v.reweight, 0);
    const totalPrice = getShipment.reduce((a, v) => a = +a + +v.totalValue, 0);
    const totalSurcharge3 = getShipment.reduce((a, v) => a = +a + +v.surcharge3, 0);
    const totalVatValue = getShipment.reduce((a, v) => a = +a + +v.vatValue, 0);
    const totalSurcharge2 = getShipment.reduce((a, v) => a = +a + +v.surcharge2, 0);
    const totalFscValue = getShipment.reduce((a, v) => a = +a + +v.fscValue, 0);
    const totalSurcharge1 = getShipment.reduce((a, v) => a = +a + +v.surcharge, 0);
    const totalComestic = getShipment.reduce((a, v) => a = +a + +v.comestic, 0);
    const totalRemote = getShipment.reduce((a, v) => a = +a + +v.remote, 0);
    const totalAddressCorrection = getShipment.reduce((a, v) => a = +a + +v.addressCorrection, 0);
    ///
    const totalUnpaid = debt.reduce((a, v) => a = +a + +v.totalValue, 0);
    const totalPaid = paid.reduce((a, v) => a = +a + +v.totalValue, 0)

    let pdfRef = useRef();
    const generatePDF = (e) => {
        pdfRef.current.save();
        setDisplayExport(false);
        setGetExcel([]);
        setGetPdf([]);
        setFileName("");
        setExportStatus("Raw");
    }
    const generatePdf = () => {
        let element = pdfRef.current || document.body;
        savePDF(element, {
            pageSize: "auto",
        })
    }

    const onScroll = () => {
        const scrollTop = scrollShipment.current.scrollTop;
        const scrollLeft = scrollShipment.current.scrollLeft;
        setScrollTopCenter(scrollTop);
        setScrollLeftCenter(scrollLeft);
    }
    const onScrollLeft = () => {
        const scrollTop = scrollShipment1.current.scrollTop;
        setScrollTopLeft(scrollTop);
    }
    const onScrollRight = () => {
        const scrollTop = scrollShipment2.current.scrollTop;
        setScrollTopRight(scrollTop);
    }




    const changeController = () => {
        setUpdateLoading(true);
        db.collection("users").doc(email).update({
            controller: staffInput,
        }).then(() => {
            if (getShipment.length === 0) {
                setTimeout(() => {
                    setErr(true);
                    setUpdateLoading(false);
                    setControllerName(staffInput);
                }, 1200)
                setTimeout(() => {
                    setErr(false);
                }, 4200)
            } else if (getShipment.length !== 0) {
                setErr(false);
                getShipment.map((s) => {
                    axios.put("https://test.skydart.com.vn/api/update/shipment/controller", {
                        hawb: s.hawb,
                        controller: staffInput,
                    }).then(() => {
                        setTimeout(() => {
                            setUpdateLoading(false);
                            setAlertSuccess(true);
                            setControllerName(staffInput);
                        }, 3000)
                    }).then(() => {
                        setStaffInput("");
                    })
                })
            }
        })
    }

    // const getPending = pendingShipment.filter((s) => {
    //     return s.makh === makh;
    // })
    const changePlan = () => {
        setUpdateLoading(true);
        db.collection("users").doc(email).update({
            plan: planInput,
        }).then(() => {
            if (getShipment.length === 0) {
                setTimeout(() => {
                    setErr(true);
                    setUpdateLoading(false);
                }, 1200)
                setTimeout(() => {
                    setErr(false);
                }, 4200)
            } else if (getShipment.length !== 0) {
                getShipment.map((s) => {
                    axios.put("https://test.skydart.com.vn/api/update/shipment/plan", {
                        hawb: s.hawb,
                        plan: planInput,
                    }).then(() => {
                        setTimeout(() => {
                            setUpdateLoading(false);
                            setAlertSuccess(true);
                            setPlan(planInput);
                        }, 3000)
                    }).then(() => {
                        setPlanInput("");
                    })
                })
            }
        })
    }
    const updateProxyController = () => {
        setUpdateLoading(true);
        db.collection("users").doc(email).update({
            proxyControllerDate: scheduleDate,
            proxyController: staffInput,
        }).then(() => {
            setTimeout(() => {
                setUpdateLoading(false);
                setAlertSuccess(true);
            }, 2000)
        })
    }
    const updateProxyClass = () => {
        setUpdateLoading(true);
        db.collection("users").doc(email).update({
            proxyPlanDate: scheduleDate,
            proxyPlan: planInput,
        }).then(() => {
            setTimeout(() => {
                setUpdateLoading(false);
                setAlertSuccess(true);
            }, 2000)
        })
    }
    const updateController = () => {
        setDisplayController(false);
        if (scheduling) {
            updateProxyController();
        } else if (!scheduling) {
            changeController();
        }
    }
    const updateClass = () => {
        setDisplayPlan(false);
        if (scheduling) {
            updateProxyClass();
        } else if (!scheduling) {
            changePlan();
        }
    }
    function resetSuccess(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertSuccess(false);
    }
    const headers = [
        { label: "CUSTOMER", key: "sender" },
        { label: "DATE", key: "date" },
        { label: "HAWB", key: "hawb" },
        { label: "SIN AWB", key: "sin" },
        { label: "DESTINATION", key: "country" },
        { label: "SERVICE", key: "service" },
        { label: "TYPE", key: "type" },
        { label: "WEIGHT", key: "chargeable" },
        { label: "REWEIGHT", key: "reweight" },
        { label: "RATE", key: "rate" },
        { label: "Comestic", key: "comestic" },
        { label: "Wrong Address", key: "addressCorrection" },
        { label: "Remote", key: "remote" },
        { label: "Extra 1", key: "surcharge" },
        { label: "FSC", key: "fscValue" },
        { label: "Extra 2", key: "surcharge2" },
        { label: "VAT", key: "vatValue" },
        { label: "Extra 3", key: "surcharge3" },
        { label: "TOTAL", key: "totalValue" },
        { label: "NOTE", key: "accNote" },
        { label: "NOTE 2", key: "sellNote" },
    ]
    const excelExport = getShipment.sort((a, b) => {
        if (includeInfo) {
            a = a.date || "";
            b = b.date || "";
            return a.localeCompare(b);
        }
    }).map((s) => {
        return {
            CUSTOMER: s.sender,
            DATE: new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
            HAWB: s.hawb,
            SIN: s.sin,
            DESTINATION: s.country,
            SERVICE: s.service,
            TYPE: s.type,
            WEIGHT: s.chargeable,
            REWEIGHT: s.reweight,
            RATE: +s.rate,
            COMESTIC: +s.comestic,
            WrongAddress: +s.addressCorrection,
            REMOTE: +s.remote,
            EXTRA1: +s.surcharge,
            FSC: +s.fscExtra,
            EXTRA2: +s.surcharge2,
            VAT: +s.vatValue,
            EXTRA3: +s.surcharge3,
            TOTAL: +s.totalExtra,
            NOTE: s.accNote,
            NOTE2: s.sellNote,
        }
    })
    let currencyRef = useRef();
    const excelFileName = fileName.replaceAll(' ', '-');

    const handleExportExcel = () => {
        setDisplayExport(false);
        setFileName("");
        setGetExcel([]);
        setGetPdf([]);
        setExportStatus("Raw");
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(excelExport);
        XLSX.utils.book_append_sheet(wb, ws, excelFileName || `report`);
        XLSX.writeFile(wb, `${excelFileName}.csv` || `report.csv`);
    }

    const handleCancelDate = () => {
        setDisplayDate(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const d = new Date(dateRange.startDate);
    const selectMonth = ((d.getMonth() + 1) < 10 ? "0" : "") + (d.getMonth() + 1);
    const selectDate = (d.getDate() < 10 ? "0" : "") + d.getDate();
    const selectYear = d.getFullYear();


    const e = new Date(dateRange.endDate);
    const selectEndMonth = ((e.getMonth() + 1) < 10 ? "0" : "") + (e.getMonth() + 1);
    const selectEndDate = (e.getDate() < 10 ? "0" : "") + e.getDate();
    const selectEndYear = e.getFullYear();

    function getLastDayOfYear(year) {
        return new Date(year, 11, 32);
    }
    const currentYear = new Date().getFullYear();
    const handleApplyDate = () => {
        if (!dateRange.endDate) {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setDisplayDate(false);
            setDateStatus("Period");
        } else {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
            setDisplayDate(false);
            setDateStatus("Period");
        }

    }
    const handleToday = () => {
        setStartDate(`${year}-${month}-${date}`)
        setEndDate(`${year}-${month}-${date}`)
        setDisplayDate(false);
        setDateStatus("Today");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleYesterday = () => {
        if (yesterday === 0) {
            setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setDisplayDate(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        } else {
            setStartDate(`${year}-${month}-${yesterday}`);
            setEndDate(`${year}-${month}-${yesterday}`);
            setDisplayDate(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        }
    }
    const handleThisWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
        setDisplayDate(false);
        setDateStatus("This week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
        setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setDisplayDate(false);
        setDateStatus("Last week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }

    const handleThisMonth = () => {
        setStartDate(`${year}-${month}-${first}`)
        setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split("T")[0]);
        setDisplayDate(false);
        setDateStatus("This month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastMonth = () => {
        setStartDate(`${year}-${prevMonth}-01`)
        setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
        setDisplayDate(false);
        setDateStatus("Last month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleThisYear = () => {
        setStartDate(`${year}-01-01`);
        setEndDate(`${year}-12-31`);
        setDateStatus("This year");
        setDisplayDate(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    let dateRef = useRef();
    let filterControlRef = useRef();
    let controllerRef = useRef();
    let classRef = useRef();
    let paymentStatusRef = useRef();
    let filterCountryRef = useRef();
    let filterSinRef = useRef();
    let filterTypeRef = useRef();
    let scrollShipment = useRef();
    let scrollShipment1 = useRef();
    let scrollShipment2 = useRef();
    let expandRightRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!dateRef.current.contains(e.target)) {
                setDisplayDate(false);
            }
            if (!filterControlRef.current.contains(e.target)) {
                setFilteringCustomer(false);
            }
            if (!controllerRef.current.contains(e.target)) {
                setDisplayController(false);
                setStaffInput("");
                setScheduling(false);
            }
            if (!classRef.current.contains(e.target)) {
                setDisplayPlan(false);
                setPlanInput("");
            }
            if (!paymentStatusRef.current.contains(e.target)) {
                setDisplayPaymentStatus(false);
            }
            if (!filterCountryRef.current.contains(e.target)) {
                setDisplayFilterCountry(false);
            }
            if (!filterSinRef.current.contains(e.target)) {
                setDisplayFilterSin(false);
            }
            if (!filterTypeRef.current.contains(e.target)) {
                setDisplayFilterType(false);
            }
            if (!expandRightRef.current.contains(e.target)) {
                setDisplayMoreRight(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    const convertThoudsand = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
    }
    const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const debitExport = {
        data: getShipment,
        headers: headers,
        filename: excelFileName || `${exporterName}'s report ${startDay} to ${endDay}`,
    }
    const generateKeywords = (displayName) => {
        const name = displayName.split('').filter((word) => word);
        const length = name.length;
        let flagArray = [];
        let result = [];
        let stringArray = [];
        for (let i = 0; i < length; i++) {
            flagArray[i] = false;
        }
        const createKeywords = (name) => {
            const arrName = [];
            let curName = '';
            name.split('').forEach((letter) => {
                curName += letter;
                arrName.push(curName);
            })
            return arrName;
        };
        function findPermutation(k) {
            for (let i = 0; i < length; i++) {
                if (!flagArray[i]) {
                    flagArray[i] = true;
                    result[k] = name[i];

                    if (k === length - 1) {
                        stringArray.push(result.join(''));
                    }
                    findPermutation(k + 1);
                    flagArray[i] = false;
                }
            }
        }
        findPermutation(0);

        const keywords = stringArray.reduce((acc, cur) => {
            const words = createKeywords(cur);
            return [...acc, ...words];
        }, [])
        return keywords;
    }
    const getKeyword = () => {
        customers.map((s) => {
            const name = s.displayName.split('').filter((word) => word);
            const length = name.length;
            let flagArray = [];
            let result = [];
            let stringArray = [];
            for (let i = 0; i < length; i++) {
                flagArray[i] = false;
            }
            const createKeywords = (name) => {
                const arrName = [];
                let curName = '';
                name.split('').forEach((letter) => {
                    curName += letter;
                    arrName.push(curName);
                })
                return arrName;
            };
            function findPermutation(k) {
                for (let i = 0; i < length; i++) {
                    if (!flagArray[i]) {
                        flagArray[i] = true;
                        result[k] = name[i];

                        if (k === length - 1) {
                            stringArray.push(result.join(''));
                        }
                        findPermutation(k + 1);
                        flagArray[i] = false;
                    }
                }
            }
            findPermutation(0);

            const keywords = stringArray.reduce((acc, cur) => {
                const words = createKeywords(cur);
                return [...acc, ...words];
            }, []);
            console.log(keywords);
            return keywords;
            // db.collection("users").doc(s.email).update({
            //     keywords: keywords,
            // })

        })
    }
    return (
        <div className="skydart-user">
            {updateLoading && <div className="skydart-user-update-loading">
                <div className="skydart-user-update-loading-left">
                    <BsHourglassSplit size={22} />
                </div>
                <div className="skydart-user-update-loading-right">
                    <LinearProgress style={{ height: 6.5, borderRadius: 5 }} />
                    <p style={{ marginTop: 7, fontSize: 13, marginLeft: 1 }}>Updating...</p>
                </div>
            </div>}
            {err && <div className="skydart-user-update-err">
                <div className="skydart-user-update-err-left">
                    <RiErrorWarningFill size={22} />
                </div>
                <div className="skydart-user-update-err-right">
                    <h5 style={{ fontWeight: 600, marginLeft: 1 }}>Warning</h5>
                    <p style={{ fontSize: 13, marginLeft: 1 }}>No shipment appear while updating</p>
                </div>
            </div>}
            <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
                <MuiAlert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
                    Update Succeed
                </MuiAlert>
            </Snackbar>
            {displayCustomer && <div className="skydart-user-customer" ref={filteringCustomer ? null : filterControlRef}>
                {showList && <div className="skydart-user-customer-left">
                    <div className="skydart-user-customer-left-title">
                        <h3 style={{ marginLeft: 3 }}>Customer list</h3>
                        <div className="skydart-user-more-filter1" onClick={() => { showCustomer ? setShowList(false) : setShowList(true) }}>
                            <Clear fontSize="small" />
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: 5, marginRight: 5 }}>
                        <div className="skydart-user-customer-search">
                            <Search className="skydart-customer-search-icon" htmlColor="rgb(205,205,205)" />
                            <input type="text" value={searchCustomer} onChange={(e) => { setSearchCustomer(e.target.value); setCurrentCustomerPage(1) }} placeholder="Search by name" className="search-customer" />
                            <div ref={filteringCustomer ? filterControlRef : null}>
                                {filterController !== "" && <div className="filter-applied" style={{ marginTop: -10, marginLeft: 30, zIndex: 9999 }}>
                                    <Filter size={11} />
                                </div>}
                                <div className="skydart-user-more-filter" onClick={() => { filteringCustomer ? setFilteringCustomer(false) : setFilteringCustomer(true) }}>
                                    <MoreHoriz fontSize="small" />
                                </div>
                                {filteringCustomer && <div className="user-more-filter-controller">
                                    <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                                        <h3 style={{ padding: 5, fontSize: 12, fontWeight: 600, color: "gray" }}>Filtering by controller</h3>
                                        <Clear className="user-more-filter-clear" onClick={() => { setCurrentCustomerPage(1); setFilterController(""); setFilteringCustomer(false) }} />
                                    </div>
                                    {staffs.map((s) => (
                                        <>
                                            <div className={s.displayName === filterController ? "user-more-filter-controller-option1" : "user-more-filter-controller-option"} onClick={() => {
                                                setFilterController(s.displayName);
                                                setFilteringCustomer(false);
                                                setCurrentCustomerPage(1);
                                            }}>
                                                <h5>{s.displayName}</h5>
                                            </div>

                                        </>
                                    ))}
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* <div className="skd-users">
                        <div className="skydart-user-customer-search">
                            <div className="skydart-user-selection">
                                <div style={{ display: "flex", alignItems: "center", marginLeft: 12 }}>
                                    <People fontSize="small" />
                                    <h3 style={{ fontSize: 11, fontWeight: 600, marginLeft: 5, textAlign: "center" }}>Showing {customers.length}</h3>
                                </div>
                                {!displayCustomer && <div className="skydart-user-selection-option" style={{ marginLeft: "auto" }}>
                                    <h5>Customers</h5>
                                </div>}
                                {displayCustomer && <div className="skydart-user-selection-option1" style={{ marginLeft: "auto" }}>
                                    <h5>Customers</h5>
                                </div>}
                                {!displayCustomer && <div className="skydart-user-selection-option1">
                                    <h5>Staffs</h5>
                                </div>}
                                {displayCustomer && <div className="skydart-user-selection-option">
                                    <h5>Staffs</h5>
                                </div>}

                            </div>

                        </div>
                    </div> */}

                    <div className="skydart-user-render" ref={scrollShipment1} onScroll={onScrollLeft}>
                        {!loading && searchCustomer !== "" && currentCustomer.map((s, index) => (
                            <div className={s.select ? "selected-user" : "skydart-user-container"}>
                                <div className="skydart-bill-checkbox-header" style={{ borderRight: "none" }} >
                                    <input type="checkbox" id="selectAll" checked={s.select} />
                                </div>
                                <div className="skydart-user-option" onClick={() => {
                                    setFetchLoading(true);
                                    setShowCustomer(false);
                                    setExporterName(s.exporter);
                                    setCustomerName(s.displayName);
                                    setControllerName(s.controller);
                                    setAddress(s.address);
                                    setAddress2(s.address2);
                                    setAddress3(s.address3);
                                    setCityName(s.city);
                                    setCountryName(s.country);
                                    setPhone(s.phone);
                                    setPlan(s.plan);
                                    setPostal(s.postal);
                                    setState(s.state);
                                    setTown(s.town);
                                    setEmail(s.email);
                                    setMakh(s.makh);
                                    setMst(s.mst);
                                    setCreateAt(s.createAt);
                                    setSelectedUser(customers.map(sd => {
                                        if (sd.exporter === s.exporter) {
                                            sd.select = true;
                                        } else {
                                            sd.select = false;
                                        }
                                        return sd;
                                    }))
                                    setTimeout(() => {
                                        setFetchLoading(false);
                                        setShowCustomer(true);
                                    }, 800)
                                }}>
                                    <h5 style={{ fontSize: 12, fontWeight: 600 }}>{s.exporter}</h5>
                                </div>
                            </div>
                        ))}
                        {loading && <div style={{ display: "flex", padding: 100, alignItems: "center", justifyContent: "center" }}><CircularProgress size={30} style={{ color: "#006fca" }} /></div>}
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="paginate">
                            <div className="pageNumbers1">
                                <IconButton type="button" disabled={currentCustomerPage == customerPages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage1}><ArrowLeft className="prev-btn-page" /></IconButton>
                                {renderPageNumbers1}
                                <IconButton type="button" disabled={currentCustomerPage == customerPages[customerPages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage1}><ArrowRight className="prev-btn-page" /></IconButton>
                            </div>
                        </div>
                    </div> */}
                </div>}
                <div ref={displayMoreRight ? null : expandRightRef}></div>
                <div className="skd-user-shipment" ref={displayDate ? null : dateRef}>
                    {showCustomer && !fetchLoading && <div className="skd-user-shipment-header">
                        <div className="skd-user-shipment-header-left">
                            <Search />
                            <input type="text" placeholder="Search and filter shipment..." value={filter} onChange={(e) => { setFilter(e.target.value); setCurrentPage(1) }} />
                        </div>
                        <div className="skd-user-shipment-center" style={{ marginLeft: "auto" }}>
                            <p style={{ fontSize: 12 }}>{convertThoudsand(getShipment.length)} shipments are {paymentStatus}</p>
                        </div>
                        <div className="skd-user-shipment-header-right" style={{ marginLeft: 7 }} ref={displayDate ? dateRef : null}>
                            <div className="skd-user-shipment-header-right-date" onClick={() => { displayDate ? setDisplayDate(false) : setDisplayDate(true) }}>
                                <GoCalendar size={20} style={{ marginRight: 10 }} />
                                <p style={{ marginRight: 7 }}>{dateStatus}:</p>
                                <p style={{ marginRight: 7 }}>{startDay}</p>
                                <AiOutlineMinus size={10} style={{ marginRight: 7 }} />
                                <p style={{ marginRight: 7 }}>{endDay}</p>
                                <ArrowDropDown style={{ fontSize: 18 }} />
                            </div>
                            {displayDate && <div className="skd-user-date">
                                <DatepickerContext.Provider
                                    value={{
                                        focusedDate,
                                        isDateFocused,
                                        isDateSelected,
                                        isDateHovered,
                                        isDateBlocked,
                                        isFirstOrLastSelectedDate,
                                        onDateSelect,
                                        onDateFocus,
                                        onDateHover
                                    }}
                                >
                                    <div className="skd-user-date-content">
                                        <div className="skd-user-date-left">
                                            <div className={dateStatus === "Today" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleToday}>
                                                {dateStatus === "Today" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>Today</p>
                                            </div>
                                            <div className={dateStatus === "Yesterday" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleYesterday}>
                                                {dateStatus === "Yesterday" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>Yesterday</p>
                                            </div>
                                            <div className={dateStatus === "This week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisWeek}>
                                                {dateStatus === "This week" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>This week</p>
                                            </div>
                                            <div className={dateStatus === "Last week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastWeek}>
                                                {dateStatus === "Last week" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>Last week</p>
                                            </div>
                                            <div className={dateStatus === "This month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisMonth}>
                                                {dateStatus === "This month" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>This Month</p>
                                            </div>
                                            <div className={dateStatus === "Last month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastMonth}>
                                                {dateStatus === "Last month" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>Last Month</p>
                                            </div>
                                            <div className={dateStatus === "This year" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisYear}>
                                                {dateStatus === "This year" && <Check style={{ fontSize: 15, color: "black" }} />}
                                                <p style={{ color: "black" }}>This Year</p>
                                            </div>
                                        </div>
                                        <div className="skd-user-date-right">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    margin: "15px 0 0",
                                                    gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                                                    gridGap: "0 24px"
                                                }}
                                            >
                                                <div className="date-month-back" onClick={goToPreviousMonths}>
                                                    <ArrowLeft />
                                                </div>
                                                <div className="date-month-back1" onClick={goToNextMonths}>
                                                    <ArrowRight />
                                                </div>
                                                {activeMonths.map(month => (
                                                    <Month1
                                                        key={`${month.year}-${month.month}`}
                                                        year={month.year}
                                                        month={month.month}
                                                        firstDayOfWeek={firstDayOfWeek}
                                                    />
                                                ))}

                                            </div>
                                            <div className="skd-user-date-shown">
                                                <div className="skd-user-date-shown-range">
                                                    <h5 style={{ fontWeight: 450 }}>{!dateRange.startDate ? "Start Date" : dateRange.startDate.toDateString()}</h5>
                                                    <AiOutlineMinus />
                                                    <h5 style={{ fontWeight: 450, marginLeft: 7 }}>{!dateRange.endDate ? "End Date" : dateRange.endDate.toDateString()}</h5>
                                                    <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                                                </div>
                                                <div className="skd-user-date-update-button">
                                                    <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                                                        <h5>Cancel</h5>
                                                    </div>
                                                    <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                                                        <h5>Update</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DatepickerContext.Provider>
                            </div>}
                        </div>
                    </div>}
                    <div ref={displayPaymentStatus ? null : paymentStatusRef}></div>
                    <div ref={displayPlan ? null : classRef}></div>
                    <div className={showCustomer ? "skd-user-shipment-container" : "skd-user-shipment-container1"} ref={displayController ? null : controllerRef}>
                        {showCustomer && !fetchLoading && <div className="skd-user-shipment-action">
                            <div className="skd-user-shipment-action-left" onClick={() => { showList ? setShowList(false) : setShowList(true) }}>
                                <List />
                                <p className="skd-user-help">Collapse/Expand List</p>
                            </div>
                            <div ref={displayController ? controllerRef : null}>
                                <div className="skd-user-shipment-action-left" onClick={() => { displayController ? setDisplayController(false) : setDisplayController(true) }}>
                                    <h5>Controller: {controllerName}</h5>
                                    <ArrowDropDown />
                                    <p className="skd-user-help">Update Controller</p>
                                </div>
                                {displayController && <div className="skd-user-shipment-action-option-container">
                                    <div className="skd-user-shipment-action-option-wrap">
                                        <input type="text" style={{ margin: 10, padding: 5, border: "1px solid lightgray", outline: "none", width: "85%" }} placeholder="Search for controller" value={searchController} onChange={(e) => setSearchController(e.target.value)} />
                                        {staffs.filter((s) => {
                                            if (s.displayName.toLowerCase().includes(searchController.toLowerCase())) {
                                                return s;
                                            }
                                        }).map((s) => (
                                            <>
                                                <div className={s.displayName === staffInput ? "skd-user-shipment-action-option-body1" : "skd-user-shipment-action-option-body"} onClick={() => setStaffInput(s.displayName)}>
                                                    <h5>{s.displayName}</h5>
                                                </div>

                                            </>
                                        ))}
                                    </div>
                                    <div className="skd-user-shipment-last">
                                        <div className="skd-user-shipment-last-header">
                                            <input type="checkbox" checked={scheduling ? true : false} onChange={e => {
                                                if (e.target.checked) {
                                                    setScheduling(true);
                                                } else {
                                                    setScheduling(false);
                                                }
                                            }} />
                                            <p>Select the checkbox for scheduling update time.</p>
                                        </div>
                                        {scheduling && <div style={{ display: "flex", alignItems: "center" }}>
                                            <input type="date" className="scheduling-date" value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} />
                                            <p style={{ fontSize: 11, marginLeft: 10 }}>Ho Chi Minh time</p>
                                        </div>}
                                        <div className="skd-user-shipment-last-button">
                                            <div className="skd-user-shipment-last-controller-cancel" onClick={() => {
                                                setDisplayController(false);
                                                setStaffInput("");
                                                setScheduling(false);
                                            }}>
                                                <h5>Cancel</h5>
                                            </div>
                                            {userData.role === "Admin" && <div className={staffInput !== "" ? "skd-user-shipment-last-controller-apply" : "skd-user-shipment-last-controller-apply1"} onClick={() => {
                                                if (staffInput !== "") {
                                                    updateController();
                                                } else {
                                                    return;
                                                }
                                            }}>
                                                <h5>Update</h5>
                                            </div>}
                                            {userData.role === "CEO" && <div className={staffInput !== "" ? "skd-user-shipment-last-controller-apply" : "skd-user-shipment-last-controller-apply1"} onClick={() => {
                                                if (staffInput !== "") {
                                                    updateController();
                                                } else {
                                                    return;
                                                }
                                            }}>
                                                <h5>Update</h5>
                                            </div>}
                                            {userData.role !== "Admin" && userData.role !== "CEO" && <div className="skd-user-shipment-last-controller-apply1">
                                                <h5>Update</h5>
                                            </div>}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div ref={displayPlan ? classRef : null}>
                                <div className="skd-user-shipment-action-left" onClick={() => { displayPlan ? setDisplayPlan(false) : setDisplayPlan(true) }}>
                                    <h5>Class: {plan}</h5>
                                    <ArrowDropDown />
                                    <p className="skd-user-help">Update Class</p>
                                </div>
                                {displayPlan && <div className="skd-user-shipment-action-option-container">
                                    <div className="skd-user-shipment-action-option-wrap" style={{ height: "fit-content" }}>
                                        {customerClass.map((s) => (
                                            <>
                                                <div className={s.name === planInput ? "skd-user-shipment-action-option-body1" : "skd-user-shipment-action-option-body"} onClick={() => setPlanInput(s.name)}>
                                                    <h5>{s.name}</h5>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <div className="skd-user-shipment-last">
                                        <div className="skd-user-shipment-last-header">
                                            <input type="checkbox" checked={scheduling ? true : false} onChange={e => {
                                                if (e.target.checked) {
                                                    setScheduling(true);
                                                } else {
                                                    setScheduling(false);
                                                }
                                            }} />
                                            <p>Select the checkbox for scheduling update time.</p>
                                        </div>
                                        {scheduling && <div style={{ display: "flex", alignItems: "center" }}>
                                            <input type="date" className="scheduling-date" value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} />
                                            <p style={{ fontSize: 11, marginLeft: 10 }}>Ho Chi Minh time</p>
                                        </div>}
                                        <div className="skd-user-shipment-last-button">
                                            <div className="skd-user-shipment-last-controller-cancel" onClick={() => {
                                                setDisplayPlan(false);
                                                setPlanInput("");
                                            }}>
                                                <h5>Cancel</h5>
                                            </div>
                                            {userData.role === "Admin" && <div className={planInput !== "" ? "skd-user-shipment-last-controller-apply" : "skd-user-shipment-last-controller-apply1"} onClick={() => {
                                                if (planInput !== "") {
                                                    updateClass();
                                                } else {
                                                    return;
                                                }
                                            }}>
                                                <h5>Update</h5>
                                            </div>}
                                            {userData.role === "CEO" && <div className={planInput !== "" ? "skd-user-shipment-last-controller-apply" : "skd-user-shipment-last-controller-apply1"} onClick={() => {
                                                if (planInput !== "") {
                                                    updateClass();
                                                } else {
                                                    return;
                                                }
                                            }}>
                                                <h5>Update</h5>
                                            </div>}
                                            {userData.role !== "Admin" && userData.role !== "CEO" && <div className="skd-user-shipment-last-controller-apply1">
                                                <h5>Update</h5>
                                            </div>}
                                        </div>
                                    </div>
                                </div>}
                            </div>

                            <div style={{ marginLeft: "auto" }} ref={displayPaymentStatus ? paymentStatusRef : null}>
                                <div className={displayPaymentStatus ? "skd-user-shipment-filter-payment1" : "skd-user-shipment-filter-payment"} onClick={() => displayPaymentStatus ? setDisplayPaymentStatus(false) : setDisplayPaymentStatus(true)}>
                                    <MdPayment size={23} />
                                    <h5>Payment Status</h5>
                                    <ArrowDropDown />
                                </div>
                                {displayPaymentStatus && <div className="skd-user-shipment-filter-payment-container">
                                    <div className={paymentStatus === "Un Paid" ? "skd-user-shipment-filter-payment-option1" : "skd-user-shipment-filter-payment-option"} onClick={() => { paymentStatus === "Un Paid" ? console.log() : setPaymentStatus("Un Paid"); setDisplayPaymentStatus(false) }}>
                                        <input type="radio" id="unpaid" name="radio-group" checked={paymentStatus === "Un Paid" ? true : false} />
                                        <label style={{ display: "flex", alignItems: "center" }} for="unpaid">
                                            <MdCreditCardOff size={20} />
                                            <h5 style={{ fontSize: 13, marginLeft: 5, fontWeight: 500 }}>Unpaid Shipments</h5>
                                        </label>
                                    </div>
                                    <div className={paymentStatus === "Pay" ? "skd-user-shipment-filter-payment-option1" : "skd-user-shipment-filter-payment-option"} onClick={() => { paymentStatus === "Pay" ? console.log() : setPaymentStatus("Pay"); setDisplayPaymentStatus(false) }}>
                                        <input type="radio" id="paid" name="radio-group" checked={paymentStatus === "Pay" ? true : false} />
                                        <label style={{ display: "flex", alignItems: "center" }} for="paid">
                                            <MdCreditCard size={20} />
                                            <h5 style={{ fontSize: 13, marginLeft: 5, fontWeight: 500 }}>Paid Shipments</h5>
                                        </label>
                                    </div>
                                </div>}
                            </div>
                            <div className="skd-user-shipment-action-left" onClick={() => { showInfo ? setShowInfo(false) : setShowInfo(true) }}>
                                <MdOutlineAutoAwesomeMosaic size={23} />
                                <h5>Customer Info</h5>
                            </div>
                        </div>}
                        <div ref={displayFilterType ? null : filterTypeRef}></div>
                        <div ref={displayFilterSin ? null : filterSinRef}></div>
                        <div ref={displayFilterCountry ? null : filterCountryRef}></div>
                        <div className={showCustomer ? "skd-user-shipment-fetch" : "skd-user-shipment-fetch1"} ref={scrollShipment} onScroll={onScroll}>
                            {fetchLoading && <div className="skd-user-unselect">
                                <CircularProgress size={40} style={{ color: "#006fca" }} />
                                <p style={{ fontSize: 15, fontWeight: 600, marginTop: 10 }}>Loading customer info</p>
                            </div>}
                            {!showCustomer && !fetchLoading && <div className="skd-user-unselect">
                                <GoHome size={50} />
                                <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>No data found</h5>
                                <p style={{ color: "gray", fontSize: 13 }}>Please select one customer to start reviewing</p>
                            </div>}
                            {showCustomer && !fetchLoading && <div className="skd-user-shipment-fetch-container">

                                <div className={scrollTopCenter !== 0 ? "skd-user-shipment-fetch-header1" : "skd-user-shipment-fetch-header"}>
                                    <div className={scrollLeftCenter !== 0 ? "skd-user-shipment-fetch-sticky-header" : "skd-user-shipment-fetch-sticky-header3"}>
                                        <div className="skd-user-shipment-fetch-hawb" style={{ paddingTop: 12, paddingBottom: 12 }}>
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5>SKD HAWB</h5>
                                        </div>
                                        <div ref={displayFilterCountry ? filterCountryRef : null}>
                                            <div className="skd-user-shipment-fetch-country" style={{ paddingTop: 11, paddingBottom: 11 }}>

                                                <DragIndicator fontSize="small" htmlColor={"rgb(202,202,202)"} />
                                                <h5>Ship To</h5>
                                                {!sortCountry ? <ArrowDropDown onClick={() => { displayFilterCountry ? setDisplayFilterCountry(false) : setDisplayFilterCountry(true) }} fontSize="small" className="dropdown-fetch-user" /> : <ArrowDropUp onClick={() => setSortCountry(false)} fontSize="small" className="dropdown-fetch-user" />}
                                            </div>
                                            {displayFilterCountry && <div className="skd-user-shipment-fetch-country-action">
                                                <input type="text" placeholder="Filter countries" value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)} />
                                                {!sortCountry && <div className="skd-user-shipment-fetch-country-option" onClick={() => { setSortCountry(true); setDisplayFilterCountry(false) }}>
                                                    <BsArrowDown size={18} />
                                                    <h5>Sort A to Z</h5>
                                                </div>}
                                                {sortCountry && <div className="skd-user-shipment-fetch-country-option" onClick={() => { setSortCountry(false); setDisplayFilterCountry(false) }}>
                                                    <BsArrowUp size={18} />
                                                    <h5>Remove Sort</h5>
                                                </div>}
                                            </div>}
                                        </div>
                                        <div className="skd-user-shipment-fetch-date" style={{ paddingTop: 11, paddingBottom: 11 }}>
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5>Date</h5>
                                            {!sortDate ? <ArrowDropDown onClick={() => { setSortDate(true) }} fontSize="small" className="dropdown-fetch-user" /> : <ArrowDropUp onClick={() => setSortDate(false)} fontSize="small" className="dropdown-fetch-user" />}
                                        </div>
                                    </div>
                                    <div className="skd-user-shipment-fetch-rest-header">
                                        <div ref={displayFilterSin ? filterSinRef : null}>
                                            <div className="skd-user-shipment-fetch-date" style={{ width: "120px", paddingBottom: 11, paddingTop: 11 }}>
                                                <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                                <h5 style={{ color: "black" }}>INTL AWB</h5>
                                                {!displayFilterSin ? <ArrowDropDown fontSize="small" className="dropdown-fetch-user" onClick={() => setDisplayFilterSin(true)} /> : <ArrowDropUp fontSize="small" className="dropdown-fetch-user" onClick={() => setDisplayFilterSin(false)} />}
                                            </div>
                                            {displayFilterSin && <div style={{ marginLeft: 110 }} className="skd-user-shipment-fetch-country-action">
                                                <input type="text" placeholder="Filter AWB" value={searchSin} onChange={(e) => setSearchSin(e.target.value)} />
                                            </div>}
                                        </div>
                                        <div ref={displayFilterType ? filterTypeRef : null}>
                                            <div className="skd-user-shipment-fetch-type" style={{ paddingBottom: 11, paddingTop: 11 }}>
                                                <DragIndicator fontSize="small" htmlColor={searchType === "" ? "rgb(202,202,202)" : "black"} />
                                                <h5>Type</h5>
                                                {!displayFilterType ? <ArrowDropDown fontSize="small" className="dropdown-fetch-user" onClick={() => setDisplayFilterType(true)} /> : <ArrowDropUp fontSize="small" className="dropdown-fetch-user" onClick={() => setDisplayFilterType(false)} />}
                                            </div>
                                            {displayFilterType &&
                                                <div className="skd-user-shipment-fetch-country-action" style={{ marginLeft: 90, width: "100px" }}>
                                                    <div className="skd-user-shipment-fetch-country-option" onClick={() => { setSearchType(""); setDisplayFilterType(false) }}>
                                                        <h5>All</h5>
                                                    </div>
                                                    <div className="skd-user-shipment-fetch-country-option" onClick={() => { setSearchType("WPX"); setDisplayFilterType(false) }}>
                                                        <h5>WPX</h5>
                                                    </div>
                                                    <div className="skd-user-shipment-fetch-country-option" onClick={() => { setSearchType("DOX"); setDisplayFilterType(false) }}>
                                                        <h5>DOX</h5>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5>Weight (KG)</h5>
                                            {!sortWeight && <ArrowDropDown fontSize="small" className="dropdown-fetch-user" onClick={() => setSortWeight(true)} />}
                                            {sortWeight && <ArrowDropUp fontSize="small" className="dropdown-fetch-user" onClick={() => setSortWeight(false)} />}
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5>Reweight (KG)</h5>
                                            {!sortReweight && <ArrowDropDown fontSize="small" className="dropdown-fetch-user" onClick={() => setSortReweight(true)} />}
                                            {sortReweight && <ArrowDropUp fontSize="small" className="dropdown-fetch-user" onClick={() => setSortReweight(false)} />}
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ color: "#0064D9" }}>Rate (USD)</h5>
                                            {!sortRate && <ArrowDropDown fontSize="small" className="dropdown-fetch-user" onClick={() => setSortRate(true)} />}
                                            {sortRate && <ArrowDropUp fontSize="small" className="dropdown-fetch-user" onClick={() => setSortRate(false)} />}
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Extra (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Add Correct (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Remote (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Extra 1 (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>FSC (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Extra 2 (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>VAT (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Extra 3 (USD)</h5>
                                        </div>
                                        <div className="skd-user-shipment-fetch-weight">
                                            <DragIndicator fontSize="small" htmlColor="rgb(202,202,202)" />
                                            <h5 style={{ marginLeft: "auto" }}>Total (USD)</h5>
                                        </div>
                                    </div>
                                </div>
                                {getShipment.length === 0 && !fetchLoading && <div className="skd-user-shipment-err">
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                        {paymentStatus === "Un Paid" && <FcApproval size={60} />}
                                        {paymentStatus === "Pay" && <BiSliderAlt size={60} style={{ color: "dimgray" }} />}
                                        {paymentStatus === "Un Paid" && <h5 style={{ fontWeight: 600, marginTop: 2, fontSize: 15 }}>Customer has already paid</h5>}
                                        {paymentStatus === "Pay" && <h5 style={{ fontWeight: 600, marginTop: 2, fontSize: 15 }}>Customer has not paid during this selected date range</h5>}
                                        <p style={{ marginTop: 2, fontSize: 14 }}>No data found</p>
                                        <div className="skd-user-no-data-range" onClick={() => setDisplayDate(true)}>
                                            <h5>Change Date</h5>
                                        </div>
                                    </div>
                                </div>}
                                {getShipment.length !== 0 && currentShipment.sort((s, i) => {
                                    if (sortDate) {
                                        s = s.date || "";
                                        i = i.date || "";
                                        return s.localeCompare(i);
                                    }
                                }).sort((s, i) => {
                                    if (sortCountry) {
                                        s = s.country || "";
                                        i = i.country || "";
                                        return s.localeCompare(i);
                                    }
                                }).sort((s, i) => {
                                    if (sortRate) {
                                        s = s.rate || "";
                                        i = i.rate || "";
                                        return s.localeCompare(i);
                                    }
                                }).sort((s, i) => {
                                    if (sortWeight) {
                                        s = s.chargeable || "";
                                        i = i.chargeable || "";
                                        return s.localeCompare(i);
                                    }
                                }).sort((s, i) => {
                                    if (sortReweight) {
                                        s = s.reweight || "";
                                        i = i.reweight || "";
                                        return s.localeCompare(i);
                                    }
                                }).map((s, index) => (
                                    <div className="skd-user-shipment-fetch-container-body">
                                        <div className={scrollLeftCenter !== 0 ? "skd-user-shipment-fetch-sticky-body" : "skd-user-shipment-fetch-sticky-body3"}>
                                            <div className="skd-user-shipment-fetch-hawb">
                                                <Description fontSize="small" className="dropdown-fetch-user1" />
                                                <h5 style={{ color: "black" }}>{s.hawb}</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-country" style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                <h5>{s.country.length > 13 ? s.country.slice(0, 13) + "..." : s.country}</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-date">
                                                <Event fontSize="small" className="dropdown-fetch-user1" />
                                                <h5 style={{ color: "black" }}>{new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h5>
                                            </div>
                                        </div>
                                        <div className="skd-user-shipment-fetch-rest-header">

                                            <div className="skd-user-shipment-fetch-date" style={{ width: "120px", paddingTop: 12, paddingBottom: 12 }}>
                                                <h5 style={{ color: "black" }}>{s.sin}</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-type">
                                                {s.type === "WPX" && <Package size={20} />}
                                                {s.type === "DOX" && <MdSummarize size={20} />}
                                                <h5>{s.type}</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{(Math.round(s.chargeable * 100) / 100).toFixed(2)} KG</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{(Math.round(s.reweight * 100) / 100).toFixed(2)} KG</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.rate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.comestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.addressCorrection.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.remote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.surcharge.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.fscValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.surcharge2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.vatValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.surcharge3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                            <div className="skd-user-shipment-fetch-weight">
                                                <h5 style={{ marginLeft: "auto" }}>{s.totalValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    {getShipment.length > 20 && !fetchLoading && <div className="skd-user-paginate">
                        <div className="skd-user-paginate-container">
                            <h5 style={{ fontWeight: 600, marginLeft: 5, fontSize: 13 }}><strong style={{ fontWeight: 600, color: "dimgray", textDecoration: "underline" }}>{exporterName}'s</strong> shipments</h5>
                            <div className="paginate" style={{ marginLeft: "auto" }}>
                                <div className="pageNumbers">
                                    <IconButton type="button" disabled={currentPage == pages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage}><ArrowLeft className="prev-btn-page" /></IconButton>
                                    {renderPageNumbers}
                                    <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage}><ArrowRight className="prev-btn-page" /></IconButton>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {/* <div ref={displayMoreRight ? null : expandRightRef}></div> */}
                {showCustomer && !fetchLoading && showInfo && <div className="skd-user-info">
                    <div className={scrollTopRight !== 0 ? "skd-user-info-header1" : "skd-user-info-header"}>
                        <div>
                            <div className="skd-user-info-header-left" onClick={() => { displayExport ? setDisplayExport(false) : setDisplayExport(true) }}>
                                <h5>Export</h5>
                                <GetApp />
                            </div>
                            {displayExport && <div className="skd-user-info-export">
                                <div style={{ position: "absolute", left: -9999, width: "70%", zIndex: 9999 }}>
                                    <PDFExport ref={pdfRef} paperSize="auto" margin={40} fileName={fileName || `${exporterName}'s report ${startDay} to ${endDay}`}>
                                        <Debit2 exporter={exporterName} address={address} currency={currencyExchange} startDate={startDate} endDate={endDate} address2={address2} address3={address3} mst={mst} displayName={customerName} email={email} phone={phone} makh={makh} getCustomer={getPdf} />
                                    </PDFExport>
                                </div>
                                <table className="skd-user-info-export-excel-table" id="export-table" style={{ background: "#f0f3f4", width: 500 }}>

                                    <thead className="skd-user-info-export-excel-table-info">
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img src="https://i.imgur.com/L6PnhXq.png" alt="" height="90" />
                                            <td style={{ fontSize: 25, textAlign: "center", fontWeight: 450, color: "rgb(55,55,55)", marginBottom: 5, marginLeft: 10 }}></td>
                                            <td style={{ fontSize: 24, textAlign: "center", fontWeight: 450, color: "rgb(55,55,55)", marginBottom: 5 }}>PAYMENT NOTIFICATION</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <td style={{ fontSize: 25, textAlign: "center", fontWeight: 450, color: "rgb(55,55,55)", marginLeft: 10 }}></td>
                                            <td style={{ fontSize: 24, textAlign: "center", fontWeight: 450, color: "darkblue" }}>Skydart Express</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <td style={{ fontSize: 25, textAlign: "center", fontWeight: 450, color: "rgb(55,55,55)", marginBottom: 5, marginLeft: 10 }}></td>
                                            <td style={{ fontSize: 16, textAlign: "center", fontWeight: 450, color: "rgb(55,55,55)", marginBottom: 5, }}>International Air Express Services</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}>from</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}>to</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 16, textAlign: "right", marginTop: 10 }}>VND Account Number: 112 0000 13407</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 18, marginLeft: 10, marginTop: 20 }}>CÔNG TY TNHH QUỲNH VŨ</td>
                                            <td style={{ fontSize: 18, marginLeft: 10, marginTop: 20 }}>{exporterName}</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 16, textAlign: "right", marginTop: 10 }}>USD Account Number: 114 0001 98472</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, marginTop: 20 }}>32 Lam Sơn</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginTop: 20, marginLeft: 10 }}>{address}</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 16, textAlign: "right", marginLeft: 10, marginTop: 10 }}>Swift Code: ICBVVNVX902</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, marginTop: 20 }}>Phường 2</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginTop: 20, marginLeft: 10 }}>{state}</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, marginTop: 20 }}>Quận Tân Bình</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginTop: 20, marginLeft: 10 }}>{town}</td>


                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Thành phố Hồ Chí Minh, 70500</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>{cityName}, {postal}</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 16, textAlign: "center", marginTop: 10 }}>Ngân Hàng thương mại cổ phần công thương Việt Nam - CN 1 - TPHCM</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Contact: Skydart Express</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Contact: {customerName}</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 16, textAlign: "center", marginTop: 10 }}>VIETINBANK</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Phone: +84 28 3848 7976</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Phone: {phone}</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 15, textAlign: "center", marginTop: 10 }}>Địa chỉ 93 - 95 Hàm Nghi Quận 1 - TP Hồ Chí Minh</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>TAX ID: 0303369205</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>TAX ID: {mst}</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Export time/ {monthName}</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>MAKH: {makh}</td>
                                            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
                                            <td style={{ fontSize: 16, width: 330, fontWeight: 700, textAlign: "center", marginTop: 10 }}>Date of issue: {startDay} - {endDay}</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white" }}>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white" }}>
                                            <td style={{ fontSize: 16, fontWeight: 450, marginLeft: 10 }}>Controller:</td>
                                            <td style={{ fontSize: 16, fontWeight: 450, marginLeft: 10 }}>{controllerName}</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white" }}>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ background: "white" }}>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "white" }}>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>DATE</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>SKD HAWB</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40, width: 190 }}>INTL AWB</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>Ship To</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>SERVICE</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40, width: 100 }}>TYPE</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>WEIGHT (KG)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>REWEIGHT (KG)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>RATE (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>EXTRA (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>ADD (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>REMOTE (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>EXTRA 1 (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>FSC (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>EXTRA 2 (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>VAT (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40 }}>EXTRA 3 (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40, borderRight: "1px solid" }}>TOTAL VALUE (USD)</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40, borderRight: "1px solid" }}>Selling Note</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", borderTop: "1px solid", borderRight: "1px solid", background: "#2887E6", color: "white", height: 40, borderRight: "1px solid" }}>Accountant Note</td>
                                        </tr>

                                        {getExcel.sort((a, b) => {
                                            if (includeInfo) {
                                                a = a.date || "";
                                                b = b.date || "";
                                                return a.localeCompare(b);
                                            }
                                        }).map((s, index) => (
                                            <tr className="skd-user-info-export-excel-table-info-container" key={index} style={{ display: "flex", background: "white" }}>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid", color: "darkblue", height: 35 }}>{s.hawb}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{+s.sin}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{s.country}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{s.service}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{s.type}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{(Math.round(s.chargeable * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "center", border: "1px solid" }}>{(Math.round(s.reweight * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.rate * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.comestic * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.addressCorrection * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.remote * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.surcharge * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.fscValue * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.surcharge2 * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.vatValue * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.surcharge3 * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{(Math.round(s.totalValue * 100) / 100).toFixed(2)}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{s.sellNote}</td>
                                                <td style={{ fontSize: 17, fontWeight: 450, marginLeft: 10, background: "white", textAlign: "right", border: "1px solid" }}>{s.accNote}</td>
                                            </tr>
                                        ))}
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "white" }}>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35, width: 150 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35, width: 100 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)} Kg</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "center", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalReweight * 100) / 100).toFixed(2)} KG</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalRate * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalComestic * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalAddressCorrection * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalRemote * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalSurcharge1 * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalFscValue * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalSurcharge2 * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalVatValue * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalSurcharge3 * 100) / 100).toFixed(2)}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, borderBottom: '1px solid', textAlign: "right", borderRight: "1px solid", background: "#f0f3f4", height: 35 }}>{(Math.round(totalAmount * 100) / 100).toFixed(2)}</td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "white" }}>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 150 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 100 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "white" }}>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 150 }}></td>
                                            <td style={{ fontSize: 13, fontWeight: 450, marginLeft: 10 }}>Contact us if you see any wrong calculation</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 100 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "#f0f3f4" }}>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 150 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", background: "#63BE09" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 100 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "#f0f3f4" }}>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, textAlign: "right" }}>Total Value In USD</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, textAlign: "right" }}>Currency Exchange</td>
                                            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, textAlign: "right", background: "#63BE09", color: "white" }}>Total Value in VND</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 100 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                        </tr>
                                        <tr className="skd-user-info-export-excel-table-info-container" style={{ display: "flex", background: "#f0f3f4" }}>
                                            <td style={{ fontSize: 25, fontWeight: 450, marginLeft: 10, textAlign: "right" }}>${totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td style={{ fontSize: 25, fontWeight: 600, marginLeft: 10, textAlign: "center" }}>X</td>
                                            <td style={{ fontSize: 25, fontWeight: 450, marginLeft: 10, textAlign: "right" }}>{currencyExchange}</td>
                                            <td style={{ fontSize: 26, fontWeight: 450, marginLeft: 10, textAlign: "right", background: "#63BE09", color: "white" }}>₫{totalVND.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center", width: 100 }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: "center" }}></td>
                                        </tr>
                                    </tbody>

                                </table>

                                <div className="skd-user-info-export-container">
                                    <div className="skd-user-info-export-header">
                                        <h3>Export report</h3>
                                        <Clear className="skd-user-info-export-clear" onClick={() => setDisplayExport(false)} />
                                    </div>
                                    <div className="skd-user-info-export-name">
                                        <h5>Export file name:</h5>
                                        <input type="text" placeholder={`${exporterName}'s report ${startDay} to ${endDay} `} value={fileName} onChange={(e) => setFileName(e.target.value)} />
                                        {fileName !== "" && <Clear fontSize="small" className="clear-export-name-skd-user" onClick={() => setFileName("")} />}
                                        <p style={{ fontSize: 12, marginTop: 5 }}>File name should not has any space</p>
                                    </div>
                                    <div className="skd-user-info-export-select">
                                        <h5>Export as:</h5>
                                        <div className="skd-user-info-export-select-option" onClick={() => { setExportStatus("Raw"); setGetExcel([]); setGetPdf([]) }}>
                                            <input type="radio" id="raw" name="export-group" checked={exportStatus === "Raw" ? true : false} />
                                            <label style={{ fontSize: 13, display: "flex", alignItems: "center" }} for="raw">
                                                Raw data table (.xlsx)
                                            </label>
                                        </div>
                                        <div className="skd-user-info-export-select-option" onClick={() => { setExportStatus("Debit Note"); setGetExcel(getShipment); setGetPdf([]) }}>
                                            <input type="radio" id="debit-note" name="export-group" checked={exportStatus === "Debit Note" ? true : false} />
                                            <label style={{ fontSize: 13, display: "flex", alignItems: "center" }} for="debit-note">
                                                Debit note (.xlsx)
                                            </label>
                                        </div>

                                        <div className="skd-user-info-export-select-option" onClick={() => { setExportStatus("CSV"); setGetExcel([]); setGetPdf([]); }}>
                                            <input type="radio" id="csv" name="export-group" checked={exportStatus === "CSV" ? true : false} />
                                            <label style={{ fontSize: 13, display: "flex", alignItems: "center" }} for="csv">
                                                CSV (.csv)
                                            </label>
                                        </div>
                                        <div className="skd-user-info-export-select-option" onClick={() => { setExportStatus("PDF"); setGetPdf(getShipment); setGetExcel([]) }}>
                                            <input type="radio" id="pdf" name="export-group" checked={exportStatus === "PDF" ? true : false} />
                                            <label style={{ fontSize: 13, display: "flex", alignItems: "center" }} for="pdf">
                                                PDF (.pdf)
                                            </label>
                                        </div>
                                    </div>
                                    <div className="skd-user-info-export-select">
                                        <h5>Export Option:</h5>
                                        <div className="skd-user-shipment-last-header" style={{ padding: 10, cursor: "pointer" }} onClick={() => { includeInfo ? setIncludeInfo(false) : setIncludeInfo(true) }}>
                                            <input type="checkbox" style={{ border: "1px solid lightgray" }} checked={includeInfo ? true : false} />
                                            <p style={{ marginLeft: 9, fontSize: 13 }}>Ascendance data ordering</p>
                                        </div>
                                    </div>
                                    <div className="skd-user-info-export-button">
                                        <div className="skd-user-info-export-button-cancel" onClick={() => setDisplayExport(false)}>
                                            <h5>Cancel</h5>
                                        </div>
                                        {exportStatus === "Raw" && <button type="button" className="skd-user-info-export-button-apply" onClick={handleExportExcel}>Export</button>}
                                        {exportStatus === "Debit Note" && <div onClick={() => { setDisplayExport(false); setExportStatus("Raw"); setGetExcel([]); setFileName("") }}>
                                            <ReactToExcel className="skd-user-info-export-button-apply1" table="export-table" filename={excelFileName || `${exporterName} debit from ${startDay} to ${endDay}`} sheet="sheet" buttonText="Export" />
                                        </div>}
                                        {exportStatus === "CSV" && <CSVLink {...debitExport} className="skd-user-info-export-button-apply2">Export</CSVLink>}
                                        {exportStatus === "PDF" && <button type="button" className="skd-user-info-export-button-apply" onClick={generatePDF}>Export</button>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="skd-user-info-header-right" onClick={refresh}>
                            <FiRefreshCw size={18} />
                            <p className="skd-user-info-header-right-help">Refresh</p>
                        </div>
                        <div ref={displayMoreRight ? expandRightRef : null}>
                            <div className="skd-user-info-header-right1" onClick={() => { displayMoreRight ? setDisplayMoreRight(false) : setDisplayMoreRight(true) }}>
                                <MoreHoriz style={{ fontSize: 18 }} />
                                <p className="skd-user-info-header-right-help">More</p>
                            </div>
                            {displayMoreRight && <div className="skd-user-info-header-right-more">
                                <div className="skd-user-inf-header-right-more-option" onClick={() => { setShowInfo(false); setDisplayMoreRight(false) }}>
                                    <RiFolderInfoLine size={18} />
                                    <h5>Collapse Info</h5>
                                </div>
                                <div className="skd-user-inf-header-right-more-option" onClick={() => {
                                    if (showLocation) {
                                        setShowLocation(false);
                                        setDisplayMoreRight(false);
                                    } else {
                                        setShowLocation(true);
                                        setDisplayMoreRight(false);
                                    }
                                }}>
                                    <MdWrongLocation size={18} />
                                    {showLocation && <h5>Collapse Location</h5>}
                                    {!showLocation && <h5>Expand Location</h5>}
                                </div>
                                <div className="skd-user-inf-header-right-more-option" onClick={() => {
                                    if (showSummary) {
                                        setShowSummary(false);
                                        setDisplayMoreRight(false);
                                    } else if (!showSummary) {
                                        setShowSummary(true);
                                        setDisplayMoreRight(false);
                                    }

                                }}>
                                    <RiBarChartLine size={18} />
                                    {showSummary && <h5>Collapse Summary</h5>}
                                    {!showSummary && <h5>Expand Summary</h5>}
                                </div>
                                <div className="skd-user-inf-header-right-more-option">
                                    <RiCurrencyLine size={18} />
                                    <h5>Currency Exchange</h5>
                                    <ArrowDropDown style={{ fontSize: 20, marginLeft: "auto" }} />
                                    <form className="skd-user-info-header-right-hover" onSubmit={(e) => {
                                        e.preventDefault();
                                        setDisplayMoreRight(false);
                                    }}>
                                        <input type="text" placeholder="Enter currency" value={currencyExchange} onChange={(e) => setCurrencyExchange(e.target.value)} />
                                        <button type="submit">Save</button>
                                    </form>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="skd-user-info-wrap" ref={scrollShipment2} onScroll={onScrollRight}>
                        <div className="skd-user-info-body">
                            <h3 style={{ color: "rgb(55,55,55)", marginLeft: 5, fontWeight: 600, fontSize: 15 }}>{exporterName}</h3>
                            <div className="skd-user-info-body-info">
                                <Person style={{ fontSize: 15 }} />
                                <h5>{customerName}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <Phone style={{ fontSize: 15 }} />
                                <h5>{phone}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <Email style={{ fontSize: 15 }} />
                                <h5>{email}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <CallReceived style={{ fontSize: 15 }} />
                                <h5>{makh}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Tax ID: {mst}</h5>
                            </div>
                        </div>
                        {showLocation && <div className="skd-user-info-body" style={{ borderTop: "1px solid rgb(230,230,230)" }}>
                            <h3 style={{ color: "rgb(55,55,55)", marginLeft: 5, fontWeight: 600, fontSize: 15 }}>Location</h3>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Country: {countryName}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>City: {cityName}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>District: {town}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Ward: {state}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Address: {address}</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Postal: {postal}</h5>
                            </div>
                        </div>}
                        {showSummary && <div className="skd-user-info-body" style={{ borderTop: "1px solid rgb(230,230,230)" }}>
                            <h3 style={{ color: "rgb(25,25,25)", marginLeft: 5, fontWeight: 600, fontSize: 15 }}>Summary for <strong style={{ color: "black" }}>{paymentStatus}</strong></h3>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Weight</h5>
                                <h5 style={{ marginLeft: "auto" }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)} KG</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Reweight</h5>
                                <h5 style={{ marginLeft: "auto" }}>{(Math.round(totalReweight * 100) / 100).toFixed(2)} KG</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Rate</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalRate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Extra</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalComestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Address Correction</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalAddressCorrection.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Remote</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalRemote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Extra 1</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalSurcharge1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Fuel Value</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalFscValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Extra 2</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalSurcharge2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Vat Value</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalVatValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Extra 3</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalSurcharge3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Value in USD</h5>
                                <h5 style={{ marginLeft: "auto" }}>{totalAmount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                            </div>
                            <div className="skd-user-info-body-info" style={{ alignItems: "center" }}>
                                <h5 style={{ marginLeft: 0 }}>Currency Exchange</h5>
                                <input type="number" value={currencyExchange} ref={currencyRef} onChange={(e) => setCurrencyExchange(e.target.value)} style={{ width: "30%", marginLeft: "auto", textAlign: "flex-end", padding: 5 }} />
                            </div>
                            <div className="skd-user-info-body-info">
                                <h5 style={{ marginLeft: 0 }}>Total Value in VND</h5>
                                <h5 style={{ marginLeft: "auto", color: "darkblue" }}>{totalVND.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} VND</h5>
                            </div>
                        </div>}
                    </div>
                    <div className="skd-user-info-body-info">
                        <MdCreditCardOff size={15} />
                        <h5 style={{ marginLeft: 5 }}>Unpaid amount</h5>
                        <h5 style={{ marginLeft: "auto" }}>{totalUnpaid.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                    </div>
                    <div className="skd-user-info-body-info">
                        <MdCreditCard size={15} />
                        <h5 style={{ marginLeft: 5 }}>Paid amount</h5>
                        <h5 style={{ marginLeft: "auto" }}>{totalPaid.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h5>
                    </div>
                </div>}
            </div>}
        </div>
    )
}


const customerClass = [
    { name: "FWD" },
    { name: "FWD1" },
    { name: "FWD2" },
    { name: "FWD3" },
    { name: "Retail" },
    { name: "Retail-1" },
    { name: "FAN-SG" },
    { name: "FWD-TTN" },
    { name: "FWD-TTT" },
    { name: "Retail-TTN" },
]

const tableHeader = [
    { title: "SKD HAWB", select: false, id: "1" },
    { title: "Ship To", select: false, id: "2" },
    { title: "Date", select: false, id: "3" },
    { title: "INTL AWB", select: false, id: "4" },
    { title: "Type", select: false, id: "5" },
    { title: "Weight (KG)", select: false, id: "6" },
    { title: "Reweight (KG)", select: false, id: "7" },
    { title: "Rate (USD)", select: false, id: "8" },
    { title: "Extra (USD)", select: false, id: "9" },
    { title: "Add Correct (USD)", select: false, id: "10" },
    { title: "Remote (USD)", select: false, id: "11" },
    { title: "Extra 1 (USD)", select: false, id: "12" },
    { title: "FSC (USD)", select: false, id: "13" },
    { title: "Extra 2 (USD)", select: false, id: "14" },
    { title: "VAT (USD)", select: false, id: "15" },
    { title: "Extra 3 (USD)", select: false, id: "16" },
    { title: "Total (USD)", select: false, id: "17" },
]