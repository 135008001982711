import { ArrowDropDown, ArrowForward, LocationOnOutlined } from '@material-ui/icons'
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { db } from "../firebase";
import firebase from "firebase";
export default function HoldReason({ no, hawb, sin, note,code, fromCity, country, city, town, state, postal, status }) {
    const [showTracing, setShowTracing] = useState(false);
    const [courierStatus, setCourierStatus] = useState("");
    const [resultTracing, setResultTracing] = useState([]);
    const day = new Date();
    const hour1 = (day.getHours() < 10 ? "0" : "") + day.getHours();
    const min1 = (day.getMinutes() < 10 ? "0" : "") + day.getMinutes();
    const etdHour = +hour1.substring(0, 2) + 2;
    const finalEtd = `${etdHour}:${min1}`;
    const newDate = new Date();
    const day1 = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    const year = newDate.getFullYear();
    const traceTime = `${year}-${month}-${day1}`;
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const dateTime = (`${hour}:${min}`);
    const traceShipment = () => {
        setShowTracing(true);

    }
    
    return (
        <div className="skydart-tracking">
            <div className="skydart-tracking-header">
                <h5><strong style={{ fontWeight: 600, background: "rgb(235,235,235)", borderRadius: 5, padding: 3, width: "10%", border: "1px solid lightgray" }}>No: {no}</strong> <strong style={{ textDecoration: "underline", color: "rgb(57, 108, 190)" }}>{hawb}</strong></h5>
            </div>
            <div className="skydart-tracking-content">
                <h5 style={{ fontSize: 11 }}>Shipping area:</h5>
                <div className="skydart-tracking-shipping-area">
                    <h5 style={{ fontSize: 11 }}>{fromCity}</h5>
                    <ArrowForward style={{ fontSize: 15 }} />
                    <img
                        loading="lazy"
                        width="18"
                        src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    <h5 style={{ fontSize: 11 }}>{country}({code}), {city}, {state}, {town}, {postal} </h5>
                </div>
            </div>
            <div className="skydart-tracking-status">
                <h5 style={{ fontSize: 11 }}>Current status: <strong style={{ color: "black", textDecoration: "underline" }}>{status}</strong>, Courier status: <strong style={{ color: "black", textDecoration: "underline" }}></strong> </h5>
                {!showTracing && <div className="skydart-tracking-status-container" onClick={() => setShowTracing(true)}>
                    <h3 style={{ marginLeft: 5, fontSize: 15 }}>Hold reason</h3>
                    <ArrowDropDown />
                </div>}
                {showTracing && <div className="skydart-tracking-status-container1" onClick={() => setShowTracing(false)}>
                    <div style={{ borderBottom: "1px solid lightgray", marginTop: 7, marginLeft: 5, marginRight: 5 }}>
                        <div className="skydart-track-detail">
                            <div className="skydart-track-detail-left">
                                <div style={{ border: "1px solid", height: 20, width: 0, color: "lightgray", marginLeft: 10 }}></div>
                                <LocationOnOutlined htmlColor="green" />
                            </div>
                            <div className="skydart-track-detail-right">
                                <h5>{note}</h5>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    )
}
