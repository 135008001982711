import { Snackbar } from '@material-ui/core';
import { Add, ArrowDownward, LocalShipping, Block, CallReceived, Assessment, ArrowRightAlt, Refresh, Clear, Crop, ArrowUpward, DeleteOutline, Edit, EventNote, FilterList, FlightTakeoff, FullscreenExit, Help, ImportExport, Launch, LocalOffer, Payment, Person, QuestionAnswer, Receipt, Search, TrackChanges, Warning, TrendingUp, ArrowBack, ArrowForward, List, FileCopy, Print, InsertDriveFile, Class, FormatAlignCenter, BorderLeft, Storefront, ArrowForwardIos, Email, ArrowDropUp, FileCopySharp, MoreHoriz, ShowChart, Event, ArrowLeft, ArrowRight, LocalShippingOutlined, PanTool, Check } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Command, Copy, File, Filter, Minus, Package } from 'react-feather';
import { CircularProgress, LinearProgress, IconButton } from '@material-ui/core';
import axios from "axios";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "./style.css";
import { db } from "../firebase";
import firebase from "firebase";
import { CSVLink } from "react-csv";
import { IoBuildOutline } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GrAnalytics } from 'react-icons/gr';
import TracingContainer from './TracingContainer';
import { BsColumnsGap } from "react-icons/bs";
import DatepickerContext from "./datepickerContext";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import { MdOutlineLayersClear } from "react-icons/md";
import { TiExportOutline } from "react-icons/ti";
import { useReactToPrint } from 'react-to-print';
import { GrSplits } from "react-icons/gr";
import Month from './Month';
import { GoCalendar } from "react-icons/go";
import { AiOutlineMinus } from 'react-icons/ai';
import { FiEdit } from "react-icons/fi";
import { GrApps } from "react-icons/gr";
import { RiBillLine } from "react-icons/ri";
import { FaFileInvoice } from "react-icons/fa";
import { FcOk } from "react-icons/fc";
import { ImNewspaper } from "react-icons/im";
import { BsReplyAll } from "react-icons/bs";
import StaffBill2 from './StaffBill2';
import Inv2 from './Inv2';
import ShippingMark from './ShippingMark';

export default function Shipmentss({ userData }) {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
    let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? "0" : "");
    let last = firstDayOfCurrentWeek + 6;
    let firstday = firstDayOfCurrentWeek - 6;
    let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
    let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
    let yesterday = date - 1;
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
    let lastMonth = month - 1;
    let year = newDate.getFullYear();
    let lastDay = new Date(year, lastMonth, 0);
    let firstDayOfYear = new Date(year, 0, 1);
    let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
    let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
    const monthName = months[newDate.getMonth()];
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? "0" : "") + firstDay.getDate();
    const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    const [query, setQuery] = useState("");
    const [dateMethod, setDateMethod] = useState("range");
    const [pageNumber, setPageNumber] = useState(1);
    const history = useHistory();
    const [alertShipment, setAlertShipment] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [defaultHeader, setDefaultHeader] = useState("skydart-sticky-header");
    const [defaultBody, setDefaultBody] = useState("skydart-sticky-body");
    const [displaySort, setDisplaySort] = useState(false);
    const [displayDateRange, setDisplayDateRange] = useState(false);
    const [apply, setApply] = useState(false);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [controlLoading, setControlLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [shipments, setShipments] = useState([]);
    const [searchExactCharge, setSearchExactCharge] = useState("");
    const [searchExactWeight, setSearchExactWeight] = useState("");
    const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
    const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
    const [searchHawb, setSearchHawb] = useState("");
    const [findSin, setFindSin] = useState("");
    const [findMawb, setFindMawb] = useState("");
    const [searchType, setSearchType] = useState("");
    const [searchCountry, setSearchCountry] = useState("");
    const [searchCode, setSearchCode] = useState("");
    const [searchCus, setSearchCus] = useState("");
    const [searchWeight, setSearchWeight] = useState("");
    const [weightInput, setWeightInput] = useState("");
    const [searchReweight, setSearchReweight] = useState("");
    const [searchPcs, setSearchPcs] = useState("");
    const [searchAirline, setSearchAirline] = useState("");
    const [searchService, setSearchService] = useState("");
    const [searchAgent, setSearchAgent] = useState("");
    const [searchMawb, setSearchMawb] = useState("");
    const [searchSin, setSearchSin] = useState("");
    const [searchRate, setSearchRate] = useState("");
    const [searchAddress, setSearchAddress] = useState("");
    const [searchRemote, setSearchRemote] = useState("");
    const [searchSurcharge, setSearchSurcharge] = useState("");
    const [searchSurcharge2, setSearchSurcharge2] = useState("");
    const [searchSurcharge3, setSearchSurcharge3] = useState("");
    const [searchFsc, setSearchFsc] = useState("");
    const [searchFscValue, setSearchFscValue] = useState("");
    const [searchVat, setSearchVat] = useState("");
    const [searchVatValue, setSearchVatValue] = useState("");
    const [searchTotal, setSearchTotal] = useState("");
    const [searchPickup, setSearchPickup] = useState("");
    const [searchAcc, setSearchAcc] = useState("");
    const [dusValue, setDusValue] = useState([]);
    const [searchCompany, setSearchCompany] = useState("");
    const [searchPack, setSearchPack] = useState("");
    const [searchSale, setSearchSale] = useState("");
    const [etdInput, setEtdInput] = useState("");
    const [etaInput, setEtaInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [pageNumberLimit, setPageNumberLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [selectedShipment, setSelectedShipment] = useState([]);
    const [displaySortCustomer, setDisplaySortCustomer] = useState(false);
    const [displaySortCountry, setDisplaySortCountry] = useState(false);
    const [displaySortMawb, setDisplaySortMawb] = useState(false);
    const [displaySortSin, setDisplaySortSin] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(false);
    const [displayType, setDisplayType] = useState(false);
    const [alphabet, setAlphabet] = useState(false);
    const [countryAlphabet, setCountryAlphabet] = useState(false);
    const [searchClass, setSearchClass] = useState("");
    const [displayEdit, setDisplayEdit] = useState(false);
    const [displayTracing, setDisplayTracing] = useState(false);
    const [displayNote, setDisplayNote] = useState(false);
    const [displayFlight, setDisplayFlight] = useState(false);
    const [displayPricing, setDisplayPricing] = useState(false);
    const [dipslayPriceShortCut, setDisplayPriceShortCut] = useState(false);
    const [displayExport, setDisplayExport] = useState(false);
    const [displayExportAction, setDisplayExportAction] = useState(false);
    const [displayReweight, setDisplayReweight] = useState(false);
    const [displayBreak, setDisplayBreak] = useState(false);
    const [displayUsExport, setDisplayUsExport] = useState(false);
    const [displayBagLoad, setDisplayBagLoad] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [loadingRate, setLoadingRate] = useState(false);
    const [rateInput, setRateInput] = useState("");
    const [fscInput, setFscInput] = useState("");
    const [fscValueInput, setFscValueInput] = useState("");
    const [comesticInput, setComesticInput] = useState("");
    const [remoteInput, setRemoteInput] = useState("");
    const [addressCorrectionInput, setAddressCorrectionInput] = useState("");
    const [vatValueInput, setVatValueInput] = useState("");
    const [surchargeInput, setSurchargeInput] = useState("");
    const [surchargeInput2, setSurchargeInput2] = useState("");
    const [surchargeInput3, setSurchargeInput3] = useState("");
    const [comInput, setComInput] = useState("");
    const [sellNoteInput, setSellNoteInput] = useState("");
    const [vatInput, setVatInput] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [traceTime, setTraceTime] = useState("");
    const [noteOption, setNoteOption] = useState("");
    const [note, setNote] = useState("");
    const [displayFlightInfo, setDisplayFlightInfo] = useState("");
    const [flightInput, setFlightInput] = useState("");
    const [MAWBInput, setMAWBInput] = useState("");
    const [flights, setFlights] = useState([]);
    const [parentStatus, setParentStatus] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [locationErr, setLocationErr] = useState(false);
    const [descriptionErr, setDescriptionErr] = useState(false);
    const [reweight, setReweight] = useState("");
    const [sin, setSin] = useState("");
    const [mawbInput, setMawbInput] = useState("");
    const [flight, setFlight] = useState("");
    const [agent, setAgent] = useState("");
    const [service, setService] = useState("");
    const [excelFileName, setExcelFileName] = useState("");
    const [displayAction, setDisplayAction] = useState(false);
    const [displayController, setDisplayController] = useState(false);
    const [staffInput, setStaffInput] = useState(false);
    const [staffs, setStaffs] = useState([]);
    const [displayBatchReweight, setDisplayBatchReweight] = useState(false);
    const [displayBatchTracking, setDisplayBatchTracking] = useState(false);
    const [displayPayment, setDisplayPayment] = useState(false);
    const [reweightResult, setReweightResult] = useState([]);
    const [trackingResult, setTrackingResult] = useState([]);
    const [getExcel, setGetExcel] = useState([]);
    const [displayExportAll, setDisplayExportAll] = useState(false);
    const [searchNote, setSearchNote] = useState("");
    const [mode, setMode] = useState("");
    const [fuel, setFuel] = useState("");
    const [searchComestic, setSearchComestic] = useState("");
    const [resultTracing, setResultTracing] = useState([]);
    const [displaySurcharge, setDisplaySurcharge] = useState(false);
    const [remote, setRemote] = useState("");
    const [addressCorrect, setAddressCorrect] = useState("");
    const [dhlFsc, setDhlFsc] = useState("");
    const [fedexFsc, setFedexFsc] = useState("");
    const [otherFsc, setOtherFsc] = useState("");
    const [fetchRemote, setFetchRemote] = useState("");
    const [fetchAddressCorrect, setFetchAddressCorrect] = useState("");
    const [fetchDhl, setFetchDhl] = useState("");
    const [fetchFedex, setFetchFedex] = useState("");
    const [fetchOther, setFetchOther] = useState("");
    const [displayTracingOption, setDisplayTracingOption] = useState(false);
    const [displayTracking, setDisplayTracking] = useState(false);
    const [selectTracing, setSelectTracing] = useState([]);
    const [accountNote, setAccountNote] = useState("");
    const [searchPickupNote, setSearchPickupNote] = useState("");
    const [searchGnNote, setSearchGnNote] = useState("");

    const [showClass, setShowClass] = useState(false);
    const [showExporter, setShowExporter] = useState(false);
    const [showFromCity, setShowFromCity] = useState(false);
    const [showToCountry, setShowToCountry] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showType, setShowType] = useState(false);
    const [showWeight, setShowWeight] = useState(false);
    const [showReweight, setShowReweight] = useState(false);
    const [showPcs, setShowPcs] = useState(false);
    const [showPacking, setShowPacking] = useState(false);
    const [showAirline, setShowAirline] = useState(false);
    const [showService, setShowService] = useState(false);
    const [showAgent, setShowAgent] = useState(false);
    const [showMawb, setShowMawb] = useState(false);
    const [showSin, setShowSin] = useState(false);
    const [showRate, setShowRate] = useState(false);
    const [showExtra, setShowExtra] = useState(false);
    const [showAddressCorrect, setShowAddressCorrect] = useState(false);
    const [showRemote, setShowRemote] = useState(false);
    const [showExtra1, setShowExtra1] = useState(false);
    const [showFsc, setShowFsc] = useState(false);
    const [showFscValue, setShowFscValue] = useState(false);
    const [showExtra2, setShowExtra2] = useState(false);
    const [showVat, setShowVat] = useState(false);
    const [showVatValue, setShowVatValue] = useState(false);
    const [showExtra3, setShowExtra3] = useState(false);
    const [showController, setShowController] = useState(false);
    const [showPaymentPickup, setShowPaymentPickup] = useState(false);
    const [showPaymentAccountant, setShowPaymentAccountant] = useState(false);
    const [showPaymentCompany, setShowPaymentCompany] = useState(false);
    const [showOpsNote, setShowOpsNote] = useState(false);
    const [showSalesNote, setShowSalesNote] = useState(false);
    const [showAccountantNote, setShowAccountantNote] = useState(false);
    const [displayFilterOption, setDisplayFilterOption] = useState(false);
    const [filterCus, setFilterCus] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [searchSellNote, setSearchSellNote] = useState("");
    const [searchAccNote, setSearchAccNote] = useState("");
    const [searchCity, setSearchCity] = useState("");
    const [scrollY, setScrollY] = useState(0);
    const [scrollX, setScrollX] = useState(0);
    const [displayBreakDown, setDisplayBreakDown] = useState(false);
    const [breakCountry, setBreakCountry] = useState(false);
    const [breakWeight, setBreakWeight] = useState(false);
    const [breakService, setBreakService] = useState(false);
    const [breakRate, setBreakRate] = useState(false);
    const [dateStatus, setDateStatus] = useState("Period");
    const [mst, setMst] = useState("");
    const [selectPrint, setSelectPrint] = useState([]);
    const [displayEditScreen, setDisplayEditScreen] = useState(false);

    ///column
    const [showFrom, setShowFrom] = useState(true);
    const [showTo, setShowTo] = useState(true);
    const [showStatus1, setShowStatus1] = useState(true);
    const [showType1, setShowType1] = useState(true);
    const [showWeight1, setShowWeight1] = useState(true);
    const [showPcs1, setShowPcs1] = useState(true);
    const [showPacking1, setShowPacking1] = useState(true);
    const [showAirline1, setShowAirline1] = useState(true);
    const [showService1, setShowService1] = useState(true);
    const [showAgent1, setShowAgent1] = useState(true);
    const [showMawb1, setShowMawb1] = useState(true);
    const [showSin1, setShowSin1] = useState(true);
    const [showRate1, setShowRate1] = useState(true);
    const [showSurcharge, setShowSurcharge] = useState(true);
    const [showAddress1, setShowAddress1] = useState(true);
    const [showRemote1, setShowRemote1] = useState(true);
    const [showSurcharge1, setShowSurcharge1] = useState(true);
    const [showFsc1, setShowFsc1] = useState(true);
    const [showSurcharge2, setShowSurcharge2] = useState(true);
    const [showVat1, setShowVat1] = useState(true);
    const [showSurcharge3, setShowSurcharge3] = useState(true);
    const [showTotal1, setShowTotal3] = useState(true);
    const [showController1, setShowController1] = useState(true);
    const [showPayment1, setShowPayment1] = useState(true);
    const [showNote1, setShowNote1] = useState(true);
    const [displaySortFrom, setDisplaySortFrom] = useState(false);

    const [hawb, setHawb] = useState("");
    const [type, setType] = useState("");
    const [code, setCode] = useState("");
    const [country, setCountry] = useState("");
    const [importer, setImporter] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [town, setTown] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postal, setPostal] = useState("");
    const [toName, setToName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [packageValue, setPackageValue] = useState([]);
    const [invoiceValue, setInvoiceValue] = useState([]);
    const [term, setTerm] = useState("");
    const [transport, setTransport] = useState("");
    const [reason, setReason] = useState("");
    const [service1, setService1] = useState("");
    const [eori, setEori] = useState("");
    const [exporter, setExporter] = useState("");
    const [fromCountry, setFromCountry] = useState("");
    const [fromAddress, setFromAddress] = useState("");
    const [fromAddress2, setFromAddress2] = useState("");
    const [fromAddress3, setFromAddress3] = useState("");
    const [fromTown, setFromTown] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [fromState, setFromState] = useState("");
    const [fromPostal, setFromPostal] = useState("");
    const [fromName, setFromName] = useState("");
    const [fromPhone, setFromPhone] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [controller, setController] = useState("");
    const [pickupBy, setPickupBy] = useState("");
    const [stat, setStat] = useState("");
    const [createAt, setCreateAt] = useState("");
    const [pickup, setPickup] = useState("");
    const [arrive, setArrive] = useState("");
    const [process, setProcess] = useState("");
    const [clearance, setClearance] = useState("");
    const [clear, setClear] = useState("");
    const [hold, setHold] = useState("");
    const [searchFrom, setSearchFrom] = useState("");
    const [description1, setDescription1] = useState("");
    const [loadShipment, setLoadShipment] = useState(false);
    const [mockInfo, setMockInfo] = useState([]);
    const [selection, setSelection] = useState([]);
    const [envelope, setEnvelope] = useState(0);
    const [doxWeight, setDoxWeight] = useState(0.5);
    const [alertUpdate, setAlertUpdate] = useState(false);
    const [currentState, setCurrentState] = useState(0);
    const [displayFromCountry, setDisplayFromCountry] = useState(false);
    const [displayToCountry, setDisplayToCountry] = useState(false);
    const [printOption, setPrintOption] = useState(false);
    const [eamService, setEamService] = useState("");
    const [doxErr, setDoxErr] = useState(false);
    const [copyText, setCopyText] = useState("");
    const [displayCopy, setDisplayCopy] = useState(false);
    const [board, setBoard] = useState("");
    const [roleInput, setRoleInput] = useState(userData.role);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE
    });
    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths
    } = useDatepicker({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        focusedInput: dateRange.focusedInput,
        onDatesChange: handleDateChange
    });

    function handleDateChange(data) {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE });
        } else {
            setDateRange(data);
        }
    }
    const onScroll = () => {
        const scrollTop = scrollShipment.current.scrollTop;
        const scrollLeft = scrollShipment.current.scrollLeft;
        setScrollY(scrollTop);
        setScrollX(scrollLeft);
    }
    const finalCharge = packageValue.reduce((a, v) => +a + +v.packageCharge, 0);
    const finalGross = packageValue.reduce((a, v) => +a + +v.packageWeight, 0);
    const finalInvoiceValue = invoiceValue.reduce((a, v) => a = +a.toFixed(2) + +v.itemValue.toFixed(2), 0);
    const totalInvoiceValue = +finalInvoiceValue + +transport;
    let handleChange = (i, e) => {
        let newFormValues = [...packageValue];
        newFormValues[i][e.target.name] = e.target.value;
        setPackageValue(newFormValues);
    };
    let invoiceChange = (i, e) => {
        let newInvoiceValues = [...invoiceValue];
        newInvoiceValues[i][e.target.name] = e.target.value;
        setInvoiceValue(newInvoiceValues);
    }
    let removeFormField = (index, e) => {
        // let newFormValues = [...packageValue];
        // newFormValues.splice(i, 1);
        // setPackageValue(newFormValues);
        setPackageValue(packageValue.filter((v, i) => i !== index));
    }
    let addFormFields = () => {
        let x = packageValue.length;
        setPackageValue([...packageValue, { packNo: x + 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0.00, packageCharge: 0 }])
    };
    let removeInvoice = (index, e) => {
        setInvoiceValue(invoiceValue.filter((v, i) => i !== index));
    }
    let addCarton = () => {
        let x = invoiceValue.length;
        setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: "", itemIngredients: "", itemUnit2: "", itemPackType2: "", itemCommonity: "", itemUnit: "", itemUnitPirce: 0, itemQuantity: 0, itemValue: 0, itemManufact: "", itemPurpose: "", itemHsCode: "", itemPackType: "", itemManufactAddress: "", itemCountryOrigin: "Vietnam", itemFda: "" }])
    }
    const handleChangeService = (e) => {
        if (e.target.value === "DHL") {
            setEamService("INT");
        }
        if (e.target.value === "FIP") {
            setEamService("FIP")
        }
        setService1(e.target.value);
    }
    let scrollShipment = useRef();
    let fromCountryRef = useRef();
    let toCountryRef = useRef();
    let printRefContainer = useRef();
    let printAllRef = useRef();
    let labelRef = useRef();
    let invoiceRef = useRef();
    let shippingMarkRef = useRef();
    const printLabel = useReactToPrint({
        content: () => labelRef.current,
    })
    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
    })
    const printShippingMark = useReactToPrint({
        content: () => shippingMarkRef.current,
    })
    const printAll = useReactToPrint({
        content: () => printAllRef.current,
    })
    useEffect(() => {
        let newDate = new Date()
        let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        let year = newDate.getFullYear();
        const monthName = months[newDate.getMonth()];
        setTraceTime(`${year}-${month}-${date}`);
        const hour = (newDate.getHours() < 10 ? "0" : "") + newDate.getHours();
        const min = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
        setDateTime(`${hour}:${min}`);
    }, []);
    function resetShipment(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertShipment(false);
    }
    function resetSuccess(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertSuccess(false);
    }
    function resetUpdate(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setAlertUpdate(false);
    }
    function resetCopy(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setDisplayCopy(false);
    }
    const renderDateRange = () => {
        if (displayDateRange) {
            setDisplayDateRange(false);
        } else if (!displayDateRange) {
            setDisplayDateRange(true);
        }
    }

    const renderSearch = () => {
        setDisplaySearch(true);
        setSearchLoading(true);
        setTimeout(() => {
            setSearchLoading(false);
        }, 500)
    }
    const pickShipment = () => {
        setLoadShipment(true);
        db.collection("shipments").doc(hawb).get().then((doc) => {
            if (doc.exists) {
                setMockInfo(doc.data());
                setSelection(exportSelected);
                if (doc.data().shipmentType === "WPX") {
                    setType(doc.data().shipmentType);
                    setCode(doc.data().code);
                    setCountry(doc.data().toCountry);
                    setImporter(doc.data().importer);
                    setAddress(doc.data().toAddress);
                    setAddress2(doc.data().toAddress2);
                    setAddress3(doc.data().toAddress3);
                    setTown(doc.data().toTown);
                    setCity(doc.data().toCity);
                    setState(doc.data().toState);
                    setPostal(doc.data().toPostal);
                    setToName(doc.data().toName);
                    setPhone(doc.data().toPhone);
                    setEmail(doc.data().toEmail);
                    setPackageValue(doc.data().packageValue);
                    setInvoiceValue(doc.data().invoiceValue);
                    setDusValue(doc.data().dus);
                    setTerm(doc.data().term);
                    setTransport(doc.data().transportation);
                    setReason(doc.data().usage);
                    setService1(doc.data().service);
                    setEori(doc.data().eori);
                    setExporter(doc.data().exporter);
                    setFromCountry(doc.data().fromCountry);
                    setFromAddress(doc.data().fromAddress);
                    setFromAddress2(doc.data().fromAddress2);
                    setFromAddress3(doc.data().fromAddress3);
                    setFromTown(doc.data().fromTown);
                    setFromCity(doc.data().fromCity);
                    setFromState(doc.data().fromState);
                    setFromPostal(doc.data().fromPostal);
                    setFromName(doc.data().fromName);
                    setFromPhone(doc.data().fromPhone);
                    setTerm(doc.data().term);
                    setFromEmail(doc.data().fromEmail);

                    setController(doc.data().controller);
                    setDescription1(doc.data().description);
                    setPickupBy(doc.data().pickupBy);
                    setStat(doc.data().status);
                    setCreateAt(doc.data().createAt);
                    setPickup(doc.data().pickupBy);
                    setArrive(doc.data().receiveBy);
                    setProcess(doc.data().processBy);
                    setClearance(doc.data().clearanceBy);
                    setClear(doc.data().clearedBy);
                    setHold(doc.data().holdBy);
                } else if (doc.data().shipmentType === "DOX") {
                    setType(doc.data().shipmentType);
                    setCode(doc.data().code);
                    setCountry(doc.data().toCountry);
                    setImporter(doc.data().importer);
                    setAddress(doc.data().toAddress);
                    setAddress2(doc.data().toAddress2);
                    setAddress3(doc.data().toAddress3);
                    setTown(doc.data().toTown);
                    setCity(doc.data().toCity);
                    setState(doc.data().toState);
                    setPostal(doc.data().toPostal);
                    setToName(doc.data().toName);
                    setPhone(doc.data().toPhone);
                    setPickupBy(doc.data().pickupBy);
                    setEmail(doc.data().toEmail);
                    setTerm(doc.data().term);
                    setTransport(doc.data().transportation);
                    setEnvelope(doc.data().totalPackage);
                    setDoxWeight(doc.data().totalCharge);
                    setReason(doc.data().usage);
                    setService1(doc.data().service);
                    setEori(doc.data().eori);
                    setExporter(doc.data().exporter);
                    setFromCountry(doc.data().fromCountry);
                    setFromAddress(doc.data().fromAddress);
                    setFromAddress2(doc.data().fromAddress2);
                    setFromAddress3(doc.data().fromAddress3);
                    setFromTown(doc.data().fromTown);
                    setFromCity(doc.data().fromCity);
                    setFromState(doc.data().fromState);
                    setFromPostal(doc.data().fromPostal);
                    setFromName(doc.data().fromName);
                    setFromPhone(doc.data().fromPhone);
                    setFromEmail(doc.data().fromEmail);
                    setController(doc.data().controller);
                    setStat(doc.data().status);
                    setCreateAt(doc.data().createAt);
                    setDescription1("Document");
                    setPickup(doc.data().pickupBy);
                    setArrive(doc.data().receiveBy);
                    setProcess(doc.data().processBy);
                    setClearance(doc.data().clearanceBy);
                    setClear(doc.data().clearedBy);
                    setHold(doc.data().holdBy);
                }
                setLoadShipment(false);
            } else {
                alert("Current shipment doesn't exist, please try again");
                setDisplayEditScreen(false);
            }
        })


    }
    useEffect(() => {
        if (hawb !== "") {
            pickShipment();
        }
    }, [hawb])
    let customerRef = useRef();
    let statusRef = useRef();
    let typeRef = useRef();
    let sinRef = useRef();
    let mawbRef = useRef();
    let countryRef = useRef();
    let fromRef = useRef();
    let exportRef = useRef();
    let breakRef = useRef();
    let emailRef = useRef();
    let actionRef = useRef();
    let tracingRef = useRef();
    let dateRef = useRef();
    let searchRef = useRef();
    let textInput = useRef();
    let breakDownRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!customerRef.current.contains(e.target)) {
                setDisplaySortCustomer(false);
            }
            if (!statusRef.current.contains(e.target)) {
                setDisplayStatus(false);
            }
            if (!typeRef.current.contains(e.target)) {
                setDisplayType(false);
            }
            if (!mawbRef.current.contains(e.target)) {
                setDisplaySortMawb(false);
            }
            if (!sinRef.current.contains(e.target)) {
                setDisplaySortSin(false);
            }
            if (!countryRef.current.contains(e.target)) {
                setDisplaySortCountry(false);
            }
            if (!fromRef.current.contains(e.target)) {
                setDisplaySortFrom(false);
            }
            if (!exportRef.current.contains(e.target)) {
                setDisplayExportAction(false);
            }
            if (!actionRef.current.contains(e.target)) {
                setDisplayAction(false);
            }
            if (!tracingRef.current.contains(e.target)) {
                setDisplayTracingOption(false);
            }
            if (!dateRef.current.contains(e.target)) {
                setDisplayDateRange(false);
            }
            if (!searchRef.current.contains(e.target)) {
                setDisplayFilterOption(false);
            }
            if (!breakDownRef.current.contains(e.target)) {
                setDisplayBreakDown(false);
            }

        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])

    const renderSort = () => {
        setDisplaySort(true);
    }

    function removeAccents(str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str?.replace(re, char);
        }
        return str;
    }
    const refreshShipment = () => {

        setLoading(true);
        axios.get(`https://test.skydart.com.vn/api/shipmentttt/${startDate}/${endDate}/**/skd/shipment`).then((snapshot) => {
            let data = snapshot.data;
            setShipments(data.filter((s, i) => {
                return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            }).map((s, i) => {
                let fsc = s.fsc;
                let fuelValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge) * +s.fsc / 100)
                let vatValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +s.comestic + +fuelValue + +s.surcharge2) * +s.vat / 100);
                let total = ((+s.rate + +s.addressCorrection + +s.remote + +s.comestic + +s.surcharge + +fuelValue + +s.surcharge2 + +vatValue + +s.surcharge3));
                let value = parseFloat(s.value).toFixed(2) || "";
                let updated = s.updated || "false";
                let removeSkd = s.hawb.substring(3);
                return {
                    select: false,
                    updated: updated,
                    account: s.account || "",
                    currency: s.currency || "",
                    service: s.service === "VDH" ? "DHL-VN" : s.service,
                    date: s.date || "",
                    hawb: s.hawb || "",
                    removeSkd: removeSkd,
                    mawb: s.mawb || "",
                    makh: s.makh || "",
                    company: s.company || "",
                    contact: s.contact || "",
                    type: s.type || "",
                    status: s.status || "",
                    term: s.term || "",
                    time: s.time || "",
                    address: s.address || "",
                    address2: s.address2 || "",
                    address3: s.address3 || "",
                    city: s.city || "",
                    country: s.country || "",
                    phone: s.phone || "",
                    postal: s.postal || "",
                    state: s.state || "",
                    term: s.term,
                    town: s.town || "",
                    chargeable: (Math.round(s.chargeable * 100) / 100).toFixed(2).toString() || "",
                    exactWeight: (Math.round(s.exactWeight * 100) / 100).toFixed(2).toString() || "",
                    exactCharge: (Math.round(s.exactCharge * 100) / 100).toFixed(2).toString() || "",
                    pickupNote: s.pickupNote,
                    gnNote: s.gnNote,
                    accountNote: s.accountNote,
                    amount: s.amount || "",
                    totalItem: s.totalItem || "",
                    transport: s.transport || "",
                    description: s.description || "",
                    controller: s.controller || "",
                    eamFormat: s.eamFormat || "",
                    value: s.type === "DOX" ? "0.00" : value,
                    airline: s.airline || "",
                    reweight: (Math.round(s.reweight * 100) / 100).toFixed(2).toString() || "",
                    agent: s.agent || "",
                    etd: s.etd || "",
                    subStatus: s.subStatus || "",
                    sin: s.sin || "",
                    sender: removeAccents(s.sender) || "",
                    note: s.note || "",
                    perform: s.perform || "",
                    rate: s.rate?.toString() || "",
                    fsc: s.fsc || "",
                    vat: s.vat.toString() || "",
                    remote: s.remote || "",
                    comestic: s.comestic || "",
                    addressCorrection: s.addressCorrection || "",
                    totalValue: total || "",
                    surcharge: s.surcharge || "",
                    surcharge2: s.surcharge2 || "",
                    surcharge3: s.surcharge3 || "",
                    fscValue: fuelValue.toString() || "",
                    vatValue: vatValue.toString() || "",
                    sellNote: s.sellNote || "",
                    paymentPickup: s.paymentPickup || "",
                    paymentAcc: s.paymentAcc || "",
                    paymentCompany: s.paymentCompany || "",
                    fromEmail: s.fromEmail || "",
                    fromCity: s.fromCity || "",
                    com: s.com || "",
                    rateUpdate: s.rateUpdate || "",
                    paymentStatus: s.paymentStatus || "",
                    paymentSubStatus: s.paymentSubStatus || "",
                    code: s.code || "",
                    eamService: s.eamService || "",
                    pack: s.pack || "",
                    plan: s.plan || "Pending",
                    accNote: s.accountNote,
                    pickupBy: s.pickupBy,
                    ref: "Pending",
                    overidedsiteId: "8423",
                    arrivalAirport: "JFK",
                    weightCode: "K",
                    originAirport: "SGN",
                    shipperCity: "Ho Chi Minh",
                    waybill: "F709",
                    importingCarrier: "",
                    gross: s.exactWeight,
                    amendmentCode: "21",
                    amendmentFlag: "A",
                    period: traceTime,
                }
            }))
            setLoading(false);
        })
    }
    useEffect(() => {
        axios.get("https://test.skydart.com.vn/api/flight/info").then((res) => {
            setFlights(res.data);
        });
    }, [])
    useEffect(() => {
        refreshShipment();
    }, [startDate, endDate])

    const headers = [
        { label: "Class", key: "plan" },
        { label: "MAKH", key: "makh" },
        { label: "Customer", key: "sender" },
        { label: "MAWB", key: "mawb" },
        { label: "Intl AWB", key: "sin" },
        { label: "SKD HAWB", key: "hawb" },
        { label: "Date", key: "date" },
        { label: "Time", key: "time" },
        { label: "From", key: "fromCity" },
        { label: "To", key: "country" },
        { label: "Sales", key: "controller" },
        { label: "Type", key: "type" },
        { label: "Weight", key: "exactCharge" },
        { label: "Reweight", key: "reweight" },
        { label: "PCS", key: "amount" },
        { label: "Term", key: "term" },
        { label: "Status", key: "status" },
        { label: "Airline", key: "airline" },
        { label: "Service", key: "service" },
        { label: "Agent", key: "agent" },
        { label: "ETD", key: "etd" },
        { label: "PACK", key: "pack" },
        { label: "Rate", key: "rate" },
        { label: "Extra", key: "comestic" },
        { label: "Address Correction", key: "addressCorrection" },
        { label: "Remote", key: "remote" },
        { label: "Extra 1", key: "surcharge" },
        { label: "Extra 2", key: "surcharge2" },
        { label: "Extra 3", key: "surcharge3" },
        { label: "FSC(%)", key: "fsc" },
        { label: "FSC Value", key: "fscValue" },
        { label: "VAT(%)", key: "vat" },
        { label: "VAT Value", key: "vatValue" },
        { label: "Total Rate", key: "totalValue" },
        { label: "Payment Pick Up", key: "paymentPickup" },
        { label: "Payment Accountant", key: "paymentAcc" },
        { label: "Payment Company", key: "paymentCompany" },
        { label: "OPS Note", key: "note" },
    ]
    const agentHeader = [
        { label: "ACCOUNT", key: "account" }, //
        { label: "HAWB", key: "removeSkd" },
        { label: "SERVICE", key: "eamService" },
        { label: "MAWB", key: "mawb" },
        { label: "DATE", key: "date" },
        { label: "COMPANY", key: "company" },
        { label: "CONTACT", key: "contact" },
        { label: "ADDRESS 1", key: "address" },
        { label: "ADDRESS 2", key: "address2" },
        { label: "ADDRESS 3", key: "address3" },
        { label: "Town", key: "town" },
        { label: "COUNTRY", key: "code" },
        { label: "Post Code", key: "postal" },
        { label: "Telephone", key: "phone" },
        { label: "Total No Of Packages", key: "amount" },
        { label: "Chargeable Weight", key: "exactCharge" },
        { label: "TYPE", key: "eamFormat" },
        { label: "DESCRIP", key: "description" },
        { label: "VALUE", key: "value" },
        { label: "CURRENCY", key: "currency" },
        { label: "Customer", key: "sender" },
        { label: "PACK", key: "pack" },
    ]

    const title = [
        { label: "OVERRIDESITEID", key: "overidedsiteId" },
        { label: "ArrivalAirport", key: "arrivalAirport" },
        { label: "WaybillOriginator", key: "waybill" },
        { label: "AirlinePrefix", key: "removeMawb" },
        { label: "AWBSerialNumber", key: "removeMawb1" },
        { label: "HouseAWB", key: "removeSkd" },
        { label: "MasterAWBIndicator", key: "master" },
        { label: "OriginAirport", key: "originAirport" },
        { label: "Pieces", key: "amount" },
        { label: "WeightCode", key: "weightCode" },
        { label: "Weight", key: "exactWeight" },
        { label: "Description", key: "description" },
        { label: "FDAIndicator", key: "fdaIndicator" },
        { label: "ImportingCarrier", key: "importingCarrier" },
        { label: "FlightNumber", key: "mawb" },
        { label: "ArrivalDay", key: "arrivalDay" },
        { label: "ArrivalMonth", key: "arrivalMonth" },
        { label: "ShipperName", key: "sender" },
        { label: "ShipperStreetAddress", key: "fromAddress" },
        { label: "ShipperCity", key: "shipperCity" },
        { label: "ShipperStateOrProvince", key: "fromTown" },
        { label: "ShipperPostalCode", key: "fromPostal" },
        { label: "ShipperCountry", key: "fromCode" },
        { label: "ShipperTelephone", key: "fromPhone" },
        { label: "ConsigneeName", key: "company" },
        { label: "ConsigneeStreetAddress", key: "address" },
        { label: "ConsigneeCity", key: "city" },
        { label: "ConsigneeStateOrProvince", key: "state" },
        { label: "ConsigneePostalCode", key: "postal" },
        { label: "ConsigneeCountry", key: "country" },
        { label: "ConsigneeTelephone", key: "phone" },
        { label: "AmendmentFlag", key: "amendmentFlag" },
        { label: "AmendmentCode", key: "amendmentCode" },
        { label: "AmendmentReason", key: "amendmentReason" },
        { label: "ptpDestination", key: "ptpDestination" },
        { label: "ptpDestinationDay", key: "ptpDestinationDay" },
        { label: "ptpDestinationMonth", key: "ptpDestinationMonth" },
        { label: "CustomsValue", key: "value" },
        { label: "CurrencyCode", key: "currency" },
        { label: "HTSNumber", key: "htsNumber" },
    ]

    const fuelValue = (+rateInput + +addressCorrectionInput + +remoteInput + +surchargeInput) * +fuel / 100;
    const vatValue = (+rateInput + +addressCorrectionInput + +remoteInput + +comesticInput + +surchargeInput + +fuel + +surchargeInput2) * +vatInput / 100;
    const total = (+rateInput + +addressCorrectionInput + +remoteInput + +surchargeInput + +fuel + +surchargeInput2 + +vatValue + +surchargeInput3);
    const onUpdateTracing = (e) => {
        e.preventDefault();
        setLoading(true);
        setDisplayTracing(false);
        exportSelected.forEach(s => {
            db.collection("shipments").doc(s.hawb).update({
                status: parentStatus,
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    time: traceTime + "T" + dateTime + ":00",
                    location: location,
                    stat: description,
                })),
                updatedBy: userData.displayName,
            })
            axios.put("https://test.skydart.com.vn/api/update/status", { hawb: s.hawb, status: parentStatus }).then(() => {
                setLoading(false);
                setAlertSuccess(true);
            }).then(() => {
                setLocation("");
                setDescription("");
            })
        })
    }
    const onUpdateNote = () => {
        setDisplayNote(false);
        setLoading(true);
        if (noteOption === "Operation Note") {
            exportSelected.forEach(s => {
                axios.put("https://test.skydart.com.vn/api/update/note", { hawb: s.hawb, note: note }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                    setSelectedShipment(shipments.map(val => {
                        let noteValue = note || s.note;
                        if (val.hawb === s.hawb) {
                            val.note = noteValue;
                        }
                        return val;
                    }))
                })
            })
        }
        if (noteOption === "Tracking Note") {
            exportSelected.forEach(s => {
                db.collection("shipments").doc(s.hawb).update({
                    note: note,
                })
                setAlertSuccess(true);
                setLoading(false);
            })
        }
        if (noteOption === "Accountant Note") {
            exportSelected.forEach((s) => {
                axios.put("https://test.skydart.com.vn/api/accountant/note", { hawb: s.hawb, accountNote: note }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                    setSelectedShipment(shipments.map(val => {
                        let noteValue = note || s.accNote;
                        if (val.hawb === s.hawb) {
                            val.accNote = noteValue;
                        }
                        return val;
                    }))
                })
            })
        }
        if (noteOption === "Courier Note") {
            exportSelected.forEach((s) => {
                axios.put("https://test.skydart.com.vn/api/courier/note", { hawb: s.hawb, gnNote: note }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                    setSelectedShipment(shipments.map(val => {
                        let noteValue = note || s.gnNote;
                        if (val.hawb === s.hawb) {
                            val.gnNote = noteValue;
                        }
                        return val;
                    }))
                })
            })
        }
    }

    const submitRate = () => {
        setLoadingRate(true);
        exportSelected.forEach(s => {
            axios.put("https://test.skydart.com.vn/api/update/rate", {
                rate: rateInput || s.rate,
                fsc: fuel || s.fsc,
                fscValue: fuelValue,
                comestic: comesticInput || s.comestic,
                remote: remoteInput || s.remote,
                addressCorrection: addressCorrectionInput || s.addressCorrection,
                vat: vatInput || s.vat,
                vatValue: vatValue,
                surcharge: surchargeInput || s.surcharge,
                surcharge2: surchargeInput2 || s.surcharge2,
                surcharge3: surchargeInput3 || s.surcharge3,
                sellNote: sellNoteInput || s.sellNote,
                totalValue: "",
                rateUpdate: userData.displayName,
                com: comInput || s.com,
                hawb: s.hawb,
            }).then(() => {
                setLoadingRate(false);
                setRateInput("");
                setComesticInput("");
                setRemoteInput("");
                setAddressCorrectionInput("");
                setVatValueInput("");
                setSurchargeInput("");
                setSurchargeInput2("");
                setSurchargeInput3("");
                setSellNoteInput("");
                setVatInput("");
                setDisplayPricing(false);
            }).then(() => {
                setAlertSuccess(true);
            })
        })
    }


    const updateShipment = () => {
        setLoadShipment(true);
        if (status === "Pending") {
            createShipment();
        } else if (status !== "Pending") {
            createShipment();
            editShipment();
        }
    }

    const updateDox = () => {
        setLoadShipment(true);
        if (status === "Pending") {
            createDox();
        } else if (status !== "Pending") {
            createDox();
            editShipment();
        }
    }

    const editShipment = () => {
        axios.put("https://test.skydart.com.vn/api/update/shipment/service", {
            hawb: hawb,
            service: service1,
            eamFormat: type === "DOX" ? "DOX" : "NDX",
            code: code,
            value: totalInvoiceValue,
            company: importer,
            type: type,
            country: country,
            fromCity: fromCountry,
            state: state,
            town: town,
            city: city,
            address: address,
            address2: address2,
            address3: address3,
            postal: postal,
            phone: phone,
            contact: toName,
            chargeable: type === "DOX" ? doxWeight : finalCharge,
            eamService: eamService,
        })
    }
    // const testAttach = shipments.filter((s) => {
    //     return s.makh === "P438188472";
    // })
    // const updateAttach = () => {
    //     testAttach.forEach((s) => {

    //     })
    // }
    const createDox = () => {
        setLoadShipment(true);
        if (doxWeight < 0.5) {
            alert("Your document can not be less than 0.5kg");
            setDoxWeight(0.5);
            setDoxErr(true);
            setLoadShipment(false);
        } else {
            setDoxErr(false);
        }
        if (doxWeight > 2) {
            alert("Your document can not be more than 2kg");
            setDoxWeight(0.5);
            setDoxErr(true);
            setLoadShipment(false);
        } else {
            setDoxErr(false);
        }
        if (doxWeight >= 0.5 && doxWeight <= 2) {
            db.collection("shipments").doc(hawb).update({
                shipmentType: type,
                service: service1,
                controller: controller,
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                code: code,
                value: totalInvoiceValue,
                toPhone2: "",
                toCountry: country,
                fromCountry: fromCountry,
                fromCity: fromCity,
                importer: importer,
                toCity: city,
                toState: state,
                toTown: town,
                toAddress: address,
                toAddress2: address2,
                toAddress3: address3,
                toPostal: postal,
                toPhone: phone,
                toName,
                toEmail: email,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge,
                reweight: type === "DOX" ? doxWeight : finalCharge,
                weight: type === "DOX" ? doxWeight : finalGross,
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                description: "Document",
            }).then(() => {
                next();
            })
        }
    }
    const createShipment = () => {
        let arr = [];
        invoiceValue.map((s) => {
            arr.push(s.itemCommonity);
        })
        setLoadShipment(true);
        setTimeout(() => {
            db.collection("shipments").doc(hawb).update({
                shipmentType: type,
                service: service1,
                controller: controller,
                eamFormat: type === "DOX" ? "DOX" : "NDX",
                code: code,
                value: totalInvoiceValue,
                toPhone2: "",
                fromCountry: fromCountry,
                fromCity: fromCity,
                toCountry: country,
                importer: importer,
                toCity: city,
                toState: state,
                toTown: town,
                toAddress: address,
                toAddress2: address2,
                toAddress3: address3,
                toPostal: postal,
                toPhone: phone,
                toName,
                toEmail: email,
                invoiceValue: type === "DOX" ? "Pending" : invoiceValue,
                packageValue: type === "DOX" ? "Pending" : packageValue,
                totalCharge: type === "DOX" ? doxWeight : finalCharge,
                reweight: type === "DOX" ? doxWeight : finalCharge,
                weight: type === "DOX" ? doxWeight : finalGross,
                totalPackage: type === "DOX" ? envelope : packageValue.length,
                totalItem: type === "DOX" ? envelope : invoiceValue.length,
                eori: eori,
                description: type === "DOX" ? "Document" : arr + ",",
                term: type === "DOX" ? "Document" : term,
                transportation: transport,
                totalItemValue: finalInvoiceValue.toFixed(2).toString(),
                totalInvoiceValue: totalInvoiceValue.toFixed(2).toString(),
                usage: reason,
            }).then(() => {
                next();

            })
        }, 1000)
    }
    const next = () => {
        setLoadShipment(false);
        if (currentState + 1 === selection.length) {
            closeEdit();
            refreshShipment();
        } else {
            setAlertUpdate(true);
            setCurrentState(currentState + 1);
            setSelectedShipment(selection.map(val => {
                let updated = "true";
                if (val.hawb === hawb) {
                    val.updated = updated;
                    setHawb(selection[currentState + 1].hawb);
                }
                return val;
            }))
        }
    }

    const refreshFlight = () => {
        axios.get("https://test.skydart.com.vn/api/flight/info").then((res) => {
            setFlights(res.data);
        })
    }
    const handleFlightForm = (e) => {
        e.preventDefault();
        axios.post("https://test.skydart.com.vn/api/flight/info", {
            flight: flightInput,
            mawb: MAWBInput,
            createAt: traceTime,
            date: new Date(),
        })
        alert("Alert Succeed");
        setMAWBInput("");
        setFlightInput("");
        refreshFlight();
    }

    const todayFlight = flights.filter((s) => {
        return s.createAt === traceTime;
    })
    const onUpdateAll = (e) => {
        e.preventDefault();
        setDisplayEdit(false);
        setLoading(true);
        exportSelected.forEach(s => {
            db.collection("shipments").doc(s.hawb).update({
                sin: sin || s.sin,
            })
            axios.put("https://test.skydart.com.vn/api/update/all", {
                hawb: s.hawb,
                mawb: mawbInput || s.mawb,
                sin: sin || s.sin,
                airline: flight || s.airline,
                agent: agent || s.agent,
                service: service || s.service,
                pack: reweight || s.pack,
            }).then(() => {
                setSelectedShipment(shipments.map(val => {
                    let mawbValue = mawbInput || s.mawb;
                    let sinValue = sin || s.sin;
                    let airlineValue = flight || s.airline;
                    let agentValue = agent || s.agent;
                    let serviceValue = service || s.service;
                    let packValue = reweight || s.pack;
                    if (val.hawb === s.hawb) {
                        val.mawb = mawbValue;
                        val.sin = sinValue;
                        val.airline = airlineValue;
                        val.agent = agentValue;
                        val.service = serviceValue;
                        val.pack = packValue;

                    }
                    return val;
                }))
                setLoading(false);
                setAlertSuccess(true);
            }).then(() => {
                setMawbInput("");
                setReweight("");
                setService("");
                setSin("");
                setFlight("");
                setAgent("");
            })
        })
    }


    const roleBase = shipments.filter((s) => {
        if (userData.role === "Sales") {
            return s.controller === userData.displayName;
        } else {
            return s;
        }
    })

    const finalShipment = roleBase.filter((s, i) => {
        let plan = s.plan || "";
        let rate = s.rate;
        let addressCorrect = s.addressCorrection;
        let remote = s.remote;
        let surcharge = s.surcharge;
        let fsc = s.fsc;
        let fscValue = s.fscValue;
        let surcharge2 = s.surcharge2;
        let vat = s.vat;
        let vatValue = s.vatValue;
        let accountNote = s.accountNote || "";
        let pickupNote = s.pickupNote || "";
        let gnNote = s.gnNote || "";
        let surcharge3 = s.surcharge3;
        return s.sender.toLowerCase().includes(searchCus.toLowerCase()) &&
            s.pack.toLowerCase().includes(searchPack.toLowerCase()) &&
            plan.toLowerCase().includes(searchClass.toLowerCase()) &&
            s.country.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
            s.fromCity.toLowerCase().startsWith(searchFrom.toLowerCase()) &&
            s.type.toLowerCase().includes(searchType.toLowerCase()) &&
            s.code.toLowerCase().startsWith(searchCode.toLowerCase()) &&
            s.amount.toLowerCase().includes(searchPcs.toLowerCase()) &&
            s.airline.toLowerCase().includes(searchAirline.toLowerCase()) &&
            s.service.toLowerCase().includes(searchService.toLowerCase()) &&
            s.agent.toLowerCase().includes(searchAgent.toLowerCase()) &&
            s.mawb.toLowerCase().includes(searchMawb.toLowerCase()) &&
            s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
            // addressCorrect.toLowerCase().includes(searchAddress.toLowerCase()) &&
            // s.vat.toLowerCase().startsWith(searchVat.toLowerCase()) &&
            // remote.toLowerCase().startsWith(searchRemote.toLowerCase()) &&
            // surcharge.toLowerCase().startsWith(searchSurcharge.toLowerCase()) &&
            // fsc.toLowerCase().startsWith(searchFsc.toLowerCase()) &&
            // s.comestic.toLowerCase().startsWith(searchComestic.toLowerCase()) &&
            // fscValue.toLowerCase().startsWith(searchFscValue.toLowerCase()) &&
            // surcharge2.toLowerCase().startsWith(searchSurcharge2.toLowerCase()) &&
            // vatValue.toLowerCase().startsWith(searchVatValue.toLowerCase()) &&
            // surcharge3.toLowerCase().startsWith(searchSurcharge3.toLowerCase()) &&
            s.controller.toLowerCase().includes(searchSale.toLowerCase()) &&
            s.paymentPickup.toLowerCase().endsWith(searchPickup.toLowerCase()) &&
            s.paymentAcc.toLowerCase().endsWith(searchAcc.toLowerCase()) &&
            s.paymentCompany.toLowerCase().endsWith(searchCompany.toLowerCase()) &&
            s.status.toLowerCase().includes(status.toLowerCase()) &&
            s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) &&
            s.sin.toLowerCase().includes(findSin.toLowerCase()) &&
            s.mawb.toLowerCase().includes(findMawb.toLowerCase()) &&
            s.note.toLowerCase().includes(searchNote.toLowerCase()) &&
            s.sellNote.toLowerCase().includes(searchSellNote.toLowerCase()) &&
            accountNote.toLowerCase().includes(searchAccNote.toLowerCase()) &&
            gnNote.toLowerCase().includes(searchGnNote.toLowerCase()) &&
            pickupNote.toLowerCase().includes(searchPickupNote.toLowerCase()) &&
            s.fromCity.toLowerCase().includes(searchCity.toLowerCase()) &&
            s.exactWeight.toLowerCase().startsWith(searchExactWeight.toLowerCase());
    })
    const getClass = finalShipment.filter((s) => {
        if (searchClass !== "") {
            return s.plan === searchClass;
        } else {
            return s;
        }
    })
    const getTrueWeight = getClass.filter((s) => {
        if (searchWeight !== "") {
            return s.chargeable === searchWeight;
        } else {
            return s;
        }
    })
    const getRate = getTrueWeight.filter((s) => {
        if (searchRate !== "") {
            return s.rate === searchRate;
        } else {
            return s;
        }
    })
    const getSurcharge = getRate.filter((s) => {
        if (searchSurcharge !== "") {
            return s.surcharge === searchSurcharge;
        } else if (searchFsc !== "") {
            return s.fsc === searchFsc;
        } else if (searchComestic !== "") {
            return s.comestic === searchComestic;
        } else if (searchFscValue !== "") {
            return s.fscValue === searchFscValue;
        } else if (searchSurcharge2 !== "") {
            return s.surcharge2 === searchSurcharge2;
        } else if (searchVatValue !== "") {
            return s.fscValue === searchVatValue;
        } else if (searchSurcharge3 !== "") {
            return s.surcharge3 === searchSurcharge3;
        } else if (searchRemote !== "") {
            return s.remote === searchRemote;
        } else if (searchVat !== "") {
            return s.vat === searchVat;
        } else if (searchAddress !== "") {
            return s.addressCorrection === searchAddress;
        }
        else {
            return s;
        }
    })


    const getWeight = getSurcharge.filter((s) => {
        if (searchReweight !== "") {
            return s.reweight === searchReweight;
        } else {
            return s;
        }
    })



    const pages = [];
    for (let i = 1; i <= Math.ceil(getWeight.length / itemPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentShipment = getWeight.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (e) => {
        setCurrentPage(Number(e.target.id));
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
    }
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
    }
    const handleMorePage = () => {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    const handleLessPage = () => {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageLimit + 1 && number > minPageLimit) {
            return (
                <li key={number} id={number} onClick={handleChangePage} className={currentPage == number ? "active" : null}>
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })
    const sortedCus1 = [...new Set(getWeight.map(s => s.sender))];
    const sortedCus2 = [...new Set(shipments.map(s => s.sender))];
    const sortedCountry1 = [...new Set(getWeight.map(s => s.country))];
    const sortedCountry2 = [...new Set(shipments.map(s => s.country))];
    const sortedStatus = [...new Set(shipments.map(s => s.status))];
    const sortedType = [...new Set(shipments.map(s => s.type))];
    const sortedWeight = [...new Set(shipments.map(s => s.chargeable))];
    const sortedReweight = [...new Set(shipments.map(s => s.reweight))];
    const sortedPcs = [...new Set(shipments.map(s => s.amount))];
    const sortedPack = [...new Set(shipments.map(s => s.pack))];
    const sortedAirline = [...new Set(shipments.map(s => s.airline))];
    const sortedService = [...new Set(shipments.map(s => s.service))];
    const sortedAgent = [...new Set(shipments.map(s => s.agent))];
    const sortedMawb = [...new Set(shipments.map(s => s.mawb))];
    const sortedSin = [...new Set(shipments.map(s => s.sin))];
    const sortedRate = [...new Set(shipments.map(s => s.rate))];
    const sortedExtra = [...new Set(shipments.map(s => s.comestic))];
    const sortedAddress = [...new Set(shipments.map(s => s.addressCorrection))];
    const sortedRemote = [...new Set(shipments.map(s => s.remote))];
    const sortedExtra1 = [...new Set(shipments.map(s => s.surcharge))];
    const sortedExtra2 = [...new Set(shipments.map(s => s.surcharge2))];
    const sortedExtra3 = [...new Set(shipments.map(s => s.surcharge3))];
    const sortedFsc = [...new Set(shipments.map(s => s.fsc))];
    const sortedFscValue = [...new Set(shipments.map(s => s.fscValue))];
    const sortedVat = [...new Set(shipments.map(s => s.vat))];
    const sortedVatValue = [...new Set(shipments.map(s => s.vatValue))];
    const sortedController = [...new Set(shipments.map(s => s.controller))];
    const sortedPaymentPickup = [...new Set(shipments.map(s => s.paymentPickup))];
    const sortedPaymentAccount = [...new Set(shipments.map(s => s.paymentAcc))];
    const sortedPaymentCompany = [...new Set(shipments.map(s => s.paymentCompany))];
    const sortedOpsNote = [...new Set(shipments.map(s => s.note))];
    const sortedSellNote = [...new Set(shipments.map(s => s.sellNote))];
    const sortedAccNote = [...new Set(shipments.map(s => s.accountNote))];
    const sortedCode = [...new Set(getWeight.map(s => s.code))];
    const sortedEmail = [...new Set(shipments.map(s => s.fromEmail))];
    const alphabetCountry = sortedCountry2.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetStatus = sortedStatus.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetType = sortedType.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetWeight = sortedWeight.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetReweight = sortedReweight.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetPcs = sortedPcs.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetPacking = sortedPack.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetAirline = sortedAirline.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetService = sortedService.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetAgent = sortedAgent.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetMawb = sortedMawb.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const alphabetSin = sortedSin.sort(function (a, b) {
        return a.localeCompare(b);
    })

    const sortedCustomer = sortedCus1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const sortedCustomer2 = sortedCus2.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const sortedCountry = sortedCountry1.sort(function (a, b) {
        return a.localeCompare(b);
    })
    const pickedupShipment = roleBase.filter((s, i) => {
        if (userData.role === "Sales") {
            return s.status === "Picked Up" && s.controller === userData.displayName;
        } else {
            return s.status === "Picked Up";
        }
    })
    const arrivedShipment = roleBase.filter((s, i) => {
        if (userData.role === "Sales") {
            return s.status === "Arrived At Skydart Origin Depot" && s.controller === userData.displayName;
        } else {
            return s.status === "Arrived At Skydart Origin Depot";
        }
    })
    const processShipment = roleBase.filter(s => {
        if (userData.role === "Sales") {
            return s.status === "In Process" && s.controller === userData.displayName;
        } else {
            return s.status === "In Process";
        }

    })
    const holdShipment = roleBase.filter(s => {
        if (userData.role === "Sales") {
            return s.status === "On Hold" && s.controller === userData.displayName;
        } else {
            return s.status === "On Hold";
        }

    })
    const clearanceShipment = roleBase.filter(s => {
        if (userData.role === "Sales") {
            return s.status === "In Clearance" && s.controller === userData.displayName;
        } else {
            return s.status === "In Clearance";
        }
    })
    const clearedShipment = roleBase.filter(s => {
        if (userData.role === "Sales") {
            return s.status === "Cleared For Export" && s.controller === userData.displayName;
        } else {
            return s.status === "Cleared For Export";
        }
    })
    const transitShipment = roleBase.filter(s => {
        if (userData.role === "Sales") {
            return s.status === "In Transit" && s.controller === userData.displayName;
        } else {
            return s.status === "In Transit";
        }

    })
    const deliveredShipment = roleBase.filter(s => {
        if (userData.role === "Sales") {
            return s.status === "Delivered" && s.controller === userData.displayName;
        } else {
            return s.status === "Delivered";
        }

    })

    const sortCustomer = () => {
        setDisplaySortCustomer(true);
    }
    const sortMawb = () => {
        setDisplaySortMawb(true);
    }


    const exportSelected = roleBase.filter((s) => {
        return s.select;
    })
    const agentReport = {
        data: exportSelected,
        headers: agentHeader,
        filename: excelFileName || "Skydart Shipment Export",

    }
    const dusReport = {
        data: exportSelected,
        headers: title,
        filename: excelFileName || "Skydart DUS Manifest Export",
    }
    const exportAll = {
        data: exportSelected,
        headers: headers,
        filename: excelFileName || "Skydart Shipment Export",
    }
    const duplicateExactWeight = [...new Set(getWeight.map(s => s.exactWeight))];
    const duplicateChargeable = [...new Set(getWeight.map(s => s.exactCharge))];
    const duplicatePickupNote = [...new Set(getWeight.map(s => s.pickupNote))];
    const duplicateGnNote = [...new Set(getWeight.map(s => s.gnNote))];
    const duplicateWeight = [...new Set(getWeight.map(s => s.chargeable))];
    const duplicateComestic = [...new Set(getWeight.map(s => s.comestic))];
    const duplicateReweight = [...new Set(getWeight.map(s => s.reweight))];
    const duplicatePcs = [...new Set(getWeight.map(s => s.amount))];
    const duplicateService = [...new Set(getWeight.map(s => s.service))];
    const duplicatePack = [...new Set(getWeight.map(s => s.pack))];
    const duplicateAirline = [...new Set(getWeight.map(s => s.airline))];
    const duplicateAgent = [...new Set(getWeight.map(s => s.agent))];
    const duplicateMawb = [...new Set(getWeight.map(s => s.mawb))];
    const duplicateSin = [...new Set(getWeight.map(s => s.sin))];
    const duplicateRate = [...new Set(getWeight.map(s => s.rate))];
    const duplicateAddress = [...new Set(getWeight.map(s => s.addressCorrection))];
    const duplicateRemote = [...new Set(getWeight.map(s => s.remote))];
    const duplicateExtra1 = [...new Set(getWeight.map(s => s.surcharge))];
    const duplicateExtra2 = [...new Set(getWeight.map(s => s.surcharge2))];
    const duplicateExtra3 = [...new Set(getWeight.map(s => s.surcharge3))];
    const duplicateFsc = [...new Set(getWeight.map(s => s.fsc))];
    const duplicateFscValue = [...new Set(getWeight.map(s => s.fscValue))];
    const duplicateVat = [...new Set(getWeight.map(s => s.vat))];
    const duplicateVatValue = [...new Set(getWeight.map(s => s.vatValue))];
    const duplicateSale = [...new Set(getWeight.map(s => s.controller))];
    const duplicateClass = [...new Set(getWeight.map(s => s.plan))];
    const duplicateClass1 = [...new Set(shipments.map(s => s.plan))];
    const duplicateNote = [...new Set(getWeight.map(s => s.note))];
    const duplicateSellNote = [...new Set(getWeight.map(s => s.sellNote))];
    const duplicateAccountNote = [...new Set(getWeight.map(s => s.accountNote))];
    const duplicatePaymentPickUp = [...new Set(getWeight.map(s => s.paymentPickup))];
    const duplicatePaymentAccountant = [...new Set(getWeight.map(s => s.paymentAcc))];
    const duplicatePaymentCompany = [...new Set(getWeight.map(s => s.paymentCompany))];
    const duplicateFromCity = [...new Set(shipments.map(s => s.fromCity))];
    const closeEdit = () => {
        setSelection([]);
        setHawb("");
        setDisplayEditScreen(false);
        setCurrentState(0);
    }
    const shipmentArrived = () => {
        setLoading(true);
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                status: "Arrived At Skydart Origin Depot",
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    time: traceTime + "T" + dateTime + ":00",
                    location: "VIETNAM - HO CHI MINH",
                    stat: "Shipment arrived at Skydart Origin Depot",
                })),
                receiveBy: userData.displayName,
            })
            axios.put("https://test.skydart.com.vn/api/update/arrived", { hawb: s.hawb, status: "Arrived At Skydart Origin Depot" }).then(() => {
                setLoading(false);
                setAlertSuccess(true);
            })
        })
    }
    const shipmentProcess = () => {
        setLoading(true);
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            if (s.status.includes("Arrived")) {
                db.collection("shipments").doc(s.hawb).update({
                    status: "In Process",
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        time: traceTime + "T" + dateTime + ":00",
                        location: "VIETNAM - HO CHI MINH",
                        stat: "Shipment is in process",
                    })),
                    processBy: userData.displayName,
                })
                axios.put("https://test.skydart.com.vn/api/update/process", { hawb: s.hawb, status: "In Process" }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                })
            } else {
                return;
            }

        })
    }
    const shipmentHold = () => {
        setLoading(true);
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                status: "On Hold",
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    time: traceTime + "T" + dateTime + ":00",
                    location: "VIETNAM - HO CHI MINH",
                    stat: "Shipment On Hold",
                })),
                holdBy: userData.displayName,
            })
            axios.put("https://test.skydart.com.vn/api/update/hold", { hawb: s.hawb, status: "On Hold" }).then(() => {
                setLoading(false);
                setAlertSuccess(true);
            })
        })
    }
    const shipmentClearance = () => {
        setLoading(true);
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            if (s.status === "In Process") {
                db.collection("shipments").doc(s.hawb).update({
                    status: "In Clearance",
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        time: traceTime + "T" + dateTime + ":00",
                        location: "VIETNAM - HO CHI MINH",
                        stat: "Shipment is in clearance at Tan Son Nhat airport",
                    })),
                    clearanceBy: userData.displayName,
                })
                axios.put("https://test.skydart.com.vn/api/update/clearance", { hawb: s.hawb, status: "In Clearance" }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                })
            } else {
                return;
            }

        })
    }
    const shipmentCleared = () => {
        setLoading(true);
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            if (s.status === "In Clearance") {
                db.collection("shipments").doc(s.hawb).update({
                    status: "Cleared For Export",
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        time: traceTime + "T" + dateTime + ":00",
                        location: "VIETNAM - HO CHI MINH",
                        stat: "Shipment cleared for export",
                    })),
                    clearedBy: userData.displayName,
                })
                axios.put("https://test.skydart.com.vn/api/update/transit", { hawb: s.hawb, status: "Cleared For Export" }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                })
            } else {
                return;
            }

        })
    }
    const returnShipment = () => {
        setLoading(true);
        setDisplayAction(false);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                status: "Pending",
                desc: firebase.firestore.FieldValue.arrayUnion(({
                    time: traceTime + "T" + dateTime + ":00",
                    location: "VIETNAM - HO CHI MINH",
                    stat: "Shipment returned to origin",
                    person: userData.displayName,
                })),
                returnAt: traceTime,
            }).then(() => {
                axios.delete(`https://test.skydart.com.vn/api/delete/${s.hawb}`).then(() => {
                    setLoading(false);
                });
            })
        })
    }
    const switchController = (e) => {
        e.preventDefault();
        setDisplayController(false);
        setLoading(true);
        exportSelected.forEach((s) => {
            axios.put("https://test.skydart.com.vn/api/update/controller", { hawb: s.hawb, controller: staffInput || "Pending" }).then(() => {
                setLoading(false);
                setAlertSuccess(true);
                setStaffInput("");
            })
        })
    }
    const updateMode = (e) => {
        e.preventDefault();
        setDisplayPayment(false);
        setLoading(true);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                paymentStatus: "Pay",
                accountant: userData.displayName,
            })
            axios.put("https://test.skydart.com.vn/api/update/shipment-paid", { hawb: s.hawb, paymentStatus: "Pay" });
            axios.put("https://test.skydart.com.vn/api/payment-mode", {
                hawb: s.hawb,
                paymentPickup: mode,
                paymentAcc: mode,
                collectBy: userData.displayName,
                paymentSubStatus: "Paid To Accountant",
            }).then(() => {
                setSelectedShipment(shipments.map(val => {
                    let paymentPickupValue = mode;
                    let paymentAccountValue = mode;
                    if (val.hawb === s.hawb) {
                        val.paymentPickup = paymentPickupValue;
                        val.paymentAcc = paymentAccountValue;
                    }
                    return val;
                }))
                setLoading(false);
                setAlertSuccess(true);
            }).then(() => {
                setMode("");
            })
        })

    }
    const updateAllMode = (e) => {
        e.preventDefault();
        setDisplayPayment(false);
        setLoading(true);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                paymentStatus: "Pay",
                collect: userData.displayName,
            })
            axios.put("https://test.skydart.com.vn/api/update/shipment-paid", { hawb: s.hawb, paymentStatus: "Pay" });
            axios.put("https://test.skydart.com.vn/api/payment/all", {
                hawb: s.hawb,
                paymentPickup: mode,
                paymentAcc: mode,
                paymentCompany: mode,
                collectBy: userData.displayName,
                paymentSubStatus: "Paid To Company"
            }).then(() => {
                setSelectedShipment(shipments.map(val => {
                    let paymentPickupValue = mode;
                    let paymentAccountValue = mode;
                    let paymentCompanyValue = mode;
                    if (val.hawb === s.hawb) {
                        val.paymentPickup = paymentPickupValue;
                        val.paymentAcc = paymentAccountValue;
                        val.paymentCompany = paymentCompanyValue;
                    }
                    return val;
                }))
                setLoading(false);
                setAlertSuccess(true);
            }).then(() => {
                setMode("");
            })
        })
    }
    const updatePrepaid = (e) => {
        e.preventDefault();
        setDisplayPayment(false);
        setLoading(true);
        exportSelected.forEach((s) => {
            db.collection("shipments").doc(s.hawb).update({
                paymentStatus: "Un Paid",
                collect: userData.displayName,
            })
            axios.put("https://test.skydart.com.vn/api/update/shipment-paid", { hawb: s.hawb, paymentStatus: "Un Paid" });
            axios.put("https://test.skydart.com.vn/api/payment/all", {
                hawb: s.hawb,
                paymentPickup: mode,
                paymentAcc: mode,
                paymentCompany: mode,
                collectBy: userData.displayName,
                paymentSubStatus: "Paid To Company"
            }).then(() => {
                setSelectedShipment(shipments.map(val => {
                    let paymentPickupValue = mode;
                    let paymentAccountValue = mode;
                    let paymentCompanyValue = mode;
                    if (val.hawb === s.hawb) {
                        val.paymentPickup = paymentPickupValue;
                        val.paymentAcc = paymentAccountValue;
                        val.paymentCompany = paymentCompanyValue;
                    }
                    return val;
                }))
                setLoading(false);
                setAlertSuccess(true);
            }).then(() => {
                setMode("");
            })
        })
    }

    const processTracking = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setTrackingResult(list)
    }
    const handleTrackingUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processTracking(data);
        };
        reader.readAsBinaryString(file);
    }

    ////

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setReweightResult(list);
    }
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);

        };
        reader.readAsBinaryString(file);
    }

    const updateBatchReweight = () => {
        setDisplayBatchReweight(false);
        setLoading(true);
        reweightResult.map((item) => {
            const hawbDoc = "SKD" + item.HAWB;
            db.collection("shipments").doc(hawbDoc).update({
                reweight: item.Chargeable,
            }).then(() => {
                axios.put("https://test.skydart.com.vn/api/reweight", { hawb: hawbDoc, reweight: item.Chargeable }).then(() => {
                    setLoading(false);
                    setAlertSuccess(true);
                    setReweightResult([]);
                })
            })

        })
    }

    const updateBatchTracking = () => {
        setDisplayBatchTracking(false);
        setLoading(true);
        trackingResult.map((item) => {
            const hawbDoc = "SKD" + item.HAWB;
            db.collection("shipments").doc(hawbDoc).update({
                sin: item.TrackingNumber,
            }).then(() => {
                axios.put("https://test.skydart.com.vn/api/update/tracking", { hawb: hawbDoc, sin: item.TrackingNumber }).then(() => {
                    setTrackingResult([]);
                    setLoading(false);
                    setAlertSuccess(true);
                })
            })
        })
    }
    const handleHawb = (e) => {
        setSearchHawb(e.target.value);
        setCurrentPage(1);
    }
    const handleMawb = (e) => {
        setSearchMawb(e.target.value);
        setCurrentPage(1);
    }
    const totalRate = getWeight.reduce((a, v) => a = +a + +v.rate, 0);
    const totalWeight = getWeight.reduce((a, v) => a = +a + +v.chargeable, 0);
    const totalExactCharge = getWeight.reduce((a, v) => a = +a + +v.exactCharge, 0);
    const totalExactWeight = getWeight.reduce((a, v) => a = +a + +v.exactWeight, 0);
    const totalPieces = getWeight.reduce((a, v) => a = +a + +v.amount, 0);
    const totalReweight = getWeight.reduce((a, v) => a = +a + +v.reweight, 0);
    const totalPrice = getWeight.reduce((a, v) => a = +a + +v.totalValue, 0);
    const totalComestic = getWeight.reduce((a, v) => a = +a + +v.comestic, 0);
    const totalAddress = getWeight.reduce((a, v) => a = +a + +v.addressCorrection, 0);
    const totalRemote = getWeight.reduce((a, v) => a = +a + +v.remote, 0);
    const totalSurcharge = getWeight.reduce((a, v) => a = +a + +v.surcharge, 0);
    const totalFscValue = getWeight.reduce((a, v) => a = +a + +v.fscValue, 0);
    const totalSurcharge2 = getWeight.reduce((a, v) => a = +a + +v.surcharge2, 0);
    const totalVatValue = getWeight.reduce((a, v) => a = +a + +v.vatValue, 0);
    const totalSurcharge3 = getWeight.reduce((a, v) => a = +a + +v.surcharge3, 0);
    const day = new Date();
    const hour1 = (day.getHours() < 10 ? "0" : "") + day.getHours();
    const min1 = (day.getMinutes() < 10 ? "0" : "") + day.getMinutes();
    const etdHour = +hour1.substring(0, 2) + 2;
    const finalEtd = `${etdHour}:${min1}`;


    const handleSurcharge = () => {
        setDisplaySurcharge(false);
        setLoading(true);
        db.collection("surcharge").doc("skd-surcharge").set({
            dhlFsc: dhlFsc || fetchDhl,
            fedexFsc: fedexFsc || fetchFedex,
            otherFsc: otherFsc || fetchOther,
            lastUpdate: userData.displayName,
        }).then(() => {
            setDhlFsc("");
            setFedexFsc("");
            setOtherFsc("");
            setAlertSuccess(true);
            setLoading(false);
            setRemote("");
            setAddressCorrect("");
        })
    }
    const markRemote = () => {
        setDisplayAction(false);
        setLoading(true);
        exportSelected.forEach((s) => {
            axios.put("https://test.skydart.com.vn/api/remote/mark", {
                hawb: s.hawb,
                remote: fetchRemote,
                lastUpdate: userData.displayName,
            }).then(() => {
                db.collection("shipments").doc(s.hawb).update({
                    isRemote: "true",
                })
                setSelectedShipment(shipments.map(val => {
                    let remoteValue = fetchRemote;
                    if (val.hawb === s.hawb) {
                        val.remote = remoteValue;
                    }
                    return val;
                }))
                setLoading(false);
                setAlertSuccess(true);
            })
        })
    }
    const markWrongAddress = () => {
        setDisplayAction(false);
        setLoading(true);
        exportSelected.forEach((s) => {
            axios.put("https://test.skydart.com.vn/api/address/mark", {
                hawb: s.hawb,
                addressCorrection: fetchAddressCorrect,
                updateAddress: userData.displayName,
            }).then(() => {
                setSelectedShipment(shipments.map(val => {
                    let addressValue = fetchAddressCorrect;
                    if (val.hawb === s.hawb) {
                        val.addressCorrection = addressValue;
                    }
                    return val;
                }))
                setLoading(false);
                setAlertSuccess(true);
            })
        })
    }
    const markBoth = () => {
        markRemote();
        markWrongAddress();
    }

    const selectedTracing = exportSelected.slice(0, 100);
    const handleTracing = () => {
        setDisplayTracingOption(false);
        setDisplayTracking(true);
        selectedTracing.forEach((s) => {
            if (s.status === "Cleared For Export") {
                db.collection("shipments").doc(s.hawb).update({
                    status: "In Transit",
                    desc: firebase.firestore.FieldValue.arrayUnion(({
                        time: traceTime + "T" + finalEtd + ":00",
                        location: "SINGAPORE - SINGAPORE",
                        stat: "Shipment in transit",
                    }))
                })
                axios.put("https://test.skydart.com.vn/api/update/hub", { hawb: s.hawb, status: "In Transit" });
            } else {
                console.log("Shipment chưa bay hoặc đã bay rồi");
            }
        })
        setSelectTracing(selectedTracing);
    }



    const onTransitShipment = () => {
        return;
    }
    const handleCancelDate = () => {
        setDisplayDateRange(false);
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const d = new Date(dateRange.startDate);
    const selectMonth = ((d.getMonth() + 1) < 10 ? "0" : "") + (d.getMonth() + 1);
    const selectDate = (d.getDate() < 10 ? "0" : "") + d.getDate();
    const selectYear = d.getFullYear();


    const e = new Date(dateRange.endDate);
    const selectEndMonth = ((e.getMonth() + 1) < 10 ? "0" : "") + (e.getMonth() + 1);
    const selectEndDate = (e.getDate() < 10 ? "0" : "") + e.getDate();
    const selectEndYear = e.getFullYear();

    const handleApplyDate = () => {
        if (!dateRange.endDate) {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setDisplayDateRange(false);
            setDateStatus("Period");
        } else {
            setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
            setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
            setDisplayDateRange(false);
            setDateStatus("Period");
        }
    }
    const handleToday = () => {
        setStartDate(`${year}-${month}-${date}`)
        setEndDate(`${year}-${month}-${date}`)
        setDisplayDateRange(false);
        setDateStatus("Today");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleYesterday = () => {
        if (yesterday === 0) {
            setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
            setDisplayDateRange(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        } else {
            setStartDate(`${year}-${month}-${yesterday}`);
            setEndDate(`${year}-${month}-${yesterday}`);
            setDisplayDateRange(false);
            setDateStatus("Yesterday");
            setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
        }
    }
    const handleThisWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
        setDisplayDateRange(false);
        setDateStatus("This week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastWeek = () => {
        setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
        setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
        setDisplayDateRange(false);
        setDateStatus("Last week");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const currentYear = new Date().getFullYear();
    const handleThisMonth = () => {
        setStartDate(`${year}-${month}-${first}`)
        setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split("T")[0]);
        setDisplayDateRange(false);
        setDateStatus("This month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleLastMonth = () => {
        setStartDate(`${year}-${prevMonth}-01`)
        setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
        setDisplayDateRange(false);
        setDateStatus("Last month");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const handleThisYear = () => {
        setStartDate(`${year}-01-01`);
        setEndDate(`${year}-12-31`);
        setDateStatus("This year");
        setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE })
    }
    const convertThoudsand = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
    }
    const showFilter = () => {
        setDisplayFilterOption(true);
    }
    const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    // reTransit();
    // onTransitShipment();
    const onCopyText = () => {
        setDisplayCopy(true);
    }
    const pendingShipment = shipments.filter((s) => {
        return s.status === "Pending";
    })
    const deletePending = () => {
        pendingShipment.forEach((s) => {
            axios.delete(`https://test.skydart.com.vn/api/delete/${s.hawb}`);
        })
    }
    return (
        <div className="skydart-nav-link">
            {/* <button onClick={deletePending}>Delete</button> */}
            <div ref={printAllRef}>
                <div className="skydart-booking-hawb1" ref={labelRef}>
                    <div className="printing3">
                        <StaffBill2
                            hawb={hawb}
                            exporter={exporter}
                            fromAddress={fromAddress}
                            fromAddress2={fromAddress2}
                            fromAddress3={fromAddress3}
                            fromState={fromState}
                            fromTown={fromTown}
                            fromCity={fromCity}
                            fromPostal={fromPostal}
                            fromCountry={fromCountry}
                            fromPhone={fromPhone}
                            fromName={fromName}
                            fromEmail={fromEmail}
                            importer={importer}
                            toAddress={address}
                            toAddress2={address2}
                            toAddress3={address3}
                            toState={state}
                            toTown={town}
                            toCity={city}
                            toPostal={postal}
                            toCountry={country}
                            toPhone={phone}
                            toName={toName}
                            toEmail={email}
                            amount={packageValue.length}
                            weight={type === "WPX" ? (Math.round(finalGross * 100) / 100).toFixed(2) : (Math.round(doxWeight * 100) / 100).toFixed(2)}
                            chargeable={type === "WPX" ? (Math.round(finalCharge * 100) / 100).toFixed(2) : (Math.round(doxWeight * 100) / 100).toFixed(2)}
                            type={type}
                            date={createAt}
                            exportSelected={selectPrint}
                            description={description1}
                        />
                    </div>
                </div>
                <div className="skydart-booking-hawb1" ref={invoiceRef}>
                    {type === "WPX" && <Inv2
                        invoice={invoiceValue}
                        hawb={hawb}
                        datePicker={createAt}
                        fromCountry={fromCountry}
                        toCountry={country}
                        usage={reason}
                        exporter={exporter}
                        fromAddress={fromAddress}
                        fromAddress2={fromAddress2}
                        fromAddress3={fromAddress3}
                        fromTown={fromTown}
                        fromState={fromState}
                        fromCity={fromCity}
                        fromPostal={fromPostal}
                        fromName={fromName}
                        fromPhone={fromPhone}
                        fromEmail={fromEmail}
                        mst={mst}
                        importer={importer}
                        toAddress={address}
                        toAddress2={address2}
                        toAddress3={address3}
                        toState={state}
                        toPostal={postal}
                        toTown={town}
                        toCity={city}
                        toName={toName}
                        toPhone={phone}
                        toEmail={email}
                        eori={eori}
                        transportation={transport}
                        exportSelected={selectPrint}
                        totalGross={(Math.round(finalGross * 100) / 100).toFixed(2)}
                        totalPackage={packageValue.length}
                        totalItemValue={(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)}
                        totalInvoiceValue={(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)}
                    />}
                </div>
                {type === "WPX" && <div className="skydart-booking-hawb1" ref={shippingMarkRef}>
                    {packageValue.map((item, index) => (
                        <div className="printing4">
                            <ShippingMark no={index + 1} date={createAt} length={item.packageLength} width={item.packageWidth} height={item.packageHeight} total={packageValue.length} hawb={hawb} weight={item.packageWeight} chargeable={item.packageCharge} importer={importer} address={address} address2={address2} address3={address3} city={city} state={state} town={town} postal={postal} country={country} phone={phone} contact={toName} />
                        </div>
                    ))}
                </div>}
            </div>
            {displayCopy && <Snackbar open={displayCopy} autoHideDuration={5000} onClose={resetCopy}>
                <MuiAlert elevation={5} variant="filled" onClose={resetCopy} severity="success">
                    Copied {copyText}
                </MuiAlert>
            </Snackbar>}
            {displayEditScreen && <div className="skd-edit-new-booking">
                <Snackbar open={alertUpdate} autoHideDuration={1000} onClose={resetUpdate}>
                    <MuiAlert elevation={1} variant="filled" onClose={resetUpdate} severity="success">
                        Shipment has been updated successfully
                    </MuiAlert>
                </Snackbar>
                <div className="skd-edit-new-booking-container">
                    <div className="skd-edit-new-booking-header" style={{ zIndex: 9999 }}>
                        <h5>Edit shipment</h5>
                        {loadShipment && <div className="skd-edit-new-booking-loading">
                            <CircularProgress size={25} style={{ padding: 5, color: "#0178da" }} />
                        </div>}
                        <Clear className="skd-edit-new-booking-header-icon" onClick={closeEdit} />
                    </div>
                    <div className="skd-edit-new-booking-body">
                        <div className="skd-edit-new-booking-left">
                            <h5 style={{ marginTop: 5, fontSize: 11, marginLeft: 0, marginBottom: 5, fontWeight: 600 }}>Selecting:</h5>
                            {selection.map((s, index) => (
                                <div key={index}>
                                    {s.updated === "false" && <div className={hawb === s.hawb ? "skd-edit-new-booking-left-option" : "skd-edit-new-booking-left-option1"} onClick={hawb === s.hawb ? () => { return } : () => { setHawb(s.hawb); setCurrentState(index) }} key={index}>
                                        <h5>{s.hawb}</h5>
                                        {hawb === s.hawb && <FiEdit style={{ marginLeft: "auto" }} />}
                                    </div>}
                                    {s.updated === "true" && <div className="skd-edit-new-booking-left-option3" onClick={hawb === s.hawb ? () => { return } : () => { setHawb(s.hawb); setCurrentState(index) }} key={index}>
                                        <h5>{s.hawb}</h5>
                                        {hawb === s.hawb ? <FiEdit style={{ marginLeft: "auto" }} /> : <FcOk style={{ marginLeft: "auto" }} />}
                                    </div>}
                                </div>
                            ))}
                            <div className="skd-edit-new-booking-left-option2">
                                <LocalShipping style={{ fontSize: 18 }} />
                                <h5>{pickup || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <CallReceived style={{ fontSize: 18 }} />
                                <h5>{arrive || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <IoBuildOutline style={{ fontSize: 18 }} />
                                <h5>{process || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <PanTool style={{ fontSize: 18 }} />
                                <h5>{hold || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <MdOutlineLayersClear style={{ fontSize: 18 }} />
                                <h5>{clearance || "Pending..."}</h5>
                            </div>
                            <div className="skd-edit-new-booking-left-option2">
                                <Package size={18} />
                                <h5>{clear || "Pending..."}</h5>
                            </div>
                            <div className="skd-shipment-dus-label">
                                {dusValue?.length !== 0 && dusValue?.map((s, index) => (
                                    <div style={{ padding: 5 }} key={index}>
                                        <a href={s.url} target="_blank">{s.tracking_number}</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="skd-edit-new-booking-center">
                            <div className="skd-edit-new-booking-center-container">
                                <div className="skd-edit-new-booking-center-type">
                                    <h5 style={{ fontWeight: 600 }}>Editing: <strong>{hawb}</strong></h5>
                                    <select value={type} onChange={(e) => setType(e.target.value)}>
                                        <option value="WPX">WPX</option>
                                        <option value="DOX">DOX</option>
                                    </select>
                                </div>
                                <div className="skd-edit-new-booking-center-shipping-info">
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Country (Sender/Recipient)</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div ref={displayEditScreen ? fromCountryRef : null} style={{ position: "relative" }}>
                                            <div className="skd-edit-new-booking-country">
                                                <h5>Ship From</h5>
                                                <input type="text" value={fromCountry} onChange={(e) => setFromCountry(e.target.value)} onClick={() => setDisplayFromCountry(true)} />
                                            </div>
                                            {displayFromCountry && <div className="skd-edit-new-booking-country1">
                                                {countries.filter((s) => {
                                                    if (s.label.toLowerCase().startsWith(fromCountry.toLowerCase())) {
                                                        return s;
                                                    }
                                                }).map((s, index) => (
                                                    <div className="skd-edit-new-booking-country-box" key={index} onClick={() => {
                                                        setFromCountry(s.label);
                                                        setDisplayFromCountry(false);
                                                    }}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                        <h5>{s.label}</h5>
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>
                                        <div ref={displayEditScreen ? toCountryRef : null} style={{ position: "relative" }}>
                                            <div className="skd-edit-new-booking-country">
                                                <h5>Ship To</h5>
                                                <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} onClick={() => setDisplayToCountry(true)} />
                                            </div>
                                            {displayToCountry && <div className="skd-edit-new-booking-country1">
                                                {countries.filter((s) => {
                                                    if (s.label.toLowerCase().startsWith(country.toLowerCase())) {
                                                        return s;
                                                    }
                                                }).map((s, index) => (
                                                    <div key={index} className="skd-edit-new-booking-country-box" onClick={() => {
                                                        setCountry(s.label);
                                                        setCode(s.code);
                                                        setDisplayToCountry(false);
                                                    }}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                        <h5>{s.label}</h5>
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Recipient Information</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Company Name (Importer)</h5>
                                            <input type="text" value={importer} onChange={(e) => setImporter(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address</h5>
                                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 2</h5>
                                            <input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 3</h5>
                                            <input type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Town</h5>
                                            <input type="text" value={town} onChange={(e) => setTown(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>City</h5>
                                            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>State</h5>
                                            <input type="text" value={state} onChange={(e) => setState(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Postal Code</h5>
                                            <input type="text" value={postal} onChange={(e) => setPostal(e.target.value)} maxLength={30} />
                                        </div>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Recipient Contact</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Contact Name</h5>
                                            <input type="text" value={toName} onChange={(e) => setToName(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Phone Number</h5>
                                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={30} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Email Address</h5>
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>

                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Sender Information</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Company Name (Exporter)</h5>
                                            <input type="text" disabled value={exporter} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address</h5>
                                            <input type="text" disabled value={fromAddress} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 2</h5>
                                            <input type="text" disabled value={fromAddress2} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Address 3</h5>
                                            <input type="text" disabled value={fromAddress3} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Town</h5>
                                            <input type="text" disabled value={fromTown} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>City</h5>
                                            <input type="text" value={fromCity} onChange={(e) => setFromCity(e.target.value)} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>State</h5>
                                            <input type="text" disabled value={fromState} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Postal Code</h5>
                                            <input type="text" disabled value={fromPostal} />
                                        </div>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-header">
                                        <h5>Sender Contact</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-center-shipping-info-body">
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Contact Name</h5>
                                            <input type="text" disabled value={fromName} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Phone Number</h5>
                                            <input type="text" disabled value={fromPhone} />
                                        </div>
                                        <div className="skd-edit-new-booking-country">
                                            <h5>Email Address</h5>
                                            <input type="text" disabled value={fromEmail} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="skd-edit-new-booking-right">
                            <div className="skd-edit-new-booking-right-header">
                                <h5>Current Status: {stat}</h5>
                            </div>
                            <div className="skd-edit-new-booking-right-packing">
                                <div className="skd-edit-new-booking-pack-main">
                                    <h5>Packing Information</h5>
                                    <GrApps />
                                </div>
                                {type === "WPX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total packages:</h5>
                                        <h5>{packageValue.length} PCS</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total gross weight:</h5>
                                        <h5>{(Math.round(finalGross * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total chargeable</h5>
                                        <h5>{(Math.round(finalCharge * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                </div>}
                                {type === "DOX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total number of envelope:</h5>
                                        <h5>{envelope}</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total gross weight:</h5>
                                        <h5>{(Math.round(doxWeight * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total chargeable</h5>
                                        <h5>{(Math.round(doxWeight * 100) / 100).toFixed(2)} KG</h5>
                                    </div>
                                </div>}
                                {type === "WPX" && <div className="skd-edit-new-booking-right-dim">
                                    <div className="skd-edit-new-booking-right-dim1" style={{ justifyContent: "space-between" }}>
                                        <div className="skd-new-booking-left-dim">
                                            <h5>Weight:</h5>
                                            {/* <input type="number" /> */}
                                        </div>
                                        <div className="skd-new-booking-center-dim">
                                            <h5 style={{ marginLeft: 15 }}>Dim:</h5>
                                            {/* <div>
                                                <input type="number" placeholder="L" />

                                                <input type="number" placeholder="W" />

                                                <input type="number" placeholder="H" />
                                            </div> */}
                                        </div>
                                        <div className="skd-new-booking-left-dim">
                                            <h5 style={{ marginLeft: 35 }}>Chargeable:</h5>
                                            {/* <input type="number" /> */}
                                        </div>
                                        <div className="skd-new-booking-empty"></div>
                                    </div>

                                    {packageValue.map((element, index) => {
                                        let dimCharge = 0;
                                        const grossWeight = element.packageWeight;
                                        if (service1 === "DAU") {
                                            dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 6000;
                                        } else if (service1 === "DUS") {
                                            dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 6000;
                                        } else {
                                            dimCharge = element.packageWidth * element.packageHeight * element.packageLength / 5000;
                                        }

                                        if (dimCharge < grossWeight) {
                                            element.packageCharge = grossWeight * 1;
                                            element.packageWeight = grossWeight * 1;
                                        } else if (grossWeight < dimCharge) {
                                            element.packageCharge = dimCharge;
                                            element.packageWeight = grossWeight * 1;

                                        }
                                        if (element.packageCharge >= 30) {
                                            element.packageCharge = Math.ceil(element.packageCharge);
                                        } else if (element.packageCharge < 30) {
                                            element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
                                        }
                                        if (element.packageWeight === "") {
                                            element.packageCharge = "0.00";
                                        }
                                        return <div className="skd-edit-new-booking-right-dim1">
                                            <div className="skd-new-booking-left-dim">
                                                <input type="number" value={element.packageWeight || ""} onChange={e => handleChange(index, e)} name="packageWeight" />
                                            </div>
                                            <div className="skd-new-booking-center-dim">
                                                <div>
                                                    <input type="number" placeholder="L" name="packageLength" value={element.packageLength || ""} onChange={e => handleChange(index, e)} />
                                                    <input type="number" placeholder="W" name="packageWidth" value={element.packageWidth || ""} onChange={e => handleChange(index, e)} />
                                                    <input type="number" placeholder="H" name="packageHeight" value={element.packageHeight || ""} onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-new-booking-left-dim">
                                                <input type="number" disabled value={(Math.round(element.packageCharge * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} name="packageCharge" />
                                            </div>
                                            <div className="skydart-pack-option">
                                                <Copy size={13} className="skydart-pack-copy" onClick={() => {
                                                    setPackageValue([...packageValue, { packageLength: element.packageLength, packageWidth: element.packageWidth, packageHeight: element.packageHeight, packageWeight: element.packageWeight, packageCharge: element.packageCharge }])
                                                }} />
                                                {packageValue.length !== 1 && <Minus size={13} className="skydart-pack-copy" onClick={e => removeFormField(index, e)} />}
                                            </div>
                                        </div>
                                    })}

                                    <div className="skydart-pack-add-btn">
                                        <button type="button" onClick={addFormFields}>
                                            <Add style={{ fontSize: 17 }} />
                                        </button>
                                    </div>
                                </div>}
                            </div>
                            <div className="skd-edit-new-booking-right-packing">
                                <div className="skd-edit-new-booking-pack-main">
                                    <h5>Description Of Goods</h5>
                                    <GrApps />
                                </div>
                                {type === "WPX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total items:</h5>
                                        <h5>{invoiceValue.length} items</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total item value:</h5>
                                        <h5>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                                    </div>
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5>Total invoice value:</h5>
                                        <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                                    </div>
                                </div>}
                                {type === "DOX" && <div className="skd-edit-new-booking-pack-main1">
                                    <div className="skd-edit-new-booking-pack-option">
                                        <h5 style={{ color: "black" }}>There is no invoice required for Document (DOX)</h5>
                                    </div>
                                </div>}
                            </div>
                            {type === "WPX" && <div className="skd-edit-new-booking-right-invoice">
                                {invoiceValue.map((s, index) => {
                                    let donGia1 = s.itemUnitPrice;
                                    let soLuong1 = s.itemQuantity;
                                    let tong1 = donGia1 * soLuong1;
                                    s.itemValue = tong1;
                                    return <div style={{ marginBottom: 10 }}>
                                        <div className="skd-edit-new-booking-right-invoice-header">
                                            <div className="skd-edit-new-booking-right-invoice-header-left">
                                                <h5>Item {s.itemNo}</h5>
                                            </div>
                                            <div className="skydart-pack-option">
                                                <Copy size={13} className="skydart-pack-copy" onClick={() => {
                                                    let x = invoiceValue.length;
                                                    setInvoiceValue([...invoiceValue, { itemNo: x + 1, itemBrand: s.itemBrand, itemIngredients: s.itemIngredients, itemUnit2: s.itemUnit2, itemPackType2: s.itemPackType2, itemCommonity: s.itemCommonity, itemUnit: s.itemUnit, itemUnitPirce: s.itemUnitPrice, itemQuantity: s.itemQuantity, itemValue: s.itemValue, itemManufact: s.itemManufact, itemPurpose: s.itemPurpose, itemHsCode: s.itemHsCode, itemPackType: s.itemPackType, itemManufactAddress: s.itemManufactAddress, itemCountryOrigin: s.itemCountryOrigin, itemFda: s.itemFda }])
                                                }} />
                                                {invoiceValue.length !== 1 && <Minus size={13} className="skydart-pack-copy" onClick={e => removeInvoice(index, e)} />}
                                            </div>
                                        </div>
                                        <div className="skd-edit-new-booking-right-invoice-body">
                                            <div className="skd-edit-new-booking-right-invoice-itemName">
                                                <input type="text" placeholder="Declare item name here" name="itemCommonity" value={s.itemCommonity} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Brand Name</h5>
                                                    <input type="text" value={s.itemBrand} name="itemBrand" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Packing Type</h5>
                                                    <select value={s.itemPackType || ""} name="itemPackType" onChange={e => invoiceChange(index, e)}>
                                                        <option value="">Type</option>
                                                        <option value="Bag">Bag</option>
                                                        <option value="Box">Box</option>
                                                        <option value="Carton">Carton</option>
                                                        <option value="Wooden Pack">Wooden Pack</option>
                                                    </select>
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Ingredient</h5>
                                                    <input type="text" value={s.itemIngredients} name="itemIngredients" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Usage</h5>
                                                    <input type="text" value={s.itemPurpose} name="itemPurpose" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Origin Country</h5>
                                                    <input type="text" value={s.itemCountryOrigin} name="itemCountryOrigin" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Manufacturer Name</h5>
                                                    <input type="text" name="itemManufact" value={s.itemManufact} onChange={e => invoiceChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-itemName" style={{ marginTop: 5 }}>
                                                <h5 style={{ fontWeight: 600, fontSize: 12, color: "gray" }}>Manufacturer Address</h5>
                                                <textarea type="text" placeholder="Declare item name here" name="itemManufactAddress" value={s.itemManufactAddress} onChange={e => invoiceChange(index, e)} />
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Hs Code</h5>
                                                    <input type="text" name="itemHsCode" value={s.itemHsCode} onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>FDA</h5>
                                                    <input type="text" value={s.itemFda || ""} name="itemFda" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Quantity</h5>
                                                    <input type="number" value={s.itemQuantity || ""} name="itemQuantity" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                            </div>
                                            <div className="skd-edit-new-booking-right-invoice-content">
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Unit</h5>
                                                    {s.itemUnit !== "Other" && <select name="itemUnit" value={s.itemUnit} onChange={e => invoiceChange(index, e)}>
                                                        <option value="">Unit</option>
                                                        <option value="Pcs">Pcs</option>
                                                        <option value="Pair">Pair</option>
                                                        <option value="Kg">Kg</option>
                                                        <option value="Set">Set</option>
                                                        <option value="Bag">Bag</option>
                                                        <option value="Other">Other</option>
                                                    </select>}
                                                    {s.itemUnit === "Other" && <div style={{ display: "flex" }}>
                                                        <input type="text" value={s.itemUnit2 || ""} name="itemUnit2" onChange={e => invoiceChange(index, e)} />
                                                        <select value={s.itemUnit || ""} name="itemUnit" onChange={e => invoiceChange(index, e)} style={{ width: "18%" }}>
                                                            <option value="">Unit</option>
                                                            <option value="Pcs">Pcs</option>
                                                            <option value="Pair">Pair</option>
                                                            <option value="Kg">Kg</option>
                                                            <option value="Set">Set</option>
                                                            <option value="Bag">Bag</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>}
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Unit Price (USD)</h5>
                                                    <input type="number" required value={s.itemUnitPrice || ""} name="itemUnitPrice" onChange={e => invoiceChange(index, e)} />
                                                </div>
                                                <div className="skd-edit-new-booking-right-invoice-option">
                                                    <h5>Total amount (USD)</h5>
                                                    <input type="text" style={{ background: "rgb(240,240,240)" }} disabled value={s.itemValue.toFixed(2)} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                })}
                                <div className="skydart-pack-add-btn">
                                    <button type="button" onClick={addCarton} style={{ marginRight: 10 }}>
                                        <Add style={{ fontSize: 17 }} />
                                    </button>
                                </div>
                            </div>}
                            <div className="skd-edit-new-booking-right-packing" style={{ paddingBottom: 30 }}>
                                <div className="skd-edit-new-booking-pack-main">
                                    <h5>Term Of Transportation</h5>
                                    <GrApps />
                                </div>
                                <div className="skd-edit-new-booking-pack-main1">
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>Transport Term</h5>
                                        <select value={term} onChange={(e) => setTerm(e.target.value)}>
                                            <option value="">Select your transport term</option>
                                            <option value="EXW">EX-WORD (EXW)</option>
                                            <option value="FOB">FREE ON BOARD (FOB)</option>
                                            <option value="CNF">COST & FREIGHT (CNF)</option>
                                            <option value="CIF">COST INSURANCE & FREIGHT (CIF)</option>
                                        </select>
                                    </div>}
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>Transport Value</h5>
                                        {term !== "FOB" && term !== "EXW" && <input type="number" style={{ padding: 7, width: "96%" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} />}
                                        {term === "FOB" && <input type="number" style={{ background: "rgb(230,230,230)", padding: 7, width: "96%" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} disabled />}
                                        {term === "EXW" && <input type="number" style={{ background: "rgb(230,230,230)", padding: 7, width: "96%" }} placeholder="Enter your transportation cost" value={transport} onChange={(e) => setTransport(e.target.value)} disabled />}
                                    </div>}
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>Export Reason</h5>
                                        <select value={reason} onChange={(e) => setReason(e.target.value)}>
                                            <option value="">Select your export reason</option>
                                            <option value="THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE">THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE</option>
                                            <option value="THE GOODS ARE E-COMMERCE SELLING">THE GOODS ARE E-COMMERCE SELLING</option>
                                            <option value="THE GOODS ARE RETURNED FOR MAINTENANCE">THE GOODS ARE RETURNED FOR MAINTENANCE</option>
                                            <option value="THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE">THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE</option>
                                            <option value="THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED">THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED</option>
                                        </select>
                                    </div>}
                                    <div className="skd-edit-new-booking-term">
                                        <h5>Service</h5>
                                        <select value={service1} onChange={handleChangeService}>
                                            <option value="">Select a service</option>
                                            <option value="SKD">SKD</option>
                                            <option value="DHL">SDH</option>
                                            <option value="UPS">SUPS</option>
                                            <option value="FIP">SIP</option>
                                            <option value="DHL-VN">VDH</option>
                                            <option value="Fedex-VN">VIP</option>
                                            <option value="DAU">DAU</option>
                                            <option value="SEC">SEC</option>
                                            <option value="DUS">DUS</option>
                                            <option value="FUS">FUS</option>
                                            <option value="UUS">UUS</option>
                                        </select>
                                    </div>
                                    {type === "WPX" && <div className="skd-edit-new-booking-term">
                                        <h5>IOSS / TIN / EORI Number / ID Tax Number</h5>
                                        <input type="text" style={{ width: "96%", padding: 7 }} value={eori} onChange={(e) => setEori(e.target.value)} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skd-edit-new-booking-button">
                        <div className="skd-edit-new-booking-button-option">
                            <div className="skd-new-booking-cancel" onClick={closeEdit}>
                                <h5>Cancel</h5>
                            </div>
                            <div ref={printOption ? printRefContainer : null}>
                                {printOption && <div className="skd-new-booking-print-container">
                                    <div className="skd-new-booking-print-option" onClick={printLabel}>
                                        <RiBillLine />
                                        <h5>Print Label</h5>
                                    </div>
                                    {type === "WPX" && <div className="skd-new-booking-print-option" onClick={printInvoice}>
                                        <FaFileInvoice />
                                        <h5>Print Invoice</h5>
                                    </div>}
                                    {type === "DOX" && <div className="skd-new-booking-print-option1">
                                        <FaFileInvoice />
                                        <h5>Print Invoice</h5>
                                    </div>}
                                    {type === "WPX" && <div className="skd-new-booking-print-option" onClick={printShippingMark}>
                                        <ImNewspaper />
                                        <h5>Print Shipping Mark</h5>
                                    </div>}
                                    {type === "DOX" && <div className="skd-new-booking-print-option1">
                                        <ImNewspaper />
                                        <h5>Print Shipping Mark</h5>
                                    </div>}
                                    <div className="skd-new-booking-print-option" onClick={printAll}>
                                        <BsReplyAll />
                                        <h5>Print All</h5>
                                    </div>
                                </div>}
                                <div className="skd-new-booking-print" onClick={() => { printOption ? setPrintOption(false) : setPrintOption(true) }}>
                                    <Print style={{ fontSize: 15 }} />
                                    <h5>Print</h5>
                                    <ArrowDropDown style={{ fontSize: 15 }} />
                                </div>
                            </div>
                            <button type="button" className="skd-new-booking-update" onClick={type === "WPX" ? updateShipment : updateDox}>Update</button>
                        </div>
                    </div>
                </div>
            </div>}
            {displaySurcharge && <div className="skydart-create-print">
                <div className="shipment-note-container" style={{ marginTop: "15%" }}>
                    <div className="skydart-create-surcharge">
                        <h3 style={{ fontWeight: 600, fontSize: 15 }}>Update Surcharge</h3>
                        <IconButton onClick={() => setDisplaySurcharge(false)} type="button" style={{ width: 35, height: 35, background: "rgb(230,230,230)" }}><Clear style={{ fontSize: 18 }} /></IconButton>
                    </div>
                    <div className="skydart-create-surcharge-container">
                        <div className="skydart-create-surcharge-container-header">
                            <div className="skydart-create-surcharge-option">
                                <h5>DHL FSC</h5>
                                <input type="number" value={dhlFsc} onChange={(e) => setDhlFsc(e.target.value)} placeholder="DHL fuel surcharge" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>Fedex FSC</h5>
                                <input type="number" value={fedexFsc} onChange={(e) => setFedexFsc(e.target.value)} placeholder="FEDEX fuel surcharge" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>Other FSC</h5>
                                <input type="number" value={otherFsc} onChange={(e) => setOtherFsc(e.target.value)} placeholder="OTHER fuel surcharge" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>Wrong Address</h5>
                                <input type="number" value={addressCorrect} onChange={(e) => setAddressCorrect(e.target.value)} placeholder="Address Correction" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <h5>Remote</h5>
                                <input type="number" value={remote} onChange={(e) => setRemote(e.target.value)} placeholder="Remote fee" />
                            </div>
                            <div className="skydart-create-surcharge-option">
                                <button type="button" onClick={handleSurcharge}>Update surcharge</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {displayTracking && <div className="shipment-note" style={{ overflowY: 'scroll' }}>
                <div className="shipment-note-container" style={{ marginTop: "15%", marginBottom: 70 }}>
                    <div className="shipment-note-header" style={{ background: "rgb(57, 108, 190)", color: "white", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <div>
                            <h3>Trace shipment</h3>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>Follow and update shipment for better customer experience</p>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayTracking(false)}>
                            <Clear fontSize="small" htmlColor="white" />
                        </IconButton>
                    </div>
                    <div className="skydart-tracking-container">
                        <h5 className="tracking-status-header">Tracing limit {selectTracing.length} shipments</h5>
                        {selectTracing.map((s, index) => {
                            let code = s.code || "";
                            return <TracingContainer hawb={s.hawb} code={code} no={index + 1} sin={s.sin} fromCity={s.fromCity} country={s.country} state={s.state} town={s.town} city={s.city} postal={s.postal} status={s.status} />
                        }
                        )}
                    </div>
                </div>
            </div>}
            {displayPayment && <div className="shipment-note">
                <div className="shipment-note-container" style={{ marginTop: "15%" }}>
                    <div className="shipment-note-header" style={{ background: "rgb(57, 108, 190)", color: "white", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <div>
                            <h3>Payment Method</h3>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>Manage customer payment option</p>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayPayment(false)}>
                            <Clear fontSize="small" htmlColor="white" />
                        </IconButton>
                    </div>
                    {userData.role !== "CEO" && userData.role !== "CFO" && <form className="shipment-note-payment" onSubmit={updateMode}>
                        <h5>Select a payment mode for selected shipments</h5>
                        <select value={mode} onChange={(e) => setMode(e.target.value)} required>
                            <option value="">Select payment method here</option>
                            <option value="CRE">CRE</option>
                            <option value="PRE">PRE</option>
                            <option value="CK">CK</option>
                            <option value="CRE-P">CRE-P</option>
                            <option value="CK-CN">CK-CN</option>
                            <option value="CK-CTY">CK-CTY</option>
                        </select>
                        <textarea placeholder="Enter accountant note here..." value={accountNote} onChange={(e) => setAccountNote(e.target.value)} />
                        {mode !== "" && <button type="submit">Update</button>}
                        {mode === "" && <button type="submit" style={{ background: "whitesmoke", color: "gray" }}>Update</button>}
                    </form>}

                    {userData.role === "CFO" && <form className="shipment-note-payment" onSubmit={mode === "CRE" ? updatePrepaid : updateAllMode}>
                        <h5>Select a payment mode for selected shipments</h5>
                        <select value={mode} onChange={(e) => setMode(e.target.value)} required>
                            <option value="">Select payment method here</option>
                            <option value="CRE">CRE</option>
                            <option value="CK">CK</option>
                            <option value="CRE-P">CRE-P</option>
                            <option value="CK-CN">CK-CN</option>
                            <option value="CK-CTY">CK-CTY</option>
                        </select>
                        <textarea placeholder="Enter accountant note here..." value={accountNote} onChange={(e) => setAccountNote(e.target.value)} />
                        {mode !== "" && <button type="submit">Update</button>}
                        {mode === "" && <button type="submit" style={{ background: "whitesmoke", color: "gray" }}>Update</button>}
                    </form>}
                    {userData.role === "CEO" && <form className="shipment-note-payment" onSubmit={mode === "CRE" ? updatePrepaid : updateAllMode}>
                        <h5>Select a payment mode for selected shipments</h5>
                        <select value={mode} onChange={(e) => setMode(e.target.value)} required>
                            <option value="">Select payment method here</option>
                            <option value="CRE">CRE</option>
                            <option value="CK">CK</option>
                            <option value="CRE-P">CRE-P</option>
                            <option value="CK-CN">CK-CN</option>
                            <option value="CK-CTY">CK-CTY</option>
                        </select>
                        <textarea placeholder="Enter accountant note here..." />
                        {mode !== "" && <button type="submit">Update</button>}
                        {mode === "" && <button type="submit" style={{ background: "whitesmoke", color: "gray" }}>Update</button>}
                    </form>}
                </div>
            </div>}
            {displayBatchTracking && <div className="shipment-note">
                <div className="shipment-note-container" style={{ width: "35%", marginTop: "20%" }}>
                    <div className="shipment-export-container" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FileCopySharp fontSize="small" />
                            <h3 style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>Import International Air Waybill Number </h3>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayBatchTracking(false)}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="shipment-batch-reweight" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <input type="file" onChange={handleTrackingUpload} />
                        <button type="button" className="shipment-batch-btn" onClick={updateBatchTracking}>Update File</button>
                    </div>
                </div>
            </div>}
            {displayBatchReweight && <div className="shipment-note">
                <div className="shipment-note-container" style={{ width: "35%", marginTop: "20%" }}>
                    <div className="shipment-export-container" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FileCopySharp fontSize="small" />
                            <h3 style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>Import Reweight File And Match To SKD HAWB</h3>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayBatchReweight(false)}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="shipment-batch-reweight" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <input type="file" onChange={handleFileUpload} />
                        <button type="button" className="shipment-batch-btn" onClick={updateBatchReweight}>Update File</button>
                    </div>
                </div>
            </div>}
            {displayUsExport && <div className="shipment-note">
                <div className='shipment-note-container' style={{ width: "35%", marginTop: "20%" }}>
                    <div className="shipment-export-container" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FileCopySharp fontSize="small" />
                            <h3 style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>File Name</h3>
                        </div>
                        <IconButton type="button" onClick={() => { setDisplayUsExport(false) }}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="shipment-export-enter-file-name">
                        <input type="text" value={excelFileName} onChange={(e) => setExcelFileName(e.target.value)} placeholder="Enter file name" />
                    </div>
                    <h5 style={{ marginLeft: 15, fontWeight: 600 }}>Exporting <strong style={{ fontWeight: 600, textDecoration: "underline", color: "darkblue" }}>{exportSelected.length} shipments from Shipment</strong></h5>
                    <div className="shipment-export-enter-file-name" style={{ gap: "0.5em" }}>
                        <CSVLink {...dusReport} onClick={() => setDisplayUsExport(false)} style={{ fontWeight: 600, marginRight: 15, textDecoration: "none", marginLeft: "auto" }} className="skydart-shipment-tool-create-shipment">Export</CSVLink>
                    </div>
                </div>
            </div>}
            {displayExport && <div className="shipment-note">
                <div className='shipment-note-container' style={{ width: "35%", marginTop: "20%" }}>
                    <div className="shipment-export-container" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FileCopySharp fontSize="small" />
                            <h3 style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>File Name</h3>
                        </div>
                        <IconButton type="button" onClick={() => { setDisplayExport(false) }}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="shipment-export-enter-file-name">
                        <input type="text" value={excelFileName} onChange={(e) => setExcelFileName(e.target.value)} placeholder="Enter file name" />
                    </div>
                    <h5 style={{ marginLeft: 15, fontWeight: 600 }}>Exporting <strong style={{ fontWeight: 600, textDecoration: "underline", color: "darkblue" }}>{exportSelected.length} shipments from Shipment</strong></h5>
                    <div className="shipment-export-enter-file-name" style={{ gap: "0.5em" }}>
                        <CSVLink {...agentReport} onClick={() => setDisplayExport(false)} style={{ fontWeight: 600, marginRight: 15, textDecoration: "none", marginLeft: "auto" }} className="skydart-shipment-tool-create-shipment">Export</CSVLink>
                    </div>
                </div>
            </div>}
            {displayExportAll && <div className="shipment-note">
                <div className='shipment-note-container' style={{ width: "35%", marginTop: "20%" }}>
                    <div className="shipment-export-container" style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FileCopySharp fontSize="small" />
                            <h3 style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>File Name</h3>
                        </div>
                        <IconButton type="button" onClick={() => { setDisplayExportAll(false) }}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="shipment-export-enter-file-name">
                        <input type="text" value={excelFileName} onChange={(e) => setExcelFileName(e.target.value)} placeholder="Enter file name" />
                    </div>
                    <h5 style={{ marginLeft: 15, fontWeight: 600 }}>Exporting <strong style={{ fontWeight: 600, textDecoration: "underline", color: "darkblue" }}>{exportSelected.length} shipments from Shipment</strong></h5>
                    <div className="shipment-export-enter-file-name" style={{ gap: "0.5em" }}>
                        <CSVLink {...exportAll} onClick={() => setDisplayExportAll(false)} style={{ fontWeight: 600, marginRight: 15, textDecoration: "none", marginLeft: "auto" }} className="skydart-shipment-tool-create-shipment">Export</CSVLink>
                    </div>
                </div>
            </div>}
            {displayFlightInfo && <div className="shipment-note">

                <div className="shipment-note-container" style={{ width: "40%" }}>
                    <div className="shipment-note-container-header">
                        <h3>Flight Info</h3>
                        <IconButton type="button" onClick={() => setDisplayFlightInfo(false)}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <form className="shipment-container-flight-info" onSubmit={handleFlightForm}>
                            <div className="shipment-container-flight-info-option">
                                <h5>Flight Name</h5>
                                <input type="text" required placeholder="Flight Name" value={flightInput} onChange={(e) => setFlightInput(e.target.value)} />
                            </div>
                            <div className="shipment-container-flight-info-option">
                                <h5>Flight Number</h5>
                                <input type="text" required placeholder='Flight Number' value={MAWBInput} onChange={(e) => setMAWBInput(e.target.value)} />
                            </div>
                            <div className="shipment-container-flight-info-option">
                                <h5>Load size</h5>
                                <input type="number" required placeholder='enter weight number' value={weightInput} onChange={(e) => setWeightInput(e.target.value)} />
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                        <div className="second">
                            <div className="second__container">
                                <div className="second__container__head" style={{ marginTop: 10 }}>
                                    <div className="second__container__option">
                                        <h5>Flight Name</h5>
                                    </div>
                                    <div className="second__container__option">
                                        <h5>MAWB</h5>
                                    </div>
                                    <div className="second__container__option">
                                        <h5>Size</h5>
                                    </div>
                                </div>
                                {todayFlight.map((s, index) => (
                                    <div className="second__container__head" style={{ marginTop: 5 }}>
                                        <div className="second__container__option">
                                            <h5 style={{ color: "black" }}>{s.flight}</h5>
                                        </div>
                                        <div className="second__container__option">
                                            <h5 style={{ color: "black" }}>{s.mawb}</h5>
                                        </div>
                                        <div className="second__container__option">
                                            <h5 style={{ color: "black" }}>{s.size}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {displayEdit && <div className="shipment-note">
                <div className="shipment-note-container" style={{ marginTop: "5%" }}>
                    <div className="shipment-note-header" style={{ background: "rgb(57, 108, 190)", color: "white", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <div>
                            <h3>Update Shipment</h3>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>Update Mawb // Sin Hawb // Flight // Agent // Etd // for selected shipments //</p>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayEdit(false)}>
                            <Clear fontSize="small" htmlColor="white" />
                        </IconButton>
                    </div>
                    <form className="shipment-note-content" onSubmit={onUpdateAll}>
                        <h5>Date</h5>
                        <input value={traceTime} disabled />
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Flight / Airline</h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input value={flight} onChange={(e) => setFlight(e.target.value)} type="text" />
                        </div>
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>MAWB / Flight Number (ID)</h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input value={mawbInput} onChange={(e) => setMawbInput(e.target.value)} type="text" />
                        </div>
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Agent</h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input value={agent} onChange={(e) => setAgent(e.target.value)} type="text" />
                        </div>
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Service </h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input value={service} disabled onChange={(e) => setService(e.target.value)} type="text" />
                        </div>

                        <h5>Intl AWB / Tracking</h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input value={sin} onChange={(e) => setSin(e.target.value)} type="text" />
                        </div>
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Packing</h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input value={reweight} onChange={(e) => setReweight(e.target.value)} type="text" />
                        </div>
                        <div style={{ display: "flex" }}>
                            <button type="button" className="shipment-note-update-btn1" onClick={() => setDisplayEdit(false)}>Close</button>
                            <button type="submit" className="shipment-note-update-btn">Update</button>
                        </div>
                    </form>

                </div>
            </div>}
            {displayTracing && <div className="shipment-note">
                <div className="shipment-note-container" style={{ marginTop: "12%" }}>
                    <div className="shipment-note-header" style={{ background: "rgb(57, 108, 190)", color: "white", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <div>
                            <h3>Update Tracing</h3>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>Tracing And Status //</p>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayTracing(false)}>
                            <Clear fontSize="small" htmlColor="white" />
                        </IconButton>
                    </div>
                    <form className="shipment-note-content" onSubmit={onUpdateTracing}>
                        <h5>Date</h5>
                        <input value={traceTime + " - " + dateTime} disabled />
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Parent Status</h5>
                        <select value={parentStatus} onChange={(e) => setParentStatus(e.target.value)} required>
                            <option value="">Status</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Delivered">Delivered</option>
                            <option value="In Process">In Process</option>
                            <option value="In Clearance">In Clearance</option>
                            <option value="Arrival">Arrival</option>
                            <option value="Delayed">Delayed</option>
                            <option value="In Transit">In Transit</option>
                            <option value="Return">Return</option>
                        </select>
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Location</h5>
                        <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
                        {locationErr && <h5 style={{ color: "red", fontWeight: 600, fontSize: 8 }}>Location is missing</h5>}
                        <h5 style={{ color: "lightgray" }}>|</h5>
                        <h5>Description (Status Detail)</h5>
                        <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
                        {descriptionErr && <h5 style={{ color: "red", fontWeight: 600, fontSize: 8 }}>Description is missing</h5>}
                        <div style={{ display: "flex" }}>
                            <button type="button" className="shipment-note-update-btn1" onClick={() => setDisplayTracing(false)}>Close</button>
                            <button type="submit" className="shipment-note-update-btn">Update</button>
                        </div>
                    </form>
                </div>
            </div>}
            {displayNote && <div className="shipment-note">
                <form className="shipment-note-container" style={{ marginTop: "15%" }}>
                    <div className="shipment-note-header">
                        <h3>Note Shipment</h3>
                        <IconButton type="button" onClick={() => setDisplayNote(false)}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="shipment-note-content">
                        <h5>Enter new note for selected HAWB</h5>
                        <textarea value={note} required onChange={(e) => setNote(e.target.value)} placeholder="Please enter note or reason of issue for selected shipments..." />
                        <select required value={noteOption} onChange={(e) => setNoteOption(e.target.value)}>
                            <option value="">Note Option</option>
                            <option value="Operation Note">Operation Note</option>
                            <option value="Tracking Note">Tracking Note</option>
                            <option value="Accountant Note">Accountant Note</option>
                            <option value="Courier Note">Courier Note</option>
                        </select>
                        <button type="button" className="shipment-note-update-btn" style={{ marginLeft: "auto" }} onClick={onUpdateNote}>Update</button>

                    </div>
                </form>
            </div>}
            {displayPricing && <form className="shipment-note" onSubmit={(e) => {
                e.preventDefault();
                if (userData.role === "CS") {
                    submitRate();
                } else if (userData.role === "CEO") {
                    submitRate();
                } else if (userData.role !== "CS" && userData.role !== "CEO") {
                    return;
                }
            }}>
                {!loadingRate && <div className="shipment-note-container" style={{ marginTop: "15%", width: "60%", marginLeft: "23%" }}>
                    <div className="shipment-note-header" style={{ background: "rgb(57, 108, 190)", color: "white", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <div>
                            <h3>Shortcuts</h3>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>Rate // FSC // Comestic // Reweight // Remote // Address Correction // VAT //</p>
                        </div>
                        <IconButton type="button" onClick={() => setDisplayPricing(false)}>
                            <Clear fontSize="small" htmlColor="white" />
                        </IconButton>
                    </div>
                    <div className="shipment-rate-content">
                        <div className="shipment-rate-collection">
                            <div className="shipment-rate-collection-option">
                                <h5>Rate</h5>
                                <input type="number" value={rateInput} onChange={(e) => setRateInput(e.target.value)} />

                            </div>
                            <div className="shipment-rate-collection-option" style={{ whitespace: "nowrap" }}>
                                <h5>Correct Add</h5>
                                <input type="number" value={addressCorrectionInput} onChange={(e) => setAddressCorrectionInput(e.target.value)} />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>Remote</h5>
                                <input type="number" value={remoteInput} onChange={(e) => setRemoteInput(e.target.value)} />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>Extra 1</h5>
                                <input type="number" value={surchargeInput} onChange={(e) => setSurchargeInput(e.target.value)} />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>FSC(%)</h5>
                                <input type="text" value={fuel} onChange={(e) => setFuel(e.target.value)} />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>FSC Value</h5>
                                <input type="text" value={(Math.round(fuelValue * 100) / 100).toFixed(2)} disabled />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>Extra</h5>
                                <input type="number" value={comesticInput} onChange={(e) => setComesticInput(e.target.value)} />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>Extra 2</h5>
                                <input type="number" value={surchargeInput2} onChange={(e) => setSurchargeInput2(e.target.value)} />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>VAT(%)</h5>
                                <select value={vatInput} onChange={(e) => setVatInput(e.target.value)} required>
                                    <option value="">VAT</option>
                                    <option value="0">0%</option>
                                    <option value="8">8%</option>
                                    <option value="10">10%</option>
                                </select>
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>VAT Value</h5>
                                <input type="text" value={(Math.round(vatValue * 100) / 100).toFixed(2)} disabled />
                            </div>
                            <div className="shipment-rate-collection-option">
                                <h5>Extra 3</h5>
                                <input type="number" value={surchargeInput3} onChange={(e) => setSurchargeInput3(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="shipment-com-note">
                        <div className="note-collection">
                            <h5>Com</h5>
                            <input type="number" value={comInput} onChange={(e) => setComInput(e.target.value)} />
                        </div>
                        <div className="note-collection">
                            <h5>Selling Note</h5>
                            <textarea type="text" value={sellNoteInput} onChange={(e) => setSellNoteInput(e.target.value)} />
                        </div>
                    </div>
                    <div className="shipment-rate-collection-button">
                        <button type="submit">Update Rate</button>
                    </div>

                </div>}
                {loadingRate && <div className="shipment-note-container" style={{ marginTop: "15%", height: 250 }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 100, flexDirection: "column", }}>
                        <CircularProgress size={30} />
                        <h5 style={{ marginLeft: 5, fontWeight: "600", marginTop: 5 }}>LOADING...</h5>
                    </div>
                </div>}
            </form>
            }
            <Snackbar open={alertShipment} autoHideDuration={1000} onClose={resetShipment}>
                <MuiAlert elevation={3} variant="filled" onClose={resetShipment} severity="error">
                    <p>{errMessage}</p>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
                <MuiAlert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
                    Update Succeed
                </MuiAlert>
            </Snackbar>
            <div className="skydart-main-shipment">
                <div className="skydart-main-shipment-filter">
                    <div className="skydart-main-shipment-filter-left">
                        <div style={{ width: "650px", display: "flex", alignItems: "center", position: "relative" }} ref={searchRef}>
                            <Search className="skydart-main-search" />
                            <input type="text" placeholder="Search and filter shipment" value={searchHawb} onChange={(e) => setSearchHawb(e.target.value)} />
                            <input type='text' placeholder='Search Mawb...' value={searchMawb} onChange={(e) => setSearchMawb(e.target.value)} style={{ paddingLeft: 10, marginLeft: 7, borderRadius: 5, width: "50%" }} />
                            <input type="text" placeholder="Search Sin AWB..." value={searchSin} onChange={(e) => setSearchSin(e.target.value)} style={{ paddingLeft: 10, marginLeft: 7, borderRadius: 5, width: "50%" }} />
                            {searchHawb !== "" && <Clear fontSize="small" className="skydart-main-clear-search" onClick={() => setSearchHawb("")} />}
                        </div>
                        <div className="skydart-refresh-button" onClick={refreshShipment}>
                            <Refresh />
                        </div>
                        {exportSelected.length !== 0 && <div className="skydart-notification">
                            <h5>{exportSelected.length} Selected</h5>
                        </div>}
                        {breakCountry && <div className="skydart-searched-result">
                            <h5>Breaking: Country</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setBreakCountry(false)} />
                        </div>}
                        {breakWeight && <div className="skydart-searched-result">
                            <h5>Breaking: Weight</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setBreakWeight(false)} />
                        </div>}
                        {breakService && <div className="skydart-searched-result">
                            <h5>Breaking: Service</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setBreakService(false)} />
                        </div>}
                        {breakRate && <div className="skydart-searched-result">
                            <h5>Breaking: Rate</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setBreakRate(false)} />
                        </div>}
                        {searchClass !== "" && <div className="skydart-searched-result">
                            <h5>Class is: {searchClass}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchClass("")} />
                        </div>}
                        {searchCus !== "" && <div className="skydart-searched-result">
                            <h5>Customer: {searchCus}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchCus("")} />
                        </div>}
                        {searchCity !== "" && <div className="skydart-searched-result">
                            <h5>Ship From: {searchCity}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchCity("")} />
                        </div>}
                        {searchCountry !== "" && <div className="skydart-searched-result">
                            <h5>Ship To: {searchCountry}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchCountry("")} />
                        </div>}
                        {searchType !== "" && <div className="skydart-searched-result">
                            <h5>Type: {searchType}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchType("")} />
                        </div>}
                        {searchWeight !== "" && <div className="skydart-searched-result">
                            <h5>Weight is: {searchWeight}kg</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchWeight("")} />
                        </div>}
                        {searchReweight !== "" && <div className="skydart-searched-result">
                            <h5>Reweight is: {searchWeight}kg</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchReweight("")} />
                        </div>}
                        {searchPcs !== "" && <div className="skydart-searched-result">
                            <h5>Pcs: {searchPcs}pcs</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchPcs("")} />
                        </div>}
                        {searchPack !== "" && <div className="skydart-searched-result">
                            <h5>Pack: {searchPack}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchPack("")} />
                        </div>}
                        {searchAirline !== "" && <div className="skydart-searched-result">
                            <h5>Airline is: {searchAirline}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchAirline("")} />
                        </div>}
                        {searchService !== "" && <div className="skydart-searched-result">
                            <h5>Service: {searchService}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchService("")} />
                        </div>}
                        {searchAgent !== "" && <div className="skydart-searched-result">
                            <h5>Agent: {searchAgent}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchAgent("")} />
                        </div>}
                        {searchMawb !== "" && <div className="skydart-searched-result">
                            <h5>MAWB: {searchMawb}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchMawb("")} />
                        </div>}
                        {searchSin !== "" && <div className="skydart-searched-result">
                            <h5>SIN HAWB: {searchSin}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchSin("")} />
                        </div>}
                        {searchRate !== "" && <div className="skydart-searched-result">
                            <h5>Rate is: {searchRate}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchRate("")} />
                        </div>}
                        {searchComestic !== "" && <div className="skydart-searched-result">
                            <h5>Extra: {searchComestic}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchComestic("")} />
                        </div>}
                        {searchAddress !== "" && <div className="skydart-searched-result">
                            <h5>{searchAddress}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchAddress("")} />
                        </div>}
                        {searchRemote !== "" && <div className="skydart-searched-result">
                            <h5>{searchRemote}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchRemote("")} />
                        </div>}
                        {searchSurcharge !== "" && <div className="skydart-searched-result">
                            <h5>Extra 1: {searchSurcharge}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchSurcharge("")} />
                        </div>}
                        {searchFsc !== "" && <div className="skydart-searched-result">
                            <h5>{searchFsc}%</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchFsc("")} />
                        </div>}
                        {searchFscValue !== "" && <div className="skydart-searched-result">
                            <h5>{searchFscValue}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchFscValue("")} />
                        </div>}
                        {searchSurcharge2 !== "" && <div className="skydart-searched-result">
                            <h5>Extra 2: {searchSurcharge2}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchSurcharge2("")} />
                        </div>}
                        {searchVat !== "" && <div className="skydart-searched-result">
                            <h5>{searchVat}%</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchVat("")} />
                        </div>}
                        {searchVatValue !== "" && <div className="skydart-searched-result">
                            <h5>{searchVatValue}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchVatValue("")} />
                        </div>}
                        {searchSurcharge3 !== "" && <div className="skydart-searched-result">
                            <h5>Extra 3: {searchSurcharge3}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchSurcharge3("")} />
                        </div>}
                        {searchSale !== "" && <div className="skydart-searched-result">
                            <h5>Controller: {searchSale}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchSale("")} />
                        </div>}
                        {searchPickup !== "" && <div className="skydart-searched-result">
                            <h5>Payment Pick Up: {searchPickup}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchPickup("")} />
                        </div>}
                        {searchAcc !== "" && <div className="skydart-searched-result">
                            <h5>Payment Accountant: {searchAcc}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchAcc("")} />
                        </div>}
                        {searchCompany !== "" && <div className="skydart-searched-result">
                            <h5>Payment Company: {searchCompany}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchCompany("")} />
                        </div>}
                        {searchNote !== "" && <div className="skydart-searched-result">
                            <h5>OPS Note: {searchNote}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchNote("")} />
                        </div>}
                        {searchSellNote !== "" && <div className="skydart-searched-result">
                            <h5>Selling Note: {searchSellNote}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchSellNote("")} />
                        </div>}
                        {searchAccNote !== "" && <div className="skydart-searched-result">
                            <h5>Accountant Note: {searchAccNote}</h5>
                            <Clear className="skydart-searched-clear" onClick={() => setSearchAccNote("")} />
                        </div>}
                        <select value={dateMethod} style={{ marginLeft: 10, padding: 5, border: "1px solid rgb(230,230,230)", outline: "none" }} onChange={(e) => setDateMethod(e.target.value)}>
                            <option value="range">Date Range</option>
                            <option value="single">Single Date</option>
                        </select>
                    </div>
                    <div ref={dateMethod !== "range" ? dateRef : null}></div>
                    {dateMethod === "range" && <div style={{ marginLeft: "auto" }} ref={dateMethod === "range" ? dateRef : null}>
                        <div className="skd-user-shipment-header-right-date" style={{ padding: 10 }} onClick={() => { displayDateRange ? setDisplayDateRange(false) : setDisplayDateRange(true) }}>
                            <GoCalendar size={20} style={{ marginRight: 10 }} />
                            <p style={{ marginRight: 7, }}>{dateStatus}:</p>
                            <p style={{ marginRight: 7, }}>{startDay}</p>
                            <AiOutlineMinus size={10} style={{ marginRight: 7 }} />
                            <p style={{ marginRight: 7, }}>{endDay}</p>
                            <ArrowDropDown style={{ fontSize: 18 }} />
                        </div>
                        {displayDateRange && <div className="skydart-date-range-container">
                            <div className="skydart-date-range-left" style={{ position: "relative" }}>
                                <DatepickerContext.Provider
                                    value={{
                                        focusedDate,
                                        isDateFocused,
                                        isDateSelected,
                                        isDateHovered,
                                        isDateBlocked,
                                        isFirstOrLastSelectedDate,
                                        onDateSelect,
                                        onDateFocus,
                                        onDateHover
                                    }}>
                                    <div className="skydart-date-range-header">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div className="skydart-date-range-input">
                                                <h5>Start Date</h5>
                                                <input type="text" disabled style={{ background: "white" }} value={!dateRange.startDate ? dateRange.startDate : dateRange.startDate.toDateString()} />
                                            </div>
                                            <AiOutlineMinus />
                                            <div className="skydart-date-range-input">
                                                <h5>End Date</h5>
                                                <input type="text" disabled style={{ background: "white" }} value={!dateRange.endDate ? dateRange.endDate : dateRange.endDate.toDateString()} />
                                            </div>
                                            {/* <div className="skydart-date-shortcut">
                                            <button type="button" onClick={handleToday}>Today</button>
                                            <button type="button" onClick={handleThisMonth}>This Month</button>
                                        </div> */}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "15px 0 0",
                                                gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                                                gridGap: "0 25px"
                                            }}
                                        >
                                            <div className="date-month-back" onClick={goToPreviousMonths}>
                                                <ArrowLeft />
                                            </div>
                                            <div className="date-month-back1" onClick={goToNextMonths}>
                                                <ArrowRight />
                                            </div>
                                            {activeMonths.map(month => (
                                                <Month
                                                    key={`${month.year}-${month.month}`}
                                                    year={month.year}
                                                    month={month.month}
                                                    firstDayOfWeek={firstDayOfWeek}
                                                />
                                            ))}

                                        </div>
                                        <div className="skydart-date-range-button">
                                            {dateRange.startDate && <div>
                                                <h5 style={{ fontWeight: 600, fontSize: 12 }}>{!dateRange.startDate ? dateRange.startDate : dateRange.startDate.toDateString()} - {!dateRange.endDate ? dateRange.endDate : dateRange.endDate.toDateString()}</h5>
                                                <h5 style={{ fontWeight: 480, fontSize: 11, color: "gray" }}>Ho Chi Minh time</h5>
                                            </div>}
                                            <button type="button" className="skydart-date-range-cancel" onClick={handleCancelDate}>Cancel</button>
                                            <button type="button" className="skydart-date-range-apply" onClick={handleApplyDate}>Apply</button>
                                        </div>
                                    </div>
                                </DatepickerContext.Provider>
                            </div>
                            <div className="skydart-date-range-right">
                                <h5 style={{ fontWeight: 700, padding: 10, fontSize: 12 }}>Date preset</h5>
                                <div className={dateStatus === "Today" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleToday}>
                                    {dateStatus === "Today" && <Check style={{ fontSize: 15 }} />}
                                    <p>Today</p>
                                </div>
                                <div className={dateStatus === "Yesterday" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleYesterday}>
                                    {dateStatus === "Yesterday" && <Check style={{ fontSize: 15 }} />}
                                    <p>Yesterday</p>
                                </div>
                                <div className={dateStatus === "This week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisWeek}>
                                    {dateStatus === "This week" && <Check style={{ fontSize: 15 }} />}
                                    <p>This week</p>
                                </div>
                                <div className={dateStatus === "Last week" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastWeek}>
                                    {dateStatus === "Last week" && <Check style={{ fontSize: 15 }} />}
                                    <p>Last week</p>
                                </div>
                                <div className={dateStatus === "This month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisMonth}>
                                    {dateStatus === "This month" && <Check style={{ fontSize: 15 }} />}
                                    <p>This Month</p>
                                </div>
                                <div className={dateStatus === "Last month" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleLastMonth}>
                                    {dateStatus === "Last month" && <Check style={{ fontSize: 15 }} />}
                                    <p>Last Month</p>
                                </div>
                                <div className={dateStatus === "This year" ? "skydart-date-range-right-option1" : "skydart-date-range-right-option"} onClick={handleThisYear}>
                                    {dateStatus === "This year" && <Check style={{ fontSize: 15 }} />}
                                    <p>This Year</p>
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
            <div className="skydart-shipment-fetch">
                {status === "" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <Package size={25} />
                        <h5>All Shipments</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{roleBase.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "" && <div className="skydart-fetch-status1" onClick={() => setStatus("")}>
                    <div className="skydart-fetch-left">
                        <Package size={23} />
                        <h5>All Shipments</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{roleBase.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "Picked Up" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <LocalShippingOutlined style={{ fontSize: 25 }} />
                        <h5>Picked Up</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{pickedupShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "Picked Up" && <div className="skydart-fetch-status1" onClick={() => { setStatus("Picked Up"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <LocalShippingOutlined style={{ fontSize: 23 }} />
                        <h5>Picked Up</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{pickedupShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "Arrived" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <CallReceived style={{ fontSize: 25 }} />
                        <h5>Arrived Depot</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{arrivedShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "Arrived" && <div className="skydart-fetch-status1" onClick={() => { setStatus("Arrived"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <CallReceived style={{ fontSize: 23 }} />
                        <h5>Arrived Depot</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{arrivedShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "In Process" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <IoBuildOutline size={25} />
                        <h5>In Process</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{processShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "In Process" && <div className="skydart-fetch-status1" onClick={() => { setStatus("In Process"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <IoBuildOutline size={23} />
                        <h5>In Process</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{processShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "On Hold" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <PanTool style={{ fontSize: 25 }} />
                        <h5>On Hold</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{holdShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "On Hold" && <div className="skydart-fetch-status1" onClick={() => { setStatus("On Hold"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <PanTool style={{ fontSize: 23 }} />
                        <h5>On Hold</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{holdShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "In Clearance" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <MdOutlineLayersClear size={25} />
                        <h5>Clearance</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{clearanceShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "In Clearance" && <div className="skydart-fetch-status1" onClick={() => { setStatus("In Clearance"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <MdOutlineLayersClear size={23} />
                        <h5>Clearance</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{clearanceShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "Cleared For Export" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <Package />
                        <h5>Cleared</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{clearedShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "Cleared For Export" && <div className="skydart-fetch-status1" onClick={() => { setStatus("Cleared For Export"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <Package size={20} />
                        <h5>Cleared</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{clearedShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "In Transit" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <TiExportOutline size={25} />
                        <h5>In Transit</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{transitShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "In Transit" && <div className="skydart-fetch-status1" onClick={() => { setStatus("In Transit"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <TiExportOutline size={23} />
                        <h5>In Transit</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{transitShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status === "Delivered" && <div className="skydart-fetch-status">
                    <div className="skydart-fetch-left1">
                        <IoMdCheckmarkCircleOutline size={25} />
                        <h5>Delivered</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length">
                            <h5>{deliveredShipment.length}</h5>
                        </div>
                    </div>
                </div>}
                {status !== "Delivered" && <div className="skydart-fetch-status1" onClick={() => { setStatus("Delivered"); setPageNumberLimit(10); setMinPageLimit(0); setMaxPageLimit(10); setCurrentPage(1) }}>
                    <div className="skydart-fetch-left">
                        <IoMdCheckmarkCircleOutline size={23} />
                        <h5>Delivered</h5>
                    </div>
                    <div className="skydart-fetch-right">
                        <div className="check-length" style={{ marginTop: -15, background: "none", color: "black" }}>
                            <h5>{deliveredShipment.length}</h5>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="skydart-shipment-tool">
                <div className="skydart-shipment-tool-left">
                    <div className="skydart-shipment-tool-option">
                        <div className="skydart-shipment-tool-create-shipment" onClick={() => history.push("/booking")}>
                            <Add fontSize="small" />
                            <h5>Create</h5>
                        </div>
                    </div>

                    <div className="skydart-shipment-tool-option">
                        {exportSelected.length !== 0 && <div className="skydart-shipment-tool-edit-shipment" onClick={() => setDisplayEdit(true)}>
                            <Edit fontSize="small" />
                            <h5>Edit</h5>
                        </div>}
                        {exportSelected.length === 0 && <div className="skydart-shipment-tool-edit-shipment1">
                            <Edit fontSize="small" />
                            <h5>Edit</h5>
                        </div>}
                    </div>
                    <div className="skydart-shipment-tool-option">
                        {exportSelected.length !== 0 && <div className="skydart-shipment-tool-edit-shipment" onClick={() => setDisplayTracingOption(true)}>
                            <TrackChanges fontSize="small" />
                            <h5>Tracing</h5>
                            <ArrowDropDown fontSize="small" />

                        </div>}
                        {exportSelected.length === 0 && <div className="skydart-shipment-tool-edit-shipment1">
                            <TrackChanges fontSize="small" />
                            <h5>Tracing</h5>
                            <ArrowDropDown fontSize="small" />
                        </div>}
                    </div>
                    <div className="skydart-shipment-tool-option">
                        <div className="skydart-shipment-tool-req-res">
                            {exportSelected.length !== 0 && <div className="req" onClick={() => setDisplayNote(true)}>
                                <h5 className='req-help'>Note Shipment</h5>
                                <EventNote fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />

                            </div>}
                            {exportSelected.length === 0 && <div className="req1">
                                <h5 className='req-help'>Note Shipment</h5>
                                <EventNote fontSize="small" style={{ paddingTop: 6, color: "gray", paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />

                            </div>}
                            <div className="res" onClick={() => setDisplayFlightInfo(true)}>
                                <h5 className='req-help'>Flight Info</h5>
                                <FlightTakeoff fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />

                            </div>
                        </div>
                    </div>
                    <div className="skydart-shipment-tool-option">
                        {userData.role === "CS" && <div className="skydart-shipment-tool-req-res">
                            {exportSelected.length !== 0 && <div className="req" onClick={() => { setDisplayPricing(true) }}>
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            {exportSelected.length === 0 && <div className="req1">
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, color: "gray", paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            <div className="res" onClick={() => setDisplaySurcharge(true)}>
                                <h5 className='req-help'>Surcharge</h5>
                                <Assessment fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>
                        </div>}
                        {/* {userData.role === "Sales" && <div className="skydart-shipment-tool-req-res">
                            {exportSelected.length !== 0 && <div className="req" onClick={() => { setDisplayPricing(true) }}>
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            {exportSelected.length === 0 && <div className="req1">
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, color: "gray", paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            <div className="res" onClick={() => setDisplaySurcharge(true)}>
                                <h5 className='req-help'>Surcharge</h5>
                                <Assessment fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>
                        </div>} */}
                        {userData.role === "CEO" && <div className="skydart-shipment-tool-req-res">
                            {exportSelected.length !== 0 && <div className="req" onClick={() => { setDisplayPricing(true) }}>
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            {exportSelected.length === 0 && <div className="req1">
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, color: "gray", paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            <div className="res" onClick={() => setDisplaySurcharge(true)}>
                                <h5 className='req-help'>Surcharge</h5>
                                <Assessment fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>
                        </div>}
                        {/* {userData.role === "OPS" && <div className="skydart-shipment-tool-req-res">
                            {exportSelected.length !== 0 && <div className="req" onClick={() => { setDisplayPricing(true) }}>
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            {exportSelected.length === 0 && <div className="req1">
                                <h5 className='req-help'>Group Rating</h5>
                                <LocalOffer fontSize="small" style={{ paddingTop: 6, color: "gray", paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>}
                            <div className="res" onClick={() => setDisplaySurcharge(true)}>
                                <h5 className='req-help'>Surcharge</h5>
                                <Assessment fontSize="small" style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 6, paddingBottom: 0 }} />
                            </div>
                        </div>} */}
                    </div>
                    <div className="skydart-shipment-tool-option">
                        <div className="skydart-shipment-tool-edit-shipment" onClick={() => {
                            if (displayExportAction) {
                                setDisplayExportAction(false);
                            } else if (!displayExportAction) {
                                setDisplayExportAction(true);
                            }
                        }}>
                            <Crop fontSize="small" />
                            <h5 style={{ marginRight: 5 }}>Export</h5>
                            <ArrowDropDown fontSize="small" />
                        </div>
                    </div>
                    <div className="skydart-shipment-tool-option">
                        {exportSelected.length !== 0 && <div className="skydart-shipment-tool-edit-shipment" onClick={() => setDisplayAction(true)}>
                            <Launch fontSize="small" />
                            <h5 style={{ marginRight: 5 }}>Action</h5>
                            <ArrowDropDown fontSize="small" />
                        </div>}
                        {exportSelected.length === 0 && <div className="skydart-shipment-tool-edit-shipment" style={{ cursor: 'not-allowed' }}>
                            <Launch fontSize="small" htmlColor="gray" />
                            <h5 style={{ marginRight: 5, color: "gray" }}>Action</h5>
                            <ArrowDropDown fontSize="small" htmlColor="gray" />
                        </div>}
                    </div>

                    <div className="skydart-shipment-tool-option">
                        {exportSelected.length !== 0 && <div className="skydart-shipment-tool-edit-shipment" onClick={() => setDisplayPayment(true)}>
                            <Receipt fontSize="small" />
                            <h5>Payment</h5>
                        </div>}
                        {exportSelected.length === 0 && <div className="skydart-shipment-tool-edit-shipment1">
                            <Receipt fontSize="small" />
                            <h5>Payment</h5>
                        </div>}

                    </div>

                    <div className="skydart-shipment-tool-option" ref={breakDownRef}>
                        <div className="skydart-shipment-tool-edit-shipment" onClick={displayBreakDown ? () => { setDisplayBreakDown(false) } : () => { setDisplayBreakDown(true) }}>
                            <BsColumnsGap size={20} />
                            <h5>Columns</h5>
                            <ArrowDropDown fontSize="small" />
                        </div>
                        {displayBreakDown && <div className="skydart-shipment-break-down">
                            <div className="skydart-shipment-break-down-option" onClick={() => { showFrom ? setShowFrom(false) : setShowFrom(true) }}>
                                <input type="radio" name="from" id="from-radio" checked={showFrom ? true : false} />
                                <label style={{ fontSize: 13 }}>From</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showTo ? setShowTo(false) : setShowTo(true) }}>
                                <input type="radio" name="to" id="to-radio" checked={showTo ? true : false} />
                                <label style={{ fontSize: 13 }}>Destination</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showStatus1 ? setShowStatus1(false) : setShowStatus1(true) }}>
                                <input type="radio" name="status" id="status-radio" checked={showStatus1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Status</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showType1 ? setShowType1(false) : setShowType1(true) }}>
                                <input type="radio" name="type" id="type-radio" checked={showType1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Type</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showWeight1 ? setShowWeight1(false) : setShowWeight1(true) }}>
                                <input type="radio" name="weight" id="weight-radio" checked={showWeight1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Weight</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showPcs1 ? setShowPcs1(false) : setShowPcs1(true) }}>
                                <input type="radio" name="pcs" id="pcs-radio" checked={showPcs1 ? true : false} />
                                <label style={{ fontSize: 13 }}>PCS</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showPacking1 ? setShowPacking1(false) : setShowPacking1(true) }}>
                                <input type="radio" name="packing" id="packing-radio" checked={showPacking1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Packing</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showAirline1 ? setShowAirline1(false) : setShowAirline1(true) }}>
                                <input type="radio" name="airline" id="airline-radio" checked={showAirline1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Airline</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showService1 ? setShowService1(false) : setShowService1(true) }}>
                                <input type="radio" name="service" id="service-radio" checked={showService1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Service</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showAgent1 ? setShowAgent1(false) : setShowAgent1(true) }}>
                                <input type="radio" name="agent" id="agent-radio" checked={showAgent1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Agent</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showMawb1 ? setShowMawb1(false) : setShowMawb1(true) }}>
                                <input type="radio" name="mawb" id="mawb-radio" checked={showMawb1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Mawb</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showSin1 ? setShowSin1(false) : setShowSin1(true) }}>
                                <input type="radio" name="sin" id="sin-radio" checked={showSin1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Intl AWB</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showRate1 ? setShowRate1(false) : setShowRate1(true) }}>
                                <input type="radio" name="pricing" id="pricing-radio" checked={showRate1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Pricing</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showController1 ? setShowController1(false) : setShowController1(true) }}>
                                <input type="radio" name="controller" id="controller-radio" checked={showController1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Controller</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showPayment1 ? setShowPayment1(false) : setShowPayment1(true) }}>
                                <input type="radio" name="payment" id="payment-radio" checked={showPayment1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Payment</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => { showNote1 ? setShowNote1(false) : setShowNote1(true) }}>
                                <input type="radio" name="note" id="note-radio" checked={showNote1 ? true : false} />
                                <label style={{ fontSize: 13 }}>Shipment Note</label>
                            </div>
                            <div className="skydart-shipment-break-down-option" onClick={() => {
                                setDisplayBreakDown(false);
                                setShowFrom(true);
                                setShowTo(true);
                                setShowStatus1(true);
                                setShowType1(true);
                                setShowWeight1(true);
                                setShowPcs1(true);
                                setShowPacking1(true);
                                setShowAirline1(true);
                                setShowService1(true);
                                setShowAgent1(true);
                                setShowMawb1(true);
                                setShowSin1(true);
                                setShowRate1(true);
                                setShowController1(true);
                                setShowPayment1(true);
                                setShowNote1(true);
                            }}>
                                <label style={{ fontSize: 13, cursor: "pointer" }}>Set column as default</label>
                            </div>
                        </div>}
                    </div>
                    {userData?.subMain === "pricing" && <div style={{ marginLeft: 10 }}>
                        <select style={{ padding: 5, marginTop: 5 }} value={roleInput || userData.role} onChange={(e) => setRoleInput(e.target.value)}>
                            <option value="Sales">Sales</option>
                            <option value="CS">CS</option>
                        </select>
                        <button type="button" style={{ padding: 5, marginLeft: 3 }} onClick={() => {
                            setLoading(true);
                            db.collection("users").doc(userData.email).update({
                                role: roleInput,
                            }).then(() => {
                                setLoading(false);
                                window.location.reload();
                            })
                        }}>SWAP</button>
                    </div>}
                    {/* <div className="skydart-shipment-tool-option">
                        <div className="skydart-shipment-tool-edit-shipment" onClick={onTransitShipment}>
                            <Receipt fontSize="small" />
                            <h5>Get</h5>
                        </div>
                    </div> */}
                    {/* <div className="skydart-shipment-tool-option">
                        <div className="skydart-shipment-tool-edit-shipment" onClick={() => setDisplayBreak(true)}>
                            <FullscreenExit fontSize="small" />
                            <h5 style={{ marginRight: 5 }}>Rule</h5>
                            <ArrowDropDown fontSize="small" />
                        </div>
                    </div> */}


                </div>

                {/* <div className="skydart-shipment-tool-right">
                    <div className="skydart-shipment-tool-right-option">
                        <h5>(Filtering 1000 / <strong style={{ fontWeight: 700, textDecoration: "underline", color: "#0068be" }}>1000</strong> Shipments from <strong style={{ fontWeight: 600, textDecoration: "underline", color: "#0068be" }}>All Shipment...</strong>)</h5>

                    </div>
                </div> */}
                <div className="paginate">
                    <div className="pageNumbers">
                        <IconButton type="button" disabled={currentPage == pages[0] ? true : false} style={{ width: 35, height: 35 }} onClick={handlePrevPage}><ArrowLeft className="prev-btn-page" /></IconButton>
                        {renderPageNumbers}
                        <IconButton type="button" disabled={currentPage == pages[pages.length - 1] ? true : false} style={{ width: 35, height: 35 }} onClick={handleNextPage}><ArrowRight className="prev-btn-page" /></IconButton>
                    </div>
                </div>
                {/* <Pagination shipmentPerPage={shipmentPerPageeeeeeeee}eeeeee allShipment={finalShipment.length} paginate={paginate} /> */}
            </div>
            {
                loading &&
                <div style={{ position: "absolute", width: "100%", zIndex: 9999 }}>
                    <LinearProgress style={{ marginLeft: 20, height: 5, borderRadius: 5, marginRight: 20 }} />
                </div>
            }
            <div className="skydart-bill" ref={scrollShipment} onScroll={onScroll}>
                <div className="skydart-bill-container" id="export">
                    <div className={scrollY > 0 ? "skydart-bill-header3" : "skydart-bill-header"}>
                        <div className={scrollX > 0 ? "skydart-sticky-header" : "skydart-sticky-header1"}>
                            <div className="skydart-bill-checkbox-header" style={{ padding: 13 }}>
                                <input type="checkbox" id="selectAll" onChange={e => {
                                    let value = e.target.checked;
                                    setSelectedShipment(getWeight.map(d => {
                                        d.select = value;
                                        return d;
                                    }))
                                }} />
                            </div>
                            <div className="skydart-bill-no-header" id="1">
                                <h5 style={{ textAlign: "center" }}>No.</h5>
                            </div>
                            <div className="skydart-bill-class-header" id="2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h5>Class</h5>
                                <select value={searchClass} style={{ border: "none", background: "none", outline: "none", width: "20%" }} onChange={(e) => setSearchClass(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateClass.sort((x, y) => {
                                        return x.localeCompare(y)
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div ref={tracingRef}>
                                {displayTracingOption && <div className="skydart-tracing-option">
                                    <div className="skydart-tracing-setting" onClick={() => { setDisplayTracing(true); setDisplayTracingOption(false) }}>
                                        <h5>Update tracing</h5>
                                    </div>
                                    <div className="skydart-tracing-setting" onClick={handleTracing}>
                                        <h5>Trace shipment</h5>
                                    </div>
                                </div>}
                            </div>
                            <div className="skydart-bill-customer-header" id="3" ref={customerRef}>
                                <div className="skydart-bill-customer-header-left">
                                    {searchCus !== "" && <div className="filter-applied">
                                        <Filter size={10} />
                                    </div>}
                                    <FilterList fontSize="small" className="filter-list-shipment" onClick={() => { displaySortCustomer ? setDisplaySortCustomer(false) : setDisplaySortCustomer(true) }} />
                                    <h5 style={{ marginLeft: 5 }}>Customer/Exporter</h5>
                                </div>
                                <div className="skydart-bill-customer-header-right" style={{ marginLeft: "auto" }}>
                                    {!alphabet && <ArrowDropDown className="skydart-bill-cus-sort" onClick={() => setAlphabet(true)} />}
                                    {alphabet && <ArrowDropUp className="skydart-bill-cus-sort" onClick={() => setAlphabet(false)} />}
                                </div>
                                {displaySortCustomer && <div className="skydart-new-booking-filter-option" style={{ marginTop: 265 }}>
                                    <form className="skydart-new-booking-filter-container" onSubmit={() => setDisplaySortCustomer(false)} style={{ borderBottom: "1px solid lightgray" }}>
                                        <Search fontSize="small" />
                                        <input type="text" placeholder="Search Customer" value={searchCus} onChange={(e) => setSearchCus(e.target.value)} />
                                        <button className="hidden" type="submit">Submit</button>
                                    </form>
                                    <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                        {sortedCustomer.filter((s) => {
                                            if (s.toLowerCase().includes(searchCus.toLowerCase())) {
                                                return s;
                                            } else {
                                                return s;
                                            }
                                        }).map((s, index) => (
                                            <div className="sorted" onClick={() => { setSearchCus(s); setDisplaySortCustomer(false) }}>
                                                <h5 style={{ fontSize: 10 }}>{s}</h5>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="sorted-btn">
                                        <button type="button" onClick={() => setDisplaySortCustomer(false)}>Search</button>
                                        <h5 onClick={() => { setDisplaySortCustomer(false); setSearchCus("") }}>Cancel</h5>
                                    </div>
                                </div>}

                                <div ref={exportRef}>
                                    {displayExportAction && <div className="skydart-bill-export">
                                        <div className='skydart-bill-export-container'>
                                            <h5 style={{ fontWeight: 900 }}>Export</h5>
                                        </div>
                                        {exportSelected.length !== 0 && <div className="skydart-bill-export-option" onClick={() => setDisplayExportAll(true)}>
                                            <h5 style={{ fontSize: 10 }}>Selected Shipments</h5>
                                        </div>}
                                        {exportSelected.length === 0 && <div className="skydart-bill-export-option" style={{ cursor: "not-allowed" }}>
                                            <h5 style={{ fontSize: 10, color: "lightgray" }}>Selected Shipments</h5>
                                        </div>}
                                        {exportSelected.length !== 0 && <div className="skydart-bill-export-option" onClick={() => setDisplayExport(true)}>
                                            <h5 style={{ fontSize: 10 }}>Booking Export</h5>
                                        </div>}
                                        {exportSelected.length !== 0 && <div className="skydart-bill-export-option" onClick={() => setDisplayExport(true)}>
                                            <h5 style={{ fontSize: 10 }}>Bag from load export</h5>
                                        </div>}
                                        {exportSelected.length !== 0 && <div className="skydart-bill-export-option" onClick={() => setDisplayUsExport(true)}>
                                            <h5 style={{ fontSize: 10 }}>DIRECT US Export</h5>
                                        </div>}
                                        {exportSelected.length === 0 && <div className="skydart-bill-export-option" style={{ cursor: "not-allowed" }}>
                                            <h5 style={{ fontSize: 10, color: "lightgray" }}>DIRECT US Export</h5>
                                        </div>}
                                        {exportSelected.length === 0 && <div className="skydart-bill-export-option" style={{ cursor: "not-allowed" }}>
                                            <h5 style={{ fontSize: 10, color: "lightgray" }}>Booking Export</h5>
                                        </div>}
                                        {/* <div className="skydart-bill-export-option">
                                            <h5 style={{ fontSize: 10 }}>Customize Export</h5>
                                        </div> */}
                                        {/* <div className='skydart-bill-export-container'>
                                            <h5 style={{ fontWeight: 900 }}>Import</h5>
                                        </div> */}
                                        {userData.role === "OPS" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchReweight(true)}>
                                            <h5 style={{ fontSize: 10 }}>Reweight File</h5>
                                        </div>}
                                        {userData.role === "OPS" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchTracking(true)}>
                                            <h5 style={{ fontSize: 10 }}>Tracking File</h5>
                                        </div>}
                                        {userData.role === "Admin" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchReweight(true)}>
                                            <h5 style={{ fontSize: 10 }}>Reweight File</h5>
                                        </div>}
                                        {userData.role === "Admin" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchTracking(true)}>
                                            <h5 style={{ fontSize: 10 }}>Tracking File</h5>
                                        </div>}
                                        {userData.role === "CEO" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchReweight(true)}>
                                            <h5 style={{ fontSize: 10 }}>Reweight File</h5>
                                        </div>}
                                        {userData.role === "CEO" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchTracking(true)}>
                                            <h5 style={{ fontSize: 10 }}>Tracking File</h5>
                                        </div>}
                                        {userData.role === "COO" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchReweight(true)}>
                                            <h5 style={{ fontSize: 10 }}>Reweight File</h5>
                                        </div>}
                                        {userData.role === "COO" && <div className="skydart-bill-export-option" onClick={() => setDisplayBatchTracking(true)}>
                                            <h5 style={{ fontSize: 10 }}>Tracking File</h5>
                                        </div>}
                                    </div>}
                                </div>
                                <div ref={actionRef}>
                                    {displayAction && <div className="skydart-bill-action">
                                        <div>
                                            <div className="skydart-bill-action-option" onClick={shipmentArrived}>
                                                <h5>Arrive Depot</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={shipmentProcess}>
                                                <h5>In Process</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={shipmentHold}>
                                                <h5>Hold</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={shipmentClearance}>
                                                <h5>In Clearance</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={shipmentCleared}>
                                                <h5>Cleared For Export</h5>
                                            </div>
                                            {userData.role === "CEO" && <div className="skydart-bill-action-option" onClick={returnShipment}>
                                                <h5>Delete shipment</h5>
                                            </div>}
                                            {userData.role === "Admin" && <div className="skydart-bill-action-option" onClick={returnShipment}>
                                                <h5>Return origin</h5>
                                            </div>}
                                        </div>
                                        {userData.role === "OPS" && <div>
                                            <div className="skydart-bill-action-option1">
                                                <h3>Surcharge</h3>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={markRemote}>
                                                <h5>Mark as remote</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={markWrongAddress}>
                                                <h5>Mark as wrong address</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={markBoth}>
                                                <h5>Mark both</h5>
                                            </div>
                                        </div>}
                                        {userData.role === "CEO" && <div>
                                            <div className="skydart-bill-action-option1">
                                                <h3>Surcharge</h3>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={markRemote}>
                                                <h5>Mark as remote</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={markWrongAddress}>
                                                <h5>Mark as wrong address</h5>
                                            </div>
                                            <div className="skydart-bill-action-option" onClick={markBoth}>
                                                <h5>Mark both</h5>
                                            </div>
                                        </div>}
                                    </div>}
                                </div>
                            </div>
                            <div className="skydart-bill-hawb-header" id="4" style={{ display: "flex" }}>
                                <h5>SKD HAWB</h5>
                            </div>
                            <div className="skydart-bill-date-header" id="5">
                                <h5>Date - Time</h5>
                                {!alphabet && <ArrowDropDown className="skydart-bill-cus-sort" onClick={() => setAlphabet(true)} />}
                                {alphabet && <ArrowDropUp className="skydart-bill-cus-sort" onClick={() => setAlphabet(false)} />}
                            </div>
                        </div>
                        {showFrom && <div className="skydart-bill-from-header" id="6" ref={showFrom ? fromRef : null}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {searchFrom !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <FilterList fontSize="small" className="filter-list-shipment" onClick={() => { displaySortCountry ? setDisplaySortFrom(false) : setDisplaySortFrom(true) }} />
                                <div style={{ width: "180px" }}>
                                    <h5 style={{ marginLeft: 5, cursor: "pointer" }}>From</h5>
                                </div>
                            </div>
                            {displaySortFrom && <div className="skydart-new-booking-filter-option" style={{ marginTop: 10 }}>
                                <form className="skydart-new-booking-filter-container" onSubmit={() => setDisplaySortCountry(false)} style={{ borderBottom: "1px solid lightgray" }}>
                                    <Search fontSize="small" />
                                    <input type="text" placeholder="Search City" value={searchFrom} onChange={(e) => setSearchFrom(e.target.value)} />
                                    <button className="hidden" type="submit">Submit</button>
                                </form>
                                <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                    {duplicateFromCity.sort((a, b) => {
                                        return a.localeCompare(b);
                                    }).filter((s) => {
                                        if (s.toLowerCase().includes(searchFrom.toLowerCase())) {
                                            return s;
                                        } else {
                                            return s;
                                        }
                                    }).map((s, index) => (
                                        <div className="sorted" onClick={() => { setSearchFrom(s); setDisplaySortFrom(false) }}>
                                            <h5 style={{ fontSize: 10 }}>{s}</h5>
                                        </div>
                                    ))}
                                </div>
                                <div className="sorted-btn">
                                    <button type="button" onClick={() => setDisplaySortFrom(false)}>Search</button>
                                    <h5 onClick={() => { setDisplaySortFrom(false); setSearchFrom("") }}>Cancel</h5>
                                </div>
                            </div>}
                        </div>}
                        {showFrom && <div className="skydart-bill-empty-header">
                        </div>}
                        <div ref={showFrom ? null : fromRef}></div>
                        <div ref={showTo ? null : countryRef}></div>
                        {showTo && <div className="skydart-bill-to-header" id="7" style={{ cursor: "pointer", justifyContent: "space-between" }} ref={showTo ? countryRef : null}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {searchCountry !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                {searchCode !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <FilterList fontSize="small" className="filter-list-shipment" onClick={() => { displaySortCountry ? setDisplaySortCountry(false) : setDisplaySortCountry(true) }} />
                                <div style={{ width: "180px" }}>
                                    <h5 style={{ marginLeft: 5, cursor: "pointer" }}>Destination</h5>
                                </div>
                            </div>
                            {!countryAlphabet && <ArrowDropDown className="skydart-bill-cus-sort1" onClick={() => setCountryAlphabet(true)} />}
                            {countryAlphabet && <ArrowDropUp className="skydart-bill-cus-sort1" onClick={() => setCountryAlphabet(false)} />}
                            {displaySortCountry && <div className="skydart-new-booking-filter-option" style={{ marginTop: 265 }}>
                                <form className="skydart-new-booking-filter-container" onSubmit={() => setDisplaySortCountry(false)} style={{ borderBottom: "1px solid lightgray" }}>
                                    <Search fontSize="small" />
                                    <input type="text" placeholder="Search Customer" value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)} />
                                    <button className="hidden" type="submit">Submit</button>
                                </form>
                                <div className="sorted-customer-list" style={{ overflowX: "hidden" }}>
                                    {sortedCountry.filter((s) => {
                                        if (s.toLowerCase().includes(searchCus.toLowerCase())) {
                                            return s;
                                        } else {
                                            return s;
                                        }
                                    }).map((s, index) => (
                                        <div className="sorted" onClick={() => { setSearchCountry(s); setDisplaySortCountry(false) }}>
                                            <h5 style={{ fontSize: 10 }}>{s}</h5>
                                        </div>
                                    ))}
                                </div>
                                <div className="sorted-btn">
                                    <button type="button" onClick={() => setDisplaySortCountry(false)}>Search</button>
                                    <h5 onClick={() => { setDisplaySortCountry(false); setSearchCountry("") }}>Cancel</h5>
                                </div>
                            </div>}
                        </div>}
                        <div ref={showStatus1 ? null : statusRef}></div>
                        {showStatus1 && <div className="skydart-bill-status-header" id="8" ref={showStatus1 ? statusRef : null}>
                            <h5>Status</h5>
                            {!displayStatus && <ArrowDropDown className="skydart-bill-cus-sort1" onClick={() => setDisplayStatus(true)} />}
                            {displayStatus && <ArrowDropUp className="skydart-bill-cus-sort1" onClick={() => setDisplayStatus(false)} />}
                            {displayStatus && <div className="skydart-new-booking-filter-option" style={{ marginTop: 195 }}>
                                <div className="sorted-customer-list" style={{ overflowX: "hidden", borderBottom: "none" }}>
                                    <div className="sorted" onClick={() => { setStatus(""); setDisplayStatus(false) }}>
                                        <h5>All Shipment</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("Arrived"); setDisplayStatus(false) }}>
                                        <h5>Arrived Depot</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("Cleared For Export"); setDisplayStatus(false) }}>
                                        <h5>Cleared For Export</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("Delivered"); setDisplayStatus(false) }}>
                                        <h5>Delivered</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("On Hold"); setDisplayStatus(false) }}>
                                        <h5>On Hold</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("Picked Up"); setDisplayStatus(false) }}>
                                        <h5>Picked Up</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("In Process"); setDisplayStatus(false) }}>
                                        <h5>In Process</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("In Clearance"); setDisplayStatus(false) }}>
                                        <h5>In Clearance</h5>
                                    </div>
                                    <div className="sorted" onClick={() => { setStatus("In Transit"); setDisplayStatus(false) }}>
                                        <h5>In Transit</h5>
                                    </div>
                                </div>
                            </div>}
                        </div>}
                        <div ref={showType1 ? null : typeRef}></div>
                        <div className="skydart-bill-info-header">
                            {showType1 && <div className="skydart-bill-type-header" id="9" style={{ justifyContent: "space-between" }} ref={showType1 ? typeRef : null}>
                                {searchType !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Type</h5>
                                {!displayType && <ArrowDropDown className="skydart-bill-cus-sort1" onClick={() => setDisplayType(true)} />}
                                {displayType && <ArrowDropUp className="skydart-bill-cus-sort1" onClick={() => setDisplayType(false)} />}
                                {displayType && <div className="skydart-bill-type-container">
                                    <div className="sorted-type-list">
                                        <div className="type-sort" onClick={() => { setDisplayType(false); setSearchType("") }}>
                                            <h5>All</h5>
                                        </div>
                                        <div className="type-sort" onClick={() => { setDisplayType(false); setSearchType("WPX") }}>
                                            <h5>WPX</h5>
                                        </div>
                                        <div className="type-sort" onClick={() => { setDisplayType(false); setSearchType("DOX") }}>
                                            <h5>DOX</h5>
                                        </div>
                                        <div className="type-sort" onClick={() => { setDisplayType(false); setSearchType("ECS") }}>
                                            <h5>ECS</h5>
                                        </div>
                                    </div>
                                </div>}
                            </div>}
                            {showWeight1 && <div className="skydart-bill-weight-header" id="10" style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                {searchWeight !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Booked</h5>
                                </div>
                                <select style={{ fontSize: 13, width: "18%", border: "none", outline: "none", background: "none" }} value={searchWeight} onChange={(e) => setSearchWeight(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateWeight.sort((x, y) => {
                                        if (x > 0 && y > 0) {
                                            return x - y;
                                        }
                                        return y - x;
                                    }).map((s) => (
                                        <option value={s}>{(Math.round(s * 100) / 100).toFixed(2)}</option>
                                    ))}
                                </select>
                            </div>}
                            {showWeight1 && userData.role === "CEO" && <div className="skydart-bill-weight-header" id="10" style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                {searchExactWeight !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Exact Weight</h5>
                                </div>
                                <select style={{ fontSize: 13, width: "18%", border: "none", outline: "none", background: "none" }} value={searchExactWeight} onChange={(e) => setSearchExactWeight(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateExactWeight.sort((x, y) => {
                                        if (x > 0 && y > 0) {
                                            return x - y;
                                        }
                                        return y - x;
                                    }).map((s) => (
                                        <option value={s}>{(Math.round(s * 100) / 100).toFixed(2)}</option>
                                    ))}
                                </select>
                            </div>}
                            {showWeight1 && <div className="skydart-bill-weight-header" id="10" style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                {searchExactCharge !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Chargeable</h5>
                                </div>
                                <select style={{ fontSize: 13, width: "18%", border: "none", outline: "none", background: "none" }} value={searchExactCharge} onChange={(e) => setSearchExactCharge(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateChargeable.sort((x, y) => {
                                        if (x > 0 && y > 0) {
                                            return x - y;
                                        }
                                        return y - x;
                                    }).map((s) => (
                                        <option value={s}>{(Math.round(s * 100) / 100).toFixed(2)}</option>
                                    ))}
                                </select>
                            </div>}

                            {showWeight1 && <div className="skydart-bill-weight-header" id="11" style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                {searchReweight !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Reweight</h5>
                                </div>
                                <select style={{ fontSize: 13, width: "18%", border: "none", outline: "none", background: "none" }} value={searchReweight} onChange={(e) => setSearchReweight(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateReweight.sort((x, y) => {
                                        if (x > 0 && y > 0) {
                                            return x - y;
                                        }
                                        return y - x;
                                    }).map((s) => (
                                        <option value={s}>{(Math.round(s * 100) / 100).toFixed(2)}</option>
                                    ))}
                                </select>
                            </div>}

                            {showPcs1 && <div className="skydart-bill-pcs-header" id="12" style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                                {searchPcs !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Pieces</h5>

                                </div>
                                <select style={{ fontSize: 13, width: "21%", border: "none", outline: "none", background: "none" }} value={searchPcs} onChange={(e) => setSearchPcs(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicatePcs.sort((x, y) => {
                                        if (x > 0 && y > 0) {
                                            return x - y;
                                        }
                                        return y - x;
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>}
                        </div>
                        <div className="skydart-bill-carrier-header">
                            {showPacking1 && <div className="skydart-bill-packing-header" id="13" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchPack !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Packing</h5>
                                <select style={{ fontSize: 13, width: "23%", border: "none", outline: "none", background: "none" }} value={searchPack} onChange={(e) => setSearchPack(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicatePack.sort((x, y) => {
                                        if (x > 0 && y > 0) {
                                            return x - y;
                                        }
                                        return y - x;
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>}
                            {showAirline1 && <div className="skydart-bill-airline-header" id="14" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchAirline !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Airline</h5>
                                <select style={{ fontSize: 13, width: "17%", border: "none", outline: "none", background: "none" }} value={searchAirline} onChange={(e) => setSearchAirline(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateAirline.sort((x, y) => {
                                        return x.localeCompare(y)
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>}
                            {showService1 && <div className="skydart-bill-service-header" id="15" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchService !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Service</h5>
                                <select style={{ fontSize: 13, width: "17%", border: "none", outline: "none", background: "none" }} value={searchService} onChange={(e) => setSearchService(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateService.sort((x, y) => {
                                        return x.localeCompare(y)
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>}
                            {showAgent1 && <div className="skydart-bill-agent-header" id="16" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchAgent !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Agent</h5>
                                <select style={{ fontSize: 13, width: "17%", border: "none", outline: "none", background: "none" }} value={searchAgent} onChange={(e) => setSearchAgent(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateAgent.sort((x, y) => {
                                        return x.localeCompare(y)
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>}
                        </div>
                        <div ref={showMawb1 ? null : mawbRef}></div>
                        {showMawb1 && <div className="skydart-bill-mawb-header" id="17" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} ref={showMawb1 ? mawbRef : null}>
                            {searchMawb !== "" && <div className="filter-applied">
                                <Filter size={10} />
                            </div>}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FilterList className="skydart-bill-cus-search" onClick={sortMawb} />
                                <h5>MAWB</h5>
                            </div>
                            <select style={{ fontSize: 13, width: "10%", border: "none", outline: "none", background: "none" }} value={searchMawb} onChange={(e) => setSearchMawb(e.target.value)}>
                                <option value="">All</option>
                                {duplicateMawb.sort((x, y) => {
                                    return x.localeCompare(y);
                                }).map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                            {displaySortMawb && <div className="skydart-new-booking-filter-option" style={{ marginTop: 65 }}>
                                <form className="skydart-new-booking-filter-container" onSubmit={() => setDisplaySortMawb(false)} style={{ borderBottom: "1px solid lightgray" }}>
                                    <Search fontSize="small" />
                                    <input type="text" placeholder="Search MAWB" value={searchMawb} onChange={(e) => setSearchMawb(e.target.value)} />
                                    <button className="hidden" type="submit">Submit</button>
                                </form>

                            </div>}
                        </div>}
                        <div ref={showSin1 ? null : sinRef}></div>
                        {showSin1 && <div className="skydart-bill-sin-header" id="18" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} ref={showSin1 ? sinRef : null}>
                            {searchSin !== "" && <div className="filter-applied">
                                <Filter size={10} />
                            </div>}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FilterList className="skydart-bill-cus-search" onClick={() => setDisplaySortSin(true)} />
                                <h5>Intl AWB</h5>
                            </div>
                            <select style={{ fontSize: 13, width: "10%", border: "none", outline: "none", background: "none" }} value={searchSin} onChange={(e) => setSearchSin(e.target.value)}>
                                <option value="">All</option>
                                {duplicateSin.sort((x, y) => {
                                    return x.localeCompare(y);
                                }).map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                            {displaySortSin && <div className="skydart-new-booking-filter-option" style={{ marginTop: 65 }}>
                                <form className="skydart-new-booking-filter-container" onSubmit={() => setDisplaySortSin(false)} style={{ borderBottom: "1px solid lightgray" }}>
                                    <Search fontSize="small" />
                                    <input type="text" placeholder="Search AWB" value={searchSin} onChange={(e) => setSearchSin(e.target.value)} />
                                    <button className="hidden" type="submit">Submit</button>
                                </form>

                            </div>}
                        </div>}
                        {showRate1 && userData.role !== "Courier" && < div className="skydart-bill-rate-header">
                            <div className="skydart-bill-ship-rate-header" id="19" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchRate !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Rate</h5>
                                </div>
                                <select style={{ fontSize: 13, width: "25%", border: "none", outline: "none", background: "none" }} value={searchRate} onChange={(e) => {
                                    setSearchRate(e.target.value);

                                }}>
                                    <option value="">All</option>
                                    {duplicateRate.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-ship-rate-header" id="20" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchRate !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <div>
                                    <h5>Extra</h5>
                                </div>
                                <select style={{ fontSize: 13, width: "25%", border: "none", outline: "none", background: "none" }} value={searchComestic} onChange={(e) => setSearchComestic(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateComestic.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-address-header" id="21" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchAddress !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Add Correct</h5>
                                <select style={{ fontSize: 13, width: "15%", border: "none", outline: "none", background: "none" }} value={searchAddress} onChange={(e) => setSearchAddress(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateAddress.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-remote-header" id="22" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchRemote !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Remote</h5>
                                <select style={{ fontSize: 13, width: "20%", border: "none", outline: "none", background: "none" }} value={searchRemote} onChange={(e) => setSearchRemote(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateRemote.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-surcharge-header" id="23" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchSurcharge !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Extra 1</h5>
                                <select style={{ fontSize: 13, width: "20%", border: "none", outline: "none", background: "none" }} value={searchSurcharge} onChange={(e) => setSearchSurcharge(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateExtra1.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-fsc-header" id="24" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchFsc !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>FSC(%)</h5>
                                <select style={{ fontSize: 13, width: "30%", border: "none", outline: "none", background: "none" }} value={searchFsc} onChange={(e) => setSearchFsc(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateFsc.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-fscValue-header" id="25" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchFscValue !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>FSC Value</h5>
                                <select style={{ fontSize: 13, width: "20%", border: "none", outline: "none", background: "none" }} value={searchFscValue} onChange={(e) => setSearchFscValue(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateFscValue.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-surcharge-header" id="26" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchSurcharge2 !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Extra 2</h5>
                                <select style={{ fontSize: 13, width: "20%", border: "none", outline: "none", background: "none" }} value={searchSurcharge2} onChange={(e) => setSearchSurcharge2(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateExtra2.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-vat-header" id="27" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchVat !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>VAT(%)</h5>
                                <select style={{ fontSize: 13, width: "30%", border: "none", outline: "none", background: "none" }} value={searchVat} onChange={(e) => setSearchVat(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateVat.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-vatValue-header" id="28" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchVatValue !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>VAT Value</h5>
                                <select style={{ fontSize: 13, width: "20%", border: "none", outline: "none", background: "none" }} value={searchVatValue} onChange={(e) => setSearchVatValue(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateVatValue.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-surcharge-header" id="29" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {searchSurcharge3 !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <h5>Extra 3</h5>
                                <select style={{ fontSize: 13, width: "20%", border: "none", outline: "none", background: "none" }} value={searchSurcharge3} onChange={(e) => setSearchSurcharge3(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateExtra3.sort((x, y) => {
                                        return x.localeCompare(y);
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-total-header" id="30" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h5>Total Value</h5>
                            </div>
                            <div className="skydart-bill-total-header" id="31" style={{ borderRight: "none" }}>
                                <h5>Last Updated</h5>

                            </div>
                        </div>}
                        {showController1 && <div className="skydart-bill-sale-header" id="32" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            {searchSale !== "" && <div className="filter-applied">
                                <Filter size={10} />
                            </div>}
                            <h5>Sales/Controller</h5>
                            <select style={{ fontSize: 13, width: "8%", border: "none", outline: "none", background: "none" }} value={searchSale} onChange={(e) => setSearchSale(e.target.value)}>
                                <option value="">All</option>
                                {duplicateSale.sort((x, y) => {
                                    return x.localeCompare(y);
                                }).map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>}
                        {showRate1 && <div className="skydart-bill-perform-header" id="33">
                            <h5>Performance</h5>
                        </div>}
                        {showPayment1 && <div className="skydart-bill-payment-header">
                            <div className="skydart-bill-pickup-header" id="34" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h5>Pick Up</h5>
                                <select value={searchPickup} onChange={(e) => { setSearchPickup(e.target.value); setCurrentPage(1) }} style={{ width: "25%", background: "none", outline: "none", border: "none" }}>
                                    <option value="">All</option>
                                    {duplicatePaymentPickUp.map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-pickup-header" id="35" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h5>ACC</h5>
                                <select value={searchAcc} onChange={(e) => { setSearchAcc(e.target.value); setCurrentPage(1) }} style={{ width: "25%", background: "none", outline: "none", border: "none" }}>
                                    <option value="">All</option>
                                    {duplicatePaymentAccountant.map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="skydart-bill-pickup-header" id="36" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <h5>Comp</h5>
                                <select value={searchCompany} onChange={(e) => { setSearchCompany(e.target.value); setCurrentPage(1) }} style={{ width: "25%", background: "none", outline: "none", border: "none" }}>
                                    <option value="">All</option>
                                    {duplicatePaymentCompany.map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                        </div>}
                        {showNote1 && <div className="skydart-bill-note-header" id="37" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h5>OPS Note</h5>
                            <select value={searchNote} onChange={(e) => { setSearchNote(e.target.value); setCurrentPage(1) }}>
                                <option value="">All</option>
                                {duplicateNote.map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>}
                        {showNote1 && <div className="skydart-bill-note-header" id="38" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h5>Selling Note</h5>
                            <select value={searchSellNote} onChange={(e) => { setSearchSellNote(e.target.value); setCurrentPage(1) }} style={{ width: "15%", background: "none", outline: "none", border: "none" }}>
                                <option value="">All</option>
                                {duplicateSellNote.map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>}
                        {showNote1 && <div className="skydart-bill-note-header" id="39" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h5>Acc Note</h5>
                            <select value={searchAccNote} onChange={(e) => { setSearchAccNote(e.target.value); setCurrentPage(1) }} style={{ width: "15%", background: "none", outline: "none", border: "none" }}>
                                <option value="">All</option>
                                {duplicateAccountNote.map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>}
                        {showNote1 && <div className="skydart-bill-note-header" id="39" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h5>Pick up Note</h5>
                            <select style={{ width: "15%", background: "none", outline: "none", border: "none" }} value={searchPickupNote} onChange={(e) => { setSearchPickupNote(e.target.value); setCurrentPage(1) }}>
                                <option value="">All</option>
                                {duplicatePickupNote.map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>}
                        {showNote1 && <div className="skydart-bill-note-header" id="39" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h5>GN - Note</h5>
                            <select value={searchGnNote} onChange={(e) => { setSearchGnNote(e.target.value); setCurrentPage(1) }} style={{ width: "15%", background: "none", outline: "none", border: "none" }}>
                                <option value="">All</option>
                                {duplicateGnNote.map((s) => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>}
                    </div>

                    {/* {shipments.map((s, index) => (
                        

                        <MainShipment defaultBody={defaultBody} shipments={shipments} select={s.select} shipmentRef={lastShipmentRef} no={index + 1} date={s.date} time={s.time} plan={s.plan} id={s.hawb} key={s.hawb} hawb={s.hawb} customer={s.sender} />
                    
                    ))} */}
                    {currentShipment.sort((s, i) => {
                        if (alphabet) {
                            s = s.sender || "";
                            i = i.sender || "";
                            return s.localeCompare(i);
                        }
                    }).sort((s, i) => {
                        if (countryAlphabet) {
                            s = s.country || "";
                            i = i.country || "";
                            return s.localeCompare(i);
                        }
                    }).map((s, index) => {

                        let code = s.code || "";
                        return <div className={s.select ? "selected-shipment" : "skydart-bill-body"} key={index}>
                            <div className={scrollX > 0 ? "skydart-sticky-body" : "skydart-sticky-body2"}>
                                <div className="skydart-bill-checkbox-header" style={{ padding: 13 }}>
                                    <input type="checkbox" className="single-check" checked={s.select} onChange={e => {
                                        setSelectedShipment(getWeight.map(sd => {
                                            let value = e.target.checked;
                                            if (sd.hawb === s.hawb) {
                                                sd.select = value;
                                            }
                                            return sd;
                                        }))
                                    }} />
                                </div>
                                <div className="skydart-bill-no-header">
                                    <h5 style={{ textAlign: "center" }}>{index + 1}</h5>
                                </div>
                                <div className="skydart-bill-class-header">
                                    <h5>{s.plan}</h5>
                                </div>
                                <div className="skydart-bill-customer-header" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                    <h5 style={{ marginLeft: 10 }}>{s.sender.length > 25 ? s.sender.slice(0, 25) + "..." : s.sender}</h5>
                                    <h5 style={{ marginLeft: 10, fontSize: 10, color: '#0068be' }}>{s.makh}</h5>
                                    <p className="skydart-bill-hawb-header-helper">{s.sender}</p>
                                </div>
                                <div className="skydart-bill-hawb-header">
                                    {!s.select && <h5 style={{ color: '#0068be', textDecoration: "none", marginTop: -13 }}>{s.hawb}</h5>}
                                    {s.select && <h5 style={{ color: 'black', textDecoration: "none", marginTop: -13 }}>{s.hawb}</h5>}

                                    <div className="skydart-bill-hawb-edit" style={{ display: "flex", alignItems: "center" }}>
                                        <div className="skydart-hawb-edit-option" onClick={() => {
                                            setDisplayEditScreen(true);
                                            setHawb(s.hawb);
                                        }}>
                                            <Edit style={{ fontSize: 13 }} />
                                            <h5 style={{ fontSize: 10, marginLeft: 3 }}>Edit</h5>
                                        </div>
                                        <CopyToClipboard text={s.hawb} onCopy={() => {
                                            setCopyText(s.hawb);
                                            setDisplayCopy(true);
                                        }}>
                                            <div className="skydart-hawb-edit-option">
                                                <Copy size={13} />
                                                <h5 style={{ fontSize: 10, marginLeft: 3 }}>Copy</h5>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <div className="skydart-bill-date-header" style={{ position: "relative" }}>
                                    <h5 style={{ marginTop: -10, fontSize: 12 }}>{new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - {s.time}</h5>
                                    {/* <h5 style={{ fontSize: 9, position: "absolute", textDecoration: "none" }}>By: {s.pickupBy}</h5> */}
                                </div>
                            </div>
                            {showFrom && <div className="skydart-bill-from-header" style={{ borderRight: "none", width: "2.68%" }}>
                                <h5>{s.fromCity}</h5>
                            </div>}
                            {showFrom && <div className="skydart-bill-empty-header">
                                <ArrowRightAlt style={{ background: "rgb(205, 233, 255)", padding: 1, marginTop: 3, fontSize: 15, color: "green" }} />
                            </div>}
                            {showTo && <div className="skydart-bill-to-header" style={{ marginLeft: 1 }}>
                                {!s.select && <h5 style={{ marginLeft: 7, color: '#0068be' }}>{s.country} ({code})</h5>}
                                {s.select && <h5 style={{ marginLeft: 7, color: 'black', textDecoration: "none" }}>{s.country} ({code})</h5>}
                            </div>}
                            {showStatus1 && <div className="skydart-bill-status-header">
                                {s.status === "Arrived At Skydart Origin Depot" && <h5 style={{ padding: 3, background: "dodgerblue", color: "white", borderRadius: 5, cursor: "pointer" }}>Arrived</h5>}
                                {s.status === "Pending" && <h5 style={{ padding: 3, background: "lightgray", color: "black", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "Picked Up" && <h5 style={{ padding: 3, background: "Orange", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "In Process" && <h5 style={{ padding: 3, background: "#0068be", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "On Hold" && <h5 style={{ padding: 3, background: "red", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "Return" && <h5 style={{ padding: 3, background: "red", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "In Clearance" && <h5 style={{ padding: 3, background: "orangered", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "Cleared For Export" && <h5 style={{ padding: 3, background: "darkgreen", color: "white", borderRadius: 5, cursor: "pointer" }}>Cleared</h5>}
                                {s.status === "In Transit" && <h5 style={{ padding: 3, background: "#0068be", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                                {s.status === "Delivered" && <h5 style={{ padding: 3, background: "green", color: "white", borderRadius: 5, cursor: "pointer" }}>{s.status}</h5>}
                            </div>}
                            <div className="skydart-bill-info-header">
                                {showType1 && s.type === "WPX" && <div className="skydart-bill-type-header">
                                    <Package size={20} />
                                    <h5 style={{ marginLeft: 5 }}>{s.type}</h5>
                                </div>}
                                {showType1 && s.type === "DOX" && <div className="skydart-bill-type-header">
                                    <File size={20} />
                                    <h5 style={{ marginLeft: 5 }}>{s.type}</h5>
                                </div>}
                                {showWeight1 && <div className="skydart-bill-weight-header">
                                    <h5>{(Math.round(s.chargeable * 100) / 100).toFixed(2)} KG</h5>
                                </div>}
                                {showWeight1 && userData.role === "CEO" && <div className="skydart-bill-weight-header">
                                    <h5>{(Math.round(s.exactWeight * 100) / 100).toFixed(2)} KG</h5>
                                </div>}
                                {showWeight1 && <div className="skydart-bill-weight-header">
                                    <h5>{(Math.round(s.exactCharge * 100) / 100).toFixed(2)} KG</h5>
                                </div>}
                                {showWeight1 && <div className="skydart-bill-weight-header">
                                    {!s.select && <h5 style={{ color: "#0068be", textDecoration: "none" }}>{(Math.round(s.reweight * 100) / 100).toFixed(2)} KG</h5>}
                                    {s.select && <h5 style={{ color: "black", textDecoration: "none" }}>{(Math.round(s.reweight * 100) / 100).toFixed(2)} KG</h5>}
                                </div>}
                                {showPcs1 && <div className="skydart-bill-pcs-header">
                                    <h5>{s.amount} PCS</h5>
                                </div>}
                            </div>
                            <div className="skydart-bill-carrier-header">
                                {showPacking1 && <div className="skydart-bill-packing-header">
                                    <h5>{s.pack?.replaceAll(",", " / ")}</h5>
                                </div>}
                                {showAirline1 && <div className="skydart-bill-airline-header">
                                    <h5>{s.airline}</h5>
                                </div>}
                                {showService1 && <div className="skydart-bill-service-header">
                                    <h5>{s.service}</h5>
                                </div>}
                                {showAgent1 && <div className="skydart-bill-agent-header">
                                    <h5>{s.agent}</h5>
                                </div>}
                            </div>
                            {showMawb1 && <div className="skydart-bill-mawb-header">
                                <h5>{s.mawb}</h5>
                            </div>}
                            {showSin1 && <div className="skydart-bill-sin-header">
                                <h5>{s.sin}</h5>
                            </div>}
                            {showRate1 && userData.role !== "Courier" && <div className="skydart-bill-rate-header">
                                <div className="skydart-bill-ship-rate-header">
                                    <h5>{(Math.round(s.rate * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-ship-rate-header">
                                    <h5>{(Math.round(s.comestic * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-address-header">
                                    <h5>{(Math.round(s.addressCorrection * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-remote-header">
                                    <h5>{(Math.round(s.remote * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-surcharge-header">
                                    <h5>{(Math.round(s.surcharge * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-fsc-header">
                                    <h5>{s.fsc}%</h5>
                                </div>
                                <div className="skydart-bill-fscValue-header">
                                    <h5>{(Math.round(s.fscValue * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-surcharge-header">
                                    <h5>{(Math.round(s.surcharge2 * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-vat-header">
                                    <h5>{s.vat}%</h5>
                                </div>
                                <div className="skydart-bill-vatValue-header">
                                    <h5>{(Math.round(s.vatValue * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-surcharge-header">
                                    <h5>{(Math.round(s.surcharge3 * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-total-header">
                                    <h5>{(Math.round(s.totalValue * 100) / 100).toFixed(2)}</h5>
                                </div>
                                <div className="skydart-bill-total-header" style={{ borderRight: "none" }}>
                                    <h5>{s.rateUpdate}</h5>
                                </div>
                            </div>}
                            {showController1 && <div className="skydart-bill-sale-header">
                                <h5>{s.controller}</h5>
                            </div>}
                            {showRate1 && <div className="skydart-bill-perform-header">
                                <h5>{s.perform}</h5>
                            </div>}
                            {showPayment1 && <div className="skydart-bill-payment-header">
                                <div className="skydart-bill-pickup-header">
                                    <h5>{s.paymentPickup}</h5>
                                </div>
                                <div className="skydart-bill-pickup-header">
                                    <h5>{s.paymentAcc}</h5>
                                </div>
                                <div className="skydart-bill-pickup-header">
                                    <h5>{s.paymentCompany}</h5>
                                </div>
                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">
                                <h5>{s.note}</h5>
                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">
                                <h5>{s.sellNote}</h5>
                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">
                                <h5>{s.accNote}</h5>
                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">
                                <h5>{s.pickupNote}</h5>
                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">
                                <h5>{s.gnNote || "Pending"}</h5>
                            </div>}
                        </div>
                    }
                    )}
                    <div className="skd-record">
                        <div className="skydart-bill-header">
                            <div className={scrollX > 0 ? "skydart-sticky-header" : "skydart-sticky-header1"} style={{ padding: 10, width: 803 }}>
                                <div className="skydart-bill-checkbox-header" style={{ border: "none" }}>
                                    {/* <h5>{userData.displayName}</h5> */}
                                </div>
                                <div className="skydart-bill-no-header" style={{ border: "none" }}>
                                    {/* <h5 style={{ textAlign: "center" }}>No.</h5> */}
                                </div>
                                <div className="skydart-bill-class-header" style={{ border: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    {/* <h5>Class</h5> */}
                                    {/* <select value={searchClass} style={{ border: "none", background: "none", outline: "none", width: "20%" }} onChange={(e) => setSearchClass(e.target.value)}>
                                    <option value="">All</option>
                                    {duplicateClass.sort((x, y) => {
                                        return x.localeCompare(y)
                                    }).map((s) => (
                                        <option value={s}>{s}</option>
                                    ))}
                                </select> */}
                                </div>
                                <div className="skydart-bill-customer-header">
                                    <div className="skydart-bill-customer-header-left">
                                        {/* {searchCus !== "" && <div className="filter-applied">
                                        <Filter size={10} />
                                    </div>}
                                    <FilterList className="skydart-bill-cus-search" onClick={sortCustomer} />
                                    <h5>Customer/Exporter</h5> */}
                                        <h5 style={{ fontSize: 11, marginLeft: 10, fontWeight: 600, color: "black" }}>(Showing {getWeight.length} of <strong style={{ color: "darkblue", textDecoration: "underline" }}>{roleBase.length}</strong> from <strong style={{ color: "gray" }}>{status || "All Shipments"}</strong>)</h5>
                                    </div>
                                    {/* <div className="skydart-bill-customer-header-right" style={{ marginLeft: "auto" }}>
                                    {!alphabet && <ArrowDropDown className="skydart-bill-cus-sort" onClick={() => setAlphabet(true)} />}
                                    {alphabet && <ArrowDropUp className="skydart-bill-cus-sort" onClick={() => setAlphabet(false)} />}
                                </div> */}



                                </div>
                                <div className="skydart-bill-hawb-header" style={{ display: "flex", border: "none" }}>

                                </div>
                                <div className="skydart-bill-date-header">

                                </div>
                            </div>
                            {showFrom && <div className="skydart-bill-from-header" style={{ border: "none" }}>

                            </div>}
                            {showFrom && <div className="skydart-bill-empty-header">

                            </div>}
                            {showTo && <div className="skydart-bill-to-header" style={{ cursor: "pointer", border: "none", justifyContent: "space-between" }}>
                                {/* <div style={{ display: "flex", alignItems: "center" }}>
                                {searchCountry !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                {searchCode !== "" && <div className="filter-applied">
                                    <Filter size={10} />
                                </div>}
                                <FilterList className="skydart-bill-cus-search" onClick={() => setDisplaySortCountry(true)} />
                                <div style={{width: "180px"}}>
                                <h5 style={{ marginLeft: 5, cursor: "pointer" }}>Destination</h5>
                                </div>
                            </div> */}

                            </div>}
                            {showStatus1 && <div className="skydart-bill-status-header" style={{ border: "none" }}>

                            </div>}
                            <div className="skydart-bill-info-header">
                                {showType1 && <div className="skydart-bill-type-header" style={{ width: 104, justifyContent: "space-between", border: "none" }}>

                                </div>}

                                {showWeight1 && <div className="skydart-bill-weight-header" style={{ border: "none", marginLeft: -1, width: 110, borderLeft: "1px solid lightgray", borderRight: "1px solid lightgray", padding: 5, justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                    <h3 className="so-lieu" style={{ color: "black" }}>{(Math.round(totalWeight * 100) / 100).toFixed(2) + " " + "KG"} </h3>
                                </div>}
                                {showWeight1 && <div className="skydart-bill-weight-header" style={{ border: "none", marginLeft: -1, width: 110, borderLeft: "1px solid lightgray", borderRight: "1px solid lightgray", padding: 5, justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                    <h3 className="so-lieu" style={{ color: "black" }}>{(Math.round(totalExactWeight * 100) / 100).toFixed(2) + " " + "KG"} </h3>
                                </div>}
                                {showWeight1 && userData.role === "CEO" && <div className="skydart-bill-weight-header" style={{ border: "none", marginLeft: -1, width: 110, borderLeft: "1px solid lightgray", borderRight: "1px solid lightgray", padding: 5, justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                    <h3 className="so-lieu" style={{ color: "black" }}>{(Math.round(totalExactCharge * 100) / 100).toFixed(2) + " " + "KG"} </h3>
                                </div>}
                                {showWeight1 && <div className="skydart-bill-weight-header" style={{ border: "none", marginLeft: 1, width: 109, padding: 5, borderRight: "1px solid lightgray", justifyContent: "space-between", display: "flex", alignItems: "center" }} >
                                    <h3 className="so-lieu" >{(Math.round(totalReweight * 100) / 100).toFixed(2)} KG</h3>
                                </div>}

                                {showPcs1 && <div className="skydart-bill-pcs-header" style={{ border: "none", borderRight: "1px solid lightgray", width: 81, marginLeft: -1, padding: 5, justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{convertThoudsand(totalPieces)}</h3>
                                </div>}
                            </div>
                            <div className="skydart-bill-carrier-header">
                                {showPacking1 && <div className="skydart-bill-packing-header" style={{ borderRight: "none", }}></div>}
                                {showAirline1 && <div className="skydart-bill-airline-header" style={{ borderRight: "none", }}></div>}
                                {showService1 && <div className="skydart-bill-service-header" style={{ borderRight: "none", }}></div>}
                                {showAgent1 && <div className="skydart-bill-agent-header" style={{ marginLeft: 2, borderRight: "none", }}></div>}
                            </div>
                            {showMawb1 && <div className="skydart-bill-mawb-header" style={{ border: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                            </div>}
                            {showSin1 && <div className="skydart-bill-sin-header" style={{ border: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                            </div>}
                            {showRate1 && userData.role !== "Courier" && <div className="skydart-bill-rate-header" style={{ padding: 0, border: "none" }}>
                                <div className="skydart-bill-ship-rate-header" style={{ padding: 5, border: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu">{totalRate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-ship-rate-header" style={{ padding: 5, border: "none", marginLeft: 10, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalComestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-address-header" style={{ padding: 5, border: "none", marginLeft: 9, display: "flex", borderLeft: "1px solid lightgray", borderRight: "none", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalAddress.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-remote-header" style={{ padding: 5, border: "none", marginLeft: 9, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalRemote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-surcharge-header" style={{ padding: 5, border: "none", marginLeft: 9, borderRight: "none", borderLeft: '1px solid lightgray', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalSurcharge.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-fsc-header" style={{ padding: 5, border: 'none', marginLeft: 8, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>.</h3>
                                </div>

                                <div className="skydart-bill-fscValue-header" style={{ padding: 5, border: "none", marginLeft: 9, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalFscValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-surcharge-header" style={{ padding: 5, border: "none", marginLeft: 8, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalSurcharge2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-vat-header" style={{ padding: 5, border: "none", marginLeft: 9, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                </div>
                                <div className="skydart-bill-vatValue-header" style={{ padding: 5, border: "none", marginLeft: 10, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalVatValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>

                                </div>
                                <div className="skydart-bill-surcharge-header" style={{ padding: 5, border: "none", marginLeft: 9, borderRight: "none", borderLeft: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "black" }}>{totalSurcharge3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-total-header" style={{ padding: 5, border: "none", marginLeft: 10, borderRight: "none", borderLeft: 'none', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <h3 className="so-lieu" style={{ color: "red" }}>{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                </div>
                                <div className="skydart-bill-total-header" >

                                </div>

                            </div>}
                            {showController1 && <div className="skydart-bill-sale-header">

                            </div>}
                            {showRate1 && <div className="skydart-bill-perform-header">

                            </div>}
                            {showPayment1 && <div className="skydart-bill-payment-header">
                                <div className="skydart-bill-pickup-header">

                                </div>
                                <div className="skydart-bill-pickup-header">

                                </div>
                                <div className="skydart-bill-pickup-header">

                                </div>
                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">

                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">

                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">

                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">

                            </div>}
                            {showNote1 && <div className="skydart-bill-note-header">

                            </div>}
                            {/* <div className="skydart-bill-sale-header" style={{padding: 5,borderRight: "none",borderLeft: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                
                            </div> */}



                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];